import { useQuery } from 'react-query';
import {
  getBaseResume, getBaseResumes, getResumeComments, getResumeTemplates, getStudentBaseResume, getStudentTailoredResume, getTailoredResume,
} from '../api/resume';

export const useGetResumeTemplates = () => useQuery('resume-templates', getResumeTemplates, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 24,
});

export const useGetBaseResume = (studentID: string, options = {}) => useQuery(
  ['baseResume', studentID],
  async () => {
    const response = await getBaseResume(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetTailoredResume = (studentID: string, options = {}) => useQuery(
  ['baseResume', studentID],
  async () => {
    const response = await getTailoredResume(studentID);
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useResumeQuery = (type: 'base' | 'tailored', studentID: string, options = {}) => useQuery(
  ['resume', type, studentID],
  () => (type === 'base' ? getBaseResume(studentID) : getTailoredResume(studentID)),
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useStudentResumeQuery = (type: 'base' | 'tailored', studentID: string, resumeID: string, options = {}) => useQuery(
  ['resume', type, studentID, resumeID],
  () => (type === 'base' ? getStudentBaseResume(studentID, resumeID) : getStudentTailoredResume(studentID, resumeID)),
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetResumeComments = (resumeID: string, options = {}) => useQuery(
  ['resumeComments', resumeID],
  () => getResumeComments(resumeID),
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetSelfBaseResume = (options = {}) => useQuery(
  ['self-base-resume'],
  async () => {
    const response = await getBaseResumes();

    return Array.isArray(response) ? response : [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);
