import { useState, useEffect } from 'react';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { useGetAlumniByUniversity } from '../queries/alumn';
import {
  Alum, AlumCompany, AlumLocation, AlumniBookFiltersType, Education,
} from '@/types/alum';
import {
  extractWorkExperience, extractEducation, determineHiringSuccess, parseCareerSummary,
} from '@/domains/core/alum/helpers/helpers';

type Props = {
  currentTab: string | null;
  defaultFilters: AlumniBookFiltersType;
  universityID: string;
};

export const useAlumniBookFiltering = ({
  defaultFilters, universityID,
}: Props) => {
  const [alumni, setAlumni] = useState<Alum[]>([]);
  const [currentActiveId, setCurrentActiveId] = useState('');
  const [currentLoadingId, setCurrentLoadingId] = useState('');
  const [companies, setCompanies] = useState<AlumCompany[]>([]);
  const [locations, setLocations] = useState<AlumLocation[]>([]);
  const [filters, setFilters] = useState<AlumniBookFiltersType>(defaultFilters);

  const { data: alumniResponse, isLoading: isStudentsLoading } = useGetAlumniByUniversity(universityID, {
    enabled: !!universityID,
  });

  const getLocations = async (allAlumni: Alum[]) => {
    // Map over all alumni to extract addresses.
    const addresses = allAlumni
      .map((alum) => {
        const summary = parseCareerSummary(alum.career_summary.profile);
        return summary?.basics?.location?.address;
      })
      // Filter out undefined and addresses that contain ',' or '@'
      .filter((address): address is string => typeof address === 'string' && !address.includes(',') && !address.includes('@'));

    // Remove duplicate addresses.
    const uniqueAddresses = Array.from(new Set(addresses));

    // Map the unique addresses into the expected object format.
    const alumLocations = uniqueAddresses.map((address) => ({ name: address, value: address }));
    setLocations(alumLocations);
  };

  const getCompanies = async () => {
    if (!alumniResponse) return;

    const companiesSet = new Set<string>();

    alumniResponse.forEach((alum) => {
      const workExperiences = extractWorkExperience(alum.career_summary?.profile);
      if (Array.isArray(workExperiences)) {
        workExperiences.forEach((job) => {
          if (job.name && job.name.trim() !== '') {
            companiesSet.add(job.name);
          }
        });
      }
    });

    // Convert the set to an array of objects for the filter
    const companiesArray = Array.from(companiesSet).map((name) => ({ name, value: name }));
    setCompanies(companiesArray);
  };

  // Helper functions for each filter type
  const matchesNameFilter = (alum: Alum, searchName: string): boolean => {
    if (!searchName) return true;
    return `${alum.first_name} ${alum.last_name}`.toLowerCase().includes(searchName.toLowerCase());
  };

  const matchesCurrentCompanyFilter = (alum: Alum, matchCompanies: AlumCompany[]): boolean => {
    if (!matchCompanies.length) return true;

    const work = extractWorkExperience(alum.career_summary?.profile);
    if (!Array.isArray(work) || !work.length) return false;

    // Only check the first (current) work experience
    return matchCompanies.some(company => work[0]?.name === company.name);
  };

  const matchesPastCompanyFilter = (alum: Alum, pastCompanies: AlumCompany[]): boolean => {
    if (!pastCompanies.length) return true;

    const work = extractWorkExperience(alum.career_summary?.profile);
    if (!Array.isArray(work) || work.length <= 1) return false;

    // Check all work experiences except the first one
    return pastCompanies.some(company => work.slice(1).some(pastWork => pastWork?.name === company.name));
  };

  const matchesLocationFilter = (alum: Alum, matchLocations: AlumLocation[]): boolean => {
    if (!Array.isArray(matchLocations) || matchLocations.length === 0) return true;

    const summary = parseCareerSummary(alum.career_summary?.profile);
    const address = summary?.basics?.location?.address || '';
    return matchLocations.some(location => address.includes(location.value));
  };

  const matchesDegreeFilter = (alum: Alum, degree: string): boolean => {
    if (!degree) return true;

    const education = extractEducation(alum.career_summary?.profile);
    if (!education) return false;

    return education.some((edu: Education) => {
      const matchesUniversity = edu?.institution?.toLowerCase()?.includes(alum.university.name.toLowerCase());

      switch (degree) {
        case 'MBA':
          return matchesUniversity && (
            edu?.studyType?.includes('MBA')
            || edu?.studyType?.includes('Master of Business Administration')
          );
        case 'Master':
          return matchesUniversity
            && edu?.studyType?.includes('Master')
            && !edu?.studyType?.includes('Business Administration')
            && !edu?.studyType?.includes('MBA');
        default:
          return matchesUniversity && edu?.studyType?.includes(degree);
      }
    });
  };

  const matchesGraduationYearFilter = (alum: Alum, graduationYear: string): boolean => {
    if (!graduationYear) return true;

    const education = extractEducation(alum.career_summary?.profile);
    if (!education) return false;

    const universityEdu = education.find(edu => edu.institution.toLowerCase().includes(alum.university.name.toLowerCase()));

    if (!universityEdu?.endDate) return false;

    return new Date(universityEdu.endDate).getFullYear().toString() === graduationYear;
  };

  const matchesHiringSuccessFilter = (alum: Alum, hiringSuccess: string): boolean => {
    if (!hiringSuccess) return true;

    const isHiringSuccess = determineHiringSuccess(alum);
    return hiringSuccess === 'Yes' ? isHiringSuccess : !isHiringSuccess;
  };

  const matchesCareerOSUserFilter = (alum: Alum, isCareerosUser: string): boolean => {
    if (!isCareerosUser) return true;

    return isCareerosUser === 'Yes' ? alum.has_given_permission : !alum.has_given_permission;
  };

  // Main search function
  const searchAlumni = (filterParams: AlumniBookFiltersType) => {
    if (!alumniResponse) return;

    const filteredAlumni = alumniResponse.filter((alum: Alum) => matchesNameFilter(alum, filterParams.contactName)
      && matchesCurrentCompanyFilter(alum, filterParams.companies)
      && matchesPastCompanyFilter(alum, filterParams.pastCompanies)
      && matchesLocationFilter(alum, filterParams.locations)
      && matchesDegreeFilter(alum, filterParams.degree)
      && matchesGraduationYearFilter(alum, filterParams.graduationYear)
      && matchesHiringSuccessFilter(alum, filterParams.hiringSuccess)
      && matchesCareerOSUserFilter(alum, filterParams.isCareerosUser));

    setAlumni(filteredAlumni);
    setCurrentActiveId(filteredAlumni[0]?.id || '');
  };

  useDebounceEffect(() => {
    if (alumniResponse) {
      setAlumni(alumniResponse);
      getLocations(alumniResponse);
      getCompanies();
      setCurrentActiveId(alumniResponse[0]?.id || '');
    }
  }, [alumniResponse]);

  useEffect(() => {
    if (universityID && !isStudentsLoading) {
      searchAlumni(filters);
    }
  }, [filters, isStudentsLoading, universityID]);

  return {
    alumni: universityID ? alumni : [],
    filters,
    isLoading: !universityID || isStudentsLoading,
    currentActiveId,
    alumniCount: universityID ? alumni?.length || 0 : 0,
    totalAlumniCount: universityID ? alumniResponse?.length || 0 : 0,
    currentLoadingId,
    companies,
    locations,
    setFilters,
    setCurrentActiveId,
    setCurrentLoadingId,
    setAlumni,
  };
};
