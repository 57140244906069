import { useEffect, useState } from 'react';
import {
  useLoaderData, useNavigate, useParams,
} from 'react-router-dom';

import { Button } from '@careeros/coco';
import {
  CompaniesFilters,
  CompanyCard,
  CompanyCardContainerSkeleton,
  EmptySearchResult,
} from '@/domains/core/company';
import { Loader } from '@/components/loader/loader';
import { NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION } from '@/services/helpers/company';
import { useCompaniesFiltering } from '@/services/hooks/use-companies-filtering';
import {
  Company, CompanyLocation, FilteredCompaniesResponse, CompaniesFilterType,
} from '@/domains/core/company/types';
import { useApplications, useArchivedCompanies } from '@/services/queries/application';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useCheckExtension } from '@/services/hooks/use-check-extension';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './styles/companies.scss';

function FilterCompaniesPage() {
  const params = useParams();
  const {
    sizes, tags, locations,
  } = useLoaderData() as FilteredCompaniesResponse;
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { filterAttribute, filterValue } = params;
  const [hasNotSavedCompanies, setHasNotSavedCompanies] = useState(false);
  const [numberOfSavedCompanies, setNumberOfSavedCompanies] = useState(0);
  const {
    observerTarget, companies, isNewPageLoading, isLoading, handleFiltersChange,
  } = useCompaniesFiltering({ defaultFilterValue: filterValue, defaultFilterAttribute: filterAttribute });
  const { data: applications = [] } = useApplications();
  const { data: archivedCompanies = [] } = useArchivedCompanies();
  const [isCompanyLoactionHidden, setIsCompanyLoactionHidden] = useState(false);
  const { checkUserExtension } = useCheckExtension();

  const handleGoToAdvancedSearch = () => {
    navigate('/app/companies');
    trackEvent('Advanced Search Button Clicked', { location: 'Explore Companies Page' });
  };

  const handleFiltering = (filters: CompaniesFilterType, savedByUser: boolean | undefined, filterLocations: CompanyLocation[]) => {
    handleFiltersChange(filters, savedByUser, filterLocations);
    setIsCompanyLoactionHidden(filterLocations.length > 0);
  };

  useEffect(() => {
    setHasNotSavedCompanies(numberOfSavedCompanies < NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION);
  }, [numberOfSavedCompanies]);

  useEffect(() => {
    setNumberOfSavedCompanies(applications.length + archivedCompanies.length);
    if ((applications.length + archivedCompanies.length) < NUMBER_OF_COMPANIES_WITH_PULSE_ANIMATION) {
      setHasNotSavedCompanies(true);
    }
  }, [applications.length, archivedCompanies.length]);

  return (
    <div>
      <div className="companies-wrapper">
        <div className="companies-wrapper__sticky-container">
          <div className="companies-wrapper__top">
            <div className="companies-wrapper__header">
              <h1 className="companies-wrapper__title">
                {
                  filterAttribute === 'tag'
                    ? `Explore companies with tag ${filterValue}`
                    : filterValue
                }
              </h1>
            </div>
            <div className="companies-wrapper__button">
              <Button
                mode="primary"
                outlined
                size="medium"
                icon="bi bi-box-arrow-up-right"
                iconPosition="right"
                label="Advanced Search"
                handleClick={handleGoToAdvancedSearch}
              />
            </div>
          </div>

          <CompaniesFilters
            locations={locations}
            tags={tags}
            sizes={sizes}
            handleFiltersChange={handleFiltering}
            preserveSearchParams
          />
        </div>
        {(companies.length > 0 && !isLoading) && (
          <>
            <ul className="companies-listing">
              {companies.map((company: Company) => (
                <CompanyCard
                  key={company.id}
                  id={company.id}
                  companyName={company.name}
                  companyIndustry={company.careeros_industry}
                  officeLocations={company.locations || []}
                  companySize={company.company_size}
                  companyDescription={company.short_description ? company.short_description : company.overview}
                  imageURL={company.logo_url}
                  tags={company.tags}
                  companyApplicationId={applications?.find((application) => application.company_id === company.id)?.id || ''}
                  hasSavedAnimation={hasNotSavedCompanies}
                  setHasNotSavedCompanies={() => setNumberOfSavedCompanies(prevNumber => prevNumber + 1)}
                  isLocationHidden={isCompanyLoactionHidden}
                />
              ))}
            </ul>
            <div ref={observerTarget} />
            {isNewPageLoading && <div className="companies-wrapper__loader" />}
          </>
        )}

        {(!companies.length && !isLoading) && (
          <EmptySearchResult
            title="We could not find any results for your search."
            subtitle="We couldn’t quite find companies that match your filters. But don’t worry! You can head to LinkedIn and save any company using our Chrome Extension."
            checkExtension={checkUserExtension}
          />
        )}

        {isLoading && (
          <div className="companies-listing">
            <CompanyCardContainerSkeleton />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(FilterCompaniesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
