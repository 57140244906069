import { Tooltip } from '@careeros/coco';
import './suggested-contact-tag.scss';

type Props = {
  label: string;
  tooltipLabel: string;
  icon: string;
  color: 'purple' | 'green' | 'primary-blue';
  handleClick?: () => void;
};

export const SuggestedContactTag = ({
  label,
  tooltipLabel,
  icon,
  color,
  handleClick,
}: Props) => {
  const handleClickWrapper = (e: React.MouseEvent<HTMLDivElement>) => {
    if (handleClick) {
      e.stopPropagation();
      handleClick();
    }
  };

  return (
    <div onClick={handleClickWrapper} className={`suggested-contact-tag ${handleClick ? 'suggested-contact-tag--clickable' : ''}`}>
      <Tooltip textAlignment="left" position="top" withArrow label={tooltipLabel}>
        <div className={`suggested-contact-tag__content suggested-contact-tag__content--${color}`}>
          <i className={`suggested-contact-tag__icon ${icon}`} />
          <span className="suggested-contact-tag__label">{label}</span>
        </div>
      </Tooltip>
    </div>
  );
};
