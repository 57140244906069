import { useEffect, useState } from 'react';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { insightsClient } from '@algolia/client-insights';
import { Company, CompanyOfficeLocation } from '@/domains/core/company/types';
import { useApplicationsNoCache } from '../queries/application';
import { useGetSavedJobs } from '../queries/jobs';

// Add TypeScript declaration for window.aa
declare global {
  interface Window {
    aa: any;
  }
}

// Constants for Algolia
const ALGOLIA_APP_ID = import.meta.env.VITE_ALGOLIA_APP_ID || 'xxxxxxxx';
const ALGOLIA_API_KEY = import.meta.env.VITE_ALGOLIA_API_KEY || 'xxxxxxxx';

// Available Algolia indexes
export const ALGOLIA_INDEXES = {
  COMPANY: 'company',
  JOB: 'job',
  CONTACT: 'contact',
} as const;

export type AlgoliaIndex = typeof ALGOLIA_INDEXES[keyof typeof ALGOLIA_INDEXES];

// Create the Algolia client
export const algoliaClient = algoliasearch(
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
);

// Initialize Algolia Insights client
const insightsClientInstance = insightsClient(ALGOLIA_APP_ID, ALGOLIA_API_KEY);

// Create a search client with empty query handling for the search bar
export const searchClient = {
  ...algoliaClient,
  search(requests: any) {
    if (requests.every(({ params }: any) => !params?.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
        })),
      });
    }

    return algoliaClient.search(requests);
  },
};

// Types for Algolia Insights events
// Convert interface to type
interface UseAlgoliaRecommendationsProps {
  userId?: string;
  applications: any[];
  industry?: string;
}

// Hook to use Algolia Insights
export const useAlgoliaInsights = () => {
  /**
   * Track when a user clicks on an object
   */
  const clickedObjectIDs = async (
    eventName: string,
    index: AlgoliaIndex,
    objectIDs: string[],
    positions?: number[],
    userToken?: string,
  ): Promise<void> => {
    if (typeof window !== 'undefined') {
      if (objectIDs.length > 0) {
        insightsClientInstance.pushEvents({
          events: [{
            eventType: 'click',
            eventName,
            index,
            objectIDs,
            ...(positions && { positions }),
            userToken: userToken || 'anonymous',
            ...(userToken && { authenticatedUserToken: userToken }),
          }],
        });
      }
    }
  };

  /**
   * Track when a user converts on an object
   */
  const convertedObjectIDs = async (
    eventName: string,
    index: AlgoliaIndex,
    objectIDs: string[],
    userToken?: string,
  ): Promise<void> => {
    if (typeof window !== 'undefined') {
      if (objectIDs.length > 0) {
        insightsClientInstance.pushEvents({
          events: [{
            eventType: 'conversion',
            eventName,
            index,
            objectIDs,
            userToken: userToken || 'anonymous',
            ...(userToken && { authenticatedUserToken: userToken }),
          }],
        });
      }
    }
  };

  /**
   * Track when a user views an object
   */
  const viewedObjectIDs = async (
    eventName: string,
    index: AlgoliaIndex,
    objectIDs: string[],
    userToken?: string,
  ): Promise<void> => {
    if (typeof window !== 'undefined') {
      if (objectIDs.length > 0) {
        insightsClientInstance.pushEvents({
          events: [{
            eventType: 'view',
            eventName,
            index,
            objectIDs,
            userToken: userToken || 'anonymous',
            ...(userToken && { authenticatedUserToken: userToken }),
          }],
        });
      }
    }
  };

  /**
   * Track when a user adds an object to cart
   */
  const addedToCartObjectIDs = async (
    eventName: string,
    index: AlgoliaIndex,
    objectIDs: string[],
    userToken?: string,
  ): Promise<void> => {
    if (typeof window !== 'undefined') {
      if (objectIDs.length > 0) {
        insightsClientInstance.pushEvents({
          events: [{
            eventType: 'conversion',
            eventName,
            index,
            objectIDs,
            userToken: userToken || 'anonymous',
            ...(userToken && { authenticatedUserToken: userToken }),
          }],
        });
      }
    }
  };

  /**
   * Track when a user purchases an object
   */
  const purchasedObjectIDs = async (
    eventName: string,
    index: AlgoliaIndex,
    objectIDs: string[],
    userToken?: string,
  ): Promise<void> => {
    if (typeof window !== 'undefined') {
      if (objectIDs.length > 0) {
        insightsClientInstance.pushEvents({
          events: [{
            eventType: 'conversion',
            eventName,
            index,
            objectIDs,
            userToken: userToken || 'anonymous',
            ...(userToken && { authenticatedUserToken: userToken }),
          }],
        });
      }
    }
  };

  return {
    clickedObjectIDs,
    convertedObjectIDs,
    addedToCartObjectIDs,
    purchasedObjectIDs,
    viewedObjectIDs,
  };
};

export const useAlgoliaRecommendations = ({
  userId,
  applications,
  industry,
}: UseAlgoliaRecommendationsProps) => {
  const [recommendations, setRecommendations] = useState<Company[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [queryID, setQueryID] = useState<string>('');
  const insights = useAlgoliaInsights();

  const getRecommendations = async () => {
    if (!userId) return;

    setIsLoading(true);
    try {
      // Create search parameters
      const searchParams: any = {
        query: '',
        hitsPerPage: 30,
        getRankingInfo: true,
        analytics: false,
        // enablePersonalization: true,
        userToken: userId,
        clickAnalytics: true,
      };

      // Add industry filter if provided
      if (industry) {
        searchParams.filters = `careeros_industry:"${industry}"`;
      }

      const results = await algoliaClient.search([{
        indexName: ALGOLIA_INDEXES.COMPANY,
        params: searchParams,
      }]);

      // Store queryID for tracking events
      if (results.results[0] && (results.results[0] as any).queryID) {
        setQueryID((results.results[0] as any).queryID);
      }

      // Extract hits from the response
      const { hits } = results.results[0] as any;

      // Filter out companies that user has already applied to
      const notAppliedCompanies = hits.filter(
        (hit: any) => !applications.some((application) => application.company_id === hit.id),
      );

      const getLocationFromString = (locationString: string): CompanyOfficeLocation => {
        const [city = '', country = ''] = locationString?.split(',').map(part => part.trim()) ?? [];
        return {
          city,
          country,
          country_name: country,
          is_hq: true,
        };
      };

      const recommendationsWithCompanyLocations = notAppliedCompanies.map((company: any) => ({
        ...company,
        locations: company.location ? [getLocationFromString(company.location)] : [],
        tags: company.tags ? company.tags.split(',') : [],
      }));

      setRecommendations(recommendationsWithCompanyLocations);
    } catch (error) {
      console.error('Error fetching Algolia recommendations:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Track when a user clicks on a recommendation
  const trackRecommendationClick = (objectID: string, position: number) => {
    insights.clickedObjectIDs(
      'Recommendation Clicked',
      ALGOLIA_INDEXES.COMPANY,
      [objectID],
      [position],
      userId,
    );
  };

  // Track when a user converts on a recommendation (e.g., applies to a job)
  const trackRecommendationConversion = (objectID: string, eventName: string = 'Company Application') => {
    insights.convertedObjectIDs(
      eventName,
      ALGOLIA_INDEXES.COMPANY,
      [objectID],
      userId,
    );
  };

  // Track when a user views a recommendation
  const trackRecommendationView = (objectIDs: string[]) => {
    insights.viewedObjectIDs(
      'Recommendation Viewed',
      ALGOLIA_INDEXES.COMPANY,
      objectIDs,
      userId,
    );
  };

  return {
    recommendations,
    isLoading,
    getRecommendations,
    trackRecommendationClick,
    trackRecommendationConversion,
    trackRecommendationView,
    queryID,
    indexName: ALGOLIA_INDEXES.COMPANY,
  };
};

type UseAlgoliaSearchProps = {
  indexName?: AlgoliaIndex;
  userId?: string;
};

export const useAlgoliaSearch = ({
  indexName = ALGOLIA_INDEXES.COMPANY,
  userId,
}: UseAlgoliaSearchProps = {}) => {
  const [query, setQuery] = useState('');
  const [queryID, setQueryID] = useState<string>('');
  const insights = useAlgoliaInsights();

  // Track when a user clicks on a search result
  const trackSearchResultClick = (objectID: string, position: number) => {
    insights.clickedObjectIDs(
      'Search Result Clicked',
      indexName,
      [objectID],
      [position],
      userId,
    );
  };

  // Track when a user converts on a search result (e.g., applies to a job)
  const trackSearchResultConversion = (objectID: string, eventName: string = 'Company Application') => {
    insights.convertedObjectIDs(
      eventName,
      indexName,
      [objectID],
      userId,
    );
  };

  // Track when a user views search results
  const trackSearchResultView = (objectIDs: string[]) => {
    insights.viewedObjectIDs(
      'Search Result Viewed',
      indexName,
      objectIDs,
      userId,
    );
  };

  // Track when a user clicks on filters
  const trackFilterClick = (filters: string[]) => {
    insights.clickedObjectIDs(
      'Filter Clicked',
      indexName,
      filters,
      undefined,
      userId,
    );
  };

  // Track when a user converts after using filters
  const trackFilterConversion = (filters: string[], eventName: string = 'Filter Conversion') => {
    insights.convertedObjectIDs(
      eventName,
      indexName,
      filters,
      userId,
    );
  };

  // Track when a user views filters
  const trackFilterView = (filters: string[]) => {
    insights.viewedObjectIDs(
      'Filter Viewed',
      indexName,
      filters,
      userId,
    );
  };

  // Update queryID when search results are received
  const handleSearchResults = (results: any) => {
    if (results.results && results.results[0] && results.results[0].queryID) {
      setQueryID(results.results[0].queryID);
    }
  };

  return {
    query,
    setQuery,
    searchClient,
    trackSearchResultClick,
    trackSearchResultConversion,
    trackSearchResultView,
    trackFilterClick,
    trackFilterConversion,
    trackFilterView,
    handleSearchResults,
    queryID,
    indexName,
  };
};

export const useRelatedCompanies = (companyId: string) => {
  const [relatedCompanies, setRelatedCompanies] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: applications, isLoading: isApplicationsLoading } = useApplicationsNoCache();
  useEffect(() => {
    async function fetchRelatedCompanies() {
      if (!companyId) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);

        // Step 1: Get the objectID for the company
        const objectID = companyId;
        if (!objectID || isApplicationsLoading) {
          setIsLoading(false);
          return;
        }

        // Get IDs of companies the user has already saved
        const savedCompanyIds = applications ? applications
          .map((app: any) => app.company_id)
          .filter(Boolean) : [];

        // Add the current company to the exclusion list
        const excludedIds = [...savedCompanyIds, companyId];

        const response = await algoliaClient.getRecommendations({
          requests: [
            {
              indexName: ALGOLIA_INDEXES.COMPANY,
              objectID,
              model: 'related-products',
              threshold: 0,
              // Get more recommendations since we'll be filtering some out
              maxRecommendations: 50,
              queryParameters: {
                clickAnalytics: true,
                attributesToRetrieve: [
                  'id', 'name', 'careeros_industry', 'location',
                  'size', 'logo_url', 'overview', 'tags',
                ],
              },
            },
          ],
        });

        if (response && response.results && response.results[0] && response.results[0].hits) {
          const filteredResults = response.results[0].hits
            .filter((company: any) => !excludedIds.includes(company.id))
            .slice(0, 10)
            .map((company: any) => ({
              ...company,
              tags: company.tags ? company.tags.split(',') : [],
            }));

          setRelatedCompanies(filteredResults);
        }
      } catch (err) {
        console.error('Error fetching related companies:', err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchRelatedCompanies();
  }, [companyId, isApplicationsLoading]);

  return { relatedCompanies, isLoading };
};

export const useRelatedJobs = (jobID: string) => {
  const [relatedJobs, setRelatedJobs] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { data: savedJobs, isLoading: areJobsLoading } = useGetSavedJobs();

  useEffect(() => {
    async function fetchRelatedJobs() { // TODO: refactor and simplify outside useEffect
      if (!jobID) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);

        // Step 1: Get the objectID for the company
        const objectID = jobID;
        if (!objectID || areJobsLoading) {
          setIsLoading(false);
          return;
        }

        // Get IDs of jobs the user has already saved
        const savedJobIds = Array.isArray(savedJobs?.jobs) ? savedJobs.jobs
          .map((job: any) => job.id)
          .filter(Boolean) : [];

        // Add the current company to the exclusion list
        const excludedIds = [...savedJobIds, jobID];

        const response = await algoliaClient.getRecommendations({
          requests: [
            {
              indexName: ALGOLIA_INDEXES.JOB,
              objectID,
              model: 'related-products',
              threshold: 0,
              // Get more recommendations since we'll be filtering some out
              maxRecommendations: 50,
              queryParameters: {
                clickAnalytics: true,
                attributesToRetrieve: [
                  'id', 'objectID', 'title', 'company_name',
                  'company_logo', 'location', 'job_type', 'remote',
                  'job_language', 'created_at', 'posted_on',
                ],
                filters: 'is_active:ACTIVE',
              },
            },
          ],
        });

        if (response && response.results && response.results[0] && response.results[0].hits) {
          const filteredResults = response.results[0].hits
            .filter((company: any) => !excludedIds.includes(company.id))
            .slice(0, 10); // Limit to 6 results

          setRelatedJobs(filteredResults);
        }
      } catch (err) {
        console.error('Error fetching related jobs:', err);
      } finally {
        setIsLoading(false);
      }
    }

    fetchRelatedJobs();
  }, [jobID, areJobsLoading]);

  return { relatedJobs, isLoading };
};
