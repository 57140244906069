import { ReactNode, CSSProperties } from 'react';
import './challenge-progress.scss';
import { hexToRgba } from '@/services/helpers/colors';

interface ChallengeProps {
  completedText: string;
  progress: number;
  badge: string
  color: string;
  maxProgress: number;
  children: ReactNode;
}

export const ChallengeProgress = ({
  completedText,
  progress,
  badge,
  color,
  maxProgress,
  children,
}: ChallengeProps) => {
  const isChallengeCompleted = progress >= maxProgress;
  const shadowColor = hexToRgba(color, 0.1);

  return (
    <div
      style={{ '--badge-color': color, '--badge-shadow-color': shadowColor } as CSSProperties}
      className={`challenge-progress ${isChallengeCompleted ? 'challenge-progress--completed' : ''}`}
    >
      <img src={badge} alt="" className="challenge-progress__badge" />
      <p className="challenge-progress__description">
        {isChallengeCompleted ? completedText : children}
      </p>
      {!isChallengeCompleted ? (
        <>
          <div className="challenge-progress__progress-container">
            <div
              className="challenge-progress__progress-bar"
              style={{ width: progress === 0 ? '12px' : `${(progress / maxProgress) * 100}%` }}
            />
          </div>
          <span className="challenge-progress__progress-meter">
            {`${progress}/${maxProgress}`}
          </span>
        </>
      ) : (
        <span className="challenge-progress__progress-meter challenge-progress__progress-meter--completed">
          {`${maxProgress}/${maxProgress}`}
        </span>
      )}
    </div>
  );
};
