import {
  useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import './tracker-contacts-tab.scss';
import { Button, InfoModal } from '@careeros/coco';
import { ContactTabStates } from '@/domains/core/tracker-data';
import { useSelfUniversity } from '@/services/queries/user';
import { useArchiveContact } from '@/services/hooks/use-archive-contact';
import { SuggestedContactType } from '@/domains/core/contact/types';
import { useCompanyContacts } from '@/services/hooks/use-company-contacts';
import { useLinkedInSuggestedContacts } from '@/services/hooks/use-linkedin-suggested-contacts';
import { LinkedinSuggestedContacts } from '../linkedin-suggested-contacts/linkedin-suggested-contacts';
import { ModalContext } from '@/components/modal/modal-provider';
import { linkedinSuggestedContactsErrorContent, LinkedInSuggestedErrorType } from '../linkedin-suggested-contacts-error/error-content';
import { GlassScrollContainer } from '../../containers/tracker-contacts-container/glass-scroll-container';
import { isMobileDevice } from '@/services/helpers/responsive';
import { SuggestedContactTileWithPreview } from '@/domains/core/contact/components/contact-tile-with-preview/suggetsed-contact-tile-with-preview';
import { SavedContactTileWithPreview } from '@/domains/core/contact/components/contact-tile-with-preview/saved-contact-tile-with-preview';

type TrackerContactTabProps = {
  companyLinkedIn: string;
  companyId: string;
  companyName: string;
  tabState?: ContactTabStates;
  setSavedContactsCount: React.Dispatch<React.SetStateAction<number>>;
  companyLogoUrl: string;
  updateUnreadIndicator: (isUnread: boolean) => void;
  openTrackerTab: (tab: string, state?: string, setTabAsCurrent?: boolean) => void;
};

const MIN_NUMBER_OF_SUGGESTED_CONTACTS = 5;

export const TrackerContactTab = ({
  companyId,
  companyName,
  tabState,
  companyLogoUrl,
  companyLinkedIn,
  setSavedContactsCount,
  updateUnreadIndicator,
  openTrackerTab,
}: TrackerContactTabProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const queryClient = useQueryClient();
  const revaildateCompanyContactsAfterArchive = () => {
    queryClient.invalidateQueries(['company-saved-contacts', companyId]);
  };
  const [shouldShowLinkedInSuggestedContacts, setShouldShowLinkedInSuggestedContacts] = useState(false);
  const [showLinkedInSuggestedContacts, setShowLinkedInSuggestedContacts] = useState(false);
  const { requestArchiveConfirmation } = useArchiveContact(revaildateCompanyContactsAfterArchive);
  const {
    computedSuggestedContacts,
    handleReachOutSuggestedContact,
    handleSaveSuggestedContact,
    savedContacts,
    isLoadingSavedContacts,
    isLoadingSuggestedContacts,
    aggregatedLinkedInUrls,
  } = useCompanyContacts(companyId);
  const {
    getLinkedInSuggestedContacts,
    saveLinkedInSuggestedContact,
    linkedInSuggestedContacts,
    isLoggedIntoLinkedIn,
    isError: isErrorForLinkedInSuggested,
    exensionIsInstalled,
    isLoadingLinkedInSuggestedContacts,
    companyLinkedInID,
  } = useLinkedInSuggestedContacts({
    companyId, companyLinkedIn, companyName, savedContactsUrls: aggregatedLinkedInUrls,
  });
  const { data: university } = useSelfUniversity();
  const navigate = useNavigate();
  const showSuggestedBlockSubtitle = useMemo(
    () => (computedSuggestedContacts.length > 0 || (linkedInSuggestedContacts.length > 0 && !isErrorForLinkedInSuggested)),
    [computedSuggestedContacts.length, linkedInSuggestedContacts.length, isErrorForLinkedInSuggested],
  );

  const goToContact = (id: string, isProfileOpen?: boolean) => {
    if (isProfileOpen) {
      navigate(`/app/inbox/${id}?open-sidebar=true`);
    } else {
      navigate(`/app/inbox/${id}`);
    }
  };

  const handleInstallExtension = () => {
    window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
    closeModal();
  };

  const handleLoginLinkedIn = () => {
    window.open('https://www.linkedin.com/', '_blank');
    closeModal();
  };

  const goToCompanyContactsLinkedIn = () => {
    const searchContactsLink = companyLinkedInID
      ? `https://www.linkedin.com/search/results/people/?currentCompany=%5B"${companyLinkedInID}"%5D&origin=COMPANY_PAGE_CANNED_SEARCH`
      : `${companyLinkedIn}/people`;
    window.open(searchContactsLink, '_blank');
  };

  const goToCompanyTab = () => {
    openTrackerTab('Company', undefined, true);
  };

  const openInfoModal = () => {
    const errorType: LinkedInSuggestedErrorType = exensionIsInstalled ? 'loginLinkedIn' : 'downloadExtension';
    const handleButtonClick = errorType === 'loginLinkedIn' ? handleLoginLinkedIn : handleInstallExtension;

    openModal(
      <InfoModal
        title={linkedinSuggestedContactsErrorContent[errorType].title}
        description={linkedinSuggestedContactsErrorContent[errorType].description}
        buttonLabel={linkedinSuggestedContactsErrorContent[errorType].buttonLabel}
        buttonIcon={linkedinSuggestedContactsErrorContent[errorType].buttonIcon}
        primaryButtonIconPosition="right"
        image={linkedinSuggestedContactsErrorContent[errorType].image}
        handleButtonClick={handleButtonClick}
      />,
    );
  };

  const goToCampusChampionsPage = () => {
    navigate('/app/contacts/campus-champions');
  };

  const handleBeforeOpenLinkedInSuggestedContacts = useCallback(() => {
    if (isErrorForLinkedInSuggested) {
      openInfoModal();
      return;
    }

    setShowLinkedInSuggestedContacts(true);
  }, [isErrorForLinkedInSuggested, isLoggedIntoLinkedIn, exensionIsInstalled]);

  useEffect(() => {
    if (tabState === 'addContact') {
      document.querySelector('.tracker-overlay')?.scrollTo({ top: 500, behavior: 'smooth' });
    }
  }, [tabState]);

  useEffect(() => {
    setSavedContactsCount(savedContacts.length);
  }, [savedContacts.length]);

  useEffect(() => {
    if (isLoadingSavedContacts || isLoadingSuggestedContacts) {
      return;
    }
    updateUnreadIndicator(computedSuggestedContacts.length > 0);

    if (computedSuggestedContacts.length < MIN_NUMBER_OF_SUGGESTED_CONTACTS) {
      setShouldShowLinkedInSuggestedContacts(true);
    } else {
      setShouldShowLinkedInSuggestedContacts(false);
    }

    if (computedSuggestedContacts.length === 0 && !showLinkedInSuggestedContacts) {
      setShowLinkedInSuggestedContacts(true);
    } else {
      setShowLinkedInSuggestedContacts(false);
    }
  }, [computedSuggestedContacts.length, isLoadingSavedContacts, isLoadingSuggestedContacts]);

  useEffect(() => {
    if (showLinkedInSuggestedContacts && !isErrorForLinkedInSuggested) {
      getLinkedInSuggestedContacts();
    }
  }, [showLinkedInSuggestedContacts, isErrorForLinkedInSuggested]);

  return (
    <div className="contacts-tab">
      <div className="contacts-tab__computed-contacts">
        {savedContacts?.length > 0 && (
          <>
            <GlassScrollContainer
              title="Saved Contacts"
              tooltipLabel="Contacts you’ve saved for future outreach and follow-ups."
            >
              {(isLoadingSavedContacts && !savedContacts.length) && (
                <div className="contacts-tab__loader-container">
                  <div className="job-tabs__loader" />
                  <p className="contacts-tab__loader-text">
                    Loading saved contacts...
                  </p>
                </div>
              )}
              {savedContacts.map((contact) => (
                <SavedContactTileWithPreview
                  key={contact.id}
                  contact={contact}
                  companyName={companyName}
                  university={university}
                  isMobileDevice={isMobileDevice}
                  companyLogoUrl={companyLogoUrl}
                  goToCampusChampionsPage={goToCampusChampionsPage}
                  requestArchiveConfirmation={() => requestArchiveConfirmation(contact.id)}
                  goToContact={(isProfileOpen) => goToContact(contact.id, isProfileOpen)}
                  goToCompany={goToCompanyTab}
                />
              ))}
            </GlassScrollContainer>
            <div className="contacts-tab__separator" />
          </>
        )}
        <GlassScrollContainer
          title="Save Suggested Networking Contacts"
          tooltipLabel={(
            <>
              You will typically see contacts that have some shared past experience with you, e.g. they are alumni from your university, have previously worked at the same company as you, live in the same area, or all of the above.
              <br />
              {' '}
              When reaching out to a contact, make sure to personalize your message.
            </>
          )}
          subtitle="Our AI thinks these contacts have the highest likelihood of replying to you (and helping you out 🚀)."
          withSubtitle={showSuggestedBlockSubtitle}
          handleButtonClick={goToCompanyContactsLinkedIn}
          withButton={(computedSuggestedContacts.length > 0 || (showLinkedInSuggestedContacts && linkedInSuggestedContacts.length > 0)) && !isMobileDevice}
        >
          {computedSuggestedContacts?.map((contact: SuggestedContactType) => (
            <SuggestedContactTileWithPreview
              key={contact.id}
              contact={contact}
              companyName={companyName}
              university={university}
              isMobileDevice={isMobileDevice}
              handleReachOutSuggestedContact={() => handleReachOutSuggestedContact(contact.contact_id)}
              handleSaveSuggestedContact={() => handleSaveSuggestedContact(contact.contact_id)}
              companyLogoUrl={companyLogoUrl}
              goToCampusChampionsPage={goToCampusChampionsPage}
              goToCompany={goToCompanyTab}
            />
          ))}

          {(isLoadingSuggestedContacts && !computedSuggestedContacts.length) && (
            <div className="contacts-tab__loader-container">
              <div className="contacts-tab__loader" />
              <p className="contacts-tab__loader-text">
                Loading suggested contacts...
              </p>
            </div>
          )}

          {(shouldShowLinkedInSuggestedContacts && !showLinkedInSuggestedContacts && !isMobileDevice) && (
            <div className="contacts-tab__generate-button">
              <Button
                label="Generate Contacts from LinkedIn"
                icon="bi bi-arrow-repeat"
                handleClick={handleBeforeOpenLinkedInSuggestedContacts}
                mode="primary"
                outlined
                size="medium"
              />
            </div>
          )}

          {shouldShowLinkedInSuggestedContacts && showLinkedInSuggestedContacts && !isMobileDevice && (
            <LinkedinSuggestedContacts
              companyName={companyName}
              companyLogoUrl={companyLogoUrl}
              isLoading={isLoadingLinkedInSuggestedContacts}
              isError={isErrorForLinkedInSuggested}
              filteredSuggestions={linkedInSuggestedContacts}
              extensionIsInstalled={exensionIsInstalled}
              isLoggedIntoLinkedIn={isLoggedIntoLinkedIn}
              handleSaveSuggestedContact={saveLinkedInSuggestedContact}
              isLoadingMinified={computedSuggestedContacts.length > 0}
            />
          )}

          {isMobileDevice && (!isLoadingSuggestedContacts && !computedSuggestedContacts.length) && (
            <div className="contacts-tab__loader-container">
              <p className="contacts-tab__loader-text">
                You don&apos;t have any suggested contacts yet.
              </p>
            </div>
          )}
        </GlassScrollContainer>
      </div>
    </div>
  );
};
