import { useMemo } from 'react';
import { UniversityResponse } from '@/services/api/student';
import { ContactWithNetworkingData } from '../../types';
import { ContactTileCard } from '../contact-tile-card/contact-tile-card';
import { mapSavedContactToContactPreviewCard } from '../../utils/contact-to-preview-parser';
import { PreviewCardWrapper } from '@/domains/generic/preview-card/preview-card-wrapper/preview-card-wrapper';
import { ContactPreviewCard } from '../contact-preview-card/contact-preview-card';
import { splitAndCapitalize } from '@/services/helpers/string';

type Props = {
  contact: ContactWithNetworkingData;
  companyName: string;
  university?: UniversityResponse;
  isMobileDevice: boolean;
  companyLogoUrl: string;
  goToCampusChampionsPage: () => void;
  requestArchiveConfirmation: () => void;
  goToContact: (isProfileOpen?: boolean) => void;
  goToCompany?: () => void;
};

export const SavedContactTileWithPreview = ({
  contact,
  companyName,
  university,
  isMobileDevice,
  companyLogoUrl,
  goToCampusChampionsPage,
  requestArchiveConfirmation,
  goToContact,
  goToCompany,
}: Props) => {
  const contactPreviewCardData = useMemo(() => mapSavedContactToContactPreviewCard(contact), [contact]);
  return (
    <ContactTileCard
      key={contact.id}
      id={contact.id}
      name={`${contact.first_name} ${contact.last_name}`}
      position={contact.current_position.title}
      image={contact.career_summary.basics.image}
      companyName={companyName}
      schoolName={university?.name || 'your school'}
      handleMainButtonAction={isMobileDevice ? undefined : () => goToContact(false)}
      handleSecondaryButtonAction={requestArchiveConfirmation}
      isCampusChampion={contact.is_campus_champion}
      languages={splitAndCapitalize(contact.matched_languages || '')}
      isSecondaryButtonDanger
      secondaryButtonLabel="Archive"
      secondaryButtonIcon="bi bi-archive"
      linkedinConnectionStatus={contact.linkedin_connection_status || 'notConnected'}
      networkingStatus={('networking_status' in contact) ? contact.networking_status : undefined}
      networkingStatusRate={contact.match_criteria?.includes('desired_job') ? 1 : 0}
      isAlumni={contact.match_criteria?.includes('alumni')}
      showLanguages={contact.match_criteria?.includes('languages')}
      companyLogo={companyLogoUrl}
      campusChampionClickHandler={goToCampusChampionsPage}
    >
      {!isMobileDevice && (
        <ContactTileCard.NameWithPreview>
          <PreviewCardWrapper
            name={contactPreviewCardData.title}
            className="suggested-contact-tile__name-text"
            popupFitToScreenHeight
          >
            <PreviewCardWrapper.Preview>
              <ContactPreviewCard
                title={contactPreviewCardData.title}
                expandButtonLabel="See full profile"
                onExpandButtonClick={() => goToContact(true)}
                currentPosition={contactPreviewCardData.currentPosition}
                previousPositions={contactPreviewCardData.previousPositions}
                headline={contactPreviewCardData.headline}
                networkingStatus={contactPreviewCardData.networkingStatus}
                linkedInConnectionStatus={contactPreviewCardData.linkedInConnectionStatus}
                languages={contactPreviewCardData.languages}
                onCurrentCompanyClick={goToCompany}
              />
            </PreviewCardWrapper.Preview>
          </PreviewCardWrapper>
        </ContactTileCard.NameWithPreview>
      )}
    </ContactTileCard>
  );
};
