import ReactQuill from 'react-quill';
import { Button, InfoModal, DefaultLogo } from '@careeros/coco';
import './comment-input-box.scss';
import {
  useContext, useMemo, useRef, useState,
} from 'react';
import '@/services/helpers/quill-config';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplatesModal } from '@/domains/core/netwoking/components';
import { ContactPlaceholders } from '@/domains/core/netwoking/types';
import { AITemplatesButton } from '@/domains/core/student/components/use-ai-button/templates-button';
import { useAdvisorTemplates } from '@/services/queries/template';
import { Template } from '@/domains/core/advisor/types/template';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { convertNewLinesToHtml, findTextInBrackets } from '@/services/helpers/messages';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';

type Props = {
  handleSendComment: (comment: string, studentId?: string, studentEmail?: string) => void;
  companyName?: string;
  companyLogo?: string;
  title: string;
  studentFirstName: string,
  studentLastName: string,
  closeCommentBox: () => void;
  showAITemplates?: boolean;
  defaultMessage?: string;
  withCloseButton?: boolean;
};

export const CommentInputBox = ({
  handleSendComment,
  companyName,
  companyLogo,
  studentFirstName,
  studentLastName,
  title,
  closeCommentBox,
  showAITemplates = false,
  defaultMessage,
  withCloseButton = false,
}: Props) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);
  const [comment, setComment] = useState(defaultMessage || '');
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const studentPlaceholders = useMemo<ContactPlaceholders>(() => ({
    FirstName: studentFirstName,
    LastName: studentLastName,
  }), []);
  const commentLength = useMemo(() => reactQuillRef.current?.getEditor().getText().trim().length || 0, [comment, reactQuillRef.current]);

  const { data: templates } = useAdvisorTemplates();
  const validatedTemplates: Template[] = useMemo<Template[]>(() => (Array.isArray(templates) ? templates : []), [templates]);

  const templateListOptions = useMemo(() => validatedTemplates.map((template: Template) => ({
    id: template.id,
    name: template.name,
    type: template.type,
  })), [validatedTemplates]);

  const checkTextForPlaceholders = () => {
    const hasPlaceholders = comment.match(/\[[^[\]]*\]/g);

    if (hasPlaceholders) {
      addToast({
        type: 'error',
        message: 'Please fill in all placeholders',
        additionalMessage:
          'Please fill in all placeholders before sending the email.',
      });
    }

    return hasPlaceholders;
  };

  const handleSendCommentClick = () => {
    const havePlaceholders = checkTextForPlaceholders();
    if (havePlaceholders) return;
    handleSendComment(comment);
    setComment('');
    trackEvent('CA Student OB General Comment Sent');
  };

  const applyCustomTemplate = (template: string) => {
    setComment(template);
    closeModal();
    trackEvent('CA Comment Custom Template Used');
  };

  const handlePredefinedTemplateClick = (_option: string, _outreachType: string, id?: string) => {
    const tpl = validatedTemplates.find((t: Template) => t.id === id);
    if (!tpl) {
      return;
    }

    setComment(convertNewLinesToHtml(tpl.message));
  };

  const handleCustomTemplatesClick = () => {
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={studentPlaceholders}
        previewType="comment"
        selectTemplate={applyCustomTemplate}
        trackEventText="CA Comment Custom Templated Created"
      />,
      {
        closeOnClickOutside: false,
      },
    );
    trackEvent('CA Comment Custom Templates Opened');
  };

  const handleSubmitCommentRemoval = () => {
    closeCommentBox();
    closeModal();
  };

  const beforeCloseCommentBox = () => {
    if (commentLength) {
      openModal(
        <InfoModal
          title="Discard Message"
          description="Looks like you’re about to discard your message. If you discard now, your message will be permanently lost. How about giving it a quick review and sending it off?"
          icon="bi bi-exclamation-triangle"
          buttonLabel="Keep Writing"
          buttonIcon="bi bi-pencil-square"
          secondaryButtonIcon="bi bi-trash"
          secondaryButtonLabel="Delete Message"
          isSecondButtonDanger
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={handleSubmitCommentRemoval}
          isImageDanger
          isButtonOutlined
          orientation="horizontal"
        />,
      );
    } else {
      closeCommentBox();
    }
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }

    const quillEditor = reactQuillRef.current.getEditor();
    const bracketedParts = findTextInBrackets(quillEditor.getText());

    quillEditor.formatText(0, quillEditor.getText().length, {
      color: false,
    });

    bracketedParts.forEach((part) => {
      quillEditor.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
      });
    });
  };

  useDebounceEffect(() => {
    if (comment) {
      highlightPlaceholders();
    }
  }, [comment]);

  return (
    <div className="comment-input-box">
      {withCloseButton && (
        <div className="comment-input-box__close-button" onClick={beforeCloseCommentBox}>
          <i className="bi bi-x-lg" />
        </div>
      )}
      <div className="comment-input-box__header">
        <div className="comment-input-box__image">
          {(companyLogo && companyName) ? (
            <DefaultLogo
              source={companyLogo}
              name={companyName || 'Company'}
              size={24}
              type="company"
              className="comment-input-box__header-logo"
            />
          ) : (
            <i className="comment-input-box__header-icon bi bi-chat-square-text" />
          )}
        </div>
        <div className="comment-input-box__title">{title}</div>
      </div>
      <div className="comment-input-box__text-field">
        <ReactQuill
          ref={reactQuillRef}
          modules={{ toolbar: null }}
          theme="snow"
          value={comment}
          placeholder="Write comment"
          onChange={setComment}
          style={{
            border: 'none',
            padding: '0',
            height: '240px',
          }}
        />
      </div>
      <div className="comment-input-box__template-buttons">
        {showAITemplates && (
          <AITemplatesButton
            options={templateListOptions}
            size="small"
            buttonMode="blue"
            listPosition="left"
            handleOptionClick={handlePredefinedTemplateClick}
            isLoading={false}
            buttonIcon="bi bi-file-text"
            buttonLabel="Add Template"
            isOutlined
          />
        )}
        <Button
          label="Custom Templates"
          icon="bi bi-tools"
          handleClick={handleCustomTemplatesClick}
          mode="blue"
          size="medium"
          outlined
        />
      </div>
      <div className="comment-input-box__separator" />
      <div className="comment-input-box__send-button">
        <Button
          label="Send Comment"
          icon="bi bi-send"
          handleClick={handleSendCommentClick}
          disabled={!commentLength}
          mode="primary"
          size="medium"
        />
      </div>
    </div>
  );
};
