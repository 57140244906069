import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { useTabs } from '@/components/tabs/TabsContext';
import './contact-description.scss';
import Table from '@/components/table/table';
import { ContactHeader } from '../contact-header/contact-header';
import { TableRow } from '@/components/table/table-types';
import { ContactSummary } from '@/domains/core/contact';
import { SelectedContactType } from '@/domains/core/advisor/types';
import { ContactBookContactsTableColumns, mapStudentsToContactTableData } from '../../utils/contact-book-table';
import { useSelfUniversity } from '@/services/queries/user';

type Props = {
  selectedContact: SelectedContactType | null;
  isSaved: boolean;
  handleSaveAction: (contactId: string) => void;
  handleUnsaveAction: (contactId: string) => void;
};

const ContactDescription = ({
  selectedContact,
  isSaved,
  handleSaveAction,
  handleUnsaveAction,
}: Props) => {
  const tabs = ['Student Interactions', 'Contact Profile'];
  const { setCurrentTab } = useTabs();
  const navigate = useNavigate();
  const { data: university } = useSelfUniversity();
  const descriptionScrollRef = useRef<HTMLDivElement>(null);
  const [contactTableData, setContactTableData] = useState<TableRow[]>([]);

  const handleNavigationToStudentProfile = (id: string) => {
    navigate(`/app/advisor/students/student-overview/${id}/university/${university?.id}?tab=Profile`);
  };

  useEffect(() => {
    if (selectedContact?.student_interactions === null) {
      setContactTableData([]);
    }
    if (selectedContact?.student_interactions) {
      setContactTableData(mapStudentsToContactTableData(selectedContact.student_interactions, handleNavigationToStudentProfile, university?.id));
    }
    if (descriptionScrollRef.current) {
      descriptionScrollRef.current.scrollTop = 0;
    }
    setCurrentTab(tabs[0]);
  }, [selectedContact]);

  if (!selectedContact) {
    return null;
  }

  return (
    <div className="contact-description">
      <ContactHeader
        handleSaveButtonClick={isSaved ? handleUnsaveAction : handleSaveAction}
        isSaved={isSaved}
        badges={selectedContact?.badges}
        image={selectedContact?.profile_picture_url}
        companyName={selectedContact?.current_position?.company}
        position={selectedContact?.current_position?.title}
        name={`${selectedContact?.first_name} ${selectedContact?.last_name}`}
        id={selectedContact?.id}
        email={selectedContact?.email}
        linkedInUrl={selectedContact?.linkedin_url}
      />
      <div className="contact-description__bottom">
        <TabsHeader
          withBorder
          tabs={tabs}
          containerName="description"
          defaultTab={tabs[0]}
        />
        <div
          className="contact-description__tabs-content"
          ref={descriptionScrollRef}
        >
          <TabsContent>
            <div
              className="contact-description__description"
              data-tab="Student Interactions"
            >
              <Table
                data={contactTableData}
                columns={ContactBookContactsTableColumns}
                isRowClickable
              />
            </div>
            <div
              className="contact-description__description"
              data-tab="Contact Profile"
            >
              <ContactSummary careerSummary={selectedContact?.career_summary} />
            </div>
          </TabsContent>
        </div>
      </div>
    </div>
  );
};

export default withTabs(ContactDescription);
