import {
  useCallback, useRef,
  useEffect, useState,
} from 'react';
import './topbar.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { BreadCrumbs, Crumb } from '@/components/breadcrumbs/breadcrumbs';
import { SkipToMainContent } from '@/domains/support/skip/skip';
import { getIndustryByCategoryName, getSingleCompany } from '@/services/api/company';
import { TopbarDumb } from './topbar-dumb';
import { useSelf } from '@/services/queries/user';
import { Company } from '@/domains/core/company/types';
import { getStudentByID } from '@/services/api/student';
import { getStudentBaseResume, getStudentTailoredResume } from '@/services/api/resume';
import { getUserRoles } from '@/services/helpers/user';
import { TopbarHelpButton } from './components/topbar-help-button';
import { TopbarChallenges } from './components/topbar-challenges';
import { TopbarXP } from './components/topbar-xp';
import { useIsBetaUser } from '@/services/hooks/use-is-beta-user';

type TopBarProps = {
  collapsed: boolean;
};

const transparentTopbarPages = [
  '/app/dashboard',
];

export default function TopBar({ collapsed }: TopBarProps) {
  const { user } = useAuth0();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const [onboarding, setOnboarding] = useState(false);
  const [isResume, setIsResume] = useState(false);
  const location = useLocation();
  const [crumbs, setCrumbs] = useState<Crumb[]>([]);
  const [isCareerAdvisor, setIsCareerAdvisor] = useState(false);
  const prevPathRef = useRef<string | null>(null);
  const navigate = useNavigate();
  const { isBetaUser } = useIsBetaUser();

  const companyPagePathRegex = /app\/companies\/company\/([a-f0-9-]+)/;
  const industryPathRegex = /app\/companies\/explore\/([^/]+)/;
  const tagPathRegex = /app\/companies\/filter\/tag\/by\/([^/]+)/;
  const categoryPathRegex = /app\/companies\/filter\/category\/by\/([^/]+)/;
  const studentOBPathRegex = /app\/advisor\/students\/student-overview\/([^/]+)/;
  const resumePathRegex = /app\/resume-os\/student\/([^/]+)\/base\/([^/]+)/;
  const resumeTailoredPathRegex = /app\/resume-os\/student\/([^/]+)\/tailored\/([^/]+)/;
  const resumeStudentListRegex = /app\/resume-os\/student-resumes\/([^/]+)/;
  const resumeBaseEditPathRegex = /app\/resume-os\/base\/([^/]+)/;
  const resumeTailoredEditPathRegex = /app\/resume-os\/tailored\/([^/]+)/;
  const companyKanbanPathRegex = /\/app\/company-overview\/([^/]+)/;

  const path = location.pathname;
  const isCareerAdvisorPage = path.includes('advisor') || path.includes('contacts-book') || path.includes('student') || path.includes('insights');

  const logout = () => {
    navigate('/logout');
  };

  const setCompanyPageCrumbs = async (companyId?: string) => {
    try {
      const company: Company = await getSingleCompany(companyId);
      const industry = company.careeros_industry;
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: industry, link: `/app/companies/explore/${encodeURIComponent(industry)}` },
          { name: company.name, link: `/app/companies/company/${companyId}` },
        ],
      );
    } catch (error) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: 'Industry', link: '/app/companies/explore' },
          { name: 'Company', link: `/app/companies${companyId ? `/company/${companyId}` : ''}` },
        ],
      );
    }
  };

  const setTagPageCrumbs = (tag?: string) => {
    setCrumbs(
      [
        { name: 'Explore Companies', link: '/app/companies/explore' },
        { name: decodeURIComponent(tag || 'Tag'), link: location.pathname },
      ],
    );
  };

  const setCategoryPageCrumbs = async (category?: string) => {
    try {
      const industryCategory = category ? await getIndustryByCategoryName(category) : '';
      if (industryCategory) {
        setCrumbs(
          [
            { name: 'Explore Companies', link: '/app/companies/explore' },
            { name: industryCategory.name, link: `/app/companies/explore/${encodeURIComponent(industryCategory.name)}` },
            { name: decodeURIComponent(category || 'Category'), link: location.pathname },
          ],
        );
        return;
      }
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: decodeURIComponent(category || 'Category'), link: location.pathname },
        ],
      );
    } catch (error) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: decodeURIComponent(category || 'Category'), link: location.pathname },
        ],
      );
    }
  };

  const setStudentPageCrumbs = async (studentId?: string, resumeId?: string, type?: 'base' | 'tailored') => {
    if (!studentId) {
      setCrumbs(
        [
          { name: 'Students', link: '/app/advisor/students' },
        ],
      );
      return;
    }

    try {
      const studentData = await getStudentByID(studentId);
      const studentName = `${studentData.first_name} ${studentData.last_name}`;

      if (resumeId && type === 'base' && self) {
        const resume = await getStudentBaseResume(studentId, resumeId);
        const resumeTitle = `${resume.title || 'Resume Title'} (Base Resume)`;

        setCrumbs([
          { name: 'Students', link: '/app/advisor/students' },
          { name: studentName, link: `/app/advisor/students/student-overview/${studentData.id}/university/${self.university_id}?tab=Resumes` },
          { name: resumeTitle || 'Resume', link: location.pathname },
        ]);
        return;
      }

      if (resumeId && type === 'tailored' && self) {
        const resume = await getStudentTailoredResume(studentId, resumeId);
        const resumeTitle = `${resume.title || 'Resume Title'} (Tailored Resume)`;
        setCrumbs([
          { name: 'Students', link: '/app/advisor/students' },
          { name: studentName, link: `/app/advisor/students/student-overview/${studentData.id}/university/${self.university_id}?tab=Resumes` },
          { name: resumeTitle || 'Resume', link: location.pathname },
        ]);
        return;
      }

      setCrumbs(
        [
          { name: 'Students', link: '/app/advisor/students' },
          { name: studentName, link: location.pathname },
        ],
      );
    } catch (error) {
      setCrumbs(
        [
          { name: 'Students', link: '/app/advisor/students' },
        ],
      );
    }
  };

  const setResumePageCrumbs = async (studentId?: string, resumeId?: string, type?: 'base' | 'tailored') => {
    try {
      if (!studentId) {
        return;
      }
      const studentData = await getStudentByID(studentId);
      const studentName = `${studentData.first_name} ${studentData.last_name}`;

      if (resumeId && type === 'base') {
        const resume = await getStudentBaseResume(studentId, resumeId);
        const resumeTitle = `${resume.title || 'Resume Title'} (Base Resume)`;

        setCrumbs([
          { name: 'ResumeOS', link: '/app/resume-os/students' },
          { name: studentName, link: `/app/resume-os/student-resumes/${studentId}` },
          { name: resumeTitle || 'Resume', link: location.pathname },
        ]);
        return;
      }

      if (resumeId && type === 'tailored') {
        const resume = await getStudentTailoredResume(studentId, resumeId);
        const resumeTitle = `${resume.title || 'Resume Title'} (Tailored Resume)`;
        setCrumbs([
          { name: 'ResumeOS', link: '/app/resume-os/students' },
          { name: studentName, link: `/app/resume-os/student-resumes/${studentId}` },
          { name: resumeTitle || 'Resume', link: location.pathname },
        ]);
        return;
      }

      setCrumbs([
        { name: 'ResumeOS', link: '/app/resume-os/students' },
        { name: studentName, link: `/app/resume-os/student-resumes/${studentId}` },
      ]);
    } catch (error) {
      setCrumbs([
        { name: 'ResumeOS', link: '/app/resume-os/students' },
      ]);
    }
  };

  const setResumeBuilderCrumbs = (locationPath: string) => {
    const baseCrumb = { name: 'ResumeOS', link: '/app/resume-os' };

    if (locationPath.includes('/app/resume-os/base/')) {
      const resumeId = locationPath.match(resumeBaseEditPathRegex)?.[1];
      setCrumbs([
        baseCrumb,
        { name: 'Edit Base Resume', link: `/app/resume-os/base/${resumeId}` },
      ]);
      return;
    }

    if (locationPath.includes('/app/resume-os/tailored/')) {
      const resumeId = locationPath.match(resumeTailoredEditPathRegex)?.[1];
      setCrumbs([
        baseCrumb,
        { name: 'Edit Tailored Resume', link: `/app/resume-os/tailored/${resumeId}` },
      ]);
      return;
    }

    if (locationPath.includes('/app/resume-os/base-resume')) {
      setCrumbs([
        baseCrumb,
        { name: 'Create Base Resume', link: '/app/resume-os/base-resume' },
      ]);
      return;
    }

    if (locationPath.includes('/app/resume-os/tailored-resume')) {
      setCrumbs([
        baseCrumb,
        { name: 'Create Tailored Resume', link: '/app/resume-os/tailored-resume' },
      ]);
    }
  };

  const setCompanyKanbanPageCrumbs = async (companyId?: string) => {
    try {
      const company: Company = await getSingleCompany(companyId);
      const companyName = company.name;

      setCrumbs([
        { name: 'Dashboard', link: '/app/dashboard-advisor' },
        { name: 'Student Activity', link: '/app/dashboard-advisor' },
        { name: companyName, link: location.pathname },
      ]);
    } catch (error) {
      setCrumbs([
        { name: 'Dashboard', link: '/app/dashboard-advisor' },
        { name: 'Student Activity', link: '/app/dashboard-advisor' },
      ]);
    }
  };

  const updateCrumbs = () => {
    if (companyPagePathRegex.test(path)) {
      const companyId = path.match(companyPagePathRegex)?.[1];
      setCompanyPageCrumbs(companyId);
    }

    if (studentOBPathRegex.test(path)) {
      const studentId = path.match(studentOBPathRegex)?.[1];
      setStudentPageCrumbs(studentId);
    }

    if (industryPathRegex.test(path)) {
      const industry = path.match(industryPathRegex)?.[1];
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: decodeURIComponent(industry || 'Industry'), link: path },
        ],
      );
      return;
    }

    if (tagPathRegex.test(path)) {
      const tag = path.match(tagPathRegex)?.[1];
      setTagPageCrumbs(tag);

      return;
    }

    if (categoryPathRegex.test(path)) {
      const category = path.match(categoryPathRegex)?.[1];
      setCategoryPageCrumbs(category);
      return;
    }
    if (path.includes('/app/dashboard-advisor')) {
      setCrumbs(
        [
          { name: 'Dashboard', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/dashboard')) {
      setCrumbs(
        [
          { name: 'Home', link: path },
        ],
      );
      return;
    }

    if (path.includes('/app/articles')) {
      setCrumbs(
        [
          { name: 'Articles', link: path },
        ],
      );
      return;
    }

    if (path.includes('/app/companies/explore') || path.includes('/app/search')) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
        ],
      );
      return;
    }

    if (path.includes('/app/companies/search')) {
      setCrumbs([
        { name: 'Search Companies', link: path },
      ]);
      return;
    }

    if (path.includes('/app/jobs')) {
      setCrumbs(
        [
          { name: 'Job Feed', link: '/app/jobs' },
        ],
      );
      return;
    }

    if (path.includes('/app/alumni')) {
      setCrumbs(
        [
          { name: 'AlumniOS', link: path },
        ],
      );
      return;
    }

    if (path.includes('/app/overview')) {
      setCrumbs(
        [
          { name: 'Overview Board', link: path },
        ],
      );
      return;
    }
    // Gamification
    if (path.includes('/app/challenges')) {
      setCrumbs(
        [
          { name: 'Home', link: '/app/dashboard' },
          { name: 'Challenges', link: path },
        ],
      );
      return;
    }
    if (path.includes('resume-book')) {
      setCrumbs(
        [
          { name: 'ResumeOS', link: '/app/resume-os/students' },
          { name: 'Resume Book', link: location.pathname },
        ],
      );
      return;
    }

    if (path.includes('/app/leaderboards')) {
      setCrumbs(
        [
          { name: 'Home', link: '/app/dashboard' },
          { name: 'Leaderboards', link: path },
        ],
      );
      return;
    }

    if (resumePathRegex.test(path)) {
      const studentId = path.match(resumePathRegex)?.[1];
      const resumeId = path.match(resumePathRegex)?.[2];
      if (prevPathRef.current && studentOBPathRegex.test(prevPathRef.current)) {
        setStudentPageCrumbs(studentId, resumeId, 'base');
      } else {
        setResumePageCrumbs(studentId, resumeId, 'base');
      }
      return;
    }
    if (resumeTailoredPathRegex.test(path)) {
      const studentId = path.match(resumeTailoredPathRegex)?.[1];
      const resumeId = path.match(resumeTailoredPathRegex)?.[2];
      if (prevPathRef.current && studentOBPathRegex.test(prevPathRef.current)) {
        setStudentPageCrumbs(studentId, resumeId, 'tailored');
      } else {
        setResumePageCrumbs(studentId, resumeId, 'tailored');
      }
      return;
    }

    if (resumeStudentListRegex.test(path)) {
      const studentId = path.match(resumeStudentListRegex)?.[1];
      setResumePageCrumbs(studentId);
      return;
    }
    if (path.includes('/app/resume-os/base') || path.includes('/app/resume-os/tailored')) {
      setResumeBuilderCrumbs(path);
      return;
    }
    if (path.includes('app/resume-os')) {
      setCrumbs(
        [
          { name: 'ResumeOS', link: '/app/resume-os' },
        ],
      );
      return;
    }
    if (path.includes('app/tour')) {
      setCrumbs(
        [
          { name: 'Tour', link: path },
        ],
      );
      return;
    }
    if (path.includes('app/inbox')) {
      setCrumbs(
        [
          { name: 'Inbox', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/favorites')) {
      setCrumbs(
        [
          { name: 'Saved Companies', link: path },
        ],
      );
      return;
    }
    if (companyKanbanPathRegex.test(path)) {
      const companyId = path.match(companyKanbanPathRegex)?.[1];
      setCompanyKanbanPageCrumbs(companyId);
      return;
    }
    if (path.includes('/app/profile') || path.includes('/app/documents')) {
      setCrumbs(
        [
          { name: 'My Account', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/students')) {
      setCrumbs(
        [
          { name: 'Student Table', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/support')) {
      setCrumbs(
        [
          { name: 'Support', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/archive')) {
      setCrumbs(
        [
          { name: 'Archive', link: path },
        ],
      );
      return;
    }
    if (path.includes('/app/contacts/campus-champions')) {
      setCrumbs(
        [
          { name: 'Contacts', link: '/app/contacts' },
          { name: 'Campus Champions', link: '/app/contacts/campus-champions' },
        ],
      );
      return;
    }
    if (path.includes('/app/contacts')) {
      setCrumbs(
        [
          { name: 'Contacts', link: '/app/contacts' },
        ],
      );
      return;
    }

    if (path.includes('/app/companies')) {
      setCrumbs(
        [
          { name: 'Explore Companies', link: '/app/companies/explore' },
          { name: 'Advanced Search', link: '/app/companies' },
        ],
      );
      return;
    }

    if (path.includes('/app/cohort-assignment')) {
      setCrumbs(
        [
          { name: 'Welcome to CareerOS!', link: path },
        ],
      );
      return;
    }

    if (path.includes('/app/advisor/sent-email-log')) {
      setCrumbs(
        [
          { name: 'Students', link: '/app/advisor/students' },
          { name: 'Email Log', link: path },
        ],
      );
      return;
    }

    if (path.includes('/app/advisor/students')) {
      setCrumbs(
        [
          { name: 'Students', link: path },
        ],
      );
      return;
    }

    // If the path is not recognized, set the default CarrerOS breadcrumb (for the storybook)
    if (!location.hash.includes('thecareeros')) {
      setCrumbs(
        [
          { name: 'CareerOS', link: path },
        ],
      );
    }

    prevPathRef.current = path;
  };

  const handlePageReload = useCallback(() => {
    window.location.reload();
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    if (isCareerAdvisor && isCareerAdvisorPage) {
      window.open('https://www.careeros.com/ca-app-258317/support', '_blank');
      return;
    }

    const appcueParam = 'appcue=404504ce-cc04-4bef-81ae-8ce413a7f48e';
    const separator = path.includes('?') ? '&' : '?';
    const newUrl = `${path}${separator}${appcueParam}`;
    window.history.pushState({}, '', newUrl);
  }, [isCareerAdvisor, path]);

  useEffect(() => {
    if (user) {
      const roles = getUserRoles(user);
      setIsCareerAdvisor(roles.includes('CareerAdvisor'));
    }
  }, [user]);

  useEffect(() => {
    updateCrumbs();
  }, [location]);

  useEffect(() => {
    prevPathRef.current = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    if ((path.includes('/app/onboarding') || path.includes('/app/cohort-assignment')) && !location.search.includes('show_navigation')) {
      setOnboarding(true);
    } else {
      setOnboarding(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (path.includes('/app/resume-os')) {
      setIsResume(true);
    } else {
      setIsResume(false);
    }
  }, [location.pathname]);

  if (isSelfLoading) return null;

  return (
    <div className={collapsed ? 'topbar-wrapper topbar-wrapper--collapsed' : 'topbar-wrapper'}>
      <TopbarDumb
        title={(
          <>
            <BreadCrumbs
              crumbs={crumbs}
              onLastCrumbClick={handlePageReload}
            />
            <SkipToMainContent />
          </>
        )}
        withSearch={!onboarding && !isResume && !isCareerAdvisorPage}
        withProfile={!onboarding}
        profileImage={self?.profile_picture_url || user?.picture}
        userName={user?.name}
        email={user?.email}
        isTransparent={transparentTopbarPages.includes(location.pathname)}
        handleHelpButtonClick={handleHelpButtonClick}
        handleLogoutClick={logout}
        isCareerAdvisorPage={isCareerAdvisorPage}
      >
        {isCareerAdvisorPage ? <TopbarHelpButton />
          : (
            <>
              {isBetaUser && <TopbarChallenges />}
              {isBetaUser && <TopbarXP />}
            </>
          )}
      </TopbarDumb>
    </div>
  );
}
