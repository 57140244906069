import { TagType } from '@/components/tag/tag';
import {
  AllCompaniesType, IndustryType, NetworkingType, TopCompaniesType,
} from '@/domains/core/advisor/types';
import { http } from '@/services/api/http';

const baseURL = import.meta.env.VITE_APP_URL;
export const addComment = (comment: string, applicationID: string, companyName: string, companyLogo: string, caName: string, caProfilePicture: string) => http.post(
  `/applications/${applicationID}/comment`,
  {
    text: comment,
    url: `${baseURL}/app/overview?openSidebar=true`,
    company_name: companyName,
    company_logo: companyLogo,
    ca_name: caName,
    profile_picture_url: caProfilePicture,
  },
);

export const getUniversityTags = (universityID: string): Promise<TagType[]> => http.get(`/university/${universityID}/tags`);

export const getUniversityAnalyticsIndustry = (cohortID: string): Promise<IndustryType> => http.get(`/university/analytics/industry/companies?cohort_id=${cohortID}`, 'v2');

export const getUniversityAnalyticsTopCompanies = (cohortID: string): Promise<TopCompaniesType[]> => http.get(`/university/analytics/companies/top?cohort_id=${cohortID}`, 'v2');

export const getUniversityAnalyticsNetworking = (cohortID: string): Promise<NetworkingType> => http.get(`/university/analytics/networking?cohort_id=${cohortID}`, 'v2');

export const getUniversityAnalyticsAllCompanies = (cohortID: string): Promise<AllCompaniesType[]> => http.get(`/university/analytics/companies/all?cohort_id=${cohortID}`, 'v2');

export const addTagToStudent = (tag: TagType, studentID: string, universityID: string) => http.post(`/university/${universityID}/students/${studentID}/tag`, { ...tag });

export const deleteTagFromUniversity = (tagID: string, universityID: string) => http.delete(`/university/${universityID}/tag/${tagID}`);

export const createTag = (name: string, color: string, universityID: string) => http.post(`/university/${universityID}/tag`, { label: name, color });

export const removeTagFromStudent = (tag: TagType, studentID: string, universityID: string) => http.delete(`/university/${universityID}/students/${studentID}/tag/${tag.id}`);

export const addResumeComment = (comment: string, resumeID: string, url: string) => http.post(`/resume/${resumeID}/comments`, { text: comment, redirect_url: url });

export const getStudentData = async (universityID: string, cohortIDs?: string[]) => http.post('/university/export-students-data', {
  university_id: universityID,
  cohort_ids: cohortIDs,
}, { responseType: 'blob' });
