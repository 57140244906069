// import { PDFDownloadLink } from '@react-pdf/renderer';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import { PreviewResume } from '../resume-preview/resume-preview';
import { ResumeSchema, TemplateNames } from '../../types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { RESUME_TEMPLATES } from '../../templates/template-rules/resume-rules';
import PDFDownloadWrapper from '../download-resume/download-resume';
import { useResumeReview } from '../../hooks/use-resume-review';
import { useSelf } from '@/services/queries/user';
import { useCareerAdvisors } from '../../hooks/use-career-advisor-data';
import { universityWhoHasResumeApprovingLogic } from '../../helpers/university-with-approving-logic-feature';

GlobalWorkerOptions.workerSrc = '/pdf-worker.mjs';

type SaveResumeProps = {
  baseResume: ResumeSchema;
  title: string;
  resumeStatus?: string;
  templateName: TemplateNames;
  saveResume: () => void;
  resumeId?: string;
  universityName?: string;
};

const SaveResume = ({
  baseResume, title, resumeStatus, templateName, saveResume, resumeId, universityName,
}: SaveResumeProps) => {
  const SelectedComponent = RESUME_TEMPLATES[templateName];
  const { user } = useAuth0();
  const { data: self } = useSelf();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { careerAdvisors } = useCareerAdvisors();
  const { requestReview: requestResumeReview } = useResumeReview(resumeId || '', 'base', self?.id);
  const requestReview = () => {
    requestResumeReview(careerAdvisors);
  };

  const isResumeReadyForReview = (resumeStatus === 'READY_FOR_REVIEW' || resumeStatus === 'APPROVED') && universityName && universityWhoHasResumeApprovingLogic.includes(universityName) && resumeId;

  const saveAndTrack = async () => {
    await saveResume();
    trackEvent('Resume Downloaded', user, {
      source: 'Resume Save Step',
    });
  };

  const saveResumeAndRedirect = async () => {
    await saveResume();
    if (window.location.pathname.includes('tailored')) {
      navigate('/app/resume-os?tab=Job-Tailored%2520Resumes');
    } else {
      navigate('/app/resume-os');
    }
  };

  return (
    <>
      <h2 className="resume-builder__title">
        Save & Export
      </h2>
      <div>
        <p className="resume-builder__subtitle">Done! You can now download your resume as a PDF or simply save it here in CareerOS.</p>
        <p className="resume-builder__subtitle">You can then use it as a base resume to tailor it to a specific job posting from your saved jobs! 🚀</p>
      </div>
      <div className="resume-builder__bottom">
        <div className="resume-builder__resume-name">{`PDF Preview: ${title}.pdf`}</div>
        <div className="resume-builder__export-buttons">
          {baseResume && (
          <PDFDownloadWrapper saveAndTrack={saveAndTrack} document={<SelectedComponent resume={baseResume} filename={`${title}.pdf`} />} fileName={`${title}.pdf`} />
          )}
          {isResumeReadyForReview && (<Button icon="bi bi-send-exclamation" label="Request Review" handleClick={requestReview} mode="primary" size="medium" outlined />)}
          <Button icon="bi bi-floppy" label="Save in CareerOS" handleClick={saveResumeAndRedirect} mode="primary" size="medium" />
        </div>
      </div>
      {baseResume && (
      <PreviewResume preview hideHighlights selectedTemplate={templateName} baseResume={baseResume} />
      )}
    </>
  );
};

export default SaveResume;
