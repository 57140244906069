import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { Button, Input } from '@careeros/coco';
import { addResumeComment } from '@/services/api/advisor';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './add-comment-to-resume.scss';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';

type Props = {
  id: string;
  url: string;
  toggleCommentInput: () => void;
};

export const AddCommentToResume = ({
  id,
  url,
  toggleCommentInput,
}: Props) => {
  const [comment, setComment] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const { addToast }: AddToastType = useContext(ToastContext);

  const addComment = async () => {
    if (comment.trim() === '') {
      setIsInvalid(true);
      return;
    }
    try {
      const response = await addResumeComment(comment, id, url);

      if (!response || response.error) {
        throw new Error(response?.error || 'Failed to add comment');
      }

      addToast({
        type: 'success',
        message: 'Success',
        additionalMessage: 'Comment added successfully.',
      });
      queryClient.invalidateQueries(['resumeComments', id]);
      setComment('');
      trackEvent('CA Resume Comment Sent');
    } catch (error) {
      Sentry.captureException(error);
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again later.',
      });
    }
  };

  const onChangeHandler = (value: string) => {
    setComment(value);
    setIsInvalid(false);
  };
  return (
    <div className="add-comment-to-resume">
      <Input
        id="comment"
        label="Write a comment:"
        placeholder="Add your comment here"
        value={comment}
        height="auto"
        handleValueChange={onChangeHandler}
        invalid={isInvalid}
        warningText="Comment cannot be empty"
      />
      <div className="add-comment-to-resume__buttons">
        <Button
          label="Cancel"
          mode="primary"
          outlined
          size="small"
          icon="bi bi-x-circle"
          onClick={toggleCommentInput}
        />
        <Button
          label="Send"
          mode="primary"
          size="small"
          icon="bi bi-send"
          iconPosition="right"
          onClick={addComment}
        />
      </div>
    </div>
  );
};
