import { useEffect, useState } from 'react';
import { Button } from '@careeros/coco';
import { getContactsByCompanyAndUser } from '@/services/api/contact';
import Comments from '@/assets/images/comments.svg';
import { ContactCard } from '@/domains/core/contact';
import './contacts-sidebar.scss';

type Props = {
  companyName: string;
  companyID: string;
  closeSidebar: () => any;
  otherContacts?: any;
  userID: string;
  handleSeeAllContacts: () => void;
};

export const ContactsSidebar = ({
  companyName,
  companyID,
  closeSidebar,
  handleSeeAllContacts,
  otherContacts,
  userID,
}: Props) => {
  const [contacts, setContacts] = useState([]);
  const fetchContacts = async () => {
    const response = await getContactsByCompanyAndUser(companyID, userID);
    setContacts(response ?? []);
  };
  useEffect(() => {
    if (companyID) {
      fetchContacts();
    }
  }, [companyID]);

  return (
    <div className="contacts-sidebar">
      <h2 className="contacts-sidebar__title" onClick={closeSidebar}>
        {companyName}
      </h2>
      <p className="contacts-sidebar__subtitle">Contacts student is networking with.</p>
      <Button
        mode="primary"
        size="medium"
        outlined
        label="See all student contacts"
        icon="bi bi-box-arrow-up-right"
        iconPosition="right"
        handleClick={handleSeeAllContacts}
      />
      <div className="contacts-sidebar__separator" />
      {contacts?.length > 0 ? (
        <div className="contacts-sidebar__companies">
          <div className="contacts-sidebar__companies-list">
            {contacts.map((contact: any) => (
              <ContactCard
                key={contact.id}
                id={contact.id}
                imageURL={contact.career_summary?.basics?.image}
                contactName={`${contact.first_name} ${contact.last_name}`}
                contactPosition={contact.current_position.title}
                linkedInUrl={contact.linkedin_url}
                hasLinkedInButton
              />
            ))}
          </div>
        </div>
      ) : (
        <div className="contacts-sidebar__comments">
          <img src={Comments} alt="" />
          <p>There are no contacts for this student.</p>
        </div>
      )}
      {otherContacts && otherContacts.length > 0 && (
        <>
          <h2 className="contacts-sidebar__title" onClick={closeSidebar}>
            Student Overlap
          </h2>
          <p className="contacts-sidebar__subtitle">
            Other contacts saved by students
          </p>
          <div className="contacts-sidebar__companies">
            <div className="contacts-sidebar__companies__list">
              {otherContacts.map((contact: any) => (
                <ContactCard
                  key={contact.id}
                  id={contact.id}
                  imageURL={contact.career_summary?.basics?.image}
                  contactName={`${contact.first_name} ${contact.last_name}`}
                  contactPosition={contact.current_position.title}
                  linkedInUrl={contact.linkedin_url}
                  hasLinkedInButton
                />
              ))}
            </div>
          </div>
        </>
      ) }
    </div>
  );
};
