import {
  Contact, contactKanbanStatuses, ContactStatus, contactStatuses,
} from '../../contact/types';

type ContactKanbanColumn = {
  label: string;
  value: string;
  isSpecial?: boolean;
  icon?: string;
  withCounter?: boolean;
  isLive?: boolean;
  isGreyedOut?: boolean;
  withStatusIndicator?: boolean;
  withExtraInfo?: boolean;
  extraInfoLabel?: string | JSX.Element;
};
const additionalColumns = ['Suggested'];
export const contactKanbanColumnsWithExtra = [...additionalColumns, ...contactStatuses] as (ContactStatus | 'Suggested')[];
export type ContactKanbanColumnsWithExtra = (typeof contactKanbanColumnsWithExtra)[number];

export const contactKanbanColumnProps: { [key in typeof contactKanbanColumnsWithExtra[number]]: ContactKanbanColumn } = {
  Suggested: {
    label: 'Suggested',
    value: 'suggested',
    isSpecial: true,
    withCounter: false,
    icon: 'bi bi-stars',
    withExtraInfo: true,
    extraInfoLabel: (
      <>
        You will typically see contacts that have some shared past experience with you, e.g. they are alumni from your university, have previously worked at the same company as you, live in the same area, or all of the above.
        <br />
        {' '}
        When reaching out to a contact, make sure to personalize your message.
      </>
    ),
  },
  'Contact Saved': {
    label: 'Contact Saved',
    value: 'saved',
    isSpecial: false,
    isLive: true,
    withCounter: true,
    withStatusIndicator: true,
  },
  '1st Outreach': {
    label: '1st Outreach',
    value: '1st-outreach',
    isSpecial: false,
    isLive: true,
    withCounter: true,
    withStatusIndicator: true,
  },
  '2nd Outreach': {
    label: '2nd Outreach',
    value: '2nd-outreach',
    isSpecial: false,
    isLive: true,
    withCounter: true,
    withStatusIndicator: true,
  },
  'Response Received': {
    label: 'Response Received',
    value: 'response-received',
    isSpecial: false,
    isLive: true,
    withCounter: true,
    withStatusIndicator: true,
  },
  'Coffee Chat': {
    label: 'Coffee Chat',
    value: 'coffee-chat',
    isSpecial: false,
    withCounter: true,
    withStatusIndicator: true,
  },
  Nurturing: {
    label: 'Nurturing',
    value: 'nurturing',
    isSpecial: false,
    withCounter: true,
    withStatusIndicator: true,
  },
  'Golden Referral': {
    label: 'Golden Referral',
    value: 'golden-referral',
    isSpecial: false,
    withCounter: true,
    withStatusIndicator: true,
  },
  Archived: {
    label: 'Archived',
    value: 'archive',
    isSpecial: false,
    isGreyedOut: true,
    withCounter: true,
    withStatusIndicator: true,
  },
} as const;

export type ContactKanbanCardInfo = {
  id: string;
  status: ContactKanbanColumnsWithExtra;
  dropPosition: number;
};

const sortByPosition = (unsortedTiles: Contact[]): Contact[] => unsortedTiles.sort((a, b) => {
  if (a.kanban_position.row > b.kanban_position.row) {
    return 1;
  } if (a.kanban_position.row < b.kanban_position.row) {
    return -1;
  }
  return 1;
});

export const getContactsKanbanLayout = (contacts: Contact[]) => {
  const layoutResult = contactStatuses.reduce((acc, column) => {
    acc[column] = sortByPosition(
      contacts.filter((contact) => contact.kanban_position.status === contactKanbanStatuses[column]),
    );

    return acc;
  }, {} as { [key in ContactStatus]: Contact[] });

  return layoutResult;
};

export const presavedContactNames = [
  'Marcus Castro',
  'Maximilian Stengel',
  'Franz Schürmann',
];

export const filterPresavedContacts = (contactsToFilter?: Contact[]) => {
  if (!contactsToFilter || !Array.isArray(contactsToFilter)) {
    return [];
  }

  return contactsToFilter.filter((contact) => !presavedContactNames.includes(`${contact.first_name} ${contact.last_name}`));
};
