import {
  Challenge, GamificationActions, Leaderboards, SuccessMessage,
} from '@/types/gamification';
import { http } from './http';

export const getChallenges = async (): Promise<Challenge[]> => http.get('/gamification/challenges/self');

export const getXp = async (): Promise<{
  total_xp: number;
}> => http.get('/gamification/xp/self');

export const getAvailableActions = async (): Promise<GamificationActions> => http.get('/gamification/actions');

export const completeAction = async (actionId: string): Promise<SuccessMessage> => http.put(`/gamification/actions/${actionId}`, {});

export const readChallenge = async (challengeId: string): Promise<SuccessMessage> => http.post(`/gamification/challenge/${challengeId}/read`, {});

export const getLeaderboards = async (): Promise<Leaderboards> => http.get('/gamification/leaderboards');
