import { useQuery } from 'react-query';
import { getAllAdvisors } from '@/domains/core/advisor/api/advisor';
import { getCAStudentNotes } from '@/domains/core/advisor/api/note';
import { checkResumeReviewStatus, getUniversityAdvisors } from '../api/resume';
import {
  getUniversityAnalyticsAllCompanies, getUniversityAnalyticsIndustry, getUniversityAnalyticsNetworking, getUniversityAnalyticsTopCompanies,
} from '../api/advisor';

export const useGetAllAdvisors = (universityID: string, options = {}) => useQuery(
  ['all-advisors', universityID],
  async () => {
    const response = await getAllAdvisors(universityID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    enabled: !!universityID,
    staleTime: 1000 * 60 * 60 * 8, // 8 hours
    ...options,
  },
);

export const useStudentNotes = (studentID: string, options = {}) => useQuery(
  ['ca-student-notes', studentID],
  async () => {
    const response = await getCAStudentNotes(studentID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);

export const useCheckResumeReviewStatus = (resumeId: string, options = {}) => useQuery(
  ['check-resume-review-status', resumeId],
  async () => {
    const response = await checkResumeReviewStatus(resumeId);
    return response?.is_requested ?? false;
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);

export const useCareerAdvisorsForUniversity = (options = {}) => useQuery(
  ['career-advisors-by-university'],
  async () => {
    const response = await getUniversityAdvisors();
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 1,
    ...options, // Spread additional options
  },
);

export const useUniversityAnalyticsIndustry = (cohortID: string, options = {}) => useQuery(
  ['university-analytics-industry', cohortID],
  async () => {
    const response = await getUniversityAnalyticsIndustry(cohortID);
    return response ?? {};
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);

export const useUniversityAnalyticsTopCompanies = (cohortID: string, options = {}) => useQuery(
  ['university-analytics-top-companies', cohortID],
  async () => {
    const response = await getUniversityAnalyticsTopCompanies(cohortID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);

export const useUniversityAnalyticsNetworking = (cohortID: string, options = {}) => useQuery(
  ['university-analytics-networking', cohortID],
  async () => {
    const response = await getUniversityAnalyticsNetworking(cohortID);
    return response ?? { total_students: 0, networking_students: 0 };
  },
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);

export const useUniversityAnalyticsAllCompanies = (cohortID: string, options = {}) => useQuery(
  ['university-analytics-all-companies', cohortID],
  async () => {
    const response = await getUniversityAnalyticsAllCompanies(cohortID);
    return (!response || !Array.isArray(response)) ? [] : response;
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);
