import './tracker-header.scss';
import { DefaultLogo } from '@careeros/coco';

interface HeaderProps {
  logo: string;
  title: string;
  subtitle?: string;
}

export const TrackerHeader = ({
  logo,
  title,
  subtitle,
}: HeaderProps) => (
  <div className="company-tracker-header">
    <div className="company-tracker-header__logo">
      <DefaultLogo
        source={logo}
        type="company"
        name={title}
        className=""
        size={72}
      />
    </div>
    <div className="company-tracker-header__text">
      <h3 className="company-tracker-header__name">
        {title}
      </h3>
      {subtitle && (
        <span className="company-tracker-header__subtitle">
          {subtitle}
        </span>
      )}
    </div>
  </div>
);
