import { PreviewCardWrapper } from '@/domains/generic/preview-card/preview-card-wrapper/preview-card-wrapper';
import { SuggestedPeopleItem } from '../../types';
import { ContactTileCard } from '../contact-tile-card/contact-tile-card';
import { ContactPreviewCard } from '../contact-preview-card/contact-preview-card';

type Props = {
  contact: SuggestedPeopleItem;
  companyName: string;
  companyLogoUrl: string;
  handleSaveSuggestedContact: (contactUrl: string) => void;
};

export const LinkedInSuggestedContactTileWithPreview = ({
  contact,
  companyName,
  companyLogoUrl,
  handleSaveSuggestedContact,
}: Props) => (
  <ContactTileCard
    key={contact.link}
    id={contact.link}
    name={contact.name}
    position={contact.role}
    image={contact.image}
    companyName={companyName}
    schoolName=""
    handleMainButtonAction={() => handleSaveSuggestedContact(contact.link)}
    mainButtonLabel="Save Contact"
    mainButtonIcon="bi bi-bookmark-heart"
    companyLogo={companyLogoUrl}
    handleNameClick={() => window.open(contact.link, '_blank')}
  >

    <ContactTileCard.NameWithPreview>
      <PreviewCardWrapper
        name={contact.name}
        className="suggested-contact-tile__name-text"
        popupFitToScreenHeight
      >
        <PreviewCardWrapper.Preview>
          <ContactPreviewCard
            title={contact.name}
            headline={contact.role}
            linkedInUrl={contact.link}
          />
        </PreviewCardWrapper.Preview>
      </PreviewCardWrapper>
    </ContactTileCard.NameWithPreview>
  </ContactTileCard>
);
