/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, StyleSheet, Font, Image,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { HighlightWords, ResumeSchema } from '../../types';
import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';
import linkedin from '@/assets/images/resume-builder/EBS/linkedin-blue.png';

import {
  computeSkillsStyle,
  renderWorkHighlightedText,
} from '../helpers/utils';

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: 600 }, { src: CalibriBold, fontWeight: 800 }, { src: CalibriItalic, fontStyle: 'italic' }] });

Font.registerHyphenationCallback(word => (
  [word]
));

const formatDate = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  return dayjs(date, 'MM/YY').format('MM.YYYY');
};

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Calibri',
    fontSize: 10,
    color: '#7f7f7f',
    width: '100%',
    height: '100%',
    padding: '26px 0',
  },
  mainHeader: {
    flexDirection: 'row',
    padding: '0px 40px 12px 40px',
  },
  column: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
  },
  name: {
    color: '#151e53',
    fontSize: 16,
    fontWeight: 400,
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  linkedinContainer: {
    flexDirection: 'row',
    gap: 5,
    marginTop: 10,
  },
  linkedinIcon: {
    width: 12,
    height: 12,
  },
  profilePicture: {
    width: 100,
    height: 110,
    objectFit: 'contain',
  },
  profilePictureContainer: {
    width: 100,
    height: 110,
    margin: '0 40px 10px auto',
  },
  sectionContainer: {
    flexDirection: 'column',
    padding: '0px 40px',
    marginTop: 2,
    flexGrow: 0,
  },
  sectionLine: {
    borderBottomWidth: 0.5,
    borderBottomColor: '#0a66c2',
    width: '100%',
    marginBottom: 12,
  },
  sectionTitle: {
    color: '#151e53',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 6,
  },
  educationBlock: {
    padding: '0px 10px',
    marginBottom: 10,
    flexDirection: 'row',
    minHeight: 'auto',
    width: '100%',
  },
  detailsColumn: {
    width: '70%',
    flexShrink: 0,
  },
  textBold: {
    fontWeight: 'bold',
  },
  dateColumn: {
    width: '30%',
    alignItems: 'flex-end',
    flexShrink: 0,
  },
  detailText: {
    fontSize: 10,
    marginLeft: 5,
    marginBottom: 4,
    lineHeight: 1.1,
  },
  skillsRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  skillLabel: {
    width: '10%',
  },
  highlights: {
    marginTop: 5,
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: 10,
    marginLeft: 5,
    marginBottom: 4,
    lineHeight: 1.1,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  highlightText: {
    fontSize: 10,
    color: '#7f7f7f',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 3,
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null,
};

const EBSTemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => (
  <Document title={title}>
    <Page size="A4" style={styles.page} wrap>
      <View fixed>
        <View render={({ pageNumber }) => (
          pageNumber === 1 && (
          <View style={styles.mainHeader}>
            <View style={styles.column}>
              <Text style={styles.name}>{resume.basics.name}</Text>
              <View style={[styles.row, { gap: 15 }]}>
                {(resume.basics.location?.address || resume.basics.location?.city || resume.basics.birth_date) && (
                <View>
                  {resume.basics.location?.address && <Text>{resume.basics.location.address}</Text>}
                  {resume.basics.location?.city && <Text>{resume.basics.location.city}</Text>}
                  {resume.basics.birth_date && (
                    <Text>{`Birth date: ${resume.basics.birth_date}`}</Text>
                  )}
                </View>
                )}

                <View>
                  <Text>{resume.basics.phone_numbers?.[0]}</Text>
                  <Text>{resume.basics.emails?.[0]}</Text>
                  <Text>{resume.basics.nationality?.join(', ')}</Text>
                </View>
              </View>
              <View style={styles.linkedinContainer}>
                <Image src={linkedin} style={styles.linkedinIcon} />
                <Text style={{ marginTop: 1.5 }}>{resume.basics.url}</Text>
              </View>
            </View>

            {resume.basics.profile_picture_url && (
              <View style={styles.profilePictureContainer}>
                <Image src={resume.basics.profile_picture_url} style={styles.profilePicture} />
              </View>
            )}
          </View>
          )
        )}
        />
      </View>

      {/* EDUCATION */}
      <View style={styles.sectionContainer}>
        <View style={styles.sectionLine} />
        <Text style={styles.sectionTitle}>EDUCATION</Text>

        {resume.education?.map((edu) => (
          <View key={edu.institution} style={styles.educationBlock}>
            <View style={styles.detailsColumn}>
              <View style={[styles.row, { flexWrap: 'wrap', lineHeight: 1 }]}>
                <Text style={[styles.textBold]}>
                  {`${edu.institution}, ${edu.location} / `}
                </Text>
                <Text>{edu.area}</Text>
              </View>

              {edu.score && <Text style={{ fontStyle: 'italic', marginTop: 5 }}>{`GPA: ${edu.score}`}</Text>}
              <View style={[styles.column, { marginTop: 5 }]}>
                {edu.description?.map((desc) => (
                  <Text style={styles.detailText}>
                    •
                    {' '}
                    {desc}
                  </Text>
                ))}
              </View>

            </View>
            <View style={styles.dateColumn}>
              <Text>
                {formatDate(edu.startDate)}
                {' '}
                -
                {' '}
                {edu.endDate === 'Present' ? 'present' : formatDate(edu.endDate)}
              </Text>
            </View>
          </View>
        ))}
      </View>

      {/* WORK EXPERIENCE */}
      <View style={styles.sectionContainer}>
        <View style={styles.sectionLine} />
        <Text style={styles.sectionTitle}>EXPERIENCE</Text>

        {resume.work?.map((work, index) => (
          <View key={work.company} style={styles.educationBlock}>
            <View style={styles.detailsColumn}>
              <Text style={styles.textBold}>{`${work.name}, ${work.location}`}</Text>
              <Text style={{ margin: '3px 0px' }}>{`${work.position}${work.summary ? `, ${work.summary}` : ''}`}</Text>

              <View style={styles.column}>
                {work.highlights && work.highlights.length > 0 && (
                  <View style={styles.highlights}>
                    {work.highlights.map((highlight, highlightIndex) => {
                      const cleanedHighlight = highlight.replace(/^•\s/, '');

                      return (
                        <View key={`${highlight}-${highlightIndex}`} style={styles.listItem} wrap={false}>
                          <Text style={styles.bulletPoint}>•</Text>
                          <View style={styles.highlightText}>
                            {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            </View>
            <View style={styles.dateColumn}>
              <Text>
                {formatDate(work.startDate)}
                {' '}
                -
                {' '}
                {work.endDate === 'Present' ? 'present' : formatDate(work.endDate)}
              </Text>
            </View>
          </View>
        ))}
      </View>

      {/* QUALIFICATIONS */}
      <View style={styles.sectionContainer}>
        <View style={styles.sectionLine} />
        <Text style={styles.sectionTitle}>QUALIFICATIONS</Text>

        <View style={{ flexDirection: 'column', marginHorizontal: '10px' }}>
          <View style={styles.skillsRow}>
            <Text style={[styles.skillLabel, styles.textBold]}>Language</Text>
            <Text>
              {resume.languages?.map(language => `${language.language} (${language.fluency})`).join(', ')}
            </Text>
          </View>

          <View style={styles.skillsRow}>
            <Text style={[styles.skillLabel, styles.textBold]}>IT</Text>
            <Text style={computeSkillsStyle(highlightedWords, activeField, styles)}>
              {resume.skills?.map(skill => `${skill.name} (${skill.level})`).join(', ')}
            </Text>
          </View>

          {resume.certificates?.length && (
            <View style={styles.skillsRow}>
              <Text style={[styles.skillLabel, styles.textBold]}>Certificates</Text>
              <Text>
                {resume.certificates?.map(cert => `${cert.name} (${cert.issuer})`).join(', ')}
              </Text>
            </View>
          )}
        </View>
      </View>

      {/* VOLUNTARY ENGAGEMENT */}
      {resume.extracurricular_activities?.length
        && (
        <View style={styles.sectionContainer}>
          <View style={styles.sectionLine} />
          <Text style={styles.sectionTitle}>VOLUNTARY ENGAGEMENT</Text>

          {resume.extracurricular_activities?.map((extracurricular) => (
            <View style={styles.educationBlock}>
              <View style={styles.detailsColumn}>
                <Text style={styles.textBold}>
                  {[extracurricular.institution, extracurricular.location].filter(Boolean).join(', ')}
                </Text>

                {extracurricular.role && <Text>{`${extracurricular.role}`}</Text>}
                <View style={styles.column}>
                  {extracurricular.description?.map((description) => (
                    <Text style={styles.detailText}>
                      {`${description}`}
                    </Text>
                  ))}
                </View>
              </View>
              <View style={styles.dateColumn}>
                <Text>
                  {formatDate(extracurricular.startDate)}
                  {' '}
                  -
                  {' '}
                  {extracurricular.endDate === 'Present' ? 'present' : formatDate(extracurricular.endDate)}
                </Text>
              </View>
            </View>
          ))}
        </View>
        )}
    </Page>
  </Document>
);

export default EBSTemplate;
