import { useEffect, useState } from 'react';
import { StudentProfile, TallyData } from './types/types';
import { getStudentProfileData, getTallyData } from '@/services/api/profile';
import { BasicProfileInfo } from './components/basic-profile-info/basic-profile-info';
import { WorkExperience } from './components/work-experience/work-experience';
import { Education } from './components/education/education';
import { Skills } from './components/skills/skills';
import { TallyInfo } from './components/tally-info/tally-info';
import { ComingSoonStateStudentProfile } from './components/coming-soon-state-student-profile/coming soon-state-student-profile';
import './profile.scss';

const defaultStudentProfile: StudentProfile = {
  id: '',
  user_id: '',
  email: '',
  first_name: '',
  last_name: '',
  profile_picture_url: '',
  background_image: '',
  degree: '',
  summary: '',
  university: {
    id: '',
    name: '',
    logo_url: '',
    location: '',
  },
  sections: {
    work_experiences: [],
    educations: [],
    skills: [],
  },
  created_at: '',
  updated_at: '',
};

type Props = {
  id: string;
  isCAView?: boolean;
};

export const StudentsProfile = ({ id, isCAView }: Props) => {
  const [tallyData, setTallyData] = useState<TallyData>([]);
  const [studentProfile, setStudentProfile] = useState<StudentProfile>(defaultStudentProfile);

  const getTallyDataInfo = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await getTallyData(id);
      setTallyData(response);
    } catch (error) {
      setTallyData([]);
    }
  };

  const getStudentProfile = async () => {
    if (!id) {
      return;
    }
    try {
      const response = await getStudentProfileData(id);
      setStudentProfile(response.profile);
    } catch (error) {
      setStudentProfile(defaultStudentProfile);
    }
  };

  useEffect(() => {
    getTallyDataInfo();
    getStudentProfile();
  }, [id]);

  return (
    <div className="profile-page career-os-grid">
      <div className="profile-page__container width-14/24">
        <BasicProfileInfo
          email={studentProfile?.email}
          first_name={studentProfile?.first_name}
          last_name={studentProfile?.last_name}
          profile_picture_url={studentProfile?.profile_picture_url}
          background_image={studentProfile?.background_image}
          degree={studentProfile?.degree}
          summary={studentProfile?.summary}
          university={studentProfile?.university}
        />
        {isCAView ? (
          <ComingSoonStateStudentProfile first_name={studentProfile?.first_name} />
        )
          : (
            <>
              <WorkExperience workExperience={studentProfile?.sections?.work_experiences} />
              <Education education={studentProfile?.sections?.educations} />
              <Skills skills={studentProfile?.sections?.skills} />
            </>
          )}
      </div>
      <div className="width-10/24">
        <TallyInfo
          tallyData={tallyData}
          isCAView={isCAView}
          id={id}
          firstName={studentProfile?.first_name}
          universityName={studentProfile?.university?.name}
        />
      </div>
    </div>
  );
};
