import './industry-sidebar.scss';
import { MiniCompany } from '@/domains/core/advisor/types';

type Props = {
  industry: string;
  closeSidebar: () => void;
  companies: MiniCompany[];
  onClick: (id: string) => void;
};

export const IndustrySidebar = ({
  industry,
  closeSidebar,
  companies,
  onClick,
}: Props) => (
  <div className="industry-sidebar">
    <h2 className="industry-sidebar__title" onClick={closeSidebar}>
      {industry}
    </h2>
    <p className="industry-sidebar__subtitle">Companies with most student engagement.</p>
    <div className="industry-sidebar__companies">
      <div className="industry-sidebar__companies-headers">
        <div className="industry-sidebar__companies-head">Company</div>
        <div className="industry-sidebar__companies-head"># of Students</div>
      </div>
      <div className="industry-sidebar__companies-list">
        {companies.sort((a: MiniCompany, b: MiniCompany) => (a.saved_count > b.saved_count ? -1 : 1)).map((company: MiniCompany) => (
          <div key={company.id} className="industry-sidebar__companies-item" onClick={() => onClick(company.id)}>
            <div className="industry-sidebar__companies-item-company">
              <img className="industry-sidebar__companies-item-logo" src={company.logo_url} alt="company logo" />
              <span className="industry-sidebar__companies-item-title">{company.name}</span>
            </div>
            <div className="industry-sidebar__companies-item-students">
              {company.saved_count}
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
