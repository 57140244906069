/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Font,
  Link,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';

import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';
import {
  computeSkillsStyle,
  renderAdditionalHighlightedText,
  renderWorkHighlightedText,
  trimUrlPrefix,
} from '../helpers/utils';

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: 400 }, { src: CalibriBold, fontWeight: 700 }, { src: CalibriItalic, fontStyle: 'italic' }] });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: 'white',
    padding: '40 60',
    fontFamily: 'Calibri',
    fontSize: 10,
    lineHeight: 1.2,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: 3,
  },
  sectionTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderBottom: '1 solid #000',
    color: '#0A305B',
    marginTop: 15,
    marginBottom: 5,
    paddingBottom: 1,
  },
  content: {
    fontSize: 10,
    marginBottom: 3,
  },
  workDates: {
    fontSize: 10,
    marginBottom: 3,
    marginLeft: 'auto',
  },
  bold: {
    fontWeight: 'bold',
  },
  workPosition: {
    fontSize: 10,
    marginBottom: 3,
    marginRight: 80,
  },
  bullet: {
    width: 10,
  },
  experienceItem: {
    marginBottom: 8,
  },
  experienceHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  companyDescription: {
    fontSize: 10,
    fontStyle: 'italic',
    marginBottom: 3,
  },
  EdRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: 15,
    width: '100%',
  },
  EdDescription: {
    fontSize: 10,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftColumn: {
    width: '30%',
  },
  rightColumn: {
    width: '70%',
    alignItems: 'flex-end',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bulletPoint: {
    width: 10,
    fontSize: 9,
  },
  name: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 5,
    color: '#0A305B',
  },
  contactInfo: {
    fontSize: 10,
    display: 'flex',
    columnGap: 5,
    marginBottom: 2,
  },
  link: {
    color: 'black',
    textDecoration: 'none',
  },
  highlightText: {
    fontSize: 10,
    color: '#000000',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 'auto',
  },
  normalWord: {
    color: 'black',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontFamily: 'Calibri',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontFamily: 'Calibri',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  highlights: {
    marginTop: 5,
    paddingLeft: 15,
    width: '100%',
  },
  bulletColor: {
    color: '#0A305B',
  },
});

type ESMTATSTemplateProps = {
  resume: ResumeSchema;
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null
};

const ESMTATSTemplate: React.FC<ESMTATSTemplateProps> = ({ resume, highlightedWords, activeField }) => {
  let previousWorkName = '';
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <View style={styles.leftColumn}>
            <Text style={styles.name}>{resume.basics.name}</Text>
            {resume.basics.nationality && resume.basics.nationality.length > 0 && (
              <Text style={styles.contactInfo}>
                Nationality:
                {' '}
                {resume.basics.nationality}
              </Text>
            )}
            {resume.basics.birth_date && (
              <Text style={styles.contactInfo}>
                Date of birth:
                {' '}
                {resume.basics.birth_date}
              </Text>
            )}
          </View>
          <View style={styles.rightColumn}>
            <View style={styles.row}>
              <Text style={styles.contactInfo}>{resume?.basics?.location?.address}</Text>
              <Text style={styles.contactInfo}>{resume?.basics?.location?.city}</Text>
            </View>
            <Text style={styles.contactInfo}>
              {resume.basics?.emails?.map((email) => (
                <Text key={email}>
                  <Link style={styles.link} key={email} href={`mailto:${email}`}>
                    {' '}
                    {' '}
                    {email}
                  </Link>
                </Text>
              ))}
              <Text style={styles.bulletColor}>
                {' '}
                •
                {' '}
              </Text>
              {resume.basics.phone_numbers?.map((phone) => (
                <Text key={phone}>
                  {phone}
                </Text>
              ))}
            </Text>
            <Link style={styles.link} key={resume.basics.url} href={`https://www.${resume.basics.url && (trimUrlPrefix(resume.basics.url))}`}>
              {resume.basics.url && (trimUrlPrefix(resume.basics.url))}
            </Link>
          </View>
        </View>

        {/* Professional Summary */}
        <View>
          <Text style={styles.sectionTitle}>Professional Summary</Text>
          <Text style={styles.content}>{resume.basics.summary}</Text>
        </View>

        {/* Skills */}
        <View>
          <Text style={styles.sectionTitle}>SKILLS & ACCOMPLISHMENTS</Text>

          <View style={styles.listItem} wrap={false}>
            <Text style={styles.bulletPoint}>•</Text>
            <View style={styles.highlightText}>
              <Text style={computeSkillsStyle(highlightedWords, activeField, styles)}>
                <Text style={styles.bold}>
                  Skills:
                  {' '}
                </Text>
                {resume.skills?.map((skill) => `${skill.name}${skill.level && ` (${skill.level})`}`).join(', ')}
              </Text>
            </View>
          </View>
        </View>

        {resume.additional_information.bullets?.map((bullet, bulletIndex) => bullet.replace(/^•\s/, '') && (
        <View key={`${bullet}-${bulletIndex}`} style={styles.listItem} wrap={false}>
          <Text style={styles.bulletPoint}>•</Text>
          <View style={styles.highlightText}>
            {renderAdditionalHighlightedText(bullet.replace(/^•\s/, ''), bulletIndex, highlightedWords, activeField, styles)}
          </View>
        </View>
        ))}

        {/* Professional Experience */}
        <View>
          <Text style={styles.sectionTitle}>Professional Experience</Text>
          {resume.work.map((job, index) => {
            const showWorkName = job.name !== previousWorkName;
            previousWorkName = job.name as string;
            return (
              <View key={index} style={styles.experienceItem}>
                {showWorkName ? (
                  <View>
                    <View style={styles.experienceHeader}>
                      <Text style={styles.workPosition}>
                        <Text style={styles.bold}>{job.position}</Text>
                      </Text>
                      <Text style={styles.content}>
                        {job.location}
                      </Text>
                    </View>
                    <View style={styles.experienceHeader}>
                      <Text style={styles.content}>
                        <Text style={styles.bold}>{job.name}</Text>
                      </Text>
                      <Text style={styles.content}>
                        {job.startDate}
                        {' '}
                        –
                        {' '}
                        {job.endDate}
                      </Text>
                    </View>
                    {job.summary && <Text style={styles.companyDescription}>{job.summary}</Text>}
                  </View>
                ) : (
                  <View>
                    <View style={styles.experienceHeader}>
                      <Text style={styles.content}>
                        <Text style={styles.bold}>{job.position}</Text>
                      </Text>
                      <Text style={styles.content}>
                        {job.location}
                      </Text>
                    </View>
                    <View style={styles.experienceHeader}>
                      <Text style={styles.workDates}>
                        {job.startDate}
                        {' '}
                        –
                        {' '}
                        {job.endDate}
                      </Text>
                    </View>
                  </View>
                )}
                {job.highlights && job.highlights.length > 0 && (
                <View style={styles.highlights}>
                  {job.highlights.map((highlight, highlightIndex) => {
                    const cleanedHighlight = highlight.replace(/^•\s/, '');

                    return (
                      <View key={`${highlight}-${highlightIndex}`} style={styles.listItem}>
                        <Text style={styles.bulletPoint}>•</Text>
                        <View style={styles.highlightText}>
                          {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                        </View>
                      </View>
                    );
                  })}
                </View>
                )}
              </View>
            );
          })}
        </View>

        {/* Education */}
        <View>
          <Text style={styles.sectionTitle}>Education</Text>
          {resume.education.map((edu, index) => (
            <View key={index} style={{ marginBottom: 5 }}>
              <View style={styles.experienceHeader}>
                <Text style={styles.content}>
                  <Text style={styles.bold}>{edu.area}</Text>
                </Text>
                <Text style={styles.content}>
                  {edu.startDate}
                  {' '}
                  –
                  {' '}
                  {edu.endDate}
                </Text>
              </View>
              <Text style={styles.content}>
                {edu.institution}
                {' '}
                -
                {' '}
                {edu.location}
              </Text>
              {edu.description
              && edu.description.map((desc) => (
                <View key={desc} style={styles.EdRow}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.EdDescription}>{desc}</Text>
                </View>
              ))}
            </View>
          ))}
        </View>

        {/* Languages */}
        <View>
          <Text style={styles.sectionTitle}>Languages</Text>
          <Text style={styles.content}>
            {resume.languages.map((lang, index) => (
              `${lang.language} (${lang.fluency})${index < resume.languages.length - 1 ? ' • ' : ''}`
            ))}
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default ESMTATSTemplate;
