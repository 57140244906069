import { useDeferredValue, useEffect, useState } from 'react';
import { Loader } from '@/components/loader/loader';

import './student-contacts-tab.scss';
import {
  Contact, ContactStatus, contactStatuses, ContactWithNetworkingData,
} from '@/domains/core/contact/types';
import { ContactsTableColumnsCA, mapReadonlyContacts } from '@/domains/core/netwoking/constants/contact-table';
import { TableRow } from '@/components/table/table-types';
import { useSelf } from '@/services/queries/user';
import { useGetStudentContact } from '@/services/queries/student';
import { StudentContactsOverview } from '../student-contacts-overview/student-contacts-overview';
import { filterPresavedContacts, getContactsKanbanLayout } from '@/domains/core/netwoking/constants/contacts-kanban';

type Props = {
  studentID: string;
  studentName: string;
};

type KanbanBoard = {
  [K in ContactStatus]: ContactWithNetworkingData[];
};

export const StudentContactsTab = ({ studentID, studentName }: Props) => {
  const { data: contacts, isLoading: areContactsLoading, isRefetching: isRefetchingContacts } = useGetStudentContact(studentID ?? '', {
    enabled: !!studentID,
  });

  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [kanbanLayout, setKanbanLayout] = useState<KanbanBoard | null>(null);
  const [nonPresavedContacts, setNonPresavedContacts] = useState<Contact[]>(filterPresavedContacts(contacts));
  const { data: self } = useSelf();
  const deferredQuery = useDeferredValue(searchQuery);
  const computeKanbanLayout = (allContacts: Contact[]) => {
    setKanbanLayout(getContactsKanbanLayout(allContacts));
  };

  const filterContacts = (queryString: string, contactsToFilter: ContactWithNetworkingData[] | undefined) => {
    const filteredResults = Array.isArray(contactsToFilter) ? contactsToFilter?.filter((contact) => {
      const fullName = `${contact.first_name} ${contact.last_name}`;
      return fullName.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.company.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.title.toLowerCase().includes(queryString.toLowerCase());
    }) : [];
    const filteredNonArchived = filteredResults?.filter((contact) => contact.kanban_position.status !== 'archive');

    setTableData(mapReadonlyContacts(filteredNonArchived || [], () => {}, () => {}));
    computeKanbanLayout(filteredResults || []);
  };

  useEffect(() => {
    if (deferredQuery) {
      filterContacts(deferredQuery.trim(), contacts);
    } else {
      filterContacts('', contacts);
    }
  }, [deferredQuery]);

  useEffect(() => {
    if (contacts?.length) {
      filterContacts(searchQuery, contacts);
    }
  }, [contacts]);

  useEffect(() => {
    if (self && !areContactsLoading && !isRefetchingContacts && contacts) {
      setNonPresavedContacts(filterPresavedContacts(contacts));
      filterContacts(searchQuery, contacts);
    }
  }, [contacts?.length, self?.id, areContactsLoading, isRefetchingContacts]);

  if (areContactsLoading || !kanbanLayout) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="student-OB-contacts">
      {Array.isArray(contacts) && kanbanLayout && nonPresavedContacts.length > 0 ? (
        <StudentContactsOverview
          columns={contactStatuses}
          layout={kanbanLayout}
          tableColumns={ContactsTableColumnsCA}
          handleSearchQueryChange={setSearchQuery}
          searchQuery={searchQuery}
          tableData={tableData}
        />
      ) : (
        <div className="student-OB-resumes__empty">
          <h2 className="student-OB-resumes__empty-title">
            Looks like
            {' '}
            {studentName}
            {' '}
            hasn’t save any contacts yet.
          </h2>
          <p className="student-OB-resumes__empty-subtitle">Let’s get them started on networking. You might need to send them an email to nudge them.</p>
        </div>
      )}
    </div>
  );
};
