import {
  useMutation, useQuery, useQueryClient, UseQueryOptions,
} from 'react-query';
import {
  getCompanyContacts, getContact, getContacts, getNotesByContact,
  saveContact,
} from '../api/contact';
import { Contact, SuggestedContactType } from '@/domains/core/contact/types';
import { Note } from '@/domains/core/company/types';
import { getCampusChampionsByCompanyID, getSuggestedContacts, getSuggestedContactsByCompanyID } from '../api/contacts';

export const useGetContacts = (options = {}) => useQuery(
  'contacts',
  async () => {
    const response = await getContacts();
    return response ?? [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 1, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetContactsNoCache = (options = {}) => useQuery(
  'contacts-no-cache',
  async () => {
    const response = await getContacts();

    return response ?? [];
  },
  {
    cacheTime: Infinity, // refetch on tab change
    retry: 0,
    ...options, // Spread additional options
  },
);

export const useGetContact = (contactID: string | undefined, options = {}) => useQuery(
  ['contact', contactID],
  async () => {
    if (contactID) {
      const response: Contact | { error: any } = await getContact(contactID);

      return 'error' in response ? undefined : response;
    }
    return undefined;
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetContactNotes = (contactID: string | undefined, options = {}) => useQuery(
  ['contact-notes', contactID],
  async () => {
    if (contactID) {
      const response: Note[] | { error: any } = await getNotesByContact(contactID);

      return 'error' in response ? [] : response;
    }
    return [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useSuggestedContacts = (options = {}) => useQuery(
  'suggested-contacts',
  async () => {
    const response: SuggestedContactType[] | null = await getSuggestedContacts();

    if (!Array.isArray(response)) {
      return [];
    }

    return response;
  },
  {
    cacheTime: Infinity, // refetch on tab change
    retry: 0,
    ...options, // Spread additional options
  },
);

export const useSuggestedContactsByCompanyID = (companyID: string, options = {}) => useQuery(
  ['company-suggested-contacts', companyID],
  async () => {
    if (companyID) {
      const response: SuggestedContactType[] | { error: any } = await getSuggestedContactsByCompanyID(companyID);
      if (response) {
        return !Array.isArray(response) ? [] : response;
      }
    }
    return [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetSavedContactsByCompany = (companyID: string, options: Omit<UseQueryOptions<Contact[], unknown, Contact[], string[]>, 'queryKey' | 'queryFn'> = {}) => useQuery(
  ['company-saved-contacts', companyID],
  async () => {
    if (companyID) {
      const response: Contact[] | { error: any } = await getCompanyContacts(companyID);

      if (!Array.isArray(response)) {
        return [];
      }

      return response;
    }
    return [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useGetCampusChampionsByCompanyID = (companyID: string, options = {}) => useQuery(
  ['company-campus-champions', companyID],
  async () => {
    if (companyID) {
      const response: SuggestedContactType[] | { error: any } = await getCampusChampionsByCompanyID(companyID);

      if (!Array.isArray(response)) {
        return [];
      }

      return response;
    }
    return [];
  },
  {
    retry: 0,
    staleTime: 1000 * 60 * 60 * 8, // refetch in the background every 8 hours
    ...options, // Spread additional options
  },
);

export const useSaveContactMutation = () => {
  const queryClient = useQueryClient();

  // Define the expected type for the variables passed to the mutation
  type SaveContactVariables = {
    suggestedContact: SuggestedContactType;
    companyID: string;
  };

  return useMutation<any, unknown, SaveContactVariables>(
    async ({ suggestedContact, companyID }: SaveContactVariables) => saveContact(suggestedContact, companyID),
    {
      onSuccess: (companyID) => {
        queryClient.invalidateQueries('contacts-no-cache');
        queryClient.invalidateQueries('gamification-actions');
        queryClient.invalidateQueries('challenges');
        queryClient.invalidateQueries('xp');
        queryClient.invalidateQueries('leaderboards');
        queryClient.invalidateQueries('company-saved-contacts');
        queryClient.invalidateQueries(['company-suggested-contacts', companyID]);
        queryClient.invalidateQueries(['company-saved-contacts', companyID]);
        queryClient.invalidateQueries(['company-campus-champions', companyID]);
        queryClient.invalidateQueries(['suggested-contacts']);
        queryClient.invalidateQueries('contacts');
        queryClient.invalidateQueries('quests');
      },
    },
  );
};
