import { DefaultLogo } from '@careeros/coco';
import './alum-header.scss';
import { AlumTagType } from '@/types/alum';
import AlumTag from '../alum-tag/alum-tag';

type Props = {
  badges: AlumTagType;
  image: string;
  name: string;
  position: string;
  companyName: string;
  email: string;
  linkedInUrl: string;
  location: string;
};

export const AlumHeader = ({
  badges,
  image,
  name,
  position,
  companyName,
  email,
  linkedInUrl,
  location,
}: Props) => {
  const tags = [
    ...(badges?.graduated ? [{ tag_content: '🎓 Graduated' }] : []),
    ...(badges?.hiring_success ? [{ tag_content: '🤩 Hiring Success' }] : []),
    ...(badges?.location ? [{ tag_content: `📍 ${location}` }] : []),
    ...(badges?.isCareerosUser ? [{ tag_content: '🚀 CareerOS Superstar' }] : []),
    ...(badges?.degree ? [{ tag_content: badges.degree }] : []),
    ...(badges?.graduation ? [{ tag_content: badges.graduation }] : []),
  ];

  return (
    <div className="alum-header">
      <div className="alum-header__main-info">
        <div className="alum-header__company-logo">
          <DefaultLogo
            source={image}
            type="contact"
            name={name}
            className="alum-header__company-logo"
            data-testid="job-logo"
            size={48}
          />
        </div>
        <div className="alum-header__job-details">
          <div className="alum-header__job-title" data-testid="job-title">{name}</div>
          <div className="alum-header__job-subtitle">
            <div className="alum-header__job-company-name">{companyName}</div>
            •
            <p className="alum-header__job-company-position">{position}</p>
          </div>
        </div>

      </div>
      <div className="alum-header__tags">
        {tags.map((tag) => (
          <AlumTag key={tag.tag_content} tag={tag} />
        ))}
      </div>
      <div className="alum-header__infos">
        {linkedInUrl && (
          <div className="alum-header__info">
            <i className="bi bi-linkedin" />
            <a href={linkedInUrl} target="_blank" rel="noreferrer">{linkedInUrl}</a>
          </div>
        )}
        {email && (
          <div className="alum-header__info">
            <i className="bi bi-envelope" />
            <a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
          </div>
        )}
      </div>
    </div>
  );
};
