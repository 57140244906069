import {
  useContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from 'react';
import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import {
  changeApplicationStatus,
} from '@/domains/core/company';
import { ApplicationStatus, statuses } from '@/domains/core/tracker-data';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { OverviewContent } from '@/domains/generic';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import {
  filterTiles, filterTilesByStatus, getAllSavedIndustries, sortByPosition,
  universitiesWithPostingScore,
} from '@/services/helpers/company';
import {
  FilterNameType, FilterOption, Tile,
} from '@/domains/core/company/types';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useApplicationsNoCache } from '@/services/queries/application';
import './styles/overview.scss';
import { CommentsHistorySidebar } from '@/domains/generic/sidebars';
import { CommentFromCA } from '@/domains/core/advisor/types';
import { useStudentComments } from '@/services/queries/student';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';
import { useSelfUniversity } from '@/services/queries/user';

const companyStatuses: string[] = [
  'Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Rejected',
];

function OverviewPage() {
  const { data: tiles = [], isLoading: isTilesLoading, isFetching: isFetchingTiles } = useApplicationsNoCache();
  const { data: commentsResponse } = useStudentComments();
  const { trackEvent } = useAnalytics();
  const { user } = useAuth0();
  const { data: university } = useSelfUniversity();
  const { addToast }: AddToastType = useContext(ToastContext);
  const [kanbanLayout, setKanbanLayout] = useState({});
  const [currentFilters, setCurrentFilters] = useState<{ [name in FilterNameType]: FilterOption[] }>();
  const { openSidebar } = useContext(SidebarContext) as any;
  const [industries, setIndustries] = useState(getAllSavedIndustries(tiles));
  const [searchValue, setSearchValue] = useState('');
  const [filteredTilesByStatus, setFilteredTilesByStatus] = useState<Tile[]>([]);
  const { completeActionIfAvailable } = useGamification();

  const hasPostingScore = useMemo(() => university && universitiesWithPostingScore.includes(university.name), [university]);
  const hasUnresolvedComments = useMemo(() => {
    if (!commentsResponse) {
      return false;
    }
    return commentsResponse.some((comment: CommentFromCA) => !comment.resolved);
  }, [commentsResponse]);
  const unresolvedCommentIDs = useMemo(() => {
    if (!commentsResponse) {
      return [];
    }
    return commentsResponse.filter((comment: CommentFromCA) => !comment.resolved).map((comment: CommentFromCA) => comment.id);
  }, [commentsResponse]);
  const queryClient = useQueryClient();
  const areFiltersEmpty = (!currentFilters || Object.values(currentFilters).filter((v) => v && v.length > 0).length === 0) && !searchValue.length;
  const [searchParams] = useSearchParams();
  const [hasOpenedSidebar, setHasOpenedSidebar] = useState(false);

  const computeKanbanLayout = (allCompanies: Tile[]) => {
    if (!allCompanies || !Array.isArray(allCompanies)) {
      setKanbanLayout({});
      return;
    }
    const kanban = {
      Saved: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'saved')),
      Networking: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'networking')),
      Applying: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applying')),
      Applied: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applied')),
      Interviewing: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'interviewing')),
      Offer: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'offer')),
      Rejected: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'reject')),
    };
    setKanbanLayout(kanban);
  };

  const handleFiltering = (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => {
    setCurrentFilters(filters);
    setSearchValue(searchQuery);
    const filteredResult = filterTiles(tiles, filters, searchQuery);
    const tilesFilteredByStatusRes = filterTilesByStatus(filteredResult, Object.values(filters.status));

    setFilteredTilesByStatus(tilesFilteredByStatusRes);
    computeKanbanLayout(filteredResult);
  };

  const openFullCommentHistory = useCallback(() => {
    openSidebar(
      <CommentsHistorySidebar
        withFilter
        title="Full Comments History"
      />,
    );
  }, [openSidebar]);

  const trackStatusChange = (status: string, newStatus: string, companyName: string) => {
    if (status === newStatus) {
      return;
    }

    if (newStatus !== 'Saved') {
      trackEvent(`Company moved to ${newStatus}`, user, { company: companyName });
    }

    if (newStatus === 'Offer') {
      completeActionIfAvailable(GAMIFICATION_ACTIONS.RECEIVED_OFFER);
    }

    trackEvent(`Overview Board: Company Dropped from ${status} to ${newStatus}`);
    trackEvent('Overview Board: Company Status change');
    trackEvent('Kanban Board Update', user);
  };

  // Handle Data
  const cardChangeHandler = async (cardInfo: any, newStatus: ApplicationStatus) => {
    const {
      id, status, dropPosition,
    } = cardInfo;
    const newStatusForAPI = statuses[newStatus];
    const newKanban: any = { ...kanbanLayout };
    const oldKanban: any = { ...kanbanLayout };
    const tile = newKanban[status].find((company: Tile) => company.id === id);
    if (!tile) {
      return;
    }
    newKanban[status] = newKanban[status].filter((company: Tile) => company.id !== id);

    // Insert the tile at the specific index provided in dropPosition
    newKanban[newStatus].splice(dropPosition, 0, tile);

    setKanbanLayout(newKanban);
    trackStatusChange(status, newStatus, tile.company_name);

    const response = await changeApplicationStatus(id, newStatusForAPI, dropPosition);
    // Handle the API response and possible error states
    if (response?.status === newStatusForAPI) {
      addToast({
        type: 'success',
        message: 'Company status updated',
        additionalMessage: 'The company status has been successfully updated.',
      });
      completeActionIfAvailable(GAMIFICATION_ACTIONS.COMPANY_STATUS_CHANGE);
      if (newStatus === 'Interviewing') {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.GETTING_INTERVIEW);
      }
    } else {
      addToast({
        type: 'error',
        message: 'Failed to update company status',
        additionalMessage: "We're looking into the issue. Please try again later.",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update company status',
        error: response.error,
      });
      oldKanban[newStatus] = oldKanban[newStatus].filter((company: Tile) => company.id !== id);
      setKanbanLayout(oldKanban);
    }
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);
    queryClient.invalidateQueries(['dashboard-actions']);
  };

  const checkForUnresolvedComments = () => {
    const openedUnresolvedComments = localStorage.getItem('openedUnresolvedComments');
    const parsedOpenedUnresolvedComments = JSON.parse(openedUnresolvedComments || '[]') || [];
    const hasNewUnresolvedComments = unresolvedCommentIDs.some((id) => !parsedOpenedUnresolvedComments.includes(id));

    if (unresolvedCommentIDs.length > 0 && hasNewUnresolvedComments) {
      openFullCommentHistory();
      localStorage.setItem('openedUnresolvedComments', JSON.stringify(unresolvedCommentIDs));
    }
  };

  useEffect(() => {
    if (isTilesLoading || isFetchingTiles || !Array.isArray(tiles)) {
      return;
    }

    if (!areFiltersEmpty && currentFilters) {
      handleFiltering(currentFilters, searchValue);
    } else {
      computeKanbanLayout(tiles);
      setFilteredTilesByStatus(tiles.filter((tile: Tile) => tile.status !== statuses.Archived));
    }

    setIndustries(getAllSavedIndustries(tiles));
  }, [tiles, isTilesLoading, isFetchingTiles]);

  useEffect(() => {
    trackEvent('Page Visit', {}, {
      pageURL: 'Overview Board',
    });
    queryClient.invalidateQueries(['studentComments']);
  }, []);

  useEffect(() => {
    if (searchParams.get('openSidebar') === 'true' && commentsResponse && !hasOpenedSidebar) {
      openFullCommentHistory();
      setHasOpenedSidebar(true);
    }
  }, [searchParams, commentsResponse]);

  useEffect(() => {
    if (unresolvedCommentIDs.length > 0) {
      checkForUnresolvedComments();
    }
  }, [unresolvedCommentIDs]);

  return (
    <div className="overview-board" id="main">
      <OverviewContent
        columns={companyStatuses}
        layout={kanbanLayout}
        cardChangeHandler={cardChangeHandler}
        handleFiltersChange={handleFiltering}
        savedIndustries={industries}
        openFullCommentHistory={openFullCommentHistory}
        tiles={filteredTilesByStatus}
        hasUnresolvedComments={hasUnresolvedComments}
        hasPostingScore={hasPostingScore}
      />
    </div>
  );
}

export default CustomWithAuthenticationRequired(OverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
