import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplateEditor } from '../custom-template-editor/custom-template-editor';
import { ContactPlaceholders, TemplatePreviewType, TemplateType } from '../../types';
import { CustomTemplatesContainer } from '../custom-templates-container/custom-templates-container';

import './custom-templates-modal.scss';
import {
  deleteCustomTemplate, saveCustomTemplate, updateCustomTemplate,
} from '@/services/api/template';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useGamification, GAMIFICATION_ACTIONS } from '@/services/hooks/use-gamification';
import { useGetCustomTemplates } from '@/services/queries/template';

type Props = {
  contactPlaceholders: ContactPlaceholders;
  hasLinkedInPremium?: boolean;
  contactEmail?: string;
  previewType: TemplatePreviewType;
  selectTemplate: (message: string, subject?: string) => void;
  trackEventText?: string;
};

export const CustomTemplatesModal = ({
  contactPlaceholders,
  previewType,
  selectTemplate,
  contactEmail,
  hasLinkedInPremium = false,
  trackEventText,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { closeModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);
  const { data: templatesData, isLoading } = useGetCustomTemplates();
  const [templates, setTemplates] = useState<TemplateType[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isChangingTemplate, setIsChangingTemplate] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateType>();
  const { completeActionIfAvailable } = useGamification();
  const queryClient = useQueryClient();

  const getMaxLength = () => {
    if (previewType !== 'connection') {
      return undefined;
    }

    return hasLinkedInPremium ? 300 : 200;
  };

  const handleEditTemplate = (template?: TemplateType) => {
    setSelectedTemplate(template);
    setIsEditing(true);

    if (template) {
      setIsChangingTemplate(true);
    }
  };

  const prepareTemplates = async (data: TemplateType[]) => {
    if (data) {
      const sortedTemplates = data.sort((a: TemplateType, b: TemplateType) => b.created_at?.localeCompare(a.created_at || '') || 0);

      if (sortedTemplates.length > templates.length) {
        setSelectedTemplate(sortedTemplates[0]);
      }

      setTemplates(sortedTemplates);
    }
  };

  const useTemplate = (message: string, subject?: string) => {
    selectTemplate(message, subject);
  };

  const handleCloseEditor = () => {
    setIsEditing(false);
    setIsChangingTemplate(false);
  };

  const handleCancelEditing = () => {
    if (templates.length === 0) {
      closeModal();
    }

    handleCloseEditor();
  };

  const removeTemplate = async (templateId: string) => {
    try {
      await deleteCustomTemplate(templateId);
      queryClient.invalidateQueries(['custom-templates']);
      trackEvent('Custom template deleted');
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error while deleting template',
      });
    }
  };

  const handleSaveTemplate = async (title: string, message: string, subject?: string) => {
    try {
      const templateTitle = title.trim().length > 0 ? title : 'Untitled Template';

      if (isChangingTemplate) {
        const updatedTemplate = {
          subject: subject || '',
          message,
          title: templateTitle,
          id: selectedTemplate?.id || '',
        };

        await updateCustomTemplate(updatedTemplate);
        trackEvent('Custom template updated');
        setSelectedTemplate(updatedTemplate);
      } else {
        await saveCustomTemplate({ title: templateTitle, message, subject });
        trackEvent('Custom template created');
        if (templates.length === 0) {
          completeActionIfAvailable(GAMIFICATION_ACTIONS.CREATE_FIRST_TEMPLATE);
        } else {
          completeActionIfAvailable(GAMIFICATION_ACTIONS.CREATE_TEMPLATE);
        }
      }

      await queryClient.invalidateQueries(['custom-templates']);
      handleCloseEditor();
      if (trackEventText) {
        trackEvent(trackEventText);
      }
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error while saving template',
      });
    }
  };

  useEffect(() => {
    trackEvent('Custom templates modal opened');
  }, []);

  useEffect(() => {
    if (!isLoading) {
      prepareTemplates(templatesData || []);
    }
  }, [templatesData, isLoading]);

  return (
    <div className="templates-modal">
      <div className="templates-modal__header">
        <h2 className="templates-modal__title">
          Custom Templates
        </h2>
        <div
          className="templates-modal__close-button"
          onClick={closeModal}
          aria-label="Close"
          role="button"
          tabIndex={0}
        >
          <i className="templates-modal__close-icon bi bi-x-circle" />
          <i className="templates-modal__close-icon templates-modal__close-icon--hover bi bi-x-circle-fill" />
        </div>
      </div>

      {isLoading && (
        <div className="templates-modal__loading">
          <div className="templates-modal__loading-spinner" />
        </div>
      )}

      {isEditing && !isLoading && (
        <CustomTemplateEditor
          defaultContent={selectedTemplate?.message}
          defaultSubject={selectedTemplate?.subject}
          defaultTitle={selectedTemplate?.title}
          templateType={previewType}
          handleCancel={handleCancelEditing}
          handleSaveTemplate={handleSaveTemplate}
          contactPlaceholders={contactPlaceholders}
          maxLength={getMaxLength()}
          contactEmail={contactEmail}
        />
      )}

      {!isEditing && !isLoading && (
        <CustomTemplatesContainer
          previewType={previewType}
          templates={templates}
          handleEditTemplate={handleEditTemplate}
          handleRemoveTemplate={removeTemplate}
          handleCancel={closeModal}
          handleUseTemplate={useTemplate}
          contactPlaceholders={contactPlaceholders}
          contactEmail={contactEmail}
          selectTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
          maxLength={getMaxLength()}
        />
      )}
    </div>
  );
};
