import { useMemo } from 'react';
import { DefaultLogo } from '@careeros/coco';

import { Tag, TagColors } from '@/components/tag/tag';
import './alum-book-tile.scss';
import { Alum, AlumniBookFiltersType } from '@/types/alum';
import {
  getCurrentCompany,
  getCurrentPosition,
  getDegreeTag,
  getGraduationTag,
  parseCareerSummary,
} from '../../helpers/helpers';
import { CareerType } from '@/domains/core/contact/types';

type Props = {
  alum: Alum;
  isActive?: boolean;
  onSelect: (alumId: string) => void;
  setFilters: (filters: AlumniBookFiltersType) => void;
  filters: AlumniBookFiltersType;
};

export const AlumBookTile = ({
  alum,
  isActive = false,
  onSelect,
  setFilters,
  filters,
}: Props) => {
  const degreeTag = useMemo(() => getDegreeTag(alum), [alum]);
  const graduationTag = useMemo(() => getGraduationTag(alum), [alum]);
  const currentPosition = useMemo(() => getCurrentPosition(alum), [alum]);
  const currentCompany = useMemo(() => getCurrentCompany(alum), [alum]);

  const tags = [
    ...(alum?.has_given_permission ? [{ tag_content: '🚀 CareerOS Superstar' }] : []),
    ...(degreeTag ? [degreeTag] : []),
    ...(graduationTag ? [graduationTag] : []),
  ];

  const parsedCareerSummary = useMemo(
    () => parseCareerSummary(alum?.career_summary.profile) as CareerType,
    [alum?.career_summary.profile],
  );

  const basicInfo = useMemo(
    () => (parsedCareerSummary ? parsedCareerSummary.basics : null),
    [parsedCareerSummary],
  );

  return (
    <div
      className={['alum-tile', isActive ? 'alum-tile--active' : ''].join(' ')}
      onClick={() => onSelect(alum.id)}
    >
      <div className="alum-tile__main">
        <div className="alum-tile__main-top">
          <DefaultLogo
            source={basicInfo?.image || ''}
            type="contact"
            name={alum?.first_name || 'Rockstar'}
            className="alum-tile__company-logo"
            size={64}
          />
          <p className="alum-tile__name">{`${alum?.first_name} ${alum?.last_name}`}</p>
          <p
            className="alum-tile__company-name"
            onClick={() => setFilters({
              ...filters,
              companies: [...filters.companies, {
                name: currentCompany,
                value: currentCompany,
              }],
            })}
          >
            {currentCompany}
          </p>
          <p className="alum-tile__position">{currentPosition}</p>

        </div>
      </div>
      <div className="alum-tile__bottom">
        <div className="alum-tile__with-tooltip" />
        {tags.map((tag) => (
          <Tag
            key={tag.tag_content}
            color={TagColors.Grey}
            label={tag.tag_content}
          />
        ))}
      </div>
    </div>
  );
};
