import { ApiStudent } from '@/domains/core/advisor/types/api/student';
import { convertFromApiStatus, convertFromApiStatusForCompany } from '@/domains/core/advisor/api/status';
import { convertFromApiMiniAdvisors } from '@/domains/core/advisor/api/model/advisor';
import { StudentModel } from '@/domains/core/advisor/types';

export const convertFromApiStudent = (apiStudent: ApiStudent): StudentModel => ({
  id: apiStudent.id,
  firstName: apiStudent.first_name,
  lastName: apiStudent.last_name,
  email: apiStudent.email,
  profileImage: apiStudent.profile_picture_url,
  status: convertFromApiStatus(apiStudent.status),
  cohort: apiStudent.cohort,
  assignedAdvisors: apiStudent.assigned_advisors ? convertFromApiMiniAdvisors(apiStudent.assigned_advisors) : [],
  tags: apiStudent.tags,
  lastActivityAt: apiStudent.last_activity_at,
});

export const convertFromApiStudentForCompany = (apiStudent: ApiStudent): StudentModel => ({
  id: apiStudent.id,
  firstName: apiStudent.first_name,
  lastName: apiStudent.last_name,
  email: apiStudent.email,
  profileImage: apiStudent.profile_picture_url,
  status: convertFromApiStatusForCompany(apiStudent.status),
  cohort: apiStudent.cohort,
  assignedAdvisors: apiStudent.assigned_advisors ? convertFromApiMiniAdvisors(apiStudent.assigned_advisors) : [],
  tags: apiStudent.tags,
  lastActivityAt: apiStudent.last_activity_at,
});

export const convertFromApiStudents = (apiStudents: ApiStudent[]): StudentModel[] => apiStudents.map(convertFromApiStudent);
export const convertFromApiStudentsForCompany = (apiStudents: ApiStudent[]): StudentModel[] => apiStudents.map(convertFromApiStudentForCompany);
