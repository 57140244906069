/* eslint-disable no-underscore-dangle */
import { User } from '@auth0/auth0-react';
import { setUser } from '@sentry/react';
import aa from 'search-insights';
import { Mixpanel } from '../hooks/mixpanel';

// Initialize Algolia Insights
aa('init', {
  appId: import.meta.env.VITE_ALGOLIA_APP_ID,
  apiKey: import.meta.env.VITE_ALGOLIA_API_KEY,
  useCookie: true,
});

export function getUserRole(user: User): string {
  if (user && Array.isArray(user['https://careeros.com/roles']) && user['https://careeros.com/roles'].length > 0) {
    return user['https://careeros.com/roles'][0];
  }
  return '';
}

export function getUserId(user: User): string {
  return user['https://careeros.com/claims/uuid'];
}
export function getUserRoles(user: User): string[] {
  return user['https://careeros.com/roles'];
}
export function routeByRole(user: User) {
  if (!user['https://careeros.com/roles']) {
    return '/app/dashboard';
  }
  const role = getUserRole(user);

  switch (role) {
    case 'CareerAdvisor':
      return '/app/dashboard-advisor';
    case 'BetaUser':
      return '/app/dashboard-advisor';
    case 'Admin':
      return '/app/admin-view';
    default: // Student role is the default
      return '/app/dashboard';
  }
}

export function setUserInMixpanel(user: User, university: string | null = null, cohort: string | null = null) {
  Mixpanel.identify(getUserId(user));
  Mixpanel.people.set({
    $email: user.email,
    name: user.name,
    Name: user.name,
    role: getUserRole(user),
    school: university,
    program: cohort,
    university,
    cohort,
    completedDemographicsForm: user?.completedDemographicsForm,
  });
}

export function identifyUserInAppcues(user: User, university: string | null = null, cohort: string | null = null) {
  if ((window as any).Appcues) {
    (window as any).Appcues.identify(
      getUserId(user),
      {
        firstName: user.name,
        email: user.email,
        school: university,
        program: cohort,
        university,
        cohort,
        role: getUserRole(user),
        completedDemographicsForm: user?.completedDemographicsForm,
      },
    );
  }
}

export function identifyUserInCustomerIO(user: User, university: string | null = null, cohort: string | null = null) {
  if ((window as any)._cio) {
    (window as any)._cio.identify({
      // Required attributes
      id: getUserId(user),
      name: user.name,
      first_name: user.given_name,
      email_first_name: user.given_name,
      last_name: user.family_name,
      email: user.email,
      role: getUserRole(user),
      school: university,
      program: cohort,
      university,
      cohort,
      completedDemographicsForm: user?.completedDemographicsForm,
    });
  }
}

export function identifyUserInHotjar(user: User) {
  if ((window as any).hj) {
    (window as any).hj('identify', getUserId(user), {
      firstName: user.name,
      email: user.email,
    });
  }
}

export function identifyUserInAlgolia(user: User) {
  if (user) {
    try {
      aa('setAuthenticatedUserToken', getUserId(user));
    } catch (error) {
      console.error('Error setting authenticated user token in Algolia', error);
    }
  }
}

function setUserInSentry(user: User): void {
  if (user) setUser(user);
}

export function trackUser(user: User, trackEvent: any, university: string | null = null, cohort: string | null = null, isIncognito = false) {
  setUserInMixpanel(user, university, cohort);
  identifyUserInAppcues(user, university, cohort);
  identifyUserInHotjar(user);
  identifyUserInCustomerIO(user, university, cohort);
  identifyUserInAlgolia(user);
  if (!isIncognito) setUserInSentry(user);

  trackEvent('Successful login', {});
}
