import { QuestType } from '../../student/types';

export const networkingQuestsTypes = [
  QuestType.AddNewContact,
  QuestType.RespondToEmail,
  QuestType.SaveContact,
  QuestType.SendConnection,
  QuestType.SendFirstEmail,
  QuestType.SendFollowUpEmail,
  QuestType.SendMessage,
  QuestType.ScheduleCoffeeChat,
  QuestType.SendFirstOutreach,
  QuestType.SendSecondOutreach,
  QuestType.ReplyToContact,
];

export const defTabs = ['Tips & Tricks', 'AI Tools', 'Contacts', 'Jobs', 'Notes', 'Company'];
export const defTabsIcons = ['bi bi-lightbulb', 'bi bi-stars', 'bi bi-person', 'bi bi-briefcase', 'bi bi-file-earmark-text', 'bi bi-building'];
export const defTabsActiveIcons = ['bi bi-lightbulb-fill', 'bi bi-stars', 'bi bi-person-fill', 'bi bi-briefcase-fill', 'bi bi-file-earmark-text-fill', 'bi bi-building-fill'];
