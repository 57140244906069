import { CompanyPartnerType } from '@/domains/core/company/types';
import { JOB_SOURCE } from '@/domains/core/job/types/job';

export const getJobBadgeType = (jobSource: string, companyType: CompanyPartnerType | null) => {
  if (!jobSource) {
    return null;
  }

  if (jobSource !== JOB_SOURCE.EMPLOYER_APP && jobSource !== JOB_SOURCE.EMPLOYER_ATS) {
    return null;
  }

  return companyType;
};
