import './OB-tile-preview-skeleton.scss';

export const OBTilePreviewSkeleton = () => (
  <div className="OB-tile-preview-skeleton">
    <div className="OB-tile-preview-skeleton__header">
      <div className="OB-tile-preview-skeleton__title">
        <div className="OB-tile-preview-skeleton__text-skeleton" />
      </div>
      <div className="OB-tile-preview-skeleton__header-right" />
    </div>
    <div className="OB-tile-preview-skeleton__separator" />
    <div className="OB-tile-preview-skeleton__tags">
      <div className="OB-tile-preview-skeleton__tag">
        <div className="OB-tile-preview-skeleton__text-skeleton" />
      </div>
      <div className="OB-tile-preview-skeleton__tag">
        <div className="OB-tile-preview-skeleton__text-skeleton" />
      </div>
      <div className="OB-tile-preview-skeleton__tag">
        <div className="OB-tile-preview-skeleton__text-skeleton" />
      </div>
    </div>
    <div className="OB-tile-preview-skeleton__description">
      <div className="OB-tile-preview-skeleton__text-skeleton" />
    </div>
  </div>
);
