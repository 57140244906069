import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { About } from '@careeros/coco';
import { Company } from '../../types';
import { addCompanyToFavorites } from '@/services/api/favorite';
import './company-overview.scss';
import { Contact } from '@/domains/core/contact/types';
import { CompanyStatusContainer } from '@/domains/core/company';
import { prettifyCompanyOverview } from '@/services/helpers/company';
import { useSelf } from '@/services/queries/user';
import { useAlgoliaInsights } from '@/services/hooks/use-algolia';

interface CompanyOverviewProps {
  company: Company;
  contacts: Contact[];
  application: {
    application_id: string;
    application_status: string
  }
}

/**
 * Primary UI component for the Company Overview Tab
 */
export const CompanyOverviewContainer = ({
  company,
  contacts,
  application,
}: CompanyOverviewProps) => {
  const [aboutCompany, setAboutCompany] = useState('');
  const navigate = useNavigate();
  const insights = useAlgoliaInsights();
  const { data: self } = useSelf();
  const queryClient = useQueryClient();
  const revalidate = () => {
    queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);
    queryClient.invalidateQueries(['dashboard-actions']);
    queryClient.invalidateQueries(['company-profile', company.id]);
  };

  const goToContact = (contactID: string) => {
    navigate(`/app/inbox/${contactID}`);
  };

  const saveCompany = async () => {
    addCompanyToFavorites(company.id).finally(revalidate);
    if (self?.id) {
      insights.purchasedObjectIDs(
        'Company Saved',
        'company',
        [company.id],
        self.id,
      );
    }
  };

  useEffect(() => {
    setAboutCompany(prettifyCompanyOverview(company.overview));
  }, [company.overview]);

  return (
    <div className="company-overview-container">
      <CompanyStatusContainer company={company} application={application} saveCompany={saveCompany} goToContact={goToContact} contacts={contacts} />
      <div className="company-overview-container__data">
        <div className="company-overview-container__about">
          <div className="company-about">
            <h3 className="company-about__title">About</h3>
            <div>{aboutCompany.split(/\n/).map(line => <p className="company-about__text" key={line}>{line}</p>)}</div>
          </div>
        </div>
        <div className="company-overview-container__info">
          <About
            showDescription={false}
            generalInformation={{
              description: company.overview,
              name: company.name,
              location: company.hq_location,
              industry: company.careeros_industry,
              size: company.company_size || '',
              website_url: company.website_url || '',
              linkedin_url: company.linkedin_url,
              twitter_url: company.twitter_url,
              instagram_url: company.instagram_url,
              logo_url: company.logo_url,
              bg_image_url: company.bg_image_url || '',
              show_background: !!company.bg_image_url,
              additional_links: company.additional_links || [],
            }}
          />
        </div>
      </div>
    </div>
  );
};
