import { useContext, useMemo } from 'react';
import { useRouteLoaderData } from 'react-router-dom';
import { Button, InfoModal, Tooltip } from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ResumeOSRelevantContextResponse } from '@/domains/core/resume/types';
import './action-verb-modal.scss';

export const ActionVerbModal = () => {
  const { openModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);

  const { action_verbs: actionVerbs } = useRouteLoaderData('resume-os') as ResumeOSRelevantContextResponse;
  const actionVerbsCapitalized = useMemo(() => actionVerbs.map((verb) => verb.charAt(0).toUpperCase() + verb.slice(1)), []);

  const copyActionVerb = (verb: string) => {
    navigator.clipboard.writeText(verb);

    addToast({
      type: 'success',
      message: 'Action verb copied to clipboard successfully!',
    });
  };

  const showActionVerbsModal = () => {
    openModal(
      <InfoModal
        orientation="vertical"
        title="What action verbs to use?"
        icon="bi bi-lightbulb"
        description={(
          <div className="action-verb-modal__action-verb-container">
            <p className="action-verb-modal__subtitle">Click on any action verb below to copy it to your clipboard, and you can then paste it into your bullet points.</p>
            {actionVerbsCapitalized.map((actionVerb) => (
              <div
                className="action-verb-modal__action-verb-item"
                onClick={() => copyActionVerb(actionVerb)}
              >
                <Tooltip
                  label="Click to copy to clipboard"
                  position="top"
                  key={actionVerb}
                  withArrow
                  size="fit-content"
                >
                  <Button
                    mode="invisible"
                    icon="bi bi-copy"
                    label={actionVerb}
                    size="small"
                    iconPosition="left"
                  />
                </Tooltip>
              </div>
            ))}
          </div>
        )}
      />,
    );
  };
  return (
    <div className="action-verb-modal">
      <span className="action-verb-modal__divider">|</span>
      <span
        className="action-verb-modal__description-link action-verb-modal__divider"
        onClick={showActionVerbsModal}
      >
        Tell me Action Verbs
      </span>
    </div>
  );
};
