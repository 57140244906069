import { useEffect, useState } from 'react';
import {
  Button, Input, Chip, ChipTypes, Select,
} from '@careeros/coco';
// import { MultiSelect } from '@/components/multi-select/multi-select';
import { DropdownSearchDynamic } from '@/components/dropdown-search-dynamic/dropdown-search-dynamic';
import './alum-book-filters.scss';
import { FilterOption } from '@/domains/core/company/types';

import {
  AlumCompany,
  AlumFilterChipType, AlumFilterTagTypes, AlumLocation, AlumniBookFiltersType,
} from '@/types/alum';

type Props = {
  filters: AlumniBookFiltersType;
  defaultFilters: AlumniBookFiltersType;
  companies: AlumCompany[];
  locations: AlumLocation[];
  setFilters: (filters: AlumniBookFiltersType) => void;
};

export const AlumBookFilters = ({
  filters,
  defaultFilters,
  companies,
  locations,
  setFilters,
}: Props) => {
  const [selectedFiltersTags, setSelectedFiltersTags] = useState<AlumFilterChipType[]>([]);

  const handleCompanySearch = async (query: string) => companies.filter((company) => company.name.toLowerCase().includes(query.toLowerCase()));

  const getOptionDisplayName = (option: any): string => {
    if (typeof option === 'string') return option;
    if ('city' in option) return `${option.city}, ${option.country_name}`;
    return option.name;
  };

  const updateFilterTags = (newFilters: typeof filters) => {
    const newTags: AlumFilterChipType[] = Object.entries(newFilters).flatMap(([filterName, filterValue]) => {
      // Handle string contactName
      if (filterName === 'contactName' && typeof filterValue === 'string' && filterValue) {
        return [{
          type: 'tag' as ChipTypes,
          name: filterValue,
          filterName: filterName as AlumFilterTagTypes,
        }];
      }

      // Handle arrays (companies, locations, industries)
      if (Array.isArray(filterValue)) {
        return filterValue.map((option) => ({
          type: 'industry' as ChipTypes,
          name: getOptionDisplayName(option),
          filterName: filterName as AlumFilterTagTypes,
        }));
      }

      // Handle boolean values (alumni, recruiter, hiringSuccess)
      if ((filterName === 'isCareerosUser' || filterName === 'hiringSuccess') && filterValue === 'Yes') {
        return [{
          type: 'tag' as ChipTypes,
          name: (() => {
            switch (filterName) {
              case 'isCareerosUser': return 'CareerOS User';
              case 'hiringSuccess': return 'Hiring Success';
              default: return '';
            }
          })(),
          filterName: filterName as AlumFilterTagTypes,
        }];
      }

      // Handle boolean values (isCareerosUser, hiringSuccess)
      if ((filterName === 'isCareerosUser' || filterName === 'hiringSuccess') && filterValue === 'No') {
        return [{
          type: 'tag' as ChipTypes,
          name: (() => {
            switch (filterName) {
              case 'isCareerosUser': return 'non-CareerOS User';
              case 'hiringSuccess': return 'Hiring non-success';
              default: return '';
            }
          })(),
          filterName: filterName as AlumFilterTagTypes,
        }];
      }

      // Handle degree
      if (filterName === 'degree' && filterValue) {
        return [{
          type: 'tag' as ChipTypes,
          name: filterValue,
          filterName: filterName as AlumFilterTagTypes,
        }];
      }

      // Handle graduation year
      if (filterName === 'graduationYear' && filterValue) {
        return [{
          type: 'tag' as ChipTypes,
          name: `Class of ${filterValue}`,
          filterName: filterName as AlumFilterTagTypes,
        }];
      }

      return [];
    }).filter(tag => tag.name !== '');

    setSelectedFiltersTags(newTags);
  };

  const handleSearchChange = (contactName: string) => {
    const newFilters = { ...filters, contactName };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleCompanySelect = (item: string) => {
    const selectedCompany = companies.find((company) => company.value === item)!;
    const newFilters = { ...filters, companies: [...filters.companies, selectedCompany] };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handlePastCompanySelect = (item: string) => {
    const selectedCompany = companies.find((company) => company.value === item)!;
    const newFilters = { ...filters, pastCompanies: [...filters.pastCompanies, selectedCompany] };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleIsCareerOSUser = (value: 'Yes' | 'No') => {
    const newFilters = { ...filters, isCareerosUser: value };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleFilterDelete = (filterName: AlumFilterTagTypes, tagName: string) => {
    const newFilters = { ...filters } as AlumniBookFiltersType;

    switch (filterName) {
      case 'companies':
        newFilters[filterName] = (newFilters[filterName] as AlumCompany[]).filter(
          (item) => item.name !== tagName,
        );
        break;
      case 'industryName':
        newFilters[filterName] = (newFilters[filterName] as FilterOption[]).filter(
          (item) => item.name !== tagName,
        );
        break;
      case 'contactName':
        newFilters[filterName] = defaultFilters.contactName;
        break;
      default:
        newFilters[filterName] = defaultFilters[filterName];
    }

    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleFilterReset = () => {
    setFilters(defaultFilters);
    updateFilterTags(defaultFilters);
  };

  const handleLocationSelect = (item: string) => {
    if (item) {
      const newFilters = {
        ...filters,
        locations: [...filters.locations, {
          name: item,
          value: item,
        }],
      };
      setFilters(newFilters);
      updateFilterTags(newFilters);
    }
  };

  const handleLocationSearch = async (query: string) => locations.filter((location) => location.name.toLowerCase().includes(query.toLowerCase()));

  const handleDegreeChange = (value: 'MBA' | 'Master' | 'Bachelor') => {
    const newFilters = { ...filters, degree: value };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleGraduationYearChange = (value: string) => {
    const newFilters = { ...filters, graduationYear: value };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  const handleHiringSuccessChange = (value: 'Yes' | 'No') => {
    const newFilters = { ...filters, hiringSuccess: value };
    setFilters(newFilters);
    updateFilterTags(newFilters);
  };

  useEffect(() => {
    updateFilterTags(filters);
  }, [filters]);

  return (
    <div className="alum-book-filters">
      <div className="alum-book-filters__container">
        <div className="alum-book-filters__filters">
          <div className="alum-book-filters__input">
            <Input
              value={filters.contactName}
              handleValueChange={handleSearchChange}
              placeholder="Search first or last name"
              label=""
              name="filter-search-bar"
              id="filter-search-bar"
              data-testid="filter-search-bar"
              icon="bi bi-search"
              isClearable
            />
          </div>
          <DropdownSearchDynamic
            selectedOption={filters.companies.map((company) => company.value)}
            options={companies}
            handleItemSelect={handleCompanySelect}
            loaderFunction={handleCompanySearch}
            placeholder="Current Company"
            name="current-company-search"
            id="current-company-search"
            size="medium"
            canAddItems={false}
            showIcon={false}
            withMultipleSelection
            openOnClick
            listWidth="full"
          />
          <DropdownSearchDynamic
            selectedOption={filters.pastCompanies.map((company) => company.value)}
            options={companies}
            handleItemSelect={handlePastCompanySelect}
            loaderFunction={handleCompanySearch}
            placeholder="Past Companies"
            name="past-company-search"
            id="past-company-search"
            size="medium"
            canAddItems={false}
            showIcon={false}
            withMultipleSelection
            openOnClick
            listWidth="full"
          />

          <DropdownSearchDynamic
            selectedOption=""
            options={locations}
            handleItemSelect={handleLocationSelect}
            loaderFunction={handleLocationSearch}
            placeholder="Search location"
            name="location-search"
            id="location-search"
            size="medium"
            canAddItems={false}
            showIcon={false}
            withMultipleSelection
            openOnClick
            listWidth="full"
          />

          {/* <MultiSelect
            labelType="list"
            handleSelectedOptions={handleIndustriesChange}
            placeholder="Industry"
            selected={filters.industryName || []}
            options={mappedIndustries}
            width="full"
            withSelectAllOption
          /> */}

          <Select
            id="degree"
            label=""
            handleValueChange={handleDegreeChange}
            placeholder="Degree"
            value={filters.degree}
            options={['MBA', 'Master', 'Bachelor']}
            width="fit-box"
          />

          <Select
            id="graduationYear"
            label=""
            handleValueChange={handleGraduationYearChange}
            placeholder="Graduation Year"
            value={filters.graduationYear}
            options={['2024', '2023', '2022', '2021', '2020', '2019']}
            width="fit-box"
          />
          <Select
            id="hiringSuccess"
            label=""
            handleValueChange={handleHiringSuccessChange}
            placeholder="Hiring Success"
            value={filters.hiringSuccess}
            options={['Yes', 'No']}
            width="fit-box"
          />
          <Select
            id="careerOSUser"
            label=""
            handleValueChange={handleIsCareerOSUser}
            placeholder="CareerOS User"
            value={filters.isCareerosUser}
            options={['Yes', 'No']}
            width="fit-box"
          />
        </div>
      </div>
      {selectedFiltersTags.length > 0 && (
        <div className="alum-book-filters__filter-labels" data-testid="contact-filters-chips">
          {selectedFiltersTags.map((tag) => (
            <Chip
              key={`${tag.filterName}-${tag.name}`}
              label={tag.name}
              type={tag.type}
              handleClose={() => handleFilterDelete(tag.filterName, tag.name)}
            />
          ))}
          <Button
            mode="reset"
            outlined
            size="medium"
            label="Reset"
            handleClick={handleFilterReset}
            icon="bi bi-arrow-repeat"
            data-testid="contact-filters-reset-button"
          />
        </div>
      )}
    </div>
  );
};
