import './tile-skeleton.scss';

export const TileSkeleton = () => (
  <div className="tile-skeleton">
    <div className="tile-skeleton__header">
      <div className="tile-skeleton__avatar">
        <div className="tile-skeleton__image-skeleton" />
      </div>
      <div className="tile-skeleton__info">
        <div className="tile-skeleton__text-skeleton" />
      </div>
      <div className="tile-skeleton__status">
        <div className="tile-skeleton__image-skeleton" />
      </div>
    </div>
    <div className="tile-skeleton__position">
      <div className="tile-skeleton__text-skeleton" />
    </div>

    <div className="tile-skeleton__separator" />
    <div className="tile-skeleton__message">
      <div className="tile-skeleton__text-skeleton" />
    </div>
  </div>
);
