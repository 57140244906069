import { Button } from '@careeros/coco';
import { downloadPDF } from '@/domains/core/resume/helpers/save-resume';
import { getStudentData } from '@/services/api/advisor';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  universityID?: string;
  cohortID?: string;
  trackEventText?: string;
};

export const ExportStudentData = ({ universityID, cohortID, trackEventText }: Props) => {
  const { trackEvent } = useAnalytics();
  const downloadStudentsData = async () => {
    let response;
    if (universityID && !cohortID) {
      response = await getStudentData(universityID);
    } else if (universityID && cohortID) {
      response = await getStudentData(universityID, [cohortID]);
    }

    if (!response) {
      return;
    }

    const blob = await response.response;
    const fileName = 'students-data.xlsx';
    const blobUrl = URL.createObjectURL(blob);

    await downloadPDF(blobUrl, fileName);
    if (trackEventText) {
      trackEvent(trackEventText);
    }
  };

  return (
    <Button
      mode="primary"
      size="medium"
      label="Export Student Data"
      onClick={downloadStudentsData}
      icon="bi bi-cloud-download"
    />
  );
};
