import LinkedInLogoRound from '@/assets/images/linkedin-logo-round.png';
import COSLogo from '@/assets/images/logo-centered.png';
import ArrowImage from '@/assets/images/arrow-right-dark-gradient.svg';
import './linkedin-suggested-contacts-error.scss';

export const LinkedInConnectionIcons = () => (
  <div className="suggested-contacts-error__icons">
    <img src={LinkedInLogoRound} alt="LinkedIn" className="suggested-contacts-error__image" />
    <img src={ArrowImage} alt="Arrow Right" />
    <img src={COSLogo} alt="CareerOS" className="suggested-contacts-error__image" />
  </div>
);
