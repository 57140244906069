import React from 'react';
import './OB-tile.scss';

type Props = {
  children: React.ReactNode;
  onClick: () => void;
  className?: string;
  label?: string;
  withHoverLabel?: boolean;
};

type SubComponentProps = {
  children: React.ReactNode;
};

export function OBTile({
  children,
  onClick,
  className = '',
  label,
  withHoverLabel = false,
}: Props) {
  return (
    <div className={`OB-tile ${className}`} onClick={onClick}>
      {React.Children.map(children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child);
        }
        return child;
      })}
      {(withHoverLabel && label) && (
      <div className="OB-tile__hover-label">
        <span className="OB-tile__hover-label-text">
          {label}
        </span>
      </div>
      )}
    </div>
  );
}

OBTile.Header = function OBTileHeader({ children }: SubComponentProps) {
  return <div className="OB-tile__header">{children}</div>;
};

OBTile.Separator = function OBTileSeparator() {
  return <div className="OB-tile__separator" />;
};

OBTile.Body = function OBTileBody({ children }: SubComponentProps) {
  return <div className="OB-tile__body">{children}</div>;
};

OBTile.TrafficLights = function OBTileTrafficLights({ children }: SubComponentProps) {
  return <div className="OB-tile__traffic-lights">{children}</div>;
};
