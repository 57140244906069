import { Company, Tile } from '@/domains/core/company/types';
import { TileV2 } from '@/domains/core/company/types/tile-v2';
import { http } from '@/services/api/http';

export const getApplications = async (): Promise<Tile[] | null> => http.get('/applications');
export const getApplicationsV2 = async (): Promise<TileV2[] | null> => http.get('/applications', 'v2');

export const getStudentApplications = async (studentID: string) => http.get(`/applications/${studentID}`);

export const getArchivedApplications = async (): Promise<Company[] | null> => http.get('/applications/archive');

export const changeApplicationStatus = async (id: string, status: string, position: number) => {
  const response = await http.patch('/applications', {
    id,
    status,
    ordering: position,
  });

  return response;
};

// this function is used in both v1 and v2 overview board
export const updateApplicationHiringScore = async (id: string, score: number) => http.patch(`/applications/score/${id}?score=${score}`, {}, 'v2');
