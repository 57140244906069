import './company-tile-body.scss';
import {
  AvatarStack, Button, IconButton,
  Tooltip,
} from '@careeros/coco';
import { AvatarStackItem } from '@careeros/coco/dist/src/components/AvatarStack/AvatarStack';
import { useMemo } from 'react';
import { DropdownMenuButton } from '../dropdown-menu-button/dropdown-menu-button';
import { getApplicationActionsByStatus, getApplicationAIActionsByStatus } from '@/domains/core/tracker-data/application-actions';
import { ApiStatus } from '@/domains/core/company/types';
import { trackerTabs } from '@/domains/core/tracker-data';
import { ContactInfo } from '@/domains/core/company/types/tile-v2';

type Props = {
  jobNumber?: number | null;
  tileStatus: ApiStatus;
  handleArchiveCompany: () => void;
  handleMoveToRejected: () => void;
  handleOpenTrackerTab: (tab: (typeof trackerTabs)[number], additionalParams?: { [key: string]: string }) => void;
  navigateToPage: (path: string) => void;
  contacts?: ContactInfo[];
};

export const CompanyTileBody = ({
  jobNumber = null,
  tileStatus,
  handleArchiveCompany,
  handleOpenTrackerTab,
  handleMoveToRejected,
  navigateToPage,
  contacts = [],
}: Props) => {
  const actions = useMemo(() => getApplicationActionsByStatus(tileStatus, handleOpenTrackerTab, handleArchiveCompany, handleMoveToRejected), [tileStatus]);
  const AIActions = useMemo(() => getApplicationAIActionsByStatus(tileStatus, handleOpenTrackerTab, navigateToPage), [tileStatus]);
  const parsedContacts = useMemo<AvatarStackItem[]>(() => contacts?.map((contact) => ({
    id: contact.id,
    name: `${contact.first_name} ${contact.last_name}`,
    avatar: contact.image_url || '',
  })) || [], [contacts]);
  const handleJobsCountClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    handleOpenTrackerTab('Jobs');
  };

  return (
    <div className="company-tile-body">
      <div className="company-tile-body__left">
        {AIActions.length > 0 && (
          <DropdownMenuButton
            dropdownOptions={AIActions}
            dropdownHorizontalAlignment="left"
            tooltipLabel="AI Tools"
            scrollContainerQuery="#kanban-board"
          >
            <Button
              icon="bi bi-stars"
              mode="special-subtle"
              size="small"
              label="AI"
            />
          </DropdownMenuButton>
        )}
        <div className="company-tile-body__contacts">
          <AvatarStack
            personList={parsedContacts}
            listTooltip={`${parsedContacts.length} Saved Contacts`}
            emptyStateTooltip="No Saved Contacts"
            handleClick={() => handleOpenTrackerTab('Contacts')}
          />
        </div>
        {jobNumber !== null && (
          <div className="company-tile-body__jobs-count-wrapper" onClick={handleJobsCountClick}>
            <Tooltip label={`${jobNumber} Jobs Open`} position="top" withArrow>
              <div className="company-tile-body__jobs-count">
                <i className={`company-tile-body__jobs-icon ${jobNumber > 0 ? 'company-tile-body__jobs-icon--unread' : ''} bi bi-briefcase`} />
                <span>{`${jobNumber > 99 ? '99+' : jobNumber}`}</span>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="company-tile-body__right">
        <DropdownMenuButton
          dropdownOptions={actions}
          tooltipLabel="Actions"
          scrollContainerQuery="#kanban-board"
        >
          <IconButton
            icon="bi bi-three-dots"
            mode="rounded"
            size="small"
            outlined
          />
        </DropdownMenuButton>
      </div>
    </div>
  );
};
