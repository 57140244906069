import { useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useCookies } from 'react-cookie';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useNavbarContext } from '@/services/hooks/use-navbar-context';
import { LinkType, NavbarItemName } from '@/pages/layout/navbar/types/types';
import { CareerAdvisorLinks, StudentLinksForCareerAdvisor } from '@/pages/layout/navbar/navbar-links';
import { NavbarLogo } from '@/pages/layout/navbar/components/navbar-logo/navbar-logo';
import { NavbarLinks } from '@/pages/layout/navbar/components/navbar-links/navbar-links';
import { NavbarCollapse } from '@/pages/layout/navbar/components/collapse-button/collapse-button';
import { NavbarJoinButton } from '@/pages/layout/navbar/components/navbar-join-button/navbar-join-button';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { getLinksForRole } from '@/pages/layout/navbar/utils/links';
import { useSelfUniversity } from '@/services/queries/user';
import './career-advisor-navbar.scss';

type Props = {
  collapsed: boolean;
  handleToggle: () => void;
  isOnboarding?: boolean;
};

export default function CareerAdvisorNavbar({ collapsed, handleToggle, isOnboarding }: Props) {
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { checkLinkedInLogin } = useExtensionMessaging();
  const { data: university, isLoading: selfLoading } = useSelfUniversity();
  const { unreadTabs, addUnreadTab, removeUnreadTab } = useNavbarContext();
  const [links, setLinks] = useState<LinkType[]>(CareerAdvisorLinks);
  const [cookies] = useCookies(['cookiesAccepted']);
  const isCookieBannerVisible = useMemo(() => !cookies.cookiesAccepted, [cookies.cookiesAccepted]);

  const handleLinkClick = (linkName: string) => {
    trackEvent('Page Visit', user, {
      pageURL: linkName,
    });
    const newLinks = links?.map((linksLink: LinkType) => {
      linksLink.isActive = false;
      return linksLink;
    });
    setLinks(newLinks);
  };

  const checkIfExtensionInstalled = async () => {
    const linkedInLoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      addUnreadTab(NavbarItemName.ChromeExtension);
    } else {
      removeUnreadTab(NavbarItemName.ChromeExtension);
    }
  };

  const setUpLinks = () => {
    if (user && university) {
      const roles = user['https://careeros.com/roles'];
      const linksByRole = getLinksForRole(roles, university);

      setLinks(linksByRole);
    }
  };

  useEffect(() => {
    if (!selfLoading) {
      setUpLinks();
    }
  }, [user, university, selfLoading]);

  useEffect(() => {
    checkIfExtensionInstalled();

    // Checking if extension is installed on tab visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkIfExtensionInstalled();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return isOnboarding ? null : (
    <aside
      className={[
        'ca-sidebar',
        collapsed ? 'ca-sidebar--collapsed' : '',
        isCookieBannerVisible ? 'ca-sidebar--with-cookie-banner' : '',
      ].join(' ')}
    >
      <NavbarLogo logoLink="/app/dashboard-advisor" collapsed={collapsed} />
      <nav className="ca-sidebar__navigation">
        <div className="ca-sidebar__links">
          <NavbarLinks
            links={links}
            collapsed={collapsed}
            handleLinkClick={handleLinkClick}
            unreadTabs={unreadTabs}
          />
          <div className={`ca-sidebar__separator ${collapsed ? 'ca-sidebar__separator--collapsed' : ''}`} />
          <div className="ca-sidebar__join-button" key="show-student-app-button">
            <NavbarJoinButton
              handleLinkClick={handleLinkClick}
              links={StudentLinksForCareerAdvisor}
              unreadTabs={unreadTabs}
              collapsed={collapsed}
            />
          </div>
        </div>
        <NavbarCollapse
          handleCollapse={handleToggle}
          isCollapsed={collapsed}
        />
      </nav>
    </aside>
  );
}
