import { useMemo } from 'react';
import { SuggestedContactType } from '../../types';
import { ContactPreviewCard } from '../contact-preview-card/contact-preview-card';
import { ContactTileCard } from '../contact-tile-card/contact-tile-card';
import { PreviewCardWrapper } from '@/domains/generic/preview-card/preview-card-wrapper/preview-card-wrapper';
import { UniversityResponse } from '@/services/api/student';
import { mapSuggestedContactToContactPreviewCard } from '../../utils/contact-to-preview-parser';
import { splitAndCapitalize } from '@/services/helpers/string';

type Props = {
  contact: SuggestedContactType;
  companyName: string;
  university?: UniversityResponse;
  isMobileDevice: boolean;
  handleReachOutSuggestedContact: () => void;
  handleSaveSuggestedContact: () => void;
  companyLogoUrl: string;
  goToCampusChampionsPage: () => void;
  goToCompany?: () => void;
};

export const SuggestedContactTileWithPreview = ({
  contact,
  companyLogoUrl,
  companyName,
  university,
  isMobileDevice,
  handleReachOutSuggestedContact,
  handleSaveSuggestedContact,
  goToCampusChampionsPage,
  goToCompany,
}: Props) => {
  const contactPreviewCardData = useMemo(() => mapSuggestedContactToContactPreviewCard(contact), [contact]);
  return (
    <div>
      <ContactTileCard
        id={contact.contact_id}
        name={`${contact.contact.first_name} ${contact.contact.last_name}`}
        position={contact.contact.current_position.title}
        image={contact.contact.career_summary.basics?.image}
        companyName={companyName}
        schoolName={university?.name || 'your school'}
        handleMainButtonAction={isMobileDevice ? undefined : handleReachOutSuggestedContact}
        handleSecondaryButtonAction={handleSaveSuggestedContact}
        languages={splitAndCapitalize(contact.matched_languages || '')}
        companyLogo={companyLogoUrl}
        isAlumni={contact.match_criteria.includes('alumni')}
        isCampusChampion={contact.is_campus_champion}
        campusChampionClickHandler={goToCampusChampionsPage}
        networkingStatusRate={contact.match_criteria.includes('desired_job') ? 1 : 0}
        showLanguages={contact.match_criteria.includes('languages')}
        mainButtonLabel="Draft an outreach message"
        linkedinConnectionStatus={contact.contact.linkedin_connection_status}
      >
        {!isMobileDevice && (
          <ContactTileCard.NameWithPreview>
            <PreviewCardWrapper
              name={contactPreviewCardData.title}
              className="suggested-contact-tile__name-text"
              popupFitToScreenHeight
            >
              <PreviewCardWrapper.Preview>
                <ContactPreviewCard
                  title={contactPreviewCardData.title}
                  currentPosition={contactPreviewCardData.currentPosition}
                  previousPositions={contactPreviewCardData.previousPositions}
                  headline={contactPreviewCardData.headline}
                  networkingStatus={contactPreviewCardData.networkingStatus}
                  linkedInConnectionStatus={contactPreviewCardData.linkedInConnectionStatus}
                  languages={contactPreviewCardData.languages}
                  onCurrentCompanyClick={goToCompany}
                />
              </PreviewCardWrapper.Preview>
            </PreviewCardWrapper>
          </ContactTileCard.NameWithPreview>
        )}
      </ContactTileCard>
    </div>
  );
};
