import { InfoBox } from '@careeros/coco';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import '../tracker-info.scss';

const NetworkingInfoModal = () => (
  <div className="company-saved-info-modal">
    <div className="company-saved-info-modal">
      <TabsHeader
        tabs={[
          'Coffee Chats',
          'Build your “business case”',
          'It’s a numbers game',
        ]}
        defaultTab="Coffee Chats"
      />
      <TabsContent>
        <div data-tab="Coffee Chats">
          <div className="info-modal-boxes">
            <InfoBox
              text="The goal of this stage is to schedule coffee chats of approx. 30 minutes with relevant contacts at your target companies in order to learn more about the company and industry and potentially get a direct referral for a role."
              type="normal"
            />
          </div>
        </div>
        <div data-tab="Build your “business case”">
          <div className="info-modal-boxes">
            <InfoBox
              text="Use the insider insights you gain from coffee chats with experts to build your “business case”. Highlight what specific skills you bring to the table that are valuable in the role and industry that you target."
              type="normal"
            />
          </div>
        </div>
        <div data-tab="It’s a numbers game">
          <div className="info-modal-boxes">
            <InfoBox
              text="Expect to receive responses from about 20-40% of the contacts you reach out to. Use our messaging templates to maximize your responses. You will be surprised how many people are willing to help you!"
              type="normal"
            />
          </div>
        </div>
      </TabsContent>
    </div>
  </div>
);

export default withTabs(NetworkingInfoModal);
