import { useEffect, useRef, useState } from 'react';
import { Button } from '@careeros/coco';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import './glass-scroll-container.scss';

type Props = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  withSubtitle?: boolean;
  tooltipLabel: string | JSX.Element;
  maxHeight?: number;
  handleButtonClick?: () => void;
  withButton?: boolean;
};

export const GlassScrollContainer = ({
  children,
  title,
  subtitle,
  withSubtitle = false,
  tooltipLabel,
  handleButtonClick,
  maxHeight = 490,
  withButton = false,
}: Props) => {
  const headerRef = useRef<HTMLDivElement>(null);
  const [headerHeight, setHeaderHeight] = useState<number | null>(null);

  useEffect(() => {
    if (!headerRef.current) {
      return undefined;
    }
    const resizeObserver = new ResizeObserver(() => {
      setHeaderHeight(headerRef.current?.clientHeight || null);
    });
    resizeObserver.observe(headerRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div className={`glass-scroll-container ${withSubtitle ? 'glass-scroll-container--with-description' : ''}`}>
      <div ref={headerRef} className="glass-scroll-container__header-container">
        <div className="glass-scroll-container__header">
          <div className="glass-scroll-container__header-left">
            <h3 className="glass-scroll-container__title">
              <div className="glass-scroll-container__title-text">{title}</div>
              <TooltipIcon
                tooltipLabel={tooltipLabel}
                tooltipPosition="top"
                tooltipTextAlignment="left"
              />
            </h3>
            {withSubtitle && subtitle && (
              <span className="glass-scroll-container__subtitle">{subtitle}</span>
            )}
          </div>
          <div className="glass-scroll-container__header-right">
            {(withButton && handleButtonClick) && (
              <Button
                mode="text"
                size="medium"
                label="Find more on LinkedIn"
                icon="bi bi-box-arrow-up-right"
                iconPosition="right"
                handleClick={handleButtonClick}
              />
            )}
          </div>
        </div>
      </div>
      <div className="glass-scroll-container__scroll-container">
        <div
          className="glass-scroll-container__wrapper"
          style={{ maxHeight, '--title-height': `${headerHeight || 48}px` } as React.CSSProperties}
        >
          {children}
        </div>
      </div>
    </div>
  );
};
