import { Alum, Education } from '@/types/alum';
import { CareerType } from '../../contact/types';

// Constants
const HIRING_SUCCESS_WINDOW = {
  BEFORE_MONTHS: -3,
  AFTER_MONTHS: 3,
} as const;

const MILLISECONDS_PER_MONTH = 1000 * 60 * 60 * 24 * 30;

const DEGREE_TYPES = {
  MBA: {
    keywords: ['MBA', 'Master of Business Administration'],
    tag: '🎓 MBA',
  },
  MASTER: {
    keywords: ['Master'],
    excludeKeywords: ['Business Administration', 'MBA'],
    tag: '🎓 Master',
  },
  BACHELOR: {
    keywords: ['Bachelor'],
    tag: '🎓 Bachelor',
  },
} as const;

// Helper functions
const findUniversityEducation = (education: Education[] | null, universityName: string) => {
  if (!education) return null;
  return education.find(edu => edu?.institution?.toLowerCase().includes(universityName.toLowerCase()));
};

const calculateMonthsDifference = (date1: Date, date2: Date): number => (date1.getTime() - date2.getTime()) / MILLISECONDS_PER_MONTH;

const isWithinHiringWindow = (jobStartDate: Date, graduationDate: Date): boolean => {
  const monthsDiff = calculateMonthsDifference(jobStartDate, graduationDate);
  return monthsDiff >= HIRING_SUCCESS_WINDOW.BEFORE_MONTHS
    && monthsDiff <= HIRING_SUCCESS_WINDOW.AFTER_MONTHS;
};

// Main functions
export const parseCareerSummary = (careerSummary: string): CareerType | null => {
  if (!careerSummary) return null;

  try {
    if (typeof careerSummary === 'object') return careerSummary;

    let parsed = careerSummary;
    try {
      while (typeof parsed === 'string') {
        parsed = JSON.parse(parsed);
      }
    } catch {
      parsed = JSON.parse(careerSummary.replace(/\\/g, ''));
    }

    return typeof parsed === 'object' ? parsed as CareerType : null;
  } catch {
    return null;
  }
};

export const extractBasicInfo = (careerSummary: string) => parseCareerSummary(careerSummary)?.basics || null;

export const extractWorkExperience = (careerSummary: string) => parseCareerSummary(careerSummary)?.work || null;

export const extractEducation = (careerSummary: string) => parseCareerSummary(careerSummary)?.education || null;

export const determineHiringSuccess = (alum: Alum): boolean => {
  const education = extractEducation(alum.career_summary?.profile);
  const work = extractWorkExperience(alum.career_summary?.profile);
  if (!education || !work) return false;

  const universityEducation = findUniversityEducation(education, alum.university.name);
  if (!universityEducation?.endDate) return false;

  const graduationDate = new Date(universityEducation.endDate);

  return work.some(job => {
    if (!job.startDate) return false;
    return isWithinHiringWindow(new Date(job.startDate), graduationDate);
  });
};

export const determineIfGraduated = (alum: Alum): boolean => {
  const education = extractEducation(alum.career_summary?.profile);
  const universityEducation = findUniversityEducation(education, alum.university.name);

  if (!universityEducation?.endDate) return false;

  return new Date(universityEducation.endDate) < new Date();
};

export const getDegreeTag = (alum: Alum) => {
  if (!alum?.career_summary?.profile) return null;

  const education = extractEducation(alum.career_summary.profile);
  const universityEdu = findUniversityEducation(education, alum.university.name);

  if (!universityEdu?.studyType) return null;

  const { studyType } = universityEdu;

  const matchedDegree = Object.values(DEGREE_TYPES).find(degreeType => {
    const hasRequiredKeywords = degreeType.keywords.some(keyword => studyType.includes(keyword));
    const hasNoExcludedKeywords = 'excludeKeywords' in degreeType
      ? !degreeType.excludeKeywords.some(keyword => studyType.includes(keyword))
      : true;
    return hasRequiredKeywords && hasNoExcludedKeywords;
  });

  return matchedDegree ? { tag_content: matchedDegree.tag } : null;
};

export const getGraduationTag = (alum: Alum) => {
  if (!alum?.career_summary?.profile || !determineIfGraduated(alum)) return null;

  const education = extractEducation(alum.career_summary.profile);
  const universityEdu = findUniversityEducation(education, alum.university.name);

  if (!universityEdu?.endDate) return null;

  const graduationYear = new Date(universityEdu.endDate).getFullYear();
  return { tag_content: `📅 ${graduationYear}` };
};

export const getCurrentPosition = (alum: Alum): string => {
  const work = extractWorkExperience(alum?.career_summary?.profile);
  return work?.[0]?.position || '';
};

export const getCurrentCompany = (alum: Alum): string => {
  const work = extractWorkExperience(alum?.career_summary?.profile);
  return work?.[0]?.name || '';
};
