import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useNavigate } from 'react-router-dom';
import {
  useContext, useState, useMemo, useCallback,
} from 'react';
import { IconButton } from '@careeros/coco';
import { JobTile } from '@/domains/core/job';
import { useJobsFiltering } from '@/services/hooks/use-jobs';
import { saveJob, unsaveJob } from '@/services/api/job';
import { useSelf } from '@/services/queries/user';
import { useSavedJobs } from '@/services/hooks/use-saved-jobs';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useAlgoliaInsights, useRelatedJobs } from '@/services/hooks/use-algolia';
import { JobFeedJob, JobTagType } from '@/domains/core/job/types/job';
import { ModalContext } from '@/components/modal/modal-provider';
import { CompanyPartnerType } from '@/domains/core/company/types/company';

import './related-jobs.scss';
import { useScroll } from '@/services/hooks/use-scroll';

type RelatedJobItemProps = {
  item: {
    id: string;
    title: string;
    company_name: string;
    location: string;
    company_logo: string;
    posted_on: string;
    company_id: string;
    description: string;
    created_at: string;
    company_partner_type: CompanyPartnerType | null;
    job_type: string;
    remote: string;
    saved: boolean;
    industry?: string;
    size?: string;
  };
  currentLoadingId: string;
  setCurrentLoadingId: (id: string) => void;
  setTotalJobsCountSaved: (cb: (prev: number) => number) => void;
  setSavedJobs: (cb: (prev: JobFeedJob[]) => JobFeedJob[]) => void;
  savedJobs: JobFeedJob[];
};

const RelatedJobItem = ({
  item,
  currentLoadingId,
  setCurrentLoadingId,
  setTotalJobsCountSaved,
  setSavedJobs,
  savedJobs,
}: RelatedJobItemProps) => {
  const { data: self } = useSelf();
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const insights = useAlgoliaInsights();
  const navigate = useNavigate();
  const { closeModal } = useContext(ModalContext) as any;

  const isItemInSavedJobs = (itemId: string) => savedJobs.some((job: JobFeedJob) => job.id === itemId);

  const goToJobPage = (jobID: string) => {
    navigate(`/app/jobs/${jobID}`);
  };

  const convertToJobFeedJob = useCallback((algoliaItem: RelatedJobItemProps['item']): JobFeedJob => ({
    id: algoliaItem.id,
    title: algoliaItem.title,
    company_name: algoliaItem.company_name,
    location: algoliaItem.location,
    linkedin_logo_url: algoliaItem.company_logo,
    linkedin_url: '',
    deadline: '',
    rolling_date: false,
    posted_on: algoliaItem.posted_on,
    company_id: algoliaItem.company_id || '',
    source: 'algolia',
    saved: algoliaItem.saved,
    applied: false,
    description: algoliaItem.description || 'Description not available for now',
    created_at: algoliaItem.created_at,
    team_id: null,
    company_partner_type: algoliaItem.company_partner_type,
    tags: [
      {
        tag_type: 'contract' as JobTagType['tag_type'],
        tag_content: algoliaItem.job_type,
      },
      {
        tag_type: 'location' as JobTagType['tag_type'],
        tag_content: algoliaItem.location,
      },
      {
        tag_type: 'workMode' as JobTagType['tag_type'],
        tag_content: algoliaItem.remote,
      },
    ],
  }), []);

  const handleSaveAction = useCallback(async (jobID: string) => {
    setCurrentLoadingId(jobID);
    try {
      await saveJob(jobID);

      setTotalJobsCountSaved(prevTotalJobsCount => prevTotalJobsCount + 1);

      if (self?.id) {
        insights.addedToCartObjectIDs(
          'Job Saved',
          'job',
          [jobID],
          self.id,
        );
      }

      item.saved = true;
      const jobFeedItem = convertToJobFeedJob(item);
      setSavedJobs(prevSavedJobs => [jobFeedItem, ...prevSavedJobs]);

      trackEvent('Job Saved', {
        job_id: jobID,
        company_name: item.company_name,
        job_title: item.title,
        location: item.location,
        full_time: item.job_type,
        on_site: item.remote,
        industry: item.industry,
        size: item.size,
        source: 'related-jobs',
      });

      await queryClient.invalidateQueries(['applications-no-cache']);
      queryClient.invalidateQueries(['applications-no-cache-v2']);
      queryClient.invalidateQueries(['dashboard-actions']);
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setCurrentLoadingId('');
    }
  }, [self?.id, item, setCurrentLoadingId, setTotalJobsCountSaved, setSavedJobs, insights, trackEvent, queryClient, convertToJobFeedJob]);

  const handleUnsaveAction = useCallback(async (jobID: string) => {
    setCurrentLoadingId(jobID);
    try {
      await unsaveJob(jobID);

      setTotalJobsCountSaved(prevTotalJobsCount => prevTotalJobsCount - 1);

      const jobToUnsave = savedJobs.find(job => job.id === jobID);

      if (!jobToUnsave) {
        return;
      }

      jobToUnsave.saved = false;

      setSavedJobs(prevSavedJobs => prevSavedJobs.filter(job => job !== jobToUnsave));
      trackEvent('Job unsaved', {
        job_id: jobID,
        company_name: jobToUnsave.company_name,
        job_title: jobToUnsave.title,
        location: jobToUnsave.tags[1]?.tag_content,
        full_time: jobToUnsave.tags[0]?.tag_content,
        on_site: jobToUnsave.tags[2]?.tag_content,
        industry: jobToUnsave.tags[4]?.tag_content,
        size: jobToUnsave.tags[3]?.tag_content,
      });
    } catch (err) {
      Sentry.captureException(err);
    } finally {
      setCurrentLoadingId('');
    }
    closeModal();
  }, [savedJobs, setCurrentLoadingId, setTotalJobsCountSaved, setSavedJobs, trackEvent, closeModal]);

  const handleClick = (jobId: string) => {
    navigate(`/app/jobs/${jobId}`);
  };

  const jobTileProps = useMemo(() => ({
    job: convertToJobFeedJob(item),
    isActive: false,
    isLoading: currentLoadingId === item.id,
    isSaved: isItemInSavedJobs(item.id),
    onJobSaveToggle: isItemInSavedJobs(item.id)
      ? () => handleUnsaveAction(item.id)
      : () => handleSaveAction(item.id),
    onSelect: handleClick,
    withCompanyName: true,
    onApplyClick: () => {},
    handleCompanyClick: goToJobPage,
  }), [item, currentLoadingId, isItemInSavedJobs, handleUnsaveAction, handleSaveAction, handleClick, goToJobPage, convertToJobFeedJob]);

  return <JobTile {...jobTileProps} />;
};

export function RelatedJobsWrapper({ jobID }: { jobID: string }) {
  const { data: self } = useSelf();
  const { relatedJobs, isLoading } = useRelatedJobs(jobID);
  const [widgetContainer, handleVerticalScroll] = useScroll();
  const { currentLoadingId, setCurrentLoadingId } = useJobsFiltering({
    currentTab: 'saved',
    userId: self?.id,
  });
  const {
    savedJobs,
    setTotalJobsCountSaved,
    setSavedJobs,
  } = useSavedJobs({
    currentTab: 'saved',
    isLoading: false,
  });

  const [showRightScrollArrow, setShowRightScrollArrow] = useState(true);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;
    const allColumnsWidth = widgetContainer.current.scrollWidth;
    const columnsInViewWidth = widgetContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }
    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  if (isLoading) {
    return <div className="job-tabs__loader" />;
  }

  if (!relatedJobs || !relatedJobs.length) {
    return null;
  }

  return (
    <div className="related-jobs">
      <h2 className="related-jobs__title">Similar Jobs to This One</h2>
      <p className="related-jobs__description">Based on job data & other user’s behavior... </p>
      <div className="related-jobs__jobs" ref={widgetContainer} onScroll={handleScroll}>
        {relatedJobs.map((job: any) => (
          <RelatedJobItem
            key={job.id || job.objectID}
            item={job}
            currentLoadingId={currentLoadingId}
            setCurrentLoadingId={setCurrentLoadingId}
            setTotalJobsCountSaved={setTotalJobsCountSaved}
            setSavedJobs={setSavedJobs}
            savedJobs={savedJobs}
          />
        ))}
      </div>
      <div className="related-jobs__controls">
        <IconButton
          icon="bi bi-chevron-left"
          mode="rounded"
          size="medium"
          outlined
          handleClick={() => handleVerticalScroll('left')}
          disabled={!showLeftScrollArrow}
        />
        <IconButton
          icon="bi bi-chevron-right"
          mode="rounded"
          size="medium"
          outlined
          handleClick={() => handleVerticalScroll('right')}
          disabled={!showRightScrollArrow}
        />
      </div>
    </div>
  );
}
