import { IconButton, Tooltip, DefaultLogo } from '@careeros/coco';
import './contact-tile-card.scss';
import React, { useState } from 'react';
import { SuggestedContactTag } from '../suggested-contact-tag/suggested-contact-tag';
import { ConnectionStatus } from '../../types';
import { StatusTag } from '../status-tags/status-tag';

type Props = {
  id: string;
  name: string;
  position: string;
  image: string;
  languages?: string[];
  isAlumni?: boolean;
  companyLogo?: string;
  companyName: string;
  schoolName: string;
  networkingStatusRate?: number;
  handleMainButtonAction?: (id: string) => Promise<void> | void;
  handleSecondaryButtonAction?: (id: string) => Promise<void> | void;
  mainButtonLabel?: string;
  secondaryButtonLabel?: string;
  mainButtonIcon?: string;
  secondaryButtonIcon?: string;
  isSecondaryButtonDanger?: boolean;
  isBlurred?: boolean;
  isCampusChampion?: boolean;
  showLanguages?: boolean;
  linkedinConnectionStatus?: ConnectionStatus | null;
  networkingStatus?: ConnectionStatus;
  campusChampionClickHandler?: () => void;
  handleNameClick?: () => void;
  children?: React.ReactNode;
};

type SubComponentProps = {
  children: React.ReactNode;
};

export const ContactTileCard = ({
  id,
  name,
  position,
  image,
  languages = [],
  isAlumni = false,
  companyLogo,
  companyName,
  schoolName,
  networkingStatusRate = 0,
  handleMainButtonAction,
  handleSecondaryButtonAction,
  linkedinConnectionStatus,
  networkingStatus,
  isBlurred = false,
  isCampusChampion = false,
  showLanguages = false,
  mainButtonIcon = 'bi bi-chat-text',
  secondaryButtonIcon = 'bi bi-bookmark-heart',
  mainButtonLabel = 'Message',
  secondaryButtonLabel = 'Save Contact',
  isSecondaryButtonDanger = false,
  campusChampionClickHandler,
  handleNameClick,
  children,

}: Props) => {
  const [isMainButtonLoading, setIsMainButtonLoading] = useState(false);
  const [isSecondaryButtonLoading, setIsSecondaryButtonLoading] = useState(false);

  const handleMainButtonClick = async () => {
    if (!handleMainButtonAction) {
      return;
    }
    setIsMainButtonLoading(true);
    await handleMainButtonAction(id);
    setIsMainButtonLoading(false);
  };

  const handleSecondaryButtonClick = async () => {
    setIsSecondaryButtonLoading(true);
    await handleSecondaryButtonAction?.(id);
    setIsSecondaryButtonLoading(false);
  };
  return (
    <div className="suggested-contact-tile">
      <div className={`suggested-contact-tile__main-info ${isBlurred ? 'suggested-contact-tile__main-info--blur' : ''}`}>
        <div className="suggested-contact-tile__avatar-container">
          <div
            className={`suggested-contact-tile__avatar ${isCampusChampion ? 'suggested-contact-tile__avatar--is-outlined' : ''} ${handleNameClick ? 'suggested-contact-tile__avatar--clickable' : ''}`}
            onClick={handleNameClick}
          >
            <DefaultLogo
              source={image}
              name={name}
              className="suggested-contact-tile__avatar-image"
              size={72}
              type="contact"
            />
          </div>
          {companyLogo && (
            <div className="suggested-contact-tile__company-logo">
              <Tooltip label={companyName} withArrow position="top">
                <DefaultLogo
                  source={companyLogo}
                  name={companyName}
                  className="suggested-contact-tile__company-logo-image"
                  size={24}
                  type="company"
                />
              </Tooltip>
            </div>
          )}
        </div>

        <div className="suggested-contact-tile__description">
          <div className="suggested-contact-tile__name">
            {React.Children.map(children, child => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child);
              }
              return child;
            })}
            {!children && (
              <span className={`suggested-contact-tile__name-text ${handleNameClick ? 'suggested-contact-tile__name-text--clickable' : ''}`} onClick={handleNameClick}>{name}</span>
            )}
            {isCampusChampion && (
              <span className="suggested-contact-tile__star">
                <Tooltip position="top" label="Campus Champion">
                  <span>
                    ⭐
                  </span>
                </Tooltip>
              </span>
            )}
          </div>
          <div className="suggested-contact-tile__position">
            <span>{position}</span>
            {networkingStatusRate > 0 && (
            <span className="suggested-contact-tile__rate">
              <Tooltip position="top" withArrow label="In your desired functional area!">
                <div>
                  {new Array(Math.floor(networkingStatusRate)).fill(0).map(() => (
                    <span>🔥</span>
                  ))}
                </div>
              </Tooltip>
            </span>
            )}
          </div>

          <div className="suggested-contact-tile__tags">
            {isAlumni && (
              <SuggestedContactTag
                label="Alumni"
                tooltipLabel={`Also studied at ${schoolName}`}
                icon="bi bi-mortarboard"
                color="purple"
              />
            )}
            {showLanguages && Array.isArray(languages) && languages.length > 0 && (
              <SuggestedContactTag
                label={languages[0]}
                tooltipLabel={`Also speaks: ${languages.join(', ')}`}
                icon="bi bi-translate"
                color="green"
              />
            )}
            {isCampusChampion && (
              <SuggestedContactTag
                label="Campus Champion"
                tooltipLabel={`These are contacts selected specifically for ${schoolName} by ${companyName}. Click to see all Campus Champions.`}
                icon="bi bi-star"
                color="primary-blue"
                handleClick={campusChampionClickHandler}
              />
            )}
          </div>
        </div>
      </div>
      <div className="suggested-contact-tile__right">
        {(linkedinConnectionStatus || networkingStatus) && (
        <div className="suggested-contact-tile__statuses">
          {linkedinConnectionStatus && (
          <StatusTag status={linkedinConnectionStatus} type="linkedin" withTooltip />
          )}
          {networkingStatus && (
          <StatusTag status={networkingStatus} type="networking" withTooltip />
          )}
        </div>
        )}
        <div className="suggested-contact-tile__actions">
          {handleSecondaryButtonAction && (
            <Tooltip label={secondaryButtonLabel} withArrow position="top">
              <IconButton
                mode="rounded"
                size="small"
                onClick={handleSecondaryButtonClick}
                outlined
                icon={secondaryButtonIcon}
                isDangerous={isSecondaryButtonDanger}
                isLoading={isSecondaryButtonLoading}
              />
            </Tooltip>
          )}
          {handleMainButtonAction && (
            <Tooltip label={mainButtonLabel} withArrow position="top">
              <IconButton
                mode="rounded"
                size="small"
                onClick={handleMainButtonClick}
                outlined
                icon={mainButtonIcon}
                isLoading={isMainButtonLoading}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

ContactTileCard.NameWithPreview = function NameWithPreview({ children }: SubComponentProps) {
  return <div className="contact-tile-card__name-with-preview">{children}</div>;
};
