import './resume-preview.scss';
import { useEffect } from 'react';
import { ResumeSchema } from '../../types';
import PDFViewer from '../pdf-viewer/pdf-viewer';
import { useResumeBuilderContext } from '@/pages/context/resume-builder';
import { RESUME_TEMPLATES } from '../../templates/template-rules/resume-rules';

type PreviewResumeProps = {
  baseResume: ResumeSchema
  onError?: (error: string) => void
  selectedTemplate: string;
  setNumberOfPages?: (num: number) => void;
  hideHighlights?: boolean;
  preview?: boolean;
};

export const PreviewResume = ({
  baseResume, onError, selectedTemplate, setNumberOfPages, hideHighlights, preview,
}: PreviewResumeProps) => {
  const SelectedComponent = RESUME_TEMPLATES[selectedTemplate];
  const { highlightWords, activeField, zoomLevel } = useResumeBuilderContext();
  useEffect(() => {
    if (zoomLevel) {
      const container = document.querySelector('.confirm-base-resume') as HTMLElement;

      if (!container) return;

      // Get the current scroll position
      const { scrollLeft } = container;
      const { scrollTop } = container;

      // Calculate the scroll position as a percentage of the container's size
      const scrollXPercentage = (scrollLeft / container.scrollWidth) * 100;
      const scrollYPercentage = (scrollTop / container.scrollHeight) * 100;

      // Set CSS variables to pass the scroll position to the CSS
      container.style.setProperty('--scroll-x', `${scrollXPercentage}`);
      container.style.setProperty('--scroll-y', `${scrollYPercentage}`);
    }
  }, [zoomLevel]);
  return (
    <div className={preview ? 'confirm-base-resume--zoomed-0 confirm-base-resume--preview' : `confirm-base-resume--zoomed-${zoomLevel} confirm-base-resume`}>
      {baseResume
      && (
        <PDFViewer onError={onError} setNumberOfPages={setNumberOfPages || (() => {})}>
          <SelectedComponent resume={baseResume} title={selectedTemplate} highlightedWords={hideHighlights ? null : highlightWords} activeField={activeField} />
        </PDFViewer>
      )}
    </div>
  );
};
