/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useRef,
  useState,
  useContext, useMemo,
} from 'react';
import Quill from 'quill';
import ReactQuill from 'react-quill';
import { Button, InfoModal, Tooltip } from '@careeros/coco';
import useDebounceEffect from '@/services/hooks/use-debounce';
import {
  convertNewLinesToHtml,
  findTextInBrackets,
  transformEmailHTML,
} from '@/services/helpers/messages';
import { EditorToolbar } from '@/domains/core/contact/components/editor-toolbar/editor-toolbar';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import 'react-quill/dist/quill.snow.css';
import './email-box.scss';
import '@/services/helpers/quill-config';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplatesModal } from '@/domains/core/netwoking/components';
import { stripHTML } from '@/services/helpers/processHtml';
import { AITemplatesButton } from '@/domains/core/student/components/use-ai-button/templates-button';
import { StudentStatus } from '@/domains/core/advisor/types';
import { useAdvisorTemplates } from '@/services/queries/template';
import { Template } from '@/domains/core/advisor/types/template';

const Embed = Quill.import('blots/embed');

class BadgeBlot extends Embed {
  static create() {
    const node = document.createElement('span');
    node.className = 'ca-email-box__highlights-box-container';
    node.setAttribute('contenteditable', 'false');
    node.innerHTML = `
      <div class="ca-email-box__highlights-box">
        <span>Student First Name</span>
        <i class="bi bi-x ca-email-box__highlights-close"></i>
      </div>
    `;

    // Add click handler after creating the element
    const closeIcon = node.querySelector('.bi-x');
    if (closeIcon) {
      closeIcon.addEventListener('click', () => {
        node.remove();
      });
    }

    return node;
  }

  static value(node: HTMLElement) {
    return node.textContent;
  }
}
BadgeBlot.blotName = 'badge';
BadgeBlot.tagName = 'div';
BadgeBlot.className = 'ca-email-box__highlights-box-container';

Quill.register(BadgeBlot);

type EmailBoxProps = {
  handleSend: (message: string, subject: string) => void;
  setIsBoxOpened: (value: boolean) => void;
  column?: StudentStatus | null;
  showAITemplates?: boolean;
  companyName?: string;
  selfFullName?: string;
  studentCount?: number;
};

export const EmailBox = ({
  handleSend,
  setIsBoxOpened,
  column,
  showAITemplates = false,
  companyName,
  selfFullName,
  studentCount,
}: EmailBoxProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const reactQuillSubjectRef = useRef<ReactQuill | null>(null);
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState('');
  const [isBoxMinimized, setIsBoxMinimized] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);

  const { data: templates } = useAdvisorTemplates();
  const validatedTemplates = useMemo<Template[]>(() => (Array.isArray(templates) ? templates : []), [templates]);

  const templateListOptions = useMemo(() => validatedTemplates.map((template: Template) => ({
    id: template.id,
    name: template.name,
    type: template.type,
  })), [validatedTemplates]);

  const toggleMinimize = () => setIsBoxMinimized(!isBoxMinimized);

  const applyCustomTemplate = (template: string, subjectTemplate?: string) => {
    closeModal();
    setMessage(template);

    if (subjectTemplate) {
      setSubject(stripHTML(subjectTemplate));
    } else {
      setSubject('');
    }
  };

  const handlePredefinedTemplateClick = (_option: string, _outreachType: string, id?: string) => {
    closeModal();

    const tpl = validatedTemplates.find((t: Template) => t.id === id);
    if (!tpl) {
      return;
    }

    if (tpl.emailSubject) {
      setSubject(tpl.emailSubject);
    }

    setMessage(convertNewLinesToHtml(tpl.message));
  };

  const handleCustomTemplatesClick = () => {
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={{}}
        previewType="email"
        contactEmail={`${column} Students`}
        selectTemplate={applyCustomTemplate}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };

  const checkTextForPlaceholders = () => {
    const hasPlaceholders = message.match(/\[[^[\]]*\]/g) || subject.match(/\[[^[\]]*\]/g);

    if (hasPlaceholders) {
      addToast({
        type: 'error',
        message: 'There are still placeholders in your email!',
        additionalMessage:
          'Fill in or remove all remaining placeholders before sending the email.',
      });
    }

    return hasPlaceholders;
  };

  const handleClose = () => {
    setIsBoxOpened(false);
    setSubject('');
    setMessage('');
  };

  const handleSendClick = () => {
    const havePlaceholders = checkTextForPlaceholders();
    if (havePlaceholders) return;

    const messageSubject = reactQuillSubjectRef.current?.editor?.getText().trim();

    if (!message.length || !messageSubject?.length) {
      addToast({
        type: 'error',
        message: 'Your email is incomplete!',
        additionalMessage: 'To send out the email it should have both a subject and a email body.',
      });
      return;
    }

    handleSend(transformEmailHTML(message), messageSubject);

    closeModal();
  };

  const showConfirmationModal = () => {
    openModal(
      <InfoModal
        title={`Send Email to Students in the ${column} Stage`}
        description={`You are about to send this email to ${studentCount} ${studentCount === 1 ? 'student' : 'students'}. This action cannot be undone — make sure everything looks good before proceeding. Do you want to continue?`}
        icon="bi bi-exclamation-triangle"
        buttonLabel="Send Email"
        buttonIcon="bi bi-send"
        secondaryButtonIcon="bi bi-x-circle"
        secondaryButtonLabel="Cancel"
        handleButtonClick={handleSendClick}
        handleSecondaryButtonClick={closeModal}
        isImageDanger
        orientation="horizontal"
      />,
    );
  };

  const handleSubmitCommentRemoval = () => {
    handleClose();
    closeModal();
  };

  const handleEmailClose = () => {
    const messageLength = reactQuillRef.current?.getEditor().getText().trim().length || 0;
    const subjectLength = reactQuillSubjectRef.current?.getEditor().getText().trim().length || 0;

    if (messageLength > 0 || subjectLength > 0) {
      openModal(
        <InfoModal
          title="Discard Message"
          description="Looks like you’re about to discard your message. If you discard now, your message will be permanently lost. How about giving it a quick review and sending it off?"
          icon="bi bi-exclamation-triangle"
          buttonLabel="Keep Writing"
          buttonIcon="bi bi-pencil-square"
          secondaryButtonIcon="bi bi-trash"
          secondaryButtonLabel="Delete Message"
          isSecondButtonDanger
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={handleSubmitCommentRemoval}
          isImageDanger
          isButtonOutlined
          orientation="horizontal"
        />,
      );

      return;
    }

    handleClose();
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }

    const quillEditor = reactQuillRef.current.getEditor();
    const editorText = quillEditor.getText();
    const bracketedParts = findTextInBrackets(quillEditor.getText());

    const placeholderIndexForCareerAdvisor = editorText.indexOf('[career advisor first name]');
    if (placeholderIndexForCareerAdvisor !== -1 && selfFullName) {
      quillEditor.deleteText(placeholderIndexForCareerAdvisor, '[career advisor first name]'.length);
      quillEditor.insertText(placeholderIndexForCareerAdvisor, selfFullName);
    }

    const matchText = editorText.indexOf('[student first name]');
    if (matchText !== -1 && column) {
      // Insert Badge component for student first name
      quillEditor.deleteText(matchText, '[student first name]'.length);
      quillEditor.insertEmbed(matchText, 'badge', true);
    }

    quillEditor.formatText(0, quillEditor.getText().length, {
      color: false,
    });

    bracketedParts.forEach((part) => {
      quillEditor.formatText(part.index + 1, part.length, {
        color: 'var(--purpose-information)',
      });
    });
  };

  useDebounceEffect(() => {
    if (message) {
      highlightPlaceholders();
    }
  }, [message]);

  return (
    <div className="ca-email-box">
      <div className="ca-email-box__title">
        <h2>New Message</h2>
        <div className="ca-email-box__actions">
          <div onClick={toggleMinimize} className="ca-email-box__action">
            <i className="bi bi-dash" />
          </div>
          <div onClick={handleEmailClose} className="ca-email-box__action">
            <i className="bi bi-x-lg" />
          </div>
        </div>
      </div>
      <div className={`ca-email-box__content ${isBoxMinimized ? 'ca-email-box__content--hidden' : ''}`}>
        <div className="ca-email-box__header-line ca-email-box__email">
          To
          <div className="ca-email-box__recipient-box">
            {column ? `Students in ‘${column}’ Status` : `Students who interact with ${companyName}`}
          </div>
        </div>
        <div
          className="ca-email-box__subject ca-email-box__header-line"
          onKeyDownCapture={(e) => {
            if (e.code === 'Enter' || e.code === 'Tab') {
              e.preventDefault();
              reactQuillRef.current?.focus();
            }
          }}
        >
          <ReactQuill
            ref={reactQuillSubjectRef}
            theme="snow"
            modules={{
              toolbar: null,
            }}
            id="email-subject"
            value={subject}
            placeholder="Subject"
            onChange={setSubject}
            style={{
              border: 'none',
              padding: '0',
              height: 'fit-content',
              width: '100%',
            }}
            data-testid="email-subject-input"
          />
        </div>
        <div className="ca-email-box__text-container" onClick={() => reactQuillRef.current?.focus()}>
          <ReactQuill
            ref={reactQuillRef}
            placeholder="Draft your message using one of our templates or write your own"
            modules={{
              toolbar: {
                container: '#toolbar',
              },
            }}
            theme="snow"
            value={message}
            onChange={setMessage}
            style={{
              border: 'none',
              padding: '0',
              borderRadius: '8px',
              display: 'block',
            }}
          />
          <div className="ca-email-box__footer">
            <Button
              handleClick={showConfirmationModal}
              mode="primary"
              size="medium"
              label="Send Message"
              icon="bi bi-send"
            />
            {showAITemplates && (
              <div>
                <AITemplatesButton
                  options={templateListOptions}
                  size="small"
                  buttonMode="blue"
                  listPosition="left"
                  handleOptionClick={handlePredefinedTemplateClick}
                  isLoading={false}
                  buttonIcon="bi bi-file-text"
                  buttonLabel="Template"
                  isOutlined
                  tooltipLabel="Choose from templates list"
                />
              </div>
            )}
            <div>
              <Tooltip label="Create custom templates" position="top" withArrow>
                <Button
                  mode="blue"
                  outlined
                  label="Custom"
                  handleClick={handleCustomTemplatesClick}
                  size="medium"
                  icon="bi bi-tools"
                  data-testid="templates-button-custom"
                />
              </Tooltip>
            </div>
            <EditorToolbar />
          </div>
        </div>
      </div>
    </div>
  );
};
