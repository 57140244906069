import { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import * as Sentry from '@sentry/react';
import { sendTallyNudgeEmail } from '@/services/api/profile';
import './tally-info-empty.scss';
import { TallyInfoEmptySneakPeak } from './tally-info-empty-sneak-peek';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { CareerAdvisorLinks } from '@/pages/layout/navbar/navbar-links';

type Props = {
  id: string;
  universityName: string;
  firstName?: string;
};

export const TallyInfoEmptyCA = ({ id, firstName = 'student', universityName }: Props) => {
  const { addToast }: AddToastType = useContext(ToastContext);
  const navigate = useNavigate();
  const [showSneakPeak, setShowSneakPeak] = useState(false);

  const redirectToInsights = useMemo(() => CareerAdvisorLinks.find(link => link.label === 'Insights')?.includedForUniversities || [], []);

  const handleSendEmailButtonClick = async () => {
    try {
      await sendTallyNudgeEmail(id);
      addToast(
        {
          type: 'success',
          message: 'Email sent successfully',
          additionalMessage: "Email was sent to the student's email address.",
        },
      );
    } catch (error) {
      Sentry.captureException(error);
      addToast(
        {
          type: 'error',
          message: 'Failed to send email',
          additionalMessage: 'Please try again later',
        },
      );
    }
  };

  const handleInsightsButtonClick = () => {
    if (redirectToInsights?.includes(universityName)) {
      navigate('/app/insights');
    } else {
      navigate('/app/dashboard-advisor');
    }
  };

  return (
    <div className="tally-info-empty">
      <div className="tally-info-empty__title">Targets and Preferences</div>
      <div className="tally-info-empty__description">
        {`You’ll see ${firstName}’s target sectors, dream role, and even salary expectations once they complete their profile.`}
      </div>
      <div className="tally-info-empty__with-tooltip">
        <div
          onMouseEnter={() => setShowSneakPeak(true)}
          onMouseLeave={() => setShowSneakPeak(false)}
        >
          <Button
            handleClick={handleSendEmailButtonClick}
            label={`Send ${firstName} a Reminder`}
            icon="bi bi-arrow-right"
            iconPosition="right"
            mode="primary"
            size="medium"
            outlined
          />
          {showSneakPeak && <TallyInfoEmptySneakPeak />}
        </div>
        <Button
          handleClick={handleInsightsButtonClick}
          label="All Student Insights"
          icon="bi bi-box-arrow-up-right"
          iconPosition="right"
          mode="invisible"
          size="medium"
        />
      </div>
    </div>
  );
};
