/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-no-bind */
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { pdf } from '@react-pdf/renderer';
import { PDFDocumentProxy } from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface MemoizedPDFDocumentProps {
  pdfUrl: string;
  numberOfPages: number | null;
  opt: any;
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void;
  updateOldResume: (url: string) => void;
}

const hasPageContent = async (pdfDoc: any, pageNumber: number) => {
  const page = await pdfDoc.getPage(pageNumber);
  const operatorList = await page.getOperatorList();
  return operatorList.fnArray.some((fn: number) => [pdfjs.OPS.paintImageXObject, pdfjs.OPS.setFont, pdfjs.OPS.showText, pdfjs.OPS.showSpacedText, pdfjs.OPS.nextLineShowText].includes(fn));
};

const MemoizedPDFDocument: React.FC<MemoizedPDFDocumentProps> = React.memo(
  ({
    pdfUrl, numberOfPages, opt, onDocumentLoadSuccess, updateOldResume,
  }: any) => {
    const [pagesWithContent, setPagesWithContent] = useState<number[]>([]);

    const checkPages = async () => {
      const pdfFile = await pdfjs.getDocument(pdfUrl).promise;
      const contentPages = [];
      for (let i = 1; i <= Math.min(numberOfPages, pdfFile.numPages); i++) {
        if (await hasPageContent(pdfFile, i)) {
          contentPages.push(i);
        }
      }
      setPagesWithContent(contentPages);
    };

    useEffect(() => {
      checkPages();
    }, [pdfUrl, numberOfPages]);

    const pages = useMemo(() => pagesWithContent.map(
      (pageNumber) => (
        <Page
          renderMode="canvas"
          key={`page_${pageNumber}`}
          pageNumber={pageNumber}
          onRenderSuccess={() => updateOldResume(pdfUrl)}
          scale={2}
        />
      ),
    ), [pagesWithContent, pdfUrl, updateOldResume]);

    return (
      <Document
        options={opt}
        loading={null}
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        className="rendering-document"
      >
        {pages}
      </Document>
    );
  },
  (prevProps, nextProps) => prevProps.pdfUrl === nextProps.pdfUrl,
);

const PDFViewer = ({ children, onError, setNumberOfPages }: any) => {
  const [pdfUrl, setPdfUrl] = useState<any>(null);
  const [previousRenderValue, setPreviousRenderValue] = useState<any>(null);
  const [numberOfPages, setNumPages] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const onDocumentLoadSuccess = async ({ numPages }: { numPages: number }) => {
    if (!pdfUrl) return;

    try {
      const pdfDoc = await pdfjs.getDocument(pdfUrl).promise;
      let contentPageCount = 0;

      for (let i = 1; i <= numPages; i++) {
        if (await hasPageContent(pdfDoc, i)) {
          contentPageCount++;
        }
      }

      setIsLoading(false);
      const finalPageCount = Math.min(contentPageCount, numPages);
      setNumPages(finalPageCount);
      setNumberOfPages(finalPageCount);

      if (contentPageCount > 3) {
        onError('The resume should be less than 3 pages');
      }
    } catch (error) {
      onError('Error loading PDF document', error);
      setIsLoading(false);
    }
  };

  const opt = useMemo(() => ({
    cMapUrl: '/bcmaps/',
    cMapPacked: true,
  }), []);

  const updateOldResume = (url: string) => {
    setPreviousRenderValue(url);
  };

  const childProps = useMemo(() => ({
    resume: children.props.resume,
    title: children.props.title,
    highlightedWords: children.props.highlightedWords,
    activeField: children.props.activeField,
  }), [children.props]);

  useEffect(() => {
    let mounted = true;
    const blobUrls: string[] = [];

    const renderPDF = async () => {
      const child = React.Children.only(children);
      try {
        setIsLoading(true);
        const blob = await pdf(child).toBlob();
        if (!mounted) return;

        const blobUrl = URL.createObjectURL(blob);
        blobUrls.push(blobUrl);
        setPdfUrl(blobUrl);
      } catch (error) {
        console.error('PDF rendering failed:', error);
      }
    };

    renderPDF();

    return () => {
      mounted = false;
      blobUrls.forEach(url => URL.revokeObjectURL(url));
    };
  }, [JSON.stringify(childProps)]);

  useEffect(() => {
    if (pdfUrl) {
      pdfjs.getDocument(pdfUrl).promise.then((pdfFile: PDFDocumentProxy) => {
        onDocumentLoadSuccess({ numPages: pdfFile.numPages });
      });
    }
  }, [pdfUrl]);

  return (
    <div className="renders">
      {isLoading && <div>Loading PDF...</div>}
      {previousRenderValue && !isLoading && (
        <Document
          key={previousRenderValue}
          className="previous-document"
          file={previousRenderValue}
          loading={null}
        >
          <Page renderMode="canvas" key={1} pageNumber={1} scale={2} />
        </Document>
      )}
      {pdfUrl && !isLoading && (
        <MemoizedPDFDocument
          pdfUrl={pdfUrl}
          numberOfPages={numberOfPages}
          opt={opt}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          updateOldResume={updateOldResume}
        />
      )}
    </div>

  );
};

export default PDFViewer;
