import {
  Button, IconButton, Tooltip, DefaultLogo,
} from '@careeros/coco';
import './contact-preview-card.scss';
import { ConnectionStatus, LanguageType } from '@/domains/core/contact/types';
import { StatusTag } from '@/domains/core/contact/components/status-tags/status-tag';

export type ContactPreviewWork = {
  company: string;
  position: string;
  dateRange?: string;
  logoUrl?: string;
  location?: string;
};

export type ContactPreviewLanguage = Omit<LanguageType, 'fluency'> & {
  fluency?: string;
};

export type ContactPreviewCardProps = {
  title: string;
  expandButtonLabel?: string;
  onExpandButtonClick?: () => void;
  currentPosition?: ContactPreviewWork;
  previousPositions?: ContactPreviewWork[];
  headline?: string;
  networkingStatus?: ConnectionStatus;
  linkedInConnectionStatus?: ConnectionStatus;
  languages?: ContactPreviewLanguage[];
  onCurrentCompanyClick?: () => void;
  linkedInUrl?: string;
};

/**
 * Renders a tile preview component for a job or company tile.
 *
 * @component
 * @example
 * ```tsx
 * <ContactPreviewCard
 *   title="John Doe"
 *   expandButtonLabel="Expand"
 *   onExpandButtonClick={() => {}}
 *   currentPosition={{ company: 'Google', position: 'Software Engineer' }}
 *   previousPositions={[]}
 *   headline="Passionate about building scalable web applications"
 *   onCurrentCompanyClick={() => {}}
 * />
 * ```
 */

export const ContactPreviewCard = ({
  title,
  expandButtonLabel = 'Expand',
  onExpandButtonClick,
  currentPosition,
  previousPositions,
  headline,
  networkingStatus,
  linkedInConnectionStatus,
  languages,
  onCurrentCompanyClick,
  linkedInUrl,
}: ContactPreviewCardProps) => (
  <div className="contact-preview-card">
    <div className="contact-preview-card__header">
      <div className="contact-preview-card__header-left">

        <span className="contact-preview-card__title">
          {title}
        </span>
        {currentPosition && (
          <div className="contact-preview-card__position">
            <span>
              {`${currentPosition.position} at `}
            </span>
            <span
              onClick={onCurrentCompanyClick}
              className={`contact-preview-card__position-company ${onCurrentCompanyClick ? 'contact-preview-card__position-company--clickable' : ''}`}
            >
              {currentPosition.company}
            </span>
          </div>
        )}
        {headline && (
          <span className="contact-preview-card__headline">
            {headline}
          </span>
        )}

        {(networkingStatus || linkedInConnectionStatus) && (
          <div className="contact-preview-card__statuses">
            {linkedInConnectionStatus && <StatusTag status={linkedInConnectionStatus} type="linkedin" withTooltip />}
            {networkingStatus && <StatusTag status={networkingStatus} type="networking" />}
          </div>
        )}
      </div>
      {onExpandButtonClick && (
        <div className="contact-preview-card__header-right">
          <Tooltip label={expandButtonLabel} position="top" withArrow>
            <IconButton
              icon="bi bi-arrows-angle-expand"
              mode="rounded"
              size="small"
              outlined
              handleClick={onExpandButtonClick}
            />
          </Tooltip>
        </div>
      )}
    </div>

    <div className="contact-preview-card__separator" />

    {(languages && languages.length > 0) && (
      <>
        <div className="contact-preview-card__languages">
          {languages.map(language => (
            <div className="contact-preview-card__language">
              <i className="contact-preview-card__language-icon bi bi-translate" />
              <span className="contact-preview-card__language-name">
                <span>{language.language}</span>
                {/* {language.fluency && (
                  <span className="contact-preview-card__language-level">{` (${language.fluency} proficiency)`}</span>
                )} */}
              </span>
            </div>
          ))}
        </div>
        <div className="contact-preview-card__separator" />
      </>
    )}

    <div className="contact-preview-card__body">
      {(linkedInUrl && !previousPositions) && (
        <div className="contact-preview-card__linkedin-url">
          <Button
            mode="text"
            size="medium"
            label={`Go to LinkedIn to find out more about ${title.split(' ')[0]}`}
            handleClick={() => window.open(linkedInUrl, '_blank')}
            icon="bi bi-arrow-right"
            iconPosition="right"
          />
        </div>
      )}
      {previousPositions?.map((exp) => (
        <div className="contact-preview-card__exp">
          {exp.logoUrl && (
            <div className="contact-preview-card__exp-logo">
              <DefaultLogo
                source={exp.logoUrl}
                size={40}
                name={exp.company}
                type="company"
                className="contact-preview-card__exp-logo-image"
              />
            </div>
          )}
          <div className="contact-preview-card__exp-info">
            <div className="contact-preview-card__exp-main">
              <span className="contact-preview-card__exp-info-position">
                {exp.position}
              </span>
              <span className="contact-preview-card__exp-info-company">
                {exp.company}
              </span>
            </div>
            <div className="contact-preview-card__exp-info-location-date">
              {exp.dateRange && (
                <span className="contact-preview-card__exp-info-date">
                  {exp.dateRange}
                </span>
              )}
              {exp.location && (
                <span className="contact-preview-card__exp-info-location">
                  {exp.location}
                </span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);
