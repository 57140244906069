import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import RocketEmoji from '@/assets/images/rocket-emoji.png';

import './suggested-contacts-empty.scss';
import { ContactTileCard } from '../contact-tile-card/contact-tile-card';
import { mockedSuggestedContacts } from '../../types/mocked-contacts';

export const SuggestedContactsEmpty = () => {
  const [isPromptVisible, setIsPromptVisible] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="suggested-contacts-empty">
      <div className="suggested-contacts-empty__container">
        {mockedSuggestedContacts.map((contact) => (
          <ContactTileCard
            key={contact.name}
            id={contact.name}
            name={contact.name}
            position={contact.position}
            image={contact.image}
            languages={contact.languages}
            isAlumni={contact.isAlumni}
            companyLogo={contact.companyLogo}
            companyName={contact.companyName}
            schoolName={contact.schoolName}
            networkingStatusRate={contact.networkingStatusRate}
            isBlurred
            handleMainButtonAction={() => setIsPromptVisible(true)}
            handleSecondaryButtonAction={() => setIsPromptVisible(true)}
          />
        ))}
      </div>
      {isPromptVisible && (
        <div className="suggested-contacts-empty__prompt">
          <img src={RocketEmoji} alt="" className="suggested-contacts-empty__prompt-image" />
          <h3 className="suggested-contacts-empty__prompt-title">Almost ready for take-off</h3>
          <p className="suggested-contacts-empty__prompt-description">
            To be able to browse suggested contacts, you need to first
            {' '}
            <mark>save 5 companies</mark>
          </p>

          <Button
            label="Explore Companies"
            mode="primary"
            size="medium"
            handleClick={() => navigate('/app/companies/explore')}
            icon="bi bi-box-arrow-up-right"
            iconPosition="right"
          />
        </div>
      )}
    </div>
  );
};
