import { useQuery } from 'react-query';
import { getAlumniByUniversity } from '../api/alumni';

export const useGetAlumniByUniversity = (universityID: string, options = {}) => useQuery(
  ['alumni', universityID], // Use an array with 'cohort' and the actual universityID as the query key
  () => getAlumniByUniversity(universityID), // Use an arrow function to call your actual function with the cohortID
  {
    retry: 0,
    // should be refetched in the background every 8 hours
    staleTime: 1000 * 60 * 60 * 8,
    ...options, // Spread additional options
  },
);
