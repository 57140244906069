import { DefaultLogo, Tooltip } from '@careeros/coco';
import './avatar-with-logo.scss';

type Props = {
  avatarImage: string;
  logoImage: string;
  avatarName: string;
  logoName: string;
  size?: number;
  logoSize?: number;
  isAvatarDisabled?: boolean;
  handleCompanyClick?: () => void;
  isOutlined?: boolean;
};

export const AvatarWithLogo = ({
  avatarImage,
  logoImage,
  avatarName,
  logoName,
  size = 48,
  logoSize = 16,
  isAvatarDisabled = false,
  handleCompanyClick,
  isOutlined = false,
}: Props) => (
  <div
    className="avatar-with-logo"
  >
    <Tooltip label={logoName} position="top" withArrow>
      <div className="avatar-with-logo__wrapper">
        <div className={`avatar-with-logo__avatar ${isAvatarDisabled ? 'avatar-with-logo__avatar--disabled' : ''} ${(isOutlined && !isAvatarDisabled) ? 'avatar-with-logo__avatar--outlined' : ''}`}>
          <DefaultLogo
            type="contact"
            source={avatarImage}
            name={avatarName}
            size={size}
            className={`avatar-with-logo__avatar-image ${isAvatarDisabled ? 'avatar-with-logo__avatar-image--disabled' : ''}`}
          />
          <DefaultLogo
            type="company"
            source={logoImage}
            name={logoName}
            size={size}
            handleClick={handleCompanyClick}
            className={`avatar-with-logo__avatar-image avatar-with-logo__avatar-image--hovered ${handleCompanyClick ? 'avatar-with-logo__avatar-image--clickable' : ''}`}
          />
        </div>
        <div className={`avatar-with-logo__logo ${(isOutlined && !isAvatarDisabled) ? 'avatar-with-logo__logo--outlined' : ''}`}>
          <DefaultLogo
            type="company"
            source={logoImage}
            name={logoName}
            size={logoSize}
            className="avatar-with-logo__logo-image"
            handleClick={handleCompanyClick}
          />
          <DefaultLogo
            type="contact"
            source={avatarImage}
            name={avatarName}
            size={logoSize}
            className={
              `avatar-with-logo__logo-image avatar-with-logo__logo-image--hovered ${isAvatarDisabled ? 'avatar-with-logo__logo-image--disabled' : ''}`
            }
          />
        </div>
      </div>
    </Tooltip>
  </div>
);
