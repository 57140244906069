import {
  Fragment, useContext, useState,
} from 'react';
import { DropdownList } from '@careeros/coco';
import { NavLink, useNavigate } from 'react-router-dom';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import './breadcrumbs.scss';

export type Crumb = {
  name: string;
  link: string;
  isCompany?: boolean;
};

type Props = {
  crumbs: Crumb[];
  onLastCrumbClick?: () => void;
};

export const BreadCrumbs = ({ crumbs, onLastCrumbClick }: Props) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const navigate = useNavigate();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;

  return (
    <div className="breadcrumbs">
      {(crumbs.length > 3) ? (
        <>
          <div className="breadcrumbs__item">
            <NavLink to={crumbs[0].link}>{crumbs[0].name}</NavLink>
          </div>
          <div className="breadcrumbs__divider">/</div>
          <div className="breadcrumbs__context-menu">
            <i data-testid="three-dots" className="breadcrumbs__menu-button bi bi-three-dots" onClick={() => setIsContextMenuOpen(true)} />
            {isContextMenuOpen && (
              <div className="breadcrumbs__menu">
                <DropdownList
                  options={crumbs.slice(1, -2).map((crumb) => ({
                    label: crumb.name,
                    onClick: () => {
                      const action = crumb.isCompany ? openTracker : navigate;
                      action(crumb.link);
                      setIsContextMenuOpen(false);
                    },
                  }))}
                  handleClose={() => setIsContextMenuOpen(false)}
                />
              </div>
            )}
          </div>
          <div className="breadcrumbs__divider">/</div>
          {crumbs.slice(-2).map((crumb, index) => (
            <Fragment key={crumb.name}>
              <div className="breadcrumbs__item">
                {crumb.isCompany && (
                  <span onClick={() => openTracker(crumb.link)}>{crumb.name}</span>
                )}
                {(!crumb.isCompany && index === crumbs.length - 1) && (
                  <span className="breadcrumbs__last-item" onClick={onLastCrumbClick}>{crumb.name}</span>
                )}
                {(!crumb.isCompany && index !== crumbs.length - 1) && (
                  <NavLink to={crumb.link}>{crumb.name}</NavLink>
                )}
              </div>
              {(index < crumbs.slice(-2).length - 1) && (
                <div className="breadcrumbs__divider">/</div>
              )}
            </Fragment>
          ))}
        </>
      ) : (crumbs.map((crumb, index) => (
        <Fragment key={crumb.name}>
          <div className="breadcrumbs__item">
            {crumb.isCompany && (
              <span onClick={() => openTracker(crumb.link)}>{crumb.name}</span>
            )}
            {(!crumb.isCompany && index === crumbs.length - 1) && (
              <span className="breadcrumbs__last-item" onClick={onLastCrumbClick}>{crumb.name}</span>
            )}
            {(!crumb.isCompany && index !== crumbs.length - 1) && (
              <NavLink to={crumb.link}>{crumb.name}</NavLink>
            )}
          </div>
          {(index < crumbs.length - 1) && (
            <div className="breadcrumbs__divider">/</div>
          )}
        </Fragment>
      )))}
    </div>
  );
};
