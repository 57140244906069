import { useNavigate, useParams } from 'react-router-dom';
import {
  useCallback,
  useContext, useEffect, useMemo, useState,
} from 'react';
// import { Input } from '@careeros/coco';
import { Loader } from '@/components/loader/loader';
import '../styles/dashboard.scss';
import {
  // CompaniesTableColumns,
  companyOptions,
  GraphWidget,
  industryOptions,
  networkingOptions,
} from '@/domains/core/advisor';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { IndustrySidebar } from '@/domains/generic';
import { useAnalytics } from '@/services/hooks/use-analytics';
// import { TableRow } from '@/components/table/table-types';
import {
  buildCohortsWithAll,
  // mapCompaniesSavedByStudentsToTableData,
} from '@/services/helpers/advisor';
import { useSelfUniversity } from '@/services/queries/user';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { ExportStudentData } from '@/domains/core/advisor/components/export-student-data/export-student-data';
// import Table from '@/components/table/table';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';
import { useMobileWarning } from './hooks/use-mobile-warning';
import { useDashboardAnalytics } from '@/services/hooks/use-dashboard-analytics';
import { universityLookerLinks } from './helpers/looker-data';
// import { AllCompaniesType } from '@/domains/core/advisor/types';

function DashboardAdvisorPage() {
  const { trackEvent } = useAnalytics();
  const { universityID, cohortID } = useParams();
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const navigate = useNavigate();
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  // const [searchCompanyValue, setSearchCompanyValue] = useState('');
  // const [filteredCompanies, setFilteredCompanies] = useState<TableRow[]>([]);
  const [lookerLink, setLookerLink] = useState('');

  const {
    cohorts,
    isCohortsLoading,
    industryData: topIndustries,
    isIndustryDataLoading,
    topCompaniesData,
    isTopCompaniesLoading,
    networkingData,
    isNetworkingDataLoading,
    // allCompanies,
    // isAllCompaniesLoading,
  } = useDashboardAnalytics(universityID);

  // const searchCompaniesByName = (name: string) => {
  //   if (!university) return;
  //   setSearchCompanyValue(name);
  //   const filteredAllCompanies = allCompanies?.filter((company: AllCompaniesType) => company.name.toLowerCase().includes(name.toLowerCase().trim()));
  //   setFilteredCompanies(mapCompaniesSavedByStudentsToTableData(filteredAllCompanies || allCompanies || [], universityID || university.id, cohortID || '', trackEvent));
  // };

  const handleCompanyClick = useCallback((companyIndex: number) => {
    const company = topCompaniesData?.all[companyIndex];
    trackEvent('Company Overview Clicked');
    if (cohortID) {
      navigate(`/app/company-overview/${company?.id}/university/${universityID || university?.id}/${cohortID}`);
    } else {
      navigate(`/app/company-overview/${company?.id}/university/${universityID || university?.id}`);
    }
    trackEvent('CA Company Kanban Opened from Top Companies');
  }, [topCompaniesData, cohortID]);

  const handleCompanySidebarClick = (id: string) => {
    if (cohortID) {
      navigate(`/app/company-overview/${id}/university/${universityID}/${cohortID}`);
    } else {
      navigate(`/app/company-overview/${id}/university/${universityID || university?.id}`);
    }
    trackEvent('CA Company Kanban Opened From Top Industries');
  };

  const handleIndustryClick = useCallback((industryIndex: number) => {
    if (!topIndustries) return;
    const industry: string = topIndustries.industries[industryIndex];
    const industryCompanies = topIndustries.industriesCompanies[industry];

    trackEvent('CA Top Industry Clicked');

    openSidebar(<IndustrySidebar industry={industry} companies={industryCompanies} closeSidebar={closeSidebar} onClick={handleCompanySidebarClick} />);
  }, [topIndustries]);

  const cohortsWithAll = useMemo(() => {
    if (!cohorts) return [];
    return buildCohortsWithAll('/app/dashboard-advisor', cohorts, universityID, university?.id);
  }, [cohorts]);

  useAdvisorRoleCheck();

  useEffect(() => {
    if (!isUniversityLoading && universityLookerLinks[university?.name || '']) {
      setLookerLink(universityLookerLinks[university?.name || '']);
    }
  }, [university, isUniversityLoading]);

  useMobileWarning();

  // useEffect(() => {
  //   if (allCompanies) {
  //     setFilteredCompanies(mapCompaniesSavedByStudentsToTableData(allCompanies, universityID || university?.id || '', cohortID || '', trackEvent));
  //   }
  // }, [allCompanies]);

  if (isCohortsLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="advisor-dashboard">
      <div className="advisor-dashboard__top-container">
        <div className="advisor-dashboard__top">
          <CohortsNavigation
            links={cohortsWithAll}
            defaultLinkName="All Cohorts"
          />
        </div>
        {university && (<ExportStudentData universityID={university.id || universityID} cohortID={cohortID} trackEventText="CA Student Data Exported from Dashboard" />)}
      </div>

      <div className="advisor-dashboard__content">
        <div className="career-os-grid">
          <div className="width-6/24">
            <div className="industry-data-container">
              {isNetworkingDataLoading && <Loader />}
              {networkingData && !isNetworkingDataLoading && (
                <GraphWidget
                  title="Student Networking Overview"
                  subtitle="Networking Criteria: Has initiated conversations from at least one contact in CareerOS."
                  chartType="doughnut"
                  chartClicked={() => { }}
                  options={networkingOptions}
                  data={networkingData}
                />
              )}
            </div>
          </div>
          <div className="width-18/24">
            <div className="industry-data-container">
              {isIndustryDataLoading && <Loader />}
              {topIndustries && !isIndustryDataLoading && (
                <GraphWidget
                  title="Top Industries on CareerOS"
                  subtitle="Click on an industry bar to view the engagement with all the companies in that industry."
                  chartType="bar"
                  chartClicked={handleIndustryClick}
                  options={industryOptions}
                  data={topIndustries.industryData}
                />
              )}
            </div>
          </div>
          <div className="width-24/24">
            <div className="industry-data-container">
              {isTopCompaniesLoading && <Loader />}
              {topCompaniesData?.companiesData && !isTopCompaniesLoading && (
              <GraphWidget
                title="Top 10 Companies Engagement"
                subtitle="Click on a company bar to view Student Activity with each company"
                chartType="bar"
                chartClicked={handleCompanyClick}
                options={companyOptions}
                data={topCompaniesData.companiesData}
              />
              )}
            </div>
          </div>
          {/* <div className="width-24/24">
            <div className="industry-data-container">
              {isAllCompaniesLoading && <Loader />}
              {allCompanies && !isAllCompaniesLoading && (
              <>
                <Input
                  icon="bi bi-search"
                  value={searchCompanyValue}
                  id="search-companies"
                  label=""
                  placeholder="Search Companies"
                  handleValueChange={searchCompaniesByName}
                />
                <br />
                <div className="companies__table">
                  <Table
                    data={filteredCompanies}
                    columns={CompaniesTableColumns}
                    hasTooltip={false}
                  />
                </div>
              </>
              )}
            </div>
          </div> */}
          {lookerLink && !cohortID && (
            <div className="width-24/24">
              <div className="jobs-section">
                <h3 className="graph-widget__title">
                  Saved Jobs Activity
                </h3>
                <div className="jobs-section__iframe" onClick={() => trackEvent('CA Job Insights Clicked')}>
                  <iframe title="Job Insights" className="airtable-embed" src={lookerLink} frameBorder="0" width="1366" height="1000" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(DashboardAdvisorPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
