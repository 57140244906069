/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Link } from 'react-router-dom';
import './actions-box.scss';
import { Badge } from '@careeros/coco';
import { TaskAction, TaskObject } from '@/domains/core/tracker-data';

interface ActionsBoxProps {
  tasks: TaskObject[];
  handleAction: (action: TaskAction) => void;
}

/**
 * Primary UI component for user actions
 */
export const ActionsBox = ({
  tasks = [],
  handleAction,
}: ActionsBoxProps) => (
  <div
    className="white-bordered-box"
  >
    <div className="actions-box">
      <h3 className="actions-box__title">
        Next Steps
      </h3>
      <ul className="actions-box__actions-list">
        {tasks && tasks.map((task: TaskObject, index) => (
          <>
            <li key={task.name} className="actions-box__task">
              <div className="actions-box__task-count">
                <span className="actions-box__number">{index + 1}</span>
              </div>
              <span>
                {task.content && task.content.map((content) => {
                  if (content.isLink) {
                    return (
                      <Link key={content.label} className="actions-box__task-link" to={content.to || ''} target={content.target}>
                        <span>{content.label}</span>
                      </Link>
                    );
                  }
                  if (content.action) {
                    return (
                      <span
                        key={content.label}
                        className="actions-box__task-link"
                        onClick={() => handleAction(content.action || { type: 'openTip', target: '' })}
                      >
                        {content.label}
                      </span>
                    );
                  }
                  return (
                    <span key={content.label}>
                      {content.label}
                    </span>
                  );
                })}
              </span>
              {task.isAI && (
              <Badge
                color="light-purple"
                icon="bi bi-stars"
                iconPosition="left"
                iconColor="#9747FF"
                label="AI"
              />
              )}
            </li>
            {(index < tasks.length - 1) && (
              <div className="actions-box__separator" />
            )}
          </>
        ))}
      </ul>
    </div>
  </div>
);
