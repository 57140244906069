import DOMPurify from 'dompurify';
import { useEffect, useRef, useState } from 'react';
import { Checkbox, Tooltip, DefaultLogo } from '@careeros/coco';
import './advisor-comment-preview.scss';
import { addBlankTargetToLinks } from '@/services/helpers/processHtml';

const sanitizeConfig = {
  ADD_ATTR: ['target'], // Allow the "target" attribute
};

type Props = {
  id: string;
  title: string;
  comment: string;
  imageUrl?: string;
  isResolved: boolean;
  dateAdded: string;
  advisorName: string;
  companyName?: string;
  handleResolveComment: (id: string) => Promise<void>;
  isResolveDisabled: boolean;
  profilePictureUrl: string;
  currentTab: string | null;
};

export const AdvisorCommentPreview = ({
  id,
  title,
  comment,
  imageUrl,
  dateAdded,
  isResolved,
  advisorName,
  companyName,
  profilePictureUrl,
  currentTab,
  isResolveDisabled,
  handleResolveComment,
}: Props) => {
  const [isResolvedSuccess, setIsResolvedSuccess] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const commentRef = useRef<HTMLDivElement>(null);

  const handleResolveCommentClick = async () => {
    try {
      await handleResolveComment(id);
      setIsResolvedSuccess(true);
    } catch {
      setIsResolvedSuccess(false);
    }
  };

  const handleSeeCommentClick = () => {
    setIsExpanded(!isExpanded);
  };

  const checkIfTruncated = () => {
    if (commentRef.current) {
      const lineHeight = parseInt(window.getComputedStyle(commentRef.current).lineHeight, 10);
      const maxHeight = lineHeight * 2; // 2 lines
      const actualHeight = commentRef.current.scrollHeight;

      setIsTruncated(actualHeight > maxHeight);
    }
  };

  useEffect(() => {
    checkIfTruncated();
    window.addEventListener('resize', checkIfTruncated);

    return () => {
      window.removeEventListener('resize', checkIfTruncated);
    };
  }, [commentRef.current, currentTab]);

  return (
    <div className="advisor-comment-preview">
      <div className="advisor-comment-preview__header">
        <div className="advisor-comment-preview__image" onClick={handleSeeCommentClick}>
          {imageUrl ? (
            <DefaultLogo
              source={imageUrl}
              name={companyName || advisorName}
              size={16}
              type={companyName ? 'company' : 'contact'}
              className="advisor-comment-preview__image-logo"
            />
          ) : (
            <i className="bi bi-chat-square-text" />
          )}
        </div>
        <Tooltip label="Click to expand comment" position="top" withArrow disableHoverListener={!isTruncated}>
          <div className="advisor-comment-preview__title" onClick={handleSeeCommentClick}>{title}</div>
        </Tooltip>
        <div className="advisor-comment-preview__date">{dateAdded}</div>
      </div>
      <div className="advisor-comment-preview__content-wrapper">
        <div
          ref={commentRef}
          className={`advisor-comment-preview__content ${isExpanded ? 'advisor-comment-preview__content--expanded' : ''}`}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(addBlankTargetToLinks(comment), sanitizeConfig) }}
        />
        {isTruncated && (
          <span className="advisor-comment-preview__content-show-more" onClick={handleSeeCommentClick}>
            <Tooltip label="Click to expand comment" position="top" withArrow disableHoverListener={isExpanded}>
              {isExpanded ? 'Show less' : 'Show more'}
            </Tooltip>
          </span>
        )}
      </div>
      <div className="advisor-comment-preview__bottom">
        <div className="advisor-comment-preview__advisor">
          <DefaultLogo
            source={profilePictureUrl}
            name={advisorName}
            size={16}
            type="contact"
            className="advisor-comment-preview__image-logo"
          />
          <span>{advisorName}</span>
        </div>
        <div className="advisor-comment-preview__resolve">
          {(!isResolveDisabled && !(isResolved || isResolvedSuccess)) && (
            <Checkbox
              id="advisor-comment-preview-resolve"
              label="Mark as Resolved"
              checked={isResolved}
              onChange={handleResolveCommentClick}
            />
          )}
          {(isResolveDisabled && !(isResolved || isResolvedSuccess)) && (
            <Tooltip label="Student has not worked on comment yet" position="top">
              <div
                className="advisor-comment-preview__unresolved advisor-comment-preview__unresolved--disabled"
              >
                <i className="advisor-comment-preview__resolved-icon advisor-comment-preview__resolved-icon--grey bi bi-dash-circle-dotted" />
                Unresolved
              </div>
            </Tooltip>
          )}
          {isResolved && (
            <div className="advisor-comment-preview__resolved">
              <i className="advisor-comment-preview__resolved-icon bi bi-check-circle-fill" />
              Resolved
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
