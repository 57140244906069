import { Contact } from '../../contact/types/contact';

export type UserStatistic = {
  weekly_goals: {
    'saved_contacts': number,
    'sent_linkedin_invitations': number,
    'saved_companies': number,
    'saved_companies_target': number,
    'saved_contacts_target': number,
    'sent_linkedin_invitations_target': number,
  },
  challenges: {
    'saved_contacts': number,
    'sent_linkedin_invitations': number,
    'saved_companies': number,
    'sent_outreaches': number,
    'save_company_challenge_status': 'activated' | 'halfway' | 'completed' | '',
    'save_contact_challenge_status': 'activated' | 'halfway' | 'completed' | '',
    'send_request_challenge_status': 'activated' | 'halfway' | 'completed' | '',
  },
};

export type LeaderboardItemType = {
  completed_quests: number;
  first_name: string;
  last_name: string;
};

export type QRLeaderboardItemType = {
  scanned_companies: number;
  first_name: string;
  last_name: string;
};

export type DashboardLeaderboardType = {
  university_name: string;
  university_logo: string;
  cohort_name: string;
  students: LeaderboardItemType[];
};

export type QRLeaderboardType = {
  university_name: string;
  university_logo: string;
  cohort_name: string;
  students: QRLeaderboardItemType[];
};

export type DashboardResponse = {
  conversationIDs?: string[];
  statistic: UserStatistic;
  pendingContacts: Contact[]
  leaderboard: DashboardLeaderboardType;
  contacts: Contact[];
};

export enum DasboardActionTileType {
  CompaniesCard = 'companies',
  Network = 'contacts',
  Inbox = 'inbox',
  Jobs = 'jobs',
  Applications = 'applications',
  Resume = 'resume',
  AITools = 'ai-tools',
  Resources = 'resources',
}

export type DashboardActionTileData = {
  id: string;
  name: string;
  image_url: string;
};

export type DashboardActionTile = {
  type: DasboardActionTileType;
  order: number;
  name: string;
  data: DashboardActionTileData[] | null;
};

export type DashboardActionsResponse = {
  cards: DashboardActionTile[];
  company_id: string | null;
};
