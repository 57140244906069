import { useQueryClient } from 'react-query';
import { useContext } from 'react';
import { ModalContext } from '@/components/modal/modal-provider';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { updateContactPositionInKanban } from '../api/contact';
import { ArchiveContactModal } from '@/domains/core/netwoking/components';
import { useAnalytics } from './use-analytics';

export const useArchiveContact = (updateContacts: () => void) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();

  const handleStatusUpdateResponse = (isSuccess: boolean, response: any) => {
    if (isSuccess) {
      addToast({
        type: 'success',
        message: 'Contact archived',
        additionalMessage: 'The contact has been successfully archived.',
      });
      queryClient.invalidateQueries('contacts');
      queryClient.invalidateQueries('contacts-no-cache');
      queryClient.invalidateQueries('quests');
      updateContacts();
    } else {
      addToast({
        type: 'error',
        message: 'Failed to archive contact',
        additionalMessage: "We're looking into the issue. Please try again later.",
      });
      trackEvent('Toast Error Shown', undefined, {
        message: 'Failed to archive contact',
        error: response?.error,
      });
    }
  };

  const archiveContact = async (id: string, position: number) => {
    const response = await updateContactPositionInKanban(id, 'archive', position);
    handleStatusUpdateResponse(response && response.message === 'The position has been updated', response);
  };

  const handleArchiveContactConfirmation = (id: string, position: number, rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveContact', 'true');
    }
    closeModal();
    archiveContact(id, position);
  };

  const requestArchiveConfirmation = (id: string, position: number = 0) => {
    const rememberAction = localStorage.getItem('rememberArchiveContact');

    if (!rememberAction) {
      openModal(
        <ArchiveContactModal
          handleArchiveContact={(remember) => handleArchiveContactConfirmation(id, position, remember)}
          closeModal={closeModal}
        />,
      );
    } else {
      handleArchiveContactConfirmation(id, position, false);
    }
  };

  return { requestArchiveConfirmation };
};
