import './resource-tile.scss';
import { Button } from '@careeros/coco';

interface ResourceProps {
  image?: string;
  name: string;
  description: string;
  link: string;
  linkTarget?: React.HTMLAttributeAnchorTarget | undefined;
  linkLabel: string;
  icon?: string;
  customOnClickHandler?: () => void;
}

export const ResourceTile = ({
  image,
  name,
  description,
  link,
  linkTarget = '_blank',
  linkLabel,
  icon,
  customOnClickHandler,
}: ResourceProps) => {
  const handleButtonClick = () => {
    if (customOnClickHandler) {
      customOnClickHandler();
      return;
    }

    window.open(link, linkTarget);
  };
  return (
    <div className="resource">
      {image && (
        <img
          className="resource__image"
          src={image}
          alt=""
        />
      )}
      {icon && (
        <div className="resource__icon-container">
          <i className={`resource__icon ${icon}`} />
        </div>
      )}
      <div className="resource__info">
        <h4 className="resource__name">
          {name}
        </h4>
        <p className="resource__description">
          {description}
        </p>
      </div>
      <Button mode="primary" outlined onClick={handleButtonClick} icon="bi bi-box-arrow-up-right" iconPosition="right" size="small" label={linkLabel} />
    </div>
  );
};
