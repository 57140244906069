import { Contact } from '../../contact/types';
import { COMPANY_STEPS } from '../constants';
import { ApplicationStatusAPI } from '../../tracker-data/status';
import { TagType } from '@/components/tag/tag';

export type CompanyOfficeLocation = CompanyLocation & { is_hq: boolean };

export type CompanyGeneralInformation = {
  bg_image_url: string | null;
  industry: string;
  size: string;
  linkedin_url: string | null;
  location: string;
  name: string;
  twitter_url: string | null;
  website_url: string | null;
};

type Statistic = {
  category: string;
  value: string
};
export type CompanyCulture = {
  certifications: string[];
  culture_description: string;
  diversity_statistics: Statistic[]
  glassdoor_url: string;
  kununu_url: string;
  mission: string;
  photos_videos: {
    url?: string;
    youtube_link?: string;
  }[];
  values: Statistic[];
  vision: string;
};

export type CompanyProfile = {
  overview: OverviewInformation;
  features: CompanyFeatures;
  general_information: PremiumCompanyGeneralInformation;
  events: CompanyEvents[];
  teams: Team[] | null;
  profile_type: CompanyProfileType;
  partner_type: CompanyPartnerType | null;
  application_info: {
    application_status: ApiStatus;
    application_id: string;
  }
  culture: CompanyCulture;
  articles_url_profile: string;
};
export type Company = {
  id: string;
  name: string;
  overview: string;
  short_description: string;
  hq_location: string;
  size?: string;
  company_url: string;
  logo_url: string;
  linkedin_url: string;
  twitter_url: string;
  instagram_url?: string;
  industry: string;
  careeros_industry: string;
  tags: string[];
  locations?: CompanyOfficeLocation[];
  application_status?: ApiStatus;
  application_id?: string;
  jobs: Job[];
  values?: string;
  position?: number;
  bg_image_url?: string | null;
  website_url?: string;
  company_size?: string;
  articles_url_tracker?: string;
  profile_type?: CompanyProfileType;
  partner_type?: CompanyPartnerType | null;
  teams?: Team[];
  additional_links?: {
    label: string;
    url: string;
  }[];
};

export type PremiumCompanyGeneralInformation = {
  description: string;
  name: string;
  hq_location: string;
  industry: string;
  careeros_industry: string;
  company_size: string;
  website_url: string;
  linkedin_url: string;
  twitter_url: string | undefined;
  instagram_url: string | undefined;
  logo_url: string;
  bg_image_url: string;
  show_background: boolean;
  id: string
  tags: string[] | null;
  overview: string;
  additional_links?: {
    label: string;
    url: string;
  }[];
};

export type CompanyEvents = {
  id: string | null;
  title: string;
  description: string;
  format: string;
  date_time: string;
  time_zone: string;
  signup_link: string;
  created_at: string | null;
  updated_at: string | null;
};

export type CompanyFeatures = {
  show_background: boolean
  show_benefits: boolean
  show_certifications: boolean
  show_company_mission: boolean
  show_company_values: boolean
  show_company_vision: boolean
  show_culture_description: boolean
  show_culture_media: boolean
  show_diversity_statistics: boolean
  show_events: boolean
  show_faq: boolean
  show_glassdoor: boolean
  show_kununu: boolean
  show_office_locations: boolean
  show_office_perks: boolean
  show_overview_media: boolean
  show_teams: boolean
};

export type CompanyLocationMap = {
  address: string;
  latitude: number;
  longitude: number;
};

export type FaqItem = {
  category: string;
  question: string;
  answer: string;
};

export type OverviewInformation = {
  benefits: {
    value: string;
  }[];
  benefits_custom: string;
  upload_photos_videos: {
    url?: string;
    youtube_link?: string;
  }[]
  office_locations: CompanyLocationMap[];
  office_perks: {
    value: string;
  }[];
  office_perks_custom: string;
  faq_questions: FaqItem[]
  overview: string
};

// TODO: this type should be moved to jobs domain or shared location
export type Job = {
  id: string,
  company_name: string,
  title: string,
  location: string,
  linkedin_url: string,
  description: string,
  note: string,
  deadline: string | null,
  rolling_date: boolean,
  posted_on: string,
  saved: boolean,
  [key: string]: any
};

// TODO: This type is used for the contact notes too, so it should be moved to a shared location
export type Note = {
  id: string;
  title: string;
  updated_at: string;
  content: string;
  company_id?: string;
  student_id?: string;
  contact_id?: string;
  is_ai_generated?: boolean;
  is_my_note?: boolean;
  advisor?: {
    id: string;
    first_name: string;
    last_name: string;
    profile_picture_url: string;
  }
};

export type Industry = {
  name: string;
  icon: string;
};

export type Category = {
  name: string;
  summary: string;
  companies: Company[];
  emoji: string;
  name_with_emoji: string;
};

export type ApiStatus = 'saved' | 'networking' | 'applying' | 'applied' | 'interviewing' | 'offer' | 'archive' | 'reject';

export type CompanyResponse = {
  company: Company | undefined;
  contacts: Contact[];
  status?: typeof COMPANY_STEPS[number];
};

export type QRCompanyResponse = {
  company: Company;
};

export type CompanyPatchRequestPayload = {
  id: number;
  tags: string;
  category: string;
};

type JobInfo = {
  title: string;
  linkedin_url: string;
  id: string;
};

type CompanyComment = {
  id: string;
  name: string;
  logo_url: string;
};

type CommentApplication = {
  id: string;
  company: CompanyComment;
};
export type Comment = {
  id: string;
  company_name?: string
  application_id: string;
  application: CommentApplication;
  comment: string;
  resolved: boolean;
  created_at: string;
  advisor_name: string;
};

export type TileContactInfo = {
  id: string;
  first_name: string;
  last_name: string;
  image_url: string;
};

export type Tile = {
  id: string;
  company_name: string
  company_id: string;
  logo_url: string;
  industry: string;
  status: ApplicationStatusAPI;
  ordering: number
  jobs: JobInfo[];
  linkedin_url: string;
  updated_at: string;
  count_of_contacts: number;
  rating: number;
  // count_of_jobs?: number;
  contacts?: TileContactInfo[];
  hiring_score: 0 | 1 | 2 | 3;
};

export type CompanyWithCount = Tile & {
  count: number;
};

export type FilterOption = {
  name: JSX.Element | string;
  value: string | number | boolean;
};

export type FilterNameType = 'industry' | 'hype_level' | 'contacts_saved' | 'jobs_saved' | 'status' | 'hiring_score';

export type CompanyLocation = {
  city: string;
  country: string;
  country_name: string;
};

export type FilterCompaniesRequestBody = {
  locations?: CompanyLocation[],
  tags?: string,
  tag?: string,
  sizes?: string,
  industries?: string,
  categories?: string,
  category?: string,
  saved_by_user?: boolean,
};

export type Teams = {
  teams: Team[] | null
  show_teams: boolean
};

export type Team = {
  id?: string
  name: string
  description: string
  skills: Array<string>
  tempSkill: string
  working_style: Array<{
    category: string
    value: string
  }>
  languages_spoken: Array<string>
  application_steps: Array<{
    id?: string
    step_name: string
    step_description: string
    step_order: number
  }> | null
};

export type OBTilePreviewDataType = {
  id: string;
  tags?: TagType[];
  name: string;
  location?: string;
  description: string;
  date?: string | null;
};

export const COMPANY_PARTNER_TYPE_UNIVERSITY_PARTNER = 'university_partner';
export const COMPANY_PARTNER_TYPE_EMPLOYER = 'employer';

export type CompanyPartnerType = typeof COMPANY_PARTNER_TYPE_UNIVERSITY_PARTNER | typeof COMPANY_PARTNER_TYPE_EMPLOYER;

export const COMPANY_PROFILE_TYPE_PREMIUM = 'premium';
export const COMPANY_PROFILE_TYPE_BASIC = 'basic';

export type CompanyProfileType = typeof COMPANY_PROFILE_TYPE_PREMIUM | typeof COMPANY_PROFILE_TYPE_BASIC;
