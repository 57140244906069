/* eslint-disable no-irregular-whitespace */
import {
  useCallback, useContext, useMemo, useState,
} from 'react';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { sendMassEmail } from '@/domains/core/advisor/api/student';
import {
  KanbanBoardLayout, MiniAdvisor, StudentModel, StudentStatus,
} from '@/domains/core/advisor/types';
import { AdvisorKanbanBoard } from '../../components/kanban-board/kanban-board';
import { EmailBox } from '../../components/email-box/email-box';

type StudentKanbanProps = {
  columns: StudentStatus[];
  studentsData: StudentModel[];
  universityID: string;
  allAdvisors: MiniAdvisor[];
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  showSendButton?: boolean;
  selfFullName?: string;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  cohortName?: string;
};

const StudentKanban = ({
  columns,
  studentsData,
  universityID,
  allAdvisors,
  scrollContainerRef,
  onScroll,
  showSendButton,
  selfFullName,
  cohortName,
}: StudentKanbanProps) => {
  const [isEmailBoxOpened, setIsEmailBoxOpened] = useState(false);
  const { addToast }: AddToastType = useContext(ToastContext);
  const [sendMessageToColumn, setSendMessageToColumn] = useState<StudentStatus | null>(null);

  const studentCount = useMemo(() => (sendMessageToColumn
    ? studentsData.filter((student) => student.status === sendMessageToColumn).length
    : 0), [sendMessageToColumn, studentsData]);

  const transformDataToKanban = useCallback((s: StudentModel[]): KanbanBoardLayout => {
    const kanbanBoard = new Map<StudentStatus, StudentModel[]>();
    const sortedStudents = s.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName) || a.email.localeCompare(b.email));

    columns.forEach((column) => {
      const studentsPerColumn = sortedStudents.filter((student) => student.status === column);

      kanbanBoard.set(column, studentsPerColumn);
    });

    return kanbanBoard;
  }, [studentsData]);

  const handleSendMessagePerColumn = (column: StudentStatus) => {
    setIsEmailBoxOpened(true);
    setSendMessageToColumn(column);
  };

  const cleanEmailMessage = (html: string) => html
    .replace(/<span class="ca-email-box__highlights-box-container" contenteditable="false">﻿<span contenteditable="false">\s*<div class="ca-email-box__highlights-box">\s*<span>Student First Name<\/span>\s*<i class="bi bi-x ca-email-box__highlights-close"><\/i>\s*<\/div>\s*<\/span>﻿<\/span>/g, '[student first name]')
    .trim();

  const sendEmail = async (message: string, subject: string) => {
    addToast(
      {
        type: 'success',
        message: 'Success',
        additionalMessage: 'Your email has been sent.',
      },
    );
    const columnEmails = sendMessageToColumn ? studentsData.filter((student) => student.status === sendMessageToColumn).map((student) => student.email) : [];

    const cleanMessage = cleanEmailMessage(message);

    if (sendMessageToColumn) {
      try {
        await sendMassEmail(cleanMessage, subject, columnEmails, sendMessageToColumn, cohortName);
        addToast({
          type: 'success',
          message: 'Email successfully sent',
          additionalMessage: `Email sent to students in the ${sendMessageToColumn} column.`,
        });
      } catch (error) {
        addToast({
          type: 'error',
          message: 'Failed to send an email',
          additionalMessage: 'Something went wrong while sending the email.',
        });
      }
    }
    setIsEmailBoxOpened(false);
  };

  return (
    <>
      <AdvisorKanbanBoard
        messageButtonTooltip="Send email to all students in this category"
        sendMessagePerColumn={(column) => handleSendMessagePerColumn(column)}
        warningColumns={['Inactive']}
        layout={transformDataToKanban(studentsData)}
        universityId={universityID}
        allAdvisors={allAdvisors}
        scrollContainerRef={scrollContainerRef}
        onScroll={onScroll}
        showSendButton={showSendButton}
      />

      {isEmailBoxOpened && (
        <EmailBox
          handleSend={sendEmail}
          setIsBoxOpened={setIsEmailBoxOpened}
          column={sendMessageToColumn}
          showAITemplates
          selfFullName={selfFullName}
          studentCount={studentCount}
        />
      )}
    </>
  );
};

export default StudentKanban;
