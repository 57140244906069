import { Counter } from '@careeros/coco';
import { useContext } from 'react';
import { Loader } from '@/components/loader/loader';

import '../styles/gamification.scss';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { useGamification } from '@/services/hooks/use-gamification';
import { ModalContext } from '@/components/modal/modal-provider';
import { ChallengeBadge, ChallengeModal } from '@/domains/core/student';

function ChallengesPage() {
  const {
    earnedChallenges,
    visibleChallenges,
    lockedChallenges,
    isChallengesLoading,
  } = useGamification();

  const { openModal, closeModal } = useContext(ModalContext) as any;

  const handleBadgeClick = (badgeId: string) => {
    const badge = earnedChallenges.find((bdg) => bdg.id === badgeId);
    if (badge) {
      openModal(
        <ChallengeModal
          badgeImage={badge.image}
          description={`You earned the <strong>${badge.name}</strong> badge by ${badge.completed_description}`}
          color={badge.color}
          id={badge.id}
          isRead={badge.is_read}
          handleViewAllBadges={closeModal}
          handlePrimaryClick={closeModal}
        />,
      );
    }
  };

  if (isChallengesLoading) {
    return (
      <div className="suggested-contacts-loading">
        <Loader />
      </div>
    );
  }
  return (
    <div className="gamification-challenges">
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="challenges-wrapper">
            <div className="challenges-section">
              <div className="challenges-section__title">
                <span>🎖️</span>
                Earned Badges
                <span>🎖️</span>
                <Counter counter={earnedChallenges.length} size="small" mode="default" />
              </div>
              <div className="challenges-section__badges">
                {earnedChallenges.sort((a, b) => new Date(b.completed_at).getTime() - new Date(a.completed_at).getTime()).map((challenge) => (
                  <ChallengeBadge key={challenge.id} handleBadgeClick={handleBadgeClick} challenge={challenge} />
                ))}
              </div>
            </div>
            <div className="challenges-section">
              <div className="challenges-section__title">
                <span> 👀</span>
                Locked Badges
                <span>👀</span>
                <Counter counter={lockedChallenges.length} size="small" mode="default" />
              </div>
              <div className="challenges-section__badges challenges-section__badges--locked">
                {lockedChallenges.map((challenge) => (
                  <ChallengeBadge isLocked key={challenge.id} challenge={challenge} />
                ))}
              </div>
            </div>
            <div className="challenges-section">
              <div className="challenges-section__title">
                <span> 🎯</span>
                Ongoing Challenges
                <span>🎯</span>
                <Counter counter={visibleChallenges.length} size="small" mode="default" />
              </div>
              <div className="challenges-section__badges challenges-section__badges--locked">
                {visibleChallenges.map((challenge) => (
                  <ChallengeBadge isOngoing key={challenge.id} challenge={challenge} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(ChallengesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
