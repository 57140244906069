import CompanySavedTipModal from './company-saved/company-saved';
import NetworkingTipModal from './networking/networking';
import ApplyingTipModal from './applying/applying';
import AppliedTipModal from './applied/applied';
import InterviewingTipModal from './interviewing/interviewing';
import OfferTipModal from './offer/offer';
import './tracker-tip.scss';
import { APIstatuses } from '@/domains/core/tracker-data';
import { trackerTipsLocation } from '@/domains/core/tracker-data/tracker-data';

interface TrackerTipProps {
  title: string;
  activeState: string;
  tipLocation: string;
  university?: string;
}
/**
 * Primary UI component for the modal that warns the user about moving to the next step
 */

export const TrackerTip = ({
  title, activeState, tipLocation, university,
}: TrackerTipProps) => {
  const renderSwitch = () => {
    switch (APIstatuses[activeState]) {
      case 'Saved':
        return <CompanySavedTipModal openTip={trackerTipsLocation[tipLocation]} />;
      case 'Networking':
        return <NetworkingTipModal openTip={trackerTipsLocation[tipLocation]} />;
      case 'Applying':
        return <ApplyingTipModal university={university} openTip={trackerTipsLocation[tipLocation]} />;
      case 'Applied':
        return <AppliedTipModal openTip={trackerTipsLocation[tipLocation]} />;
      case 'Interviewing':
        return <InterviewingTipModal openTip={trackerTipsLocation[tipLocation]} />;
      case 'Offer':
        return <OfferTipModal openTip={trackerTipsLocation[tipLocation]} />;
      default:
        return <CompanySavedTipModal openTip={trackerTipsLocation[tipLocation]} />;
    }
  };
  return (
    <div className="tracker-tip">
      {APIstatuses[activeState] !== 'Saved' && (
        <div className="tracker-tip__title">{title}</div>
      )}
      <div className="tracker-tip__content">{renderSwitch()}</div>
    </div>
  );
};
