import { DefaultLogo } from '@careeros/coco';
import { Column, TableRow } from '@/components/table/table-types';
import { StatusTag } from '../../contact/components/status-tags/status-tag';
import { ConnectionStatus } from '../../contact/types';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { StudentIterationType } from '@/domains/core/advisor/types';

const NETWORKING_STATUS_MAP = {
  no_outreach: 'notConnected',
  response_pending: 'pending',
  response_received: 'connected',
} as const;

type BackendNetworkingStatus = keyof typeof NETWORKING_STATUS_MAP;
type FrontendNetworkingStatus = typeof NETWORKING_STATUS_MAP[BackendNetworkingStatus];

const convertNetworkingStatus = (backendStatus: BackendNetworkingStatus): FrontendNetworkingStatus => NETWORKING_STATUS_MAP[backendStatus];

const calculateNetworkingStatusValue = (status: ConnectionStatus) => {
  const statusValues = {
    connected: 3,
    pending: 2,
    notConnected: 1,
  };

  return statusValues[status];
};

export const ContactBookContactsTableColumns: Column[] = [
  {
    label: 'Full Name',
    accessor: 'full_name',
    sortable: true,
    type: 'link',
    isCentered: false,
  },
  {
    label: 'First Outreach',
    accessor: 'first_outreach',
    sortable: true,
    type: 'text',
    isCentered: false,
  },
  {
    label: 'Networking Status',
    accessor: 'networking_status',
    sortable: true,
    type: 'text',
    isCentered: false,
    sortbyOrder: 'desc',
  },
];

export const mapStudentsToContactTableData = (
  contacts: StudentIterationType[],
  handleNavigationToProfile: (id: string) => void,
  universityId?: string,
): TableRow[] => contacts
  .filter((contact) => contact?.student?.first_name && contact?.student?.last_name)
  .map((contact: StudentIterationType) => ({
    id: contact?.student?.id,
    handleRowClick: () => handleNavigationToProfile(contact?.student?.id),
    items: {
      full_name: {
        value: `${contact?.student?.first_name} ${contact?.student?.last_name}`,
        label: `${contact?.student?.first_name} ${contact?.student?.last_name}`,
        image: (
          <DefaultLogo
            type="contact"
            source={contact?.student?.profile_picture_url}
            className="contacts-page__table-image"
            name={`${contact?.student?.first_name} ${contact?.student?.last_name}`}
            size={24}
          />
        ),
        type: 'link',
        to: `/app/advisor/students/student-overview/${contact?.student?.id}/university/${universityId}?tab=Profile`,
      },
      first_outreach: {
        label: formatDateDeltaFromNow(contact?.first_outreach_at),
        value: formatDateDeltaFromNow(contact?.first_outreach_at),
      },
      networking_status: {
        label: (
          <div className="contacts-page__statuses">
            <StatusTag status={convertNetworkingStatus(contact?.networking_status) || 'notConnected'} type="careerAdvisor" />
          </div>
        ),
        value: calculateNetworkingStatusValue(convertNetworkingStatus(contact?.networking_status) || 'notConnected'),
      },
    },
  }));
