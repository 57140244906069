import { formatDates, orderByDate } from '@/services/helpers/contact';
import { ContactPreviewLanguage, ContactPreviewWork } from '../components/contact-preview-card/contact-preview-card';
import {
  ConnectionStatus, ContactWithNetworkingData, SuggestedContactType,
} from '../types';

type SuggestedContactPreviewData = {
  title: string;
  currentPosition: ContactPreviewWork;
  previousPositions: ContactPreviewWork[];
  headline?: string;
  networkingStatus?: ConnectionStatus;
  linkedInConnectionStatus?: ConnectionStatus;
  languages: ContactPreviewLanguage[];
};

export function mapSuggestedContactToContactPreviewCard(contact: SuggestedContactType): SuggestedContactPreviewData {
  const previousPositions = contact.contact.career_summary.work?.sort(orderByDate).map(formatDates).map((work) => ({
    company: work.name,
    position: work.position,
    dateRange: work.startDate && `${work.startDate} - ${work.endDate}`,
    // logoUrl: work.logoUrl,
    location: work.location,
  })) || [];

  return {
    title: contact.contact.career_summary.basics.name,
    currentPosition: {
      company: contact.contact.current_position.company,
      position: contact.contact.current_position.title,
    },
    previousPositions,
    headline: contact.contact.career_summary.basics.headline,
    languages: contact.contact.career_summary.languages || [],
    linkedInConnectionStatus: contact.contact.linkedin_connection_status || undefined,
  };
}

export function mapSavedContactToContactPreviewCard(contact: ContactWithNetworkingData): SuggestedContactPreviewData {
  const previousPositions = contact.career_summary?.work?.sort(orderByDate).map(formatDates).map((work) => ({
    company: work.name,
    position: work.position,
    dateRange: work.startDate && `${work.startDate} - ${work.endDate}`,
    location: work.location,
  })) || [];

  return {
    title: contact.career_summary.basics.name,
    currentPosition: {
      company: contact.current_position.company,
      position: contact.current_position.title,
    },
    headline: contact.career_summary.basics.label,
    previousPositions,
    languages: contact.career_summary.languages || [],
    linkedInConnectionStatus: contact.linkedin_connection_status || undefined,
    networkingStatus: contact.networking_status || undefined,
  };
}
