import { TagColors } from '@/components/tag/tag';
import { Company, OBTilePreviewDataType } from '@/domains/core/company/types';
import { getTagIcons } from '@/domains/core/job/helpers/getTagIcons';
import { getSingleCompany } from '@/services/api/company';
import { getJobByIDPrivate } from '@/services/api/job';

export const getCompanyPreviewData = async (companyId: string): Promise<OBTilePreviewDataType | null> => {
  try {
    const company: Company = await getSingleCompany(companyId);

    if (!company?.name) {
      return null;
    }

    const companyPreviewData: OBTilePreviewDataType = {
      id: company.id,
      tags: company.tags.map((tag) => ({
        label: tag,
        color: TagColors.LightPurple,
        id: tag,
      })),
      name: company.name,
      location: company.hq_location,
      description: company.overview,
    };

    return companyPreviewData;
  } catch (error) {
    return null;
  }
};

export const getJobPreviewData = async (jobId: string): Promise<OBTilePreviewDataType | null> => {
  try {
    const jobInfo = await getJobByIDPrivate(jobId);

    const date = jobInfo.deadline ? new Date(jobInfo.deadline).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', year: 'numeric' }) : null;

    const mappedJob: OBTilePreviewDataType = {
      id: jobInfo.id,
      tags: jobInfo.tags.map((tag) => ({
        label: tag.tag_content,
        color: TagColors.Grey,
        id: tag.tag_content,
        image: getTagIcons(tag.tag_type),
      })),
      name: jobInfo.title,
      description: jobInfo.description,
      date,
    };

    return mappedJob;
  } catch (error) {
    return null;
  }
};
