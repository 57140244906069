import './student-contacts-kanban.scss';
import { Counter } from '@careeros/coco';
import { Contact } from '@/domains/core/contact/types';
import { ReadonlyContactKanbanTile } from '../readonly-contact-kanban-tile/readonly-contact-kanban-tile';

type ContactsKanbanProps = {
  columns: string[];
  layout: any;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
};

export const StudentContactsKanban = ({
  columns,
  layout,
  scrollContainerRef,
  onScroll,
}: ContactsKanbanProps) => (
  <div className="readonly-contact-kanban-board">
    <div className="readonly-contact-kanban-board__content" ref={scrollContainerRef} onScroll={onScroll}>
      {columns.map((column, index) => (
        <div
          key={column}
          className={`readonly-contact-kanban-board__column readonly-contact-kanban-board__column--nr-${index}`}
        >
          <div className={`readonly-contact-kanban-board__title ${column === 'Archived' ? 'readonly-contact-kanban-board__title--archive' : ''}`}>
            <span>{column}</span>
            <span className="readonly-contact-kanban-board__counter">
              <Counter counter={layout[column] && layout[column].length} size="medium" mode="default" />
            </span>
          </div>

          {layout[column] && layout[column].map((contact: Contact) => (
            <ReadonlyContactKanbanTile
              key={contact.id}
              image={contact.career_summary?.basics?.image || ''}
              name={contact.career_summary?.basics?.name || ''}
              companyName={contact.current_position.company || ''}
              position={contact.current_position.title || ''}
              linkedInConnectionStatus={contact.linkedin_connection_status || 'notConnected'}
              isArchived={contact.kanban_position.status === 'archive'}
            />
          ))}
        </div>
      ))}
    </div>
  </div>
);
