import { Button } from '@careeros/coco';
import { useEffect, useState, useMemo } from 'react';

import { useQueryClient } from 'react-query';
import { CompanyProgressTracker } from '../../components/company-progress-tracker/company-progress-tracker';
import { Company } from '../../types';
import { Contact, SuggestedContactType } from '@/domains/core/contact/types';

import './company-status.scss';
import { Loader } from '@/components/loader/loader';
import CompanySavedContacts from '../company-saved-contacts/company-saved-contacts';
import { CampusChampionsForCompanyContainer } from '@/domains/core/contact/components/campus-champions-container /campus-champions-container';
import { useSelfCampusChampions } from '@/services/queries/campus-champion';

type CompanyStatusProps = {
  company: Company;
  application: {
    application_id: string;
    application_status: string;
  }
  saveCompany: () => void;
  goToContact: (contactID: string) => void;
  contacts: Contact[];
};

const CompanyStatusContainer = ({
  company, application, saveCompany, contacts, goToContact,
}: CompanyStatusProps) => {
  const linkToAddContacts = `https://www.linkedin.com/search/results/people/?keywords=${encodeURIComponent(company.name)}&spellCorrectionEnabled=false&origin=GLOBAL_SEARCH_HEADER&sid=n)F`;
  const { data: campusChampions, isLoading: areCampusChampionsLoading } = useSelfCampusChampions();
  const [companyHasCampusChampions, setCompanyHasCampusChampions] = useState(false);

  const queryClient = useQueryClient();

  const filteredContacts = useMemo(() => (
    Array.isArray(contacts) ? contacts.filter((contact) => contact.current_position.company_id === company.id && contact.kanban_position.status !== 'archive') : []
  ), [contacts, company.id]);

  const updateContacts = () => {
    queryClient.invalidateQueries('contacts-no-cache');
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries(['company-profile', company.id]);
    queryClient.invalidateQueries('gamification-actions');
    queryClient.invalidateQueries('challenges');
    queryClient.invalidateQueries('xp');
    queryClient.invalidateQueries('leaderboards');
  };
  useEffect(() => {
    if (!areCampusChampionsLoading && campusChampions) {
      const hasCampusChampions = Array.isArray(campusChampions) && campusChampions.some((champion: SuggestedContactType) => champion.company_id === company.id);
      setCompanyHasCampusChampions(hasCampusChampions);
    }
  }, [campusChampions, areCampusChampionsLoading]);

  if (areCampusChampionsLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }
  return (
    <div className="company-status-container">
      <div className="company-status-container__tracker-container">
        <div className={`progress-tracker ${(application.application_status || companyHasCampusChampions) ? '' : 'progress-tracker--full'}`}>
          <h3 className="progress-tracker__title">
            Your application progress
          </h3>
          {application.application_status ? (
            <CompanyProgressTracker status={application.application_status} name={company.name} id={company.id} />
          ) : (
            <>
              <p className="progress-tracker__text">
                Saving
                {' '}
                {company.name}
                {' '}
                allows you to start your complete journey with them, including tips for success and a game plan checklist for each step along the way that will prepare and propel you towards your dream job.
              </p>
              <Button mode="primary" handleClick={saveCompany} iconPosition="left" icon="bi bi-bookmark-heart" label="Save Company" size="medium" />
            </>
          )}
        </div>
      </div>
      {application.application_status && <CompanySavedContacts applicationStatus={application.application_status} contacts={filteredContacts} linkToAddContacts={linkToAddContacts} goToContact={goToContact} />}
      {companyHasCampusChampions && <CampusChampionsForCompanyContainer updateContacts={updateContacts} companyID={company.id} savedContacts={contacts} archivedContacts={[]} size="small" source="Company Profile Page" />}
    </div>
  );
};

export default CompanyStatusContainer;
