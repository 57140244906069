import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './tracker-tips-tab.scss';
import { Banner, Button, InfoModal } from '@careeros/coco';
import { TrackerContent } from '../tracker-content/tracker-content';
import { useTabs } from '@/components/tabs/TabsContext';
import { SuccessModal, TrackerTip } from '@/domains/generic/modals';
import { TaskAction } from '@/domains/core/tracker-data';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { TrackerResources } from '../tracker-resources/tracker-resources';
import { ModalContext } from '@/components/modal/modal-provider';
import SuccessImage from '@/assets/images/success.jpg';
import { QuestTileType } from '@/domains/core/student/types';
import { TrackerQuests } from '../tracker-quests-container/tracker-quests-container';

type Props = {
  activeState: any;
  status: any;
  tasks: any[];
  university?: string;
  changeTab: (tab: string, state?: string, setTabAsCurrent?: boolean) => void;
  tabState?: 'tips';
  handleRejection: () => void;
  handleRestoreCompany: () => void;
  quests: QuestTileType[];
  handleQuestDelete: (questID: string) => void;
  infoBanner: {
    title: string;
    text: string;
    button?: string;
  };
};

export const TrackerTipsTab = ({
  activeState,
  status,
  tasks,
  university,
  changeTab,
  tabState,
  handleRejection,
  handleRestoreCompany,
  quests,
  handleQuestDelete,
  infoBanner,
}: Props) => {
  const { setCurrentTab } = useTabs();
  const { trackEvent } = useAnalytics();
  const { openModal } = useContext(ModalContext) as any;
  const [targetTip, setTargetTip] = useState<string>();
  const showTips = useMemo(() => tabState === 'tips', [tabState, activeState]);
  const showResources = useMemo(() => activeState.resources && activeState.resources.some((resource: any) => resource.forUniversities.includes(university)), [activeState, university]);

  const handleActionClick = (action: TaskAction) => {
    if (action.type === 'openTab') {
      setCurrentTab(action.target);
    }

    if (action.type === 'openTip') {
      setTargetTip(action.target);
      changeTab('Tips & Tricks', 'tips');
    }

    if (action.type === 'useAITool') {
      changeTab('AI Tools', action.target, true);
    }

    trackEvent('Tips & Tricks: Tip clicked');
  };

  const closeTips = () => {
    changeTab('Tips & Tricks', undefined);
    setTargetTip(undefined);
  };

  const openSuccessModal = () => openModal(
    <SuccessModal
      title="Congratulations!"
      image={SuccessImage}
    >
      The sky’s the limit. We wish you a wonderful start to your career!
    </SuccessModal>,
  );

  const handleReceivedRejection = () => {
    openModal(
      <InfoModal
        title="Thanks for sharing!"
        description="This company will now automatically be moved to the “Rejected” column on your Overview Board. Your progress and data such as contacts, notes etc. will not be lost."
        handleButtonClick={handleRejection}
        buttonLabel="Got it"
      />,
    );
  };

  useEffect(() => {
    changeTab('Tips & Tricks', tabState);
  }, [status]);

  if (showTips) {
    return (
      <div className="jobs-tab">
        <div className="jobs-tab__back-button">
          <Button
            mode="invisible"
            size="medium"
            label="All Tips"
            icon="bi bi-arrow-left"
            handleClick={closeTips}
          />
        </div>

        <TrackerTip title={activeState.tipTitle} university={university} activeState={status} tipLocation={targetTip || '#openTipModal'} />
      </div>
    );
  }

  return (
    <div className="tracker-tips-tab">
      {(status === 'archive' || status === 'reject') && infoBanner && (
        <Banner
          title={infoBanner.title}
          text={infoBanner.text}
          buttonLabel={infoBanner.button}
          buttonPlacement="horizontal"
          isButtonOutlined
          handleButtonClick={status === 'archive' ? handleRestoreCompany : undefined}
        />
      )}
      {(quests?.length > 0) && (
        <TrackerQuests
          quests={quests}
          handleQuestDelete={handleQuestDelete}
          changeTab={changeTab}
        />
      )}
      <TrackerContent
        activeState={activeState}
        tasks={tasks || []}
        handleAction={handleActionClick}
      />
      {showResources && (
        <TrackerResources university={university} resources={activeState.resources} />
      )}
      {status === 'offer' && (
        <div className="company-tracker-content__buttons">
          <Button
            mode="primary"
            icon="bi bi-emoji-neutral"
            outlined
            label="Got Rejected"
            size="medium"
            handleClick={handleReceivedRejection}
          />
          <Button
            mode="primary"
            label="🎉 I Accepted My Offer! 🎉"
            size="medium"
            handleClick={openSuccessModal}
          />
        </div>
      )}
    </div>
  );
};
