import { ChipTypes } from '@careeros/coco';
import { CompanyPartnerType, FilterOption } from '@/domains/core/company/types/company';

export type JobTagType = {
  tag_type: 'industry'
  | 'contract'
  | 'salary'
  | 'employees'
  | 'location'
  | 'workMode';
  tag_content: string;
};

export type JobFeedJob = {
  tags: JobTagType[];
  saved: boolean;
  linkedin_logo_url: string;
  applied: boolean;
  id: string;
  description: string;
  title: string;
  company_name: string;
  company_id?: string;
  location: string;
  linkedin_url: string;
  note?: string;
  deadline: string | null;
  rolling_date: boolean;
  posted_on: string | null;
  source: string;
  created_at: string | null
  team_id: string | null;
  company_partner_type: CompanyPartnerType | null;
};

export const JOB_SOURCE = {
  EMPLOYER_APP: 'EmployerApp',
  EMPLOYER_ATS: 'EmployerAts',
} as const;

export type JobsData = JobFeedJob[];

export type JobsLocationsType = {
  city?: string;
  state?: string;
  country: string;
};

export type JobFilterTagTypes = 'query'
| 'sizes'
| 'job_types'
| 'work_modes'
| 'locations'
| 'industries'
| 'date_range'
| 'languages'
| 'favoriteCompanies'
| 'required_languages';

export type JobFilterChipType = {
  type: ChipTypes,
  name: string,
  filterName: JobFilterTagTypes,
  label?: string,
};

export type FilterJobsRequestBody = {
  sizes?: string;
  industries?: string;
  job_types?: string;
  work_modes?: string;
  locations?: string;
  date_range?: string;
  query?: string;
  languages?: string;
  required_languages?: string;
};

export type JobBannerType = { type: 'banner' };
export type JobOrBanner = JobFeedJob | JobBannerType;

export type JobsFilterState = {
  query: string;
  sizes: FilterOption[];
  job_types: FilterOption[];
  work_modes: FilterOption[];
  industries: FilterOption[];
  locations: FilterOption[];
  date_range: FilterOption[];
  languages: FilterOption[];
  favoriteCompanies: boolean;
  byUniversityPartners: FilterOption[];
  byCareerOSPartners: FilterOption[];
  required_languages: FilterOption[];
};

export type Team = {
  id?: string;
  name: string;
  description: string;
  skills: string[];
  working_style: Array<{
    category: string;
    value: string;
  }>;
  languages_spoken: string[];
  application_steps: Array<{
    id?: string;
    step_name: string;
    step_description: string;
    step_order: number;
  }> | null;
  tempSkill?: string;
};
