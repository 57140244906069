import { UniversityResponse } from '@/services/api/student';
import {
  AdminLinks, BetaUsersLinks, CareerAdvisorLinks, StudentLinks,
} from '../navbar-links';
import { LinkType } from '../types/types';

export const getLinksForRole = (roles: string[], university: UniversityResponse) => {
  let visibleLinks: LinkType[] = [];

  if (roles.includes('CareerAdvisor')) {
    const filteringFunction = (link: LinkType) => (!link.includedForUniversities
      || link.includedForUniversities.includes(university.name))
      && (!link.excludedForUniversities || !link.excludedForUniversities?.includes(university.name));
    visibleLinks = CareerAdvisorLinks.filter(filteringFunction);
  } else if (roles.includes('Admin')) {
    visibleLinks = [...AdminLinks, ...CareerAdvisorLinks, ...StudentLinks];
  } else {
    visibleLinks = StudentLinks.filter(
      (link) => !link.excludedForUniversities
      || !link.excludedForUniversities.includes(university.name),
    );
  }

  if (roles.includes('BetaUser')) {
    visibleLinks = [
      ...visibleLinks,
      ...BetaUsersLinks,
    ];
  }

  return visibleLinks.sort((a, b) => a.order - b.order);
};
