import './quest-container.scss';
import { useQueryClient } from 'react-query';
import { useEffect, useState } from 'react';
import { Tooltip } from '@careeros/coco';
import { QuestTile } from '../quest-tile/quest-tile';
import { changeQuestStatus } from '@/services/api/student';
import { QuestsEmpty } from '../quests-empty/quests-empty';
import { useHash } from '@/services/hooks/use-hash';
import { fetchAllReminders } from '@/services/api/quest';
import { Loader } from '@/components/loader/loader';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { QUESTSTATUS } from '../quest-service/quest.service';
import { QuestTileType, QuestType } from '../../../types/quests';
import { useIdentities } from '@/services/queries/user';
import { useGetQuests } from '@/services/queries/quest';
import { UserConnectionsResponse } from '@/domains/core/contact/types';
import { filterAndUpdateQuests, sortQuests } from '@/services/helpers/quest';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';

type Props = {
  userConnections?: UserConnectionsResponse;
};

export const Quests = ({ userConnections }: Props) => {
  const [quests, setQuests] = useState<QuestTileType[]>([]);
  const [questsLoading, setQuestsLoading] = useState(true);
  const { data: questResponses, isLoading: areQuestsLoading, isRefetching: areQuestsReloading } = useGetQuests();
  const { hash } = window.location;
  const { handleHashChange } = useHash({});
  const { trackEvent } = useAnalytics();
  const { data: identities } = useIdentities();
  const queryClient = useQueryClient();
  const { completeActionIfAvailable } = useGamification();

  const processQuests = async (questsToBeFiltered: QuestTileType[]) => {
    const filteredQuests = await filterAndUpdateQuests(questsToBeFiltered, userConnections);

    return filteredQuests;
  };

  const handleDissmissClick = async (questID: string, questType: string) => {
    const response = await changeQuestStatus(questID, QUESTSTATUS.WONTDO, questType);
    if (response) {
      const filteredQuests = quests.filter((quest) => quest.id !== questID);
      setQuests(filteredQuests);
      trackEvent('Quest dismissed', { questType });
      queryClient.invalidateQueries('quests');
    }
  };

  const handleCompleteClick = async (questID: string, questType: string) => {
    const response = await changeQuestStatus(questID, QUESTSTATUS.DONE, questType);
    if (response) {
      const filteredQuests = quests.filter((quest) => quest.id !== questID);
      setQuests(filteredQuests);
      queryClient.invalidateQueries('quests');
      if (questType === QuestType.ScheduleCoffeeChat) {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.COFFEE_CHAT);
      }
      if (questType === QuestType.DownloadExtension) {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.CHROME_EXTENSION_INSTALL);
      }
    }
  };

  const prepareQuests = async () => {
    const responseQuests = questResponses.quests ?? [];
    const filteredQuests = await processQuests(responseQuests);
    const sortedAndFilteredQuests = sortQuests(filteredQuests);

    setQuests(sortedAndFilteredQuests);
    setQuestsLoading(false);
  };

  const checkForResponses = async () => {
    if (!identities || identities.length === 0) {
      return;
    }

    await fetchAllReminders();
  };

  useEffect(() => {
    checkForResponses();
  }, []);

  useEffect(() => {
    if (!areQuestsLoading && questResponses) {
      prepareQuests();
    }
  }, [areQuestsLoading, areQuestsReloading, userConnections]);

  useEffect(() => {
    handleHashChange();
  }, [hash]);

  return (
    <div className="quests">
      <h2 className="quests__title">
        <span>
          Quests
        </span>
        <div className="quests__icon-container">
          <Tooltip
            withArrow
            position="top"
            label="Finish Quests to climb the leaderboard!"
          >
            <div className="quests__icon">
              <i className="bi bi-info-circle" />
              <i className="bi bi-info-circle-fill" />
            </div>
          </Tooltip>
        </div>
      </h2>
      {questsLoading ? (
        <div className="quests__wrapper quests__wrapper--loading">
          <Loader />
        </div>
      ) : (
        <div className="quests__scroll-container">
          <div className={`quests__wrapper ${quests.length > 4 ? 'quests__wrapper--scrollable' : ''}`}>
            {quests.length > 0 ? quests.map((quest: QuestTileType, index) => (
              <QuestTile
                key={quest.id}
                id={quest.id}
                type={quest.type}
                dueDate={quest.due_date}
                company={quest.company}
                contact={quest.contact}
                job={quest.job}
                dismissAction={handleDissmissClick}
                completeAction={handleCompleteClick}
                primary={index === 0}
                createdAt={quest.created_at}
              />
            )) : <QuestsEmpty />}
          </div>
        </div>
      )}
    </div>
  );
};
