/* eslint-disable no-restricted-globals */
import { useQueryClient } from 'react-query';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  IconButton, Tooltip, DefaultLogo,
} from '@careeros/coco';
import {
  CompanyRating,
} from '@/domains/core/company';
import './read-only-kanban-tile.scss';
import { useSelf } from '@/services/queries/user';
import { Tile } from '@/domains/core/company/types';
import { useStudentComments } from '@/services/queries/student';
import { KanbanTileJob } from '../tile-job/tile-job';
import { TileCta } from '../tile-cta/tile-cta';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { useCACommentController } from '@/domains/core/advisor/contexts/CA-comment-input/use-CA-comment-input';
import { addComment } from '@/services/api/advisor';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useStudentContext } from '@/domains/core/advisor/contexts/student-context/student-context-provider';
import { CommentsHistorySidebar, ContactsSidebar } from '../../sidebars';
import { CommentFromCA } from '@/domains/core/advisor/types';
import { CommentInputBox } from '@/domains/core/advisor/components/comment-input-box/comment-input-box';
import { AddToastType } from '../../toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { useTabs } from '@/components/tabs/TabsContext';
import { CompanyTileTrafficLights } from '@/domains/core/company/components/tile-traffic-lights/tile-traffic-lights';

interface KanbanTileProps {
  tile: Tile;
  withTrafficLights?: boolean;
}

/**
 * Primary UI component for the kanban board
 */
export const ReadOnlyCompanyKanbanTile = ({
  tile,
  withTrafficLights = false,
}: KanbanTileProps) => {
  const { openCACommentBox, closeCACommentBox } = useCACommentController();
  // TODO ELINA: remove as any after BE is connected
  const { student } = useStudentContext() || {} as any;
  const [isNameOverflow, setIsNameOverflow] = useState(false);
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const { trackEvent } = useAnalytics();
  const [tileComments, setTileComments] = useState<CommentFromCA[]>([]);
  const nameElementRef = useRef<any>(null);
  const { data: self } = useSelf();
  const { studentID } = useParams() as any;
  const { data: commentsResponse, isLoading } = useStudentComments(studentID);
  const queryClient = useQueryClient();
  const { addToast }: AddToastType = useContext(ToastContext);
  const { setCurrentTab } = useTabs();

  const sendComment = async (comment: string) => {
    try {
      await addComment(comment, tile.id, tile.company_name, tile.logo_url, `${self?.first_name} ${self?.last_name}`, self?.profile_picture_url || '');
      addToast({
        type: 'success',
        message: 'Success',
        additionalMessage: 'Comment was sent successfully to the student.',
      });
      trackEvent('CA Student OB Company-Specific Comment Sent');
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again.',
      });
    }

    // TODO ELINA: revalidate comments query
    closeCACommentBox();
    trackEvent('CA Left comment');
    queryClient.invalidateQueries(['studentComments', student.id]);
  };

  const openAddCommentModal = () => {
    closeSidebar();
    openCACommentBox(
      <CommentInputBox
        handleSendComment={sendComment}
        companyName={tile.company_name}
        companyLogo={tile.logo_url}
        title={tile.company_name}
        studentFirstName={student?.first_name || '[Student First Name]'}
        studentLastName={student?.last_name || '[Student Last Name]'}
        closeCommentBox={closeCACommentBox}
        showAITemplates
        withCloseButton
      />,
    );
    trackEvent('CA Student OB “Write a (company-specific) Comment” on tile Clicked');
  };

  const handleCommentsIconClick = () => {
    openSidebar(
      <CommentsHistorySidebar
        studentID={studentID}
        applicationID={tile.id}
        title="Company Comments"
        subtitle={`All Comments made for ${tile.company_name}`}
        onWriteCommentClick={openAddCommentModal}
        isResolveDisabled
      />,
    );
    trackEvent('CA Student OB “See Comments” Clicked');
  };

  const handleSeeAllContacts = () => {
    closeSidebar();
    setCurrentTab('Contacts');
  };

  const handleContactsIconClicked = () => {
    openSidebar(
      <ContactsSidebar
        companyName={tile.company_name}
        companyID={tile.company_id}
        closeSidebar={closeSidebar}
        userID={studentID}
        handleSeeAllContacts={handleSeeAllContacts}
      />,
    );

    trackEvent('CA Student OB “See Contacts Saved” Clicked');
  };

  const getTileComments = () => {
    const tileCommentsFiltered = commentsResponse?.filter((comment: CommentFromCA) => comment.application?.id === tile.id) || [];
    setTileComments(tileCommentsFiltered);
  };

  useEffect(() => {
    if (nameElementRef.current) {
      setIsNameOverflow(nameElementRef.current.scrollWidth > nameElementRef.current.clientWidth);
    }
  }, [self?.id]);

  useEffect(() => {
    if (isLoading) return;
    getTileComments();
  }, [commentsResponse, isLoading]);

  return (
    <div
      className="readonly-kanban-tile"
    >
      {withTrafficLights && (
        <div className="readonly-kanban-tile__traffic-lights">
          <CompanyTileTrafficLights currentStatus={tile.hiring_score} isReadOnly />
        </div>
      )}
      <div className="readonly-kanban-tile__top">
        <div className="readonly-kanban-tile__header">
          <div className="readonly-kanban-tile__avatar">
            <DefaultLogo
              source={tile.logo_url}
              type="company"
              name={tile.company_name}
              className="readonly-kanban-tile__avatar-image"
              size={24}
            />
          </div>
          <Tooltip label={tile.company_name} position="top" disableHoverListener={!isNameOverflow}>
            <h3 ref={nameElementRef} className="readonly-kanban-tile__name">
              {tile.company_name}
            </h3>
          </Tooltip>
        </div>
        <div className="readonly-kanban-tile__rating">
          <CompanyRating readOnly currentRate={tile.rating} />
        </div>
        {tile.jobs?.length > 0 && (
          <div className="readonly-kanban-tile__jobs">
            {tile.jobs.map((job) => (
              <KanbanTileJob id={job.id} key={job.title} title={job.title} url={job.linkedin_url} companyId={tile.company_id} readOnly />
            ))}
          </div>
        )}
      </div>
      <div className="readonly-kanban-tile__separator" />
      <div className="readonly-kanban-tile__bottom">
        {/* TODO: uncomment when we get this data from API */}
        {/* <AvatarsGroup
          personList={[]}
        /> */}
        <TileCta
          label={`${tile.count_of_contacts} Contact(s) Saved`}
          icon="bi bi-people"
          handleClick={handleContactsIconClicked}
          counter={tile.count_of_contacts}
        />
        <TileCta
          label="View Comments"
          icon="bi bi-chat-square-text"
          handleClick={handleCommentsIconClick}
          counter={tileComments.length}
        />
        <div className="readonly-kanban-tile__button readonly-kanban-tile__button--xs">
          <Tooltip label="Write a comment for this company" position="top" withArrow>
            <IconButton
              icon="bi bi-chat-right-dots"
              onClick={openAddCommentModal}
              label="Add Comment"
              mode="rounded"
              size="small"
              outlined
            />
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
