import { Tooltip } from '@careeros/coco';

type TopbarMenuProps = {
  isCareerAdvisorPage: boolean;
  isProfileMenuOpen: boolean;
  email: string;
  handleNavigate: (path: string) => void;
  handleHelpButtonClick: () => void;
  handleLogoutClick: () => void;
};

export const TopbarMenu = ({
  isCareerAdvisorPage, isProfileMenuOpen, email, handleNavigate, handleHelpButtonClick, handleLogoutClick,
}: TopbarMenuProps) => (
  <div className={`topbar__profile-menu ${isProfileMenuOpen ? 'is-open' : ''}`}>
    <div className="topbar__profile-menu-item topbar__profile-menu-item--email">
      <span className="email-text">
        <Tooltip label={email || ''} position="top">
          <span>{email}</span>
        </Tooltip>
      </span>
    </div>
    <div
      className="topbar__profile-menu-item"
      onClick={() => handleNavigate('/app/profile')}
    >
      <i className="bi bi-gear" />
      <span>Settings</span>
    </div>
    {!isCareerAdvisorPage && (
    <div
      className="topbar__profile-menu-item"
      onClick={() => handleNavigate('/app/onboarding?show_navigation=true')}
    >
      <i className="bi bi-pencil-square" />
      <span>Personalize Experience</span>
    </div>
    )}
    {!isCareerAdvisorPage && (
    <div
      className="topbar__profile-menu-item"
      onClick={() => {
        if (handleHelpButtonClick) handleHelpButtonClick();
      }}
    >
      <i className="bi bi-question-circle" />
      <span>Help</span>
    </div>
    )}
    {!isCareerAdvisorPage && (
    <div
      className="topbar__profile-menu-item"
      onClick={() => handleNavigate('/app/documents')}
    >
      <i className="bi bi-filetype-doc" />
      <span>My Documents</span>
    </div>
    )}
    <div
      className="topbar__profile-menu-item topbar__profile-menu-item--logout"
      onClick={() => {
        if (handleLogoutClick) handleLogoutClick();
      }}
    >
      <i className="bi bi-box-arrow-right" />
      <span>Log Out</span>
    </div>
  </div>
);
