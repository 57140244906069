import { useQueryClient } from 'react-query';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { useCACommentController } from '@/domains/core/advisor/contexts/CA-comment-input/use-CA-comment-input';
import { FilterNameType, FilterOption, Tile } from '@/domains/core/company/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { CommentInputBox } from '../comment-input-box/comment-input-box';
import { addComment } from '@/services/api/advisor';
import {
  filterTiles, filterTilesByStatus, getAllSavedIndustries, sortByPosition,
  universitiesWithPostingScore,
} from '@/services/helpers/company';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { ReadonlyOverviewContent } from '@/domains/generic/application/overview-content/readonly-overview-content';

const companies: string[] = [
  'Company Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Rejected',
];

type Props = {
  studentFirstName?: string,
  studentLastName?: string,
  tiles: Tile[];
  studentID: string;
};

export const StudentOBTab = ({
  studentFirstName,
  studentLastName,
  studentID,
  tiles,
}: Props) => {
  const { openCACommentBox, closeCACommentBox } = useCACommentController();
  const [kanbanLayout, setKanbanLayout] = useState({});
  const { trackEvent } = useAnalytics();
  const [filteredTiles, setFilteredTiles] = useState<Tile[]>(tiles);
  const [filteredTilesByStatus, setFilteredTilesByStatus] = useState<Tile[]>([]);
  const savedIndustries = useMemo(() => getAllSavedIndustries(tiles), [tiles]);
  const { data: self } = useSelf();
  const { data: university } = useSelfUniversity();
  const hasPostingScore = useMemo(() => university && universitiesWithPostingScore.includes(university.name), [university]);
  const queryClient = useQueryClient();
  const { addToast }: AddToastType = useContext(ToastContext);

  const sendComment = async (tileID: string, comment: string, companyName: string, companyLogo: string) => {
    const caName = `${self?.first_name} ${self?.last_name}`;
    const caPicture = self?.profile_picture_url || '';

    try {
      await addComment(comment, tileID, companyName, companyLogo, caName, caPicture);
      addToast({
        type: 'success',
        message: 'Success',
        additionalMessage: 'Comment was sent successfully to the student.',
      });
    } catch (error) {
      Sentry.captureException(error);
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again.',
      });
    }

    closeCACommentBox();
    trackEvent('CA Left comment');
    queryClient.invalidateQueries(['studentComments', studentID]);
  };

  const openAddCommentModal = (tile: Tile) => {
    openCACommentBox(
      <CommentInputBox
        handleSendComment={(comment) => sendComment(tile.id, comment, tile.company_name, tile.logo_url)}
        companyName={tile.company_name}
        companyLogo={tile.logo_url}
        title={tile.company_name}
        studentFirstName={studentFirstName || '[Student First Name]'}
        studentLastName={studentLastName || '[Student Last Name]'}
        closeCommentBox={closeCACommentBox}
        showAITemplates
        withCloseButton
      />,
    );
  };

  const computeKanbanLayout = (allCompanies: Tile[]) => {
    const kanban = {
      'Company Saved': sortByPosition(allCompanies.filter((company: Tile) => company.status === 'saved')),
      Networking: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'networking')),
      Applying: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applying')),
      Applied: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'applied')),
      Interviewing: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'interviewing')),
      Offer: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'offer')),
      Rejected: sortByPosition(allCompanies.filter((company: Tile) => company.status === 'reject')),
    };
    setKanbanLayout(kanban);
  };

  const handleFiltering = (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => {
    const filteredResult = filterTiles(tiles, filters, searchQuery);
    const filteredResultByStatus = filterTilesByStatus(filteredResult, Object.values(filters.status));

    setFilteredTilesByStatus(filteredResultByStatus);
    setFilteredTiles(filteredResult);
  };

  useEffect(() => {
    computeKanbanLayout(filteredTiles);
  }, [filteredTiles.length]);

  return (
    <ReadonlyOverviewContent
      columns={companies}
      layout={kanbanLayout}
      handleFiltersChange={handleFiltering}
      savedIndustries={savedIndustries}
      tiles={filteredTilesByStatus}
      handleAddComment={openAddCommentModal}
      hasPostingScore={hasPostingScore}
    />
  );
};
