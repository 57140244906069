import { Tooltip, Badge, BadgeColor } from '@careeros/coco';
import { ConnectionStatus } from '../../types/contact';
import './status-tag.scss';

type StatusTagSize = 'small' | 'medium';

type Props = {
  status: ConnectionStatus;
  type: 'networking' | 'linkedin' | 'careerAdvisor';
  size?: StatusTagSize;
  withTooltip?: boolean;
};

type StatusesType = {
  [key in 'networking' | 'linkedin' | 'careerAdvisor']: {
    [K in ConnectionStatus]: {
      color: BadgeColor;
      text: {
        [size in StatusTagSize]: string;
      }
      tooltip?: string;
    }
  };
};

const statuses: StatusesType = {
  linkedin: {
    pending: {
      color: 'yellow',
      text: {
        medium: '⏳ Connection Pending',
        small: '⏳',
      },
      tooltip: 'LinkedIn connection request pending ',
    },
    connected: {
      color: 'light-green',
      text: {
        medium: '✅ Connected',
        small: '✅',
      },
      tooltip: 'Connected on LinkedIn',
    },
    notConnected: {
      color: 'light-red',
      text: {
        medium: '📌 Not Connected',
        small: '📌',
      },
      tooltip: 'Not connected on LinkedIn',
    },
  },
  networking: {
    pending: {
      color: 'yellow',
      text: {
        medium: 'Response Pending',
        small: '⏳',
      },
      tooltip: 'Waiting for contact to reply',
    },
    connected: {
      color: 'light-green',
      text: {
        medium: 'Networking',
        small: '✅',
      },
      tooltip: 'Ongoing conversation with contact',
    },
    notConnected: {
      color: 'light-red',
      text: {
        medium: 'No Outreach Yet',
        small: '📌',
      },
      tooltip: 'No message sent. Reach out now!',
    },
  },
  careerAdvisor: {
    pending: {
      color: 'yellow',
      text: {
        medium: 'Response Pending',
        small: '⏳',
      },
    },
    connected: {
      color: 'light-green',
      text: {
        medium: 'Response Received',
        small: '✅',
      },
    },
    notConnected: {
      color: 'light-red',
      text: {
        medium: 'No Outreach Yet',
        small: '✖️',
      },
    },
  },
};

export const StatusTag = ({
  status,
  type,
  size = 'medium',
  withTooltip = false,
}: Props) => {
  if (!statuses[type][status]) {
    return null;
  }

  return (
    <div className="contact-status-tag">
      <Tooltip position="top" withArrow label={statuses[type][status]?.tooltip || ''} disableHoverListener={!withTooltip || !statuses[type][status]?.tooltip}>
        <Badge color={statuses[type][status].color} label={statuses[type][status].text[size]} />
      </Tooltip>
    </div>
  );
};
