import React from 'react';
import { Counter } from '@careeros/coco';
import './kanban-board.scss';
import { Tile } from '@/domains/core/company/types';
import { ReadOnlyCompanyKanbanTile } from '../read-only-kanban-tile/read-only-kanban-tile';

interface KanbanBoardProps {
  columns: string[];
  layout: any;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  withPostingScore?: boolean;
}

export const ReadonlyKanbanBoard = ({
  columns,
  layout,
  scrollContainerRef,
  onScroll,
  withPostingScore,
}: KanbanBoardProps) => (
  <div className="kanban-board">
    <div className="kanban-board__content" ref={scrollContainerRef} onScroll={onScroll} id="kanban-board">
      {columns.map((column, index) => (
        <div
          key={column}
          className={`kanban-board__column kanban-board__column--readonly kanban-board__column--nr-${index}`}
        >
          <div className="kanban-board__column__title">
            <span className="kanban-board__column__title__text">{column}</span>
            <span className="kanban-board__title-number">
              <Counter counter={layout[column] && layout[column].length} size="medium" mode="default" />
            </span>
          </div>

          {layout[column] && layout[column].map((tile: Tile) => (

            <div className="kanban-board__tile">
              <ReadOnlyCompanyKanbanTile
                key={tile.id}
                tile={tile}
                withTrafficLights={withPostingScore}
              />
            </div>
          ))}

        </div>
      ))}
    </div>
  </div>
);
