import { useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import SearchImage from '@/assets/images/man-shrugging.png';

import './serach-empty-result.scss';
import { LoginStatus } from '@/services/hooks/use-check-extension';

type Props = {
  title: string;
  subtitle: string;
  checkExtension: () => Promise<boolean | LoginStatus>;
  linkedInURLSearchParams?: string;
  exploreMoreRedirectLink?: string;
};

export const EmptySearchResult = ({
  checkExtension,
  title,
  subtitle,
  linkedInURLSearchParams = '',
  exploreMoreRedirectLink = '/app/companies/explore',
}: Props) => {
  const navigate = useNavigate();
  const handleSaveFromLinkedInClick = async () => {
    const isExtensionInstalled = await checkExtension();

    if (!isExtensionInstalled) {
      return;
    }

    window.open(`https://www.linkedin.com/search/results/companies/${linkedInURLSearchParams}`, '_blank');
  };

  return (
    <div className="search-empty-result">
      <img className="search-empty-result__image" src={SearchImage} alt="" />
      <h3 className="search-empty-result__title">
        {title}
      </h3>
      <p className="search-empty-result__subtitle">
        {subtitle}
      </p>
      <div className="search-empty-result__buttons">
        <Button
          handleClick={handleSaveFromLinkedInClick}
          mode="primary"
          outlined
          size="medium"
          label="Save Company from LinkedIn"
          icon="bi bi-plus-circle"
        />
        <span>or</span>
        <Button
          handleClick={() => navigate(exploreMoreRedirectLink)}
          mode="primary"
          size="medium"
          label="Explore other Companies"
          icon="bi bi-box-arrow-up-right"
          iconPosition="right"
        />
      </div>
    </div>
  );
};
