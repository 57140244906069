import '../tracker-tip.scss';
import { Button, InfoBoxWithChildren } from '@careeros/coco';
import { TipModalProps } from '../types';
import { useTabs } from '@/components/tabs/TabsContext';

const CompanySavedTipModal = ({ openTip }: TipModalProps) => {
  const { setCurrentTab } = useTabs();

  return (
    <div className="company-saved-info-modal">
      <div className="company-saved-info-modal">
        {openTip.tab === 'LinkedIn Profile' ? (
          <div data-tab="LinkedIn Profile">
            <div className="tracker-tip__title">LinkedIn Profile</div>
            <InfoBoxWithChildren>
              <>
                <p>Make sure that you fill out all sections of your LinkedIn profile. Explain each stage of your career journey briefly using bullets and highlight specific key results that you achieved. This is extremely important! Use strong action verbs and show specific outcomes:</p>
                <br />
                <p>
                  <strong>👍 Better example:</strong>
                  <br />
                  “Led organizational restructuring by creating more efficient operational processes and increasing clarity of goals across the division; reduced operating cost by €15m annually”
                </p>
                <br />
                <p>
                  <strong>👎 Bad example:</strong>
                  <br />
                  “Responsible for organizational restructuring to improve cost structure of the division”
                </p>
              </>
            </InfoBoxWithChildren>
            <InfoBoxWithChildren>
              <>
                <p>
                  Some of the
                  {' '}
                  <strong>most common strong action verbs</strong>
                  {' '}
                  are:
                </p>
                <ul>
                  <li>Developed</li>
                  <li>Led</li>
                  <li>Managed</li>
                  <li>Analyzed</li>
                  <li>Created</li>
                  <li>Collaborated</li>
                  <li>Conducted</li>
                  <li>Performed</li>
                  <li>Negotiated</li>
                  <li>Established</li>
                  <li>Built</li>
                </ul>
              </>
            </InfoBoxWithChildren>
            <InfoBoxWithChildren>
              Make sure you have a professional headshot, a captivating headline and a short, interesting bio that highlights your key skills and passions. Add skills, languages and certificates to your profile.
            </InfoBoxWithChildren>
          </div>
        ) : (
          <div data-tab="Ideal Contacts">
            <div className="tracker-tip__title">
              <span>Find Good Networking Contacts</span>
              <Button
                mode="primary"
                size="medium"
                icon="bi bi-plus-circle"
                label="Save a Contact"
                handleClick={() => setCurrentTab('Contacts')}
              />
            </div>
            <InfoBoxWithChildren>
              Look for alumni from your university and contacts in the functional role that you are targeting
            </InfoBoxWithChildren>
            <InfoBoxWithChildren>
              Ideally, a contact is 1-2 levels above where you would start in the company
            </InfoBoxWithChildren>
            <InfoBoxWithChildren>
              A contact is more valuable if they have already been promoted within the company or worked there for at least 1-2 years
            </InfoBoxWithChildren>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanySavedTipModal;
