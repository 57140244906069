import Lottie from 'react-lottie';
import LoaderGif from '@/assets/images/contacts-loading.json';

import './linkedin-suggested-contacts.scss';
import { SuggestedContactsError } from '../linkedin-suggested-contacts-error/linkedin-suggested-contacts-error';
import { SuggestedPeopleItem } from '@/domains/core/contact/types';
import { LinkedInSuggestedContactTileWithPreview } from '@/domains/core/contact/components/contact-tile-with-preview/li-suggested-contact-tile-with-preview';

type SuggestedContactsProps = {
  companyLogoUrl: string;
  companyName: string;
  isLoading: boolean;
  isError: boolean;
  filteredSuggestions: SuggestedPeopleItem[];
  extensionIsInstalled: boolean;
  isLoggedIntoLinkedIn: boolean;
  handleSaveSuggestedContact: (contactUrl: string) => void;
  isLoadingMinified?: boolean;
};

export const LinkedinSuggestedContacts = ({
  companyName,
  companyLogoUrl,
  isLoading,
  isError,
  filteredSuggestions,
  extensionIsInstalled,
  isLoggedIntoLinkedIn,
  handleSaveSuggestedContact,
  isLoadingMinified = false,
}: SuggestedContactsProps) => (
  <>
    {(!isLoading && !isError) && (
      !!filteredSuggestions && filteredSuggestions.map((contact: any) => (
        <LinkedInSuggestedContactTileWithPreview
          key={contact.link}
          contact={contact}
          companyName={companyName}
          companyLogoUrl={companyLogoUrl}
          handleSaveSuggestedContact={handleSaveSuggestedContact}
        />
      ))
    )}
    {!filteredSuggestions.length && !isLoading && !isError && (
      <p className="suggested-contacts__error">
        We couldn&apos;t find any suggested contacts for this company. Try again later!
      </p>
    )}
    {isLoading && (
      <div className="suggested-contacts__loader">
        {!isLoadingMinified && (
          <>
            <div className="suggested-contacts__loader-animation">
              <Lottie
                options={{
                  loop: true,
                  autoplay: true,
                  animationData: LoaderGif,
                }}
                height={100}
                width={100}
                style={{
                  background: 'transparent',
                }}
              />
            </div>
            <div className="suggested-contacts__typing-animation" />
          </>
        )}
        {isLoadingMinified && (
          <>
            <div className="suggested-contacts__loader-dots" />
            <div className="suggested-contacts__loader-title">
              loading more contacts
            </div>
          </>
        )}
        <p className={`suggested-contacts__loader-text ${isLoadingMinified ? 'suggested-contacts__loader-text--subtitle' : ''}`}>
          We’re looking for things in common such as same university, past employer, or your target department.
        </p>
      </div>
    )}
    {isError && (
      <SuggestedContactsError exensionIsInstalled={extensionIsInstalled} isLoggedIntoLinkedIn={isLoggedIntoLinkedIn} />
    )}
  </>
);
