import { Timestamp } from 'firebase/firestore';
import { SentEmailLogBackendResponseType, SentEmailLogType } from '../types/sent-email-log';

const replaceSpaces = (str: string) => str.replace(/\s+/g, '\u00A0');

export const transformBackendData = (backendData: SentEmailLogBackendResponseType[]): SentEmailLogType[] => backendData.map((data) => ({
  fromContactID: data.advisor_id || '',
  fromUserID: data.id || '',
  conversationID: data.id || '',
  ID: data.id || '',
  text: data.body || '',
  sentDateTime: new Timestamp(
    Math.floor(new Date(data.created_at).getTime() / 1000), // seconds
    (new Date(data.created_at).getTime() % 1000) * 1000000, // nanoseconds
  ),
  from: data.sent_by_advisor || '',
  subject: data.subject || '',
  to: `Students\u00A0in\u00A0${
    data.column
  }\u00A0Status\u00A0in\u00A0${replaceSpaces(data.cohort_name)}`,
}));
