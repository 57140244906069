import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
import { useQueryClient } from 'react-query';
import { Loader } from '@/components/loader/loader';
import { TrackerContextType, TrackerContext } from './tracker-context';
import './tracker-overlay.scss';
import SingleCompanyTrackerModal from '../tracker-modal/tracker-modal';
import { useGetSingleCompany } from '@/services/queries/company';

const CLOSING_ANIMATION_DURATION = 300;

// TODO: find a better way to solve problem with root
const trackerRoot = document.getElementById('tracker-root') as HTMLElement || document.getElementById('storybook-root') as HTMLElement;
const TrackerLoaderWrapper = () => {
  const {
    isTrackerModal, closeTracker, companyID, trackerTab, jobID,
  } = useContext(TrackerContext) as TrackerContextType;
  const { data: company, isError, isLoading } = useGetSingleCompany(companyID, {
    enabled: !!companyID,
  });
  const [status, setStatus] = useState<string>('');
  const [closing, setClosing] = useState<boolean>(false);
  const boxRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();

  const handleCloseClick = () => {
    setClosing(true);
    setTimeout(() => {
      closeTracker();
      setClosing(false);
    }, CLOSING_ANIMATION_DURATION);
  };

  const revalidateCompany = () => {
    queryClient.invalidateQueries(['company', companyID]);
  };

  const handleBlur = (e: any) => {
    const { target } = e;

    if (boxRef.current === target) {
      handleCloseClick();
    }
  };

  useEffect(() => {
    if (companyID && isTrackerModal) {
      revalidateCompany();
    }
  }, [companyID, isTrackerModal]);

  useEffect(() => {
    setStatus(company?.application_status || 'archived');
  }, [company?.application_status]);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  if (isTrackerModal) {
    return createPortal(
      <div
        className="tracker-overlay"
        ref={boxRef}
        tabIndex={-1}
      >
        {isLoading && (
          <div className="tracker-overlay__loader">
            <Loader />
          </div>
        )}
        <div onClick={(e) => e.stopPropagation()} className="tracker-overlay__content">
          {(isError && !isLoading) && (
          <div className="tracker-overlay__error">
            <h3>Sorry, something went wrong</h3>
            <p>We couldn&apos;t load the company tracker</p>
          </div>
          )}
          {!isLoading && !isError && company && companyID && (
            <>
              <div className={`tracker-overlay__box ${closing ? 'tracker-overlay__box--closing' : ''}`}>
                <div className="tracker-overlay__close-button-container">
                  <div
                    className="tracker-overlay__close-button"
                    onClick={handleCloseClick}
                  >
                    <i className="tracker-overlay__close-icon bi bi-x-circle" />
                    <i className="tracker-overlay__close-icon tracker-overlay__close-icon--hover bi bi-x-circle-fill" />
                  </div>
                </div>
                <div className="tracker-overlay__box-content">
                  <SingleCompanyTrackerModal
                    company={company}
                    status={status}
                    companyID={companyID}
                    openTab={trackerTab}
                    revalidate={revalidateCompany}
                    jobID={jobID}
                  />
                </div>
              </div>
              <div onClick={handleCloseClick} className="tracker-overlay__box tracker-overlay__box--transparent" />
            </>
          )}
        </div>
      </div>,
      trackerRoot,
    );
  } return null;
};

export default TrackerLoaderWrapper;
