import { ScoringParamsType } from '../types/resume-templates-types';

const defaultParams = {
  m: 1,
  b: 1,
  a: 1,
};

export const ScoringParams: ScoringParamsType = {
  'ESMT Standard': defaultParams,
  'Modern Simple': defaultParams,
  'Hult Masters': defaultParams,
  'Hult MBAN': defaultParams,
  'Hult MBA': defaultParams,
  'Hult BBA': defaultParams,
  'Hult EMBA': defaultParams,
  Rice: defaultParams,
  'Modern Minimalist': defaultParams,
  Georgia: defaultParams,
  'Compliant with AI-powered ATS': defaultParams,
  Oxford: defaultParams,
  ASB: defaultParams,
  Bayes: defaultParams,
  'Maryland MBA': defaultParams,
  'Maryland MBA (2 pager)': defaultParams,
  'Maryland MS': defaultParams,
  'Maryland UG': defaultParams,
  'HHL 2 Page - Education first': defaultParams,
  'HHL 2 Page - Work first': defaultParams,
  'HHL 1 Page': defaultParams,
  'MBS MBA': defaultParams,
  'MBS Masters': defaultParams,
  EBS: defaultParams,
  'EBS (2 pager)': defaultParams,
};
