import { useContext, useEffect, useState } from 'react';
import { FilterNameType, FilterOption, Tile } from '@/domains/core/company/types';
import { OverviewHeader } from '../overview-header/overview-header';
import './overview-content.scss';
import { useScroll } from '@/services/hooks/use-scroll';
import { ReadonlyKanbanBoard } from '../../kanban/board/advisor-kanban-board';
import { ReadonlyApplicationsTable } from '../applications-table/readonly-applications-table';
import { PostingScoreExplainerModal } from '@/domains/core/company/components/posting-score-modal/posting-score-modal';
import { ModalContext } from '@/components/modal/modal-provider';

type Props = {
  tiles: Tile[];
  columns: string[];
  layout: any;
  handleFiltersChange: (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => void;
  savedIndustries: string[];
  openFullCommentHistory?: () => void;
  handleAddComment?: (tile: Tile) => void;
  hasPostingScore?: boolean;
};

export const ReadonlyOverviewContent = ({
  tiles,
  columns,
  layout,
  handleFiltersChange,
  savedIndustries,
  openFullCommentHistory,
  handleAddComment,
  hasPostingScore = false,
}: Props) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(false);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const [selectedView, setSelectedView] = useState(0);

  const updateScrollState = () => {
    if (!kanbanContainer.current) {
      return;
    }

    setShowLeftScrollArrow(kanbanContainer.current.scrollLeft > 0);
    setShowRightScrollArrow(kanbanContainer.current.scrollLeft < (kanbanContainer.current.scrollWidth - kanbanContainer.current.clientWidth));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    if (!kanbanContainer.current) {
      return;
    }

    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }

    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const handleViewChange = (value: number) => {
    setSelectedView(value);

    const currentSearch = new URLSearchParams(window.location.search);
    currentSearch.delete('view');
    currentSearch.append('view', value.toString());

    window.history.replaceState(null, '', `?${currentSearch.toString()}`);
  };

  const openPostingScoreModal = () => {
    openModal(
      <PostingScoreExplainerModal closeModal={closeModal} />,
    );
  };

  useEffect(() => {
    updateScrollState();
  }, [kanbanContainer.current]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const view = searchParams.get('view');

    if (view) {
      setSelectedView(Number(view));
    }
  }, []);

  return (
    <div className={`overview-content ${selectedView === 1 ? 'overview-content--fit-screen-height' : ''}`}>
      <div className="overview-content__header overview-content__header--spacing-small">
        <OverviewHeader
          handleFiltersChange={handleFiltersChange}
          savedIndustries={savedIndustries}
          openFullCommentHistory={openFullCommentHistory}
          showRightScrollArrow={showRightScrollArrow}
          showLeftScrollArrow={showLeftScrollArrow}
          handleContentScroll={handleVerticalScroll}
          selectedView={selectedView}
          changeSelectedView={handleViewChange}
          withPostingScoreFilter={hasPostingScore}
          openPostingScoreModal={openPostingScoreModal}
        />
      </div>

      <div
        className={`overview-content__kanban ${selectedView === 0 ? 'overview-content__kanban--active' : ''}`}
      >
        <ReadonlyKanbanBoard
          columns={columns}
          layout={layout}
          scrollContainerRef={kanbanContainer}
          onScroll={handleScroll}
          withPostingScore={hasPostingScore}
        />
      </div>
      <div className={`overview-content__table overview-content__table--readonly ${selectedView === 1 ? 'overview-content__table--active' : ''}`}>
        <ReadonlyApplicationsTable
          tiles={tiles}
          handleAddComment={handleAddComment}
        />
      </div>
    </div>
  );
};
