import { Button } from '@careeros/coco';
import './job-feed-blocker-banner.scss';
import ShushEmoji from '@/assets/images/emoji-shushing-face.png';

type Props = {
  handleClick?: () => void;
};

export const JobFeedBlockerBanner = ({ handleClick }: Props) => (
  <div className="job-feed-blocker-banner">
    <img src={ShushEmoji} alt="Shushing face" className="job-feed-blocker-banner__image" />
    <div className="job-feed-blocker-banner__content">
      <h2 className="job-feed-blocker-banner__title">
        Almost there!
      </h2>
      <p className="job-feed-blocker-banner__description">
        To see jobs, please share some more info about your preferences first.
      </p>
    </div>
    {handleClick && (
      <Button
        mode="primary"
        size="medium"
        icon="bi bi-arrow-right"
        label="Fill Missing Info"
        iconPosition="right"
        handleClick={handleClick}
      />
    )}
  </div>
);
