/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo } from 'react';
import { GraphWidget } from '@/domains/core/advisor';

import './alumni-analytics.scss';
import { Alum } from '@/types/alum';
import {
  determineHiringSuccess, extractEducation, extractWorkExperience, parseCareerSummary,
} from '../../helpers/helpers';

type Props = {
  alumni: Alum[];
};

export const locationOptions = {
  plugins: {
    legend: {
      position: 'bottom',
    },
    datalabels: {
      color: '#fff',
      font: {
        size: 10,
      },
      formatter(value: any) {
        return `${value}%`;
      },
    },
  },
  animation: false,
};
export const hiringSuccessOptions = {
  plugins: {
    legend: {
      position: 'bottom',
    },
    datalabels: {
      color: '#fff',
      font: {
        size: 20,
      },
      formatter(value: any) {
        return `${value}%`;
      },
    },
  },
  animation: false,
};

export const companyOptions = {
  responsive: true,
  animation: false,
  plugins: {
    legend: {
      display: false,
    },
  },
};

export const AlumniAnalytics = ({
  alumni,
}: Props) => {
  const totalAlumni = useMemo(() => alumni.length, [alumni]);
  const studentsWithHiringSuccess = useMemo(
    () => alumni.reduce((acc, alum) => (determineHiringSuccess(alum) ? acc + 1 : acc), 0),
    [alumni],
  );
  const hiringSuccessPercentage = useMemo(
    () => (totalAlumni > 0 ? (studentsWithHiringSuccess * 100) / totalAlumni : 0),
    [totalAlumni, studentsWithHiringSuccess],
  );

  const hiringSuccessData = {
    labels: ['Hire Success', 'Hire non-success'],
    datasets: [
      {
        label: '% of Students Networking',
        data: [
          Number(hiringSuccessPercentage.toFixed(2)),
          Number((100 - Number(hiringSuccessPercentage.toFixed(2))).toFixed(2)),
        ],
        backgroundColor: [
          '#98A6FD',
          '#484F7C',

        ],
      },
    ],
  };

  const locationData = useMemo(() => {
    const totalAlumni = alumni.length;
    if (totalAlumni === 0) {
      return { labels: [], datasets: [{ label: '% of Students Located in this Country', data: [], backgroundColor: [] }] };
    }

    const locationMap = new Map<string, number>();

    alumni.forEach((alum) => {
      const summary = parseCareerSummary(alum.career_summary?.profile);
      const address = summary?.basics?.location?.address;
      if (address && typeof address === 'string' && address.trim() !== '') {
        locationMap.set(address, (locationMap.get(address) || 0) + 1);
      }
    });

    const sortedLocations = Array.from(locationMap.entries()).sort((a, b) => b[1] - a[1]);
    const labels = sortedLocations.map(([location]) => location);
    const data = sortedLocations.map(([, count]) => Number(((count / totalAlumni) * 100).toFixed(2)));

    // Use a default set of colors, repeat if there are more locations than colors.
    const defaultColors = ['#98A6FD', '#484F7C', '#FF8A65', '#4DB6AC', '#BA68C8', '#FFD54F'];
    const backgroundColor = labels.map((_, index) => defaultColors[index % defaultColors.length]);

    return {
      labels,
      datasets: [
        {
          label: '% of Students Located in this Country',
          data,
          backgroundColor,
        },
      ],
    };
  }, [alumni]);

  const companiesData = useMemo(() => {
    const companiesMap = new Map<string, number>();

    alumni.forEach((alum) => {
      // Only process alumni who have hiring success
      if (!determineHiringSuccess(alum)) return;

      // Find the education record for the current university
      const education = extractEducation(alum.career_summary?.profile);
      if (!education) return;
      const universityEdu = education.find((edu) => edu.institution.toLowerCase().includes(alum.university.name.toLowerCase()));
      if (!universityEdu?.endDate) return;
      const graduationDate = new Date(universityEdu.endDate);

      // Get the work experiences for this alum
      const workExperiences = extractWorkExperience(alum.career_summary?.profile);
      if (!Array.isArray(workExperiences)) return;

      // For each alum, use a set to count each company only once
      const companiesForAlum = new Set<string>();

      workExperiences.forEach((job) => {
        if (!job.startDate) return;
        const jobStartDate = new Date(job.startDate);
        // Calculate six months later using 6 * 30 days (approx.)
        const sixMonthsLater = new Date(graduationDate.getTime() + 6 * 30 * 24 * 60 * 60 * 1000);

        // Check if the job start date is within the hiring window
        if (jobStartDate >= graduationDate && jobStartDate <= sixMonthsLater) {
          if (job.name && job.name.trim() !== '') {
            companiesForAlum.add(job.name);
          }
        }
      });

      // Increment the company count for companies found for this alum
      companiesForAlum.forEach((company) => {
        companiesMap.set(company, (companiesMap.get(company) || 0) + 1);
      });
    });

    // Convert the map to an array, sort descending by count (alphabetically if equal), and take top 10
    const companiesArray = Array.from(companiesMap.entries());
    companiesArray.sort((a, b) => b[1] - a[1] || a[0].localeCompare(b[0]));
    const topCompanies = companiesArray.slice(0, 10);

    return {
      labels: topCompanies.map(([company]) => company),
      datasets: [{ data: topCompanies.map(([_, count]) => count) }],
    };
  }, [alumni]);

  return (
    <div className="career-os-grid">
      <div className="width-7/24">
        {hiringSuccessData && (
          <GraphWidget
            title="Hiring Success Overview"
            subtitle="Hiring Success Criteria: Student found a job withing 3 months of graduation."
            chartType="doughnut"
            chartClicked={() => { }}
            options={hiringSuccessOptions}
            data={hiringSuccessData}
          />
        )}
      </div>
      <div className="width-10/24">
        {companiesData && (
          <GraphWidget
            title="Top 10 Companies that hired Alumni in the first 3 months of graduation"
            subtitle="All this data is based after graduation"
            chartType="bar"
            chartClicked={() => { }}
            options={companyOptions}
            data={companiesData}
          />
        )}
      </div>
      <div className="width-7/24">
        {locationData && (
          <GraphWidget
            title="Location Distribution"
            subtitle="Current location of the alumni"
            chartType="doughnut"
            chartClicked={() => { }}
            options={locationOptions}
            data={locationData}
          />
        )}
      </div>
    </div>
  );
};
