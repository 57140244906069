/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, Link, StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';

import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';

import {
  computeSkillsStyle,
  renderAdditionalHighlightedText,
  renderWorkHighlightedText,
  trimUrlPrefix,
} from '../helpers/utils';

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: 400 }, { src: CalibriBold, fontWeight: 700 }, { src: CalibriItalic, fontStyle: 'italic' }] });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Calibri',
    width: '100%',
    height: '100%',
    padding: '50px',
    paddingVertical: '30px',
    margin: 0,
  },
  document: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: 'white',
  },
  header: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginBottom: 10,
  },
  headerLeftSide: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    width: '40%',
  },
  headerLeft: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000000',
  },
  headerLeftBlack: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
  },
  headerRightBlack: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    color: '#000',
    width: '100%',
  },
  headerRight: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
  },
  headerRightSide: {
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    textAlign: 'left',
    width: '40%',
  },
  topRow: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    width: '100%',
  },
  bottomRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 5,
    width: '100%',
  },
  block: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  EducationblockTitle: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #000',
  },
  WorkblockTitle: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #000',
  },
  LanguagesBlockTitle: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #000',
  },
  AdditionalBlockTitle: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'left',
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #000',
  },
  educationBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  WorkBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  AdditionalInfoBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    rowGap: 5,
  },
  EdRow: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  rowDescription: {
    flexDirection: 'row',
    width: '100%',
    height: 'auto',
  },

  EdLocation: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'right',
    color: '#000',
  },
  EdstudyType: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#000',
    marginBottom: 5,
  },
  EdDates: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'right',
    color: '#000000',
  },
  EdDescription: {
    fontSize: 9,
    width: '100%',
    height: 'auto',
    color: '#000000',
  },
  workInstitution: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    color: '#000',
    textTransform: 'uppercase',
  },
  location: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontWeight: 'normal',
    color: '#000',
    textTransform: 'capitalize',
  },
  workLocation: {
    fontSize: 10,
    textAlign: 'right',
    fontFamily: 'Calibri',
    color: '#000',
    marginLeft: 'auto',
  },
  workPosition: {
    fontSize: 10,
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    color: '#000000',
    marginBottom: 5,
  },
  workDescription: {
    fontSize: 10,
    fontFamily: 'Calibri',
    color: '#000000',
  },
  workDates: {
    fontSize: 10,
    color: '#000000',
  },
  highlights: {
    paddingLeft: 0,
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bulletPoint: {
    width: 15,
    fontSize: 15,
    marginRight: 5,
    marginTop: -2,
  },
  highlightText: {
    fontSize: 9,
    color: '#000000',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 'auto',
  },
  highlightTextSkills: {
    fontSize: 9,
    marginBottom: 0,
  },
  nameRow: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 0,
    marginTop: 15,
  },
  headerTopSideTwo: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    columnGap: 10,
    marginBottom: 5,
    marginTop: 10,
  },
  headerTopSide: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    columnGap: 3,
    marginTop: 0,
  },
  headerTopSideText: {
    fontSize: 11,
    fontFamily: 'Calibri',
    color: '#000000',
    textAlign: 'center',
    marginRight: 2,
  },
  nameText: {
    fontSize: 15,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
  },

  link: {
    color: '#000000',
    textDecoration: 'none',
  },
  blockWithBorder: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 10,
  },
  blockBorder: {
    borderBottomWidth: 1.5,
    borderBottomColor: '#002060',
    borderBottomStyle: 'solid',
    width: '100%',
  },
  blockTitleText: {
    fontSize: 11,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  languages: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  languagesText: {
    fontSize: 10,
    fontFamily: 'Calibri',
    color: '#000000',
  },
  normalWord: {
    color: 'black',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontFamily: 'Calibri',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontFamily: 'Calibri',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null
};

// Create Document Component
const GeorgiaTemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => {
  let previousWorkName = '';
  return (
    <Document title={title} style={styles.document}>
      <Page size="A4" style={styles.page}>
        <View style={styles.nameRow}>
          <Text style={styles.nameText}>
            {resume?.basics?.name?.toLocaleUpperCase()}
          </Text>
        </View>
        <View style={styles.header}>
          <View style={styles.headerTopSide}>
            {resume?.basics?.location?.address && (
              <>
                <Text style={styles.headerTopSideText}>
                  {resume?.basics?.location?.address}
                </Text>
                <Text style={styles.headerTopSideText}>
                  |
                </Text>
              </>
            )}
            { resume?.basics?.location?.city && (
              <>
                <Text style={styles.headerTopSideText}>
                  {[
                    resume?.basics?.location?.postalCode,
                    resume?.basics?.location?.city,
                  ].filter(Boolean).join(', ')}
                </Text>
                <Text style={styles.headerTopSideText}>
                  |
                </Text>
              </>
            )}
          </View>
          <View style={styles.headerTopSide}>
            {resume.basics?.emails?.map((email) => (
              <>
                <Text key={email} style={styles.headerTopSideText}>
                  <Link style={styles.link} key={email} href={`mailto:${email}`}>
                    {email}
                  </Link>
                </Text>
                <Text style={styles.headerTopSideText}>
                  |
                </Text>
              </>
            ))}
            {resume.basics?.phone_numbers?.map((phone) => (
              <>
                <Text key={phone} style={styles.headerTopSideText}>
                  {phone}
                </Text>
                <Text style={styles.headerTopSideText}>
                  |
                </Text>
              </>
            ))}
            <Text style={styles.headerTopSideText}>
              <Link style={styles.link} key={resume.basics.url} href={`https://www.${resume.basics.url && (trimUrlPrefix(resume.basics.url))}`}>
                {resume.basics.url && (trimUrlPrefix(resume.basics.url))}
              </Link>
            </Text>
          </View>
        </View>

        <View style={styles.block}>
          <View style={styles.blockWithBorder}>
            <View style={styles.EducationblockTitle}>
              <Text style={styles.blockTitleText}>
                EDUCATION
              </Text>
            </View>
          </View>
          {resume.education?.map((item) => (
            <View key={item.institution} style={styles.educationBlock}>
              <View style={styles.row}>
                <Text style={styles.workInstitution}>
                  {item.institution}
                  {', '}
                  <Text style={styles.location}>{item.location}</Text>
                </Text>
                <Text style={styles.EdLocation}>
                  {item.startDate}
                  {' '}
                  -
                  {' '}
                  {item.endDate}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.EdstudyType}>
                  {item.area}
                </Text>
                <Text style={styles.EdDates} />
              </View>
              {item.description && item.description.map((desc) => (
                <View key={desc} style={styles.listItem}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.EdDescription}>
                    {desc}
                  </Text>
                </View>
              ))}
            </View>
          ))}
        </View>
        <View style={styles.block}>
          <View style={styles.blockWithBorder}>
            <View style={styles.WorkblockTitle}>
              <Text style={styles.blockTitleText}>
                EXPERIENCE
              </Text>
            </View>
          </View>
          {resume.work?.map((item, index) => {
            const showWorkName = item.name !== previousWorkName;
            previousWorkName = item.name as string;
            return (
              <View key={`${item.name}-${index}`} style={styles.WorkBlock}>
                <View style={styles.row}>
                  {showWorkName && (
                    <Text style={styles.workInstitution}>
                      {item.name}
                      {', '}
                      <Text style={styles.location}>
                        {item.location}
                      </Text>
                    </Text>
                  )}
                  <Text style={styles.workLocation}>
                    {item.startDate}
                    {' '}
                    -
                    {' '}
                    {item.endDate}
                  </Text>
                </View>
                <View style={styles.row}>
                  <Text style={item.summary ? styles.workDescription : styles.workPosition}>
                    {showWorkName && (item.summary ? item.summary : item.position)}
                  </Text>
                  <Text style={styles.workDates} />
                </View>
                {item?.summary && (
                <View style={styles.row}>
                  <Text style={styles.workPosition}>
                    {item.position}
                  </Text>
                </View>
                )}
                {item.highlights && item.highlights.length > 0 && (
                <View style={styles.highlights}>
                  {item.highlights.map((highlight, highlightIndex) => {
                    const cleanedHighlight = highlight.replace(/^•\s/, '');

                    return (
                      <View key={`${highlight}-${highlightIndex}`} style={styles.listItem}>
                        <Text style={styles.bulletPoint}>•</Text>
                        <View style={styles.highlightText}>
                          {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                        </View>
                      </View>
                    );
                  })}
                </View>
                )}
              </View>
            );
          })}
        </View>
        <View style={styles.block}>
          <View style={styles.blockWithBorder}>
            <View style={styles.AdditionalBlockTitle}>
              <Text style={styles.blockTitleText}>
                ADDITIONAL INFORMATION
              </Text>
            </View>
          </View>
          {resume?.skills && (
            <View style={styles.listItem}>
              <Text style={styles.bulletPoint}>•</Text>
              <View style={styles.highlightText}>
                <Text style={[computeSkillsStyle(highlightedWords, activeField, styles), styles.highlightTextSkills]}>
                  Technical Skills:
                  {' '}
                  {resume.skills?.map((skill) => `${skill.name} (${skill.level})`).join(', ')}
                </Text>
              </View>
            </View>
          )}
          {resume.additional_information?.bullets?.length > 0
          && (
          <View style={styles.AdditionalInfoBlock}>
            <View style={styles.highlights}>
              {resume.additional_information.bullets?.map((bullet, bulletIndex) => bullet.replace(/^•\s/, '') && (
                <View key={`${bullet}-${bulletIndex}`} style={styles.listItem} wrap={false}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <View style={styles.highlightText}>
                    {renderAdditionalHighlightedText(bullet.replace(/^•\s/, ''), bulletIndex, highlightedWords, activeField, styles)}
                  </View>
                </View>
              ))}
              <View style={styles.listItem} wrap={false}>
                <Text style={styles.bulletPoint}>•</Text>
                <View style={styles.highlightText}>
                  <Text>
                    Languages:
                    {' '}
                    {resume.languages?.map((language) => `${language.language} (${language.fluency})`).join(', ')}
                  </Text>
                </View>
              </View>
            </View>

          </View>
          )}
        </View>
      </Page>
    </Document>
  );
};

export default GeorgiaTemplate;
