import { Tooltip } from '@careeros/coco';
import { memo } from 'react';
import { Tag, TagColors } from '@/components/tag/tag';

type TagItem = {
  tag_content: string;
};

type TagWithTooltipProps = {
  content: string;
  tooltipMessage: string;
};

const TagWithTooltip = ({ content, tooltipMessage }:TagWithTooltipProps) => (
  <div className="contact-header__with-tooltip">
    <Tooltip
      key={content}
      label={tooltipMessage}
      position="top"
      withArrow
    >
      <Tag
        color={TagColors.Grey}
        label={content}
      />
    </Tooltip>
  </div>
);

const AlumTag = memo(({ tag }: { tag: TagItem }) => {
  const tooltipMessages: Record<string, string> = {
    '🎓 Alum': 'Students who studied at your university in the past',
    '🤩 Hiring Success': 'Students who got a job in 3 months after graduating',
    '🚀 CareerOS Superstar': 'Student who have used CareerOS and have given permission to track their data',
  };

  const tooltipMessage = tooltipMessages[tag.tag_content];

  if (tooltipMessage) {
    return (
      <TagWithTooltip
        key={tag.tag_content}
        content={tag.tag_content}
        tooltipMessage={tooltipMessage}
      />
    );
  }

  return (
    <Tag
      key={tag.tag_content}
      color={TagColors.Grey}
      label={tag.tag_content}
    />
  );
});

AlumTag.displayName = 'AlumTag';

export default AlumTag;
