import { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { getSentEmailLog } from '@/domains/core/advisor/api/student';
import { useSelfUniversity } from '@/services/queries/user';
import { Loader } from '@/components/loader/loader';
import { SentLogChat } from '@/domains/core/advisor/components/sent-log-chat/sent-log-chat';
import { transformBackendData } from '@/domains/core/advisor/utils/transform-backend-data-for-sent-log';
import { SentEmailLogType } from '@/domains/core/advisor/types/sent-email-log';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import '../styles/sent-email-log.scss';
import { useAdvisorRoleCheck } from './hooks/authz';

export const SentEmailLog = () => {
  const { data: university, isLoading } = useSelfUniversity();
  const [sentEmailMessages, setSentEmailMessages] = useState<SentEmailLogType[]>([]);
  const [isLoadingMessages, setIsLoadingMessages] = useState(true);
  const { addToast }: AddToastType = useContext(ToastContext);

  const getMassages = async () => {
    if (!university) return;
    setIsLoadingMessages(true);
    try {
      const res = await getSentEmailLog(university?.id);

      if (res) {
        const transformedMessages = transformBackendData(res);
        setSentEmailMessages(transformedMessages);
      } else {
        setSentEmailMessages([]);
      }
    } catch (err) {
      Sentry.captureException(err);
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again later.',
      });
    } finally {
      setIsLoadingMessages(false);
    }
  };

  useAdvisorRoleCheck();

  useEffect(() => {
    getMassages();
  }, [university]);

  if (isLoading || isLoadingMessages) {
    return <Loader />;
  }

  return (
    <div className="sent-email-log">
      <div className="sent-email-log__header">
        <div className="sent-email-log__title">{`Sent Email Log for ${university?.name}`}</div>
        <div className="sent-email-log__subtitle">See all emails sent by Career Advisors to students below</div>
      </div>

      <div className="sent-email-log__separator" />

      {(!isLoading && !isLoadingMessages) && (
        <div className="sent-email-log__chat">
          <SentLogChat
            messages={sentEmailMessages}
            hasLongRecipient
          />
        </div>
      )}

    </div>
  );
};
