/* eslint-disable jsx-a11y/control-has-associated-label */
import { Quill } from 'react-quill';
import { Tooltip } from '@careeros/coco';

import './editor-toolbar.scss';

const icons = Quill.import('ui/icons');
icons.bold = null;
icons.italic = null;
icons.list = null;
icons.underline = null;
icons.size = null;
icons.link = null;

interface EditorToolbarProps {
  id?: string
}

export const EditorToolbar = ({ id } : EditorToolbarProps) => (
  <div className="editor-toolbar" id={id ? `toolbar-${id}` : 'toolbar'} onClick={(e) => e.stopPropagation()}>
    <div className="edit-toolbar__container">
      <Tooltip label="Bold" position="top">
        <button className="ql-bold" type="button" aria-label="bold">
          <i className="bi bi-type-bold" />
        </button>
      </Tooltip>
    </div>
    <div className="edit-toolbar__container">
      <Tooltip label="Italic" position="top">
        <button className="ql-italic" type="button" aria-label="italic">
          <i className="bi bi-type-italic" />
        </button>
      </Tooltip>
    </div>
    <div className="edit-toolbar__container">
      <Tooltip label="Underline" position="top">
        <button className="ql-underline" type="button" aria-label="underline">
          <i className="bi bi-type-underline" />
        </button>
      </Tooltip>
    </div>
    <div className="edit-toolbar__container">
      <Tooltip label="Numbered list" position="top">
        <button className="ql-list" value="ordered" type="button" aria-label="numbered list">
          <i className="bi bi-list-ol" />
        </button>
      </Tooltip>
    </div>
    <div className="edit-toolbar__container">
      <Tooltip label="Bulleted list" position="top">
        <button className="ql-list" value="bullet" type="button" aria-label="bullet list">
          <i className="bi bi-list-ul" />
        </button>
      </Tooltip>
    </div>
    <div className="edit-toolbar__container">
      <Tooltip label="Attach link" position="top">
        <button className="ql-link" type="button" aria-label="link">
          <i className="bi bi-link" />
        </button>
      </Tooltip>
    </div>
  </div>
);
