import { Checkbox } from '@careeros/coco';
import { useState, useEffect } from 'react';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { FilterOption } from '@/domains/core/company/types';
import { JobsFilterState } from '../../types/job';
import { UniversityResponse } from '@/services/api/student';

type Props = {
  filters: JobsFilterState;
  staticOptions: Record<string, FilterOption[]>;
  handleFilterChange: (key: string, value: FilterOption[]) => void;
  university: UniversityResponse | undefined;
};

export const JobsFiltersModal = ({
  filters,
  staticOptions,
  handleFilterChange,
  university,
}: Props) => {
  const [localFilters, setLocalFilters] = useState<JobsFilterState>(filters);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleLocalFilterChange = (key: string, value: FilterOption[]) => {
    setLocalFilters(prev => ({
      ...prev,
      [key]: value,
    }));

    handleFilterChange(key, value);
  };

  const {
    contract,
    postedDate,
    workType,
    industries,
    sizes,
  } = staticOptions;

  return (
    <div className="job-filters__filters-modal">
      <MultiSelect
        labelType="list"
        handleSelectedOptions={(options: FilterOption[]) => handleLocalFilterChange('job_types', options)}
        placeholder="Full-time"
        selected={localFilters.job_types}
        options={contract}
        size="full"
      />

      <MultiSelect
        labelType="list"
        handleSelectedOptions={(options: FilterOption[]) => handleLocalFilterChange('industries', options)}
        placeholder="Industry"
        selected={localFilters.industries}
        options={industries}
        size="full"
      />

      <MultiSelect
        labelType="list"
        handleSelectedOptions={(options: FilterOption[]) => handleLocalFilterChange('date_range', options)}
        placeholder="Date Posted"
        selected={localFilters.date_range}
        options={postedDate}
        size="full"
      />

      <MultiSelect
        labelType="list"
        handleSelectedOptions={(options: FilterOption[]) => handleLocalFilterChange('work_modes', options)}
        placeholder="Remote"
        selected={localFilters.work_modes}
        options={workType}
        size="full"
      />

      <MultiSelect
        labelType="list"
        handleSelectedOptions={(options: FilterOption[]) => handleLocalFilterChange('sizes', options)}
        placeholder="Company Size"
        selected={localFilters.sizes}
        options={sizes}
        width="fit-box"
        size="full"
      />
      <Checkbox
        id="university-jobs"
        label={`Show only ${university?.name} exclusive jobs`}
        checked={localFilters.byUniversityPartners.length > 0 && localFilters.byUniversityPartners[0].value === true}
        onChange={(checked: boolean) => {
          handleLocalFilterChange('byUniversityPartners', checked ? [{ name: `${university?.name} exclusive jobs`, value: true }] : []);
        }}
      />
      <Checkbox
        id="exclusive-jobs"
        label="Show only jobs exclusive to CareerOS"
        checked={localFilters.byCareerOSPartners.length > 0 && localFilters.byCareerOSPartners[0].value === true}
        onChange={(checked: boolean) => {
          handleLocalFilterChange('byCareerOSPartners', checked ? [{ name: 'CareerOS exclusive ', value: true }] : []);
        }}
      />
    </div>
  );
};
