import {
  CSSProperties, useContext, useEffect, useMemo,
} from 'react';
import { AvatarStack, Button } from '@careeros/coco';

import { useNavigate } from 'react-router-dom';
import { ChallengeProgress } from '../challenge-progress/challenge-progress';
import './challenges-widget.scss';
import { Loader } from '@/components/loader/loader';
import { Challenge } from '@/types/gamification';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';
import { useSelfUniversity } from '@/services/queries/user';
import { ModalContext } from '@/components/modal/modal-provider';
import { ChallengeModal } from '../challenge-modal/challenge-modal';

export const ChallengesWidget = () => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();

  const navigate = useNavigate();
  const {
    completeActionIfAvailable,
    isActionsLoading,
    earnedChallenges: completedChallenges,
    visibleChallenges,
    isChallengesLoading,
  } = useGamification();

  useEffect(() => {
    if (!isActionsLoading) {
      completeActionIfAvailable(GAMIFICATION_ACTIONS.CREATE_ACCOUNT);
      if (university?.cohort) {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.ENROLL_IN_COHORT);
      }
    }
  }, [isActionsLoading, isUniversityLoading]);

  const earnedBadgesCount = useMemo(() => {
    const total = completedChallenges.length;
    if (total <= 3) return total;
    if (total === 4) return 1;
    if (total === 5) return 2;
    return 3;
  }, [completedChallenges.length]);

  const earnedBadges = useMemo(
    () => completedChallenges.sort((a, b) => new Date(b.completed_at).getTime() - new Date(a.completed_at).getTime()).slice(0, earnedBadgesCount),
    [completedChallenges, earnedBadgesCount],
  );

  const remainingBadges = useMemo(
    () => completedChallenges.slice(earnedBadgesCount),
    [completedChallenges, earnedBadgesCount],
  );

  const navigateToChallenges = () => {
    navigate('/app/challenges');
  };

  const handleNavigate = () => {
    closeModal();
    navigateToChallenges();
  };

  const handleBadgeClick = (badgeId: string) => {
    const badge = completedChallenges.find((bdg) => bdg.id === badgeId);
    if (badge) {
      openModal(
        <ChallengeModal
          badgeImage={badge.image}
          description={`You earned the <strong>${badge.name}</strong> badge by ${badge.completed_description}`}
          color={badge.color}
          id={badge.id}
          isRead={badge.is_read}
          handleViewAllBadges={handleNavigate}
          handlePrimaryClick={closeModal}
        />,
      );
    }
  };

  if (isChallengesLoading || isActionsLoading) {
    return (
      <div className="suggested-contacts-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="challenges-widget">
      <div className="challenges-widget__section">
        <div className="challenges-widget__title">
          <div className="challenges-widget__title-text">
            <span>🎖️</span>
            Badges
            <span>🎖️</span>
          </div>
        </div>
        <div className="challenges-widget__see-more">
          <Button
            mode="invisible"
            label="See all"
            size="medium"
            onClick={navigateToChallenges}
            iconPosition="right"
            icon="bi bi-box-arrow-up-right"
          />
        </div>

        {remainingBadges.length > 0 && (
          <div className="challenges-widget__badges-summary">
            <div className="challenges-widget__badges-stack">
              <AvatarStack
                personList={remainingBadges.map((badge) => ({
                  id: badge.id,
                  name: badge.name,
                  avatar: badge.image,
                  handleClick: handleBadgeClick,
                }))}
                maxAvatars={4}
                handleClick={navigateToChallenges}
                withPerItemHover
                withPerItemTooltip
                size={35}
              />
            </div>
            <span className="challenges-widget__badges-count" onClick={navigateToChallenges}>
              {`You've earned ${completedChallenges.length} challenge badges so far 🎉`}
            </span>
          </div>
        )}
        <div className="challenges-widget__earned-badges">
          {earnedBadges.map((badge: Challenge) => (
            <div onClick={() => handleBadgeClick(badge.id)} key={badge.id} className="challenges-widget__earned-badge" style={{ '--badge-color': badge.color } as CSSProperties}>
              <img
                src={badge.image}
                alt=""
                className="challenges-widget__earned-badge-icon"
              />
              <div className="challenges-widget__earned-badge-content">
                <div className="challenges-widget__earned-badge-text">
                  You last earned the
                  {' '}
                  <span className="challenges-widget__badge-name" style={{ color: badge.color, fontWeight: '700' }}>
                    {badge.name}
                  </span>
                  {' badge by '}
                  <span className="challenges-widget__earned-badge-description">
                    {badge.completed_description}
                  </span>
                </div>
                <span className="challenges-widget__earned-badge-progress challenges-widget__earned-badge-progress--gradient-text">
                  {`${badge.completed_at_value}/${badge.completed_at_value}`}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Challenges Section */}
      <div className="challenges-widget__section">
        <div className="challenges-widget__title">
          <div className="challenges-widget__title-text">
            <span>🎯</span>
            Challenges
            <span>🎯</span>
          </div>
        </div>

        <div className="challenges-widget__content">
          {visibleChallenges.map((challenge: Challenge) => (
            <ChallengeProgress
              key={challenge.id}
              completedText={`${challenge.description} to earn the ${challenge.name} Badge`}
              progress={challenge.user_progress}
              badge={challenge.image}
              color={challenge.color}
              maxProgress={challenge.completed_at_value}
            >
              <b>{challenge.description}</b>
              {' '}
              to earn the
              {' '}
              <b>
                {challenge.name}
                {' '}
                Badge
              </b>
            </ChallengeProgress>
          ))}
          <div className="challenges-widget__more-to-come">
            🔒 More challenges to come! 🔒
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChallengesWidget;
