const ALLOWED_LOCATIONS = ['Remote', 'WFH', 'Telework'];
const EMAIL_EXAMPLE = 'username@example.com';
const PHONE_NUMBER_EXAMPLE = '+1234567890';
const LOCATION_EXAMPLE = 'City, Country or Remote';
const LINKEDIN_URL_EXAMPLE = 'linkedin.com/in/username';
const LINKEDIN_URL_PATTERN = /linkedin\.com\/in\/.+/;

export const RESUME_RULES = {
  basics: {
    name: true,
    work_permit: true,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: {
      min: 1,
      require: true,
    },
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const MARYLAND_UG_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: true,
      min: 1,
      contains: '',
    },
    url: {
      require: false,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: false,
      startDate: false,
      endDate: true,
      location: {
        require: false,
        contains: ',',
        example: 'City, Country or Remote',
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: false,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: 'City, Country or Remote',
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 0,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const MARYLAND_MBA_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: 'username@example.com',
    },
    nationality: false,
    phone_numbers: {
      require: true,
      min: 1,
      contains: '',
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: /linkedin\.com\/in\/.+/,
      example: 'linkedin.com/in/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: false,
      startDate: false,
      endDate: true,
      location: {
        require: false,
        contains: ',',
        example: 'City, Country or Remote',
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: false,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: 'City, Country or Remote',
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 0,
        max: 50,
      },
    },
  },
  languages: {
    min: 0,
    box: {
      language: false,
      fluency: false,
    },
  },
  skills: {
    min: 0,
    box: {
      name: false,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const AMS_RULES = {
  basics: {
    name: true,
    work_permit: false,
    location: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const ESMT_ATS_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const HHL_EDUCATION_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: true,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
    summary: {
      min: 0,
      require: false,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: false,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 0,
    box: {
      language: true,
      fluency: false,
    },
  },
  skills: {
    min: 0,
    box: {
      name: true,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const HHL_WORK_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: true,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
    summary: {
      min: 0,
      require: false,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: false,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 0,
    box: {
      language: true,
      fluency: false,
    },
  },
  skills: {
    min: 0,
    box: {
      name: true,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const HHL_ONE_PAGE_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: true,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
    summary: {
      min: 0,
      require: false,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: false,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: false,
      endDate: false,
      location: {
        require: false,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 0,
    box: {
      language: true,
      fluency: false,
    },
  },
  skills: {
    min: 0,
    box: {
      name: true,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const OXFORD_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const GEORGIA_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const RICE_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const HULT_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
    github_url: {
      contains: 'https://github.com/',
      pattern: /github\.com\/[\w-]+/,
      example: 'github.com/username',
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const MODERN_MINIMALIST_RULES = {
  basics: {
    name: true,
    work_permit: false,
    location: false,
    emails: {
      min: 1,
      max: 2,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const ASB_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: 'xxx@MBA202X.asb.edu.my',
    },
    phone_numbers: {
      require: true,
      min: 1,
      max: 1,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const ESMT_RULES = {
  basics: {
    name: true,
    work_permit: false,
    birth_date: false,
    summary: true,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: false,
      min: 0,
      max: 1,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work_section_subheading: {
    require: true,
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 0,
    box: {
      name: false,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const BAYES_RULES = {
  basics: {
    name: true,
    work_permit: false,
    birth_date: false,
    summary: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    nationality: false,
    phone_numbers: {
      require: true,
      min: 0,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 0,
    box: {
      name: false,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const MBS_MBA_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    phone_numbers: {
      require: false,
      min: 1,
      max: 1,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
    summary: {
      min: 0,
      require: true,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: '',
        example: 'Country or Remote',
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const EBS_RULES = {
  basics: {
    name: true,
    work_permit: false,
    emails: {
      min: 1,
      max: 1,
      require: true,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    phone_numbers: {
      require: false,
      min: 1,
      max: 1,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: '',
        example: 'Country or Remote',
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 1,
    box: {
      name: true,
      level: true,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};

export const BOCCONI_STANDARD_RULES = {
  basics: {
    name: true,
    profile_picture_url: true,
    nationality: false,
    birth_date: false,
    emails: {
      min: 0,
      max: 2,
      require: false,
      contains: '@',
      example: EMAIL_EXAMPLE,
    },
    phone_numbers: {
      require: false,
      min: 1,
      max: 2,
      contains: '+',
      example: PHONE_NUMBER_EXAMPLE,
    },
    url: {
      require: true,
      contains: 'linkedin.com/in/',
      pattern: LINKEDIN_URL_PATTERN,
      example: LINKEDIN_URL_EXAMPLE,
    },
  },
  education: {
    min: 1,
    box: {
      institution: true,
      area: false,
      startDate: false,
      endDate: true,
      location: {
        require: true,
        contains: '',
        example: 'Country or Remote',
        allow: ALLOWED_LOCATIONS,
      },
    },
  },
  work: {
    min: 1,
    box: {
      name: true,
      position: true,
      startDate: true,
      endDate: true,
      location: {
        require: true,
        contains: ',',
        example: LOCATION_EXAMPLE,
        allow: ALLOWED_LOCATIONS,
      },
      highlights: {
        min: 1,
        max: 50,
      },
    },
  },
  languages: {
    min: 1,
    box: {
      language: true,
      fluency: true,
    },
  },
  skills: {
    min: 0,
    box: {
      name: false,
      level: false,
    },
  },
  additional_information: {
    bullets: {
      min: 0,
      max: 10,
    },
  },
};
