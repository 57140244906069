import './campus-champions-container.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@careeros/coco';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';

import type { Contact, SuggestedContactType } from '@/domains/core/contact/types/contact';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { CampusChampion } from '../campus-champion/campus-champion';
import { useGetContactsNoCache } from '@/services/queries/contact';
import { useAlgoliaInsights } from '@/services/hooks/use-algolia';
import { useSelfCampusChampions } from '@/services/queries/campus-champion';
import { connectCampusChampion } from '@/services/api/contacts';
// import { useAlgoliaInsights } from '@/services/hooks/use-algolia';

type Props = {
  companyID: string;
  savedContacts: Contact[];
  archivedContacts: Contact[];
  size: 'small' | 'large';
  updateContacts: () => void;
  source: 'Company Profile Page' | 'Company Tracker Page';
};

export const CampusChampionsForCompanyContainer = ({
  companyID,
  savedContacts,
  archivedContacts,
  size = 'large',
  updateContacts,
  source,
}: Props) => {
  const { data: campusChampions, isLoading: areCampusChampionsLoading } = useSelfCampusChampions();
  const { data: contacts, isLoading: areContactsLoading } = useGetContactsNoCache();
  const { data: selfUniversity, isLoading: isSelfLoading } = useSelfUniversity();
  const [companyCampusChampions, setCompanyCampusChampions] = useState<SuggestedContactType[]>([]);
  const { data: self } = useSelf();
  const insights = useAlgoliaInsights();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();

  const navigate = useNavigate();

  const handleSeeMoreCampusChampionsClick = () => {
    navigate('/app/contacts/campus-champions');
  };

  const handleSaveContact = async (id: string) => {
    const suggestedContact = campusChampions?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;

    await connectCampusChampion(suggestedContact.id);

    updateContacts();

    trackEvent('Saved Contact', user, {
      companyID,
      source,
      campusChampionName: `${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`,
      type: 'save',
    });
  };

  const handleReachOut = async (id: string) => {
    const suggestedContact = campusChampions?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;
    await connectCampusChampion(suggestedContact.id);
    updateContacts();
    trackEvent('Saved Contact', user, {
      companyID,
      source,
      campusChampionName: `${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`,
      type: 'reach_out',
    });

    if (self?.id) { // no contacts in algolia yet
      insights.purchasedObjectIDs(
        'Contact Saved',
        'contact',
        [suggestedContact.contact_id],
        self.id,
      );
    }
    navigate(`/app/inbox/${suggestedContact.contact_id}`);
  };

  useEffect(() => {
    if (!areCampusChampionsLoading) {
      const companyChampions = Array.isArray(campusChampions) ? campusChampions.filter((champion: SuggestedContactType) => champion.company_id === companyID && !savedContacts?.find((contact) => contact.id === champion.contact_id) && !archivedContacts?.find((contact) => contact.id === champion.contact_id) && !contacts?.find((contact: Contact) => contact.id === champion.contact_id)) : [];
      setCompanyCampusChampions(companyChampions);
    }
  }, [campusChampions, areCampusChampionsLoading, savedContacts, archivedContacts]);

  if (areCampusChampionsLoading || isSelfLoading || areContactsLoading) {
    return (
      <div className="campus-champions-container__loader-container">
        <div className="job-tabs__loader" />
        <p className="jobs-tab__loader-text">
          Loading campus champions...
        </p>
      </div>
    );
  }

  return (
    <div className={`campus-champions-container ${!Array.isArray(companyCampusChampions) || companyCampusChampions?.length === 0 ? 'campus-champions-container--empty' : ''}`}>
      {Array.isArray(companyCampusChampions) && companyCampusChampions.length > 0 && (
        <div className={`campus-champions-container__contacts campus-champions-container__contacts--${size}`}>
          <div className="campus-champions-container__header">
            <h3 className="campus-champions-container__title">
              Campus Champions
            </h3>
            <TooltipIcon
              tooltipLabel={`Campus Champions are contacts selected specifically for ${selfUniversity?.name} by this company.
Feel free to reach out to them, they are waiting for your message!`}
              tooltipPosition="top"
            />
            <div className="campus-champions-container__see-more">
              <Button
                mode="text"
                label="See more"
                size="medium"
                onClick={handleSeeMoreCampusChampionsClick}
                iconPosition="right"
                icon="bi bi-box-arrow-up-right"
              />
            </div>
          </div>
          <div className="campus-champions-container__scroll-container">
            <div className="campus-champions-container__list">
              {companyCampusChampions.map((suggestedContact: SuggestedContactType) => (
                <CampusChampion
                  key={suggestedContact.contact_id}
                  id={suggestedContact.contact_id}
                  name={`${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`}
                  position={suggestedContact.contact.current_position.title}
                  image={suggestedContact.contact.career_summary?.basics?.image}
                  handleMainButtonAction={handleReachOut}
                  handleSecondaryButtonAction={handleSaveContact}
                />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
