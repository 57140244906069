/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, StyleSheet, Font, Image, Link,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';

import Arial from '@/assets/fonts/resume-builder/Arial/Arial.ttf';
import ArialBold from '@/assets/fonts/resume-builder/Arial/Arial_Bold.ttf';
import ArialItalic from '@/assets/fonts/resume-builder/Arial/Arial_Italic.ttf';

import { renderAdditionalHighlightedText, renderWorkHighlightedText, trimUrlPrefix } from '../helpers/utils';
import { formatDateYearOnly } from '../helpers/formate-date-templates';

Font.register({ family: 'Arial', src: Arial, fonts: [{ src: Arial, fontWeight: 400 }, { src: ArialBold, fontWeight: 700 }, { src: ArialItalic, fontStyle: 'italic' }] });

Font.registerHyphenationCallback((word: any) => (
  [word]
));

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Arial',
    padding: '25px 20px',
  },
  document: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: 'white',
  },
  headerText: {
    textAlign: 'right',
    fontWeight: 'bold',
    fontFamily: 'Arial',
    fontSize: 13.5,
    color: '#C40033',
    marginBottom: 8,
  },
  headerTextSmall: {
    fontFamily: 'Arial',
    color: '#666666',
    fontSize: 8,
    textDecoration: 'none',
  },
  contactInfo: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    padding: '0 5px',
  },
  contactInfoRow: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontSize: 8,
    fontFamily: 'Arial',
    color: '#666666',
    gap: 3,
  },
  contactInfoRowAddress: {
    textAlign: 'left',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    fontSize: 8,
    fontFamily: 'Arial',
    color: '#666666',
  },
  contactInfoTitle: {
    fontSize: 8,
    fontFamily: 'Arial',
    fontWeight: 700,
    color: '#00396A',
    marginTop: 5,
  },
  contactInfoRowColumn: {
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    columnGap: 5,
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
  },
  leftColumn: {
    paddingTop: 28,
    width: '73%',
  },
  rightColumn: {
    alignItems: 'flex-end',
    width: '27%',
  },
  leftColumnContainer: {
    paddingBottom: 10,
  },
  sectionHeader: {
    fontSize: 8,
    color: '#00396A',
    fontWeight: 700,
    textTransform: 'uppercase',
    marginBottom: 3,
  },
  normalText: {
    fontSize: 8,
    color: '#666666',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 8,
    fontFamily: 'Arial',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 8,
    fontFamily: 'Arial',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 8,
    fontFamily: 'Arial',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 8,
    fontFamily: 'Arial',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 8,
    fontFamily: 'Arial',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 8,
    fontFamily: 'Arial',
  },
  bulletPoint: {
    width: 10,
    fontSize: 8,
  },
  educationBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    width: '100%',
    marginBottom: 10,
  },
  educationContainer: {
    width: '100%',
  },
  Edinstitution: {
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#666666',
  },
  EdstudyType: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    fontSize: 8,
    fontFamily: 'Arial',
    fontWeight: 400,
    textAlign: 'left',
    color: '#666666',
  },
  EdstudyInstitution: {
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  EdDates: {
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#C40033',
  },
  EdDescription: {
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#666666',
  },
  skillsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  langRow: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  WorkBlock: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    width: '100%',
    paddingBottom: 10,
  },
  workDates: {
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#C40033',
    width: '60px',
  },
  workPosition: {
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#666666',
    fontStyle: 'italic',
  },
  workInstitutionContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#666666',
  },
  workInstitution: {
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  workLocation: {
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    color: '#666666',
    fontWeight: 400,
  },
  summary: {
    fontSize: 8,
    fontFamily: 'Arial',
    textAlign: 'left',
    fontStyle: 'italic',
    color: '#666666',
  },
  highlights: {
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    color: '#666666',
  },
  profilePictureContainer: {
    width: 140,
    height: 170,
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePicture: {
    width: 140,
    height: 170,
    overflow: 'hidden',
  },
  highlightText: {
    fontSize: 8,
    color: '#666666',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  normalWord: {
    color: '#666666',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null
};

const BocconiTemplate = ({ resume, highlightedWords, activeField }: ResumeSchemaProps) => {
  let previousWorkName = '';

  return (
    <Document style={styles.document}>
      <Page size="A4" style={styles.page}>
        <View style={styles.columnContainer}>
          <View style={styles.leftColumn}>
            <View>
              <Text style={styles.sectionHeader}>Education</Text>
              {resume.education?.map((item) => (
                <View key={item.institution} style={styles.educationBlock}>
                  <View>
                    <Text style={styles.workDates}>
                      {item.startDate && (
                        <>
                          {formatDateYearOnly(item.startDate)}
                          {' '}
                          -
                          {' '}
                        </>
                      )}
                      {item.endDate === 'Present' ? 'current' : formatDateYearOnly(item.endDate)}
                    </Text>
                  </View>
                  <View style={styles.educationContainer}>
                    <View style={styles.EdstudyType}>
                      <Text style={styles.EdstudyInstitution}>{`${item.institution}, `}</Text>
                      <Text>{item.location}</Text>
                    </View>
                    <View>
                      <Text style={styles.Edinstitution}>
                        {item.area}
                      </Text>
                    </View>
                    {item.description && item.description.length > 0 && (
                      <View style={styles.highlights}>
                        {item.description.map((desc) => (
                          <View key={desc} style={styles.listItem}>
                            <Text style={styles.bulletPoint}>•</Text>
                            <Text style={styles.EdDescription}>{desc}</Text>
                          </View>
                        ))}
                      </View>
                    )}
                  </View>
                </View>
              ))}
            </View>
            <View>
              <Text style={styles.sectionHeader}>Professional Experience</Text>
              {resume.work?.map((item, index) => {
                const showWorkName = item.name !== previousWorkName;
                previousWorkName = item.name as string;
                return (
                  <View key={`${item.name}-${index}`} style={styles.WorkBlock}>
                    <View>
                      <Text style={styles.workDates}>
                        {formatDateYearOnly(item.startDate)}
                        {' '}
                        -
                        {' '}
                        {item.endDate === 'Present' ? 'current' : formatDateYearOnly(item.endDate)}
                      </Text>
                    </View>
                    <View>
                      {showWorkName && (
                        <View>
                          <View style={styles.workInstitutionContainer}>
                            <Text style={styles.workInstitution}>
                              {`${item.name}, `}
                            </Text>
                            <Text style={styles.workLocation}>
                              {item.location}
                            </Text>
                          </View>
                          <Text style={styles.workLocation}>
                            {item.summary}
                          </Text>
                        </View>
                      )}
                      <Text style={styles.workPosition}>
                        {item.position}
                      </Text>
                      {item.highlights && item.highlights.length > 0 && (
                        <View style={styles.highlights}>
                          {item.highlights.map((highlight, highlightIndex) => {
                            const cleanedHighlight = highlight.replace(/^•\s/, '');

                            return (
                              <View key={`${highlight}-${highlightIndex}`} style={styles.listItem}>
                                <Text style={styles.bulletPoint}>•</Text>
                                <View style={styles.highlightText}>
                                  {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                                </View>
                              </View>
                            );
                          })}
                        </View>
                      )}
                    </View>
                  </View>
                );
              })}
            </View>
            <View style={styles.leftColumnContainer}>
              <Text style={styles.sectionHeader}>Languages</Text>
              <View style={styles.skillsContainer}>
                {resume.languages.map((lang, index) => (
                  <View style={styles.langRow}>
                    <Text key={index} style={styles.normalText}>
                      {`${lang.language}: `}
                    </Text>
                    <Text key={index} style={styles.normalText}>
                      {`${lang.fluency}; `}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
            {resume.skills && resume.skills.length > 0 && (
              <View style={styles.leftColumnContainer}>
                <Text style={styles.sectionHeader}>Skills</Text>
                <View style={styles.skillsContainer}>
                  {resume.skills.map((skill, index) => (
                    <View style={styles.langRow}>
                      <Text key={index} style={styles.normalText}>
                        {`${skill.name}: `}
                      </Text>
                      <Text key={index} style={styles.normalText}>
                        {`${skill.level}; `}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            )}
            <View style={styles.leftColumnContainer}>
              <Text style={styles.sectionHeader}>Additional Information</Text>
              {resume.additional_information.bullets?.map((bullet, bulletIndex) => bullet.replace(/^•\s/, '') && (
                <View key={`${bullet}-${bulletIndex}`} style={styles.listItem} wrap={false}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <View style={styles.highlightText}>
                    {renderAdditionalHighlightedText(bullet.replace(/^•\s/, ''), bulletIndex, highlightedWords, activeField, styles)}
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View style={styles.rightColumn}>
            <Text style={styles.headerText}>
              {resume.basics.name && resume.basics.name}
            </Text>
            {resume.basics.profile_picture_url && (
              <View style={styles.profilePictureContainer}>
                <View style={styles.profilePicture}>
                  <Image
                    src={resume.basics.profile_picture_url}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </View>
              </View>
            )}
            <View style={styles.contactInfo}>
              {(resume.basics.birth_date || (resume.basics.nationality && resume.basics.nationality.length > 0)) && (
                <>
                  <Text style={styles.contactInfoTitle}>Personal data</Text>
                  {resume.basics.birth_date && (
                    <View style={styles.contactInfoRow}>
                      <Text>Date of birth:</Text>
                      <Text style={styles.headerTextSmall}>{resume.basics.birth_date}</Text>
                    </View>
                  )}
                  {resume.basics.nationality && Array.isArray(resume.basics.nationality) && resume.basics.nationality.length > 0 && (
                    <View style={styles.contactInfoRow}>
                      <Text>Nationality:</Text>
                      <Text style={styles.headerTextSmall}>
                        {resume.basics.nationality?.join(', ')}
                      </Text>
                    </View>
                  )}
                </>
              )}
              <Text style={styles.contactInfoTitle}>Address</Text>
              {resume.basics.location && (
                <View style={styles.contactInfoRowAddress}>
                  <Text>
                    {resume.basics.location?.address}
                  </Text>
                  <Text>
                    {resume.basics.location?.city}
                  </Text>
                </View>
              )}
              {resume.basics.phone_numbers?.length && resume.basics.phone_numbers?.length > 0 && (
                <View style={styles.contactInfoRowAddress}>
                  {resume.basics.phone_numbers.map((phone, index) => (
                    <View key={index} style={styles.contactInfoRow}>
                      <Text>{`${index === 0 ? 'Mobile phone:' : `Mobile phone ${index + 1}:`}`}</Text>
                      <Text style={styles.headerTextSmall}>{phone}</Text>
                    </View>
                  ))}
                </View>
              )}
              <View>
                {resume.basics.emails.map((email, index) => (
                  <View key={index} style={styles.contactInfoRowAddress}>
                    <Text style={styles.contactInfoTitle}>{index === 0 ? 'School email' : 'Personal email'}</Text>
                    <Text style={styles.headerTextSmall}>{email}</Text>
                  </View>
                ))}
              </View>
              <Text style={styles.contactInfoTitle}>Personal webpage</Text>
              <View>
                <Link href={`https://www.${resume.basics.url && (trimUrlPrefix(resume.basics.url))}`} style={styles.headerTextSmall}>
                  <Text>{resume.basics.url && (trimUrlPrefix(resume.basics.url))}</Text>
                </Link>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default BocconiTemplate;
