import React from 'react';
import { Input, Select } from '@careeros/coco';
import { RangeSlider } from '@/components/range-slider/range-slider';
import './resume-student-filters.scss';
import { CohortsNavigation } from '../cohorts-navigation/cohorts-navigation';
import { CohortLinkDataType } from '../../types';

interface StudentFiltersProps {
  cohortsWithAll: CohortLinkDataType[];
  scoreRange: [number, number];
  searchValue: string;
  selectedStatusLabel: string;
  statusOptions: string[];
  onSearchChange: (value: string) => void;
  onStatusChange: (value: string) => void;
  onScoreRangeChange: (values: [number, number]) => void;
}

export const StudentFilters: React.FC<StudentFiltersProps> = ({
  cohortsWithAll,
  scoreRange,
  searchValue,
  selectedStatusLabel,
  statusOptions,
  onSearchChange,
  onStatusChange,
  onScoreRangeChange,
}) => (
  <div className="resume-student-filters">
    <div className="resume-student-filters__search">
      <Input
        icon="bi bi-search"
        value={searchValue}
        id="search-students"
        label=""
        placeholder="Search Students by Name or Email"
        handleValueChange={onSearchChange}
      />
    </div>
    <CohortsNavigation
      links={cohortsWithAll}
      defaultLinkName="All Cohorts"
    />
    <div className="resume-student-filters__status">
      <Select
        id="review-status"
        value={selectedStatusLabel}
        options={statusOptions}
        placeholder="Review Status"
        handleValueChange={onStatusChange}
        showIcon={false}
      />
    </div>
    <div className="resume-student-filters__score">
      <RangeSlider
        label="Base Resume Score"
        min={0}
        max={100}
        defaultMinValue={scoreRange[0]}
        defaultMaxValue={scoreRange[1]}
        width="full"
        onChange={onScoreRangeChange}
      />
    </div>
  </div>
);
