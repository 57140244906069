import { Button } from '@careeros/coco';
import { useQueryClient } from 'react-query';
import { useMemo, useState } from 'react';
import { NoteInput } from '@/components/note-input/note-input';
import './student-notes-tab.scss';
import NoteCard from '@/components/note-card/note-card';
import { CAStudentNote } from '@/domains/core/advisor/types';
import { addCAStudentNote, deleteCAStudentNote, editCAStudentNote } from '@/domains/core/advisor/api/note';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  studentId: string;
  notes: CAStudentNote[];
};

export const StudentNotesTab = ({
  studentId,
  notes,
}: Props) => {
  const [showNoteInput, setShowNoteInput] = useState(false);
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const sortedNotes = useMemo(() => notes.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()), [notes]);

  const handleAddNote = async (title: string, content: string, successCallback?: () => void) => {
    await addCAStudentNote(studentId, { title, content });

    queryClient.invalidateQueries(['ca-student-notes', studentId]);
    successCallback?.();
    setShowNoteInput(false);
    trackEvent('CA Note added');
  };

  const handleEditNote = async (id: string, title: string, content: string) => {
    await editCAStudentNote(studentId, id, { title, content });

    queryClient.invalidateQueries(['ca-student-notes', studentId]);
    trackEvent('CA Note edited');
  };

  const handleDeleteNote = async (id: string) => {
    await deleteCAStudentNote(studentId, id);

    queryClient.invalidateQueries(['ca-student-notes', studentId]);
    trackEvent('CA Note deleted');
  };

  const handleAddNoteButtonClicked = () => {
    setShowNoteInput(true);
  };

  const handleCancelButtonClicked = () => {
    setShowNoteInput(false);
  };

  return (
    <div className="student-notes-tab">
      {!showNoteInput && sortedNotes.length > 0 && (
        <Button
          label="Add Note"
          mode="primary"
          outlined
          size="medium"
          icon="bi bi-plus-circle"
          handleClick={handleAddNoteButtonClicked}
        />
      )}
      {(showNoteInput || sortedNotes.length === 0) && (
        <NoteInput
          handleSave={handleAddNote}
          saveSilently={handleAddNote}
          parentID={studentId}
          handleCancel={handleCancelButtonClicked}
        />
      )}
      {sortedNotes.map((note: CAStudentNote) => (
        <NoteCard
          key={note.id}
          parentID={studentId}
          note={note}
          handleEdit={handleEditNote}
          handleDelete={handleDeleteNote}
        />
      ))}
    </div>
  );
};
