import { useEffect, useState } from 'react';
import './tile-industry.scss';

type Props = {
  icon: string;
  label: string;
};

export const TileIndustry = ({ icon, label }: Props) => {
  const [image, setImage] = useState('');
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../../../../assets/images/icons/industries/${icon}.png`);
        setImage(response.default);
      } catch (err) {
        console.log(err);
      }
    };

    fetchImage();
  }, [icon]);
  return (
    <div className="tile-industry">
      <div
        className="tile-industry__content"
      >
        {image && <img className="tile-industry__icon" src={image} alt={label} />}
        <span className="tile-industry__label">{label}</span>
      </div>
    </div>
  );
};
