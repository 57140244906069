import TallyEmailPreview from '@/assets/images/tally-email-preview.png';
import './tally-info-empty-sneak-peek.scss';

export const TallyInfoEmptySneakPeak = () => (
  <div className="tally-info-empty-sneak-peek">
    <div className="tally-info-empty-sneak-peek__top">
      <div className="tally-info-empty-sneak-peek__icon">
        <i className="bi bi-envelope-fill" />
      </div>
      <div className="tally-info-empty-sneak-peek__container">
        <div className="tally-info-empty-sneak-peek__title">Preview of Email</div>
        <div className="tally-info-empty-sneak-peek__subtitle">A quick peek of what the reminder email will look like</div>
      </div>
    </div>
    <div className="tally-info-empty-sneak-peek__separator" />
    <div className="tally-info-empty-sneak-peek__image">
      <img src={TallyEmailPreview} alt="tally-email-preview" />
    </div>
  </div>
);
