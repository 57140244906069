import { Button } from '@careeros/coco';

type TopbarHelpButtonProps = {
  onClick?: () => void;
};

export const TopbarHelpButton = ({ onClick }: TopbarHelpButtonProps) => (
  <Button
    label="Help"
    mode="primary"
    size="small"
    outlined
    handleClick={onClick}
  />
);
