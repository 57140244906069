import { useRef, useState } from 'react';
import { OBTilePreview } from '../OB-tile-preview/OB-tile-preview';
import './OB-tile-name-with-preview.scss';
import { PopupBoxWrapper } from '../../popup-box/popup-box';
import { OBTilePreviewDataType } from '@/domains/core/company/types';
import { OBTilePreviewSkeleton } from '../OB-tile-preview-skeleton/OB-tile-preview-skeleton';

type Props = {
  previewData: OBTilePreviewDataType | null;
  name: string;
  industry: string;
  companyName?: string;
  onNameClick: () => void;
  onCompanyNameClick?: () => void;
  onNameHover: () => void;
  onExpandClick: () => void;
};

export const OBTileNameWithPreview = ({
  previewData,
  name,
  industry,
  companyName,
  onNameClick,
  onNameHover,
  onExpandClick,
  onCompanyNameClick,
}: Props) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    setDelayHandler(setTimeout(() => {
      onNameHover();
      setIsPreviewOpen(true);
    }, 100));
  };

  const handleMouseLeave = () => {
    setIsPreviewOpen(false);

    if (delayHandler) {
      clearTimeout(delayHandler);
    }
  };

  return (
    <div
      className="OB-tile-name-with-preview"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span ref={titleRef} onClick={onNameClick} className="OB-tile-name-with-preview__name">
        {name}
      </span>

      {isPreviewOpen && (
        <div className="OB-tile-name-with-preview">
          <PopupBoxWrapper parentRef={titleRef}>
            <div onClick={(e) => e.stopPropagation()} className="OB-tile-name-with-preview__preview">
              {previewData && (
                <OBTilePreview
                  tags={previewData.tags || []}
                  title={previewData.name}
                  description={previewData.description}
                  location={previewData.location}
                  expandButtonLabel="View full company description"
                  onExpandButtonClick={onExpandClick}
                  date={previewData.date}
                  industry={industry}
                  companyName={companyName}
                  onCompanyNameClick={onCompanyNameClick}
                />
              )}
              {!previewData && (
                <OBTilePreviewSkeleton />
              )}
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};
