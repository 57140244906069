import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useContext } from 'react';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { ContactPreviewCard } from '@/domains/core/contact/components/contact-preview-card/contact-preview-card';
import { PreviewCardWrapper } from '../../../../generic/preview-card/preview-card-wrapper/preview-card-wrapper';
import { ContactKanbanTile } from '../../../../generic/kanban/contact-tile/contact-tile';
import { mapSavedContactToContactPreviewCard } from '@/domains/core/contact/utils/contact-to-preview-parser';
import { ContactApiStatus, ContactWithNetworkingData } from '@/domains/core/contact/types';
import { getCommunicationChannel } from '../../utils/message';
import { splitAndCapitalize } from '@/services/helpers/string';

type Props = {
  contact: ContactWithNetworkingData;
  handleContactArchive?: (contactId: string, currentStatus: ContactApiStatus, shouldArchive: boolean) => void;
  schoolName: string;
};

export const ContactKanbanTileWithPreview = ({
  contact,
  handleContactArchive,
  schoolName,
}: Props) => {
  const contactPreviewCardData = useMemo(() => mapSavedContactToContactPreviewCard(contact), [contact]);
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const navigate = useNavigate();
  const lastMessage = useMemo(() => (
    contact.last_message
      ? { text: contact.last_message?.text || contact.last_message?.subject || '', type: getCommunicationChannel(contact.last_message?.type, contact.email) }
      : undefined
  ), [contact]);

  const goToCampusChampionsPage = useCallback(() => {
    navigate('/app/contacts/campus-champions');
  }, [navigate]);

  const goToContact = useCallback((isProfileOpen?: boolean) => {
    if (isProfileOpen) {
      navigate(`/app/inbox/${contact.id}?open-sidebar=true`);
    } else {
      navigate(`/app/inbox/${contact.id}?view=${getCommunicationChannel(contact.last_message?.type, contact.email)}`);
    }
  }, [contact.id]);

  const goToCompany = useCallback(() => {
    openTracker(contact.current_position.company_id);
  }, [contact.current_position.company_id]);

  return (
    <ContactKanbanTile
      image={contact.career_summary?.basics?.image || ''}
      name={contact.career_summary?.basics?.name || ''}
      companyName={contact.current_position.company || ''}
      position={contact.current_position.title || ''}
      linkedInConnectionStatus={contact.linkedin_connection_status || 'notConnected'}
      handleTileClick={goToContact}
      message={lastMessage}
      isArchived={contact.kanban_position.status === 'archive'}
      handleArchiveToggle={(shouldArchive: boolean) => handleContactArchive?.(contact.id, contact.kanban_position.status, shouldArchive)}
      handleCompanyClick={goToCompany}
      companyLogo={contact.current_position.company_logo_url}
      networkingStatusRate={contact.match_criteria?.includes('desired_job') ? 1 : 0}
      isAlumni={contact.match_criteria?.includes('alumni')}
      showLanguages={contact.match_criteria?.includes('languages') && !!contact.matched_languages}
      isCampusChampion={contact.is_campus_champion}
      languages={splitAndCapitalize(contact.matched_languages || '')}
      schoolName={schoolName || 'your school'}
      campusChampionClickHandler={goToCampusChampionsPage}
      withArchiveToggle
    >
      <ContactKanbanTile.NameWithPreview>
        <PreviewCardWrapper
          name={contactPreviewCardData.title}
          popupFitToScreenHeight
          className="contact-kanban-tile__name-text"
        >
          <PreviewCardWrapper.Preview>
            <ContactPreviewCard
              title={contactPreviewCardData.title}
              expandButtonLabel="See full profile"
              onExpandButtonClick={() => goToContact(true)}
              currentPosition={contactPreviewCardData.currentPosition}
              previousPositions={contactPreviewCardData.previousPositions}
              headline={contactPreviewCardData.headline}
              networkingStatus={contactPreviewCardData.networkingStatus}
              linkedInConnectionStatus={contactPreviewCardData.linkedInConnectionStatus}
              languages={contactPreviewCardData.languages}
              onCurrentCompanyClick={goToCompany}
            />
          </PreviewCardWrapper.Preview>
        </PreviewCardWrapper>
      </ContactKanbanTile.NameWithPreview>
    </ContactKanbanTile>
  );
};
