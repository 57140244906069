import { ChipTypes } from '@careeros/coco';
import { CompaniesFilterType, CompanyLocationWithName, FilterTagTypes } from '../../types/companies-filtering';
import { CompanyLocation, CompanyOfficeLocation } from '../../types/company';
import { formatRange } from '@/services/helpers/company';

export const defaultCompaniesFilters: CompaniesFilterType = {
  industry: [],
  sizes: [],
  tags: [],
};

export const newTagType: (fName: FilterTagTypes) => ChipTypes = (filterName) => {
  switch (filterName) {
    case 'industry':
      return 'industry';
    case 'location':
      return 'location';
    case 'tags':
      return 'tag';
    case 'sizes':
      return 'size';
    default:
      return 'industry';
  }
};

export const industries = [
  'Consulting',
  'Banking',
  'Financial Services',
  'Investment Firm',
  'Tech',
  'Social Impact',
  'Travel \u0026 Hospitality',
  'Professional Services',
  'FMCG, Food \u0026 Retail',
  'Industrials \u0026 Manufacturing',
  'Sports \u0026 Fashion',
  'Media \u0026 Entertainment',
  'Healthcare',
  'Energy \u0026 Utilities',
  'Transport \u0026 Logistics',
  'Other',
];

const sortIndustriesWithOtherLast = (industriesArray: string[]) => industriesArray.sort((a, b) => {
  if (a === 'Other') return 1;
  if (b === 'Other') return -1;
  return a.localeCompare(b);
});

export const sortedIndustries = sortIndustriesWithOtherLast(industries);

export function stringifyCompanyLocation(location: CompanyLocation | CompanyOfficeLocation) {
  return { name: location.city ? `${location.city}, ${location.country_name}` : `${location.country_name} (all cities)`, value: location };
}

function convertCompanySize(size: string) {
  if (size === '0-1') {
    return { name: '0-1 employee', value: size };
  }
  return { name: `${formatRange(size)} employees`, value: size };
}

function getLocationFromSearchParams(searchParams: URLSearchParams) {
  try {
    const parsedLocationsFromParams = JSON.parse(searchParams.get('location') || '');

    if (!Array.isArray(parsedLocationsFromParams)) {
      return [];
    }

    const locationsFromParams = JSON.parse(searchParams.get('location') || '')
      .filter((loc: CompanyLocation) => loc.city && loc.country_name)
      .map(stringifyCompanyLocation) || [];

    return locationsFromParams;
  } catch (error) {
    return [];
  }
}

export function parseSearchParamsAndGetFilters(searchParams: URLSearchParams, sizes: string[], tags: string[]) {
  let parsedIndustriesFromParams: string[] = [];
  let parsedTagsFromParams: string[] = [];
  let parsedSizesFromParams: string[] = [];

  try {
    parsedIndustriesFromParams = Array.isArray(JSON.parse(searchParams.get('industry') || '[]'))
      ? JSON.parse(searchParams.get('industry') || '[]')
      : [];
  } catch (error) {
    parsedIndustriesFromParams = [];
  }
  try {
    parsedTagsFromParams = Array.isArray(JSON.parse(searchParams.get('tags') || '[]'))
      ? JSON.parse(searchParams.get('tags') || '[]')
      : [];
  } catch (error) {
    parsedTagsFromParams = [];
  }
  try {
    parsedSizesFromParams = Array.isArray(JSON.parse(searchParams.get('sizes') || '[]'))
      ? JSON.parse(searchParams.get('sizes') || '[]')
      : [];
  } catch (error) {
    parsedSizesFromParams = [];
  }

  const selectedFiltersFromParams: CompaniesFilterType = {
    industry: parsedIndustriesFromParams
      .filter((industry: string) => industries.includes(industry))
      .map((industry: string) => ({ name: industry, value: industry }))
      || [],
    tags: parsedTagsFromParams
      .filter((tag: string) => tags.includes(tag))
      .map((tag: string) => ({ name: tag, value: tag }))
      || [],
    sizes: parsedSizesFromParams
      .filter((size: string) => sizes.includes(size))
      .map((size: string) => convertCompanySize(size))
      || [],
  };

  const savedByUser = searchParams.get('saved_by_user') === 'true';
  const locationsFromParams = getLocationFromSearchParams(searchParams);

  return {
    savedByUser,
    filters: selectedFiltersFromParams,
    locations: locationsFromParams,
  };
}

export function parseLocationFiltersToSearchParams(selectedLocations: CompanyLocationWithName[]) {
  return JSON.stringify(selectedLocations.flatMap((loc) => loc.value));
}

export function sortCompanyLocations(locationA: CompanyLocation, locationB: CompanyLocation) {
  if (!locationA.city && locationB.city) {
    return -1;
  }

  if (locationA.city && !locationB.city) {
    return 1;
  }
  return locationA.country_name.localeCompare(locationB.country_name) || locationA.city.localeCompare(locationB.city);
}

function sortCompanySizes(sizeA: string, sizeB: string) {
  return parseInt(sizeA, 10) - parseInt(sizeB, 10);
}

export function mapAndSortCompanySizes(sizes: string[] = []) {
  if (!Array.isArray(sizes)) {
    return [];
  }

  return sizes.sort(sortCompanySizes).map(convertCompanySize);
}
