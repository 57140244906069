import { useQuery } from 'react-query';
import {
  getJobByID, getJobByIDPrivate, getJobsByCompany, searchJobs,
} from '@/services/api/job';
import { JobFeedJob } from '@/domains/core/job/types/job';

export const useJobsByCompany = (companyID: string, options = {}) => useQuery(['jobs-by-company', companyID], async () => {
  const result = await getJobsByCompany(companyID);

  return result && Array.isArray(result) ? result as JobFeedJob[] : [];
}, {
  retry: 0,
  // should be refetched in the background every 24 hours
  staleTime: 1000 * 60 * 60 * 24,
  ...options,
});

export const useGetSingleJobById = (jobId: string, options = {}) => useQuery(['job', jobId], async () => {
  const result = await getJobByID(jobId);

  return result;
}, {
  retry: 0,
  ...options,
});

export const useGetJobByIdPrivate = (jobId: string, options = {}) => useQuery(['job', jobId], async () => {
  const result = await getJobByIDPrivate(jobId);

  return result;
}, {
  retry: 0,
  staleTime: 1000 * 60 * 60 * 24,
  ...options,
});

export const useGetSavedJobs = (options = {}) => useQuery(['saved-jobs'], async () => {
  const result = await searchJobs('', 1, true);

  return result;
}, {
  ...options,
});
