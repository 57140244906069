import { IconButton, Tooltip, DefaultLogo } from '@careeros/coco';
import './campus-champion.scss';

type Props = {
  id: string;
  name: string;
  position: string;
  image: string;
  handleMainButtonAction: (id: string) => void;
  handleSecondaryButtonAction: (id: string) => void;
};

export const CampusChampion = ({
  id,
  name,
  position,
  image,
  handleMainButtonAction,
  handleSecondaryButtonAction,
}: Props) => (
  <div className="campus-champion-tile">
    <div className="campus-champion-tile__main-info">
      <div className="campus-champion-tile__avatar-container">
        <div className="campus-champion-tile__avatar">
          <DefaultLogo
            source={image}
            name={name}
            className="campus-champion-tile__avatar-image"
            size={72}
            type="contact"
          />
        </div>
      </div>

      <div className="campus-champion-tile__description">
        <div className="campus-champion-tile__name">
          <span>
            {name}
          </span>
          <span className="campus-champion-tile__networking-status">
            <Tooltip position="top" label="Campus Champion">
              <div>
                <span>⭐</span>
              </div>
            </Tooltip>
          </span>
        </div>
        <div className="campus-champion-tile__position">
          {position}
        </div>
      </div>
    </div>
    <div className="campus-champion-tile__actions">
      <Tooltip label="Save Contact" withArrow position="top">
        <IconButton
          mode="rounded"
          size="small"
          onClick={() => handleSecondaryButtonAction(id)}
          outlined
          icon="bi bi-bookmark-heart"
        />
      </Tooltip>
      <Tooltip label="Message" withArrow position="top">
        <IconButton
          mode="rounded"
          size="small"
          onClick={() => handleMainButtonAction(id)}
          outlined
          icon="bi bi-chat-text"
        />
      </Tooltip>
    </div>
  </div>
);
