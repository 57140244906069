import {
  forwardRef, useImperativeHandle, useRef, useState,
} from 'react';
import { Input } from '@careeros/coco';
import { DropdownSearchStatic } from '@/components/dropdown-search-static/dropdown-search-static';
import { CADropdownOption } from '../../types';
import './request-resume-review-modal.scss';

type Props = {
  careerAdvisors: CADropdownOption[];
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
};

const RequestResumeReviewForm = ({
  careerAdvisors,
  onSubmit,
}: Props, ref: React.Ref<any>) => {
  const [comment, setComment] = useState('');
  const [selectedAdvisor, setSelectedAdvisor] = useState<CADropdownOption>();
  const formRef = useRef<HTMLFormElement>(null);
  useImperativeHandle(ref, () => ({
    externalSubmit() {
      if (formRef.current) {
        formRef.current.requestSubmit(); // Call native form submit
      }
    },
  }), []);

  const handleAdvisorSelect = (careerAdvisorId: string) => {
    const careerAdvisor = careerAdvisors.find((ca) => ca.id === careerAdvisorId);
    setSelectedAdvisor(careerAdvisor);
  };

  const handleCommentChange = (value: string) => {
    setComment(value);
  };

  return (
    <form className="request-resume-review-modal" onSubmit={onSubmit} ref={formRef}>
      <p className="request-resume-review-modal__title">
        Your Career Advisor will receive an email notification to review your
        resume.
      </p>
      <DropdownSearchStatic
        id="career_advisor_name"
        label="Career Advisor"
        selectedOption={selectedAdvisor?.name || ''}
        options={careerAdvisors}
        handleItemSelect={(_item, value) => handleAdvisorSelect(value)}
        placeholder="Type to search..."
        name="career_advisor_name"
        size="medium"
        canAddItems={false}
        showIcon
        withMultipleSelection
        openOnClick
        listWidth="full"
      />
      <input
        id="career_advisor_id"
        name="career_advisor_id"
        value={selectedAdvisor?.id || ''}
        type="hidden"
      />
      <Input
        id="comment"
        value={comment}
        label="Comment (optional)"
        placeholder="Type..."
        height="auto"
        handleValueChange={(value) => handleCommentChange(value)}
      />
    </form>
  );
};

export const RequestResumeReviewModal = forwardRef(RequestResumeReviewForm);
