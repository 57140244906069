import { TagColors, TagColorsType } from '@/components/tag/tag';
import { ResumeStatus } from '../types';

type ResumeStatusConfig = {
  label: string;
  tooltip: string;
  color: TagColorsType;
  handleClick?: () => void;
};

export const getResumeStatuses = (requestReviewClick?: () => void): Record<ResumeStatus, ResumeStatusConfig> => ({
  READY_FOR_REVIEW: {
    label: 'Ready to Submit',
    tooltip: 'Submit your Resume for Review',
    color: TagColors.BlueGreen,
    handleClick: requestReviewClick ? () => requestReviewClick() : undefined,
  },
  APPROVED: {
    label: 'Approved',
    tooltip: 'Resume is ready for applications',
    color: TagColors.Green,
  },
  REVIEW_REQUESTED: {
    label: 'Submitted for Review',
    tooltip: 'Waiting for advisor feedback',
    color: TagColors.Orange,
  },
});

export const getResumeStatusesForAdvisors = (): Record<ResumeStatus, ResumeStatusConfig> => ({
  READY_FOR_REVIEW: {
    label: 'Ready to Submit',
    tooltip: 'Student can submit Resume for Review',
    color: TagColors.BlueGreen,
  },
  APPROVED: {
    label: 'Approved',
    tooltip: 'Resume is ready for applications',
    color: TagColors.Green,
  },
  REVIEW_REQUESTED: {
    label: 'Submitted for Review',
    tooltip: 'Waiting for advisor feedback',
    color: TagColors.Orange,
  },
});

export const getResumeStatusesForTable = (): Record<ResumeStatus | string, { label: string; color: TagColors }> => ({
  REVIEW_REQUESTED: {
    label: 'Submitted for Review',
    color: TagColors.Orange,
  },
  READY_FOR_REVIEW: {
    label: 'Ready to Submit',
    color: TagColors.BlueGreen,
  },
  APPROVED: {
    label: 'Approved',
    color: TagColors.Green,
  },
  RESUME_INVALID: {
    label: 'No Requests',
    color: TagColors.Grey,
  },
});
