import './suggested-contacts-container.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { SuggestedContactsEmpty } from '../suggested-contacts-empty/suggested-contacts-empty';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import { ContactTileCard } from '../contact-tile-card/contact-tile-card';
import { useSuggestedContacts } from '@/services/queries/contact';
import { Loader } from '@/components/loader/loader';

import type { SuggestedContactType } from '@/domains/core/contact/types/contact';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { splitAndCapitalize } from '@/services/helpers/string';
import { useAlgoliaInsights } from '@/services/hooks/use-algolia';
import { saveContact } from '@/services/api/contact';

export const SuggestedContactsContainer = () => {
  const { data: suggestedContacts, isLoading } = useSuggestedContacts();
  const { data: selfUniversity, isLoading: isSelfLoading } = useSelfUniversity();
  const { data: self } = useSelf();
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const insights = useAlgoliaInsights();

  const navigate = useNavigate();

  const revalidateSuggestedContacts = () => {
    queryClient.invalidateQueries('contacts-no-cache');
    queryClient.invalidateQueries('gamification-actions');
    queryClient.invalidateQueries('challenges');
    queryClient.invalidateQueries('xp');
    queryClient.invalidateQueries('leaderboards');
    queryClient.invalidateQueries('company-saved-contacts');
    queryClient.invalidateQueries(['suggested-contacts']);
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('quests');
  };

  const handleReachOut = async (id: string) => {
    const suggestedContact = suggestedContacts?.find((contact: SuggestedContactType) => contact.contact_id === id);

    if (!suggestedContact) {
      return;
    }

    await saveContact(suggestedContact, suggestedContact.company_id);
    revalidateSuggestedContacts();
    trackEvent('Saved Contact', {}, {
      source: 'Suggested Contacts Homepage',
      type: 'Reach out',
    });

    if (self?.id) {
      insights.purchasedObjectIDs(
        'Contact Saved',
        'contact',
        [suggestedContact.contact_id],
        self.id,
      );
    }
    navigate(`/app/inbox/${suggestedContact.contact_id}`);
  };

  const handleSave = async (id: string) => {
    const suggestedContact = suggestedContacts?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;

    await saveContact(suggestedContact, suggestedContact.company_id);
    revalidateSuggestedContacts();
    trackEvent('Saved Contact', {}, {
      source: 'Suggested Contacts Homepage',
      type: 'Save',
    });
  };

  if (isLoading || isSelfLoading) {
    return (
      <div className="suggested-contacts-loading">
        <Loader />
      </div>
    );
  }
  return (
    <div className="suggested-contacts-container">
      <div className="suggested-contacts-container__contacts">
        <div className="suggested-contacts-container__header">
          <h3 className="suggested-contacts-container__title">
            Suggested Contacts
          </h3>
          <TooltipIcon
            tooltipLabel="We found these based on your profile, matching the university, your desired job role and the languages you speak"
            tooltipPosition="top"
          />
        </div>
        {!self?.has_completed_demographics_form && self?.role === 'student' && (
        <p className="suggested-contacts-container__description">
          Want better results? Fill out our
          {' '}
          <Link to="/app/onboarding?show_navigation=true">personalization form</Link>
        </p>
        )}
        {Array.isArray(suggestedContacts) && suggestedContacts.length > 0 ? (
          <div className="suggested-contacts-container__scroll-container">
            <div className="suggested-contacts-container__list">
              {Array.isArray(suggestedContacts) && suggestedContacts.map((suggestedContact: SuggestedContactType) => (
                <ContactTileCard
                  key={suggestedContact.contact_id}
                  id={suggestedContact.contact_id}
                  name={`${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`}
                  position={suggestedContact.contact.current_position.title}
                  image={suggestedContact.contact.career_summary?.basics?.image}
                  languages={splitAndCapitalize(suggestedContact.matched_languages || '')}
                  showLanguages={suggestedContact.match_criteria.includes('languages')}
                  isAlumni={suggestedContact.match_criteria.includes('alumni')}
                  companyLogo={suggestedContact.contact.current_position.company_logo_url}
                  companyName={suggestedContact.contact.current_position.company}
                  schoolName={selfUniversity?.name || ''}
                  networkingStatusRate={suggestedContact.match_criteria.includes('desired_job') ? 1 : 0}
                  handleMainButtonAction={(id) => handleReachOut(id)}
                  handleSecondaryButtonAction={(id) => handleSave(id)}
                />
              ))}
            </div>
          </div>
        ) : <SuggestedContactsEmpty />}
      </div>
    </div>
  );
};
