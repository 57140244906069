// TODO: This should be enum
const applicationStatuses = {
  Saved: 'saved',
  Networking: 'networking',
  Applying: 'applying',
  Applied: 'applied',
  Interviewing: 'interviewing',
  Offer: 'offer',
  Archived: 'archive',
  Rejected: 'reject',
} as const;

const flip = (data: typeof applicationStatuses) => Object.fromEntries(
  Object
    .entries(data)
    .map(([key, value]) => [value, key]),
);

const applicationAPIstatuses = flip(applicationStatuses);
export type ApplicationStatus = keyof typeof applicationStatuses;
const applicationActiveStatuses = Object.keys(applicationStatuses).filter((status) => status !== 'Archived') as ApplicationStatus[];
export type ApplicationStatusAPI = (typeof applicationStatuses)[keyof typeof applicationStatuses];

export {
  applicationStatuses as statuses, applicationAPIstatuses as APIstatuses, applicationActiveStatuses as activeStatuses,
};
