import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { FilterOption } from '@/domains/core/company/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './multi-select.scss';
import { DropdownSelect } from '../select-dropdown/select-dropdown';

type Props = {
  options: FilterOption[];
  placeholder: string;
  handleSelectedOptions: (options: FilterOption[]) => void;
  selected: FilterOption[];
  withSelectAllOption?: boolean;
  size?: 'small' | 'medium' | 'large' | 'full';
  labelType: 'list' | 'number' | 'filterAndOptions' | 'placeholder';
  width?: 'full' | 'fit-box';
  dropdownPosition?: 'left' | 'right';
  label?: string;
  hint?: string;
  isInvalid?: boolean;
  warningMessage?: string;
  icon?: string;
  showIcon?: boolean;
  textSize?: 'large';
  isDisabled?: boolean;
};

export const MultiSelect = ({
  placeholder,
  options,
  handleSelectedOptions,
  labelType,
  selected,
  size = 'medium',
  withSelectAllOption = false,
  width = 'full',
  label,
  hint,
  isInvalid,
  warningMessage,
  icon,
  showIcon,
  textSize,
  dropdownPosition = 'left',
  isDisabled = false,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { user } = useAuth0();
  const [selectedOptions, setSelectedOptions] = useState<FilterOption[]>([]);
  const [isListOpened, setIsListOpened] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const handleFilterClick = () => {
    setIsListOpened(!isListOpened);

    trackEvent(`${placeholder} Filter clicked`, user);
  };

  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      handleSelectedOptions([]);
      setSelectedOptions([]);
    } else {
      handleSelectedOptions(options);
      setSelectedOptions(options);
    }
  };

  const handleItemSelect = (option: FilterOption) => {
    let newSelectedOptions: FilterOption[] = [];
    if (selectedOptions.map(o => o.value).includes(option.value)) {
      newSelectedOptions = selectedOptions.filter((item) => item.value !== option.value);
    } else {
      newSelectedOptions = [...selectedOptions, option];
    }
    handleSelectedOptions(newSelectedOptions);
    setSelectedOptions(newSelectedOptions);
  };

  const handleBlur = (e: any) => {
    if (!container.current?.contains(e.target)) {
      setIsListOpened(false);
    }
  };

  const renderSwitchLabel = () => {
    switch (labelType) {
      case 'number':
        return (
          <>
            {placeholder}
            <span className="multi-select__number">{selectedOptions.length}</span>
          </>
        );
      case 'filterAndOptions':
        return `${placeholder} (${selectedOptions.map(o => o.name).join(', ')})`;
      case 'placeholder':
        return placeholder;
      case 'list':
      default:
        return selectedOptions.map(o => o.name).join(', ');
    }
  };

  useEffect(() => {
    setSelectedOptions(selected);
  }, [selected.length]);

  useEffect(() => {
    document.addEventListener('mousedown', handleBlur);

    return () => document.removeEventListener('mousedown', handleBlur);
  }, []);

  return (
    <div className={`multi-select multi-select--${textSize} ${isDisabled ? 'multi-select--disabled' : ''}`}>
      {label && (
        <div className="multi-select__label">
          <span className="multi-select__label-text">{label}</span>
        </div>
      )}
      <div className={`multi-select__input multi-select__input--${size}`} ref={container}>
        <div
          data-testid="multi-select-field"
          className={
            `multi-select__field ${
              (!selectedOptions.length || labelType === 'placeholder') ? 'multi-select__field--placeholder' : ''
            } ${
              isListOpened ? 'multi-select__field--focused' : ''
            } ${isInvalid ? 'multi-select__field--invalid' : ''
            } ${
              isDisabled ? 'multi-select__field--disabled' : ''}`
          }
          onClick={handleFilterClick}
        >
          <span className="multi-select__name">{selectedOptions.length ? renderSwitchLabel() : placeholder}</span>
          <i
            className={`multi-select__icon ${isListOpened ? 'multi-select__icon--opened' : ''} bi bi-caret-down-fill`}
          />
        </div>
        {isListOpened && (
          <div className={`multi-select__dropdown multi-select__dropdown--${dropdownPosition}`}>
            <DropdownSelect
              options={options}
              withSelectAllOption={withSelectAllOption}
              width={width}
              icon={icon}
              showIcon={showIcon}
              handleSelectAll={handleSelectAll}
              handleItemSelect={handleItemSelect}
              selectedOptions={selectedOptions}
            />
          </div>
        )}
        {isInvalid && <span className="multi-select__warning">{warningMessage}</span>}
      </div>
      {hint && (
        <div className="multi-select__hint">
          <span className="multi-select__hint-text">{hint}</span>
        </div>
      )}
    </div>
  );
};
