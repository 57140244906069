import { DropdownListOption } from '@careeros/coco/dist/src/components/DropdownList/DropdownList';
import { ApiStatus } from '../company/types';
import { trackerTabs } from './content';

export const getApplicationActionsByStatus = (
  status: ApiStatus,
  openTrackerTab: (tab: (typeof trackerTabs)[number], additionalParams?: { [key: string]: string }) => void,
  handleArchive: () => void,
  handleRejected: () => void,
  type: 'company' | 'job' = 'company',
): DropdownListOption[] => {
  const withRejectedActions: DropdownListOption[] = ['applied', 'interviewing', 'offer'].includes(status) ? [
    {
      label: 'Move to Rejected',
      icon: 'bi bi-x',
      isDanger: true,
      onClick: handleRejected,
    },
  ] : [];
  const withEditDeadlineActions: DropdownListOption[] = (type === 'job' && ['saved', 'networking', 'applying'].includes(status)) ? [
    {
      label: 'Edit Deadline',
      icon: 'bi bi-send-plus',
      onClick: () => openTrackerTab('Jobs'),
    },
  ] : [];
  const withShowTipsActions: DropdownListOption[] = status !== 'reject' ? [
    {
      label: 'Show Tips',
      icon: 'bi bi-lightbulb',
      onClick: () => openTrackerTab('Tips & Tricks', { 'show-tips': 'true' }),
    },
  ] : [];
  const withSaveJobActions: DropdownListOption[] = [{
    label: type === 'company' ? 'Save a Job' : 'Save another Job',
    icon: 'bi bi-briefcase',
    onClick: () => openTrackerTab('Jobs'),
  }];

  const defaultActions: DropdownListOption[] = [
    ...withShowTipsActions,
    {
      label: 'Save a Contact',
      icon: 'bi bi-person-add',
      onClick: () => openTrackerTab('Contacts'),
    },
    ...withSaveJobActions,
    {
      label: 'See My Notes',
      icon: 'bi bi-sticky',
      onClick: () => openTrackerTab('Notes'),
    },
    ...withEditDeadlineActions,
    ...withRejectedActions,
    {
      label: type === 'company' ? 'Archive Company' : 'Archive Job',
      icon: 'bi bi-archive',
      isDanger: true,
      onClick: handleArchive,
    },
  ];

  return defaultActions;
};

const statusesForCompanyInsights: ApiStatus[] = ['saved'];
const statusesForCreateResume: ApiStatus[] = ['saved', 'networking', 'applying'];
const statusesForReachOut: ApiStatus[] = ['networking', 'applying', 'applied', 'interviewing', 'offer'];
const statusesForLearnRoles: ApiStatus[] = ['networking', 'applying'];
const statusesForPrepareForInterview: ApiStatus[] = ['interviewing', 'applied'];
const statusesForPrepareForCoffee: ApiStatus[] = ['networking', 'applying'];

export const getApplicationAIActionsByStatus = (
  status: ApiStatus,
  openTrackerTab: (tab: (typeof trackerTabs)[number], additionalParams?: { [key: string]: string }) => void,
  navigateToPage: (path: string) => void,
  type: 'company' | 'job' = 'company',
  hasBaseResume: boolean = false,
  jobId?: string,
): DropdownListOption[] => {
  if (status === 'reject') {
    return [];
  }

  const companyInsightsActions: DropdownListOption[] = statusesForCompanyInsights.includes(status) ? [
    {
      label: 'Get Company Insights',
      icon: 'bi bi-lightbulb',
      onClick: () => openTrackerTab('AI Tools', { 'ai-tool': 'Company Insights' }),
    },
  ] : [];

  const resumeActionLabel = type === 'job' && (hasBaseResume && jobId) ? 'Tailor your Resume' : 'Create a Resume';
  const resumeActionOnClick = type === 'job' && (hasBaseResume && jobId) ? () => navigateToPage(`/app/resume-os/tailored-resume?preselected-job-id=${jobId}`) : () => navigateToPage('/app/resume-os');

  const createResumeActions: DropdownListOption[] = statusesForCreateResume.includes(status) ? [
    {
      label: resumeActionLabel,
      icon: 'bi bi-magic',
      onClick: resumeActionOnClick,
    },
  ] : [];

  const reachOutActions: DropdownListOption[] = statusesForReachOut.includes(status) ? [
    {
      label: 'Reach Out to a Contact',
      icon: 'bi bi-send',
      onClick: () => openTrackerTab('Contacts'),
    },
  ] : [];

  const learnRolesActions: DropdownListOption[] = statusesForLearnRoles.includes(status) ? [
    {
      label: type === 'company' ? 'Learn About Roles' : 'Learn About the Role',
      icon: 'bi bi-lightbulb',
      onClick: () => openTrackerTab('AI Tools', { 'ai-tool': 'Learn About Roles' }),
    },
  ] : [];

  const prepareForInterviewActions: DropdownListOption[] = statusesForPrepareForInterview.includes(status) ? [
    {
      label: 'Prepare for Interview',
      icon: 'bi bi-journal-bookmark',
      onClick: () => openTrackerTab('AI Tools', { 'ai-tool': 'Interview Preparation' }),
    },
  ] : [];

  const prepareForCoffeeActions: DropdownListOption[] = statusesForPrepareForCoffee.includes(status) ? [
    {
      label: 'Prepare for Coffee Chat',
      icon: 'bi bi-cup-hot',
      onClick: () => openTrackerTab('AI Tools', { 'ai-tool': 'Questions to ask in a Coffee Chat' }),
    },
  ] : [];

  const defaultActions: DropdownListOption[] = [
    {
      label: 'Show Next Steps',
      icon: 'bi bi-check2-square',
      onClick: () => openTrackerTab('Tips & Tricks'),
    },
    ...companyInsightsActions,
    ...reachOutActions,
    ...learnRolesActions,
    ...prepareForInterviewActions,
    ...prepareForCoffeeActions,
    ...createResumeActions,
  ];

  return defaultActions.map((action) => ({ ...action, iconColor: 'AI' }));
};
