import {
  AiPayloadSchema,
  BaseResumeListingResponse,
  BaseResumeScoreResponse,
  CareerAdvisorsResponse,
  ProfilePictureResponse,
  ResumeBookParams,
  ResumeComment,
  ResumeDownloadResult,
  ResumeResponse,
  ResumeSchema,
  ResumeTemplates,
  TailoredResumeListingResponse,
  ActionVerbsResponse,
} from '@/domains/core/resume/types';
import { http } from './http';
import { ParamsObject } from '@/domains/core/resume/templates/types/resume-templates-types';

export type ErrorObject = {
  error: string;
};

export const uploadBaseResume = async (resume: FormData): Promise<ResumeResponse> => http.post('/resume/base', resume);

export const uploadProfilePicture = async (profileImage: FormData): Promise<ProfilePictureResponse> => http.post('/resume/profile-picture', profileImage);

export const createBaseResume = async (resume: ResumeSchema): Promise<any> => http.post('/resume/base/empty', resume);

export const getBaseResumes = async (): Promise<BaseResumeListingResponse[]> => http.get('/resume/base');

export const duplicateBaseResume = async (id: string): Promise<any> => http.post('/resume/base/copy', { base_resume_id: id });

export const getTailoredResumes = async (): Promise<TailoredResumeListingResponse[]> => http.get('/resume/tailored');

export const getBaseResume = async (id: string): Promise<ResumeResponse> => http.get(`/resume/base/${id}`);

export const getStudentBaseResume = async (studentID: string, resumeID: string): Promise<ResumeResponse> => http.get(`/resume/base/${resumeID}/student/${studentID}`);

export const getStudentTailoredResume = async (studentID: string, resumeID: string): Promise<ResumeResponse> => http.get(`/resume/tailored/${resumeID}/student/${studentID}`);

export const deleteBaseResume = async (id: string): Promise<boolean> => http.delete(`/resume/base/${id}`);

export const getBaseResumeScore = async (resumeID: string, resume: ResumeSchema, scoreParams?: ParamsObject): Promise<BaseResumeScoreResponse> => http.post(`/resume/score/${resumeID}`, {
  ...resume,
  params: scoreParams || {},
});

export const putBaseResume = async (id: string, resume: ResumeSchema, userID: string, resumeTemplate: ResumeTemplates, title: string, isValid: boolean, url?: string, pdfURL?: string): Promise<any> => http.put(`/resume/base/${id}`, {
  resume,
  user_id: userID,
  resume_template: resumeTemplate,
  title: resume.basics?.name || title,
  is_valid: isValid,
  preview_url: url || null,
  source_url: pdfURL || null,
});

export const getBaseResumeAiSuggestions = async (resume: ResumeSchema): Promise<any> => http.patch('/ai/assistant/resume/base', resume);
export const getJobResumeAiSuggestions = async (aiPayload: AiPayloadSchema): Promise<AiPayloadSchema> => http.patch('/ai/assistant/resume/tailored', aiPayload);

// Job Tailored

export const createJobTailoredResume = async (baseResumeID: string, jobID: string): Promise<any> => http.post('/resume/tailored', {
  base_resume_id: baseResumeID,
  job_id: jobID,
});
export const updateJobTailoredResume = async (id: string, resume: ResumeSchema, userID: string, resumeTemplate: ResumeTemplates, title: string, isValid: boolean, url?: string, pdfURL?: string): Promise<any> => http.put(`/resume/tailored/${id}`, {
  resume,
  user_id: userID,
  resume_template: resumeTemplate,
  title: resume.basics?.name || title,
  is_valid: isValid,
  preview_url: url || null,
  source_url: pdfURL || null,
});
export const getTailoredResume = async (id: string): Promise<ResumeResponse> => http.get(`/resume/tailored/${id}`);
export const deleteTailoredResume = async (id: string): Promise<boolean> => http.delete(`/resume/tailored/${id}`);

export const getResumeTemplates = async (): Promise<ResumeTemplates[]> => http.get('/resume/templates');

export const uploadResumeImage = async (image:FormData): Promise<any> => http.post('/resume/preview', image);

export const uploadResumePDF = async (pdf:FormData): Promise<any> => http.post('/resume/source', pdf);

export const getResumeComments = async (resumeID: string): Promise<ResumeComment[]> => http.get(`/resume/${resumeID}/comments`);

export const resolveResumeComment = async (commentID: string, redirectUrl: string): Promise<any> => http.patch(`/resume/comments/${commentID}/resolve`, {
  redirect_url: redirectUrl,
});

export const getActionVerbs = async (): Promise<ActionVerbsResponse> => http.get('/resume/score/action-verbs');

// Resume Book

export const getResumeBook = async (params: ResumeBookParams): Promise<ResumeDownloadResult> => http.post('/resume/resume-book', params);

export const getResumeBookCount = async (params: ResumeBookParams): Promise<{ count: number }> => http.post('/resume/resume-book/count', params);

// Review Request

export const getUniversityAdvisors = async (): Promise<CareerAdvisorsResponse[]> => http.get('/university/career-advisors');

export const requestResumeReview = async (resumeID: string, advisorID: string, comment: string, resumeType: string, reviewURL: string): Promise<any> => http.post('/resume/review', {
  career_advisor_id: advisorID,
  resume_id: resumeID,
  comment,
  resume_type: resumeType,
  review_url: reviewURL,
});

export const approveResumeReview = async (resumeID: string, resume_url: string): Promise<any> => http.post('/resume/review/approve', {
  resume_id: resumeID,
  resume_url,
});

export const checkResumeReviewStatus = async (resumeID: string): Promise<any> => http.post('/resume/review/check', { resume_id: resumeID });
