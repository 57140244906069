import { DefaultLogo } from '@careeros/coco';
import { ContactEmails } from '../contact-emails/contact-emails';

import './info-tile.scss';
import { Contact } from '../../types';

export type ContactInfoBasics = Pick<Contact, 'id' | 'first_name' | 'last_name' | 'email' | 'secondary_email' | 'current_position'> & { image: string };

interface InfoTileProps {
  contact: ContactInfoBasics;
  onEmailChanged: () => void;
  openCompanyTracker: () => void;
}

export const ContactInfoTile = ({
  contact,
  onEmailChanged,
  openCompanyTracker,
}: InfoTileProps) => (
  <div className="contact-page-info">
    <div className="contact-page-info__header">
      <div className="contact-page-info__image">
        <DefaultLogo
          type="contact"
          source={contact.image}
          className=""
          name={`${contact.first_name} ${contact.last_name}`}
          size={64}
        />
      </div>
      <span className="contact-page-info__name">
        {contact.first_name}
        {' '}
        {contact.last_name}
      </span>
    </div>
    <div className="contact-page-info__subheader">
      <span className="contact-page-info__position">
        {`${contact.current_position.title} at `}
        <span onClick={openCompanyTracker} className="contact-page-info__position-link">{contact.current_position.company}</span>
      </span>
      <div className="contact-page-info__email">
        <ContactEmails contactId={contact.id} primaryEmail={contact.email} secondaryEmail={contact.secondary_email} onEmailChanged={onEmailChanged} />
      </div>
    </div>
  </div>
);
