import { http } from './http';
import { TallyData } from '@/domains/core/profile/types/types';

export const getTallyData = async (userID: string): Promise<TallyData> => http.get(`/users/${userID}/onboarding`).then((res) => res.data);

export const getStudentProfileData = async (studentID: string) => {
  const response = await http.get(`/student/${studentID}/profile`);

  if (!response) {
    return {};
  }

  return response;
};

export const sendTallyNudgeEmail = async (studentID: string) => http.post(`/university/advisor/sendnudge/${studentID}`, {}, {}, 'v2');
