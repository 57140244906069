import {
  useContext,
  useDeferredValue, useEffect, useState,
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { Button, Input } from '@careeros/coco';
import { TableRow } from '@/components/table/table-types';

import './styles/campus-champions.scss';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import {
  Contact,
  contactKanbanStatuses,
  ContactStatus,
  SuggestedContactType,
  SuggestedContactTypeWithNetworkingData,
} from '@/domains/core/contact/types';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { Loader } from '@/components/loader/loader';
import {
  ChampionsTableColumns,
  mapCampusChampions,
} from '@/domains/core/netwoking/constants/contact-table';
import { ModalContext } from '@/components/modal/modal-provider';
import { ArchiveContactModal } from '@/domains/core/netwoking/components';
import Table from '@/components/table/table';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';
import { updateContactPositionInKanban } from '@/services/api/contact';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ToastContext } from '@/components/toast/toast-provider';

import { AddToastType } from '@/domains/generic/toasts/types';
import { useGetContactsNoCache } from '@/services/queries/contact';
import { useApplicationsNoCache, useArchivedCompanies } from '@/services/queries/application';
import { useNetworkingStatuses } from '@/services/hooks/use-networking-statuses';
import { useAlgoliaInsights } from '@/services/hooks/use-algolia';
import { useSelfCampusChampions } from '@/services/queries/campus-champion';
import { connectCampusChampion } from '@/services/api/contacts';

const CampusChampionsPage = () => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { data: selfUniversity } = useSelfUniversity();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [isLoading, setIsLoading] = useState(false);
  const [preparedCampusChampions, setPreparedCampusChampions] = useState<SuggestedContactTypeWithNetworkingData[]>([]);
  const { data: campusChampions, isLoading: areCampusChampionsLoading } = useSelfCampusChampions();
  const { data: contacts, isLoading: areContactsLoading } = useGetContactsNoCache();
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { data: archivedCompanies = [] } = useArchivedCompanies();
  const { user } = useAuth0();
  const insights = useAlgoliaInsights();
  const { data: applications = [] } = useApplicationsNoCache();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { mapAllConnectionStatuses, isLoading: isLoadingStatuses } = useNetworkingStatuses();
  const queryClient = useQueryClient();
  const { data: self } = useSelf();
  const { addToast }: AddToastType = useContext(ToastContext);
  const deferredQuery = useDeferredValue(searchQuery);

  const archiveContact = async (id: string, _: ContactStatus, position: number, newStatus: ContactStatus) => {
    const droppedColumn = contactKanbanStatuses[newStatus];
    await updateContactPositionInKanban(id, droppedColumn, position);

    const successToastMessage = 'Contact archived';
    const successAdditionalMessage = 'The contact has been successfully archived.';

    addToast({
      type: 'success',
      message: successToastMessage,
      additionalMessage: successAdditionalMessage,
    });
    queryClient.invalidateQueries('contacts-no-cache');
  };

  const handleArchiveContactConfirmation = (id: string, currentStatus: ContactStatus, position: number, rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveContact', 'true');
    }
    closeModal();
    archiveContact(id, currentStatus, position, 'Archived');
  };

  const restoreContact = async (id: string) => {
    await updateContactPositionInKanban(id, 'saved', 0);

    const successToastMessage = 'Contact Restored';
    const successAdditionalMessage = 'The contact has been successfully restored.';

    addToast({
      type: 'success',
      message: successToastMessage,
      additionalMessage: successAdditionalMessage,
    });
    queryClient.invalidateQueries('contacts-no-cache');
  };

  const requestArchiveConfirmation = (id: string, currentStatus: ContactStatus, position: number = 0) => {
    const rememberAction = localStorage.getItem('rememberArchiveContact');

    if (!rememberAction) {
      openModal(
        <ArchiveContactModal
          handleArchiveContact={(remember) => handleArchiveContactConfirmation(id, currentStatus, position, remember)}
          closeModal={closeModal}
        />,
      );
    } else {
      handleArchiveContactConfirmation(id, currentStatus, position, false);
    }
  };

  const revalidateContacts = () => {
    queryClient.invalidateQueries('contacts-no-cache');
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries('gamification-actions');
    queryClient.invalidateQueries('challenges');
    queryClient.invalidateQueries('xp');
    queryClient.invalidateQueries('leaderboards');
  };

  const handleSaveContact = async (id: string) => {
    const suggestedContact = campusChampions?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;

    await connectCampusChampion(suggestedContact.id);
    revalidateContacts();
    trackEvent('Saved Contact', user, {
      companyID: suggestedContact.company_id,
      source: 'Campus Champion Page',
      type: 'save',
      campusChampionName: `${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`,
    });
  };

  const handleReachOut = async (id: string) => {
    if (contacts.some((contact: Contact) => contact.id === id && contact.kanban_position.status === 'archive')) {
      await restoreContact(id);
    }
    if (contacts.some((contact: Contact) => contact.id === id)) {
      navigate(`/app/inbox/${id}`);
      return;
    }
    setIsLoading(true);
    const suggestedContact = campusChampions?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;
    await connectCampusChampion(suggestedContact.id);

    revalidateContacts();

    trackEvent('Saved Contact', user, {
      companyID: suggestedContact.company_id,
      source: 'Campus Champion Page',
      type: 'reach_out',
      campusChampionName: `${suggestedContact.contact.first_name} ${suggestedContact.contact.last_name}`,
    });

    if (self?.id) {
      insights.purchasedObjectIDs(
        'Contact Saved',
        'contact',
        [suggestedContact.contact_id],
        self.id,
      );
    }
    setIsLoading(false);
    navigate(`/app/inbox/${suggestedContact.contact_id}`);
  };

  const getCompanySavedStatus = (companyID: string) => {
    const isApplication = Array.isArray(applications) && applications?.some((application) => application.company_id === companyID);
    if (!isApplication) {
      return 'unsaved';
    }
    const isArchive = Array.isArray(archivedCompanies) && archivedCompanies?.some((company: any) => company.company_id === companyID);
    if (isArchive) {
      return 'archived';
    }
    return 'saved';
  };

  const handleOpenTracker = (companyID: string) => {
    const status = getCompanySavedStatus(companyID);
    if (status === 'unsaved') {
      navigate(`/app/companies/company/${companyID}`);
      return;
    }
    openTracker(companyID);
  };

  const processContactsWithStatuses = async (allContacts: SuggestedContactType[]) => {
    if (!self?.id) {
      return;
    }

    const contactsWithStatus = await mapAllConnectionStatuses(contacts, self?.id);

    const preparedChampions: any = allContacts.map((contact) => ({
      ...contact,
      contact: contactsWithStatus.find((c) => c.id === contact.contact_id) || contact.contact,
    }));

    setPreparedCampusChampions(preparedChampions);
  };

  const filterContacts = (queryString: string, contactsToFilter: SuggestedContactType[] | undefined) => {
    const filteredResults = Array.isArray(contactsToFilter) ? contactsToFilter?.filter((contact) => {
      const fullName = `${contact.contact.first_name} ${contact.contact.last_name}`;
      return fullName.toLowerCase().includes(queryString.toLowerCase())
        || contact.company_name.toLowerCase().includes(queryString.toLowerCase())
        || contact.contact.current_position.title.toLowerCase().includes(queryString.toLowerCase());
    }) : [];
    setTableData(mapCampusChampions(filteredResults || [], handleOpenTracker, requestArchiveConfirmation, handleReachOut, contacts, restoreContact, handleSaveContact, selfUniversity?.name || '', isLoadingStatuses));
  };

  useEffect(() => {
    if (deferredQuery) {
      filterContacts(deferredQuery.trim(), campusChampions);
    } else {
      filterContacts('', campusChampions);
    }
  }, [deferredQuery]);

  useEffect(() => {
    if (preparedCampusChampions.length) {
      filterContacts(searchQuery, preparedCampusChampions);
    }
  }, [preparedCampusChampions]);

  useEffect(() => {
    if (self && !areCampusChampionsLoading && !areContactsLoading && campusChampions) {
      filterContacts(searchQuery, campusChampions);
      processContactsWithStatuses(campusChampions);
    } else {
      processContactsWithStatuses([]);
    }
  }, [campusChampions?.length, self?.id, areCampusChampionsLoading, contacts]);

  if (areCampusChampionsLoading || areContactsLoading || isLoading) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="campus-champions-page" id="main">
      {Array.isArray(campusChampions) && campusChampions.length > 0 ? (
        <>
          <h1 className="campus-champions-page__title">
            <span>Campus Champions</span>
            <TooltipIcon
              tooltipLabel={
                `Campus Champions are contacts selected specifically for ${selfUniversity?.name} by this company.
                Feel free to reach out to them, they are waiting for your message!`
              }
              tooltipPosition="bottom-center"
              withoutTooltipArrow
            />
          </h1>
          <div className="campus-champions-page__search">
            <Input
              icon="bi bi-search"
              inputSize="medium"
              placeholder="Type by contact name, company or job title"
              value={searchQuery}
              id="contacts-search"
              label=""
              handleValueChange={setSearchQuery}
            />
          </div>
          <div className="contacts-overview-container__table contacts-overview-container__table--active">
            <Table isFixedLayout columns={ChampionsTableColumns} data={tableData} />
          </div>
        </>
      ) : (
        <div className="contacts-overview-container__table contacts-overview-container__table--active">
          <h2 className="campus-champions-page__title">No Campus Champions for your school yet</h2>
          <p className="campus-champions-page__description">
            We are working on it! In the meantime, you can reach out to any of the contacts below.
          </p>
          <div className="campus-champions-page__button-container">
            <Button
              label="See all contacts"
              mode="primary"
              size="medium"
              outlined
              onClick={() => {
                navigate('/app/contacts');
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomWithAuthenticationRequired(CampusChampionsPage);
