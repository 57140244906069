import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo, useContext } from 'react';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { ContactPreviewCard } from '@/domains/core/contact/components/contact-preview-card/contact-preview-card';
import { PreviewCardWrapper } from '../../../../generic/preview-card/preview-card-wrapper/preview-card-wrapper';
import { ContactKanbanTile } from '../../../../generic/kanban/contact-tile/contact-tile';
import { mapSuggestedContactToContactPreviewCard } from '@/domains/core/contact/utils/contact-to-preview-parser';
import { SuggestedContactTypeWithNetworkingData } from '@/domains/core/contact/types';
import { getSingleCompany } from '@/services/api/company';
import { splitAndCapitalize } from '@/services/helpers/string';

type Props = {
  contact: SuggestedContactTypeWithNetworkingData;
  schoolName: string;
  handleSaveSuggestedContact: () => Promise<void>;
};

export const SuggestedContactKanbanTileWithPreview = ({
  contact,
  schoolName,
  handleSaveSuggestedContact,
}: Props) => {
  const contactPreviewCardData = useMemo(() => mapSuggestedContactToContactPreviewCard(contact), [contact]);
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const navigate = useNavigate();

  const goToCampusChampionsPage = useCallback(() => {
    navigate('/app/contacts/campus-champions');
  }, []);

  const goToCompany = useCallback(async () => {
    const company = await getSingleCompany(contact.company_id);

    if (!company.application_status) {
      navigate(`/app/companies/company/${company.id}`);
    } else {
      openTracker(contact.company_id);
    }
  }, [contact.company_id]);

  return (
    <ContactKanbanTile
      image={contact.contact.career_summary.basics?.image || ''}
      name={`${contact.contact.first_name} ${contact.contact.last_name}`}
      companyName={contact.company_name}
      position={contact.contact.current_position.title}
      handleSave={handleSaveSuggestedContact}
      handleCompanyClick={goToCompany}
      companyLogo={contact.contact.current_position.company_logo_url}
      networkingStatusRate={contact.match_criteria?.includes('desired_job') ? 1 : 0}
      isAlumni={contact.match_criteria?.includes('alumni')}
      showLanguages={contact.match_criteria?.includes('languages')}
      isCampusChampion={contact.is_campus_champion}
      languages={splitAndCapitalize(contact.matched_languages || '')}
      schoolName={schoolName || 'your school'}
      campusChampionClickHandler={goToCampusChampionsPage}
    >
      <ContactKanbanTile.NameWithPreview>
        <PreviewCardWrapper
          name={contactPreviewCardData.title}
          popupFitToScreenHeight
          className="contact-kanban-tile__name-text"
        >
          <PreviewCardWrapper.Preview>
            <ContactPreviewCard
              title={contactPreviewCardData.title}
              expandButtonLabel="See full profile"
              currentPosition={contactPreviewCardData.currentPosition}
              previousPositions={contactPreviewCardData.previousPositions}
              headline={contactPreviewCardData.headline}
              networkingStatus={contactPreviewCardData.networkingStatus}
              linkedInConnectionStatus={contactPreviewCardData.linkedInConnectionStatus}
              languages={contactPreviewCardData.languages}
              onCurrentCompanyClick={goToCompany}
            />
          </PreviewCardWrapper.Preview>
        </PreviewCardWrapper>
      </ContactKanbanTile.NameWithPreview>
    </ContactKanbanTile>
  );
};
