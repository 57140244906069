import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Banner } from '@careeros/coco';
import { ActionsBox } from '@/domains/core/user';
import {
  TaskAction,
  TaskNames,
  TaskObject,
} from '@/domains/core/tracker-data';

import './tracker-content.scss';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';

interface ContentProps {
  activeState: any;
  tasks: TaskObject[];
  handleAction: (action: TaskAction) => void;
}

export const TrackerContent = ({
  activeState,
  handleAction,
  tasks,
}: ContentProps) => {
  const { checkLinkedInLogin } = useExtensionMessaging();
  const navigate = useNavigate();
  const [uncompletedTasks, setUncompletedTasks] = useState(tasks);

  const updateUncompletedTasks = async () => {
    const completedTasksNames: TaskNames[] = [];

    const isExtensionIstalled = await checkLinkedInLogin();

    if (isExtensionIstalled !== undefined) {
      completedTasksNames.push(TaskNames.extension);
    }

    const newUncompletedTasks = tasks?.filter((task) => !completedTasksNames.includes(task.name)) || [];

    setUncompletedTasks(newUncompletedTasks);
  };

  useEffect(() => {
    updateUncompletedTasks();
  }, [tasks]);

  const goToArhive = () => {
    navigate('/app/archive');
  };

  if (!activeState) {
    return (
      <div className="company-tracker-content">
        <Banner
          title="Company is currently in your archive"
          text="If you want to continue working on this company, you can bring it back to your Overview Board from the Archive page."
          handleButtonClick={goToArhive}
          buttonLabel="Go to Archive"
          isButtonOutlined
        />
      </div>
    );
  }
  return (
    <div className="company-tracker-content">
      {uncompletedTasks?.length > 0 && (
        <div className="company-tracker-content__actions">
          <ActionsBox
            tasks={uncompletedTasks}
            handleAction={handleAction}
          />
        </div>
      )}
    </div>
  );
};
