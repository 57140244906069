import {
  useDeferredValue, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button, Checkbox, IconButton, Input,
  ToggleViews,
} from '@careeros/coco';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { Loader } from '@/components/loader/loader';
import { CohortsNavigation } from '@/domains/core/advisor/components/cohorts-navigation/cohorts-navigation';
import { useGetCohortsByUniversityID, useGetStudentsByUniversity } from '@/services/queries/student';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import {
  Cohort, CohortLinkDataType, MiniAdvisor, StudentModel, StudentStatus,
} from '@/domains/core/advisor/types';
import { ExportStudentData } from '@/domains/core/advisor/components/export-student-data/export-student-data';
import { useGetUniversityTags } from '@/services/queries/tag';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { FilterOption } from '@/domains/core/company/types';
import { TagType } from '@/components/tag/tag';
import { useLastViewedAt } from '@/domains/core/advisor/utils/lastview';
import { useGetAllAdvisors } from '@/services/queries/advisor';
import '../styles/students-board.scss';
import StudentKanban from '@/domains/core/advisor/containers/student-kanban/student-kanban';
import StudentTable from '@/domains/core/advisor/containers/student-table/student-table';
import { useScroll } from '@/services/hooks/use-scroll';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';
import { buildCohortsWithAll } from '@/services/helpers/advisor';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useMobileWarning } from './hooks/use-mobile-warning';

const columns: StudentStatus[] = [
  'Inactive',
  'Companies Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Rejected',
];

type FilterState = {
  searchQuery: string,
  selectedCohort: string | null,
  assignedToMe: boolean,
  selectedTags: FilterOption[],
};

const StudentsOverviewPage = () => {
  const [selectedView, setSelectedView] = useState<'list' | 'kanban'>('kanban');
  const [filteredStudents, setFilteredStudents] = useState<StudentModel[]>([]);
  const [filteredByTagsStudents, setFilteredByTagsStudents] = useState<StudentModel[]>([]);
  const lastViewedAt = useLastViewedAt();
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(false);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);

  const [filters, setFilters] = useState<FilterState>({
    searchQuery: '',
    selectedCohort: null,
    assignedToMe: false,
    selectedTags: [],
  });

  const { universityID: universityIDFromParams } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { data: university } = useSelfUniversity();
  const deferredQuery = useDeferredValue(filters.searchQuery);

  const universityID = useMemo(() => {
    if (universityIDFromParams) {
      return universityIDFromParams;
    }

    if (!university) {
      return undefined;
    }

    return university.id;
  }, [universityIDFromParams, university]);

  const shouldFetchUniversityData = { enabled: !!universityID };

  const {
    data: students,
    isLoading: areStudentsLoading,
  } = useGetStudentsByUniversity(universityID || '', lastViewedAt, shouldFetchUniversityData);

  const {
    data: cohorts,
    isLoading: areCohortsLoading,
  } = useGetCohortsByUniversityID(universityID || '', shouldFetchUniversityData);

  const {
    data: tags,
    isLoading: areTagsLoading,
  } = useGetUniversityTags(universityID || '', shouldFetchUniversityData);

  const {
    data: allAdvisors,
  } = useGetAllAdvisors(universityID || '');

  const { cohortID } = useParams();
  const {
    data: self,
    isLoading: isSelfLoading,
  } = useSelf();

  const selectedCohortName = useMemo(() => cohorts?.find(cohort => cohort.id === filters.selectedCohort)?.name, [cohorts, filters.selectedCohort]);

  const validatedStudents = useMemo<StudentModel[]>(() => (Array.isArray(students) ? students : []), [students]);
  const validatedCohorts = useMemo<Cohort[]>(() => (Array.isArray(cohorts) ? cohorts : []), [cohorts]);
  const validatedTags = useMemo<TagType[]>(() => (Array.isArray(tags) ? tags : []), [tags]);
  const validatedAdvisorList = useMemo<MiniAdvisor[]>(() => (Array.isArray(allAdvisors) ? allAdvisors : []), [allAdvisors]);
  const tagOptions = useMemo<FilterOption[]>(() => validatedTags.map((tag): FilterOption => ({
    name: tag.label,
    value: tag.id,
  })), [validatedTags]);

  const cohortLinks: CohortLinkDataType[] = useMemo(() => {
    if (!universityID) {
      return [];
    }

    // does not include filters - on redirect to cohort page, filters will be reset
    return buildCohortsWithAll('/app/advisor/students', validatedCohorts, universityID, university?.id);
  }, [validatedCohorts, universityID]);

  const filterByCohort = (studentsArray: StudentModel[], cohortId: string | null) => {
    if (!cohortId) return studentsArray;
    return studentsArray.filter(student => student.cohort?.id === cohortId);
  };

  const filterBySearch = (studentsArray: StudentModel[], query: string) => {
    if (!query) return studentsArray;
    return studentsArray.filter(student => `${student.firstName} ${student.lastName}`
      .toLowerCase()
      .includes(query.toLowerCase())
    || student.email.toLowerCase().includes(query.toLowerCase()));
  };

  const filterByAssignedAdvisor = (studentsArray: StudentModel[], assignedToMe: boolean, selfId: string) => {
    if (!assignedToMe) return studentsArray;
    return studentsArray.filter(student => student.assignedAdvisors?.some(assignee => assignee.id === selfId));
  };

  const filterByTags = (studentsArray: StudentModel[], selectedTags: FilterOption[]) => {
    if (selectedTags.length === 0) return studentsArray;
    return studentsArray.filter(student => selectedTags.some(tag => student.tags?.some(studentTag => studentTag.id === tag.value)));
  };

  const applyFilters = () => {
    let result = [...validatedStudents];

    result = filterByCohort(result, filters.selectedCohort);
    result = filterBySearch(result, deferredQuery);
    result = filterByAssignedAdvisor(result, filters.assignedToMe, self?.id || '');
    // result = filterByTags(result, filters.selectedTags);

    setFilteredStudents(result);

    const resultByTags = filterByTags(result, filters.selectedTags);

    setFilteredByTagsStudents(resultByTags);
  };

  const handleSearchChange = (query: string) => {
    setFilters(prev => ({ ...prev, searchQuery: query }));
  };

  const handleCohortChange = (cohortId: string | null) => {
    setFilters(prev => ({ ...prev, selectedCohort: cohortId }));
  };

  const handleAssignedToMeChange = (assigned: boolean) => {
    setFilters(prev => ({ ...prev, assignedToMe: assigned }));
    trackEvent('CA “Show only students assigned to me” filter applied');
  };

  const handleTagsChange = (tagsArray: FilterOption[]) => {
    setFilters(prev => ({ ...prev, selectedTags: tagsArray }));
  };

  const updateScrollState = () => {
    if (!kanbanContainer.current) {
      return;
    }

    setShowLeftScrollArrow(kanbanContainer.current.scrollLeft > 0);
    setShowRightScrollArrow(kanbanContainer.current.scrollLeft < (kanbanContainer.current.scrollWidth - kanbanContainer.current.clientWidth));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    if (!kanbanContainer.current) {
      return;
    }

    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }

    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const handleViewChange = (value: number) => {
    const newView = value === 0 ? 'kanban' : 'list';
    setSelectedView(newView);

    trackEvent(
      newView === 'kanban'
        ? 'CA Student Kanban View Opened'
        : 'CA Student List View Opened',
    );
  };

  const handleEmailHistoryClick = () => {
    navigate('/app/advisor/sent-email-log');
  };

  useAdvisorRoleCheck();
  useMobileWarning();

  useEffect(() => {
    applyFilters();
  }, [validatedStudents, deferredQuery, filters, self]);

  useEffect(() => {
    if (cohortID) {
      handleCohortChange(cohortID);
    } else {
      handleCohortChange(null);
    }
  }, [cohortID]);

  useEffect(() => {
    updateScrollState();
  }, [kanbanContainer.current]);

  if (areStudentsLoading || areCohortsLoading || isSelfLoading || areTagsLoading) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="students-board" id="main">
      <div className="students-board__header">
        <div className="students-board__top-bar">
          <ToggleViews
            labels={['Kanban View', 'List View']}
            size="small"
            selectedValue={selectedView === 'kanban' ? 0 : 1}
            emitSelectedValue={handleViewChange}
            icons={['bi-kanban', 'bi-grid-3x3']}
            iconsSelected={['bi-kanban-fill', 'bi-table']}
          />
          <div className="students-board__top-right">
            <Button
              label="Email History"
              icon="bi bi-box-arrow-up-right"
              mode="primary"
              size="medium"
              iconPosition="right"
              outlined
              handleClick={handleEmailHistoryClick}
            />
            {university && (<ExportStudentData universityID={university.id || universityID} cohortID={cohortID} trackEventText="CA Student Data Exported from Students Page" />)}
          </div>
        </div>
        <div className="students-board__top-container">
          <div className="students-board__search">
            <Input
              icon="bi bi-search"
              inputSize="medium"
              placeholder="Search Students by Name or Email"
              value={filters.searchQuery}
              id="contacts-search"
              label=""
              handleValueChange={handleSearchChange}
            />
          </div>
          <CohortsNavigation defaultLinkName="All Cohorts" links={cohortLinks} trackEventText="CA Cohort selected" />
          {selectedView === 'list' ? (
            <div className="students-board__filter">
              <MultiSelect
                handleSelectedOptions={handleTagsChange}
                labelType="number"
                options={tagOptions}
                placeholder="Filter by tags"
                selected={filters.selectedTags}
                size="full"
                withSelectAllOption
                width="fit-box"
              />
            </div>
          ) : null}
          <Checkbox
            id="assigned-to-me"
            label="Show only students assigned to me"
            checked={filters.assignedToMe}
            onChange={() => handleAssignedToMeChange(!filters.assignedToMe)}
          />
          {selectedView === 'kanban' && (
            <div className="students-board__controls">
              <IconButton
                icon="bi bi-chevron-left"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => handleVerticalScroll('left')}
                disabled={!showLeftScrollArrow}
              />
              <IconButton
                icon="bi bi-chevron-right"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => handleVerticalScroll('right')}
                disabled={!showRightScrollArrow}
              />
            </div>
          )}
        </div>
      </div>

      <div className={`students-board__kanban ${selectedView === 'kanban' ? 'students-board__kanban--active' : ''}`}>
        <StudentKanban
          universityID={universityID || ''}
          columns={columns}
          studentsData={filteredStudents}
          allAdvisors={validatedAdvisorList}
          onScroll={handleScroll}
          showSendButton
          scrollContainerRef={kanbanContainer}
          selfFullName={`${self?.first_name} ${self?.last_name}`}
          cohortName={selectedCohortName || 'All Cohorts'}
        />
      </div>
      <div className={`students-board__table ${selectedView === 'list' ? 'students-board__table--active' : ''}`}>
        <StudentTable
          universityID={universityID || ''}
          tags={validatedTags}
          students={filteredByTagsStudents}
          allAdvisors={validatedAdvisorList}
        />
      </div>
    </div>
  );
};

export default CustomWithAuthenticationRequired(StudentsOverviewPage);
