/* eslint-disable no-plusplus */
import { pdf } from '@react-pdf/renderer';
import * as pdfjsLib from 'pdfjs-dist';
import { uploadResumeImage, uploadResumePDF } from '@/services/api/resume';
import { RESUME_TEMPLATES } from '../templates/template-rules/resume-rules';

export const generatePDFBlob = async (baseResume: any, templateName: string) => {
  const SelectedComponent = RESUME_TEMPLATES[templateName];
  const doc = <SelectedComponent resume={baseResume} title={templateName} />;
  const asPdf = pdf(doc);
  asPdf.updateContainer(doc);
  const blob = await asPdf.toBlob();
  return blob;
};

export const convertPdfToImage = async (pdfBlob: any) => {
  const arrayBuffer = await pdfBlob.arrayBuffer();
  const pdfDocument = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
  const page = await pdfDocument.getPage(1);
  const viewport = page.getViewport({ scale: 1.5 });
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  canvas.width = viewport.width;
  canvas.height = viewport.height;

  const renderContext: any = {
    canvasContext: context,
    viewport,
  };

  await page.render(renderContext).promise;

  return canvas.toDataURL('image/png');
};

export const dataURLToBlob = (dataURL: string) => {
  const byteString = atob(dataURL.split(',')[1]);
  const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: mimeString });
};

export const generateImageBlob = async (baseResume: any, templateName: string) => {
  const pdfBlob = await generatePDFBlob(baseResume, templateName);
  const imageSrcGenerated = await convertPdfToImage(pdfBlob);
  return dataURLToBlob(imageSrcGenerated);
};

export const generateImage = async (baseResume: any, templateName: string) => {
  const imageBlob = await generateImageBlob(baseResume, templateName);
  const formData = new FormData();
  formData.append('image', imageBlob, 'resume.png');
  const response = await uploadResumeImage(formData);
  if (response.error) {
    return '';
  }
  return response;
};

export const uploadPDF = async (baseResume: any, templateName: string, title: string) => {
  const pdfBlob = await generatePDFBlob(baseResume, templateName);
  const formData = new FormData();
  formData.append('file', pdfBlob, `${title}.pdf`);
  const response = await uploadResumePDF(formData);
  return response.source_url;
};

export const downloadPDF = async (url: string, fileName: string) => {
  const response = await fetch(url);
  const pdfBlob = await response.blob();

  const blobUrl = URL.createObjectURL(pdfBlob);
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;
  link.click();

  URL.revokeObjectURL(blobUrl);
};
