import { Button } from '@careeros/coco';
import './linkedin-suggested-contacts-error.scss';
import { LinkedInConnectionIcons } from './linkedin-connection-icons';
import { linkedinSuggestedContactsErrorContent } from './error-content';

type Props = {
  exensionIsInstalled: boolean;
  isLoggedIntoLinkedIn: boolean;
};

export const SuggestedContactsError = ({ exensionIsInstalled, isLoggedIntoLinkedIn }: Props) => {
  const renderButton = linkedinSuggestedContactsErrorContent[!exensionIsInstalled ? 'downloadExtension' : 'loginLinkedIn'].buttonLabel;

  const handleButtonClick = () => {
    if (!exensionIsInstalled) {
      window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
    } else {
      window.open('https://www.linkedin.com/', '_blank');
    }
  };

  return (
    <div className="suggested-contacts-error">
      <div className="suggested-contacts-error__content">
        <h5 className="suggested-contacts-error__title">
          {!exensionIsInstalled && linkedinSuggestedContactsErrorContent.downloadExtension.title}
          {!isLoggedIntoLinkedIn && exensionIsInstalled && linkedinSuggestedContactsErrorContent.loginLinkedIn.title}
          {isLoggedIntoLinkedIn && exensionIsInstalled && 'Failed to load suggested contacts from LinkedIn'}
        </h5>
        <p className="suggested-contacts-error__text">
          {!exensionIsInstalled && linkedinSuggestedContactsErrorContent.downloadExtension.description}
          {!isLoggedIntoLinkedIn && exensionIsInstalled && linkedinSuggestedContactsErrorContent.loginLinkedIn.description}
          {isLoggedIntoLinkedIn && exensionIsInstalled && 'Failed to load suggested contacts. Make sure you have installed the CareerOS Extension and are signed in to your LinkedIn account.'}
        </p>
        <LinkedInConnectionIcons />
      </div>
      {(!exensionIsInstalled || !isLoggedIntoLinkedIn) && (
        <Button
          mode="primary"
          size="medium"
          icon={linkedinSuggestedContactsErrorContent[!exensionIsInstalled ? 'downloadExtension' : 'loginLinkedIn'].buttonIcon}
          iconPosition="right"
          label={renderButton}
          handleClick={handleButtonClick}
        />
      )}
    </div>
  );
};
