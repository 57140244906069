import {
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { useNavigate } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { InfoModal } from '@careeros/coco';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { JobFeedJob, Team } from '@/domains/core/job/types/job';
import { ModalContext } from '@/components/modal/modal-provider';
import {
  addCompanyToFavorites,
  changeApplicationStatus,
  TrackerContext,
  TrackerContextType,
  TrackerInfoTab,
} from '@/domains/core/company';
import { getSingleCompany } from '@/services/api/company';
import { applyForJob } from '@/services/api/job';
import { EmptySavedDescription } from '../empty-saved-description/empty-saved-description';
import { useTabs } from '@/components/tabs/TabsContext';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './job-description.scss';
import { JobHeader } from '../job-header/job-header';
import { JobApplicationInfo } from '../job-application-info/job-application-info';
import { useSelf } from '@/services/queries/user';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { TeamInformation } from '../job-teams-tab/job-teams-tab';
import { Company, COMPANY_PROFILE_TYPE_PREMIUM } from '@/domains/core/company/types/company';
import { getConfig } from '@/config';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';
import { useAlgoliaInsights } from '@/services/hooks/use-algolia';
import { JobFeedBlockerBanner } from '../job-feed-blocker-banner/job-feed-blocker-banner';
import { getJobBadgeType } from '@/domains/core/job/helpers/getJobBadgeType';
import { RelatedJobsWrapper } from '@/domains/generic/algolia/related-jobs/related-jobs';

type Props = {
  job: JobFeedJob;
  isSaved: boolean;
  setAllJobsTab: () => void;
  handleSaveAction: (jobId: string) => void;
  handleUnsaveAction: (jobId: string) => void;
  optimisticJobUpdate: (jobId: string, job: JobFeedJob) => void;
  defaultShowDidYouApply: boolean;
  handleApplyAction?: () => void;
  company: Company & { teams: Team[] };
  isDisabled?: boolean;
  goToOnboardingForm?: () => void;
};

const JobDescription = ({
  job,
  isSaved,
  setAllJobsTab,
  handleSaveAction,
  handleUnsaveAction,
  optimisticJobUpdate,
  defaultShowDidYouApply,
  handleApplyAction,
  company,
  isDisabled = false,
  goToOnboardingForm,
}: Props) => {
  const DEFAULT_TABS = ['Job Description', 'About Company', 'Similar Jobs'];
  const navigate = useNavigate();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [showDidYouApply, setShowDidYouApply] = useState(false);
  const { setCurrentTab } = useTabs();
  const { isAuthenticated } = useAuth0();
  const { data: userData } = useSelf({ enabled: isAuthenticated });
  const { completeActionIfAvailable } = useGamification();
  const insights = useAlgoliaInsights();

  const { trackEvent } = useAnalytics();
  const descriptionScrollRef = useRef<HTMLDivElement>(null);
  const { addToast }: AddToastType = useContext(ToastContext);
  const selectedTeam = useMemo(
    () => company?.teams?.find(team => team.id === job?.team_id),
    [company?.teams, job?.team_id],
  );
  const [tabs, setTabs] = useState(DEFAULT_TABS);

  useEffect(() => {
    const newTabs = [...DEFAULT_TABS];

    // Add Team tab if company is premium and has team_id
    if (company?.profile_type === COMPANY_PROFILE_TYPE_PREMIUM && job?.team_id) {
      newTabs.push('Team');
    }

    setTabs(newTabs);
  }, [company?.profile_type, job?.team_id]);

  useEffect(() => {
    setCurrentTab(tabs[0]);
  }, [job]);

  useEffect(() => {
    if (defaultShowDidYouApply) {
      setShowDidYouApply(true);
    }
  }, [defaultShowDidYouApply]);

  useEffect(() => {
    if (descriptionScrollRef.current) {
      descriptionScrollRef.current.scrollTop = 0;
    }
    trackEvent('Job Viewed', {
      job_id: job?.id,
      company_name: job?.company_name,
      job_title: job?.title,
      industry: job?.tags?.find(tag => tag.tag_type === 'industry')?.tag_content,
      location: job?.tags?.find(tag => tag.tag_type === 'location')?.tag_content,
      full_time: job?.tags?.find(tag => tag.tag_type === 'contract')?.tag_content,
      on_site: job?.tags?.find(tag => tag.tag_type === 'workMode')?.tag_content,
      blurred: isDisabled,
    });
  }, [job]);

  useEffect(() => {
    if (userData?.id) {
      insights.viewedObjectIDs(
        'Job Viewed',
        'job',
        [job.id],
        userData.id,
      );
    }
  }, [userData?.id]);

  if (!job) {
    return <EmptySavedDescription setAllJobsTab={setAllJobsTab} />;
  }

  if (!company) {
    return null;
  }

  const handleYesClick = async () => {
    try {
      await applyForJob(job.id, true);
      optimisticJobUpdate(job.id, { ...job, applied: true });
      if (userData && userData?.id && job) {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.JOB_APPLICATION);
        insights.purchasedObjectIDs(
          'Job Feed Application',
          'job',
          [job.id],
          userData.id,
        );
      }

      trackEvent('Applied to job', {
        job_id: job.id,
        company_name: job.company_name,
        job_title: job.title,
        location: job.tags?.find(tag => tag.tag_type === 'location')?.tag_content,
        full_time: job.tags?.find(tag => tag.tag_type === 'contract')?.tag_content,
        on_site: job.tags?.find(tag => tag.tag_type === 'workMode')?.tag_content,
        industry: job.tags?.find(tag => tag.tag_type === 'industry')?.tag_content,
        size: job.tags?.find(tag => tag.tag_type === 'employees')?.tag_content,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleNoClick = async () => {
    try {
      await applyForJob(job.id, false);
      optimisticJobUpdate(job.id, { ...job, applied: false });
      trackEvent('Did not apply to job', {
        job_id: job?.id,
      });
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleAppliedResponse = (didApply: boolean) => {
    if (!isSaved) {
      handleSaveAction(job.id);
    }

    if (didApply) {
      handleYesClick();
    } else {
      handleNoClick();
    }

    setShowDidYouApply(false);
    closeModal();
  };

  const rememberSkipModal = (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberApplyUser', 'true');
    }
  };

  const handleOpenJobLink = () => {
    const utmUrl = `${job.linkedin_url}${job.linkedin_url.includes('?') ? '&' : '?'}utm_source=CareerOS`;
    window.open(utmUrl, '_blank');

    openModal(
      <InfoModal
        icon="bi bi-send"
        title="Did you apply?"
        description="Just let us know, and we'll help you keep tabs on your application!"
        buttonLabel="Yes, I did! 🤩"
        handleButtonClick={() => handleAppliedResponse(true)}
        secondaryButtonLabel="No, not yet 🙈"
        handleSecondaryButtonClick={() => handleAppliedResponse(false)}
      />,
    );
  };

  const updateJobDeadline = (deadline: string | null, isRolling: boolean) => {
    optimisticJobUpdate(job.id, { ...job, deadline: deadline || '', rolling_date: isRolling });
  };

  const handleHelpNetwork = async () => {
    await addCompanyToFavorites(company.id);
    const updated = await getSingleCompany(company.id);
    if (updated.application_id) {
      await changeApplicationStatus(updated.application_id, 'networking', 0);
      openTracker(updated.id);
    }
    if (!isSaved) {
      handleSaveAction(job.id);
    }
    closeModal();
    trackEvent('Help me network clicked');
  };

  const confirmApply = (rememberAction: boolean) => {
    rememberSkipModal(rememberAction);
    handleOpenJobLink();
  };

  const goToCompanyPage = () => {
    navigate(`/app/companies/company/${job.company_id}?company-page-tab=Jobs`);
  };

  const handleApply = () => {
    if (handleApplyAction) {
      handleApplyAction();
      return;
    }
    const rememberAction = localStorage.getItem('rememberApplyUser');
    if (rememberAction || (company.application_status && company.application_status !== 'saved')) {
      handleOpenJobLink();
    } else {
      openModal(
        <InfoModal
          icon="bi bi-sign-stop"
          title="Are you sure?"
          description="You have not networked with this company, yet. Are you sure you want to apply?"
          buttonLabel="Yes, I'm sure"
          handleButtonClick={confirmApply}
          secondaryButtonLabel="Help me network"
          handleSecondaryButtonClick={handleHelpNetwork}
          rememberText="Don't show this again"
        />,
      );
    }
    trackEvent('Apply button clicked', {
      company_name: job.company_name,
      job_id: job.id,
      job_title: job.title,
      company: job.company_name,
    });
  };

  const handleCopyToClipboard = () => {
    const url = `${window.location.origin}/jobs/${company.linkedin_url?.split('/').pop()}/${job.id}`;
    navigator.clipboard.writeText(url);
    addToast({
      type: 'success',
      message: 'Link copied to clipboard',
      autoClose: true,
    });
  };

  return (
    <div className="job-description">
      <JobHeader
        isSaved={isSaved}
        handleSaveButtonClick={isSaved ? () => handleUnsaveAction(job.id) : () => handleSaveAction(job.id)}
        handleApplyButtonClick={handleApply}
        tags={job?.tags && Array.isArray(job.tags) ? job.tags : []}
        jobBadgeType={getJobBadgeType(job.source, job.company_partner_type)}
        logoUrl={job.linkedin_logo_url}
        companyName={job.company_name}
        postedDate={formatDateDeltaFromNow(job.posted_on || job.created_at || '')}
        title={job.title}
        isApplied={job.applied}
        isHiddenApplyButton={job.linkedin_url === '' || !job.linkedin_url}
        handleCompanyClick={goToCompanyPage}
        handleShareClick={handleCopyToClipboard}
        isDisabled={isDisabled}
      />
      {(isSaved || showDidYouApply) && !isDisabled && (
        <div className="company-jobs-layout__deadline">
          <JobApplicationInfo
            applicationDeadline={job.deadline || ''}
            isDateRolling={!!job.rolling_date}
            handleAppliedResponse={handleAppliedResponse}
            jobID={job.id}
            showDidYouApply={showDidYouApply}
            isApplied={job.applied}
            revalidateApplicationDeadline={updateJobDeadline}
          />
        </div>
      )}
      <div className="job-description__bottom">
        <TabsHeader
          withBorder
          tabs={tabs}
          containerName="description"
          defaultTab={tabs[0]}
          isScrollableOnOverflow
        />
        {isDisabled && (
          <div className="job-description__blocker-banner">
            <JobFeedBlockerBanner handleClick={goToOnboardingForm} />
          </div>
        )}
        <div className={`job-description__tabs-content ${isDisabled ? 'job-description__tabs-content--disabled' : ''}`} ref={descriptionScrollRef}>
          <TabsContent>
            <div
              className="job-description__description"
              data-tab="Job Description"

            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(job.description),
                }}
                className="job-description__description-content"
              />
              <div className="job-description__related-jobs">
                <RelatedJobsWrapper jobID={job.id} />
              </div>
            </div>
            <div
              className="job-description__description"
              data-tab="About Company"
            >
              {
                company?.partner_type && (
                  <div className="job-description__partner-logo">
                    {company?.bg_image_url && <img src={company.bg_image_url} alt={company.name} className="job-description__partner-logo-image" />}
                    <a className="job-description__partner-logo-link" href={`${getConfig().baseUrl}/company/${company.linkedin_url?.split('/').pop()}`} target="_blank" rel="noopener noreferrer">
                      Learn more about the culture, benefits and working styles at
                      {' '}
                      {company.name}
                      <i className="bi bi-arrow-right" />
                    </a>
                  </div>
                )
              }
              <TrackerInfoTab
                aboutCompany={company.overview}
                name={company.name}
                logoUrl={company.logo_url}
                website={company.company_url}
                industry={company.industry || company.careeros_industry}
                location={company.hq_location}
              />
            </div>
            <div
              className="job-description__description"
              data-tab="Team"
            >
              {company?.partner_type && selectedTeam ? (
                <TeamInformation team={selectedTeam} />
              ) : (
                <div>This company is not a partner</div>
              )}
            </div>
            <div
              className="job-description__description"
              data-tab="Similar Jobs"
            >
              <RelatedJobsWrapper jobID={job.id} />
            </div>
          </TabsContent>
        </div>
      </div>
    </div>
  );
};
export default withTabs(JobDescription);
