import ReactDOM from 'react-dom/client';

import { lazy, Suspense } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  BrowserOptions, browserTracingIntegration, init, replayIntegration,
} from '@sentry/react';

import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { QueryClient, QueryClientProvider } from 'react-query';

import ReactGA from 'react-ga4';
import {
  favoriteCompaniesLoader,
  exploreCompaniesLoader,
  filterCompaniesLoader,
  applicationsLoader,
  archiveLoader,
  QRcompanyLoader,
} from './domains/core/company/api/company';
import { getConfig } from './config';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import './index.css';
import './styles/responsive.scss';

import { Loader } from './components/loader/loader';
import { loginLoader } from './domains/generic/auth/api';
import { documentsLoader } from './domains/core/student/api';
import { dashboardLoader } from './domains/core/user/api';
import { adminLoader } from './domains/core/admin/api';
import {
  actionPlanByStudentLoader,
} from './domains/core/advisor/api';
import {
  resumeBuilderBaseLoader,
  resumeBuilderListLoader,
  resumeBuilderLoader,
  resumeBuilderTailoredLoader,
  resumeOSRelevantContextLoader,
} from './domains/core/resume/api';
import { searchLoader } from './domains/generic/search/api';
import ErrorPage from './pages/error';
import CohortForm from './pages/cohort-form';

import JobFeedPage from './pages/job-feed';
import MyDocumentsPage from './pages/documents';
import AdminViewPage from './pages/admin/admin-view';
import AdminConsentGrantedPage from './pages/auth-approved';
import LoginWithUniversityPage from './pages/auth/university-login';
import WaitlistLoginForm from './pages/auth/waitlist-form';
import SearchUniversity from './pages/auth/search-university';
import TrialCodeCheck from './pages/auth/trial-code-check';
import SelectLoginProvider from './pages/auth/select-provider';
import LoggedInWithUniversity from './pages/auth/logged-in-with-university';
import LogoutLoader from './pages/logout-loader';
import ContactsPage from './pages/contacts';
import CampusChampionsPage from './pages/campus-champions';
import ErrorIncognitoPage from './pages/error-incognito';
import PrivacyPolicyPage from './pages/support/privacy-policy';
import TallyForm from './pages/tally-form';
import PeerVotePage from './pages/peer-vote';
import WebinarPage from './pages/webinar/webinar';
import RecordingPage from './pages/webinar/recordings';
import ServiceLevelAgreementPage from './pages/support/service-level-agreement';
import MessagesInbox from './pages/inbox';
import Relogin from './pages/auth/relogin';
import LoginLoaderIncognito from './pages/auth/incognito-login';
import StudentsResumesPage from './pages/career-advisor/resume-builder/students-resumes';
import ResumeBookPage from './pages/career-advisor/resume-builder/resume-book';
import ActionPlanPage from './pages/career-advisor/action-plan';
import StudentResumesPage from './pages/career-advisor/resume-builder/student-resumes';
import ViewResumePage from './pages/career-advisor/resume-builder/view-resume';
import CompanyQRPage from './pages/qr-scan';
import DashboardPage from './pages/dashboard';
import DashboardAdvisorPage from './pages/career-advisor/dashboard-advisor';
import Root from './pages/root';
import StyleGuide from './pages/style-guide';
import OverviewAdvisorPage from './pages/career-advisor/overview-advisor';
import OverviewPage from './pages/overview';
import OverviewPageV2 from './pages/overview-v2';
import FavoriteCompaniesPage from './pages/favorite-companies';
import ArchivePage from './pages/archive';
import CompanyPage from './pages/company';
import FilterCompaniesPage from './pages/filter-companies';
import ExploreCompaniesPage from './pages/explore-companies';
import AllCompaniesPage from './pages/all-companies';
import CompanyOverviewPage from './pages/career-advisor/company-overview';
import LoginPage from './pages/login';
import ProfilePage from './pages/profile';
import LoggedIn from './pages/login-loader';
import SearchPage from './pages/search';
import InsightsPage from './pages/career-advisor/insights';
import CookieBanner from './domains/support/cookie-banner';
import StudentOverviewPage from './pages/career-advisor/student-overview';
import HelpPage from './pages/help';
import TourPage from './pages/tour';
import GetStartedPage from './pages/get-started';
import HowToPage from './pages/how-to';
import LearningForm from './pages/learning-form';
import PublicCompanyPage from './pages/public-company';
import { setMobileVhUnit } from './services/helpers/responsive';
import { ignoredSources, sentryIgnoredErrors } from './sentry-config';
import AlumniList from './pages/alumni/alumni-list';
import ContactBookPage from './pages/career-advisor/contact-book';
import StudentsOverviewPage from '@/pages/career-advisor/students-board';
import { ModalProvider } from './components/modal/modal-provider';
import { ToastProvider } from './components/toast/toast-provider';
import ArticlesPage from './pages/articles';
import RefferalCheck from './pages/refferal-check';
import { SentEmailLog } from './pages/career-advisor/sent-email-log';
import PublicJobPage from './pages/public-job';
import JobPage from './pages/job';
import ChallengesPage from './pages/gamification/challenges';
import { TrackerProvider } from './domains/core/company';
import { BreadcrumbProvider } from './contexts/breadcrumb-context';
import LeaderboardsPage from './pages/gamification/leaderboards';

const ResumeBuilderPage = lazy(() => import('./pages/resume-builder/resume-builder'));
const ResumesListingPage = lazy(() => import('./pages/resume-builder/resumes-list'));
const ResumeEditBasePage = lazy(() => import('./pages/resume-builder/edit-base-resume'));
const ResumeEditTailoredPage = lazy(() => import('./pages/resume-builder/edit-tailored-resume'));
const ResumeBasePage = lazy(() => import('./pages/resume-builder/resume-base'));

const onRedirectCallback = (appState: any) => {
  console.log(appState);
};

setMobileVhUnit();

const API_URL = import.meta.env.VITE_BASE_URL;
const env = import.meta.env.VITE_ENV;

const sentryConfig: BrowserOptions = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: env,
  ignoreErrors: sentryIgnoredErrors,
  integrations: [
    browserTracingIntegration(),
    replayIntegration(),
  ],
  beforeSend(event) {
    // Check if the error is from a third-party source
    const errorSource = event.exception?.values?.[0]?.stacktrace?.frames?.[0]?.filename;
    if (errorSource && ignoredSources.some(source => errorSource.toLowerCase().includes(source.toLowerCase()))) {
      return null;
    }

    // Check if error is from Customer.io initialization
    const errorMessage = event.exception?.values?.[0]?.value;
    if (event.exception?.values?.[0]?.type === 'ReferenceError'
      && errorMessage?.includes('_cio')) {
      return null;
    }

    // Check for PDF blob retrieval errors
    if (errorMessage && errorMessage.includes('Unexpected server response (0) while retrieving PDF "blob:')) {
      return null;
    }

    // Additional check for script errors from these sources
    const scriptUrl = event.exception?.values?.[0]?.stacktrace?.frames?.some(frame => ignoredSources.some(source => frame?.filename?.toLowerCase().includes(source.toLowerCase())));
    if (scriptUrl) {
      return null;
    }

    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [API_URL],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

if (env === 'prod' || env === 'test') {
  init(sentryConfig);
}

const config = getConfig();
const urlParams = new URLSearchParams(window.location.search);
const universityParam = urlParams.get('university');
const cohortParam = urlParams.get('cohort');
const providerConfig: any = {
  domain: config.domain,
  clientId: config.clientId,
  cacheLocation: 'localstorage',
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: config.redirect + (universityParam ? `?university=${universityParam}&cohort=${cohortParam}` : ''),
    audience: config.audience,
  },
};

const providerIncognitoConfig: any = {
  domain: config.domain,
  clientId: config.clientId,
  cacheLocation: 'localstorage',
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: config.redirectIncognito + (universityParam ? `?university=${universityParam}&cohort=${cohortParam}` : ''),
    audience: config.audience,
  },
};

const queryClient = new QueryClient();

const Auth0ProviderLayout = () => (
  <Auth0Provider {...providerConfig}>
    <CookiesProvider>
      <QueryClientProvider client={queryClient}>
        <BreadcrumbProvider>
          <CookieBanner />
          <Outlet />
        </BreadcrumbProvider>
      </QueryClientProvider>
    </CookiesProvider>
  </Auth0Provider>
);

const Auth0ProviderLayoutIncognito = () => (
  <Auth0Provider {...providerIncognitoConfig}>
    <Outlet />
  </Auth0Provider>
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Auth0ProviderLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'styleguide',
        element: <StyleGuide />,
      },
      {
        path: 'consent-granted',
        element: <AdminConsentGrantedPage />,
      },
      {
        path: 'privacy-policy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'service-level-agreement',
        element: <ServiceLevelAgreementPage />,
      },
      {
        path: '',
        element: <LoginPage />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'company/:companySlug',
        element: <ModalProvider><ToastProvider><PublicCompanyPage /></ToastProvider></ModalProvider>,
      },
      {
        path: 'jobs/:companySlug/:jobId',
        element: <ModalProvider><TrackerProvider><ToastProvider><PublicJobPage /></ToastProvider></TrackerProvider></ModalProvider>,
      },
      {
        path: 'logout',
        element: <LogoutLoader />,
      },
      {
        path: 'with-university',
        element: <LoginWithUniversityPage />,
        children: [
          {
            path: '',
            element: <SearchUniversity />,
            loader: loginLoader,
          },
          {
            path: 'hubspot',
            element: <WaitlistLoginForm />,
            loader: loginLoader,
          },
          {
            path: 'code-check',
            element: <TrialCodeCheck />,
            loader: loginLoader,
          },
          {
            path: 'provider-select',
            element: <SelectLoginProvider />,
            loader: loginLoader,
          },
        ],
      },
      {
        path: 'logged-in',
        element: <LoggedIn />,
      },
      {
        path: 'error',
        element: <ErrorIncognitoPage />,
      },
      {
        path: 'logged-in-with-university',
        element: <LoggedInWithUniversity />,
      },
      {
        path: 'relogin',
        element: (<Relogin />),
      },
      {
        path: 'incognito-login',
        element: <Auth0ProviderLayoutIncognito />,
        children: [
          {
            path: '',
            element: <LoginLoaderIncognito />,
          },
        ],
      },
      {
        path: 'app',
        element: <Root />,
        children: [
          {
            path: 'challenges',
            element: <ChallengesPage />,
          },
          {
            path: 'leaderboards',
            element: <LeaderboardsPage />,
          },
          {
            path: 'refferal/:referralCode',
            element: <RefferalCheck />,
          },
          {
            path: 'cohort-assignment',
            element: <CohortForm />,
          },
          {
            path: '',
            element: <Navigate to="/app/dashboard" replace />,
          },
          {
            path: 'profile',
            element: <ProfilePage />,
          },
          {
            path: 'onboarding',
            element: <TallyForm />,
          },
          {
            path: 'vote-peers',
            element: <PeerVotePage />,
          },
          {
            path: 'webinar/:webinarID',
            element: <WebinarPage />,
          },
          {
            path: 'articles',
            element: <ArticlesPage />,
          },
          {
            path: 'webinar-recording/:webinarID',
            element: <RecordingPage />,
          },
          {
            path: 'learning',
            element: <LearningForm />,
          },
          {
            path: 'documents',
            element: <MyDocumentsPage />,
            loader: documentsLoader,
          },
          {
            path: 'dashboard',
            element: <DashboardPage />,
            loader: dashboardLoader,
          },
          {
            path: 'dashboard-advisor/:universityID?/:cohortID?',
            element: <DashboardAdvisorPage />,
          },
          {
            path: 'contacts-book/:universityID?/:cohortID?',
            element: <ContactBookPage />,
          },
          {
            path: 'admin-view',
            element: <AdminViewPage />,
            loader: adminLoader,
          },
          {
            path: 'tour',
            element: <TourPage />,
          },
          {
            path: 'overview-advisor',
            element: <OverviewAdvisorPage />,
          },
          {
            path: 'advisor/students/:universityID?/:cohortID?',
            element: <StudentsOverviewPage />,
          },
          {
            path: 'advisor/students/student-overview/:studentID/university/:universityID',
            element: <StudentOverviewPage />,
          },
          {
            path: 'advisor/sent-email-log',
            element: <SentEmailLog />,
          },
          {
            path: 'insights',
            element: <InsightsPage />,
          },
          {
            path: 'action-plan/:studentID',
            element: <ActionPlanPage />,
            loader: actionPlanByStudentLoader,
          },
          {
            path: 'company-overview/:companyID/university/:universityID',
            element: <CompanyOverviewPage />,
          },
          {
            path: 'company-overview/:companyID/university/:universityID/:cohortID',
            element: <CompanyOverviewPage />,
          },
          {
            path: 'contacts',
            children: [
              {
                path: '',
                element: <ContactsPage />,
              },
              {
                path: 'campus-champions',
                element: <CampusChampionsPage />,
              },
            ],
          },
          {
            path: 'companies',
            children: [
              {
                path: '',
                element: <AllCompaniesPage />,
                loader: filterCompaniesLoader,
              },
              {
                path: 'explore/:industryName?',
                element: <ExploreCompaniesPage />,
                loader: exploreCompaniesLoader,
              },
              {
                path: 'filter/:filterAttribute/by/:filterValue',
                element: <FilterCompaniesPage />,
                loader: filterCompaniesLoader,
              },
              {
                path: 'company/:companyID',
                element: <CompanyPage />,
              },
              {
                path: 'qr/:companyID',
                element: <CompanyQRPage />,
                loader: QRcompanyLoader,
              },
              {
                path: 'search',
                element: <SearchPage />,
                loader: searchLoader,
              },
            ],
          },
          {
            path: 'favorites',
            element: <FavoriteCompaniesPage />,
            loader: favoriteCompaniesLoader,
          },
          {
            path: 'overview',
            children: [
              {
                path: '',
                element: <OverviewPage />,
                loader: applicationsLoader,
              },
              {
                path: ':companyID',
                element: <Navigate to={`/app/overview?tracker-companyID=${window.location.pathname.split('/').at(-1)}`} />,
              },
            ],
          },
          {
            path: 'overview-v2',
            element: <OverviewPageV2 />,
            loader: applicationsLoader,
          },
          {
            path: 'jobs',
            element: <JobFeedPage />,
            loader: filterCompaniesLoader,
          },
          {
            path: 'jobs/:jobId',
            element: <JobPage />,
          },
          {
            path: 'inbox/:contactID?',
            element: <MessagesInbox />,
          },
          {
            path: 'alumni/:universityID?/:cohortID?',
            element: <AlumniList />,
          },
          {
            path: 'resume-os',
            id: 'resume-os',
            loader: resumeOSRelevantContextLoader,
            children: [
              {
                path: '',
                element: <ResumesListingPage />,
                loader: resumeBuilderListLoader,
              },
              {
                path: 'students/:universityID?/:cohortID?',
                element: <StudentsResumesPage />,
              },
              {
                path: 'students/:universityID/:cohortID?/resume-book',
                element: <ResumeBookPage />,
              },
              {
                path: 'student-resumes/:studentID',
                element: <StudentResumesPage />,
              },
              {
                path: 'student/:studentID/base/:resumeID',
                element: <ViewResumePage type="base" />,
              },
              {
                path: 'student/:studentID/tailored/:resumeID',
                element: <ViewResumePage type="tailored" />,
              },
              {
                path: 'base/:resumeID/:currentStepID?',
                element: <ResumeEditBasePage />,
                loader: resumeBuilderBaseLoader,
              },
              {
                path: 'tailored/:resumeID/:currentStepID?',
                element: <ResumeEditTailoredPage />,
                loader: resumeBuilderTailoredLoader,
              },
              {
                path: 'tailored-resume/:resumeID?/:currentStepID?',
                element: <ResumeBuilderPage />,
                loader: resumeBuilderLoader,
              },
              {
                path: 'base-resume/:resumeID?/:currentStepID?',
                element: <ResumeBasePage />,
              },
            ],
          },
          {
            path: 'archive',
            element: <ArchivePage />,
            loader: archiveLoader,
          },
          {
            path: 'search',
            element: <Navigate to={`/app/companies/search${window.location.search}`} />,
            loader: searchLoader,
          },
          {
            path: 'support',
            children: [
              {
                path: '',
                element: <HelpPage />,
              },
              {
                path: 'get-started',
                element: <GetStartedPage />,
              },
              {
                path: 'how-to',
                element: <HowToPage />,
              },
            ],
          },
        ],
      },
    ],
  },
]);
const envCheck = import.meta.env.VITE_ENV === 'prod';
const GID = import.meta.env.VITE_GOOGLE_ANALYTICS_ID;

if (envCheck) {
  ReactGA.initialize(GID);
}

ReactDOM.createRoot((document.getElementById('root') as HTMLElement) || document.getElementById('storybook-root')).render(
  <Suspense fallback={<Loader />}>
    <RouterProvider router={router} />
  </Suspense>,
);
