import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Company } from '@/domains/core/company/types';
import { Loader } from '@/components/loader/loader';
import './styles/company.scss';
import { withTabs } from '@/components/tabs';
import { useGetSingleCompanyProfileBySlug } from '@/services/queries/company';
import PublicCompanyProfile from './company/public-company';
import { useAnalytics } from '@/services/hooks/use-analytics';

function PublicCompanyPage() {
  const params = useParams();
  const { companySlug } = params;
  const { trackEvent } = useAnalytics();
  const { data: companyInformation, isLoading: isCompanyLoading, isFetching: isCompanyFetching } = useGetSingleCompanyProfileBySlug(companySlug, { enabled: !!companySlug && companySlug !== 'null' });
  const [company, setCompany] = useState<Company | null>(null);

  useEffect(() => {
    if (companyInformation && !isCompanyLoading && !isCompanyFetching) {
      const mappedCompany: Company = {
        id: companyInformation.general_information.id || '',
        name: companyInformation.general_information.name,
        overview: companyInformation.overview.overview || '',
        short_description: companyInformation.general_information.description,
        hq_location: companyInformation.general_information.hq_location,
        company_url: companyInformation.general_information.website_url,
        logo_url: companyInformation.general_information.logo_url,
        linkedin_url: companyInformation.general_information.linkedin_url,
        twitter_url: companyInformation.general_information.twitter_url || '',
        instagram_url: companyInformation.general_information.instagram_url,
        industry: companyInformation.general_information.careeros_industry,
        careeros_industry: companyInformation.general_information.careeros_industry,
        tags: companyInformation.general_information.tags || [],
        jobs: [], // Add jobs if available
        company_size: companyInformation.general_information.company_size,
        bg_image_url: companyInformation.general_information.bg_image_url,
        website_url: companyInformation.general_information.website_url,
      };
      setCompany(mappedCompany);
    }
  }, [companyInformation, isCompanyLoading, isCompanyFetching]);

  useEffect(() => {
    if (companyInformation) {
      trackEvent('Premium Company Profile Visit', {}, {
        pageURL: `company/${companySlug}`,
        company_id: companyInformation?.general_information.id,
      });
    }
  }, [companyInformation, companySlug]);

  if (!company && (isCompanyFetching || isCompanyLoading)) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  if (!companyInformation) {
    throw new Error('Company not found');
  }

  return (
    <div className="company-page  company-page--premium">
      {companyInformation && company?.id ? (
        <PublicCompanyProfile
          company={company}
          companyOverview={companyInformation.overview}
          companyFeatures={companyInformation.features}
          events={companyInformation.events}
          culture={companyInformation.culture}
          articlesUrlProfile={companyInformation.articles_url_profile}
          testimonials={null}
          teams={companyInformation.teams || []}
          generalInformation={companyInformation.general_information}
        />
      ) : <>Company Information is not public</>}
    </div>
  );
}

export default withTabs(PublicCompanyPage);
