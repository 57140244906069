import { User } from '@auth0/auth0-react';
import { Link } from 'react-router-dom';
import { FirebaseLinkedinMessage } from '../../types/messages';
import { Contact } from '@/domains/core/contact/types';
import { LinkedinMessage } from './linkedin-message';
import { useSelf } from '@/services/queries/user';

type CalculateMessageProps = {
  message: FirebaseLinkedinMessage;
  user: User;
  contact: Contact;
  selfID: string
};

export const IncorrectMessage = () => (
  <div className="message-not-correct">
    <i className="bi bi-exclamation-octagon" />
    <span>We had some problems fetching some messages from Linkedin (please use </span>

    <Link to="https://share.hsforms.com/1XspFVBkwT2S7dMKLWzrKqg55xla">
      the help feature
    </Link>

    <span> to contact support)</span>
  </div>
);

// TODO: find out if message.type has a relevant type that can be used here to determine if message is sent or received
export const CalculateMessage = ({
  message, contact, user, selfID,
}: CalculateMessageProps) => {
  const { data: self } = useSelf();

  if (message.fromContactID && message.fromContactID === contact.id) {
    return (
      <LinkedinMessage
        messageAuthor={
          contact.career_summary?.basics?.name
        }
        messageCreatedAt={message.createdAt}
        messageText={message.text}
        messageType={message.type}
        avatar={
          contact.career_summary?.basics?.image
        }
        isFromUser={false}
      />
    );
  }
  if (message.fromUserID && message.fromUserID === selfID) {
    return (
      <LinkedinMessage
        messageAuthor={
          user.name || ''
        }
        messageCreatedAt={message.createdAt}
        messageText={message.text}
        messageType={message.type}
        avatar={
          self?.profile_picture_url || user.picture || ''
        }
        isFromUser
      />
    );
  }
  return null;
};
