import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { getUserRoles } from '../helpers/user';

export function useIsBetaUser() {
  const [isBetaUser, setIsBetaUser] = useState(false);
  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      const role = getUserRoles(user);
      if (role.includes('BetaUser')) {
        setIsBetaUser(true);
      }
    }
  }, [user]);

  return {
    isBetaUser,
  };
}
