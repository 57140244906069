import { Helmet } from 'react-helmet';

interface SEOProps {
  title: string;
  description: string;
  company: {
    name: string;
    logo_url?: string;
    hq_location?: string;
  };
  canonicalUrl?: string;
  imageUrl?: string;
}

export const PublicJobSEO = ({
  title,
  description,
  company,
  canonicalUrl = window.location.href,
  imageUrl,
}: SEOProps) => {
  const seoTitle = `${title} at ${company.name} | CareerOS`;
  const sanitizedDescription = typeof description === 'string'
    ? description.replace(/[^\x20-\x7E]/g, '') // Remove non-printable characters
    : `Apply for ${title} position at ${company.name}. Find job details, company information, and application process on CareerOS.`;
  const seoDescription = sanitizedDescription.length > 0
    ? sanitizedDescription.substring(0, 160) // Limit to 160 characters
    : `Apply for ${title} position at ${company.name}. Find job details, company information, and application process on CareerOS.`;
  const seoImage = imageUrl || company.logo_url || '';
  const location = company.hq_location || '';

  return (
    <Helmet>
      {/* Basic metadata */}
      <title>{seoTitle}</title>
      <meta name="description" content={seoDescription} />
      <link rel="canonical" href={canonicalUrl} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={seoTitle} />
      <meta property="og:description" content={seoDescription} />
      <meta property="og:image" content={seoImage} />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="twitter:description" content={seoDescription} />
      <meta name="twitter:image" content={seoImage} />

      {/* Additional metadata */}
      <meta name="geo.region" content={location} />
      <meta name="og:locality" content={location} />
    </Helmet>
  );
};
