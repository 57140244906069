import { useMutation, useQuery, useQueryClient } from 'react-query';
import { connectCampusChampion, getCampusChampions } from '../api/contacts';
import { SuggestedContactType } from '@/domains/core/contact/types';

export const useConnectCampusChampionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(connectCampusChampion, {
    onSuccess: () => {
      queryClient.invalidateQueries('contacts-no-cache');
      queryClient.invalidateQueries('self-campus-champions');
      queryClient.invalidateQueries('gamification-actions');
      queryClient.invalidateQueries('challenges');
      queryClient.invalidateQueries('xp');
      queryClient.invalidateQueries('leaderboards');
    },
  });
};

export const useSelfCampusChampions = () => useQuery('user-campus-champions', async () => {
  const response: SuggestedContactType[] = await getCampusChampions();

  if (!Array.isArray(response)) {
    return [];
  }

  return response;
}, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 24,
});
