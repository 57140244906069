import { DefaultLogo } from '@careeros/coco';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { useSelf } from '@/services/queries/user';

type Props = {
  articlesLink?: string;
};

export const MobileDashboard = ({ articlesLink }: Props) => {
  const { user } = useAuth0();
  const { data: self } = useSelf();
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate('/app/profile');
  };

  const goToJobs = () => {
    navigate('/app/jobs');
  };

  const goToExploreCompanies = () => {
    navigate('/app/companies/explore');
  };

  const goToOverview = () => {
    navigate('/app/overview');
  };

  const goToArticles = () => {
    navigate('/app/articles');
  };

  return (
    <div className="mobile-dashboard">
      <h1 className="mobile-dashboard__greeting">
        Hi
        {' '}
        <span>
          {user?.name || 'Rockstar'}
          !
        </span>
      </h1>
      <p className="mobile-dashboard__text">
        What would you like to do today?
      </p>
      <div className="mobile-dashboard__actions">
        <div onClick={goToProfile} className="mobile-dashboard__action mobile-dashboard__action--profile">
          <div className="mobile-dashboard__action-icon">
            <DefaultLogo size={32} type="contact" source={self?.profile_picture_url || user?.picture || ''} className="" name={user?.name || 'U'} icon="bi bi-gear" />
          </div>
          <h3 className="mobile-dashboard__action-title">{user?.name || 'Profile'}</h3>
        </div>
        <div onClick={goToJobs} className="mobile-dashboard__action">
          <div className="mobile-dashboard__action-icon">
            <i className="bi bi-briefcase" />
          </div>
          <h3 className="mobile-dashboard__action-title">Search jobs</h3>
          <p className="mobile-dashboard__action-description">Check out the latest job postings.</p>
        </div>
        <div onClick={goToExploreCompanies} className="mobile-dashboard__action">
          <div className="mobile-dashboard__action-icon">
            <i className="bi bi-building" />
          </div>
          <h3 className="mobile-dashboard__action-title">Search companies</h3>
          <p className="mobile-dashboard__action-description">Explore exciting companies that hire talent like yourself.</p>
        </div>
        <div onClick={goToOverview} className="mobile-dashboard__action">
          <div className="mobile-dashboard__action-icon">
            <i className="bi bi-kanban" />
          </div>
          <h3 className="mobile-dashboard__action-title">See Overview Board</h3>
          <p className="mobile-dashboard__action-description">Keep track of your companies</p>
        </div>
      </div>
      <div className="mobile-dashboard__desktop-features">
        <h2 className="mobile-dashboard__desktop-features-title">Trust us, it gets better</h2>
        <p className="mobile-dashboard__desktop-features-description">Check out CareerOS on desktop for more!</p>
        <div className="mobile-dashboard__desktop-features-list">
          <div className="mobile-dashboard__desktop-features-item">
            <i className="mobile-dashboard__desktop-icon bi bi-chat-text" />
            <p className="mobile-dashboard__desktop-title">Inbox</p>
            <p className="mobile-dashboard__desktop-text">Network in our dual LinkedIn-Email Inbox.</p>
          </div>
          <div className="mobile-dashboard__desktop-features-item">
            <i className="mobile-dashboard__desktop-icon bi bi-layout-text-window" />
            <p className="mobile-dashboard__desktop-title">ResumeOS</p>
            <p className="mobile-dashboard__desktop-text">Tailor your resume to perfection.</p>
          </div>
          <div className="mobile-dashboard__desktop-features-item">
            <i className="mobile-dashboard__desktop-icon bi bi-stars" />
            <p className="mobile-dashboard__desktop-title">AI Coaching</p>
            <p className="mobile-dashboard__desktop-text">Create outreach templates using our AI assistant.</p>
          </div>
        </div>
      </div>
      {articlesLink && (
        <div onClick={goToArticles} className="mobile-dashboard__action mobile-dashboard__action--full">
          <div className="mobile-dashboard__action-icon">
            <i className="bi bi-newspaper" />
          </div>
          <h3 className="mobile-dashboard__action-title">Career Sip Articles</h3>
          <p className="mobile-dashboard__action-description">Explore insightful articles curated to help you succeed</p>
        </div>
      )}
    </div>

  );
};
