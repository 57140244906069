import {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { Student } from '@/domains/core/advisor/types';
import { buildStatusOptionsAndMappings, getSelectedStatusLabel } from '../utils/status-options';

export const useResumeStudentFilters = ({
  students,
}: { students: Student[] }) => {
  const [filteredStudents, setFilteredStudents] = useState<Student[]>([]);
  // Local state for filters
  const [searchValue, setSearchValue] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string>('All Statuses');
  const [scoreRange, setScoreRange] = useState<[number, number]>([0, 100]);

  const { statusOptions, statusLabelToValueMap } = useMemo(() => buildStatusOptionsAndMappings(), []);
  const selectedStatusLabel = useMemo(() => getSelectedStatusLabel(selectedStatus), [selectedStatus]);

  // Core filtering function
  const filterStudents = useCallback((): Student[] => students
    .filter(Boolean)
    .filter((student: Student) => {
      // Search filter
      const fullName = `${student.first_name} ${student.last_name}`;
      const matchesSearch = !searchValue
        || fullName.toLowerCase().includes(searchValue.toLowerCase().trim())
        || student.email.toLowerCase().includes(searchValue.toLowerCase().trim());

      // Status filter
      let matchesStatus;
      if (selectedStatusLabel === 'All Statuses') {
        matchesStatus = true;
      } else if (selectedStatusLabel === 'No Requests') {
        matchesStatus = student.status === 'RESUME_INVALID';
      } else {
        matchesStatus = student.status === selectedStatus;
      }

      // Score filter
      const matchesScore = student.max_score !== undefined
        ? student.max_score >= scoreRange[0] && student.max_score <= scoreRange[1]
        : true;

      return matchesSearch && matchesStatus && matchesScore;
    }), [students, searchValue, selectedStatus, selectedStatusLabel, scoreRange]);

  useEffect(() => {
    setFilteredStudents(filterStudents());
  }, [filterStudents]);

  // Filter handlers
  const handleFilterChange = useCallback((filters: { search?: string; status?: string; scoreRange?: [number, number] }) => {
    if (filters.search !== undefined) {
      setSearchValue(filters.search);
    }
    if (filters.status !== undefined) {
      const statusValue = statusLabelToValueMap.get(filters.status) || 'All Statuses';
      setSelectedStatus(statusValue);
    }
    if (filters.scoreRange !== undefined) {
      setScoreRange(filters.scoreRange);
    }
  }, [statusLabelToValueMap]);

  return {
    // Filter states
    searchValue,
    selectedStatus,
    selectedStatusLabel,
    scoreRange,
    // Filter options
    statusOptions,
    // Filtered results
    filteredStudents,
    // Filter handlers
    handleFilterChange,
  };
};
