import { DefaultLogo } from '@careeros/coco';
import { ConnectionStatus } from '@/domains/core/contact/types';
import './readonly-contact-kanban-tile.scss';
import { StatusTag } from '@/domains/core/contact/components/status-tags/status-tag';

type Props = {
  name: string;
  image: string;
  companyName: string;
  position: string;
  linkedInConnectionStatus: ConnectionStatus;
  isArchived?: boolean;
};

export const ReadonlyContactKanbanTile = ({
  image,
  name,
  companyName,
  position,
  linkedInConnectionStatus,
  isArchived = false,
}: Props) => (
  <div className="readonly-contact-kanban-tile">
    <div className="readonly-contact-kanban-tile__top">
      <div className="readonly-contact-kanban-tile__header">
        <div className="readonly-contact-kanban-tile__avatar">
          <DefaultLogo
            type="contact"
            source={image}
            className="readonly-contact-kanban-tile__avatar"
            name={name}
            size={48}
          />
        </div>
        <div className="readonly-contact-kanban-tile__info">
          <div className={`readonly-contact-kanban-tile__name ${isArchived ? 'readonly-contact-kanban-tile__name--archived' : ''}`}>{name}</div>
          <div className="readonly-contact-kanban-tile__company">{companyName}</div>
          <div className="readonly-contact-kanban-tile__company">{position}</div>
        </div>
      </div>

      <div className="readonly-contact-kanban-tile__status">
        {!isArchived && <StatusTag status={linkedInConnectionStatus} type="linkedin" size="small" withTooltip />}
      </div>
    </div>
  </div>
);
