import { useParams } from 'react-router-dom';
import { IndustryType, NetworkingType } from '@/domains/core/advisor/types';
import { useSelfUniversity } from '../queries/user';
import {
  useGetCohortsByUniversityID,
} from '../queries/student';
import {
  // useUniversityAnalyticsAllCompanies,
  useUniversityAnalyticsIndustry, useUniversityAnalyticsNetworking, useUniversityAnalyticsTopCompanies,
} from '../queries/advisor';
import { industriesObject } from '@/domains/core/advisor';
import { processTop10CompaniesData } from '@/domains/core/advisor/utils/process-company-data';

const computeIndustryData = (industries: IndustryType) => {
  const sortedIndustries = Object.keys(industries).sort((a, b) => industries[b].length - industries[a].length);

  const industriesValues = sortedIndustries.map((industry: string) => industries[industry].length || 0);
  const allIndustries = industriesObject.map((industry: any) => industry.name);

  return {
    industries: sortedIndustries,
    industriesCompanies: industries,
    industryData: {
      labels: [...sortedIndustries, ...allIndustries.filter((industry: any) => !sortedIndustries.includes(industry))],
      datasets: [{
        label: 'Saved Companies',
        data: industriesValues,
        backgroundColor: '#06A47E',
        color: '#ffffff',
      }],
    },
  };
};

const computeNetworkingData = (networkingData: NetworkingType | undefined) => {
  if (!networkingData) {
    return {
      labels: ['Networking', 'Not Networking'],
      datasets: [
        {
          label: '% of Students Networking',
          data: [0, 100],
        },
      ],
    };
  }
  const networkingStudentsPercentage = Math.floor((networkingData.networking_students / networkingData.total_students) * 100);
  return {
    labels: ['Networking', 'Not Networking'],
    datasets: [
      {
        label: '% of Students Networking',
        data: [networkingStudentsPercentage, 100 - networkingStudentsPercentage],
        backgroundColor: [
          '#98A6FD',
          '#484F7C',

        ],
      },
    ],
  };
};

export const useDashboardAnalytics = (universityID?: string) => {
  const { data: university } = useSelfUniversity();
  const currentUniversityID = universityID || university?.id || '';
  const { cohortID } = useParams();

  const { data: cohorts, isLoading: isCohortsLoading } = useGetCohortsByUniversityID(currentUniversityID, {
    enabled: !!currentUniversityID,
  });

  const { data: industryData, isLoading: isIndustryDataLoading, isError: isIndustryDataError } = useUniversityAnalyticsIndustry(
    cohortID || '',
  );

  const { data: topCompaniesData, isLoading: isTopCompaniesLoading, isError: isTopCompaniesError } = useUniversityAnalyticsTopCompanies(
    cohortID || '',
  );

  const { data: networkingData, isLoading: isNetworkingDataLoading } = useUniversityAnalyticsNetworking(
    cohortID || '',
  );

  // const { data: allCompanies, isLoading: isAllCompaniesLoading } = useUniversityAnalyticsAllCompanies(
  //   cohortID || '',
  // );

  return {
    cohorts,
    isCohortsLoading,
    industryData: industryData && !isIndustryDataError ? computeIndustryData(industryData as IndustryType) : null,
    isIndustryDataLoading,
    isTopCompaniesLoading,
    topCompaniesData: topCompaniesData && !isTopCompaniesError ? processTop10CompaniesData(topCompaniesData) : null,
    isNetworkingDataLoading,
    networkingData: computeNetworkingData(networkingData),
    // allCompanies,
    // isAllCompaniesLoading,
  };
};
