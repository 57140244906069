import { SetURLSearchParams } from 'react-router-dom';

export const updateURLParams = (
  search: string,
  status: string,
  scores: [number, number],
  searchParams: URLSearchParams,
  setSearchParams: SetURLSearchParams,
) => {
  const params = new URLSearchParams(searchParams);

  // Clear existing parameters
  ['search', 'status', 'minScore', 'maxScore'].forEach(key => {
    params.delete(key);
  });

  // Add parameters only if they differ from default values
  if (search) params.set('search', search);
  if (status !== 'All Statuses') params.set('status', status);
  if (scores[0] !== 0) params.set('minScore', scores[0].toString());
  if (scores[1] !== 100) params.set('maxScore', scores[1].toString());

  setSearchParams(params, { replace: true });
};
