import { InfoModal } from '@careeros/coco';

type Props = {
  handleArchiveContact: (rememberAction: boolean) => void;
  closeModal: () => void;
};

export const ArchiveContactModal = ({
  handleArchiveContact,
  closeModal,
}: Props) => (
  <InfoModal
    icon="bi bi-exclamation-triangle"
    handleButtonClick={handleArchiveContact}
    handleSecondaryButtonClick={closeModal}
    title="Archive Contact?"
    description="Archiving means that your contact will now be inactive. To view your archived contacts, visit the Archived column on the Contacts page."
    buttonLabel="Archive"
    secondaryButtonLabel="Cancel"
    rememberText="Remember my choice"
    buttonIcon="bi bi-archive"
    isButtonDanger
    isImageDanger
  />
);
