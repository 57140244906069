import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
  IconButton, Tooltip, RangeBadge,
} from '@careeros/coco';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { downloadPDF } from '../../../resume/helpers/save-resume';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { Tag } from '@/components/tag/tag';
import { BaseResumeListingResponse, ResumeStatus, ResumeStatuses } from '../../../resume/types';
import { getResumeStatusesForAdvisors } from '../../../resume/utils/resume-statuses';
import '../../../resume/components/resume-listing-box/resume-listing-box.scss';

type ResumeAdvisorListingBoxProps = {
  studentID?: string;
  resume: BaseResumeListingResponse;
  type: 'base' | 'tailored';
  isValid: boolean;
};

export const ResumeAdvisorListingBox = ({
  resume,
  type,
  studentID,
  isValid,
}: ResumeAdvisorListingBoxProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const seeResume = () => {
    if (type === 'tailored') {
      navigate(`/app/resume-os/student/${studentID}/tailored/${resume.id}`);
      return;
    }
    navigate(`/app/resume-os/student/${studentID}/base/${resume.id}`);
    trackEvent('CA Student Resume Opened');
  };

  const downloadResume = async (
    event: React.MouseEvent<HTMLButtonElement>,
    url: string,
  ) => {
    event.stopPropagation();
    try {
      const fileName = resume.title;
      await downloadPDF(url, fileName);

      trackEvent('CA Student Resume Downloaded');
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const resumeStatuses = getResumeStatusesForAdvisors();

  return (
    <div
      className="resume-listing-box resume-listing-box--advisor"
      onClick={seeResume}
    >
      <div className="resume-listing-box__image-container">
        <img
          className="resume-listing-box__image"
          src={resume?.preview_url || resume.resume_template.preview_image}
          alt="Template"
        />
      </div>
      <div className="resume-listing-box__title">{resume.title}</div>
      <div className="resume-listing-box__subtitle">
        Updated
        {' '}
        {formatDateDeltaFromNow(resume.updated_at)}
      </div>
      <div className="resume-listing-box__comments">
        <i className="bi bi-chat-left-dots" />
        {`${resume.comments_count} Open Comment(s)`}
      </div>
      <div className="resume-listing-box__type">
        {!isValid && type === 'base' && (
          <div className="resume-listing-box__type-container">
            <Tooltip label="Resume still needs improvements" position="top">
              <Tag label="Invalid" color="red" />
            </Tooltip>
          </div>
        )}
        {type === 'base' ? (
          <Tag label="Base Resume" color="blue-green" />
        ) : (
          <Tag label="Tailored Resume" color="light-blue" />
        )}
        {(type === 'base' && resume.status === ResumeStatuses.ReadyForReview && isValid) && (
          <div className="resume-listing-box__type-container">
            <Tooltip
              label={resumeStatuses[resume.status as ResumeStatus]?.tooltip}
              position="top"
            >
              <Tag
                label={resumeStatuses[resume.status as ResumeStatus]?.label}
                color={resumeStatuses[resume.status as ResumeStatus]?.color}
              />
            </Tooltip>
          </div>
        )}
        {(type === 'base' && resume.status !== ResumeStatuses.ReadyForReview) && (
          <div className="resume-listing-box__type-container">
            <Tooltip
              label={resumeStatuses[resume.status as ResumeStatus]?.tooltip}
              position="top"
            >
              <Tag
                label={resumeStatuses[resume.status as ResumeStatus]?.label}
                color={resumeStatuses[resume.status as ResumeStatus]?.color}
              />
            </Tooltip>
          </div>
        )}
      </div>
      {type === 'tailored' && resume.job && (
        <div className="resume-listing-box__job">{resume.job.title}</div>
      )}
      {type === 'base' && (
        <div className="resume-listing-box__score">
          <RangeBadge
            label={`${resume.score || '0'} pts`}
            percentageOfCompletion={resume.score || 0}
            rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']}
          />
        </div>
      )}
      <div className="resume-listing-box__actions">
        <Tooltip withArrow label="Add Comment" position="top">
          <IconButton
            icon="bi bi-chat-right-dots"
            outlined
            mode="rounded"
            handleClick={seeResume}
            size="medium"
          />
        </Tooltip>
        <Tooltip withArrow label={resume.source_url ? 'Download Resume' : 'Expired. The student has to save the resume manually to enable downloading'} position="top">
          <IconButton
            icon="bi bi-download"
            onClick={(event) => downloadResume(event, resume.source_url)}
            outlined
            mode="rounded"
            size="medium"
            disabled={!resume.source_url}
          />
        </Tooltip>
      </div>
    </div>
  );
};
