import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { IconButton, Tooltip, Counter } from '@careeros/coco';
import './kanban-board.scss';
import { KanbanBoardLayout, MiniAdvisor, StudentStatus } from '@/domains/core/advisor/types';
import { StudentKanbanTile } from '../student-tile/student-tile';
import { useAnalytics } from '@/services/hooks/use-analytics';

export interface KanbanBoardProps {
  layout: KanbanBoardLayout;
  // TODO: add enum/type here
  warningColumns: string[];
  sendMessagePerColumn?: (stage: StudentStatus) => void;
  messageButtonTooltip?: string,
  universityId: string;
  allAdvisors: MiniAdvisor[];
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  showSendButton?: boolean;
}

export const AdvisorKanbanBoard = ({
  layout,
  warningColumns,
  sendMessagePerColumn,
  messageButtonTooltip,
  universityId,
  allAdvisors,
  scrollContainerRef: kanbanContainer,
  onScroll: handleScroll,
  showSendButton = false,
}: KanbanBoardProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const handleTileClick = useCallback((studentId: string) => {
    navigate(`/app/advisor/students/student-overview/${studentId}/university/${universityId}`);
    trackEvent('CA Student Tile clicked');
  }, [universityId]);

  return (
    <div className="students-kanban-board">
      <div className="students-kanban-board__content" ref={kanbanContainer} onScroll={handleScroll}>
        {
          [...layout.entries()].map(([column, columnValues], index) => (
            <div
              key={column}
              className={`students-kanban-board__column students-kanban-board__column--nr-${index}`}
            >
              <div className="students-kanban-board__column-header">
                <div className={`students-kanban-board__title ${warningColumns.includes(column) ? 'students-kanban-board__title--warning' : ''}`}>
                  <span>{column}</span>
                  <span className="students-kanban-board__counter">
                    <Counter counter={columnValues && columnValues.length} size="medium" mode="default" />
                  </span>
                </div>
                {(showSendButton && sendMessagePerColumn && columnValues.length > 0) && (
                  <div className="students-kanban-board__message-button">
                    <Tooltip
                      label={messageButtonTooltip || 'Send message'}
                      disableHoverListener={!messageButtonTooltip}
                      position="top"
                    >
                      <IconButton
                        mode="primary"
                        size="small"
                        icon="bi bi-send"
                        outlined
                        handleClick={() => sendMessagePerColumn(column)}
                      />
                    </Tooltip>
                  </div>
                )}
              </div>

              {columnValues && columnValues.map((student) => (
                <div className="students-kanban-board__tile">
                  <StudentKanbanTile
                    key={student.id}
                    id={student.id}
                    image={student.profileImage}
                    title={student.firstName || student.lastName
                      ? `${student.firstName} ${student.lastName}`
                      : student.email}
                    subtitle={student.cohort?.name}
                    handleTileClick={handleTileClick}
                    universityId={universityId}
                    assignedAdvisors={student.assignedAdvisors || []}
                    allAdvisors={allAdvisors}
                  />
                </div>
              ))}
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default AdvisorKanbanBoard;
