import {
  Button, IconButton, Tooltip, DefaultLogo,
} from '@careeros/coco';
import { LinkButton } from '@/components/link/link';
import { Contact } from '@/domains/core/contact/types';

type CompanySavedContactsProps = {
  applicationStatus: string;
  contacts: Contact[];
  linkToAddContacts: string;
  goToContact: (contactID: string) => void;
};

const CompanySavedContacts = ({
  applicationStatus, contacts, linkToAddContacts, goToContact,
}: CompanySavedContactsProps) => {
  const saveMoreContacts = () => {
    window.open(linkToAddContacts, '_blank');
  };

  return (
    <div className="company-status-container__contacts-container">
      <div className="networking-widget">
        <div className="networking-widget__header">
          <h3 className="networking-widget__title">Saved Contacts</h3>
          {(applicationStatus && Array.isArray(contacts) && contacts.length > 0) && (
            <Button
              handleClick={saveMoreContacts}
              label="Save more"
              size="medium"
              icon="bi bi-box-arrow-up-right"
              mode="text"
              iconPosition="right"
            />
          )}
        </div>
        <div className="networking-widget__content">
          {(applicationStatus && Array.isArray(contacts) && contacts.length > 0) && (
            <div className="networking-widget__contacts">
              {contacts.map((contact) => (
                <div className="networking-widget__contact">
                  <DefaultLogo
                    type="contact"
                    source={contact.career_summary?.basics?.image}
                    handleClick={() => goToContact(contact.id)}
                    className={`networking-widget__contact__image ${contact.is_campus_champion ? 'networking-widget__contact__image--campus-champion' : ''}`}
                    name={`${contact.first_name} ${contact.last_name}`}
                    size={38}
                  />
                  <div className="networking-widget__contact__info">
                    <h4 className="networking-widget__contact__name">
                      <span className="networking-widget__contact__name-text">
                        {`${contact.first_name} ${contact.last_name}`}
                      </span>

                      {contact.is_campus_champion && (
                        <Tooltip label="Campus Champion" position="top">
                          <span>
                            ⭐
                          </span>
                        </Tooltip>
                      )}
                    </h4>
                    <span className="networking-widget__contact__label">{contact.current_position.title}</span>
                  </div>
                  <IconButton handleClick={() => goToContact(contact.id)} outlined mode="rounded" size="small" icon="bi bi-chat-text" />
                </div>
              ))}
            </div>
          )}

          {(applicationStatus && !contacts.length) && (
            <LinkButton
              link={linkToAddContacts}
              label="Add Contact"
              icon="bi bi-plus-circle"
              target="_blank"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanySavedContacts;
