/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, Link, StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema, Work } from '../../types';

import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';
import {
  computeSkillsStyle, renderAdditionalHighlightedText, renderWorkHighlightedText, trimUrlPrefix,
} from '../helpers/utils';
import { formatDateWithFullYear, formatDateWithFullYearOptionalField } from '../helpers/formate-date-templates';

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: 400 }, { src: CalibriBold, fontWeight: 700 }, { src: CalibriItalic, fontStyle: 'italic' }] });

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Calibri',
    width: '100%',
    height: '100%',
    paddingVertical: '40px',
    paddingHorizontal: '56px',
    margin: 0,
  },
  document: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    border: 0,
    background: 'white',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  title: {
    fontSize: 14,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'center',
    width: '100%',
  },
  block: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginTop: 4,
  },
  blockTitle: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textTransform: 'uppercase',
    textAlign: 'left',
    fontWeight: 700,
    width: '100%',
    color: '#000',
    borderTop: '1px solid #000',
    paddingTop: 4,
  },
  infoBlock: {
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    rowGap: 5,
  },
  rowWithScore: {
    flexDirection: 'row',
    rowGap: 2,
    marginRight: 80,
    flexWrap: 'wrap',
  },
  EdRow: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  Edinstitution: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#000000',
    marginRight: 80,
  },
  EdLocation: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    textAlign: 'right',
    color: '#000',
    maxWidth: 100,
  },
  EdstudyType: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    textAlign: 'left',
    color: '#000',
  },
  EdstudyScore: {
    fontSize: 10,
    fontFamily: 'Calibri',
    color: '#000',
    fontWeight: 'normal',
  },
  EdDates: {
    fontSize: 10,
    fontFamily: 'Calibri',
    textAlign: 'right',
    color: '#000000',
  },
  EdDescription: {
    fontSize: 10,
    width: '100%',
    height: 'auto',
    color: '#000000',
  },
  skillsBlock: {
    marginBottom: 6,
  },
  skills: {
    fontSize: 10,
  },
  workInstitution: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000',
    textAlign: 'left',
    marginRight: 50,
  },
  workLocation: {
    fontSize: 10,
    textAlign: 'right',
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    color: '#000',
    maxWidth: 100,
  },
  workPosition: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontWeight: 'bold',
    color: '#000000',
    marginRight: 80,
  },
  workSummary: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontStyle: 'italic',
    color: '#000000',
  },
  workDescription: {
    fontSize: 10,
    fontFamily: 'Calibri',
    fontStyle: 'italic',
    color: '#000000',
  },
  workDates: {
    fontSize: 10,
    color: '#000000',
    textAlign: 'right',
  },
  highlights: {
    marginTop: 2,
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bulletPoint: {
    width: 18,
    fontSize: 10,
  },
  highlightText: {
    fontSize: 10,
    color: '#000000',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    height: 'auto',
  },
  headerTopSide: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    columnGap: 2,
    marginTop: 0,
  },
  headerSummary: {
    fontSize: 10,
    fontFamily: 'Calibri',
    color: '#000',
    textAlign: 'center',
    width: '100%',
    marginTop: 0,
    marginBottom: '6px',
  },
  headerTopSideText: {
    fontSize: 10,
    fontFamily: 'Calibri',
    color: '#000',
    textAlign: 'center',
  },
  link: {
    color: '#000',
    textDecoration: 'none',
  },
  blockWithBorder: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: 5,
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null
};

// Create Document Component
const MarylandMsTemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => {
  let previousWorkName = '';
  return (
    <Document title={title} style={styles.document}>
      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>{resume.basics.name || ''}</Text>
        <View style={styles.header}>
          <View style={styles.headerTopSide}>
            {resume.basics.phone_numbers?.map((phone) => (
              <Text key={phone} style={styles.headerTopSideText}>
                {phone}
                {' '}
                ●
              </Text>
            ))}
            {resume.basics?.emails?.map((email) => (
              <Text key={email} style={styles.headerTopSideText}>
                <Link style={styles.link} key={email} href={`mailto:${email}`}>
                  {email}
                  {' '}
                  ●
                </Link>
              </Text>
            ))}
            <Text style={styles.headerTopSideText}>
              <Link style={styles.link} key={resume.basics.url} href={`https://www.${resume.basics.url && (trimUrlPrefix(resume.basics.url))}`}>
                {resume.basics.url && (trimUrlPrefix(resume.basics.url))}
              </Link>
            </Text>
          </View>
        </View>
        {resume.basics.work_permit && (
          <View>
            <Text style={styles.headerSummary}>
              {resume.basics.work_permit}
            </Text>
          </View>
        )}
        <View style={styles.block}>
          <View style={styles.blockWithBorder}>
            <Text style={styles.blockTitle}>EDUCATION</Text>
          </View>
          {resume.education?.map((item) => (
            <View key={item.institution} style={styles.infoBlock}>
              <View style={styles.row}>
                <Text style={styles.Edinstitution}>
                  {item.institution}
                </Text>
                <Text style={styles.EdLocation}>
                  {item.location}
                </Text>
              </View>
              <View style={styles.row}>
                <View style={styles.rowWithScore}>
                  <Text>
                    <Text style={styles.EdstudyType}>{item.area}</Text>
                    {item.score && (
                      <Text style={styles.EdstudyScore}>
                        ,
                        {' '}
                        {item.score}
                      </Text>
                    )}
                  </Text>
                </View>
                <Text style={styles.EdDates}>
                  {formatDateWithFullYear(item.endDate)}
                </Text>
              </View>
              {item.description && item.description.map((desc) => (
                <View key={desc} style={styles.EdRow}>
                  <Text style={styles.bulletPoint}>•</Text>
                  <Text style={styles.EdDescription}>
                    {desc}
                  </Text>
                </View>
              ))}
            </View>
          ))}
        </View>
        <View style={styles.block}>
          <View style={styles.blockWithBorder}>
            <Text style={styles.blockTitle}>TECHNICAL SKILLS</Text>
          </View>
          <View style={[computeSkillsStyle(highlightedWords, activeField, styles), styles.skillsBlock]}>
            <Text style={styles.skills}>{`Technical Skills such as ${resume.skills?.map((skill) => `${skill.name}`).join(', ')}`}</Text>
          </View>
        </View>
        <View style={styles.block}>
          {resume.work_section?.order.map((item, index) => {
            if (item.type === 'heading') {
              // Explicitly cast the value to string
              const headingValue = item.value as string;
              return (
                <View style={styles.blockWithBorder}>
                  <Text key={`heading-${index}`} style={styles.blockTitle}>
                    {headingValue}
                  </Text>
                </View>
              );
            }

            const work = item.value as Work;
            const showWorkName = work.name !== previousWorkName;
            previousWorkName = work.name as string;

            return (
              <View key={`${work.name}-${index}`} style={styles.infoBlock}>
                {showWorkName && (
                  <View style={styles.row}>
                    <Text style={styles.workInstitution}>
                      {work.name}
                    </Text>
                    {work.location ? (
                      <Text style={styles.workLocation}>
                        {work.location}
                      </Text>
                    ) : (
                      (work.startDate || work.endDate) && (
                        <Text style={styles.workDates}>
                          {formatDateWithFullYearOptionalField(work.startDate)}
                          {(work.startDate && work.endDate) && ' - '}
                          {work.endDate === 'Present' ? work.endDate : formatDateWithFullYearOptionalField(work.endDate)}
                        </Text>
                      )
                    )}
                  </View>
                )}
                <View style={styles.row}>
                  <View>
                    <Text style={styles.workPosition}>
                      {work.position}
                    </Text>
                    {work.summary && (
                      <Text style={styles.workSummary}>
                        {work.summary}
                      </Text>
                    )}
                  </View>
                  {((work.startDate || work.endDate) && work.location) && (
                    <Text style={styles.workDates}>
                      {formatDateWithFullYearOptionalField(work.startDate)}
                      {(work.startDate && work.endDate) && ' - '}
                      {work.endDate === 'Present' ? work.endDate : formatDateWithFullYearOptionalField(work.endDate)}
                    </Text>
                  )}
                </View>
                {work.highlights && work.highlights.length > 0 && (
                  <View style={styles.highlights}>
                    {work.highlights.map((highlight, highlightIndex) => {
                      const cleanedHighlight = highlight.replace(/^•\s/, '');
                      const actualWorkIndex = resume.work_section.order
                        .slice(0, index + 1)
                        .filter(i => i.type === 'work')
                        .length - 1;

                      return (
                        <View key={`${highlight}-${highlightIndex}`} style={styles.listItem} wrap={false}>
                          <Text style={styles.bulletPoint}>•</Text>
                          <View style={styles.highlightText}>
                            {renderWorkHighlightedText(cleanedHighlight, highlightIndex, actualWorkIndex, highlightedWords, activeField, styles)}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            );
          })}
        </View>
        {resume.additional_information?.bullets?.filter(bullet => bullet.trim().length > 0).length > 0
          && (
          <View style={styles.block}>
            <View style={styles.blockWithBorder}>
              <Text style={styles.blockTitle}>distinctions</Text>
            </View>
            <View style={styles.infoBlock}>
              <View style={styles.highlights}>
                {resume.additional_information.bullets?.map((bullet, bulletIndex) => bullet.replace(/^•\s/, '') && (
                  <View key={`${bullet}-${bulletIndex}`} style={styles.listItem} wrap={false}>
                    <Text style={styles.bulletPoint}>•</Text>
                    <View style={styles.highlightText}>
                      {renderAdditionalHighlightedText(bullet.replace(/^•\s/, ''), bulletIndex, highlightedWords, activeField, styles)}
                    </View>
                  </View>
                ))}
              </View>
            </View>
          </View>
          )}
      </Page>
    </Document>
  );
};

export default MarylandMsTemplate;
