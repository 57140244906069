import { statuses } from '@/domains/core/tracker-data';
import HeartEyesEmoji from '@/assets/images/icons/emojis/smiling-face-with-heart-shaped-eyes.png';
import SmilingEmoji from '@/assets/images/icons/emojis/smiling-face-with-smiling-eyes.png';
import NeutralEmoji from '@/assets/images/icons/emojis/neutral-face.png';
import DottedEmoji from '@/assets/images/icons/emojis/dotted-line-face.png';

export const hypeLevelOptions = [
  {
    name: <img src={HeartEyesEmoji} alt="" />,
    value: 3,
  },
  {
    name: <img src={SmilingEmoji} alt="" />,
    value: 2,
  },
  {
    name: <img src={NeutralEmoji} alt="" />,
    value: 1,
  },
  {
    name: <img src={DottedEmoji} alt="" />,
    value: 0,
  },
];

export const postingScoreOptions = [
  {
    name: '🟢 Hiring, and for positions I\'m interested in',
    value: 3,
  },
  {
    name: '🟡 Hiring, but for other positions',
    value: 2,
  },
  {
    name: '🔴 Not hiring right now',
    value: 1,
  },
];

const activeStatusOptions = Object.entries(statuses).filter(([key]) => key !== 'Archived');
export const activeStatuses = Object.fromEntries(activeStatusOptions);

export const defaultFilters = {
  industry: [],
  hype_level: [],
  contacts_saved: [],
  jobs_saved: [],
  status: [],
  hiring_score: [],
};
