import { useState, useMemo } from 'react';
import { ToggleViews } from '@careeros/coco';

import { Loader } from '@/components/loader/loader';
import '../styles/gamification.scss';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';

import { useGamification } from '@/services/hooks/use-gamification';
import { GamificationLeaderboard } from '@/domains/core/student/components/leaderboard/gamification-leaderboard';
import { LeaderboardType } from '@/types/gamification';

function LeaderboardsPage() {
  const {
    leaderboards,
    isLeaderboardsLoading,
    isLeaderboardsError,
  } = useGamification();

  type LeaderboardTypeInfo = {
    type: LeaderboardType;
    dataKey: 'xp_leaderboards' | 'challenges_leaderboards' | 'messages_leaderboards';
    label: string;
  };

  const leaderboardTypes: LeaderboardTypeInfo[] = useMemo(() => [
    {
      type: 'xp',
      dataKey: 'xp_leaderboards',
      label: 'XP Leaderboards',
    },
    {
      type: 'challenges',
      dataKey: 'challenges_leaderboards',
      label: 'Challenge Leaderboards',
    },
    {
      type: 'messages',
      dataKey: 'messages_leaderboards',
      label: 'Messaging Leaderboards',
    },
  ], []);

  const [selectedType, setSelectedType] = useState<LeaderboardTypeInfo['type']>(leaderboardTypes[0].type);

  const currentLeaderboards = useMemo(() => {
    const currentTypeInfo = leaderboardTypes.find(lt => lt.type === selectedType);
    if (!currentTypeInfo || !leaderboards) {
      return [];
    }
    const data = leaderboards[currentTypeInfo.dataKey] || [];
    return data.filter((leaderboard) => Boolean(leaderboard.ranking) && leaderboard.ranking.length >= 3);
  }, [leaderboards, selectedType, leaderboardTypes]);

  const handleLeaderboardTypeChange = (index: number) => {
    setSelectedType(leaderboardTypes[index].type);
  };

  const selectedIndex = useMemo(() => leaderboardTypes.findIndex(lt => lt.type === selectedType), [leaderboardTypes, selectedType]);
  const currentLabel = useMemo(() => leaderboardTypes[selectedIndex]?.label || '', [leaderboardTypes, selectedIndex]);

  if (isLeaderboardsLoading) {
    return (
      <div className="suggested-contacts-loading">
        <Loader />
      </div>
    );
  }
  if (isLeaderboardsError) {
    return (
      <div className="suggested-contacts-loading">
        <h2>Error loading leaderboards</h2>
      </div>
    );
  }

  return (
    <div className="gamification-challenges">
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="leaderboards-toggle">
            <ToggleViews
              labels={['XP', 'Challenges', 'Messages']}
              size="small"
              selectedValue={selectedIndex}
              emitSelectedValue={handleLeaderboardTypeChange}
              icons={['bi-trophy', 'bi-patch-check', 'bi-chat-dots']}
              iconsSelected={['bi-trophy-fill', 'bi-patch-check-fill', 'bi-chat-dots-fill']}
            />
          </div>
          <div className="leaderboard-container">
            <div className="leaderboard-container__header">
              <h2 className="leaderboard-container__title">🏆 CareerOS Leaderboards 🏆</h2>
            </div>
            <div className="leaderboard-container__description">
              {currentLabel}
            </div>
            <div className="leaderboard-container__leaderboards">
              {currentLeaderboards.length > 0 ? (
                currentLeaderboards.map((leaderboard) => (
                  <div className="leaderboard-container__leaderboard" key={leaderboard.title}>
                    <GamificationLeaderboard
                      leaderboard={leaderboard}
                      type={selectedType}
                    />
                  </div>
                ))
              ) : (
                <div className="leaderboard-container__empty">
                  <p>No leaderboards available for this category</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(LeaderboardsPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
