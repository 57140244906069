import {
  BayesFields,
  BocconiStandardFields,
  DefaultWithoutExtracurricularActivities,
  EBS2PageFields,
  EBSFields,
  ESMTFields,
  HHLFields,
  HultFields,
  HultFieldsWithoutLanguages,
  MarylandMbaFields,
  MarylandMsFields,
  MarylandUgFields,
  MBSBachelorsFields,
  MBSMBAFields,
  RiceFields,
} from '../types/resume-templates-types';

export const HULT_FIELDS: HultFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: false,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: false,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
};

export const HULT_MBAN_FIELDS: HultFieldsWithoutLanguages = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: true,
    nationality: false,
    work_permit: false,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: false,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
};

export const HULT_EMBA_FIELDS: HultFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: false,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: false,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
};

export const MARYLAND_UG_FIELDS: MarylandUgFields = {
  basics: {
    emails: true,
    image: false,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: false,
    github_url: false,
    nationality: false,
    work_permit: false,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: false,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: false,
    name: true,
  }],
};

export const MARYLAND_MBA_FIELDS: MarylandMbaFields = {
  basics: {
    emails: true,
    image: false,
    label: false,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: true,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: false,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const MARYLAND_MBA_2_PAGE_FIELDS: MarylandMbaFields = {
  basics: {
    emails: true,
    image: false,
    label: false,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: true,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: false,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const MARYLAND_MS_FIELDS: MarylandMsFields = {
  basics: {
    emails: true,
    image: false,
    label: false,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: true,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: false,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const BAYES_FIELDS: BayesFields = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: false,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: false,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: false,
    birth_date: false,
    availability: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
};

export const ESMT_FIELDS: ESMTFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: true,
    url: true,
    github_url: false,
    nationality: true,
    work_permit: false,
    birth_date: true,
    profile_picture_url: true,
    avatar_url: true,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work_section_subheading: {
    value: true,
  },
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
  skills_subheading: {
    value: true,
  },
};

export const HHL_EDUCATION_FIELDS: HHLFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: true,
    work_permit: false,
    birth_date: true,
    profile_picture_url: false,
    avatar_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
  extracurricular_activities: [{
    name: true,
    description: true,
    startDate: true,
    endDate: true,
    location: true,
    institution: true,
    role: true,
  }],
  certificates: [{
    name: true,
    date: true,
    issuer: false,
    url: false,
  }],
};

export const HHL_WORK_FIELDS: HHLFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: true,
    work_permit: false,
    birth_date: true,
    profile_picture_url: false,
    avatar_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
  extracurricular_activities: [{
    name: true,
    description: true,
    startDate: true,
    endDate: true,
    location: true,
    institution: true,
    role: true,
  }],
  certificates: [{
    name: true,
    date: true,
    issuer: false,
    url: false,
  }],
};

export const HHL_ONE_PAGE_FIELDS: HHLFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: true,
    work_permit: false,
    birth_date: true,
    profile_picture_url: false,
    avatar_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: true,
    order: true,
  },
  extracurricular_activities: [{
    name: true,
    description: true,
    startDate: true,
    endDate: true,
    location: true,
    institution: true,
    role: true,
  }],
  certificates: [{
    name: true,
    date: true,
    issuer: false,
    url: false,
  }],
};

export const MODERN_SIMPLE_FIELDS: DefaultWithoutExtracurricularActivities = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: true,
    label: false,
    location: false,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: true,
    work_permit: true,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const MODERN_MINIMALIST_FIELDS: DefaultWithoutExtracurricularActivities = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: true,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const RICE_FIELDS: RiceFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
};

export const GEORGIA_FIELDS: DefaultWithoutExtracurricularActivities = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    summaryTitle: true,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const OXFORD_FIELDS: DefaultWithoutExtracurricularActivities = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: true,
    url: true,
    github_url: false,
    nationality: true,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: true,
    subheadings: false,
    order: false,
  },
};

export const ESMT_ATS_FIELDS: DefaultWithoutExtracurricularActivities = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    url: true,
    github_url: false,
    nationality: true,
    work_permit: false,
    birth_date: true,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const ASB_FIELDS: DefaultWithoutExtracurricularActivities = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: false,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const AMS_FIELDS: DefaultWithoutExtracurricularActivities = {
  work_section: {
    order: true,
    subheadings: true,
    entries: true,
  },
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    url: true,
    github_url: false,
    nationality: false,
    work_permit: true,
    birth_date: false,
    profile_picture_url: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    score: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
    gmatscore: false,
    subheadings: false,
    order: false,
  },
};

export const MBS_MBA_FIELDS: MBSMBAFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summaryTitle: false,
    summary: true,
    url: true,
    profile_picture_url: true,
    avatar_url: true,
    city: true,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: false,
    score: false,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
};

export const MBS_BACHELORS_FIELDS: MBSBachelorsFields = {
  basics: {
    emails: true,
    image: true,
    label: true,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: true,
    url: true,
    profile_picture_url: true,
    avatar_url: true,
    city: true,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: false,
    score: false,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  extracurricular_activities: [{
    name: true,
    description: false,
    startDate: true,
    endDate: true,
    location: false,
    institution: true,
    role: true,
  }],
};

export const EBS_FIELDS: EBSFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    summaryTitle: false,
    url: true,
    github_url: false,
    profile_picture_url: true,
    avatar_url: true,
    city: true,
    birth_date: true,
    nationality: true,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
    score: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  extracurricular_activities: [{
    name: true,
    description: true,
    startDate: true,
    endDate: true,
    location: true,
    institution: true,
    role: true,
  }],
  certificates: [{
    name: true,
    date: false,
    issuer: true,
    url: false,
  }],
};

export const EBS_2_PAGE_FIELDS: EBS2PageFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    url: true,
    github_url: false,
    profile_picture_url: true,
    avatar_url: true,
    city: true,
    birth_date: true,
    nationality: false,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
    score: true,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  extracurricular_activities: [{
    name: true,
    description: true,
    startDate: true,
    endDate: true,
    location: true,
    institution: true,
    role: true,
  }],
  certificates: [{
    name: true,
    date: false,
    issuer: true,
    url: false,
  }],
  additional_information: {
    bullets: true,
  },
};

export const BOCCONI_STANDARD_FIELDS: BocconiStandardFields = {
  basics: {
    emails: true,
    image: true,
    label: false,
    location: true,
    name: true,
    phone_numbers: true,
    profiles: true,
    summary: false,
    url: true,
    github_url: false,
    profile_picture_url: true,
    avatar_url: true,
    city: true,
    birth_date: true,
    nationality: true,
  },
  education: [{
    area: true,
    courses: true,
    endDate: true,
    institution: true,
    startDate: true,
    studyType: true,
    url: true,
    id: true,
    location: true,
    description: true,
    score: false,
  }],
  skills: [{
    keywords: true,
    level: true,
    name: true,
  }],
  languages: [{
    fluency: true,
    language: true,
  }],
  work: [{
    description: true,
    endDate: true,
    highlights: true,
    location: true,
    name: true,
    position: true,
    startDate: true,
    summary: true,
    url: true,
    id: true,
    ai_highlights: true,
  }],
  additional_information: {
    bullets: true,
  },
};
