import { Button } from '@careeros/coco';
import './challenge-modal.scss';
import { CSSProperties, useEffect, useMemo } from 'react';
import { hexToRgba } from '@/services/helpers/colors';
import { useReadChallengeMutation } from '@/services/queries/gamification';
import { triggerConfetti } from './confeti';
import challengeAudio from '@/assets/audio/challenge.mp3';
import challengeUnreadAudio from '@/assets/audio/challenge_unread.mp3';

type Props = {
  badgeImage: string;
  description: string
  color: string
  id: string
  isRead: boolean
  handleViewAllBadges: () => void
  handlePrimaryClick: () => void
};

export const ChallengeModal = ({
  badgeImage,
  description,
  color,
  id,
  isRead,
  handleViewAllBadges,
  handlePrimaryClick,
}: Props) => {
  const shadowColor = useMemo(() => hexToRgba(color, 0.1), [color]);
  const { mutate: markChallengeAsRead } = useReadChallengeMutation();
  // Calculate a darker version of the badge color for the coin edge
  const sideColor = useMemo(() => {
    // Convert hex to RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Darken by 20%
    const darkenFactor = 0.8;
    const darkR = Math.floor(r * darkenFactor);
    const darkG = Math.floor(g * darkenFactor);
    const darkB = Math.floor(b * darkenFactor);

    // Convert back to hex
    return `#${darkR.toString(16).padStart(2, '0')}${darkG.toString(16).padStart(2, '0')}${darkB.toString(16).padStart(2, '0')}`;
  }, [color]);

  // Calculate an even darker version for shadows
  const sideDarkColor = useMemo(() => {
    // Convert hex to RGB
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);

    // Darken by 50%
    const darkenFactor = 0.5;
    const darkR = Math.floor(r * darkenFactor);
    const darkG = Math.floor(g * darkenFactor);
    const darkB = Math.floor(b * darkenFactor);

    // Convert back to hex
    return `#${darkR.toString(16).padStart(2, '0')}${darkG.toString(16).padStart(2, '0')}${darkB.toString(16).padStart(2, '0')}`;
  }, [color]);

  // Effect for playing sound on mount
  useEffect(() => {
    const audioPath = isRead ? challengeAudio : challengeUnreadAudio;
    const audio = new Audio(audioPath);
    audio.play().catch(error => {
      // Autoplay prevention is common, log the error
      console.error('Audio playback failed:', error);
    });

    return () => {
      audio.pause();
      audio.currentTime = 0; // Reset playback position
    };
  }, [id, isRead]);

  useEffect(() => {
    if (!isRead) {
      markChallengeAsRead(id);
      triggerConfetti();
    }
  }, [id]);

  return (
    <div
      className="challenge-modal"
      style={{
        '--badge-color': color,
        '--badge-shadow-color': shadowColor,
        '--badge-image': `url(${badgeImage})`,
        '--side': sideColor,
        '--side-dark': sideDarkColor,
        '--animation-delay': isRead ? '0s' : '0.3s',
      } as CSSProperties}
    >
      <h2 className="challenge-modal__title">🎉 Challenge Complete! 🎉</h2>
      <div className="challenge-modal__image">
        <div className="coin" />
        <div className="clip">
          <div className="shine" />
        </div>
      </div>
      <div className="challenge-modal__description" dangerouslySetInnerHTML={{ __html: description }} />
      <div className="challenge-modal__buttons">
        <Button onClick={handleViewAllBadges} isFullWidth mode="primary" size="medium" label="View All Badges" outlined icon="bi bi-box-arrow-up-right" iconPosition="right" />
        <Button onClick={handlePrimaryClick} isFullWidth mode="primary" size="medium" label="Let's Go! 🔥" icon="bi bi-arrow-right" iconPosition="right" />
      </div>
    </div>
  );
};
