import { useContext } from 'react';
import './tile-actions.scss';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ContactsSidebar } from '../../sidebars/contacts-sidebar/contacts-sidebar';
import { TileCta } from '../tile-cta/tile-cta';
import { CommentsHistorySidebar } from '../../sidebars';
import { CommentFromCA } from '@/domains/core/advisor/types';
import { useTabs } from '@/components/tabs/TabsContext';

type Props = {
  tileID: string;
  companyName: string;
  companyID: string;
  studentID: string;
  comments: CommentFromCA[];
  countOfContacts: number;
  handleAddCommentClick?: () => void;
};

export const ReadonlyTileActions = ({
  companyName,
  companyID,
  studentID,
  comments,
  tileID,
  countOfContacts,
  handleAddCommentClick,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { openSidebar, closeSidebar } = useContext(SidebarContext) as any;
  const { setCurrentTab } = useTabs();

  const handleSeeAllContacts = () => {
    closeSidebar();
    setCurrentTab('Contacts');
  };

  const handleContactsIconClicked = () => {
    trackEvent('Overview Board: Contact Icon clicked', {});
    openSidebar(
      <ContactsSidebar
        companyName={companyName}
        companyID={companyID}
        closeSidebar={closeSidebar}
        userID={studentID}
        handleSeeAllContacts={handleSeeAllContacts}
      />,
    );
  };

  const handleCommentsIconClick = () => {
    trackEvent('Overview Board: Comments Icon clicked', {});

    openSidebar(
      <CommentsHistorySidebar
        applicationID={tileID}
        title="Company Comments"
      />,
    );
  };

  return (
    <div className="tile-actions">
      <TileCta
        label="View Contacts"
        icon="bi bi-people"
        handleClick={handleContactsIconClicked}
        counter={countOfContacts}
      />
      <TileCta
        label="View Comments"
        icon="bi bi-chat-square-text"
        handleClick={handleCommentsIconClick}
        counter={comments?.length || 0}
      />

      {handleAddCommentClick && (
        <TileCta
          label="Write a comment"
          icon="bi bi-plus-lg"
          handleClick={handleAddCommentClick}
        />
      )}
    </div>
  );
};
