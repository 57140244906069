import {
  Button, DefaultLogo,
} from '@careeros/coco';

import {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import './topbar.scss';

import { AlgoliaSearchBar } from '@/domains/generic';
import { TopbarMenu } from './components/topbar-menu';

type Props = {
  title: string | ReactElement;
  withSearch?: boolean;
  withProfile?: boolean;
  profileImage?: string;
  userName?: string;
  email?: string;
  isLoggedIn?: boolean;
  handleLoginClick?: () => void;
  handleLogoutClick?: () => void;
  isTransparent?: boolean;
  handleHelpButtonClick?: () => void;
  isCareerAdvisorPage?: boolean;
  children?: React.ReactNode;
};

export const TopbarDumb = ({
  title,
  withSearch = false,
  withProfile = false,
  profileImage,
  userName,
  email,
  isLoggedIn = true,
  handleLoginClick,
  handleLogoutClick,
  isTransparent,
  handleHelpButtonClick,
  isCareerAdvisorPage,
  children,
}: Props) => {
  const navigate = useNavigate();
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const profileRef = useRef<HTMLDivElement>(null);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className={`topbar ${isTransparent ? 'topbar--transparent' : ''}`}>
      <div className="topbar__title">
        {title}
      </div>
      <div className="topbar__search">
        {withSearch && <AlgoliaSearchBar />}
      </div>
      <div className="topbar__menu">
        {children}
        {withProfile && (
        <div className="topbar__profile" ref={profileRef}>
          <div
            className={`topbar__profile-wrapper ${isProfileMenuOpen ? 'is-active' : ''}`}
            onClick={toggleProfileMenu}
          >
            <DefaultLogo
              size={32}
              type="contact"
              source={profileImage || ''}
              className=""
              name={userName || 'U'}
              icon="bi bi-gear"
            />
            <i className="topbar__profile-settings bi bi-gear-fill" />
            <TopbarMenu
              isCareerAdvisorPage={isCareerAdvisorPage || false}
              isProfileMenuOpen={isProfileMenuOpen}
              email={email || ''}
              handleNavigate={handleNavigate}
              handleHelpButtonClick={handleHelpButtonClick || (() => {})}
              handleLogoutClick={handleLogoutClick || (() => {})}
            />
          </div>
        </div>
        )}
        {!isLoggedIn && (
        <Button
          onClick={handleLoginClick}
          mode="primary"
          iconPosition="left"
          icon="bi bi-box-arrow-in-right"
          size="small"
          label="Login"
        />
        )}
      </div>
    </div>
  );
};
