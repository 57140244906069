import { ApiStudentStatus } from '@/domains/core/advisor/types/api/student';
import { StudentStatus } from '@/domains/core/advisor/types';

const statusMap: { [key in ApiStudentStatus]: StudentStatus } = {
  inactive: 'Inactive',
  companies_saved: 'Companies Saved',
  networking: 'Networking',
  applying: 'Applying',
  applied: 'Applied',
  interviewing: 'Interviewing',
  offer: 'Offer',
  rejected: 'Rejected',
};

const statusMapForCompany: { [key in ApiStudentStatus]: StudentStatus } = {
  inactive: 'Inactive',
  companies_saved: 'Company Saved',
  networking: 'Networking',
  applied: 'Applied',
  applying: 'Applying',
  interviewing: 'Interviewing',
  offer: 'Offer',
  rejected: 'Rejected',
};

export const convertFromApiStatus = (status: ApiStudentStatus): StudentStatus => {
  const mappedStatus = statusMap[status];
  if (!mappedStatus) {
    return 'Inactive';
  }

  return mappedStatus;
};

export const convertFromApiStatusForCompany = (status: ApiStudentStatus): StudentStatus => {
  const mappedStatus = statusMapForCompany[status];
  if (!mappedStatus) {
    return 'Inactive';
  }

  return mappedStatus;
};
