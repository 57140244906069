import PersonImage1 from '@/assets/images/fake-people/maria.jpg';
import PersonImage2 from '@/assets/images/fake-people/bessie.jpg';
import PersonImage3 from '@/assets/images/fake-people/cody.jpg';
import PersonImage4 from '@/assets/images/fake-people/wade.jpg';
import PersonImage5 from '@/assets/images/fake-people/darlene.jpg';
import FakeCompany1 from '@/assets/images/vmock-logo.png';
import FakeCompany2 from '@/assets/images/linkedin.svg';
import FakeCompany3 from '@/assets/images/mba-exchange-logo.jpg';
import FakeCompany4 from '@/assets/images/hs-school-logo-data.png';
import FakeCompany5 from '@/assets/images/vault-logo.webp';

export const mockedSuggestedContacts = [
  {
    name: 'Maria Beatriz Fernandes Frias e Carmo Ferreira',
    position: 'Software Engineer',
    image: PersonImage1,
    languages: ['English', 'Spanish'],
    isAlumni: true,
    companyLogo: FakeCompany1,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 3,
    isCampusChampion: false,
  },
  {
    name: 'Bessie Cooper Bessie Cooper',
    position: 'Software Engineer',
    image: PersonImage2,
    languages: [],
    isAlumni: true,
    companyLogo: FakeCompany2,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 0,
    isCampusChampion: false,
  },
  {
    name: 'Cody Fisher',
    position: 'Software Engineer',
    image: PersonImage3,
    languages: ['English', 'Spanish'],
    isAlumni: true,
    companyLogo: FakeCompany3,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 1,
    isCampusChampion: false,
  },
  {
    name: 'Wade Warren',
    position: 'Software Engineer',
    image: PersonImage4,
    languages: ['English', 'Spanish'],
    isAlumni: true,
    companyLogo: FakeCompany4,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 2,
    isCampusChampion: true,
  },
  {
    name: 'Darlene Robertson',
    position: 'Software Engineer',
    image: PersonImage5,
    languages: ['English'],
    isAlumni: false,
    companyLogo: FakeCompany5,
    companyName: 'Google',
    schoolName: 'Harvard University',
    networkingStatusRate: 0,
    isCampusChampion: false,
  },
];
