import { Tooltip, Badge, BadgeColor } from '@careeros/coco';
import { useMemo } from 'react';

type Props = {
  demand: 'low' | 'medium' | 'high';
  university: string;
};

const calculateDemandValues = (university: string, demand: 'low' | 'medium' | 'high') => {
  if (demand === 'low') {
    return {
      color: 'light-green',
      text: '🟢 low',
      tooltip: `Low demand - Less than 5 fellow students from ${university} have reached out to this person in in the last week`,
    };
  }
  if (demand === 'medium') {
    return {
      color: 'yellow',
      text: '🟡 medium',
      tooltip: `Medium demand - 5 to 10 fellow students from ${university} have reached out to this person in in the last week`,
    };
  }
  if (demand === 'high') {
    return {
      color: 'light-red',
      text: '🔴 high',
      tooltip: `High demand - More than 10 fellow students from ${university} have reached out to this person in in the last week`,
    };
  }
  return null;
};

export const DemandTag = ({
  demand,
  university,
}: Props) => {
  const values = useMemo(() => calculateDemandValues(university, demand), [university, demand]);
  return (
    <div className="contact-status-tag">
      <Tooltip position="top" withArrow label={values?.tooltip || ''}>
        <Badge color={values?.color as BadgeColor} label={values?.text || ''} />
      </Tooltip>
    </div>
  );
};
