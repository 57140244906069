import { useContext, useEffect, useState } from 'react';
import { FilterNameType, FilterOption } from '@/domains/core/company/types';
import { OverviewHeader } from '../overview-header/overview-header';
import './overview-content.scss';
import { useScroll } from '@/services/hooks/use-scroll';
import { KanbanBoardV2 } from '../../kanban/board/student-kanban-board-v2';
import { TileV2 } from '@/domains/core/company/types/tile-v2';
import { ApplicationStatus } from '@/domains/core/tracker-data';
import { ApplicationsTableV2 } from '../applications-table/applications-table-v2';
import { DragAndDropPayload } from '../../kanban/types';
import { PostingScoreExplainerModal } from '@/domains/core/company/components/posting-score-modal/posting-score-modal';
import { ModalContext } from '@/components/modal/modal-provider';

type Props = {
  tiles: TileV2[];
  columns: ApplicationStatus[];
  layout: any;
  cardChangeHandler?: (cardInfo: DragAndDropPayload, newStatus: ApplicationStatus) => Promise<void>;
  handleFiltersChange: (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => void;
  savedIndustries: string[];
  openFullCommentHistory?: () => void;
  hasUnresolvedComments?: boolean;
  hasPostingScore?: boolean;
};

export const OverviewContentV2 = ({
  tiles,
  columns,
  layout,
  cardChangeHandler,
  handleFiltersChange,
  savedIndustries,
  openFullCommentHistory,
  hasUnresolvedComments = false,
  hasPostingScore,
}: Props) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(false);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const [selectedView, setSelectedView] = useState(0);

  const updateScrollState = () => {
    if (!kanbanContainer.current) {
      return;
    }

    setShowLeftScrollArrow(kanbanContainer.current.scrollLeft > 0);
    setShowRightScrollArrow(kanbanContainer.current.scrollLeft < (kanbanContainer.current.scrollWidth - kanbanContainer.current.clientWidth));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    if (!kanbanContainer.current) {
      return;
    }

    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }

    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const handleViewChange = (value: number) => {
    setSelectedView(value);

    const currentSearch = new URLSearchParams(window.location.search);
    currentSearch.delete('view');
    currentSearch.append('view', value.toString());

    window.history.replaceState(null, '', `?${currentSearch.toString()}`);
  };

  const openPostingScoreModal = () => {
    openModal(
      <PostingScoreExplainerModal closeModal={closeModal} />,
    );
  };

  useEffect(() => {
    updateScrollState();
  }, [kanbanContainer.current]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const view = searchParams.get('view');

    if (view) {
      setSelectedView(Number(view));
    }
  }, []);

  return (
    <div className={`overview-content ${selectedView === 1 ? 'overview-content--fit-screen-height' : ''}`}>
      <div className="overview-content__header">
        <OverviewHeader
          handleFiltersChange={handleFiltersChange}
          savedIndustries={savedIndustries}
          openFullCommentHistory={openFullCommentHistory}
          showRightScrollArrow={showRightScrollArrow}
          showLeftScrollArrow={showLeftScrollArrow}
          handleContentScroll={handleVerticalScroll}
          selectedView={selectedView}
          changeSelectedView={handleViewChange}
          hasUnresolvedComments={hasUnresolvedComments}
          openPostingScoreModal={openPostingScoreModal}
          withPostingScoreFilter={hasPostingScore}
          hideJobsFilter
        />
      </div>

      <div
        className={`overview-content__kanban ${selectedView === 0 ? 'overview-content__kanban--active' : ''}`}
      >
        <KanbanBoardV2
          onChange={cardChangeHandler}
          columns={columns}
          layout={layout}
          scrollContainerRef={kanbanContainer}
          onScroll={handleScroll}
          withPostingScore={hasPostingScore}
        />
      </div>
      <div className={`overview-content__table ${selectedView === 1 ? 'overview-content__table--active' : ''}`}>
        <ApplicationsTableV2 tiles={tiles} />
      </div>
    </div>
  );
};
