import { CSSProperties, useMemo } from 'react';
import { hexToRgba } from '@/services/helpers/colors';
import { Challenge } from '@/types/gamification';
import { formatDate } from '@/services/strapi/api-helpers';

export const ChallengeBadge = ({
  challenge, isLocked, isOngoing, handleBadgeClick,
}: { challenge: Challenge, isLocked?: boolean, isOngoing?: boolean, handleBadgeClick?: (badgeId: string) => void }) => {
  const shadowColor = useMemo(() => hexToRgba(challenge.color, 0.1), [challenge.color]);

  const badgeClassName = useMemo(() => {
    const baseClass = 'challenge-badge';
    if (isLocked) return `${baseClass} ${baseClass}--locked`;
    if (isOngoing) return `${baseClass} ${baseClass}--ongoing`;
    return baseClass;
  }, [isLocked, isOngoing]);

  const badgeTitle = useMemo(() => {
    if (isLocked) return '🔒 Locked Badge';
    return challenge.name;
  }, [isLocked, challenge.name]);

  const badgeStatus = useMemo(() => {
    if (isLocked) return '?/?';
    if (isOngoing) return `${challenge.user_progress}/${challenge.completed_at_value}`;
    return `Earned on ${formatDate(challenge.completed_at)}`;
  }, [isLocked, isOngoing, challenge]);

  const handleClick = () => {
    if (isLocked) return;
    if (isOngoing) return;
    if (handleBadgeClick) handleBadgeClick(challenge.id);
  };

  return (
    <div
      className={badgeClassName}
      style={{ '--badge-shadow-color': shadowColor, '--badge-color': challenge.color } as CSSProperties}
    >
      <div className="challenge-badge__image" onClick={handleClick}>
        <img src={challenge.image} alt={badgeTitle} />
        {!isLocked && (
          <span className="challenge-badge__xp">
            +
            {challenge.xp}
            {' '}
            XP
          </span>
        )}
      </div>
      <div className="challenge-badge__content">
        <p className="challenge-badge__title">{badgeTitle}</p>
        {!isLocked && <p className="challenge-badge__description">{challenge.description}</p>}
        <p className="challenge-badge__earned">{badgeStatus}</p>
      </div>
    </div>
  );
};
