import {
  ReactNode, useEffect, useRef,
} from 'react';
import ReactDOM from 'react-dom';
import './popup-box.scss';

type Props = {
  children: ReactNode;
  parentRef?: React.RefObject<HTMLDivElement>;
  handleBlur?: () => void;
  scrollContainerQuery?: string;
  fitToScreenHeight?: boolean;
};

export const PopupBoxWrapper = ({
  children,
  parentRef,
  handleBlur,
  scrollContainerQuery,
  fitToScreenHeight = false,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: any) => {
    if (popupRef.current && !popupRef.current.contains(event.target) && !parentRef?.current?.contains(event.target)) {
      handleBlur?.();
    }
  };

  const updatePosition = () => {
    if (popupRef.current && wrapperRef.current) {
      const rect = wrapperRef.current.getBoundingClientRect();
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft;

      popupRef.current.style.top = `${rect.bottom + scrollTop}px`;
      popupRef.current.style.left = `${rect.left + scrollLeft}px`;

      const boxWidth = popupRef.current.clientWidth;
      const boxVisibleWidth = window.innerWidth - (rect.left + scrollLeft);
      const boxOverflowRight = boxWidth - (boxVisibleWidth - 10);
      const boxOverflowBottom = (rect.bottom + popupRef.current.clientHeight) - window.innerHeight;

      if (boxOverflowBottom > 0 && fitToScreenHeight) {
        const wrapperTop = rect.bottom - rect.height + window.scrollY;
        // commented out as a backup
        // popupRef.current.style.top = `${window.innerHeight - popupRef.current.clientHeight - 10 + window.scrollY}px`;
        // popupRef.current.style.left = `${popupRect.left + rect.width}px`;
        popupRef.current.style.top = `${wrapperTop - popupRef.current.clientHeight}px`;
      }

      if (boxOverflowRight > 0) {
        popupRef.current.style.left = `${window.innerWidth - boxWidth - 20}px`;
        popupRef.current.style.right = 'auto';
      }
    }
  };

  const createComponent = (): ReactNode => (
    <div ref={popupRef} className="popup-box">
      {children}
    </div>
  );

  useEffect(() => {
    updatePosition();
    document.addEventListener('mousedown', handleClickOutside);
  }, [popupRef.current, wrapperRef.current]);

  useEffect(() => {
    if (scrollContainerQuery) {
      document.querySelector(scrollContainerQuery)?.addEventListener('scrollend', updatePosition);

      return () => {
        document.querySelector(scrollContainerQuery)?.removeEventListener('scrollend', updatePosition);
      };
    }

    return undefined;
  }, []);

  useEffect(() => () => {
    document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div
      ref={wrapperRef}
      style={{
        height: '100%',
      }}
    >
      {ReactDOM.createPortal(createComponent(), document.querySelector('#root') as HTMLElement)}
    </div>
  );
};
