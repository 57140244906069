import { useEffect } from 'react';
import { Challenge } from '@/types/gamification';

export interface AnimatedChallengeProps {
  challenge: Challenge;
  onAnimationEnd: (challengeId: string) => void;
}

export const AnimatedChallenge = ({ challenge, onAnimationEnd }: AnimatedChallengeProps) => {
  useEffect(() => {
    // Set a timer to end the animation after 2 seconds (matches the CSS animation duration)
    const timer = setTimeout(() => {
      // Notify parent that animation is done
      onAnimationEnd(challenge.id);
    }, 3000);
    return () => clearTimeout(timer);
  }, [challenge.id, onAnimationEnd]);

  return (
    <div
      style={{
        width: '26px',
        height: '26px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        border: '1px solid white',
        boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1)',
      }}
      className="bounce-out"
    >
      <img
        src={challenge.image}
        alt={`Challenge: ${challenge.name}`}
        style={{
          width: '24px',
          height: '24px',
          borderRadius: '50%',
          boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.1), 0px 1px 2px -1px rgba(0, 0, 0, 0.1))',
        }}
      />
    </div>
  );
};
