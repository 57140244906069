import { AvatarStack } from '@careeros/coco';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatedChallenge, ChallengeModal } from '@/domains/core/student';
import { useGamification } from '@/services/hooks/use-gamification';
import { ModalContext } from '@/components/modal/modal-provider';

export const TopbarChallenges = () => {
  const navigate = useNavigate();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const {
    unreadChallenges,
    animatingChallenges,
    handleAnimationEnd,
    setAnimatingChallenges,
    setUnreadChallenges,

  } = useGamification();

  const navigateToChallenges = () => {
    closeModal();
    navigate('/app/challenges');
  };

  const handleChallengeAnimationEnd = (challengeId: string) => {
    const challenge = animatingChallenges.find((c) => c.id === challengeId);
    if (challenge) {
      setUnreadChallenges((prev) => [challenge, ...prev]);
      setAnimatingChallenges((prev) => prev.filter((c) => c.id !== challengeId));
    }
    handleAnimationEnd(challengeId);
  };

  const handleBadgeClick = (badgeId: string) => {
    const badge = unreadChallenges.find((bdg) => bdg.id === badgeId);
    if (badge) {
      openModal(
        <ChallengeModal
          badgeImage={badge.image}
          description={`You earned the <strong>${badge.name}</strong> badge by ${badge.completed_description}`}
          color={badge.color}
          id={badge.id}
          isRead={badge.is_read}
          handleViewAllBadges={navigateToChallenges}
          handlePrimaryClick={closeModal}
        />,
      );
    }
  };

  return (

    <div className="topbar__challenges">
      {animatingChallenges.length > 0 && (
      <div className="topbar__animating-challenges">
        {animatingChallenges.map((challenge) => (
          <AnimatedChallenge
            key={challenge.id}
            challenge={challenge}
            onAnimationEnd={handleChallengeAnimationEnd}
          />
        ))}
      </div>
      )}

      {Array.isArray(unreadChallenges) && unreadChallenges.length > 0 && (
      <AvatarStack
        personList={unreadChallenges.map((badge) => ({
          id: badge.id,
          name: badge.name,
          avatar: badge.image,
          handleClick: handleBadgeClick,
        }))}
        maxAvatars={4}
        handleClick={navigateToChallenges}
        withPerItemHover
        withPerItemTooltip
        tooltipPosition="bottom"
        size={24}
      />
      )}
    </div>
  );
};
