import { useRef, useMemo } from 'react';
import './alum-description.scss';
import { ContactSummary } from '@/domains/core/contact';
import { AlumHeader } from '../alum-header/alum-header';
import { Alum } from '@/types/alum';
import {
  determineIfGraduated, getCurrentCompany, getCurrentPosition, getDegreeTag, getGraduationTag, parseCareerSummary,
} from '../../helpers/helpers';
import { CareerType } from '@/domains/core/contact/types';

type Props = {
  selectedAlum: Alum;
};

export const AlumDescription = ({
  selectedAlum,
}: Props) => {
  const descriptionScrollRef = useRef<HTMLDivElement>(null);

  const degreeTag = useMemo(() => getDegreeTag(selectedAlum), [selectedAlum]);
  const graduationTag = useMemo(() => getGraduationTag(selectedAlum), [selectedAlum]);

  const parsedCareerSummary = useMemo(
    () => (selectedAlum ? parseCareerSummary(selectedAlum.career_summary.profile) as CareerType : null),
    [selectedAlum],
  );

  if (!selectedAlum) {
    return null;
  }

  return (
    <div className="contact-description">
      <AlumHeader
        badges={{
          graduated: determineIfGraduated(selectedAlum),
          hiring_success: selectedAlum.hiring_success,
          isCareerosUser: selectedAlum.has_given_permission,
          location: `${parsedCareerSummary?.basics?.location?.address}`,
          degree: degreeTag?.tag_content || '',
          graduation: graduationTag?.tag_content || '',
        }}
        location={`${parsedCareerSummary?.basics?.location?.address}`}
        image={parsedCareerSummary?.basics?.image || ''}
        companyName={getCurrentCompany(selectedAlum)}
        position={getCurrentPosition(selectedAlum)}
        name={`${selectedAlum?.first_name} ${selectedAlum?.last_name}`}
        email={selectedAlum?.email}
        linkedInUrl={`https://www.linkedin.com/in/${selectedAlum?.linkedin_slug}`}
      />
      <div className="contact-description__bottom">
        <div
          className="contact-description__tabs-content"
          ref={descriptionScrollRef}
        >
          <div
            className="contact-description__description"
            data-tab="Contact Profile"
          >
            {parsedCareerSummary && <ContactSummary careerSummary={parsedCareerSummary} />}
          </div>
        </div>
      </div>
    </div>
  );
};
