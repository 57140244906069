export const universityWhoHasResumeApprovingLogic = [
  'University of Maryland',
  'Hult International Business School',
  'ESMT',
  'University of Oxford',
  'University of Georgia',
  'Rice University',
  'The University of Leeds',
  'Asia School of Business',
  'Trial',
  'Project Leonardo Da Vinci',
];

export const MINIMUM_APPROVAL_SCORES: { [key: string]: number } = {
  default: 80,
  'University of Maryland': 80,
  'Hult International Business School': 80,
  ESMT: 80,
  'University of Oxford': 80,
  'University of Georgia': 80,
  'Rice University': 80,
  'Munich Business School': 80,
  'The University of Leeds': 80,
  'Asia School of Business': 80,
  Trial: 80,
  'Project Leonardo Da Vinci': 80,
};
