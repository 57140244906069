/* eslint-disable no-nested-ternary */

// Helper function to get months array
function getMonthsArray(): string[] {
  return [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
  ];
}

export function formatDate(dateString: string | undefined | null): string {
  if (!dateString) {
    return 'N/A';
  }

  // Handle ISO date format (YYYY-MM-DD)
  if (dateString.includes('-')) {
    const [year, month] = dateString.split('-');
    const monthIndex = parseInt(month, 10) - 1;
    const months = getMonthsArray();
    // Get last 2 digits of the year
    const shortYear = year.length === 4 ? year.slice(-2) : year;
    return `${months[monthIndex]} ${shortYear}`;
  }

  // If dateString is just a year (doesn't contain a slash)
  if (!dateString.includes('/')) {
    // Check if it's a valid year
    const yearNum = parseInt(dateString, 10);
    if (Number.isNaN(yearNum) || yearNum < 1900 || yearNum > 2100) {
      return 'N/A';
    }
    // Get last 2 digits of the year
    const shortYear = dateString.length === 4 ? dateString.slice(-2) : dateString;
    return `Jan ${shortYear}`;
  }

  const months = getMonthsArray();
  const parts = dateString.split('/');

  // Handle MM/YYYY format
  if (parts.length === 2) {
    const [month, year] = parts;
    const monthIndex = parseInt(month, 10) - 1;

    // Validate month
    if (Number.isNaN(monthIndex) || monthIndex < 0 || monthIndex >= 12) {
      return 'N/A';
    }

    // Get last 2 digits of the year
    const shortYear = year.length === 4 ? year.slice(-2) : year;
    return `${months[monthIndex]} ${shortYear}`;
  }

  // Handle MM/DD/YYYY format
  if (parts.length === 3) {
    const [month, , year] = parts;
    const monthIndex = parseInt(month, 10) - 1;

    // Validate month
    if (Number.isNaN(monthIndex) || monthIndex < 0 || monthIndex >= 12) {
      return 'N/A';
    }

    // Get last 2 digits of the year
    const shortYear = year.length === 4 ? year.slice(-2) : year;
    return `${months[monthIndex]} ${shortYear}`;
  }

  return 'N/A';
}

export function formatDateYearOnly(dateString: string | undefined | null): string {
  if (!dateString) {
    return 'N/A';
  }

  // If dateString is just a year (doesn't contain a slash or dash)
  if (!dateString.includes('/') && !dateString.includes('-')) {
    const yearNum = parseInt(dateString, 10);
    return Number.isNaN(yearNum) ? 'N/A' : dateString;
  }

  // Handle ISO date format (YYYY-MM-DD)
  if (dateString.includes('-')) {
    const [year] = dateString.split('-');
    return year;
  }

  const parts = dateString.split('/');

  // Handle MM/YYYY format
  if (parts.length === 2) {
    const [, yearStr] = parts;
    const year = parseInt(yearStr, 10);

    if (Number.isNaN(year)) {
      return 'N/A';
    }

    const fullYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
    return fullYear.toString();
  }

  // Handle MM/DD/YYYY format
  if (parts.length === 3) {
    const [, , yearStr] = parts;
    const year = parseInt(yearStr, 10);

    if (Number.isNaN(year)) {
      return 'N/A';
    }

    const fullYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
    return fullYear.toString();
  }

  return 'N/A';
}

export function formatDateWithFullYear(dateString: string | undefined | null): string {
  if (!dateString) {
    return 'N/A';
  }

  // Handle ISO date format (YYYY-MM-DD)
  if (dateString.includes('-')) {
    const [year, month] = dateString.split('-');
    const monthIndex = parseInt(month, 10) - 1;
    const months = getMonthsArray();

    if (Number.isNaN(monthIndex) || monthIndex < 0 || monthIndex >= 12) {
      return 'N/A';
    }

    return `${months[monthIndex]} ${year}`;
  }

  // If dateString is just a year (doesn't contain a slash)
  if (!dateString.includes('/')) {
    const yearNum = parseInt(dateString, 10);
    if (Number.isNaN(yearNum) || yearNum < 1900 || yearNum > 2100) {
      return 'N/A';
    }
    return `Jan ${dateString}`;
  }

  const months = getMonthsArray();
  const parts = dateString.split('/');

  // Handle MM/YYYY format
  if (parts.length === 2) {
    const [month, yearStr] = parts;
    const monthIndex = parseInt(month, 10) - 1;
    const year = parseInt(yearStr, 10);

    if (Number.isNaN(monthIndex) || monthIndex < 0 || monthIndex >= 12 || Number.isNaN(year)) {
      return 'N/A';
    }

    const fullYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
    return `${months[monthIndex]} ${fullYear}`;
  }

  // Handle MM/DD/YYYY format
  if (parts.length === 3) {
    const [month, , yearStr] = parts;
    const monthIndex = parseInt(month, 10) - 1;
    const year = parseInt(yearStr, 10);

    if (Number.isNaN(monthIndex) || monthIndex < 0 || monthIndex >= 12 || Number.isNaN(year)) {
      return 'N/A';
    }

    const fullYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
    return `${months[monthIndex]} ${fullYear}`;
  }

  return 'N/A';
}

export function formatDateWithFullYearOptionalField(dateString: string | undefined | null): string | null {
  if (!dateString || dateString === '') {
    return null;
  }

  // Handle ISO date format (YYYY-MM-DD)
  if (dateString.includes('-')) {
    const [year, month] = dateString.split('-');
    const monthIndex = parseInt(month, 10) - 1;
    const months = getMonthsArray();

    if (Number.isNaN(monthIndex) || monthIndex < 0 || monthIndex >= 12) {
      return null;
    }

    return `${months[monthIndex]} ${year}`;
  }

  // If dateString is just a year (doesn't contain a slash)
  if (!dateString.includes('/')) {
    const yearNum = parseInt(dateString, 10);
    if (Number.isNaN(yearNum) || yearNum < 1900 || yearNum > 2100) {
      return null;
    }
    return `Jan ${dateString}`;
  }

  const months = getMonthsArray();
  const parts = dateString.split('/');

  // Handle MM/YYYY format
  if (parts.length === 2) {
    const [month, yearStr] = parts;
    const monthIndex = parseInt(month, 10) - 1;
    const year = parseInt(yearStr, 10);

    if (Number.isNaN(monthIndex) || monthIndex < 0 || monthIndex >= 12 || Number.isNaN(year)) {
      return null;
    }

    const fullYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
    return `${months[monthIndex]} ${fullYear}`;
  }

  return null;
}

export function formatDateToYear(dateString: string | undefined | null): string {
  if (!dateString) {
    return 'N/A';
  }

  // Handle ISO date format (YYYY-MM-DD)
  if (dateString.includes('-')) {
    const [year] = dateString.split('-');
    return year;
  }

  // If dateString is just a year (doesn't contain a slash)
  if (!dateString.includes('/')) {
    const yearNum = parseInt(dateString, 10);
    return Number.isNaN(yearNum) ? 'N/A' : dateString;
  }

  const parts = dateString.split('/');

  // Handle MM/YYYY format
  if (parts.length === 2) {
    const [, yearStr] = parts;
    const year = parseInt(yearStr, 10);

    if (Number.isNaN(year)) {
      return 'N/A';
    }

    const fullYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
    return fullYear.toString();
  }

  // Handle MM/DD/YYYY format
  if (parts.length === 3) {
    const [, , yearStr] = parts;
    const year = parseInt(yearStr, 10);

    if (Number.isNaN(year)) {
      return 'N/A';
    }

    const fullYear = year < 100 ? (year < 50 ? 2000 + year : 1900 + year) : year;
    return fullYear.toString();
  }

  return 'N/A';
}
