/* eslint-disable jsx-a11y/label-has-associated-control */
import { useContext, useState } from 'react';
import { Button, InfoModal, Input } from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';

import './add-video.scss';

interface AddVideoProps {
  defaultTitle?: string;
  handleSubmit: any;
}

export const AddVideo = ({
  defaultTitle = '',
  handleSubmit,
}: AddVideoProps) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [link, setLink] = useState('');
  const [title, setTitle] = useState(defaultTitle);

  const handleSubmitButton = () => {
    if (!title.length || !link) {
      return;
    }

    const payload = {
      name: title,
      type: 'Link',
      link,
    };
    handleSubmit(payload);
    closeModal();
  };

  const reopenCurrentModal = () => {
    openModal(
      <AddVideo
        defaultTitle={title}
        handleSubmit={handleSubmit}
      />,
    );
  };

  const handleDiscardButton = () => {
    openModal(
      <InfoModal
        handleButtonClick={closeModal}
        handleSecondaryButtonClick={reopenCurrentModal}
        buttonLabel="Discard"
        secondaryButtonLabel="Cancel"
        description="Are you sure you want to discard all the changes you made to this document?"
        title="Discard Changes"
        isButtonDanger
      />,
      true,
    );
  };

  return (
    <div className="add-document">
      <div className="add-document__title">
        Add Video Link
      </div>
      <div className="add-document__content">
        <form action="" className="add-document__form">
          <div className="add-document__form-field add-document__form-field--required">
            <Input
              type="text"
              id="link"
              label="Youtube Link"
              placeholder="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
              value={link}
              handleValueChange={(value) => setLink(value)}
            />
          </div>
          <div className="add-document__form-field add-document__form-field--required">
            <Input
              type="text"
              id="title"
              label="Title"
              placeholder="e.g. Amazon Resume"
              value={title}
              handleValueChange={(value) => setTitle(value)}
            />
          </div>
        </form>
      </div>
      <div className="add-document__actions">
        <Button
          handleClick={handleDiscardButton}
          label="Cancel"
          mode="primary"
          size="medium"
          outlined
        />
        <Button
          handleClick={handleSubmitButton}
          label="Add"
          mode="primary"
          size="medium"
        />
      </div>
    </div>
  );
};
