import { Company, CompanyProfile } from '../types/company';

/**
 * Transforms company data from API response format to internal Company type
 * @param company - Raw company data from API
 * @returns Transformed company data in consistent internal format
 */
export const transformCompanyData = (company: CompanyProfile): Company => {
  const {
    general_information: gi,
    application_info: ai,
    partner_type: partnerType,
    profile_type: profileType,
    teams,
  } = company;

  return {
    id: gi.id,
    name: gi.name,
    logo_url: gi.logo_url,
    company_url: gi.website_url,
    industry: gi.industry,
    hq_location: gi.hq_location,
    overview: gi.overview,
    short_description: gi.description,
    application_status: ai.application_status,
    application_id: ai.application_id,
    careeros_industry: gi.careeros_industry,
    size: gi.company_size,
    linkedin_url: gi.linkedin_url,
    twitter_url: gi.twitter_url || '',
    partner_type: partnerType,
    profile_type: profileType,
    teams: teams!,
    bg_image_url: gi.bg_image_url,
    jobs: [], // Required by Company type
    tags: [], // Required by Company type
  };
};
