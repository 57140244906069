import DOMPurify from 'dompurify';
import {
  useCallback, useContext, useRef, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { Button, IconButton, Tooltip } from '@careeros/coco';
import { PopupBoxWrapper } from '@/domains/generic/popup-box/popup-box';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { prettifyHTML } from '@/services/helpers/processHtml';
import { AIToolLoader } from '@/domains/core/company/components/ai-tool-loader/ai-tool-loader';
import './student-ai-summary.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { addCAStudentNote } from '../../api/note';
import { formatDateWithFullMonth } from '@/services/helpers/date';
import { useAISummaryQuery } from '@/services/queries/student';

type Props = {
  studentID: string;
  name: string;
};

export const StudentAISummary = ({
  studentID,
  name,
}: Props) => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { data: AISummary, isLoading: isAISummaryLoading } = useAISummaryQuery(studentID || '', { enabled: !!studentID && isPopupOpen });
  const contentRef = useRef<HTMLDivElement>(null);
  const { addToast }: AddToastType = useContext(ToastContext);
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();

  const handleCopyToClipboard = () => {
    if (!contentRef.current) {
      return;
    }

    try {
      const content = contentRef.current.innerText;
      navigator.clipboard.writeText(content);

      addToast({ type: 'success', message: 'Copied to clipboard' });
      trackEvent('CA AI Summary Copied to Clipboard');
    } catch (error) {
      addToast({ type: 'error', message: 'Failed to copy to clipboard' });
    }
  };

  const handleSaveNote = useCallback(async () => {
    if (!AISummary) {
      return;
    }

    try {
      const title = `AI Summary for ${name || 'this student'}, ${formatDateWithFullMonth(Date.now())}`;
      await addCAStudentNote(studentID, { title, content: AISummary.message, is_ai_generated: true });

      queryClient.invalidateQueries(['ca-student-notes', studentID]);

      trackEvent('CA AI Summary Saved as Note');

      addToast(
        {
          type: 'success',
          message: 'Note added successfully',
          additionalMessage: "This note is now saved to your 'Notes' section for this student.",
        },
      );
    } catch (error) {
      addToast({ type: 'error', message: 'Failed to add note' });
    }
  }, [AISummary, studentID]);

  const closePopup = () => setIsPopupOpen(false);
  const togglePopup = () => setIsPopupOpen((prev) => !prev);

  return (
    <div className="student-ai-summary">
      <Button
        mode="special"
        size="medium"
        label="AI Summary"
        icon="bi bi-stars"
        handleClick={togglePopup}
      />
      <div className="student-ai-summary__popup-wrapper">
        {isPopupOpen && (
          <PopupBoxWrapper handleBlur={closePopup}>
            <div className="student-ai-summary__popup">
              <div className="student-ai-summary__popup-header">
                <h3 className="student-ai-summary__popup-title">
                  <i className="student-ai-summary__title-icon bi bi-stars" />
                  <span>AI Summary</span>
                </h3>
              </div>
              {(AISummary && !isAISummaryLoading) && (
              <div className="student-ai-summary__popup-content">
                <div className="student-ai-summary__actions">
                  <Tooltip
                    label="Copy to clipboard"
                    position="top"
                    withArrow
                  >
                    <IconButton
                      icon="bi bi-copy"
                      mode="primary"
                      outlined
                      size="small"
                      handleClick={handleCopyToClipboard}
                    />
                  </Tooltip>
                  <Tooltip
                    label="Save as Note"
                    position="top"
                    withArrow
                  >
                    <IconButton
                      icon="bi bi-pencil"
                      mode="primary"
                      outlined
                      size="small"
                      handleClick={handleSaveNote}
                    />
                  </Tooltip>
                </div>
                <p
                  ref={contentRef}
                  className="student-ai-summary__response-text"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(AISummary.message)) }}
                />
              </div>
              )}
              {isAISummaryLoading && (
              <AIToolLoader
                text="Generating your unique insights."
              />
              )}
            </div>
          </PopupBoxWrapper>
        )}
      </div>
    </div>
  );
};
