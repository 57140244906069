import { InfoModal } from '@careeros/coco';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { PublicLayout } from '@/domains/core/layouts/public-layout';
import { setLatestUrlToStorage } from '@/services/helpers/browser-storage';
import { ModalContext } from '@/components/modal/modal-provider';
import { useGetSingleJobById } from '@/services/queries/jobs';
import ErrorPage from './error';
import { JobDescription } from '@/domains/core/job';
import { useGetSingleCompanyProfileBySlug } from '@/services/queries/company';
import { Loader } from '@/components/loader/loader';
import { transformCompanyData } from '@/domains/core/company/utils/company-transformers';
import { PublicJobSEO } from '@/services/seo/public-job';

function PublicJobPage() {
  const { jobId, companySlug } = useParams();
  const navigate = useNavigate();
  const {
    isAuthenticated,
  } = useAuth0();
  const { openModal } = useContext(ModalContext) as any;
  const { data: job, isLoading: isJobLoading, isFetching: isJobFetching } = useGetSingleJobById(jobId!, { enabled: !!jobId });
  const { data: company } = useGetSingleCompanyProfileBySlug(companySlug, { enabled: !!companySlug && companySlug !== 'null' });

  const companyData = company && transformCompanyData(company);

  if (isJobLoading || isJobFetching) {
    return <Loader />;
  }

  if (!job) {
    return <ErrorPage />;
  }

  if (isAuthenticated) {
    return <Navigate to={`/app/jobs/${jobId}`} />;
  }

  const handleLoginClick = () => {
    setLatestUrlToStorage(`${window.location.origin}/app/jobs/${jobId}`);
    navigate('/with-university');
  };

  const handleApplyAction = () => {
    openModal(
      <InfoModal
        icon="bi bi-sign-stop"
        title="80% of jobs are filled by referrals and networking!"
        description={`You have not networked with this company, yet. Are you sure you want to apply? Connect with decision makers in ${job.company_name} and get tips every step of the way.`}
        secondaryButtonLabel="Yes, I'm sure"
        handleSecondaryButtonClick={() => {
          const utmUrl = `${job.linkedin_url}${job.linkedin_url.includes('?') ? '&' : '?'}utm_source=CareerOS`;
          window.open(utmUrl, '_blank');
        }}
        buttonLabel="Log in to CareerOS"
        handleButtonClick={handleLoginClick}
      />,
    );
  };

  const handleSaveAction = () => {
    setLatestUrlToStorage(`${window.location.origin}/app/jobs/${job.id}`);
    navigate('/with-university');
  };

  return (
    <PublicLayout handleLoginClick={handleLoginClick}>
      <PublicJobSEO
        title={job.title}
        description={job.description || `${job.title} position at ${job.company_name}`}
        company={{
          name: job.company_name,
          logo_url: job.linkedin_logo_url,
          hq_location: job.location,
        }}
      />
      <JobDescription
        company={companyData}
        job={job}
        isSaved={false}
        setAllJobsTab={() => { }}
        handleSaveAction={handleSaveAction}
        handleUnsaveAction={() => { }}
        optimisticJobUpdate={() => { }}
        defaultShowDidYouApply={false}
        handleApplyAction={handleApplyAction}
      />
    </PublicLayout>
  );
}

export default PublicJobPage;
