import { Loader } from '@/components/loader/loader';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';

function OverviewAdvisorPage() { // old page we don't use anymore
  return (
    <div>
      <div className="white-wrapper">
        <h2>Welcome to Career Advisor Overview</h2>
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(OverviewAdvisorPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
