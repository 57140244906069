import React, { useRef, useState } from 'react';
import './preview-card-wrapper.scss';
import { PopupBoxWrapper } from '../../popup-box/popup-box';

type Props = {
  name: string;
  onNameClick?: () => void;
  onNameHover?: () => void;
  className?: string;
  children?: React.ReactNode;
  popupFitToScreenHeight?: boolean;
};

export const PreviewCardWrapper = ({
  name,
  onNameClick,
  onNameHover,
  className,
  children,
  popupFitToScreenHeight = false,
}: Props) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [delayHandler, setDelayHandler] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    setDelayHandler(setTimeout(() => {
      onNameHover?.();
      setIsPreviewOpen(true);
    }, 100));
  };

  const handleMouseLeave = () => {
    if (delayHandler) {
      clearTimeout(delayHandler);
    }

    setIsPreviewOpen(false);
  };

  const handlePreviewClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setIsPreviewOpen(false);
  };

  return (
    <div
      className="preview-card-wrapper"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span ref={titleRef} onClick={onNameClick} className={`preview-card-wrapper__name ${className}`}>
        {name}
      </span>

      {isPreviewOpen && (
        <div className="preview-card-wrapper__preview-container">
          <PopupBoxWrapper parentRef={titleRef} fitToScreenHeight={popupFitToScreenHeight}>
            <div
              onClick={handlePreviewClick}
              className="preview-card-wrapper__preview"
            >
              {React.Children.map(children, child => {
                if (React.isValidElement(child)) {
                  return React.cloneElement(child);
                }
                return child;
              })}
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};

type SubComponentProps = {
  children: React.ReactNode;
};

PreviewCardWrapper.Preview = function PreviewCardWrapperPreview({ children }: SubComponentProps) {
  return <div className="preview-card-wrapper__preview">{children}</div>;
};
