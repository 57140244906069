import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Droppable, Draggable, DropResult,
} from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

import { Button, Banner, Counter } from '@careeros/coco';
import ChromeLogo from '@/assets/images/chrome-icon.png';
import './kanban-board.scss';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ColumnConfetti } from '../column-confetti/column-confetti';
import Confetti from '../../confetti/confetti';
import { CompanyKanbanTileV2 } from '../tile-v2/kanban-tile';
import { TileV2 } from '@/domains/core/company/types/tile-v2';
import { JobKanbanTileV2 } from '../tile-v2/kanban-job-tile';
import { ApplicationStatus } from '@/domains/core/tracker-data';
import { DragAndDropPayload } from '../types';

interface KanbanBoardProps {
  columns: ApplicationStatus[];
  layout: any;
  onChange?: (cardInfo: DragAndDropPayload, newStatus: ApplicationStatus) => Promise<void>;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  withPostingScore?: boolean;
}

export const KanbanBoardV2 = ({
  columns,
  layout,
  onChange,
  scrollContainerRef,
  onScroll,
  withPostingScore = false,
}: KanbanBoardProps) => {
  const { trackEvent } = useAnalytics();
  const [promptToInstallExtension, setPromptToInstallExtension] = useState(false);
  const { checkLinkedInLogin } = useExtensionMessaging();
  const [offerAnimationCount, setOfferAnimationCount] = useState(0);
  const [newInterviewStageTileIds, setNewInterviewStageTileIds] = useState<string[]>([]);
  const navigate = useNavigate();

  const checkUserExtension = async () => {
    const linkedInLoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      setPromptToInstallExtension(true);
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !onChange) {
      return;
    }
    const { source, destination, draggableId } = result;
    const payload: DragAndDropPayload = {
      id: draggableId,
      status: source.droppableId,
      dropPosition: destination.index,
    };

    onChange(payload, destination.droppableId as ApplicationStatus);

    if (destination.droppableId === payload.status) {
      return;
    }

    setNewInterviewStageTileIds((prev) => prev.filter((id) => id !== draggableId));

    if (destination.droppableId === 'Offer') {
      setOfferAnimationCount(prev => prev + 1);
    }

    if (destination.droppableId === 'Interviewing') {
      setNewInterviewStageTileIds(prev => [...prev, draggableId]);
    }
  };

  const handleGetExtensionClick = () => {
    window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
    trackEvent('Get Extension on OB banner clicked');
  };

  useEffect(() => {
    checkUserExtension();
  }, []);

  return (
    <div className="kanban-board">
      {promptToInstallExtension && (
        <div className="kanban-board__banner">
          <Banner
            buttonLabel="Get Extension"
            handleButtonClick={handleGetExtensionClick}
            image={ChromeLogo}
            buttonPlacement="horizontal"
            buttonIcon="bi bi-box-arrow-up-right"
            buttonIconPosition="right"
            title="Save jobs, contacts and companies from LinkedIn and other job boards to bring all into one place with our Chrome Extension."
          />
        </div>
      )}
      <div className="kanban-board__content" ref={scrollContainerRef} onScroll={onScroll} id="kanban-board">
        <DragDropContext onDragEnd={onDragEnd}>
          {columns.map((column, index) => (
            <div
              key={column}
              className={`kanban-board__column kanban-board__column--nr-${index}`}
            >
              {column === 'Offer' && (
                <div className="kanban-board__confetti">
                  <ColumnConfetti fireCount={offerAnimationCount} />
                </div>
              )}
              <div className="kanban-board__column__title">
                <span className="kanban-board__column__title__text">{column}</span>
                <span className="kanban-board__title-number">
                  <Counter counter={layout[column] && layout[column].length} size="medium" mode="default" />
                </span>
              </div>
              {index === 0 && (
              <div className="kanban-board__column__cta">
                <Button
                  label="Add Company"
                  icon="bi bi-plus-circle"
                  handleClick={() => navigate('/app/companies/explore')}
                  mode="invisible"
                  size="large"
                />
              </div>
              )}
              <Droppable key={column} droppableId={column}>
                {(providedDrop) => (
                  <div
                    className="kanban-board__column__drop-zone"
                    ref={providedDrop.innerRef}
                    {...providedDrop.droppableProps}
                  >
                    {layout[column] && layout[column].map((tile: TileV2, idx: number) => (
                      <Draggable key={tile.id} draggableId={tile.id} index={idx}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div className="kanban-board__tile">
                              {newInterviewStageTileIds.includes(tile.id) && (
                                <div className="kanban-board__confetti">
                                  <Confetti fireCount={1} emojis={['🤞', '🚀']} duration={500} />
                                </div>
                              )}
                              {tile.type === 'company' ? (
                                <CompanyKanbanTileV2
                                  key={tile.id}
                                  tile={tile}
                                  withTrafficLights={withPostingScore}
                                />
                              ) : (
                                <JobKanbanTileV2
                                  key={tile.id}
                                  tile={tile}
                                  withTrafficLights={withPostingScore}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {providedDrop.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          ))}
        </DragDropContext>
      </div>
    </div>
  );
};
