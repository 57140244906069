import { useLoaderData, useNavigate, useRevalidator } from 'react-router-dom';
import { useState } from 'react';

import { Button } from '@careeros/coco';
import {
  CompanyCard,
  CompaniesFilters,
  EmptySearchResult,
  CompanyCardContainerSkeleton,
} from '@/domains/core/company';
import { Loader } from '@/components/loader/loader';
import {
  CompaniesFilterType, Company, CompanyLocation, FavoriteCompaniesResponse,
} from '@/domains/core/company/types';
import { useCompaniesFiltering } from '@/services/hooks/use-companies-filtering';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useCheckExtension } from '@/services/hooks/use-check-extension';
import './styles/companies.scss';

function FavoriteCompaniesPage() {
  const navigate = useNavigate();
  const {
    applications, locations, tags, sizes,
  } = useLoaderData() as FavoriteCompaniesResponse;
  const {
    observerTarget, companies, isLoading, isNewPageLoading, handleFiltersChange,
  } = useCompaniesFiltering({ defaultFilterAttribute: 'saved_by_user', defaultFilterValue: true });
  const revalidator = useRevalidator();
  const [isCompanyLoactionHidden, setIsCompanyLoactionHidden] = useState(false);
  const { checkUserExtension } = useCheckExtension();

  const goToAdvancedSearch = () => navigate('/app/companies');

  const handleFiltering = (filters: CompaniesFilterType, savedByUser: boolean | undefined, filterLocations: CompanyLocation[]) => {
    handleFiltersChange(filters, true, filterLocations);
    setIsCompanyLoactionHidden(filterLocations.length > 0);
  };

  return (
    <div>
      <div className="companies-wrapper" id="main">

        <div className="companies-wrapper__sticky-container">
          <div className="companies-wrapper__top">
            <div className="companies-wrapper__header">
              <h1 className="companies-wrapper__title">Your Saved Companies</h1>

            </div>
            <div className="companies-wrapper__button">
              <Button
                mode="invisible"
                size="medium"
                icon="bi bi-box-arrow-up-right"
                iconPosition="right"
                label="View All Companies"
                handleClick={goToAdvancedSearch}
              />
            </div>
          </div>

          <CompaniesFilters
            locations={locations}
            tags={tags}
            sizes={sizes}
            handleFiltersChange={handleFiltering}
            withIndustryFilter
            withoutSavedFilter
            preserveSearchParams
          />
        </div>
        {(companies.length > 0 && !isLoading) && (
          <>
            <ul className="companies-listing">
              {companies.map((company: Company) => (
                <CompanyCard
                  key={company.id}
                  id={company.id}
                  companyName={company.name}
                  companyIndustry={company.careeros_industry}
                  officeLocations={company.locations || []}
                  companySize={company.company_size}
                  companyDescription={company.short_description ? company.short_description : company.overview}
                  imageURL={company.logo_url}
                  tags={company.tags}
                  onArchiveCompany={() => revalidator.revalidate()}
                  companyApplicationId={applications?.find((application) => application.company_id === company.id)?.id || ''}
                  isLocationHidden={isCompanyLoactionHidden}
                />
              ))}
            </ul>
            <div ref={observerTarget} />
            {isNewPageLoading && <div className="companies-wrapper__loader" />}
          </>
        )}

        {!companies.length && !isLoading && (
          <EmptySearchResult
            title="We could not find any results for your search."
            subtitle="We couldn’t quite find companies that match your filters. But don’t worry! You can head to LinkedIn and save any company using our Chrome Extension."
            checkExtension={checkUserExtension}
          />
        )}

        {isLoading && (
          <div className="companies-listing">
            <CompanyCardContainerSkeleton />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(FavoriteCompaniesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
