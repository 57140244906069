export const formDataToRequestReview = (formData: FormData): Record<string, string> => {
  const requestData: Record<string, string> = {};

  formData.forEach((value, key) => {
    requestData[key] = value.toString();
  });

  return requestData;
};

export const extractFormData = (event: React.FormEvent<HTMLFormElement>): Record<string, string> => {
  const formData = new FormData(event.currentTarget);
  return formDataToRequestReview(formData);
};
