import {
  Button, IconButton, ToggleViews, Tooltip,
} from '@careeros/coco';

import { KanbanFilterBar } from '../../kanban/filter/filter-bar';
import './overview-header.scss';
import { FilterNameType, FilterOption } from '@/domains/core/company/types';

type Props = {
  handleFiltersChange: (filters: { [name in FilterNameType]: FilterOption[] }, searchQuery: string) => void;
  savedIndustries: string[];
  handleContentScroll: (direction: 'left' | 'right') => void;
  openFullCommentHistory?: () => void;
  showRightScrollArrow: boolean;
  showLeftScrollArrow: boolean;
  selectedView: number;
  changeSelectedView: (value: number) => void;
  hasUnresolvedComments?: boolean;
  hideJobsFilter?: boolean;
  openPostingScoreModal?: () => void;
  withPostingScoreFilter?: boolean;
};

export const OverviewHeader = ({
  handleFiltersChange,
  savedIndustries,
  handleContentScroll,
  openFullCommentHistory,
  showRightScrollArrow,
  showLeftScrollArrow,
  selectedView,
  changeSelectedView,
  hasUnresolvedComments = false,
  hideJobsFilter = false,
  openPostingScoreModal,
  withPostingScoreFilter = false,
}: Props) => (
  <div className="overview-header">
    <div className="overview-header__left">
      <div className="overview-header__view-toggle">
        <ToggleViews
          size="small"
          selectedValue={selectedView}
          emitSelectedValue={changeSelectedView}
          icons={['bi bi-kanban', 'bi bi-grid-3x3', 'bi bi-grid']}
          labels={['Kanban View', 'Table View']}
          iconsSelected={['bi bi-kanban-fill', 'bi bi-table', 'bi bi-grid-fill']}
        />
      </div>
      <KanbanFilterBar
        handleFiltersChange={handleFiltersChange}
        industries={savedIndustries}
        withStatusFilter={selectedView !== 0}
        hideJobsFilter={hideJobsFilter}
        openPostingScoreModal={openPostingScoreModal}
        withPostingScoreFilter={withPostingScoreFilter}
      />
    </div>
    <div className="overview-header__right">
      {openFullCommentHistory && (
        <div className="overview-header__comments">
          <Tooltip label="View full comments history" position="top" withArrow>
            <Button
              label="Comments"
              mode="primary"
              outlined
              size="medium"
              icon="bi bi-arrow-bar-left"
              iconPosition="right"
              handleClick={openFullCommentHistory}
              withUnreadIndicator={hasUnresolvedComments}
            />
          </Tooltip>
        </div>
      )}
      {selectedView === 0 && (
        <div className="overview-header__controls">
          <IconButton
            icon="bi bi-chevron-left"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleContentScroll('left')}
            disabled={!showLeftScrollArrow}
          />
          <IconButton
            icon="bi bi-chevron-right"
            mode="rounded"
            size="medium"
            outlined
            handleClick={() => handleContentScroll('right')}
            disabled={!showRightScrollArrow}
          />
        </div>
      )}
    </div>
  </div>
);
