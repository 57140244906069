import { SuggestedContactType } from '@/domains/core/contact/types';
import { http } from './http';

export const getSuggestedContacts = async (): Promise<SuggestedContactType[] | null> => http.get('/suggested-contacts/self');

export const getSuggestedContactsByCompanyID = async (companyID: string) => http.get(`/suggested-contacts/bycompany/${companyID}`);

export const getCampusChampions = async () => http.get('/campus-champions/self');

export const connectCampusChampion = async (campusChampionID: string) => http.post(`/suggested-contacts/${campusChampionID}/connect`, {});

export const getCampusChampionsByCompanyID = async (companyID: string) => http.get(`/campus-champions/bycompany/${companyID}`);
