import { useQueryClient } from 'react-query';
import { InfoModal } from '@careeros/coco';
import { useNavigate } from 'react-router-dom';
import { useContext, useMemo } from 'react';
import { changeApplicationStatus } from '@/services/api/application';
import { TileCta } from '../../kanban/tile-cta/tile-cta';
import './ob-table-actions.scss';
import { CommentsHistorySidebar } from '../../sidebars';
import { TrackerContextType, TrackerContext } from '@/domains/core/company';
import { SidebarContext } from '@/components/sidebar/sidebar-provider';
import { CommentFromCA } from '@/domains/core/advisor/types';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { ModalContext } from '@/components/modal/modal-provider';
import { statuses, trackerTabs } from '@/domains/core/tracker-data';
import { getApplicationAIActionsByStatus } from '@/domains/core/tracker-data/application-actions';
import { ApiStatus } from '@/domains/core/company/types';
import { DropdownMenuButton } from '../../kanban/dropdown-menu-button/dropdown-menu-button';
import { useGetSelfBaseResume } from '@/services/queries/resume';

type Props = {
  companyName: string;
  companyID: string;
  jobID?: string;
  comments: CommentFromCA[];
  countOfContacts: number;
  tileID: string;
  tileOrdering: number;
  type?: 'job' | 'company';
  tileStatus: ApiStatus;
  jobTitle?: string;
};

export const OBTableActions = ({
  companyName,
  companyID,
  comments,
  countOfContacts,
  tileID,
  tileOrdering,
  type = 'company',
  jobID,
  tileStatus,
  jobTitle,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const { openSidebar } = useContext(SidebarContext) as any;
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { data: baseResumes } = useGetSelfBaseResume();
  const hasBaseResume = useMemo(() => !!baseResumes?.length, [baseResumes]);
  const handleOpenTrackerTab = (tab: (typeof trackerTabs)[number], additionalParams?: { [key: string]: string }) => {
    openTracker(companyID, tab, additionalParams, jobID);
  };
  const navigate = useNavigate();
  const navigateToPage = (path: string) => {
    navigate(path);
  };
  const AIActions = useMemo(() => getApplicationAIActionsByStatus(tileStatus, handleOpenTrackerTab, navigateToPage, type, hasBaseResume, jobID), [tileStatus, jobID, hasBaseResume]);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const queryClient = useQueryClient();

  const handleContactsIconClicked = () => {
    trackEvent('Overview Board: Contact Icon clicked', {});
    handleOpenTrackerTab('Contacts');
  };

  const handleCommentsIconClick = () => {
    trackEvent('Overview Board: Comments Icon clicked', {});

    openSidebar(
      <CommentsHistorySidebar
        applicationID={tileID}
        title="Company Comments"
        subtitle={`All Comments made for ${companyName}`}
      />,
    );
  };

  const archiveCompany = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveCompany', 'true');
    }
    closeModal();
    await changeApplicationStatus(tileID, statuses.Archived, tileOrdering);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);
    trackEvent('Overview Board: Company Archive', {});
  };

  const openAddJobModal = () => {
    trackEvent('Overview Board: Jobs Icon clicked', {});
    handleOpenTrackerTab('Jobs');
  };

  const goToNotesTab = () => {
    trackEvent('Overview Board: Notes Icon clicked', {});
    handleOpenTrackerTab('Notes');
  };

  const openAreYouSure = () => {
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle"
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={archiveCompany}
          title={type === 'job' ? 'Archive Job' : 'Archive Company'}
          description={`Archiving will remove ${jobTitle || companyName} from your Overview Board. This action can’t be reversed.`}
          buttonLabel="Keep on Board"
          secondaryButtonLabel="Archive"
          rememberText="Don't ask me again"
          isSecondButtonDanger
          isImageDanger
          secondaryButtonIcon="bi bi-archive"
          buttonIcon="bi bi-hand-thumbs-up"
          isButtonOutlined
        />,
      );
    } else {
      archiveCompany(false);
    }
  };

  return (
    <div className="tile-actions">
      <TileCta
        label="View Notes"
        icon="bi bi-sticky"
        hoverIcon="bi bi-sticky-fill"
        handleClick={goToNotesTab}
      />
      <TileCta
        label={`You have ${countOfContacts} contact(s) at this company.`}
        icon="bi bi-people"
        handleClick={handleContactsIconClicked}
        counter={countOfContacts}
        hoverIcon="bi bi-people-fill"
      />
      {comments?.length > 0 && (
        <TileCta
          label="See Comments"
          icon="bi bi-chat-square-text"
          handleClick={handleCommentsIconClick}
          counter={comments.length}
          hoverIcon="bi bi-chat-square-text-fill"
        />
      )}
      <TileCta
        label={type === 'job' ? 'Save another Job Posting' : 'Save Job Posting'}
        icon="bi bi-suitcase-lg"
        handleClick={openAddJobModal}
        hoverIcon="bi bi-suitcase-lg-fill"
      />

      <TileCta
        label={type === 'job' ? 'Archive Job Posting' : 'Archive Company'}
        icon="bi bi-archive"
        handleClick={openAreYouSure}
        hoverIcon="bi bi-archive-fill"
        isDangerous
      />
      {AIActions.length > 0 && (
        <DropdownMenuButton
          dropdownOptions={AIActions}
          dropdownHorizontalAlignment="right"
          tooltipLabel="AI Tools"
          scrollContainerQuery=".applications-table"
        >
          <i className="ob-table-actions__ai-icon bi bi-stars" />
        </DropdownMenuButton>
      )}
    </div>
  );
};
