import { useState, useEffect } from 'react';
import { useCareerAdvisorsForUniversity } from '@/services/queries/advisor';
import { CADropdownOption } from '../types';

export const useCareerAdvisors = () => {
  const [careerAdvisors, setCareerAdvisors] = useState<CADropdownOption[]>([]);
  const { data: careerAdvisorsData, isLoading } = useCareerAdvisorsForUniversity();

  useEffect(() => {
    if (!careerAdvisorsData) return;

    const mappedAdvisors = careerAdvisorsData.map(advisor => ({
      id: advisor.id,
      name: `${advisor.first_name} ${advisor.last_name}`,
      value: advisor.id,
      logo: advisor.profile_picture_url,
    }));

    setCareerAdvisors(mappedAdvisors);
  }, [careerAdvisorsData]);

  return { careerAdvisors, isLoading };
};
