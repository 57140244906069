/* eslint-disable no-restricted-syntax */
import {
  useContext, useEffect, useRef, useState, useCallback,
} from 'react';
import * as Sentry from '@sentry/react';
import { useLoaderData, useNavigate } from 'react-router';
import { isWeekend } from 'date-fns';
import { Button } from '@careeros/coco';
import { Loader } from '@/components/loader/loader';
import './styles/dashboard.scss';

import { ModalContext } from '@/components/modal/modal-provider';
import { MessageTemplates } from '@/domains/generic/modals';

import { updateContactConnectionStatus } from '@/services/api/contact';
import {
  WeeklyGoals,
  ChallengesWidget,
  Quests,
  MobileDashboard,
  DashboardUniversityResources,
  DashboardActions,
  ResourceTile,
} from '@/domains/core/student';

import { useSelf, useSelfUniversity } from '@/services/queries/user';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { Contact, UserConnectionsResponse } from '@/domains/core/contact/types';
import { updateSelfProfileImage } from '@/services/api/user';
import { batchTasks } from '@/services/helpers/batch-tasks';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { DasboardActionTileType, DashboardResponse } from '@/domains/core/student/types';
import { SuggestedContactsContainer } from '@/domains/core/contact/components/suggested-contacts-container/suggested-contacts-container';
import { AnchorButton } from '@/domains/core/student/components/anchor-button/anchor-button';
import { checkIfCohortHasResources } from '@/domains/core/student/components/dashboard-university-resources/dashboard-resources';
import { useDashboardActions } from '@/services/queries/actions';
import { isMobileDevice } from '@/services/helpers/responsive';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';
import { isNight } from '@/services/helpers/date-utils';
import { GamificationLeaderboard } from '@/domains/core/student/components/leaderboard/gamification-leaderboard';
import { GamificationLeaderboardType } from '@/types/gamification';
import { useIsBetaUser } from '@/services/hooks/use-is-beta-user';

const RATE_LIMIT = 24;

function DashboardPage() {
  const {
    conversationIDs, statistic, pendingContacts,
  } = useLoaderData() as DashboardResponse;
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const { data: actions, isLoading: isActionsLoading } = useDashboardActions();
  const { hash } = window.location;
  const { trackEvent } = useAnalytics();
  const [isResourcesHighlighted, setIsResourcesHighlighted] = useState(false);
  const [leaderboard, setLeaderboard] = useState<GamificationLeaderboardType | null>(null);
  const [userConnections, setUserConnections] = useState<UserConnectionsResponse>();
  const [hiddenActionTiles, setHiddenActionTiles] = useState<DasboardActionTileType[]>([]);
  const [isFirstGamificationBoxVisible, setIsFirstGamificationBoxVisible] = useState(false);
  const {
    getUserLinkedInConnections, updateAllLinkedInConversations, checkLinkedInLogin,
    getUserProfilePicture,
  } = useExtensionMessaging();
  const { openModal } = useContext(ModalContext) as any;
  const questsBoxRef = useRef<HTMLDivElement>(null);
  const resourcesBoxRef = useRef<HTMLDivElement>(null);
  const firstGamificationBoxRef = useRef<HTMLDivElement>(null);
  const { completeActionIfAvailable, isActionsLoading: isGamificationLoading } = useGamification();
  const navigate = useNavigate();
  const { isBetaUser } = useIsBetaUser();

  const {
    leaderboards,
    isLeaderboardsLoading,
    isLeaderboardsError,
  } = useGamification();

  const openTemplatesModal = () => {
    openModal(
      <MessageTemplates
        templates={[]}
      />,
    );
  };

  const statusUpdateTask = async (contact: Contact) => {
    await updateContactConnectionStatus(contact, 'connected');
  };

  const checkStatusUpdates = async (contactsData: Contact[]) => {
    const userConnectionsResponse: UserConnectionsResponse | undefined = await getUserLinkedInConnections();
    setUserConnections(userConnectionsResponse);

    if (!userConnectionsResponse) {
      return;
    }

    const connectedContacts = userConnectionsResponse.connected || [];
    const onlyConnected = contactsData.filter((contact: Contact) => connectedContacts.find((connection) => connection.url === contact.linkedin_url));
    if (onlyConnected.length) {
      const batches = batchTasks(onlyConnected, RATE_LIMIT, statusUpdateTask);

      for await (const batch of batches) {
        await Promise.all(batch.map(task => task()));
      }
    }
  };

  const highlightResuorcesBox = () => {
    if (!resourcesBoxRef.current) {
      return;
    }

    const questsScrollTop = (resourcesBoxRef.current?.getBoundingClientRect().top || 0) + window.scrollY - 20;

    window.scrollTo({ top: questsScrollTop, behavior: 'smooth' });
    setIsResourcesHighlighted(true);

    setTimeout(() => setIsResourcesHighlighted(false), 3000);
  };

  const scrollToBottom = () => {
    firstGamificationBoxRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const checkForProfilePicture = async () => {
    if (self && !self.profile_picture_url) {
      try {
        const profileImage = await getUserProfilePicture();

        if (!profileImage) {
          // TODO: proper error handling from requests to extension messaging - reason why we don't have profile image
          // would be useful to report to Sentry

          return;
        }

        await updateSelfProfileImage(profileImage);
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };

  const checkUserUniversityResources = () => {
    if (checkIfCohortHasResources(university?.cohort || '', university?.name || '', self?.id || '')) {
      setHiddenActionTiles((prev) => prev.filter((type) => type !== DasboardActionTileType.Resources));
    } else {
      setHiddenActionTiles((prev) => [...prev, DasboardActionTileType.Resources]);
    }
  };

  const checkForActions = () => {
    if (isMobileDevice) {
      completeActionIfAvailable(GAMIFICATION_ACTIONS.MOBILE_LOGIN);
    }

    if (isWeekend(new Date())) {
      completeActionIfAvailable(GAMIFICATION_ACTIONS.WEEKEND_USAGE);
    }

    if (isNight(new Date())) {
      completeActionIfAvailable(GAMIFICATION_ACTIONS.NIGHT_USAGE);
    }
  };

  const getLeaderboardType = useCallback((lb: GamificationLeaderboardType | null): 'xp' | 'challenges' | 'messages' => {
    if (!lb) return 'xp';

    // Check if this leaderboard exists in one of the specific leaderboard arrays
    if (leaderboards?.xp_leaderboards?.some((item: GamificationLeaderboardType) => item.title === lb.title)) {
      return 'xp';
    }
    if (leaderboards?.challenges_leaderboards?.some((item: GamificationLeaderboardType) => item.title === lb.title)) {
      return 'challenges';
    }
    if (leaderboards?.messages_leaderboards?.some((item: GamificationLeaderboardType) => item.title === lb.title)) {
      return 'messages';
    }

    return 'xp';
  }, [leaderboards]);

  useEffect(() => {
    if (hash === '#messagingTemplates') {
      openTemplatesModal();
    }
  }, [hash]);

  useEffect(() => {
    if (isSelfLoading) return;
    checkForProfilePicture();
  }, [isSelfLoading]);

  useEffect(() => {
    if (conversationIDs?.length) {
      checkLinkedInLogin()
        .then((isLogged) => {
          if (isLogged) {
            updateAllLinkedInConversations();
          }
        })
        .catch(() => console.error('Error updating LinkedIn conversations'));
    }
  }, [conversationIDs?.length]);

  useEffect(() => {
    if (pendingContacts.length) {
      checkStatusUpdates(pendingContacts);
    }
  }, [pendingContacts]);

  useEffect(() => {
    if (!isGamificationLoading) {
      checkForActions();
    }
  }, [isGamificationLoading]);

  useEffect(() => {
    if (!isLeaderboardsLoading && !isLeaderboardsError && leaderboards) {
      // Get all leaderboards from the different categories
      const allLeaderboards = [
        ...(leaderboards.xp_leaderboards.filter((ldb) => Boolean(ldb.ranking) && ldb.ranking.length >= 3) || []),
        ...(leaderboards.challenges_leaderboards.filter((ldb) => Boolean(ldb.ranking) && ldb.ranking.length >= 3) || []),
        ...(leaderboards.messages_leaderboards.filter((ldb) => Boolean(ldb.ranking) && ldb.ranking.length >= 3) || []),
      ];

      if (allLeaderboards.length > 0) {
        const randomLeaderboard = allLeaderboards[Math.floor(Math.random() * allLeaderboards.length)];
        setLeaderboard(randomLeaderboard);
      }
    }
  }, [leaderboards, isLeaderboardsLoading]);

  useEffect(() => {
    trackEvent('Page Visit', {}, {
      pageURL: 'Dashboard',
    });

    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFirstGamificationBoxVisible(entry.isIntersecting);
      },
      {
        root: null, // viewport
        rootMargin: '0px', // no margin
        threshold: 0.5, // 50% of target visible
      },
    );

    if (firstGamificationBoxRef.current) {
      observer.observe(firstGamificationBoxRef.current);
    }

    // Clean up the observer
    return () => {
      if (firstGamificationBoxRef.current) {
        observer.unobserve(firstGamificationBoxRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isUniversityLoading || isSelfLoading) return;

    if (university && university.redirect_to_demographics_form && !self?.has_completed_demographics_form && self?.role === 'student') {
      navigate('/app/onboarding');
    }

    checkUserUniversityResources();
  }, [isUniversityLoading, university, self, isSelfLoading]);

  return (
    <>
      <div className="student-dashboard--mobile">
        <MobileDashboard articlesLink={university?.articles_url} />
      </div>
      <div className="student-dashboard">
        {!isFirstGamificationBoxVisible && (
          <div className="student-dashboard__anchor-button">
            <AnchorButton text="Goals, Challenges & More" icon="bi bi-arrow-down" handleClick={scrollToBottom} />
          </div>
        )}
        <div className="student-dashboard__actions">
          <DashboardActions
            mostProgressedApplicationId={actions?.company_id || null}
            highlightResuorcesBox={highlightResuorcesBox}
            hiddenTileTypes={hiddenActionTiles}
            tiles={actions?.cards || []}
            firstName={self?.first_name || ''}
            isLoading={isActionsLoading}
          />
        </div>
        <div className="student-dashboard__row">
          <div ref={questsBoxRef} id="main" className="student-dashboard__row-item">
            <Quests
              userConnections={userConnections}
            />
          </div>
          <div className="student-dashboard__row-item student-dashboard__row-item--with-blur">
            <SuggestedContactsContainer />
          </div>
        </div>
        <div className="career-os-grid">
          <div className="width-12/24">
            <div className="student-dashboard__block">
              {isBetaUser && (
              <div className="student-dashboard__row-item student-dashboard__row-item--with-blur">
                <ChallengesWidget />
              </div>
              )}
              <div className="student-dashboard__box" ref={firstGamificationBoxRef}>
                <WeeklyGoals goals={statistic.weekly_goals} />
              </div>
            </div>
          </div>
          <div className="width-12/24">
            {!isUniversityLoading && university?.name && (
            <div ref={resourcesBoxRef}>
              <DashboardUniversityResources isHighlighted={isResourcesHighlighted} universityName={university?.name} universityCohort={university.cohort} selfId={self?.id || ''} />
            </div>
            )}
            {university?.articles_url && (
              <div className="student-dashboard__box">
                <ResourceTile
                  name="Career Sip Articles"
                  description="Explore insightful articles curated to help you succeed"
                  linkLabel="Explore"
                  link="/app/articles"
                  linkTarget="_self"
                  icon="bi bi-newspaper"
                  customOnClickHandler={() => navigate('/app/articles')}
                />
              </div>
            )}
            {!isLeaderboardsLoading && !isLeaderboardsError && leaderboard && isBetaUser && (
            <div className="student-dashboard__box student-dashboard__box--with-leaderboard">
              <div className="student-dashboard__button-container">
                <Button onClick={() => navigate('/app/leaderboards')} mode="primary" size="small" outlined label="See all Leaderboards" iconPosition="right" icon="bi bi-box-arrow-up-right" />
              </div>
              <GamificationLeaderboard
                key={leaderboard.title}
                type={getLeaderboardType(leaderboard)}
                leaderboard={leaderboard}
              />
            </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomWithAuthenticationRequired(DashboardPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
