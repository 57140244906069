import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@careeros/coco';
// import { useEffect } from 'react';
import JobDescription from '@/domains/core/job/components/job-description/job-description';
import { useGetSingleCompanyProfile } from '@/services/queries/company';
import { Loader } from '@/components/loader/loader';
import { useGetJobByIdPrivate } from '@/services/queries/jobs';
import { saveJob, unsaveJob } from '@/services/api/job';
import { useSelf } from '@/services/queries/user';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { transformCompanyData } from '@/domains/core/company/utils/company-transformers';
// import { useBreadcrumbs } from '@/contexts/breadcrumb-context';
import './styles/job-page.scss';
import { useAlgoliaInsights } from '@/services/hooks/use-algolia';

function JobPage() {
  const { jobId } = useParams();
  const { isAuthenticated } = useAuth0();
  const { data: self } = useSelf({ enabled: isAuthenticated });
  const queryClient = useQueryClient();
  const { trackEvent } = useAnalytics();
  const insights = useAlgoliaInsights();
  const navigate = useNavigate();
  // const { setBreadcrumbs, resetBreadcrumbs } = useBreadcrumbs();

  const { data: job, isLoading: isJobLoading, isFetching: isJobFetching } = useGetJobByIdPrivate(jobId!, { enabled: !!jobId && isAuthenticated });
  const { data: company, isLoading: isCompanyLoading, isFetching: isCompanyFetching } = useGetSingleCompanyProfile(job?.company_id, { enabled: !!job?.company_id && job?.company_id !== 'null' && isAuthenticated });

  const companyData = company && transformCompanyData(company);

  // useEffect(() => {
  //   if (job) {
  //     setBreadcrumbs([
  //       { name: 'Jobs', link: '/app/jobs' },
  //       { name: job.title.length > 30 ? `${job.title.substring(0, 27)}...` : job.title, link: `/app/jobs/${jobId}` },
  //     ]);
  //   }
  //   return () => resetBreadcrumbs();
  // }, [job?.title, jobId, setBreadcrumbs, resetBreadcrumbs]);

  const handleSaveAction = async (jobID: string) => {
    try {
      await saveJob(jobID);

      if (!job) {
        return;
      }

      // Track the save in Recombee
      if (self?.id) {
        insights.addedToCartObjectIDs(
          'Job Saved',
          'job',
          [jobID],
          self.id,
        );
      }

      queryClient.setQueryData(['job', jobId], { ...job, saved: true });

      trackEvent('Job Saved', {
        job_id: jobID,
        company_name: job.company_name,
        job_title: job.title,
        location: job.tags[1]?.tag_content,
        full_time: job.tags[0]?.tag_content,
        on_site: job.tags[2]?.tag_content,
        industry: job.tags[4]?.tag_content,
        size: job.tags[3]?.tag_content,
      });

      await queryClient.invalidateQueries(['applications-no-cache']);
      queryClient.invalidateQueries(['applications-no-cache-v2']);
      queryClient.invalidateQueries(['dashboard-actions']);
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleUnsaveAction = async (jobID: string) => {
    try {
      await unsaveJob(jobID);

      if (!job) {
        return;
      }

      queryClient.setQueryData(['job', jobId], { ...job, saved: false });

      trackEvent('Job unsaved', {
        job_id: jobID,
        company_name: job.company_name,
        job_title: job.title,
        location: job.tags[1]?.tag_content,
        full_time: job.tags[0]?.tag_content,
        on_site: job.tags[2]?.tag_content,
        industry: job.tags[4]?.tag_content,
        size: job.tags[3]?.tag_content,
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handleClose = () => {
    navigate('/app/jobs');
  };

  if (isJobLoading || isJobFetching || isCompanyLoading || isCompanyFetching || !job) {
    return <Loader />;
  }

  return (
    <div className="job-page">
      <div className="job-page__close-button">
        <Button
          icon="bi bi-x-lg"
          mode="text"
          size="small"
          label=""
          handleClick={handleClose}
        />
      </div>
      <JobDescription
        company={companyData}
        job={job}
        isSaved={job.saved}
        setAllJobsTab={() => { }}
        handleSaveAction={handleSaveAction}
        handleUnsaveAction={handleUnsaveAction}
        optimisticJobUpdate={() => { }}
        defaultShowDidYouApply={false}
      />
    </div>
  );
}

export default JobPage;
