import { http } from '@/services/api/http';
import { CommentFromCAType } from '@/domains/core/advisor/types';
import { convertFromApiCAComment } from './model/comment';

const baseURL = import.meta.env.VITE_APP_URL;

export const addStudentGeneralComment = async (studentId: string, comment: string, userEmail: string, caName: string, caProfilePicture: string) => http.post(`/university/advisor/comment/${studentId}`, {
  comment,
  user_email: userEmail,
  url: `${baseURL}/app/overview?openSidebar=true`,
  ca_name: caName,
  profile_picture_url: caProfilePicture,
}, undefined, 'v2');

export const resolveComment = async (commentID: string, commentType: CommentFromCAType): Promise<any> => http.patch(`/comments/${commentID}/resolve`, { comment_type: commentType });

export const getStudentComments = async (studentID: string) => {
  const response = await http.get(`/comments/${studentID}`);

  if (!response?.comments || !Array.isArray(response?.comments)) {
    return [];
  }

  return response.comments.map(convertFromApiCAComment);
};
