import { Button, IconButton, Tooltip } from '@careeros/coco';

type Props = {
  handleApplyButtonClick: () => void;
  handleSaveButtonClick: () => void;
  isSaved: boolean;
  isApplied: boolean;
  isIconCTA?: boolean;
  hiddenApplyButton: boolean;
  isDisabled?: boolean;
  handleShareClick?: () => void;
};

export const JobHeaderCta = ({
  handleApplyButtonClick,
  handleSaveButtonClick,
  isSaved,
  isApplied,
  isIconCTA = false,
  hiddenApplyButton,
  isDisabled = false,
  handleShareClick,
}: Props) => (
  isIconCTA ? (
    <>
      {handleShareClick && (
        <Tooltip label="Copy link to job" position="top" withArrow disableHoverListener={isDisabled}>
          <IconButton
            size="medium"
            mode="rounded"
            outlined
            icon="bi bi-share"
            label="Share"
            handleClick={handleShareClick}
            disabled={isDisabled}
          />
        </Tooltip>
      )}
      {!hiddenApplyButton && (
        <Tooltip label={isApplied ? 'Applied' : 'Apply'} position="top" withArrow disableHoverListener={isDisabled}>
          <IconButton
            size="medium"
            mode="rounded"
            outlined
            label="Apply"
            icon={isApplied ? 'bi bi-send-fill' : 'bi bi-send'}
            handleClick={handleApplyButtonClick}
            disabled={isDisabled}
          />
        </Tooltip>
      )}
      <Tooltip label={isSaved ? 'Saved' : 'Save'} position="top" withArrow disableHoverListener={isDisabled}>
        <IconButton
          data-testid="save-button"
          size="medium"
          mode="rounded"
          label={isSaved ? 'Saved' : 'Save'}
          icon={isSaved ? 'bi bi-bookmark-heart-fill' : 'bi bi-bookmark-heart'}
          handleClick={handleSaveButtonClick}
          disabled={isDisabled}
        />
      </Tooltip>
    </>
  ) : (
    <>
      {handleShareClick && (
        <div>
          <Tooltip label="Copy link to job" position="top" withArrow disableHoverListener={isDisabled}>
            <Button
              mode="primary"
              size="medium"
              outlined
              icon="bi bi-share"
              label="Share"
              onClick={handleShareClick}
              disabled={isDisabled}
            />
          </Tooltip>
        </div>
      )}
      {!hiddenApplyButton && (
        <Button
          size="medium"
          mode="primary"
          outlined
          iconPosition="right"
          label="Apply"
          icon="bi bi-send"
          handleClick={handleApplyButtonClick}
          disabled={isDisabled}
        />
      )}
      <Button
        data-testid="save-button"
        size="medium"
        mode="primary"
        label={isSaved ? 'Saved' : 'Save'}
        icon="bi bi-bookmark-heart"
        handleClick={handleSaveButtonClick}
        disabled={isDisabled}
      />
    </>
  )
);
