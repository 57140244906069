import confetti from 'canvas-confetti';

export const triggerConfetti = () => {
  const duration = 300;
  const end = Date.now() + duration;

  (function frame() {
    // Left confetti fires immediately
    confetti({
      particleCount: 3,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
    });

    // Right confetti fires after a 0.1s delay
    setTimeout(() => {
      confetti({
        particleCount: 3,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
      });
    }, 150);

    if (Date.now() < end) {
      requestAnimationFrame(frame);
    }
  }());
};
