import { Tooltip } from '@careeros/coco';
import './tile-traffic-lights.scss';
import { useEffect, useState } from 'react';

const trafficLightValues = [1, 2, 3] as const;

const trafficLightColors: { [key in 1 | 2 | 3]: string } = {
  1: 'red',
  2: 'yellow',
  3: 'green',
} as const;

type TrafficLightValue = keyof typeof trafficLightColors;

const defaultStatusLabels: { [key in TrafficLightValue]: string } = {
  1: 'Not hiring right now',
  2: 'Hiring, but for other positions',
  3: 'Hiring, and for positions I\'m interested in.',
};

type Props = {
  currentStatus?: TrafficLightValue | 0;
  handleStatusClick?: (status:TrafficLightValue | 0) => Promise<void>;
  statusLabels?: { [key in TrafficLightValue]: string };
  isReadOnly?: boolean;
};

export const CompanyTileTrafficLights = ({
  currentStatus = 0,
  handleStatusClick,
  statusLabels,
  isReadOnly = false,
}: Props) => {
  const [status, setStatus] = useState(currentStatus);
  const onStatusClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, newStatus: TrafficLightValue) => {
    if (!handleStatusClick) {
      return;
    }

    e.stopPropagation();
    const updatedStatus = newStatus === status ? 0 : newStatus;
    handleStatusClick(updatedStatus);
    // set new status optimistically
    setStatus(updatedStatus);
  };

  useEffect(() => {
    setStatus(currentStatus);
  }, [currentStatus]);

  return (
    <div className="tile-traffic-lights">
      {trafficLightValues.map((lightStatus) => (
        <div key={lightStatus} className="tile-traffic-lights__tooltip-container">
          <Tooltip
            label={statusLabels ? statusLabels[lightStatus] : defaultStatusLabels[lightStatus]}
            position="right"
            textAlignment="left"
          >
            <div
              className={`tile-traffic-lights__light ${(handleStatusClick && !isReadOnly) ? 'tile-traffic-lights__light--clickable' : ''}`}
              onClick={(e) => onStatusClick(e, lightStatus)}
            >
              <div className={`tile-traffic-lights__light-inner tile-traffic-lights__light-inner--${trafficLightColors[lightStatus]} ${
                status === lightStatus ? 'tile-traffic-lights__light-inner--active' : ''} ${
                status === 0 ? 'tile-traffic-lights__light-inner--disabled' : ''} ${
                (isReadOnly && !handleStatusClick) ? 'tile-traffic-lights__light-inner--readonly' : ''}
                }`}
              />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  );
};
