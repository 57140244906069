import { useContext } from 'react';
import { InfoModal } from '@careeros/coco';
import { useExtensionMessaging } from './use-extension-messaging';
import { ModalContext } from '@/components/modal/modal-provider';

export enum LoginStatus {
  NOT_LOGGED_IN = 'User is not logged into LinkedIn.',
  NO_OPEN_TAB = 'No LinkedIn tab open.',
  LOGGED_IN = 'User is logged into LinkedIn.',
}

export type RequestedView = 'inbox';

type Props = {
  requestedView?: RequestedView;
};

export const useCheckExtension = ({ requestedView }: Props = {}) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { checkLinkedInLogin } = useExtensionMessaging();

  const handleDownloadExtensionClick = () => {
    closeModal();
    window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');
  };

  const handleDownloadExtensionClickForInbox = () => {
    closeModal();
    window.open(import.meta.env.VITE_EXTENSION_LINK, '_blank');

    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('wait-extension-instalation', 'true');

    window.history.replaceState(null, '', `?${searchParams.toString()}`);
  };

  const renderModalBasedOnView = () => {
    switch (requestedView) {
      case 'inbox':
        openModal(
          <InfoModal
            icon="bi bi-puzzle"
            title="Install our Chrome Extension to send via LinkedIn"
            description={(
              <>
                This will allow you to send AI-generated LinkedIn messages directly from CareerOS.
                <br />
                Works with Chrome, Brave, Edge and others. Uninstall anytime.
              </>
            )}
            handleButtonClick={handleDownloadExtensionClickForInbox}
            buttonLabel="Install Extension"
            primaryButtonIconPosition="right"
            buttonIcon="bi bi-box-arrow-up-right"
            orientation="horizontal"
            secondaryButtonLabel="Cancel"
            handleSecondaryButtonClick={closeModal}
          />,
        );
        break;
      default:
        openModal(
          <InfoModal
            icon="bi bi-puzzle"
            title="Looks like you haven’t downloaded our Chrome Extension"
            description="You’ll have the power to save jobs, contacts and new companies from LinkedIn and other job boards to your Overview Board."
            handleButtonClick={handleDownloadExtensionClick}
            buttonLabel="Download Extension"
            orientation="horizontal"
          />,
        );
    }
  };

  const checkUserExtension = async () => {
    const linkedInLoginStatus: LoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      renderModalBasedOnView();
      return false;
    }

    return linkedInLoginStatus;
  };

  return {
    checkUserExtension,
  };
};
