import { InfoModal } from '@careeros/coco';
import './posting-score-modal.scss';

type Props = {
  closeModal: () => void;
};

export const PostingScoreExplainerModal = ({ closeModal }: Props) => (
  <InfoModal
    orientation="horizontal"
    icon="bi bi-lamp"
    title="Let’s Chat about the &quot;P&quot; in LAMP"
    description={(
      <div className="posting-score-modal">
        <div className="posting-score-modal__text">
          <b>P</b>
          {' '}
          is for
          {' '}
          <b>P</b>
          osting. This indicates whether this company is generally hiring people like you for positions that you are interested in.
          <br />
          Check the CareerOS job board or other online job boards, or use a simple Google search.
        </div>

        <div className="posting-score-modal__options-list">
          <div className="posting-score-modal__option">
            <div className="posting-score-modal__light posting-score-modal__light--red" />
            <span>Not hiring at the moment</span>
          </div>
          <div className="posting-score-modal__option">
            <div className="posting-score-modal__light posting-score-modal__light--yellow" />
            <span>Hiring, but not for positions I am interested in</span>
          </div>
          <div className="posting-score-modal__option">
            <div className="posting-score-modal__light posting-score-modal__light--green" />
            <span>Hiring for positions I am interested in</span>
          </div>
        </div>
      </div>
    )}
    buttonLabel="Got it!"
    handleButtonClick={closeModal}
  />
);
