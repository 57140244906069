import './job-tile-body.scss';

import { AvatarStack, Button, IconButton } from '@careeros/coco';
import { AvatarStackItem } from '@careeros/coco/dist/src/components/AvatarStack/AvatarStack';
import { useMemo } from 'react';
import { DropdownMenuButton } from '../dropdown-menu-button/dropdown-menu-button';
import { getApplicationActionsByStatus, getApplicationAIActionsByStatus } from '@/domains/core/tracker-data/application-actions';
import { ApiStatus } from '@/domains/core/company/types';
import { trackerTabs } from '@/domains/core/tracker-data';
import { KanbanTileDeadline } from '@/domains/core/job/components/kanban-tile-deadline/kanban-tile-deadline';
import { ContactInfo } from '@/domains/core/company/types/tile-v2';

type Props = {
  // jobNumber: number;
  jobID?: string;
  tileStatus: ApiStatus;
  handleArchive: () => void;
  handleMoveToRejected: () => void;
  handleOpenTrackerTab: (tab: (typeof trackerTabs)[number], additionalParams?: { [key: string]: string }) => void;
  navigateToPage: (path: string) => void;
  contacts?: ContactInfo[];
  jobDeadline?: string | null;
  applicationDate?: string | null;
  hasBaseResume?: boolean;
};

export const JobTileBody = ({
  // jobNumber,
  jobID,
  tileStatus,
  handleArchive: handleArchiveCompany,
  handleOpenTrackerTab,
  handleMoveToRejected,
  navigateToPage,
  contacts = [],
  jobDeadline = null,
  applicationDate = null,
  hasBaseResume = false,
}: Props) => {
  const actions = useMemo(() => getApplicationActionsByStatus(tileStatus, handleOpenTrackerTab, handleArchiveCompany, handleMoveToRejected, 'job'), [tileStatus]);
  const AIActions = useMemo(() => getApplicationAIActionsByStatus(tileStatus, handleOpenTrackerTab, navigateToPage, 'job', hasBaseResume, jobID), [tileStatus, hasBaseResume, jobID]);
  const parsedContacts = useMemo<AvatarStackItem[]>(() => contacts?.map((contact) => ({
    id: contact.id,
    name: `${contact.first_name} ${contact.last_name}`,
    avatar: contact.image_url || '',
  })) || [], [contacts]);
  const handleJobsCountClick = () => {
    // handleOpenTrackerTab('Jobs');
  };

  return (
    <div className="company-tile-body">
      <div className="company-tile-body__left">
        {AIActions.length > 0 && (
          <DropdownMenuButton
            dropdownOptions={AIActions}
            dropdownHorizontalAlignment="left"
            tooltipLabel="AI Tools"
            scrollContainerQuery="#kanban-board"
          >
            <Button
              icon="bi bi-stars"
              mode="special-subtle"
              size="small"
              label="AI"
            />
          </DropdownMenuButton>
        )}
        <div className="company-tile-body__contacts">
          <AvatarStack
            personList={parsedContacts}
            listTooltip={`${parsedContacts.length} Saved Contacts`}
            emptyStateTooltip="No Saved Contacts"
            handleClick={() => handleOpenTrackerTab('Contacts')}
          />
        </div>
        <div className="company-tile-body__appliaction-deadline">
          <KanbanTileDeadline
            deadline={jobDeadline}
            isApplied={!!applicationDate}
            applicationDate={applicationDate}
            handleClick={handleJobsCountClick}
          />
        </div>
      </div>
      <DropdownMenuButton
        dropdownOptions={actions}
        tooltipLabel="Actions"
        scrollContainerQuery="#kanban-board"
      >
        <IconButton
          icon="bi bi-three-dots"
          mode="rounded"
          size="small"
          outlined
        />
      </DropdownMenuButton>
    </div>
  );
};
