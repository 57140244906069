import { useContext, useEffect } from 'react';
import { Button } from '@careeros/coco';

import { Loader } from '@/components/loader/loader';
import { useSelf, useSelfUniversity } from '@/services/queries/user';

import './styles/dashboard.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { isMobileDevice } from '@/services/helpers/responsive';

function PeerVotePage() {
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();
  const { addToast }: AddToastType = useContext(ToastContext);

  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href);
    addToast({
      type: 'success',
      message: 'URL has been copied',
      additionalMessage: 'Share the ranking with your class!',
    });
  };

  useEffect(() => {
    // Set the real viewport height
    const setViewportHeight = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    // Initial set
    setViewportHeight();

    // Update on resize and orientation change
    window.addEventListener('resize', setViewportHeight);
    window.addEventListener('orientationchange', setViewportHeight);

    return () => {
      window.removeEventListener('resize', setViewportHeight);
      window.removeEventListener('orientationchange', setViewportHeight);
    };
  }, []);

  useEffect(() => {
    // Load the Fillout script
    const script = document.createElement('script');
    script.src = 'https://server.fillout.com/embed/v1/';
    script.async = true;

    script.onload = () => {
      // The script has loaded and is ready to use
      if (window.Fillout) {
        window.Fillout.initializeEmbeds();
      }
    };

    document.body.appendChild(script);

    // Cleanup
    return () => {
      document.body.removeChild(script);
    };
  }, [isSelfLoading, isUniversityLoading]); // Empty dependency array means this runs once on mount

  if (isSelfLoading || isUniversityLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="student-dashboard student-dashboard--form-for-peer-vote">
      <div className={isMobileDevice ? 'student-dashboard__share-button-container student-dashboard__share-button-container--mobile' : 'student-dashboard__share-button-container'}>
        <Button handleClick={handleShareClick} outlined mode="blue" size={isMobileDevice ? 'medium' : 'large'} label={isMobileDevice ? 'Share' : 'Share with Class'} icon="bi bi-box-arrow-up" />
      </div>
      <div className="career-os-grid">
        <div className="width-24/24">
          <div className="form-wrapper form-wrapper--peer-vote">
            <div
              style={{
                width: '100%',
                height: isMobileDevice ? 'calc(var(--vh, 1vh) * 100 - 60px)' : 'calc(var(--vh, 1vh) * 100 - 50px)',
              }}
              data-fillout-id="rKx92aPA8Cus"
              data-email={self?.email}
              data-cohort_name={university?.cohort}
              data-role={self?.role}
              data-cohort={university?.cohort_id}
              data-fillout-embed-type="standard"
              data-fillout-inherit-parameters
              data-fillout-domain="form.careeros.com"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

// Add TypeScript declaration for the Fillout global
declare global {
  interface Window {
    Fillout?: {
      initializeEmbeds: () => void;
    };
  }
}

export default CustomWithAuthenticationRequired(PeerVotePage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
