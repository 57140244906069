import DOMPurify from 'dompurify';
import { IconButton, Tooltip } from '@careeros/coco';
import { Tag, TagType } from '@/components/tag/tag';
import './OB-tile-preview.scss';
import { TileIndustry } from '../tile-industry/tile-industry';
import { industryIconFiles } from '@/domains/core/company/constants/industry';

export type OBTilePreviewProps = {
  tags: TagType[];
  title: string;
  location?: string;
  description: string;
  date?: string | null;
  expandButtonLabel: string;
  onExpandButtonClick: () => void;
  industry: string;
  companyName?: string;
  onCompanyNameClick?: () => void;
};

/**
 * Renders a tile preview component for a job or company tile.
 *
 * @component
 * @example
 * ```tsx
 * <OBTilePreview
 *   tags={[]}
 *   title="Job Title"
 *   location="Job Location"
 *   description="Job Description"
 *   date="01/01/2025"
 *   expandButtonLabel="Expand"
 *   onExpandButtonClick={() => {}}
 *   companyName="Company Name"
 *   industry="industry"
 * />
 * ```
 */

export const OBTilePreview = ({
  tags,
  title,
  location,
  description,
  date = null,
  expandButtonLabel,
  onExpandButtonClick,
  industry,
  companyName,
  onCompanyNameClick,
}: OBTilePreviewProps) => (
  <div className="OB-tile-preview">
    <div className="OB-tile-preview__header">
      <div className="OB-tile-preview__header-top">
        <span className="OB-tile-preview__title">
          {title}
        </span>
        <div className="OB-tile-preview__header-right">
          <Tooltip label={expandButtonLabel} position="top" withArrow>
            <IconButton
              icon="bi bi-arrows-angle-expand"
              mode="rounded"
              size="small"
              outlined
              handleClick={onExpandButtonClick}
            />
          </Tooltip>
        </div>
      </div>

      <div className="OB-tile-preview__header-bottom">
        {industryIconFiles[industry] && (
        <div className="OB-tile-preview__industry">
          <TileIndustry icon={industryIconFiles[industry]} label={industry} />
        </div>
        )}

        {location && (
        <div className="OB-tile-preview__location">
          <i className="OB-tile-preview__location-icon bi bi-geo-alt-fill" />
          <span className="OB-tile-preview__location-name">
            {location}
          </span>
        </div>
        )}

        {companyName && (
          <span onClick={onCompanyNameClick} className="OB-tile-preview__company-name">{`@ ${companyName}`}</span>
        )}
      </div>
    </div>

    <div className="OB-tile-preview__separator" />

    <div className="OB-tile-preview__body">
      {date && (
        <>
          <div className="OB-tile-preview__date" onClick={onExpandButtonClick}>
            <i className="OB-tile-preview__date-icon bi bi-send" />
            <span className="OB-tile-preview__date-name">
              {date}
            </span>
          </div>
          <div className="OB-tile-preview__separator" />
        </>
      )}

      <div className="OB-tile-preview__description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />

      {tags.length > 0 && (
        <div className="OB-tile-preview__tags">
          {tags.map(tag => (
            <Tag key={tag.id} label={tag.label} color={tag.color} image={tag.image} />
          ))}
        </div>
      )}
    </div>
  </div>
);
