/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, StyleSheet, Font, Image,
  Link,

} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';
import { formatDateYearOnly } from '../helpers/formate-date-templates';
import location from '@/assets/images/resume-builder/MBS/geo-alt-fill.png';
import phone from '@/assets/images/resume-builder/MBS/phone.png';
import email from '@/assets/images/resume-builder/MBS/envelope-open-fill.png';
import linkedin from '@/assets/images/resume-builder/MBS/linkedin.png';
import LeagueSpartan from '@/assets/fonts/resume-builder/league-spartan/LeagueSpartan-Regular.ttf';
import LeagueSpartanBold from '@/assets/fonts/resume-builder/league-spartan/LeagueSpartan-Bold.ttf';

import {
  extractLinkedInUsername,
  renderLongText,
  computeSkillsStyle,
  renderWorkHighlightedText,
} from '../helpers/utils';

Font.register({ family: 'LeagueSpartan', src: LeagueSpartan, fonts: [{ src: LeagueSpartan, fontWeight: 400 }, { src: LeagueSpartanBold, fontWeight: 700 }] });

Font.registerHyphenationCallback(word => ([word]));

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff',
    fontFamily: 'LeagueSpartan',
  },
  leftSection: {
    width: '30%',
    backgroundColor: '#081941',
    color: 'white',
    padding: '20px 10px 20px 20px',
    minHeight: '100%',
  },
  mainContent: {
    width: '70%',
    padding: '20px 30px',
    color: '#081941',
  },
  profileImage: {
    width: '120px',
    height: '120px',
    borderRadius: '60px',
    marginBottom: '20px',
    alignSelf: 'center',
  },
  name: {
    fontSize: 34,
    color: '#081941',
    fontWeight: 'bold',
    marginBottom: '5px',
    letterSpacing: '2px',
  },
  subtitle: {
    fontSize: 16,
    color: '#081941',
    marginBottom: '10px',
  },
  summary: {
    fontSize: 10,
    color: '#081941',
    marginBottom: '10px',
    textAlign: 'justify',
  },
  sectionTitle: {
    fontSize: 20,
    color: 'white',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  mainSectionTitle: {
    fontSize: 20,
    color: '#081941',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  contactItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 10,
    marginBottom: '8px',
    color: 'white',
    maxWidth: '90%',
  },
  educationItem: {
    fontSize: 10,
  },
  experienceItem: {
  },
  timeline: {
    flexDirection: 'row',
    marginBottom: '15px',
  },
  timelineDate: {
    width: '5%',
    fontSize: 10,
    color: '#081941',
    position: 'relative',
  },
  timelineDot: {
    width: 7,
    height: 7,
    borderRadius: 4,
    backgroundColor: '#081941',
    position: 'absolute',
    left: '50px',
    transform: 'translateX(-50%)',
  },
  timelineLine: {
    width: 1,
    backgroundColor: '#081941',
    position: 'absolute',
    left: '53px',
    transform: 'translateX(-50%)',
    top: 7,
    bottom: -20,
  },
  timelineContent: {
    width: '75%',
  },
  companyName: {
    fontSize: 12,
    color: '#081941',
  },
  jobTitle: {
    fontSize: 13,
    fontWeight: 'bold',
    marginBottom: '5px',
    color: '#081941',
  },
  bulletPoint: {
    fontSize: 10,
    marginBottom: '3px',
    flexDirection: 'row',
    marginLeft: '10px',
    color: '#081941',
  },
  bullet: {
    width: '10px',
  },
  bulletText: {
    flex: 1,
  },
  expertiseItem: {
    fontSize: 10,
    color: 'white',
  },
  languageItem: {
    fontSize: 10,
    color: 'white',
    marginBottom: '5px',
  },
  profilePictureContainer: {
    width: '120px',
    height: '120px',
    borderRadius: '75px',
    overflow: 'hidden',
    alignSelf: 'center',
    marginBottom: '20px',
  },
  profilePicture: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    borderRadius: '75px',
  },
  contactIcon: {
    width: 14,
    height: 14,
    marginRight: 8,
    objectFit: 'contain',
  },
  sectionLine: {
    width: '120%',
    height: 1,
    backgroundColor: 'white',
    marginBottom: '10px',
  },
  mainSectionLine: {
    width: '100%',
    height: 1,
    backgroundColor: '#081941',
    marginBottom: '10px',
  },
  timelineDateText: {
    fontSize: 10,
    color: '#081941',
    fontWeight: 'bold',
  },
  workDescription: {
    fontSize: 10,
    color: '#081941',
    marginBottom: '4px',
  },
  link: {
    color: 'white',
    textDecoration: 'none',
  },
  iconContainer: {
    width: 22,
    alignItems: 'center',
    justifyContent: 'center',
  },
  highlights: {
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: 10,
    marginLeft: 5,
    marginBottom: 4,
    lineHeight: 1.1,
  },
  highlightText: {
    fontSize: 10,
    color: '#081941',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null,
};

const MBSMBATemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => (
  <Document title={title}>
    <Page size="A4" style={styles.page}>
      <View style={{ flexDirection: 'row' }}>
        {/* Left Section */}
        <View style={styles.leftSection}>
          {resume.basics.profile_picture_url && (
            <View style={styles.profilePictureContainer}>
              <View style={styles.profilePicture}>
                <Image
                  src={resume.basics.profile_picture_url}
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
              </View>
            </View>
          )}

          <View style={{
            marginBottom: '10px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start',
          }}
          >
            <Text style={styles.sectionTitle}>Contact</Text>
            <View style={styles.sectionLine} />
            {resume.basics.phone_numbers?.[0] && (
            <View style={styles.contactItem}>
              <View style={[styles.iconContainer, { width: 19 }]}>
                <Image src={phone} style={styles.contactIcon} />
              </View>
              <Text>
                {renderLongText(resume.basics.phone_numbers?.[0])}
              </Text>
            </View>
            )}
            {resume.basics?.emails[0] && (
            <View style={styles.contactItem}>
              <View style={styles.iconContainer}>
                <Image src={email} style={styles.contactIcon} />
              </View>
              <Text>
                {renderLongText(resume.basics?.emails[0])}
              </Text>
            </View>
            )}
            {(resume.basics.location?.address || resume.basics.location?.city) && (
            <View style={styles.contactItem}>
              <View style={[styles.iconContainer, { width: 19 }]}>
                <Image src={location} style={[styles.contactIcon]} />
              </View>
              <Text>
                {renderLongText(resume.basics.location?.address)}
                {resume.basics.location?.address && resume.basics.location?.city ? renderLongText(', ') : ''}
                {resume.basics.location?.city ? renderLongText(`${resume.basics.location?.city}`) : ''}
              </Text>
            </View>
            )}
            {resume.basics.url && (
            <View style={styles.contactItem}>
              <View style={styles.iconContainer}>
                <Link src={resume.basics.url} style={styles.link}>
                  <Image src={linkedin} style={styles.contactIcon} />
                </Link>
              </View>
              <Link src={resume.basics.url} style={styles.link}>
                <Text>
                  {resume.basics.url && renderLongText(extractLinkedInUsername(resume.basics.url))}
                </Text>
              </Link>
            </View>
            )}
          </View>

          <Text style={styles.sectionTitle}>Education</Text>
          <View style={styles.sectionLine} />

          {resume.education?.map((edu) => (
            <View key={edu.institution} style={[styles.educationItem, { marginBottom: '10px' }]}>
              <Text style={styles.educationItem}>
                {edu.endDate === 'Present'
                  ? `Since ${formatDateYearOnly(edu.startDate)}`
                  : `${formatDateYearOnly(edu.startDate)} - ${formatDateYearOnly(edu.endDate)}`}
              </Text>
              <Text style={[styles.educationItem, { fontWeight: 'bold' }]}>
                <Text>
                  {edu.area}
                </Text>
              </Text>
              <Text style={styles.educationItem}>
                {`${edu.institution}, ${edu.location}`}
              </Text>

            </View>
          ))}

          <Text style={styles.sectionTitle}>Expertise</Text>
          <View style={styles.sectionLine} />

          <View style={{
            display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 5, marginBottom: '10px',
          }}
          >
            {resume.skills?.map((skill) => (
              <View style={{
                display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2,
              }}
              >
                <View>
                  <Text style={{ fontSize: 10 }}>
                    •
                  </Text>
                </View>
                <View>
                  <Text key={skill.name} style={[computeSkillsStyle(highlightedWords, activeField, styles), styles.expertiseItem]}>
                    {skill.name}
                    {skill.level && ` (${skill.level})`}
                  </Text>
                </View>
              </View>
            ))}
          </View>

          <Text style={styles.sectionTitle}>Languages</Text>
          <View style={styles.sectionLine} />

          {resume.languages?.map((lang) => (
            <Text key={lang.language} style={styles.languageItem}>
              •
              {' '}
              {`${lang.language} - ${lang.fluency}`}
            </Text>
          ))}
        </View>

        {/* Main Content */}
        <View style={styles.mainContent}>
          <Text style={styles.name}>{resume.basics.name}</Text>
          <Text style={styles.subtitle}>{resume.basics.label}</Text>
          <Text style={styles.summary}>{resume.basics.summary}</Text>

          <Text style={styles.mainSectionTitle}>Experience</Text>
          <View style={styles.mainSectionLine} />

          {resume.work?.map((work, index) => (
            <View key={`${work.company} - ${work.position}`} style={styles.experienceItem}>
              <View style={styles.timeline}>
                <View style={styles.timelineDate}>
                  <View style={styles.timelineDot} />
                  <View style={styles.timelineLine} />
                </View>
                <View style={styles.timelineContent}>
                  <Text style={styles.timelineDateText}>
                    {work.endDate === 'Present'
                      ? `Since ${formatDateYearOnly(work.startDate)}`
                      : `${formatDateYearOnly(work.startDate)} - ${formatDateYearOnly(work.endDate)}`}
                  </Text>
                  <Text style={styles.companyName}>{`${work.name} | ${work.location}`}</Text>
                  <Text style={styles.jobTitle}>{work.position}</Text>
                  <Text style={styles.workDescription}>Role included:</Text>
                  {work.highlights && work.highlights.length > 0 && (
                    <View style={styles.highlights}>
                      {work.highlights.map((highlight, highlightIndex) => {
                        const cleanedHighlight = highlight.replace(/^•\s/, '');

                        return (
                          <View key={`${highlight}-${highlightIndex}`} style={styles.bulletPoint} wrap={false}>
                            <Text style={styles.bullet}>•</Text>
                            <View style={styles.highlightText}>
                              {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                            </View>
                          </View>
                        );
                      })}
                    </View>
                  )}
                </View>
              </View>
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

export default MBSMBATemplate;
