export const APPROACHING_DEADLINE_DAYS = 7;
export const TODAY = 0;
export const APPLIED_OVERDUE_DAYS = -29;

export const getLabelText = (dayDelta: number | null, isApplied: boolean) => {
  if (dayDelta === null) {
    return '?';
  }
  if (isApplied && dayDelta === TODAY) {
    return 'Today';
  }

  if (isApplied && dayDelta < -99) {
    return '99+ days';
  }

  if (isApplied && dayDelta < TODAY) {
    return `${Math.abs(dayDelta)} days`;
  }

  if (dayDelta === TODAY) {
    return 'Today!';
  }

  if (dayDelta < TODAY) {
    return 'Overdue';
  }

  return `${Math.abs(dayDelta)} days`;
};

export const getTooltipLabelText = (dayDelta: number | null, isApplied: boolean) => {
  if (dayDelta === null) {
    return 'No application deadline set.';
  }

  if (isApplied && dayDelta === TODAY) {
    return 'Applied today ☑️';
  }

  if (isApplied && dayDelta < TODAY && dayDelta > -30) {
    return `Applied ${Math.abs(dayDelta)} days ago ☑️`;
  }

  if (isApplied && dayDelta < APPLIED_OVERDUE_DAYS && dayDelta > -100) {
    return `Applied ${Math.abs(dayDelta)} days ago ⌛️`;
  }

  if (isApplied && +dayDelta < -99) {
    return 'Applied more than 99 days ago 😔';
  }

  if (dayDelta === TODAY) {
    return 'Deadline today';
  }

  if (dayDelta < TODAY) {
    return `Deadline overdue by ${Math.abs(dayDelta)} days 😕`;
  }

  if (dayDelta <= APPROACHING_DEADLINE_DAYS) {
    return 'Deadline approaching!';
  }

  return `Application deadline in ${Math.abs(dayDelta)} days.`;
};
