import { useMemo } from 'react';
import { Tooltip } from '@careeros/coco';
import './kanban-tile-deadline.scss';
import { getDateDeltaFromNowInDays } from '@/services/helpers/date';
import {
  APPLIED_OVERDUE_DAYS, APPROACHING_DEADLINE_DAYS, getLabelText, getTooltipLabelText, TODAY,
} from './date-parser';

const DAYS_SINCE_APPLIED_BASE = -7;

type Props = {
  deadline: string | null;
  applicationDate: string | null;
  isApplied?: boolean;
  handleClick: () => void;
};

export const KanbanTileDeadline = ({
  deadline,
  applicationDate,
  isApplied = false,
  handleClick,
}: Props) => {
  const dayDelta = useMemo(() => {
    if (isApplied && applicationDate) {
      return getDateDeltaFromNowInDays(applicationDate);
    }

    if (deadline) {
      return getDateDeltaFromNowInDays(deadline);
    }

    return null;
  }, [deadline, isApplied, applicationDate]);
  const isDangerous = useMemo<boolean>(() => {
    if (dayDelta === null) {
      return false;
    }

    if (isApplied && dayDelta < APPLIED_OVERDUE_DAYS && dayDelta < DAYS_SINCE_APPLIED_BASE) {
      return true;
    }

    return dayDelta <= APPROACHING_DEADLINE_DAYS && !isApplied;
  }, [isApplied, dayDelta]);

  const isTextBold = useMemo<boolean>(() => {
    if (dayDelta === null) {
      return false;
    }
    if (isApplied) {
      return false;
    }

    return dayDelta <= TODAY;
  }, [isApplied, dayDelta]);
  const isHighlighted = useMemo<boolean>(() => {
    if (dayDelta === null) {
      return false;
    }
    if (isApplied) {
      return false;
    }

    return dayDelta > APPROACHING_DEADLINE_DAYS;
  }, [isApplied, dayDelta]);
  const labelText = useMemo<string>(() => getLabelText(dayDelta, isApplied), [dayDelta, isApplied]);
  const tooltipLabelText = useMemo<string>(() => getTooltipLabelText(dayDelta, isApplied), [dayDelta, isApplied, deadline]);
  const icon = useMemo<string>(() => {
    if (isApplied) {
      return 'bi bi-check-circle';
    }

    if (!deadline || dayDelta === null) {
      return 'bi bi-send-plus';
    }

    if (dayDelta < TODAY) {
      return 'bi bi-send-slash';
    }

    return 'bi bi-send-exclamation';
  }, [isApplied, deadline, dayDelta]);
  const handleElementClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    handleClick();
  };

  return (
    <div className="kanban-tile-deadline" onClick={handleElementClick}>
      <Tooltip label={tooltipLabelText} position="top" withArrow>
        <div className="kanban-tile-deadline__wrapper">
          <div className="kanban-tile-deadline__icon-wrapper">
            <i className={`kanban-tile-deadline__icon kanban-tile-deadline__icon--hover ${icon}-fill`} />
            <i className={`kanban-tile-deadline__icon ${icon}`} />
          </div>
          <div
            className={[
              'kanban-tile-deadline__date',
              !deadline && !applicationDate ? 'kanban-tile-deadline__date--grey' : '',
              isDangerous ? 'kanban-tile-deadline__date--dangerous' : '',
              isTextBold ? 'kanban-tile-deadline__date--bold' : '',
              isHighlighted ? 'kanban-tile-deadline__date--highlighted' : '',
            ].join(' ')}
          >
            {labelText}
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
