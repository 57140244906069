import {
  useContext, useEffect, useRef, useState,
} from 'react';
import ReactQuill from 'react-quill';
import { Button, InfoModal, Tooltip } from '@careeros/coco';
import { EditorToolbar } from '@/domains/core/contact/components/editor-toolbar/editor-toolbar';
import '@/services/helpers/quill-config';

import './note-input.scss';
import { ModalContext } from '@/components/modal/modal-provider';
import { stripHTML } from '@/services/helpers/processHtml';

type Props = {
  id?: string;
  title?: string;
  content?: string;
  parentID: string;
  handleSave: (title: string, content: string, successCallback: () => void) => void;
  saveSilently: (title: string, content: string) => void;
  handleCancel?: () => void;
};

export const NoteInput = ({
  title,
  content,
  handleSave,
  handleCancel,
  id,
  parentID,
  saveSilently,
}: Props) => {
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [warningMessage, setWarningMessage] = useState<string>();
  const [newTitle, setNewTitle] = useState(title || '');
  const [newContent, setNewContent] = useState(content || '');
  const [isFormSubmissionDisabled, setIsFormSubmissionDisabled] = useState(true);
  const [isCancelable, setIsCancelable] = useState(!!newTitle.trim() || !!newContent.trim());
  const [saveButtonTooltip, setSaveButtonTooltip] = useState<string>();
  const noteInLocalStorageName = `pendingNote-${parentID}-${id || 'new'}`;

  const handleCancelling = () => {
    closeModal();
    setNewContent(content || '');
    setNewTitle(title || '');
    if (handleCancel) {
      handleCancel();
    }
    window.localStorage.removeItem(noteInLocalStorageName);
  };

  const handleCancelButtonClick = (note: string, noteTitle: string) => {
    if (stripHTML(note) || noteTitle) {
      openModal(
        <InfoModal
          title="Discard Changes"
          buttonLabel="Confirm"
          secondaryButtonLabel="Cancel"
          handleButtonClick={handleCancelling}
          handleSecondaryButtonClick={closeModal}
          description="Are you sure you want to discard all the changes you made to this note? "
          orientation="horizontal"
        />,
      );
    } else {
      handleCancelling();
    }
  };

  const checkNoteLength = () => {
    const noteLenght = reactQuillRef.current && reactQuillRef.current.getEditor().getText().trim().length;

    if (noteLenght && noteLenght > 10000) {
      setIsFormSubmissionDisabled(true);
      setWarningMessage('You have exceeded the 10,000 character limit for this note. Please reduce in order to save.');

      return false;
    }

    if (newTitle.trim().length > 255) {
      setIsFormSubmissionDisabled(true);
      setWarningMessage('You have exceeded the 255 character limit for the title. Please reduce in order to save.');

      return false;
    }

    setWarningMessage(undefined);
    setIsFormSubmissionDisabled(noteLenght === 0 && newTitle.trim().length === 0);

    return true;
  };

  const handleSavingNote = async () => {
    const successCallback = () => {
      setNewContent('');
      setNewTitle('');
    };

    if (!checkNoteLength()) {
      return;
    }

    handleSave(newTitle, newContent, successCallback);
    window.localStorage.removeItem(noteInLocalStorageName);
  };

  const validateNoteFields = () => {
    const isMessageEmpty = reactQuillRef.current && reactQuillRef.current.getEditor().getText().trim().length === 0;

    return {
      title: newTitle.trim().length > 0,
      content: !isMessageEmpty,
    };
  };

  const updatePendingNoteInStorage = () => {
    if (validateNoteFields()) {
      window.localStorage.setItem(noteInLocalStorageName, JSON.stringify({ title: newTitle, content: newContent }));
    } else if (window.localStorage.getItem(noteInLocalStorageName)) {
      window.localStorage.removeItem(noteInLocalStorageName);
    }
  };

  const getSaveButtonTooltip = (isTitleValid: boolean, isContentValid: boolean) => {
    if (!isTitleValid && !isContentValid) {
      setSaveButtonTooltip('Add note content and title to save the note');
    } else if (!isTitleValid) {
      setSaveButtonTooltip('Add a note title to save the note');
    } else if (!isContentValid) {
      setSaveButtonTooltip('Add note content to save the note');
    } else {
      setSaveButtonTooltip(undefined);
    }
  };

  useEffect(() => {
    const validatedFields = validateNoteFields();
    setIsCancelable(validatedFields.title || validatedFields.content);
    setIsFormSubmissionDisabled(!validatedFields.title || !validatedFields.content);
    getSaveButtonTooltip(validatedFields.title, validatedFields.content);

    if (warningMessage) {
      checkNoteLength();
    }

    updatePendingNoteInStorage();
  }, [newTitle, newContent]);

  useEffect(() => () => {
    const pendingNoteFromLocalStorage = window.localStorage.getItem(noteInLocalStorageName);
    const pendingNote = pendingNoteFromLocalStorage && JSON.parse(pendingNoteFromLocalStorage);

    if (!pendingNote || !pendingNote.title?.trim().length || !pendingNote.content?.trim().length || pendingNote.content?.length > 10000) {
      return;
    }

    saveSilently(pendingNote.title, pendingNote.content);
  }, []);

  return (
    <div className="note-input-box">
      <div className={`note-input-box__container ${warningMessage ? 'note-input-box__container--dangerous' : ''}`}>
        <input
          aria-label="Note Title"
          type="text"
          className="note-input-box__input"
          placeholder="Note Title"
          value={newTitle}
          onChange={(e) => setNewTitle(e.target.value)}
        />
        <div className="note-input-box__separator" />
        <div className="note-input-box__text-field">
          <EditorToolbar id={id} />
          <ReactQuill
            ref={reactQuillRef}
            modules={{ toolbar: { container: id ? `#toolbar-${id}` : '#toolbar' } }}
            theme="snow"
            value={newContent}
            placeholder="Write your note..."
            onChange={setNewContent}
            style={{
              border: 'none',
              padding: '0',
              height: '180px',
            }}
          />
        </div>
      </div>
      <div className="note-input-box__footer">
        {warningMessage && <div className="note-input-box__warning">{warningMessage}</div>}
        <div className="note-input-box__buttons">
          <Button
            handleClick={() => handleCancelButtonClick(newContent, newTitle)}
            label="Cancel"
            mode="primary"
            size="small"
            outlined
            disabled={!isCancelable && !handleCancel}
          />
          <div className="note-input-box__button-container">
            <Tooltip
              label={saveButtonTooltip || 'Save the note'}
              position="top"
              disableHoverListener={!isFormSubmissionDisabled || !saveButtonTooltip}
            >
              <Button
                onClick={handleSavingNote}
                mode="primary"
                label="Save"
                size="small"
                icon="bi bi-floppy"
                disabled={isFormSubmissionDisabled}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
