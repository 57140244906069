import {
  useEffect, useMemo, useRef, useState,
} from 'react';

import './email-recipient.scss';
import { DropdownList } from '@careeros/coco';

type Props = {
  selectedEmails: string[];
  setSelectedEmails: (emails: string[]) => void;
  emailAddresses: string[];
};

export const EmailRecipient = ({ selectedEmails, emailAddresses, setSelectedEmails }: Props) => {
  const [filteredSelectedEmails, setFilteredSelectedEmails] = useState(selectedEmails.filter(email => !!email));
  const [showUnselectedEmails, setShowUnselectedMessages] = useState(false);
  const emailListRef = useRef<HTMLDivElement>(null);
  const unselectedEmails = useMemo(() => emailAddresses.filter(email => email && !selectedEmails.includes(email)), [selectedEmails, emailAddresses.length]);

  const selectEmailFromList = (email: string) => {
    setSelectedEmails([email]);
    setShowUnselectedMessages(false);
    // ev.stopPropagation();
  };

  const onEmailInputFocusHandler = () => {
    if (unselectedEmails.length) {
      setShowUnselectedMessages(!showUnselectedEmails);
    }
  };

  const unselectEmail = (email: string) => setSelectedEmails(selectedEmails.filter(eml => eml !== email));

  useEffect(() => {
    setFilteredSelectedEmails(selectedEmails.filter(email => !!email));
  }, [selectedEmails]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (emailListRef.current && !emailListRef.current.contains(event.target)) {
        setShowUnselectedMessages(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={emailListRef} className="email-recipient" onClick={onEmailInputFocusHandler}>
      {filteredSelectedEmails.map(email => (
        <div key={email} className="email-recipient__email">
          <span>{email}</span>
          <button
            type="button"
            className="email-recipient__remove-button"
            onClick={() => unselectEmail(email)}
          >
            <i className="bi bi-x" />
          </button>
        </div>
      ))}
      {filteredSelectedEmails.length === 0 && (
        <span className="email-recipient__placeholder">
          Select email
        </span>
      )}
      {showUnselectedEmails && (
        <div className="email-recipient__list">
          <DropdownList
            options={unselectedEmails.map(email => ({ label: email, onClick: () => selectEmailFromList(email) }))}
            handleClose={() => setShowUnselectedMessages(false)}
            parentRef={emailListRef}
          />
        </div>
      )}
    </div>
  );
};
