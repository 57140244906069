import { useEffect, useRef } from 'react';
import { useHits } from 'react-instantsearch';
import { BaseHit } from 'instantsearch.js';
import { JobFeedJob, JobTagType } from '@/domains/core/job/types/job';

type SearchResultsProps = {
  setJobs: (jobs: JobFeedJob[]) => void;
  query: string;
  trackEvent: (event: string) => void;
  setTotalJobsCount: (count: number) => void;
  setCurrentActiveId: (id: string) => void;
};

export const SearchResults = ({
  setJobs, query, trackEvent, setCurrentActiveId, setTotalJobsCount,
}: SearchResultsProps) => {
  const { results } = useHits();
  const prevResultsRef = useRef<string>();

  const processResults = (hits: BaseHit[]) => {
    const transformedJobs: JobFeedJob[] = hits.map(hit => {
      const tags: JobTagType[] = [];
      // Add contract tag
      if (hit.job_type) {
        tags.push({
          tag_type: 'contract',
          tag_content: hit.job_type,
        });
      }

      // Add workMode tag
      if (hit.remote) {
        tags.push({
          tag_type: 'workMode',
          tag_content: hit.remote,
        });
      }

      // Add location tag
      if (hit.location) {
        tags.push({
          tag_type: 'location',
          tag_content: hit.location,
        });
      }

      return {
        id: hit.objectID,
        title: hit.title,
        company_name: hit.company_name,
        location: hit.location,
        description: hit.description,
        linkedin_url: hit.linkedin_url,
        linkedin_logo_url: hit.company_logo,
        posted_on: hit.posted_on,
        deadline: hit.deadline,
        rolling_date: hit.rolling_date,
        company_id: hit.company_id,
        team_id: hit.team_id || null,
        company_partner_type: hit.company_partner_type as any || null,
        tags,
        saved: false,
        applied: false,
        source: 'Algolia',
        created_at: hit.posted_on,
      };
    });
    trackEvent(`Algolia Jobs Search for query: ${query}`);
    setJobs(transformedJobs);
    setTotalJobsCount(transformedJobs.length);
    if (transformedJobs.length > 0) {
      setCurrentActiveId(transformedJobs[0].id);
    }
  };

  useEffect(() => {
    if (results?.hits) {
      const resultsKey = JSON.stringify(results.hits);
      if (prevResultsRef.current !== resultsKey) {
        prevResultsRef.current = resultsKey;
        processResults(results.hits);
      }
    }
  }, [results?.hits, query]);

  return null;
};
