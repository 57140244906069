import {
  useContext,
  useEffect, useMemo, useRef, useState,
} from 'react';
import './linkedin-actions.scss';
import ReactQuill from 'react-quill';
import {
  Button, IconButton, InfoModal, Tooltip,
} from '@careeros/coco';
import { GenerateMessageOptions } from '@/domains/core/contact';
import { UseAIButton } from '@/domains/core/student';
import { convertNewLinesToHtml, findTextInBrackets } from '@/services/helpers/messages';
import useDebounceEffect from '@/services/hooks/use-debounce';
import { useAnalytics } from '@/services/hooks/use-analytics';
import '@/services/helpers/quill-config';
import { ContactPlaceholders, OutreachScenarioType, TemplateRequestPayload } from '../../types';
import { ModalContext } from '@/components/modal/modal-provider';
import { CustomTemplatesModal } from '../custom-templates-modal/custom-templates-modal';
import { useInboxContext } from '@/pages/context/inbox';
import { TemplatesButton } from '../templates-button/templates-button';
import { useCheckExtension } from '@/services/hooks/use-check-extension';
import { useOutreachScenario } from '@/services/hooks/use-outreach-scenario';
import { useSelfUniversity } from '@/services/queries/user';
import { CatolicaTemplate } from '../outreach-scenarios/scenarios';
import { prettifyHTML } from '@/services/helpers/processHtml';
import { OutreachScenarios } from '../outreach-scenarios/outreach-scenarios';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';

type LinkedinActionsProps = {
  contactName: string;
  contactID: string;
  sendMessage: (message: string, aiUsed?: boolean) => void;
  isFirstOutreach?: boolean;
  isConnectionRequest?: boolean;
  messageLengthLimit?: number;
  regenerateData?: TemplateRequestPayload;
};

const MIN_MESSAGE_LENGTH = 25;

export const LinkedinActions = ({
  contactName,
  sendMessage,
  contactID,
  isFirstOutreach = false,
  isConnectionRequest = false,
  messageLengthLimit = 200,
  regenerateData,
}: LinkedinActionsProps) => {
  const { checkUserExtension } = useCheckExtension({ requestedView: 'inbox' });
  const { askToTrackLinkedInLogin } = useExtensionMessaging();
  const { currentContact } = useInboxContext() || {};
  const { trackEvent } = useAnalytics();
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [message, setMessage] = useState<any>('');
  const [AIUsed, setAIUsed] = useState(false);
  const [warningMessage, setWarningMessage] = useState<string>();
  const [messageLength, setMessageLength] = useState(0);
  const [isSendingDisabled, setIsSendingDisabled] = useState(true);
  const isSendButtonDisabled = useMemo(() => isSendingDisabled || !messageLength, [isSendingDisabled, messageLength]);
  const reactQuillRef = useRef<ReactQuill | null>(null);
  const quillEditor = useRef<any>(null);
  const [contactPlaceholders, setContactPlaceholders] = useState<ContactPlaceholders>();
  const { data: univeristy } = useSelfUniversity();
  const {
    showScenarios,
    regenerateScenario,
    isLoadingOutreachScenario,
    hideScenarios,
    scenarioTemplate,
    toggleScenarioVisibility,
    showRegenerateScenario,
    displayScenarios,
    cancelRegenerateScenario,
  } = useOutreachScenario();

  const applyTemplate = (template: string) => {
    setMessage(template);
    setIsSendingDisabled(false);
    hideScenarios();
    setAIUsed(false);
    closeModal();
  };

  const checkForCatolica = (sc: OutreachScenarioType) => {
    if (sc === 'meet_at_in_person_career_fair' && univeristy?.name === 'Católica Lisbon School of Business') {
      setMessage(prettifyHTML(CatolicaTemplate(regenerateData?.studentName || '[Your First Name]')));
      hideScenarios();
      return;
    }
    regenerateScenario(regenerateData, sc);
    setAIUsed(true);
  };

  const handleWriteMessageSelection = () => {
    hideScenarios();
    setMessage('');
    setAIUsed(false);
  };

  const handleCustomTemplatesClick = () => {
    if (!contactPlaceholders) {
      return;
    }
    openModal(
      <CustomTemplatesModal
        contactPlaceholders={contactPlaceholders}
        previewType="linkedin"
        selectTemplate={applyTemplate}
      />,
      {
        closeOnClickOutside: false,
      },
    );
  };

  const checkMessagePlaceholders = () => {
    const hasPlaceholders = message.match(/\[[^[\]]*\]/g);

    return hasPlaceholders;
  };

  const checkMessageLength = () => {
    const textLength = quillEditor.current?.getText().trim().length;

    if (isConnectionRequest && textLength > messageLengthLimit) {
      setIsSendingDisabled(true);
      setWarningMessage(`Please make sure your message does not exceed ${messageLengthLimit} characters.`);

      return false;
    }

    if (isConnectionRequest && textLength < MIN_MESSAGE_LENGTH) {
      setIsSendingDisabled(true);

      return false;
    }

    setIsSendingDisabled(false);
    setWarningMessage(undefined);

    return true;
  };

  const checkMessageBeforeSend = () => {
    const textLength = quillEditor.current?.getText().trim().length;

    if (checkMessagePlaceholders()) {
      setIsSendingDisabled(true);
      setWarningMessage('Please make sure to replace the placeholders in brackets with the according information.');

      return false;
    }

    if (isConnectionRequest && textLength > messageLengthLimit) {
      setIsSendingDisabled(true);
      setWarningMessage(`Please make sure your message does not exceed ${messageLengthLimit} characters.`);

      return false;
    }

    if (isConnectionRequest && textLength < MIN_MESSAGE_LENGTH) {
      setIsSendingDisabled(true);
      setWarningMessage(`Please send a personalized outreach message with at least ${MIN_MESSAGE_LENGTH} characters.`);

      return false;
    }

    setIsSendingDisabled(false);
    setWarningMessage(undefined);

    return true;
  };

  const handleMessageChange = (value: string) => {
    const textLength = quillEditor.current?.getText().trim().length;
    setMessage(value);
    setMessageLength(textLength);

    checkMessageLength();
  };

  const handleRedirectToLinkedInForLogin = () => {
    window.open('https://www.linkedin.com', '_blank');

    askToTrackLinkedInLogin();
    closeModal();
  };

  const handleRegenerateClick = (msg: string) => {
    setMessage(convertNewLinesToHtml(msg));
    setAIUsed(true);
    trackEvent('Inbox Linkedin Message AI Generated');
  };

  const handleClickSendMessage = async () => {
    const currentValue = quillEditor.current?.getText() || '';
    const trimmedValue = currentValue.trim();

    if (trimmedValue.length === 0) {
      return;
    }

    if (!checkMessageBeforeSend()) {
      return;
    }

    const isExtensionInstalled = await checkUserExtension();

    if (!isExtensionInstalled) {
      return;
    }

    if (isExtensionInstalled === 'User is not logged into LinkedIn.') {
      openModal(
        <InfoModal
          title="Login to LinkedIn"
          description={(
            <>
              To send LinkedIn messages through CareerOS...
              <ul>
                <li>Go to LinkedIn.</li>
                <li>Log in on LinkedIn.</li>
                <li>Come back to this tab.</li>
              </ul>
            </>
          )}
          icon="bi bi-box-arrow-in-right"
          buttonLabel="Login on LinkedIn"
          buttonIcon="bi bi-box-arrow-up-right"
          handleButtonClick={handleRedirectToLinkedInForLogin}
          handleSecondaryButtonClick={closeModal}
          secondaryButtonLabel="Cancel"
          orientation="horizontal"
          primaryButtonIconPosition="right"
        />,
      );

      return;
    }

    sendMessage(trimmedValue, AIUsed);
    quillEditor.current?.setText('');
    setAIUsed(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
      event.preventDefault();
      handleClickSendMessage();
    }
  };

  const attachQuillRefs = () => {
    if (!reactQuillRef.current) {
      return;
    }
    if (typeof reactQuillRef.current.getEditor !== 'function') return;
    quillEditor.current = reactQuillRef.current.getEditor();
  };

  const highlightPlaceholders = () => {
    if (!reactQuillRef.current) {
      return;
    }
    const bracketedParts = findTextInBrackets(quillEditor.current.getText());
    quillEditor.current.formatText(0, quillEditor.current.getText().length, {
      color: false,
      bold: false,
    });
    bracketedParts.forEach(part => {
      quillEditor.current.formatText(part.index, part.length, {
        color: 'var(--purpose-information)',
        bold: true,
      });
    });
  };

  useDebounceEffect(() => {
    if (!reactQuillRef.current) {
      return;
    }

    const textLength = quillEditor.current.getText().trim().length;
    setMessageLength(textLength);

    highlightPlaceholders();
  }, [message, contactName, reactQuillRef.current]);

  useEffect(() => {
    attachQuillRefs();
    setMessage('');
  }, [contactName]);

  useEffect(() => {
    if (scenarioTemplate) {
      setMessage(scenarioTemplate);
    }
  }, [scenarioTemplate, isLoadingOutreachScenario]);

  useEffect(() => {
    toggleScenarioVisibility(isFirstOutreach);
  }, [isFirstOutreach]);

  useEffect(() => {
    if (currentContact) {
      setContactPlaceholders({
        FirstName: currentContact.first_name,
        LastName: currentContact.last_name,
        Company: currentContact.current_position?.company,
      });
    }
  }, [currentContact?.id]);

  return (
    <div className="linkedin-actions" data-testid="linkedin-actions">
      <div className="linkedin-actions__input-container">
        <div data-testid="linkedin-actions-message-input" className="linkedin-actions__box">
          {(showScenarios || isLoadingOutreachScenario) && isFirstOutreach && (
            <div className="linkedin-actions__scenarios" data-testid="linkedin-actions-scenarios">
              <OutreachScenarios
                handleApplyTemplate={(sc) => checkForCatolica(sc)}
                handleWriteMessageSelection={handleWriteMessageSelection}
                isLoading={isLoadingOutreachScenario}
              />
            </div>
          )}
          <ReactQuill
            ref={reactQuillRef}
            placeholder="Write a message"
            modules={{
              toolbar: null,
            }}
            theme="snow"
            value={message}
            onChange={handleMessageChange}
            onKeyDown={handleKeyPress}
            style={{
              border: 'none',
              padding: '0',
              borderRadius: '8px',
              display: (showScenarios || isLoadingOutreachScenario) ? 'none' : 'block',
            }}
          />
        </div>
        <div
          className="linkedin-actions__bottom"
          data-testid="linkedin-actions-bottom"
        >
          {warningMessage && (
          <span data-testid="linkedin-actions-warning" className="linkedin-actions__warning">{warningMessage}</span>
          )}
          {isConnectionRequest && (
            <span
              className={`linkedin-actions__length ${warningMessage ? 'linkedin-actions__length--dangerous' : ''}`}
              data-testid="linkedin-actions-message-length"
            >
              {messageLength}
              /
              {messageLengthLimit}
            </span>
          )}
        </div>
      </div>
      <div className="linkedin-actions__footer">
        <TemplatesButton
          showOutreachButton={isFirstOutreach && !showScenarios && !isLoadingOutreachScenario}
          triggerOutreachTemplateButton={displayScenarios}
          triggerCustomTemplateButton={handleCustomTemplatesClick}
        />
        <div className="linkedin-actions__cta">
          {showRegenerateScenario && (
            <IconButton
              label="Regenerate First Outreach"
              icon={isLoadingOutreachScenario ? 'bi bi-x-lg' : 'bi bi-arrow-clockwise'}
              onClick={() => (isLoadingOutreachScenario ? cancelRegenerateScenario() : regenerateScenario(regenerateData))}
              mode="special"
              size="large"
              outlined
            />
          )}
          {!isFirstOutreach && (
            <UseAIButton
              options={GenerateMessageOptions}
              type="linkedin"
              size="small"
              handleResult={handleRegenerateClick}
              metaData={{
                contactID,
              }}
            />
          )}
          <div className="linkedin-actions__send-button" data-testid="linkedin-actions-send-button">
            <Tooltip position="top" label={warningMessage || 'Message cannot be empty.'} disableHoverListener={!isSendButtonDisabled}>
              <Button
                disabled={isSendButtonDisabled}
                mode="primary"
                size="medium"
                icon="bi bi-send"
                handleClick={handleClickSendMessage}
                label={isConnectionRequest ? 'Send LinkedIn Connection Request' : 'Send via LinkedIn'}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};
