import { useCallback, useContext, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import {
  Contact, contactKanbanStatuses, ContactsKanbanBoardLayout, ContactStatus,
} from '@/domains/core/contact/types';
import { ArchiveContactModal } from '@/domains/core/netwoking/components';
import { ContactKanbanCardInfo, getContactsKanbanLayout } from '@/domains/core/netwoking/constants/contacts-kanban';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { useAnalytics } from './use-analytics';
import { updateContactPositionInKanban } from '../api/contact';
import { ModalContext } from '@/components/modal/modal-provider';

export const useContactsKanban = () => {
  const [kanbanLayout, setKanbanLayout] = useState<ContactsKanbanBoardLayout | null>(null);

  const { addToast }: AddToastType = useContext(ToastContext);
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const { user } = useAuth0();
  const queryClient = useQueryClient();

  const computeKanbanLayout = (allContacts: Contact[]) => {
    setKanbanLayout(getContactsKanbanLayout(allContacts));
  };

  const trackStatusChange = (status: ContactStatus, newStatus: ContactStatus) => {
    if (status === newStatus) {
      return;
    }

    if (newStatus !== 'Contact Saved') {
      trackEvent(`Kanban Contact moved to ${newStatus}`, user);
    }
  };

  const updateTilePositionOnKanban = (id: string, currentStatus: ContactStatus, newStatus: ContactStatus, position: number) => {
    if (!kanbanLayout) {
      return;
    }

    const newKanban = { ...kanbanLayout };

    const contactCard = newKanban[currentStatus]?.find((contact: any) => contact.id === id);

    if (!contactCard) {
      return;
    }

    newKanban[currentStatus] = newKanban[currentStatus].filter((contact: any) => contact.id !== id);

    newKanban[newStatus].splice(position, 0, contactCard);

    setKanbanLayout(newKanban);
    trackStatusChange(currentStatus, newStatus);
    const droppedColumn = contactKanbanStatuses[newStatus];
    contactCard.kanban_position.status = droppedColumn;
  };

  const handleStatusUpdateResponse = useCallback((isSuccess: boolean, response: any, id: string, newStatus: ContactStatus, oldStatus: ContactStatus, oldKanban: ContactsKanbanBoardLayout) => {
    const successToastMessage = newStatus === 'Archived' ? 'Contact archived' : 'Contact status updated';
    const successAdditionalMessage = newStatus === 'Archived' ? 'The contact has been successfully archived.' : 'The contact status has been successfully updated.';
    const errorToastMessage = newStatus === 'Archived' ? 'Failed to archive contact' : 'Failed to update contact status';

    if (isSuccess && newStatus !== oldStatus) {
      addToast({
        type: 'success',
        message: successToastMessage,
        additionalMessage: successAdditionalMessage,
      });

      // if (oldStatus === 'Archived' || newStatus === 'archive') {
      //   updatePreparedContactsAfterArchive(id, newStatus, dropPosition);
      // }
    } else if (!isSuccess) {
      addToast({
        type: 'error',
        message: errorToastMessage,
        additionalMessage: "We're looking into the issue. Please try again later.",
      });
      trackEvent('Toast Error Shown', user, {
        message: 'Failed to update contact status',
        error: response?.error,
      });

      oldKanban[newStatus] = oldKanban[newStatus].filter((contact: any) => contact.id !== id);
      setKanbanLayout(oldKanban);
    }
  }, []);

  const handleContactNewStatus = async (cardInfo: ContactKanbanCardInfo & { status: ContactStatus }, newStatus: ContactStatus) => {
    const { id, status, dropPosition } = cardInfo;
    if (!kanbanLayout) {
      return;
    }

    const oldKanban: ContactsKanbanBoardLayout = { ...kanbanLayout };

    updateTilePositionOnKanban(id, status, newStatus, dropPosition);
    trackStatusChange(status, newStatus);
    const droppedColumn = contactKanbanStatuses[newStatus];

    const response = await updateContactPositionInKanban(id, droppedColumn, dropPosition);

    handleStatusUpdateResponse(response && response.message === 'The position has been updated', response, id, newStatus, status, oldKanban);
    await queryClient.invalidateQueries(['applications']);
    queryClient.invalidateQueries(['dashboard-actions']);
  };

  const archiveContact = async (id: string, currentStatus: ContactStatus, position: number, newStatus: ContactStatus) => {
    const oldKanban: any = { ...kanbanLayout };

    updateTilePositionOnKanban(id, currentStatus, newStatus, position);
    const droppedColumn = contactKanbanStatuses[newStatus];

    const response = await updateContactPositionInKanban(id, droppedColumn, position);
    handleStatusUpdateResponse((response && response.message) === 'The position has been updated', response, id, newStatus, currentStatus, oldKanban);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries(['dashboard-actions']);
  };

  const handleArchiveContactConfirmation = (id: string, currentStatus: ContactStatus, position: number, rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveContact', 'true');
    }
    closeModal();
    archiveContact(id, currentStatus, position, 'Archived');
  };

  const requestArchiveConfirmation = (id: string, currentStatus: ContactStatus, position: number = 0) => {
    const rememberAction = localStorage.getItem('rememberArchiveContact');

    if (!rememberAction) {
      openModal(
        <ArchiveContactModal
          handleArchiveContact={(remember) => handleArchiveContactConfirmation(id, currentStatus, position, remember)}
          closeModal={closeModal}
        />,
      );
    } else {
      handleArchiveContactConfirmation(id, currentStatus, position, false);
    }
  };

  return {
    handleContactNewStatus,
    requestArchiveConfirmation,
    computeKanbanLayout,
    kanbanLayout,
    changeKanbanLayout: setKanbanLayout,
  };
};
