import './custom-template-preview.scss';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { IconButton, DefaultLogo } from '@careeros/coco';
import { TemplatePreviewType } from '../../types';
import { addBlankTargetToLinks, prettifyHTML } from '@/services/helpers/processHtml';
import { MessageHeader } from '@/domains/core/contact/components/message-header/message-header';

const sanitizeConfig = {
  ADD_ATTR: ['target'], // Allow the "target" attribute
};

type Props = {
  template: string;
  subject: string;
  messageType: TemplatePreviewType;
  user: {
    name: string;
    picture: string;
  };
  contactEmail?: string;
  withCTA?: boolean;
  handleRemoveTemplate?: () => void;
  handleEditTemplate?: () => void;
  maxLength?: number;
  isEmpty?: boolean;
};

export const CustomTemplatePreview = ({
  template,
  subject,
  messageType,
  contactEmail,
  user,
  withCTA = false,
  handleRemoveTemplate,
  handleEditTemplate,
  maxLength,
  isEmpty = false,
}: Props) => {
  const [isLengthWarningVisible, setIsLengthWarningVisible] = useState(false);

  useEffect(() => {
    if (!maxLength) {
      return;
    }
    const doc = new DOMParser().parseFromString(template, 'text/html');
    const textLength = doc.body.textContent?.length || 0;

    setIsLengthWarningVisible(textLength > maxLength);
  }, [template]);

  return (
    <div data-testid="template-preview" className="template-preview">
      <div className="template-preview__header">
        <h3 data-testid="template-preview-title" className="template-preview__title">
          <i className="template-preview__title-icon bi bi-eye-fill" />
          Preview
        </h3>
        {withCTA && (
          <div data-testid="template-preview-cta" className="template-preview__actions">
            <IconButton
              icon="bi bi-trash"
              onClick={handleRemoveTemplate}
              mode="rounded"
              isDangerous
              size="medium"
              outlined
              data-testid="template-preview-delete"
            />
            <IconButton
              icon="bi bi-pencil"
              onClick={handleEditTemplate}
              mode="rounded"
              size="medium"
              outlined
              data-testid="template-preview-edit"
            />
          </div>
        )}
      </div>

      <div className="template-preview__separator" />
      <div className={`template-preview__content ${messageType !== 'email' ? 'template-preview__content--linkedin' : ''} ${isEmpty ? 'template-preview__content--hidden' : ''}`}>
        <div className="template-preview__content-container">
          {(messageType === 'connection' || messageType === 'linkedin') ? (
            <div className="template-preview__linkedin">
              <div className="template-preview__linkedin-message">
                <div
                  className="template-preview__linkedin-text"
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(prettifyHTML(template), sanitizeConfig) }}
                  data-testid="template-preview-linkedin-text"
                />
              </div>
              <div className="template-preview__linkedin-image">
                <DefaultLogo
                  type="contact"
                  source={user.picture}
                  name={user.name}
                  size={48}
                  className="template-preview__linkedin-image"
                />
              </div>
            </div>
          ) : (
            <div className="template-preview__email">
              {messageType !== 'comment' && (
                <MessageHeader
                  messageType="email"
                  isRepliable={false}
                  contactFirstName={user.name}
                  contactImage={user.picture}
                  subtitle={`to ${contactEmail}`}
                  title={user.name || ''}
                  messageCreatedAt={null}
                  handleReplyClick={() => { }}
                  isReplyButtonExpanded
                  isPreview
                />
              )}
              <div className={`template-preview__email-body ${messageType === 'email' ? 'template-preview__email-body--with-padding-left' : ''}`}>
                {subject && (
                  <span
                    className="template-preview__email-subject"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(subject, sanitizeConfig),
                    }}
                    data-testid="template-preview-subject"
                  />
                )}
                <div
                  data-testid="template-preview-email-body"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(addBlankTargetToLinks(template), sanitizeConfig),
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {isLengthWarningVisible && (
        <div data-testid="template-preview-warning" className="template-preview__warning">
          This template exceeds the character limit. Please mind that you will need to modify the message before sending the connection request.
        </div>
      )}
    </div>
  );
};
