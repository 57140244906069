import { Link } from 'react-router-dom';
import { LinkedInConnectionIcons } from './linkedin-connection-icons';

type LinkedInSuggestedErrorContent = {
  title: string;
  description: string | JSX.Element;
  buttonLabel: string;
  buttonIcon: string;
  image: JSX.Element;
};

export type LinkedInSuggestedErrorType = 'downloadExtension' | 'loginLinkedIn';

export const linkedinSuggestedContactsErrorContent: { [key in LinkedInSuggestedErrorType]: LinkedInSuggestedErrorContent } = {
  downloadExtension: {
    title: 'Find the Right Contacts',
    description: (
      <>
        Receive suggested contacts based on similarities like language and alumni connections, using our
        {' '}
        <Link
          to={import.meta.env.VITE_EXTENSION_LINK}
          target="_blank"
        >
          Chrome Extension
        </Link>
        , on your laptop or desktop.
      </>
    ),
    buttonLabel: 'Download Chrome Extension',
    buttonIcon: 'bi bi-box-arrow-up-right',
    image: <LinkedInConnectionIcons />,
  },
  loginLinkedIn: {
    title: 'Save Contacts for any Company',
    description: 'Log into LinkedIn to be able to save contacts and see your suggested contacts for this company!',
    buttonLabel: 'Log in now',
    buttonIcon: 'bi bi-box-arrow-up-right',
    image: <LinkedInConnectionIcons />,
  },
};
