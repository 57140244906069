import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { trackerTabs } from '@/domains/core/tracker-data';
import { useAnalytics } from './use-analytics';

export function useTrackerModal() {
  const [isTrackerModal, setIsTrackerModal] = useState(false);
  const [companyID, setCompanyID] = useState<string>();
  const [jobID, setJobID] = useState<string | null>(null);
  const [trackerTab, setTrackerTab] = useState<typeof trackerTabs[number]>();
  const [additionalParams, setAdditionalParams] = useState<{ [key: string]: string }>();
  const [searchParams] = useSearchParams();
  const { trackEvent } = useAnalytics();

  const openTracker = (id: string, tab?: typeof trackerTabs[number], params?: { [key: string]: string }, jobIDPayload?: string) => {
    setCompanyID(id);
    setIsTrackerModal(true);
    setTrackerTab(tab);
    setAdditionalParams(params);
    const newSearchParams = new URLSearchParams(window.location.search);

    if (newSearchParams.get('tracker-companyID')) {
      newSearchParams.delete('tracker-companyID');
    }
    newSearchParams.append('tracker-companyID', encodeURIComponent(id));
    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        newSearchParams.delete(key);
        newSearchParams.append(key, value);
      });
    }

    if (jobIDPayload) {
      setJobID(jobIDPayload);
      newSearchParams.delete('jobID');
      newSearchParams.append('jobID', jobIDPayload);
    }

    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    trackEvent('Tracker Modal Opened', {}, {
      company_id: id,
    });
  };

  const closeTracker = () => {
    const newSearchParams = new URLSearchParams(window.location.search);

    if (newSearchParams.get('tracker-companyID')) {
      newSearchParams.delete('tracker-companyID');
      newSearchParams.delete('jobID');
    }

    if (additionalParams) {
      Object.keys(additionalParams).forEach((key) => newSearchParams.delete(key));
      setAdditionalParams(undefined);
    }

    window.history.replaceState(null, '', newSearchParams.size > 0 ? `?${newSearchParams.toString()}` : '');

    setCompanyID(undefined);
    setJobID(null);
    setTrackerTab(undefined);
    setIsTrackerModal(false);
  };

  const getCompanyIdFromSearchParams = () => {
    const companyIDFromSearchParams = searchParams.get('tracker-companyID');
    return companyIDFromSearchParams;
  };

  const openTrackerBySearchParams = () => {
    const companyIDFromSearchParams = getCompanyIdFromSearchParams();
    const tabFromSearchParams = decodeURIComponent(searchParams.get('company-tracker-tab') || trackerTabs[0]);
    const jobIDFromSearchParams = searchParams.get('jobID') || undefined;

    openTracker(companyIDFromSearchParams || '', tabFromSearchParams, undefined, jobIDFromSearchParams);
  };

  useEffect(() => {
    if (searchParams.has('tracker-companyID')) {
      openTrackerBySearchParams();
    }
  }, [searchParams]);

  useEffect(() => {
    if (!window.location.search.includes('tracker-companyID')) {
      closeTracker();
    }
  }, [window.location.search]);

  return {
    openTracker, closeTracker, isTrackerModal, companyID, trackerTab, jobID,
  };
}
