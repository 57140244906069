/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useState, useEffect, useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  InstantSearch, SearchBox, Configure, useHits,
} from 'react-instantsearch';
import { Button } from '@careeros/coco';
import { searchClient } from '@/services/hooks/use-algolia';
import './algolia-search.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

type CompanyHitProps = {
  hit: {
    objectID: string;
    name: string;
    overview: string;
    location: string;
    logo_url: string;
    _highlightResult: {
      id: {
        value: string;
      };
    };
  };
  setQuery: (query: string) => void;
};

const CompanyHit = ({ hit, setQuery }: CompanyHitProps) => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const handleClick = () => {
    // eslint-disable-next-line no-underscore-dangle
    const companyId = hit._highlightResult.id.value;
    trackEvent('Topbar search clicked on suggested', {}, companyId);
    navigate(`/app/companies/company/${companyId}`);
    // Close the dropdown by setting the query to an empty string
    setQuery('');
  };

  return (
    <li className="search-result-item" onClick={handleClick}>
      <img src={hit.logo_url} alt={hit.name} />
      <div className="company-info">
        <span className="company-name">{hit.name}</span>
        <span className="company-description">{hit.overview}</span>
      </div>
    </li>
  );
};

function removeSpecialCharacters(searchValue: string) {
  return searchValue.trim().replace(/\\/g, '').replace(/\//g, '');
}

// Custom hits component that passes setQuery to Hit
function CustomHits({
  setQuery,
  query,
  onSearchSubmitHandler,
}: {
  setQuery: (query: string) => void;
  query: string;
  onSearchSubmitHandler: (query: string) => void;
}) {
  const { results } = useHits();
  const hits = results?.hits || [];

  return (
    <ul className="ais-Hits-list">
      {hits.length === 0 ? (
        <li className="search-result-item no-results">
          No results for &quot;
          {query}
          &quot;
        </li>
      ) : (
        <>
          {hits.map((hit) => (
            <CompanyHit key={hit.objectID} hit={hit as any} setQuery={setQuery} />
          ))}
          <div className="view-more-results">
            <Button mode="primary" outlined icon="bi bi-box-arrow-up-right" iconPosition="right" onClick={() => onSearchSubmitHandler(query)} size="small" label="View More Results" />
          </div>
        </>
      )}
    </ul>
  );
}

export const AlgoliaSearchBar = () => {
  const [query, setQuery] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();

  const onSearchSubmitHandler = (qr: string) => {
    if (qr) {
      const removeSpecialChar = removeSpecialCharacters(qr);
      const queryParams = new URLSearchParams([['query', removeSpecialChar]]);
      trackEvent('Topbar search view more results for companies', undefined, removeSpecialChar);
      navigate(`/app/companies/search?${queryParams.toString()}`);
      setQuery('');
    }
  };

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        setQuery('');

        // Directly set the input value to empty
        setTimeout(() => {
          const input = document.querySelector('.algolia-search-form__input') as HTMLInputElement;
          if (input) {
            input.value = '';
          }
        }, 0);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  // Handle enter key press when dropdown is open
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && query) {
        event.preventDefault();
        onSearchSubmitHandler(query);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [query]);

  // Scroll dropdown to top when query changes
  useEffect(() => {
    if (query && dropdownRef.current) {
      dropdownRef.current.scrollTop = 0;
    }
  }, [query]);

  // Handle body scroll blocking and cleanup on page navigation
  useEffect(() => {
    if (query) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    // Only clean up body scroll when unmounting, don't interfere with query state during normal operation
    return () => {
      document.body.style.overflow = '';
    };
  }, [query]);

  // Additional cleanup for page navigation/unmounting
  useEffect(() => () => {
    setQuery('');
    const input = document.querySelector('.algolia-search-form__input') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <form ref={formRef} className="algolia-search-form" method="" onSubmit={handleSubmit}>
      <button className="algolia-search-form__button" aria-label="Search for companies on CareerOS" type="submit">
        <i className="bi bi-search" />
      </button>
      <InstantSearch
        searchClient={searchClient}
        indexName="company"
        future={{ preserveSharedStateOnUnmount: true }}
      >
        <label className="algolia-search-form__label">
          <SearchBox
            placeholder="Search for companies on CareerOS"
            classNames={{
              root: '',
              form: '',
              input: 'algolia-search-form__input',
              submit: 'hidden',
              reset: 'hidden',
              loadingIndicator: 'hidden',
            }}
            onInput={(e) => setQuery((e.target as HTMLInputElement).value)}
            searchAsYouType
          />
        </label>
        {/* Search results dropdown */}
        {query && (
          <div ref={dropdownRef} className="search-results-dropdown search-results-dropdown--company">
            <Configure
              hitsPerPage={20}
              clickAnalytics
            />
            <CustomHits setQuery={setQuery} query={query} onSearchSubmitHandler={onSearchSubmitHandler} />
          </div>
        )}
      </InstantSearch>
    </form>
  );
};
