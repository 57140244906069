import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTabs } from './TabsContext';
import './tabs.scss';
import { Tab } from './tab';

interface TabsHeaderProps {
  tabs: string[];
  unreadTabs?: string[];
  defaultTab: string;
  icons?: string[];
  activeIcons?: string[];
  size?: 'medium' | 'large';
  tabCounters?: { [key: string]: number };
  disabledTabs?: string[];
  withBorder?: boolean;
  containerName?: string;
  onTabClick?: (tab: string) => void;
  counterTooltips?: { [key: string]: string };
  tabTooltips?: { [key: string]: string };
  isScrollableOnOverflow?: boolean;
}
/**
 * Primary UI component for Tabs Header
 */
export const TabsHeader = ({
  tabs,
  defaultTab,
  size = 'large',
  icons = [],
  activeIcons = [],
  unreadTabs = [],
  disabledTabs = [],
  tabCounters,
  withBorder = false,
  containerName,
  onTabClick,
  counterTooltips,
  tabTooltips,
  isScrollableOnOverflow,
}: TabsHeaderProps) => {
  const { currentTab, setCurrentTab } = useTabs();
  const [searchParams] = useSearchParams();
  const tabParamName = containerName ? `${containerName}-tab` : 'tab';

  const updateTabParam = (tab: string) => {
    const newSearchParams = new URLSearchParams(window.location.search);
    newSearchParams.set(tabParamName, encodeURIComponent(tab));
    window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
  };

  const handleTabClicked = (tab: string) => {
    if (onTabClick) {
      onTabClick(tab);
    }
    setCurrentTab(tab);
    updateTabParam(tab);
  };

  useEffect(() => {
    setCurrentTab(defaultTab);
  }, [setCurrentTab, defaultTab]);

  useEffect(() => {
    if (searchParams.has(tabParamName)) {
      const tab = decodeURIComponent(searchParams.get(tabParamName) || '');
      setCurrentTab(tabs.includes(tab) ? tab : defaultTab);
    }
  }, [searchParams]);

  useEffect(() => {
    if (currentTab) {
      updateTabParam(currentTab);
    }
  }, [currentTab]);

  useEffect(() => () => {
    const newSearchParams = new URLSearchParams(window.location.search);
    setCurrentTab(undefined);

    if (newSearchParams.get(tabParamName) && tabs.includes(decodeURIComponent(newSearchParams.get(tabParamName) || ''))) {
      newSearchParams.delete(tabParamName);
      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    }
  }, []);

  return (
    <div className={`tabs-header-wrapper ${isScrollableOnOverflow ? 'tabs-header-wrapper--scrollable' : ''}`}>
      <div className={`tabs-header ${withBorder ? 'tabs-header--with-border' : ''} ${isScrollableOnOverflow ? 'tabs-header--scrollable' : ''}`}>
        {tabs.map((tab: string, index: number) => (
          <Tab
            key={tab}
            tabName={tab}
            size={size}
            icon={icons && icons[index]}
            activeIcon={activeIcons && activeIcons[index]}
            isActive={currentTab === tab}
            isUnread={unreadTabs.includes(tab)}
            counter={tabCounters && tabCounters[tab]}
            handleTabClicked={() => handleTabClicked(tab)}
            isDisabled={disabledTabs.includes(tab)}
            counterTooltip={counterTooltips && counterTooltips[tab]}
            tabTooltip={tabTooltips && tabTooltips[tab]}
          />
        ))}
      </div>
    </div>
  );
};
