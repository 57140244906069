import { Tooltip } from '@careeros/coco';
import './tile-cta.scss';

type Props = {
  label: string;
  icon: string;
  hoverIcon?: string;
  handleClick: () => void;
  counter?: number;
  isDangerous?: boolean;
};

export const TileCta = ({
  label,
  icon,
  handleClick,
  counter,
  hoverIcon,
  isDangerous,
}: Props) => {
  const onClick = (ev: React.MouseEvent) => {
    ev.preventDefault();
    ev.stopPropagation();
    handleClick();
  };

  return (
    <div className="tile-cta" onClick={onClick}>
      <Tooltip label={label} withArrow position="top">
        <div className="tile-cta__container">
          <div>
            <i className={`tile-cta__icon ${icon} ${isDangerous ? 'tile-cta__icon--danger' : ''}`} />
            <i className={`tile-cta__icon tile-cta__icon--hover ${hoverIcon || icon} ${isDangerous ? 'tile-cta__icon--danger' : ''}`} />
          </div>
          {counter !== undefined && (
            <span
              className={`tile-cta__counter tile-cta__counter--${counter}`}
            >
              {counter}
            </span>
          )}
        </div>
      </Tooltip>
    </div>
  );
};
