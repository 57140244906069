import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import { listEmailMessages } from '@/services/helpers/messages';
import { useActiveEmail } from '@/services/hooks/use-active-email';
import { EmailThread } from '@/domains/core/netwoking/components/email-thread/email-thread';
import { EmailThreadPreview } from '@/domains/core/netwoking/components/email-thread-preview/email-thread-preview';
import { SentEmailLogType } from '../../types/sent-email-log';
import '../../../netwoking/components/email-chat/email-chat.scss';
import EmptyEmailBox from '@/assets/images/empty-email-box.png';

type SentLogChatProps = {
  messages: SentEmailLogType[];
  hasLongRecipient?: boolean;
};

export const SentLogChat = ({
  messages,
  hasLongRecipient,
}: SentLogChatProps) => {
  const activeEmail = useActiveEmail();
  const navigate = useNavigate();
  const groupedMessages = useMemo(
    () => listEmailMessages(messages, activeEmail),
    [messages, activeEmail],
  );
  const [currentThread, setCurrentThread] = useState<string | null>(null);

  const handleSelectThread = (id: string) => {
    setCurrentThread(id);
  };

  const handleMessageStudentsClick = () => {
    navigate('/app/advisor/students');
  };

  useEffect(() => {
    setCurrentThread(null);
  }, [messages]);

  if (messages.length === 0) {
    return (
      <div className="sent-email-log__empty">
        <img src={EmptyEmailBox} alt="Empty Email Box" />
        <div className="sent-email-log__empty-container">
          <h2 className="sent-email-log__empty-title">
            No Email History Yet
          </h2>
          <p className="sent-email-log__empty-subtitle">
            You haven’t sent any emails to students yet. Once you do, you’ll see your conversation history here.
          </p>
        </div>
        <Button
          label="Message Students"
          mode="primary"
          size="medium"
          outlined
          icon="bi bi-arrow-right"
          iconPosition="right"
          handleClick={handleMessageStudentsClick}
        />
      </div>
    );
  }

  return (
    <div className={`email-chat ${currentThread ? 'email-chat--white-background' : ''}`}>
      {currentThread && (
        <EmailThread
          messages={[...groupedMessages[currentThread]?.messages || []].reverse()}
          threadData={groupedMessages[currentThread]?.data}
          handleCloseThread={() => setCurrentThread(null)}
          isReplyButtonShown
        />
      )}
      {groupedMessages && !currentThread && (
        <div className="sent-email-log__column-header">
          <span className="sent-email-log__first-column-header">
            <b>From,</b>
            &nbsp;To
          </span>
          <span className="sent-email-log__content-header">
            <b>Subject</b>
            &nbsp;& Email Preview
          </span>
        </div>
      )}
      <div className="sent-email-log__separator" />
      {groupedMessages && !currentThread
          && Object.entries(groupedMessages).map(([id, thread]) => (
            <EmailThreadPreview
              key={id}
              messagesCount={thread.messages.length}
              threadData={thread.data}
              handleSelectThread={() => handleSelectThread(id)}
              hasLongRecipient={hasLongRecipient}
            />
          ))}
    </div>
  );
};
