import { Link } from 'react-router-dom';
import { IconButton, Tooltip } from '@careeros/coco';
import { useContext, useMemo } from 'react';
import { ContactInfoTile, ContactSummary } from '@/domains/core/contact';
import { ConnectionStatus } from '@/domains/core/contact/types';
import { InboxContextType, useInboxContext } from '@/pages/context/inbox';

import './contact-bio-sidebar.scss';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { ContactNotes } from '../contact-notes/contact-notes';
import { ContactInfoBasics } from '@/domains/core/contact/components/info-tile/info-tile';
import { StatusTag } from '@/domains/core/contact/components/status-tags/status-tag';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';

type Props = {
  connectionStatus: ConnectionStatus;
  networkingStatus: ConnectionStatus;
  onEmailChanged: () => void
  handleArchive: () => void;
  handleClose: () => void;
};

const ContactBioSidebar = ({
  connectionStatus,
  networkingStatus,
  onEmailChanged,
  handleArchive,
  handleClose,
}: Props) => {
  const { currentContact: contact } = useInboxContext() as InboxContextType;
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const contactBasicInfo = useMemo<ContactInfoBasics>(() => ({
    id: contact.id,
    first_name: contact.first_name,
    last_name: contact.last_name,
    email: contact.email,
    secondary_email: contact.secondary_email,
    linkedin_url: contact.linkedin_url,
    current_position: contact.current_position,
    image: contact.career_summary?.basics?.image,
  }), [contact]);

  return (
    <div className="contact-sidebar">
      <div className="contact-sidebar__collapse-button">
        <Tooltip label="Collapse Profile" withArrow position="top">
          <IconButton
            icon="bi bi-arrow-bar-right"
            mode="rounded"
            size="medium"
            handleClick={handleClose}
            outlined
          />
        </Tooltip>
      </div>
      <div className="contact-sidebar__actions">
        <Tooltip label="Archive" withArrow position="top">
          <IconButton
            icon="bi bi-archive"
            mode="rounded"
            size="small"
            handleClick={handleArchive}
            outlined
            isDangerous
          />
        </Tooltip>
        <Tooltip label="View Profile" withArrow position="top">
          <Link className="contact-sidebar__linkedin" to={contact.linkedin_url} target="_blank" rel="noreferrer">
            <IconButton
              icon="bi bi-linkedin"
              hoverIcon="bi bi-box-arrow-up-right"
              mode="rounded"
              size="small"
              outlined
            />
          </Link>
        </Tooltip>
      </div>
      <div className="contact-sidebar__summary">
        <ContactInfoTile
          onEmailChanged={onEmailChanged}
          contact={contactBasicInfo}
          openCompanyTracker={() => openTracker(contact.company_id)}
        />
      </div>
      <div className="contact-sidebar__statuses">
        <StatusTag status={connectionStatus} type="linkedin" withTooltip />
        <StatusTag status={networkingStatus} type="networking" />
      </div>
      <div className="contact-sidebar__tabs">
        <div className="contact-sidebar__separator" />
        <TabsHeader
          containerName="contact-sidebar"
          tabs={['Profile', 'Notes']}
          defaultTab="Profile"
          size="medium"
          icons={['bi bi-person-vcard', 'bi bi-file-earmark-text']}
        />
      </div>
      <div className="contact-sidebar__content">
        <TabsContent>
          <div className="contact-sidebar__tab" data-tab="Profile">
            <ContactSummary careerSummary={contact.career_summary} />
          </div>
          <div className="contact-sidebar__tab" data-tab="Notes">
            <ContactNotes />
          </div>
        </TabsContent>
      </div>
    </div>
  );
};

export default withTabs(ContactBioSidebar);
