import { Badge } from '@careeros/coco';
import { COMPANY_PARTNER_TYPE_UNIVERSITY_PARTNER, CompanyPartnerType } from '../../../company/types/company';
import { VerifiedBadge } from '../verified-badge/verified-badge';
import './job-badge.scss';

export const JobBadge = ({ jobBadgeType }: { jobBadgeType: CompanyPartnerType | null }) => {
  if (!jobBadgeType) return null;

  if (jobBadgeType === COMPANY_PARTNER_TYPE_UNIVERSITY_PARTNER) {
    return (
      <div className="job-badge">
        <Badge
          label="Exclusive Job Post"
          color="light-purple"
          icon="bi bi-check-circle"
          iconPosition="left"
        />
      </div>
    );
  }

  return (
    <VerifiedBadge
      label="Verified CareerOS Partner"
    />
  );
};
