import React, {
  createContext, useContext, useState, useCallback, ReactNode,
  useMemo, useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface Breadcrumb {
  name: string;
  link: string;
}

interface BreadcrumbContextType {
  breadcrumbs: Breadcrumb[];
  setBreadcrumbs: (crumbs: Breadcrumb[]) => void;
  resetBreadcrumbs: () => void;
}

const BreadcrumbContext = createContext<BreadcrumbContextType | undefined>(undefined);

export const BreadcrumbProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const staticCrumbs: Breadcrumb[] = [
    { name: 'CareerOS', link: '/' },
  ];

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>(staticCrumbs);

  const resetBreadcrumbs = useCallback(() => {
    setBreadcrumbs(staticCrumbs);
  }, []);

  const memoizedSetBreadcrumbs = useCallback((crumbs: Breadcrumb[]) => {
    setBreadcrumbs(crumbs);
  }, []);

  const updatePathBasedBreadcrumbs = useCallback(() => {
    const path = location.pathname;

    if (path.includes('/app/dashboard-advisor')) {
      setBreadcrumbs([
        { name: 'Dashboard', link: path },
      ]);
      return;
    }

    if (path.includes('/app/dashboard')) {
      setBreadcrumbs([
        { name: 'Home', link: path },
      ]);
      return;
    }

    if (path.includes('/app/articles')) {
      setBreadcrumbs([
        { name: 'Articles', link: path },
      ]);
      return;
    }

    if (path.includes('/app/companies/explore') || path.includes('/app/search')) {
      setBreadcrumbs([
        { name: 'Explore Companies', link: '/app/companies/explore' },
      ]);
      return;
    }

    if (path.includes('/app/companies/search')) {
      setBreadcrumbs([
        { name: 'Company search', link: path },
      ]);
      return;
    }

    if (path.includes('/app/jobs')) {
      setBreadcrumbs([
        { name: 'Jobs', link: path },
      ]);
      return;
    }

    if (path.includes('/app/overview')) {
      setBreadcrumbs([
        { name: 'Overview Board', link: path },
      ]);
      return;
    }

    if (path.includes('app/resume-os')) {
      setBreadcrumbs([
        { name: 'ResumeOS', link: '/app/resume-os' },
      ]);
      return;
    }

    if (path.includes('app/tour')) {
      setBreadcrumbs([
        { name: 'Tour', link: path },
      ]);
      return;
    }

    if (path.includes('app/inbox')) {
      setBreadcrumbs([
        { name: 'Inbox', link: path },
      ]);
      return;
    }

    if (path.includes('/app/favorites')) {
      setBreadcrumbs([
        { name: 'Saved Companies', link: path },
      ]);
      return;
    }

    if (path.includes('/app/company-overview')) {
      setBreadcrumbs([
        { name: 'Student Activity', link: path },
      ]);
      return;
    }

    if (path.includes('/app/profile') || path.includes('/app/documents')) {
      setBreadcrumbs([
        { name: 'My Account', link: path },
      ]);
      return;
    }

    if (path.includes('/app/students')) {
      setBreadcrumbs([
        { name: 'Student Table', link: path },
      ]);
      return;
    }

    if (path.includes('/app/support')) {
      setBreadcrumbs([
        { name: 'Support', link: path },
      ]);
      return;
    }

    if (path.includes('/app/archive')) {
      setBreadcrumbs([
        { name: 'Archive', link: path },
      ]);
      return;
    }

    if (path.includes('/app/contacts/campus-champions')) {
      setBreadcrumbs([
        { name: 'Contacts', link: '/app/contacts' },
        { name: 'Campus Champions', link: '/app/contacts/campus-champions' },
      ]);
      return;
    }

    if (path.includes('/app/contacts')) {
      setBreadcrumbs([
        { name: 'Contacts', link: '/app/contacts' },
      ]);
      return;
    }

    if (path.includes('/app/companies')) {
      setBreadcrumbs([
        { name: 'Explore Companies', link: '/app/companies/explore' },
        { name: 'Advanced Search', link: '/app/companies' },
      ]);
      return;
    }

    if (path.includes('/app/cohort-assignment')) {
      setBreadcrumbs([
        { name: 'Welcome to CareerOS!', link: path },
      ]);
      return;
    }

    if (path.includes('/app/advisor/students')) {
      setBreadcrumbs([
        { name: 'Students', link: path },
      ]);
      return;
    }

    // Default breadcrumb
    setBreadcrumbs(staticCrumbs);
  }, [location.pathname]);

  useEffect(() => {
    updatePathBasedBreadcrumbs();
  }, [updatePathBasedBreadcrumbs]);

  const contextValue = useMemo(() => ({
    breadcrumbs,
    setBreadcrumbs: memoizedSetBreadcrumbs,
    resetBreadcrumbs,
  }), [breadcrumbs, memoizedSetBreadcrumbs, resetBreadcrumbs]);

  return (
    <BreadcrumbContext.Provider value={contextValue}>
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbContext);
  if (!context) {
    throw new Error('useBreadcrumbs must be used within a BreadcrumbProvider');
  }
  return context;
};
