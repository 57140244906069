import { EnumAsUnion } from '@/services/helpers/types';
import './tag.scss';

export enum TagColors {
  LightGreen = 'light-green',
  LightPurple = 'light-purple',
  Pink = 'pink',
  Orange = 'orange',
  Blue = 'blue',
  Green = 'green',
  Red = 'red',
  LightBlue = 'light-blue',
  BlueGreen = 'blue-green',
  Grey = 'grey',
}

export type TagColorsType = EnumAsUnion<typeof TagColors>;

export type TagType = {
  id: string;
  label: string;
  color: TagColorsType;
  image?: string;
};

interface TagProps {
  label: string;
  color?: TagColorsType;
  image?: string;
  marker?: number | string;
  markerColor?: 'red' | 'yellow' | 'green';
  withUnreadIndicator?: boolean;
  handleClose?: () => void;
  handleClick?: React.MouseEventHandler<HTMLDivElement>;
}
/**
 * Primary UI component for tags
 */
export const Tag = ({
  label,
  color = 'light-purple',
  image,
  marker,
  markerColor,
  withUnreadIndicator,
  handleClick,
  handleClose,
}: TagProps) => {
  const isInteractive = handleClick || handleClose;

  const handleClickWrapper = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (handleClick) {
      e.stopPropagation();
      handleClick(e);
    }
  };

  return (
    <div
      data-testid="tag"
      onClick={handleClickWrapper}
      className={[
        'careerOS-tag',
        `careerOS-tag--color-${color}`,
        isInteractive ? 'careerOS-tag--clickable' : '',
      ].join(' ')}
    >
      {withUnreadIndicator && <div className="careerOS-tag__unread-indicator" />}
      {image && <img className="careerOS-tag__image" src={image} alt={label} />}
      {marker && <span className={`careerOS-tag__marker careerOS-tag__marker--${markerColor}`}>{marker}</span>}
      <span className="careerOS-tag__label">{label}</span>
      {handleClose && (
        <i
          data-testid="close-icon"
          onClick={(e) => {
            e.stopPropagation();
            handleClose();
          }}
          className="careerOS-tag__icon bi bi-x"
        />
      )}
    </div>
  );
};
