import { BadgeColor } from '@careeros/coco';
import { StudentStatus } from '@/domains/core/advisor/types';

type StudentProgressBadges = {
  [key in StudentStatus]: {
    color: BadgeColor,
    label: string;
    order: number;
  }
};

export const studentProgressBadges: StudentProgressBadges = {
  Inactive: {
    color: 'light-red',
    label: '💤 Inactive',
    order: 1,
  },
  'Companies Saved': {
    color: 'medium-grey',
    label: '🏢 Companies Saved',
    order: 2,
  },
  'Company Saved': {
    color: 'medium-grey',
    label: '🏢 Company Saved',
    order: 2,
  },
  Networking: {
    color: 'light-green',
    label: '💬 Networking',
    order: 3,
  },
  Applying: {
    color: 'yellow',
    label: '📩 Applying',
    order: 4,
  },
  Applied: {
    color: 'light-purple',
    label: '⏳ Applied',
    order: 5,
  },
  Interviewing: {
    color: 'light-blue',
    label: '👥 Interviewing',
    order: 6,
  },
  Offer: {
    color: 'dark-gradient',
    label: '📑 Offer',
    order: 7,
  },
  Rejected: {
    color: 'medium-grey',
    label: '⛔️ Rejected',
    order: 8,
  },
  Archived: {
    color: 'medium-grey',
    label: '📁 Archived',
    order: 9,
  },
};
