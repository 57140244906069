import { useMemo } from 'react';
import { Tooltip, DefaultLogo } from '@careeros/coco';
import './job-header.scss';
import { JobTagType } from '../../types/job';
import { Tag, TagColors } from '@/components/tag/tag';
import { getTagIcons } from '../../helpers/getTagIcons';
import { sortTags } from '@/services/helpers/job-feed';
import { JobHeaderCta } from '../job-header-cta/job-header-cta';
import { CompanyPartnerType } from '../../../company/types/company';
import { JobBadge } from '../job-badge/job-badge';

type Props = {
  handleApplyButtonClick: () => void;
  tags: JobTagType[];
  handleSaveButtonClick: () => void;
  jobBadgeType: CompanyPartnerType | null;
  logoUrl: string;
  companyName: string;
  postedDate: string;
  title: string;
  isApplied: boolean;
  isSaved: boolean;
  isIconCTA?: boolean;
  isHiddenApplyButton: boolean;
  handleCompanyClick?: () => void;
  handleShareClick?: () => void;
  isDisabled?: boolean;
};

export const JobHeader = ({
  handleApplyButtonClick: handleApply,
  tags,
  handleSaveButtonClick,
  jobBadgeType,
  logoUrl,
  companyName,
  postedDate,
  title,
  isSaved,
  isIconCTA = false,
  isApplied,
  isHiddenApplyButton,
  handleCompanyClick,
  handleShareClick,
  isDisabled = false,
}: Props) => {
  const sortedTags = useMemo(() => (tags ? sortTags(tags) : []), [tags]);

  return (
    <div className="job-header">
      <div className="job-header__main-info">
        <div className={`job-header__company-logo ${isDisabled ? 'job-header__company-logo--disabled' : ''}`}>
          <DefaultLogo
            source={logoUrl}
            type="company"
            name={companyName}
            className="job-header__company-logo"
            data-testid="job-logo"
            size={48}
          />
        </div>
        <div className={`job-header__job-details ${isDisabled ? 'job-header__job-details--disabled' : ''}`}>
          <div className="job-header__job-title" data-testid="job-title">
            <span className="job-header__job-title-text">{title}</span>
          </div>

          <div className="job-header__job-subtitle">
            <div
              className="job-header__job-company-name-container"
              onClick={handleCompanyClick}
            >
              <Tooltip label="View Company Profile" position="top" withArrow disableHoverListener={!handleCompanyClick}>
                <div className={`job-header__job-company-name ${handleCompanyClick ? 'job-header__job-company-name--with-hover' : ''}`}>
                  {companyName}
                </div>
              </Tooltip>
            </div>
            •
            <div>{postedDate}</div>
          </div>
        </div>
        <div className={`job-header__actions ${isIconCTA ? 'job-header__actions--gap-small' : ''}`}>
          <JobHeaderCta
            handleApplyButtonClick={handleApply}
            handleShareClick={handleShareClick}
            handleSaveButtonClick={handleSaveButtonClick}
            isSaved={isSaved}
            isIconCTA={isIconCTA}
            isApplied={isApplied}
            hiddenApplyButton={isHiddenApplyButton}
            isDisabled={isDisabled}
          />
        </div>
      </div>
      <div className={`job-header__actions job-header__actions--mobile ${isIconCTA ? 'job-header__actions--gap-small' : ''}`}>
        <JobHeaderCta
          handleApplyButtonClick={handleApply}
          handleSaveButtonClick={handleSaveButtonClick}
          handleShareClick={handleShareClick}
          isSaved={isSaved}
          isIconCTA={isIconCTA}
          isApplied={isApplied}
          hiddenApplyButton={isHiddenApplyButton}
          isDisabled={isDisabled}
        />
      </div>
      <div className="job-header__tags">
        <JobBadge jobBadgeType={jobBadgeType} />
        {sortedTags.map((tag) => (
          <Tag
            key={tag.tag_type}
            color={TagColors.Grey}
            label={tag.tag_content}
            image={getTagIcons(tag.tag_type)}
          />
        ))}
      </div>
    </div>
  );
};
