import { ApplicationProcess, CardWithBadges } from '@careeros/coco';
import { useMemo } from 'react';
import { Team } from '../../types/job';
import './job-teams-tab.scss';

interface TeamInformationProps {
  team: Team;
}

export const TeamInformation = ({ team }: TeamInformationProps) => {
  const workingStyles = useMemo(() => team.working_style.map(style => style.value), [team.working_style]);
  const applicationSteps = useMemo(
    () => team.application_steps?.map(step => ({
      step_name: step.step_name,
      step_description: step.step_description,
      step_order: step.step_order,
    })) || [],
    [team.application_steps],
  );

  return (
    <div className="job-teams-tab__team">
      <CardWithBadges
        title={team.name}
        badges={team.skills || []}
        description={team.description}
      />

      <CardWithBadges
        title="Working Style"
        badges={workingStyles || []}
      />

      <CardWithBadges
        title="Languages Spoken"
        badges={team.languages_spoken || []}
      />

      <ApplicationProcess application_steps={applicationSteps} />
    </div>
  );
};
