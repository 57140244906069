import { Link } from 'react-router-dom';
import './industry-filter.scss';
import { useEffect, useState } from 'react';
import { Tooltip } from '@careeros/coco';

interface FilterProps {
  label: string;
  icon: string;
  link: string;
  active: boolean;
  expanded: boolean;
}

/**
 * Primary UI component for the filter button
 */
export const FilterButton = ({
  label, icon, link, active, expanded,
}: FilterProps) => {
  const [image, setImage] = useState('');
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await import(`../../../../../assets/images/icons/industries/${icon}.png`); // TO-DO this is not good, makes it not reusable
        setImage(response.default);
      } catch (err) {
        console.log(err);
      }
    };

    fetchImage();
  }, [icon]);
  return (
    <Tooltip label={label} position="top" disableHoverListener={expanded}>
      <Link
        className={`filter-button ${active ? 'filter-button--active' : ''} ${expanded ? '' : 'filter-button--collapsed'}`}
        to={link}
      >
        <span className="filter-button__icon">
          {image && <img src={image} alt="" />}
        </span>
        <span className={`filter-button__label ${expanded ? '' : 'filter-button__label--hidden'}`}>
          {label}
        </span>
      </Link>
    </Tooltip>
  );
};
