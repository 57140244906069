import { useMemo, useState } from 'react';
import {
  Button, Checkbox, IconButton, Input, Chip,
} from '@careeros/coco';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { JobsFilterState, JobsLocationsType } from '@/domains/core/job/types/job';
import { FilterOption } from '@/domains/core/company/types';
import './mobile-job-filters.scss';
import { DropdownSearchStatic } from '@/components/dropdown-search-static/dropdown-search-static';
import { UniversityResponse } from '@/services/api/student';

type Props = {
  filters: JobsFilterState;
  staticOptions: Record<string, FilterOption[]>;
  jobsLocations: JobsLocationsType[];
  handleClose?: () => void;
  setFilters: (filters: JobsFilterState) => void;
  university: UniversityResponse | undefined;
};

export const MobileJobFilters = ({
  filters,
  staticOptions,
  jobsLocations,
  handleClose,
  setFilters,
  university,
}: Props) => {
  const [localFilters, setLocalFilters] = useState<JobsFilterState>(filters);
  const {
    locations,
    contract,
    postedDate,
    workType,
    industries,
    sizes,
    languages,
  } = staticOptions;

  const onFilterChange = (
    name: keyof typeof filters,
    value: string | string [] | FilterOption | FilterOption[],
  ) => {
    setLocalFilters({
      ...localFilters,
      [name]: value,
    });
  };

  const locationOptions = useMemo(
    () => localFilters.locations?.reduce<string[]>((acc, location) => {
      if (typeof location.name === 'string') {
        acc.push(location.name);
      }
      return acc;
    }, []) || [],
    [localFilters.locations],
  );

  const mappedLocations = useMemo(() => locations
    ?.filter(location => !localFilters.locations.some(loc => loc.name === location.name))
    .map(location => ({
      name: typeof location.name === 'string' ? location.name : '',
      value: location.value,
    })), [locations, localFilters.locations]);

  const handleLocationSelect = (name: string, value: any) => {
    const selectedLocation = jobsLocations.find(location => (location.city ? `${location.city}, ${location.country}` === name : location.country === name));

    if (selectedLocation) {
      const locationExists = localFilters.locations.some(loc => loc.name === name);

      const newLocations = locationExists
        ? localFilters.locations.filter(loc => loc.name !== name)
        : [...localFilters.locations, { name, value: JSON.stringify(value) }];

      setLocalFilters(prevFilters => ({
        ...prevFilters,
        locations: newLocations,
      }));
    }
  };

  const handleReset = () => {
    setLocalFilters({
      query: '',
      sizes: [],
      job_types: [],
      work_modes: [],
      industries: [],
      locations: [],
      date_range: [],
      languages: [],
      favoriteCompanies: false,
      byUniversityPartners: [],
      byCareerOSPartners: [],
      required_languages: [],
    });
  };

  return (
    <div className="mobile-job-filters">
      <div className="mobile-job-filters__wrapper">
        <div className="mobile-job-filters__top">
          <div className="mobile-job-filters__header">All Filters</div>
          <div className="mobile-job-filters__content">
            <div className="mobile-job-filters__filters">
              <Input
                value={localFilters.query}
                handleValueChange={(value) => onFilterChange('query', value)}
                placeholder="Search jobs"
                label=""
                name="filter-search-bar"
                id="filter-search-bar"
                data-testid="filter-search-bar"
                icon="bi bi-search"
                isClearable
              />
              <DropdownSearchStatic
                selectedOption={locationOptions}
                options={mappedLocations}
                handleItemSelect={(item, value) => handleLocationSelect(item, JSON.parse(value))}
                placeholder="Search office locations"
                name="loaction-search"
                id="location-search"
                inputIcon="bi bi-geo-alt"
                size="medium"
                canAddItems={false}
                showIcon={false}
                withMultipleSelection
                openOnClick
                listWidth="full"
              />

              {locationOptions.length > 0 && (
                <div className="mobile-job-filters__location-tags">
                  {localFilters.locations.map((location) => (
                    <Chip
                      key={`location-${location.name}`}
                      label={location.name as string}
                      type="location"
                      handleClose={() => handleLocationSelect(location.name as string, JSON.parse(location.value as string))}
                    />
                  ))}
                </div>
              )}

              <MultiSelect
                labelType="list"
                handleSelectedOptions={(value) => onFilterChange('languages', value)}
                placeholder="Job Ad Language"
                selected={localFilters.languages}
                options={languages}
                width="fit-box"
                size="full"
              />

              <MultiSelect
                labelType="list"
                handleSelectedOptions={(value) => onFilterChange('industries', value)}
                placeholder="Industry"
                selected={localFilters.industries}
                options={industries}
                size="full"
              />

              <MultiSelect
                labelType="list"
                handleSelectedOptions={(value) => onFilterChange('sizes', value)}
                placeholder="Company Size"
                selected={localFilters.sizes}
                options={sizes}
                size="full"
              />

              <MultiSelect
                labelType="list"
                handleSelectedOptions={(value) => onFilterChange('job_types', value)}
                placeholder="Contract"
                selected={localFilters.job_types}
                options={contract}
                width="fit-box"
                size="full"
              />

              <MultiSelect
                labelType="list"
                handleSelectedOptions={(value) => onFilterChange('work_modes', value)}
                placeholder="Work Type"
                selected={localFilters.work_modes}
                options={workType}
                width="fit-box"
                size="full"
              />

              <MultiSelect
                labelType="list"
                handleSelectedOptions={(value) => onFilterChange('date_range', value)}
                placeholder="Date Posted"
                selected={filters.date_range}
                options={postedDate}
                width="fit-box"
                size="full"
              />

              <Checkbox
                id="university-jobs"
                label={`Show only ${university?.name} exclusive jobs`}
                checked={localFilters.byUniversityPartners.length > 0 && localFilters.byUniversityPartners[0].value === true}
                onChange={(checked: boolean) => {
                  onFilterChange('byUniversityPartners', checked ? [{ name: `${university?.name} exclusive jobs`, value: true }] : []);
                }}
              />
              <Checkbox
                id="exclusive-jobs"
                label="Show only jobs exclusive to CareerOS"
                checked={localFilters.byCareerOSPartners.length > 0 && localFilters.byCareerOSPartners[0].value === true}
                onChange={(checked: boolean) => {
                  onFilterChange('byCareerOSPartners', checked ? [{ name: 'CareerOS exclusive ', value: true }] : []);
                }}
              />
            </div>
          </div>
        </div>
        <div className="mobile-job-filters__bottom">
          <Button
            mode="primary"
            outlined
            size="fill"
            label="Reset"
            icon="bi bi-arrow-repeat"
            handleClick={handleReset}
          />
          <Button
            mode="primary"
            size="fill"
            label="Apply All"
            icon="bi bi-funnel"
            handleClick={() => {
              setFilters(localFilters);
              handleClose?.();
            }}
          />
        </div>
        <div className="mobile-job-filters__close-button">
          <IconButton
            mode="unset"
            icon="bi bi-x"
            size="medium"
            handleClick={handleClose}
          />
        </div>
      </div>
    </div>
  );
};
