import { useState } from 'react';
import { CategoryWidget, CategoryWidgetSkeleton, CompanyCard } from '@/domains/core/company';
import { useRelatedCompanies } from '@/services/hooks/use-algolia';
import { useApplicationsNoCache } from '@/services/queries/application';

// Define the RelatedCompanyItem component outside the render function
const RelatedCompanyItem = ({
  item,
}: {
  item: any;
}) => {
  // These props would normally be passed from the parent component
  // but for simplicity, we're using them directly in the component
  const { data: applications = [] } = useApplicationsNoCache();
  const [hasNotSavedCompanies, setHasNotSavedCompanies] = useState(false);

  return (
    <CompanyCard
      key={item.id}
      id={item.id}
      companyName={item.name}
      companyIndustry={item.careeros_industry}
      officeLocations={[item.location]}
      companySize={item.size}
      companyDescription={item.overview}
      imageURL={item.logo_url}
      tags={item.tags}
      hasSavedAnimation={hasNotSavedCompanies}
      source="related-companies"
      setHasNotSavedCompanies={() => setHasNotSavedCompanies(true)}
      companyApplicationId={applications?.find((application) => application.company_id === item.id)?.id || ''}
    />
  );
};

export function RelatedCompaniesWrapper({ companyId }: { companyId: string }) {
  const { relatedCompanies, isLoading } = useRelatedCompanies(companyId);

  if (isLoading) {
    return (
      <CategoryWidgetSkeleton />
    );
  }

  if (!relatedCompanies.length) {
    return null;
  }

  return (
    <CategoryWidget
      title="Similar Companies"
      subtitle="Companies you might also be interested in"
      exploreMoreLink="/app/companies"
    >
      {relatedCompanies.map((company: any) => (
        <RelatedCompanyItem
          key={company.id || company.objectID}
          item={company}
        />
      ))}
    </CategoryWidget>
  );
}
