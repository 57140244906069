import {
  ApiStudent,
} from '@/domains/core/advisor/types/api/student';
import { StudentModel } from '@/domains/core/advisor/types';
import { convertFromApiStudents, convertFromApiStudentsForCompany } from '@/domains/core/advisor/api/model/student';
import { http } from '@/services/api/http';

export const getStudents = async (universityID: string, lastViewedAt: Date): Promise<StudentModel[]> => {
  const url = `/university/students?last_viewed_at=${encodeURIComponent(lastViewedAt.toISOString())}`;

  const res: ApiStudent[] = await http.get(url, 'v2');

  return convertFromApiStudents(res);
};

export const getStudentsByCompany = async (universityID: string, lastViewedAt: Date, companyID: string): Promise<StudentModel[]> => {
  const url = `/university/students?last_viewed_at=${encodeURIComponent(lastViewedAt.toISOString())}&company_id=${companyID}`;

  const res: ApiStudent[] = await http.get(url, 'v2');

  return convertFromApiStudentsForCompany(res);
};

export const sendMassEmail = async (message: string, subject: string, emails: string[], column?: string, cohort_name?: string): Promise<any> => http.post('/university/advisor/sendmail', {
  body: message,
  subject,
  emails,
  column,
  cohort_name,
}, undefined, 'v2');

export const getSentEmailLog = async (universityID: string): Promise<any> => http.get(`/university/advisor/sentemails/${universityID}`, 'v2');
