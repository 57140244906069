import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Select } from '@careeros/coco';
import { CohortLinkDataType } from '../../types';
import './cohorts-navigation.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  links: CohortLinkDataType[];
  defaultLinkName?: string;
  trackEventText?: string;
};

export const CohortsNavigation = ({
  links, defaultLinkName, trackEventText,
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const activeLinkName = useMemo(() => {
    const activeLink = links.find((link) => location.pathname === link.url);
    return activeLink?.label || defaultLinkName;
  }, [links, defaultLinkName, location.pathname]);

  const mappedOptions = useMemo(() => links.map((link) => link.label), [links]);

  const handleValueChange = (value: string) => {
    const selectedLink = links.find(link => link.label === value);
    if (selectedLink) {
      navigate(selectedLink.url);
    }
    if (trackEventText) {
      trackEvent(trackEventText);
    }
  };

  return (
    <div className="cohorts-navigation">
      <Select
        id="cohorts-navigation"
        value={activeLinkName || ''}
        options={mappedOptions}
        placeholder="Search Cohort"
        handleValueChange={handleValueChange}
        showIcon={false}
      />
    </div>
  );
};
