import { Job } from '../../company/types';

export type Work = {
  [index: string]: any;
  description?: string;
  endDate?: string;
  highlights?: string[];
  location?: string | null;
  name?: string;
  position?: string;
  startDate?: string;
  summary?: string | null;
  url?: string;
  id?: string;
  ai_highlights?: string[];
};

export type ResumeSection = 'education' | 'work';

export type Education = {
  [index: string]: any;
  area?: string;
  courses?: string[];
  endDate?: string;
  score?: string;
  institution?: string;
  startDate?: string;
  studyType?: string;
  url?: string;
  id?: string;
  location?: string;
  description?: string[];
};

export type ExtracurricularActivity = {
  [index: string]: any;
  description?: string[];
  startDate?: string;
  endDate?: string;
  location?: string;
  institution?: string;
  role?: string;
};

export interface Profile {
  [index: string]: any;
  network?: string;
  url?: string;
  username?: string;
}

export interface Location {
  [index: string]: any;
  address?: string;
  city?: string;
  countryCode?: string;
  postalCode?: string;
  region?: string;
}

export type Basics = {
  [index: string]: any;
  emails: string[];
  image?: string;
  label?: string;
  location?: Location | null;
  name: string;
  phone_numbers?: string[];
  profiles: Profile[];
  summary?: string | null;
  summaryTitle?: string | null;
  url?: string;
  nationality?: string[];
  work_permit?: string[];
  birth_date?: string;
  profile_image?: string;
  profile_image_url?: string;
};

export type Skill = {
  [index: string]: any;
  keywords?: string[];
  level?: string;
  name?: string;
};

export type Language = {
  [index: string]: any;
  fluency?: string;
  language?: string;
};

export type Award = {
  [index: string]: any;
  awarder?: string;
  date?: string;
  summary?: string;
  title?: string;
};

export type Interest = {
  [index: string]: any;
  keywords?: string[];
  name?: string;
};

export type Publication = {
  name?: string;
  publisher?: string;
  releaseDate?: string;
  summary?: string;
  website?: string;
};

export type Reference = {
  name?: string;
  reference?: string;
};

export type Volunteer = {
  endDate?: string;
  highlights?: string[];
  organization?: string;
  position?: string;
  startDate?: string;
  summary?: string;
  website?: string;
};

export type Certification = {
  name: string;
  date: string;
  issuer: string;
  url: string;
};

export type Project = {
  name: string;
  startDate: string;
  endDate: string;
  description: string;
  highlights: string[];
  url: string;
};

export type AiPayloadSchema = {
  basics: Basics;
  job: Job;
  work: Work;
};

export type HighlightWord = {
  word: string;
  type: 'positive' | 'negative';
  started_at?: number;
  ended_at?: number;
  listIndex?: number;
};

export type AnalysisItem = {
  started_at: number;
  ended_at: number;
};

export type AnalysisResult = {
  items: AnalysisItem[];
  result: {
    is_done: boolean;
  };
};

export type SuggestionSection = {
  [key: string]: AnalysisResult;
};

export type Suggestions = {
  work: SuggestionSection[][];
  additional_information: SuggestionSection[];
  skills: {
    is_done: boolean;
  };
};

export type HighlightWords = {
  work: HighlightWord[][];
  additional_information: HighlightWord[];
  skills: {
    is_done: boolean;
  } | null;
};

export type ResumeComment = {
  text: string;
  author_first_name: string;
  author_image: string;
  author_last_name: string;
  id: string;
  created_at: string;
  updated_at: string;
  resolved: boolean;
  kind: string;
};

export type ResumeDownloadResult = {
  file_name: string;
  resume_book_url: string;
  error?: string;
};

export type WorkOrderItem = {
  type: 'work' | 'heading';
  value: Work | string;
};

export type WorkSection = {
  order: WorkOrderItem[];
  subheadings: string[];
  entries: Work[];
};

export type ResumeSchema = {
  [key: string]: any;
  basics: Basics;
  education: Education[];
  languages: Language[];
  skills: Skill[];
  work: Work[];
  work_section: WorkSection;
  work_section_subheading?: string;
  skills_subheading?: string;
  additional_information: AdditionalInformation;
  extracurricular_activities: ExtracurricularActivity[];
  certificates: Certification[];
};

export type AdditionalInformation = {
  bullets: string[];
  gmatscore: string | null;
  subheadings: string[];
  order: { type: string, value: string }[];
};

export type ResumeTemplates = {
  id: string;
  name: TemplateNames;
  preview_image: string;
  created_at: string;
  updated_at: string;
  university_id?: string;
};

export type ResumeResponse = {
  id: string;
  resume: ResumeSchema;
  user_id: string;
  title: string;
  job?: Job;
  resume_template: ResumeTemplates;
  source_url: string;
  preview_url: string
  created_at: string;
  updated_at: string;
  status?: string;
};

export type ProfilePictureResponse = {
  profile_picture_url: string;
};

export type ResumeBuilderResponse = {
  jobs: Job[];
};

export type BaseResumeListingResponse = {
  id: string;
  title: string;
  resume_template: ResumeTemplatePreview;
  resume: ResumeSchema;
  preview_url: string;
  source_url: string;
  job?: Job;
  is_valid: boolean;
  comments_count: number;
  created_at: string;
  updated_at: string;
  score: number;
  status?: string;
};

type ResumeTemplatePreview = {
  id: string;
  university_id: string;
  name: string;
  preview_image: string;
  created_at: string;
  updated_at: string;
};

export type TailoredResumeListingResponse = {
  id: string;
  job: Job;
  base_resume_id: string
  resume: ResumeSchema;
  name: string;
  comments_count: number;
  created_at: string;
  updated_at: string;
  preview_url: string;
  source_url: string;
  title: string;
  resume_template: ResumeTemplatePreview;
  is_valid: boolean;
  score: number;
  status?: string;
};

export type ResumesResponse = {
  baseResumes: BaseResumeListingResponse[];
  tailoredResumes: TailoredResumeListingResponse[];
};

export type ResumesIDResponse = {
  resume: Omit<ResumeSchema, 'work_section'>;
};

export type ActionVerbsResponse = {
  action_verbs: string[];
};

export type ResumeOSRelevantContextResponse = ActionVerbsResponse;

export type OverallLengthSection = {
  number_of_positions: ScoreFeedbackObject
  number_of_total_bullets: ScoreFeedbackObject;
  number_of_total_words: ScoreFeedbackObject;
  percentage_score: number;
};
export type BulletsBalanceSection = {
  average_bullets_per_position: ScoreFeedbackObject;
  average_words_per_bullet: ScoreFeedbackObject;
  percentage_score: number;
};
export type BulletsQualitySection = {
  percentage_of_strong_action_verbs: ScoreFeedbackObject;
  percentage_of_quantify_outcomes: ScoreFeedbackObject;
  percentage_of_bullets_starting_with_strong_action_verbs: ScoreFeedbackObject;
  percent_score: number;
};

export type ScoreFeedbackObjectFragment = {
  text: string;
  anchor: string;
};

type ScoreFeedbackObject = {
  feedback: string;
  value: number;
  percentage_score: number;
  fragments: ScoreFeedbackObjectFragment[];
};

export type SkillsSection = {
  number_of_total_skills_listed: ScoreFeedbackObject,
  weight: number;
  percent_score: number;
};

export type TemplateNames = 'ESMT Standard' | 'Modern Simple' | 'Hult Masters' | 'Rice' | 'Modern Minimalist' | 'Georgia' | 'Compliant with AI-powered ATS' | 'AMS' | 'ASB' | 'Bayes' | 'Hult EMBA' | 'Hult MBA' | 'Hult BBA' | 'Hult MBAN' | 'Maryland MBA' | 'Maryland MS' | 'Maryland UG' | 'HHL 2 Page - Education first' | 'HHL 2 Page - Work first' | 'HHL 1 Page' | 'MBS MBA' | 'MBS Masters' | 'MBS Bachelors' | 'EBS' | 'EBS (2 pager)';

export type ConsistencySection = {
  weight: number;
  percent_score: number;
  all_bullets_end_with_period_or_none: ScoreFeedbackObject;
  overuse_of_action_verbs_in_bullets: ScoreFeedbackObject;
  avoided_words_used_in_bullets: ScoreFeedbackObject;
  all_bullets_start_with_capital_letter: ScoreFeedbackObject;
};

export type BaseResumeScoreResponse = {
  overall_length: OverallLengthSection;
  bullets_balance: BulletsBalanceSection;
  bullets_quality: BulletsQualitySection;
  skills: SkillsSection
  consistency: ConsistencySection;
  score: number;
  suggestion: any;
};

export type ResumeErrors = {
  [key: string]: any;
  basics?: Partial<Record<keyof Basics, string>>;
  education?: Partial<Record<keyof Education, string>>[];
  educationMin?: string;
  work?: Partial<Record<keyof Work, string>>[];
  workMin?: string;
  workHighlights?: string;
  skills?: Partial<Record<keyof Skill, string>>[];
  skillsMin?: string;
  languagesMin?: string;
  languages?: Partial<Record<keyof Language, string>>[];
  certificates?: Partial<Record<keyof Certification, string>>[];
};

export type Sections = 'work' |
'additionalInformation' |
'basics' |
'education' |
'skills' |
'languages' |
'extracurricularActivities' |
'certificates';

export type SectionScoreName = 'overall_length' | 'bullets_balance' | 'bullets_quality' | 'skills' | 'consistency';

export type ResumeBookParams = {
  cohort_ids: string[];
  resume_template_id: string;
  score: number;
  more_than_one_preference: 'HIGHEST_SCORE' | 'RESENT',
  order_by: 'RANDOM' | 'TITLE';
};

export type CareerAdvisorsResponse = {
  id: string;
  first_name: string;
  last_name: string;
  profile_picture_url: string;
  email?: string;
};

export enum ResumeStatuses {
  ReadyForReview = 'READY_FOR_REVIEW',
  Approved = 'APPROVED',
  ReviewRequested = 'REVIEW_REQUESTED',
}

export type ResumeStatus = `${ResumeStatuses}`;

export type CADropdownOption = {
  id: string;
  name: string;
  value: string;
  logo?: string;
};
