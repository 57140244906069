import {
  About,
  Benefits, Button, CardWithBadges, CompanyMedia, CompanyProfileHeader, Faqs, IconButton, Locations,
} from '@careeros/coco';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth0 } from '@auth0/auth0-react';
import {
  Company, CompanyCulture, CompanyEvents, CompanyFeatures, OverviewInformation, PremiumCompanyGeneralInformation,
  Team,
} from '@/domains/core/company/types';
import '../styles/company.scss';
import '../styles/public-company.scss';
import { TabsContent, TabsHeader } from '@/components/tabs';
import {
  CompanyEventsContainer, CompanyCultureContainer,
  CompanyTeamsContainer,
} from '@/domains/core/company';
import { ModalContext } from '@/components/modal/modal-provider';
import { TopbarDumb } from '../layout/topbar/topbar-dumb';
import { StudentNavbarView } from '../layout/navbar/components/navbar-view/navbar-view';
import { NavbarItemName } from '../layout/navbar/types/types';
import { setLatestUrlToStorage } from '@/services/helpers/browser-storage';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { PublicCompanySEO } from '@/services/seo/public-company';
import { Loader } from '@/components/loader/loader';
import { useTabs } from '@/components/tabs/TabsContext';
import { useAnalytics } from '@/services/hooks/use-analytics';

type PublicProfileProps = {
  company: Company;
  companyOverview: OverviewInformation;
  companyFeatures: CompanyFeatures;
  generalInformation: PremiumCompanyGeneralInformation;
  events: CompanyEvents[];
  culture: CompanyCulture;
  testimonials: any; // we dont know the response yet
  teams: Team[];
  articlesUrlProfile?: string;
};

const mapsKey = import.meta.env.VITE_GOOGLE_MAPS_ID || '';
const DEFAULT_TABS = ['Overview'];

const PublicCompanyProfile = ({
  companyOverview, company, companyFeatures, generalInformation, events, culture, testimonials, teams, articlesUrlProfile,
}: PublicProfileProps) => {
  const [tabs, setTabs] = useState(DEFAULT_TABS);
  const {
    isAuthenticated,
  } = useAuth0();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoginBoxHighlighted, setIsLoginBoxHighlighted] = useState(false);
  const { openModal } = useContext(ModalContext) as any;
  const { addToast }: AddToastType = useContext(ToastContext);
  const { setCurrentTab } = useTabs();
  const navigate = useNavigate();
  const loginBoxRef = useRef<HTMLDivElement>(null);
  const { trackEvent } = useAnalytics();

  const [searchParams, setSearchParams] = useSearchParams();

  const openEvent = (id: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('eventID', id);
    newSearchParams.set('company-page-tab', 'Events'); // Ensure we're on Events tab
    setSearchParams(newSearchParams);
    trackEvent('Event Tile Profile Click', {}, {
      pageURL: window.location.href,
      eventID: id,
      companyID: company.id,
    });
  };

  const closeEvent = () => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.delete('eventID');
    // Keep the current tab
    setSearchParams(newSearchParams);
  };

  const handleLoginClick = () => {
    setLatestUrlToStorage(`${window.location.origin}/app/companies/company/${company.id}`);
    navigate('/with-university');
  };

  const highlightLoginBox = () => {
    setCurrentTab(tabs[0]);
    if (!loginBoxRef.current) {
      return;
    }

    const loginScrollTop = (loginBoxRef.current?.getBoundingClientRect().top || 0) + window.scrollY - 20;

    window.scrollTo({ top: loginScrollTop, behavior: 'smooth' });
    setIsLoginBoxHighlighted(true);

    setTimeout(() => setIsLoginBoxHighlighted(false), 3000);
  };

  const handleShareClick = () => {
    const companyName = generalInformation.linkedin_url.split('company/')[1];
    navigator.clipboard.writeText(`${window.location.origin}/company/${companyName}`);
    addToast({
      type: 'success',
      message: 'Company URL has been copied',
      additionalMessage: 'Share it with intrested parties.',
    });
  };

  const handleTabClick = (tab: string) => {
    trackEvent('Profile Tab Click', {}, {
      pageURL: window.location.href,
      profileTab: tab,
      companyID: company.id,
    });
  };

  const handleTeamClick = () => {
    trackEvent('Profile Tab Click', {}, {
      pageURL: window.location.href,
      profileTab: 'Teams',
      companyID: company.id,
    });
  };

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const defaultRoute = `/app/companies/company/${company.id}`;
    navigate(defaultRoute);
  }, [isAuthenticated]);

  useEffect(() => {
    if (tabs.length > 1) return;
    const newTabs = DEFAULT_TABS;
    // TODO uncomment when we have the components
    if (culture) {
      newTabs.push('Culture');
    }
    if (testimonials) {
      newTabs.push('Testimonials');
    }
    if (companyFeatures.show_teams && teams && teams?.length > 0) {
      newTabs.push('Teams');
    }
    if (events && events?.length > 0) {
      newTabs.push('Events');
    }
    if (articlesUrlProfile) {
      newTabs.push('Articles');
    }
    newTabs.push('Jobs');

    setTabs([...newTabs]);
  }, []);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      if (target.closest('.sidebar-link')) {
        e.preventDefault();
        highlightLoginBox();
      }
    };

    // Add event listener
    document.addEventListener('click', handleClick);

    // Cleanup function to remove event listener
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);

  if (!company) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  const showInfoSection = companyOverview.benefits_custom || (companyOverview.benefits && companyOverview.benefits.length > 0) || companyOverview.upload_photos_videos || companyOverview.office_locations || (companyOverview.faq_questions && companyOverview.faq_questions.length > 0);

  return (
    <div className="public-company">
      <PublicCompanySEO
        description={generalInformation.overview}
        company={company}
        imageUrl={company.bg_image_url || company.logo_url}
      />
      <StudentNavbarView
        collapsed={isCollapsed}
        isCookieBannerVisible={false}
        showContent
        logoLink="/with-university"
        links={[
          {
            label: NavbarItemName.Home,
            url: '/',
            icon: 'bi bi-house-door',
            iconSelected: 'bi bi-house-door-fill',
            order: 1,
            isActive: false,
          },
          {
            label: NavbarItemName.OverviewBoard,
            url: '/with-university',
            icon: 'bi bi-grid-1x2',
            iconSelected: 'bi bi-grid-1x2-fill',
            order: 2,
            isActive: true,
          },
          {
            label: NavbarItemName.Inbox,
            url: '/with-university',
            icon: 'bi bi-chat',
            iconSelected: 'bi bi-chat-fill',
            order: 3,
            isActive: true,
          },
          {
            label: NavbarItemName.Contacts,
            url: '/with-university',
            icon: 'bi bi-person-vcard',
            iconSelected: 'bi bi-person-vcard-fill',
            order: 4,
            isActive: true,
          },
          {
            label: NavbarItemName.Jobs,
            url: '/with-university',
            icon: 'bi bi-briefcase',
            iconSelected: 'bi bi-briefcase-fill',
            order: 5,
            isActive: true,
          },
          {
            label: NavbarItemName.Companies,
            url: '/with-university',
            icon: 'bi bi-building',
            iconSelected: 'bi bi-building-fill',
            order: 6,
            isActive: true,
          },
          {
            label: NavbarItemName.ResumeOS,
            url: '/with-university',
            icon: 'bi bi-file-earmark',
            iconSelected: 'bi bi-file-earmark-fill',
            order: 6,
            isActive: true,
          },
        ]}
        handleToggle={() => {
          setIsCollapsed(!isCollapsed);
        }}
        handleLinkClick={(ev: any) => {
          ev.preventDefault();
        }}
      />
      <div className="topbar-wrapper">
        <TopbarDumb
          title=""
          withSearch={false}
          withProfile={false}
          isLoggedIn={false}
          handleLoginClick={handleLoginClick}
        />
      </div>
      <div className="public-company__content">
        <CompanyProfileHeader
          name={company.name}
          logoImage={company.logo_url}
          location={company.hq_location}
          coverImage={company.bg_image_url || ''}
          isVerified
          headerControl={<IconButton mode="primary" outlined size="large" icon="bi bi-share" handleClick={handleShareClick} />}
        />
        <div className="company-page-premium__content">
          <div className="company-page-premium__tabs">
            <TabsHeader
              tabs={tabs}
              size="medium"
              withBorder
              defaultTab={searchParams.get('company-page-tab') || tabs[0]}
              containerName="basic-company"
              onTabClick={handleTabClick}
            />
          </div>
          <TabsContent>
            <div data-tab="Overview">
              <div className="company-status-container">
                <div className="company-status-container__tracker-container">
                  <div className={`progress-tracker progress-tracker--full ${isLoginBoxHighlighted ? 'progress-tracker--highlighted' : ''}`} ref={loginBoxRef}>
                    <h3 className="progress-tracker__title">
                      Your application progress
                    </h3>
                    <p className="progress-tracker__text">
                      Start working on your dream career at
                      {' '}
                      {company.name}
                      . We will guide you every step along the way, including suggested networking contacts, tips for success and a game plan checklist that will prepare and propel you towards your dream job.
                    </p>
                    <Button mode="primary" handleClick={handleLoginClick} iconPosition="left" icon="bi bi-box-arrow-in-right" label="Log in to CareerOS" size="medium" />
                  </div>
                </div>
              </div>
              <div className="company-page-premium__overview">
                {showInfoSection && (
                  <section className="company-page-premium__info-section">
                    {(companyOverview.benefits_custom || companyOverview.benefits.length > 0) && companyFeatures.show_benefits
                      ? <Benefits badges={companyOverview.benefits} description={companyOverview.benefits_custom} />
                      : null}

                    {companyOverview.upload_photos_videos && companyFeatures.show_overview_media ? <CompanyMedia media={companyOverview.upload_photos_videos} openModal={openModal} /> : null}
                    {companyOverview.office_locations && companyFeatures.show_office_locations && <Locations locations={companyOverview.office_locations} googleMapsApiKey={mapsKey} />}
                    {companyFeatures.show_office_perks && <CardWithBadges title="Office Perks" description={companyOverview.office_perks_custom} badges={companyOverview.office_perks} />}
                    {companyOverview.faq_questions && companyOverview.faq_questions.length > 0 && companyFeatures.show_faq ? <Faqs faqs={companyOverview.faq_questions} companyName={company.name} /> : null}
                  </section>
                )}
                <section className={`company-page-premium__about-section ${showInfoSection ? '' : 'company-page-premium__about-section--full'}`}>
                  <About generalInformation={{
                    ...generalInformation,
                    location: generalInformation.hq_location,
                    size: generalInformation.company_size,
                    description: generalInformation.overview,
                    industry: generalInformation.careeros_industry,
                    additional_links: generalInformation.additional_links || [],
                  }}
                  />
                </section>
              </div>
            </div>
            <div className="company-page__events" data-tab="Events">
              <CompanyEventsContainer
                events={events}
                eventID={searchParams.get('eventID')}
                companyName={company.name}
                companyLogo={company.logo_url}
                handleOpenEvent={openEvent}
                handleCloseEvent={closeEvent}
              />
            </div>
            <div className="company-page__articles" data-tab="Articles">
              <div className="articles-container">
                <iframe
                  src={`${articlesUrlProfile}`}
                  className="articles-iframe"
                  title="Articles"
                />
              </div>
            </div>
            <div className="company-page__culture" data-tab="Culture">
              <CompanyCultureContainer culture={culture} features={companyFeatures} />
            </div>
            <div className="company-page__teams" data-tab="Teams">
              <CompanyTeamsContainer onTeamClick={handleTeamClick} teams={teams || []} />
            </div>
            <div className="company-page__jobs--public" data-tab="Jobs">
              <div className="company-page__jobs__emoji">🧑‍💻</div>
              Log in to CarrerOS to explore exclusive job opportunities at
              {' '}
              {company.name}
              <div id="company-page__jobs__button" className="company-page__jobs__button">
                <Button
                  mode="primary"
                  size="large"
                  outlined
                  isFullWidth
                  label="Log in to CarrerOS"
                  handleClick={handleLoginClick}
                />
              </div>
            </div>
          </TabsContent>
        </div>
      </div>
    </div>
  );
};

export default PublicCompanyProfile;
