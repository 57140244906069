import { IconButton, Tooltip, DefaultLogo } from '@careeros/coco';
import { Column, TableRow } from '@/components/table/table-types';
import {
  ConnectionStatus, Contact, contactAPIstatuses, ContactStatus, ContactWithNetworkingData,
  SuggestedContactTypeWithNetworkingData,
} from '../../contact/types';
import { router } from '@/main';
import { StatusTag } from '../../contact/components/status-tags/status-tag';
import { DemandTag } from '../../contact/components/demand-tag/demand-tag';

export const ContactsTableColumns: Column[] = [
  {
    label: 'Name', accessor: 'full_name', sortable: true, type: 'link', isCentered: false,
  },
  {
    label: 'Company', accessor: 'company', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Job Title', accessor: 'job_title', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Email', accessor: 'email', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'LinkedIn Status', accessor: 'linkedin_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Networking Status', accessor: 'networking_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

export const ContactsTableColumnsCA: Column[] = [
  {
    label: 'Name', accessor: 'full_name', sortable: true, type: 'link', isCentered: false,
  },
  {
    label: 'Company', accessor: 'company', sortable: true, type: 'text', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Job Title', accessor: 'job_title', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Email', accessor: 'email', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'LinkedIn Status', accessor: 'linkedin_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Networking Status', accessor: 'networking_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

export const ChampionsTableColumns: Column[] = [
  {
    label: 'Name', accessor: 'full_name', sortable: true, type: 'text-with-image', isCentered: false,
  },
  {
    label: 'Company', accessor: 'company', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Industry', accessor: 'industry', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Job Title', accessor: 'job_title', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Demand', accessor: 'demand', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Networking Status', accessor: 'networking_status', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

export const ContactsTableColumnsWithoutStatuses: Column[] = ContactsTableColumns.filter((column) => column.label !== 'Networking Status');

const calculateNetworkingStatusValue = (status: ConnectionStatus) => {
  const statusValues = {
    connected: 3,
    pending: 2,
    notConnected: 1,
  };

  return statusValues[status];
};

type MapContactsFunction = (
  contacts: ContactWithNetworkingData[],
  openTracker: (
    id: string,
    tab?: string | undefined,
    additionalParams?: {
      [key: string]: string;
    }) => void,
  archiveContact: (id: string, currentStatus: ContactStatus) => void,
  isLoading?: boolean,
) => TableRow[];

type MapCampusChampionsFunction = (
  champions: SuggestedContactTypeWithNetworkingData[],
  handleRowClick: (
    id: string,
    tab?: string | undefined,
    additionalParams?: {
      [key: string]: string;
    }) => void,
  archiveContact: (id: string, currentStatus: ContactStatus) => void,
  handleReachOut: (id: string) => void,
  contacts: Contact[],
  restoreContact: (id: string) => void,
  handleSaveContact: (id: string) => void,
  university: string,
  isLoading?: boolean,
) => TableRow[];

export const mapCampusChampions: MapCampusChampionsFunction = (champions, handleRowClick, archiveContact, handleReachOut, contacts, restoreContact, handleSaveContact, university, isLoadingStatuses) => (Array.isArray(champions) ? champions.map((champion) => ({
  id: champion.contact_id,
  handleRowClick: () => {},
  items: {
    full_name: {
      value: `${champion.contact.first_name} ${champion.contact.last_name}`,
      label: `${champion.contact.first_name} ${champion.contact.last_name}`,
      image: <DefaultLogo
        type="contact"
        source={champion.contact.career_summary.basics?.image}
        className="contacts-page__table-image"
        name={`${champion.contact.first_name} ${champion.contact.last_name}`}
        size={24}
      />,
      type: 'text-with-image',
    },
    company: {
      value: champion.company_name,
      label: champion.company_name,
      image: <DefaultLogo
        type="company"
        source={champion.contact.current_position.company_logo_url}
        className="contacts-page__table-image"
        name={champion.company_name}
        size={24}
      />,
      type: 'link',
      handleClick: () => handleRowClick(encodeURIComponent(champion.company_id)),
    },
    industry: {
      type: 'text',
      label: champion.company_industry,
      value: champion.company_industry,
    },
    job_title: {
      type: 'text',
      label: champion.contact.current_position.title,
      value: champion.contact.current_position.title,
    },
    demand: {
      type: 'text',
      label: (
        <div className="contacts-page__demand">
          <DemandTag demand={champion.demand} university={university} />
        </div>
      ),
      value: champion.demand,
    },
    networking_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          {isLoadingStatuses ? (
            <div className="contacts-page__spinner" />
          ) : (
            <StatusTag status={champion.contact.networking_status || 'notConnected'} type="networking" />
          )}
        </div>
      ),
      value: calculateNetworkingStatusValue(champion.contact.networking_status || 'notConnected'),
    },
    actions: {
      label: (
        <div className="contacts-page__actions">
          {Array.isArray(contacts) && !contacts.some((contact) => contact.id === champion.contact_id) && (
          <div className="contacts-page__action">
            <Tooltip label="Save" position="top" withArrow>
              <IconButton
                icon="bi bi-bookmark-heart"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => handleSaveContact(champion.contact_id)}
              />
            </Tooltip>
          </div>
          )}
          <div className="contacts-page__action">
            <Tooltip label="Reach out" position="top" withArrow>
              <IconButton
                icon="bi bi-send"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => handleReachOut(champion.contact_id)}
                hoverIcon="bi bi-box-arrow-up-right"
              />
            </Tooltip>
          </div>

          {Array.isArray(contacts) && contacts.some((contact) => contact.id === champion.contact_id && contact.kanban_position.status !== 'archive') && (
          <div className="contacts-page__action">
            <Tooltip label="Archive" position="top" withArrow>
              <IconButton
                icon="bi bi-archive"
                mode="rounded"
                size="medium"
                outlined
                isDangerous
                handleClick={() => archiveContact(champion.contact.id, 'Contact Saved')}
              />
            </Tooltip>
          </div>
          )}
          {Array.isArray(contacts) && contacts.some((contact) => contact.id === champion.contact_id && contact.kanban_position.status === 'archive') && (
          <div className="contacts-page__action">
            <Tooltip label="Restore" position="top" withArrow>
              <IconButton
                icon="bi bi-arrow-clockwise"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => restoreContact(champion.contact.id)}
              />
            </Tooltip>
          </div>
          )}
        </div>
      ),
      value: 'notConnected',
    },
  },
})) : []);

export const mapContacts: MapContactsFunction = (contacts, openTracker, archiveContact, isLoadingStatuses) => (Array.isArray(contacts) ? contacts.map((contact) => ({
  id: contact.id,
  handleRowClick: () => router.navigate(`/app/inbox/${contact.id}`),
  items: {
    full_name: {
      value: `${contact.first_name} ${contact.last_name}`,
      label: `${contact.first_name} ${contact.last_name}`,
      image: <DefaultLogo
        type="contact"
        source={contact.career_summary.basics?.image}
        className="contacts-page__table-image"
        name={`${contact.first_name} ${contact.last_name}`}
        size={24}
      />,
      type: 'link',
      to: `/app/inbox/${contact.id}`,
    },
    company: {
      value: contact.current_position.company,
      label: contact.current_position.company,
      image: <DefaultLogo
        type="company"
        source={contact.current_position.company_logo_url}
        className="contacts-page__table-image"
        name={contact.current_position.company}
        size={24}
      />,
      type: 'link',
      handleClick: () => openTracker(encodeURIComponent(contact.current_position.company_id)),
    },
    job_title: {
      type: 'text',
      label: contact.current_position.title,
      value: contact.current_position.title,
    },
    email: {
      type: 'text',
      label: contact.email ? (
        <span className="contacts-page__contact-email">
          {[contact.email, contact.secondary_email].filter(Boolean).join(', ')}
        </span>
      ) : (
        <span className="contacts-page__table-placeholder">no email found</span>
      ),
      value: [contact.email, contact.secondary_email].filter(Boolean).join(', ') || '',
    },
    networking_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          {isLoadingStatuses ? (
            <div className="contacts-page__spinner" />
          ) : (
            <StatusTag status={contact.networking_status || 'notConnected'} type="networking" />
          )}
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
    linkedin_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          <StatusTag status={contact.linkedin_connection_status || 'notConnected'} type="linkedin" withTooltip />
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.linkedin_connection_status || 'notConnected'),
    },
    actions: {
      label: (
        <div className="contacts-page__actions">
          <div className="contacts-page__action">
            <Tooltip label="View profile" position="top" withArrow>
              <IconButton
                icon="bi bi-linkedin"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => window.open(contact.linkedin_url, '_blank')}
                hoverIcon="bi bi-box-arrow-up-right"
              />
            </Tooltip>
          </div>
          <div className="contacts-page__action">
            <Tooltip label="Archive" position="top" withArrow>
              <IconButton
                icon="bi bi-archive"
                mode="rounded"
                size="medium"
                outlined
                isDangerous
                handleClick={() => archiveContact(contact.id, contactAPIstatuses[contact.kanban_position.status])}
              />
            </Tooltip>
          </div>
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
  },
})) : []);

export const mapReadonlyContacts: MapContactsFunction = (contacts, openTracker) => (Array.isArray(contacts) ? contacts.map((contact) => ({
  id: contact.id,
  // handleRowClick: () => router.navigate(`/app/inbox/${contact.id}`), // TODO?: redirect to contacts book
  items: {
    full_name: {
      value: `${contact.first_name} ${contact.last_name}`,
      label: `${contact.first_name} ${contact.last_name}`,
      image: <DefaultLogo
        type="contact"
        source={contact.career_summary.basics?.image}
        className="contacts-page__table-image"
        name={`${contact.first_name} ${contact.last_name}`}
        size={24}
      />,
      type: 'link',
      to: contact.linkedin_url,
      target: '_blank',
    },
    company: {
      value: contact.current_position.company,
      label: contact.current_position.company,
      image: <DefaultLogo
        type="company"
        source={contact.current_position.company_logo_url}
        className="contacts-page__table-image"
        name={contact.current_position.company}
        size={24}
      />,
      type: 'link',
      handleClick: () => openTracker(encodeURIComponent(contact.current_position.company_id)),
    },
    job_title: {
      type: 'text',
      label: contact.current_position.title,
      value: contact.current_position.title,
    },
    email: {
      type: 'text',
      label: contact.email ? (
        <span className="contacts-page__contact-email">
          {[contact.email, contact.secondary_email].filter(Boolean).join(', ')}
        </span>
      ) : (
        <span className="contacts-page__table-placeholder">no email found</span>
      ),
      value: [contact.email, contact.secondary_email].filter(Boolean).join(', ') || '',
    },
    networking_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          <StatusTag status={contact.networking_status || 'notConnected'} type="networking" />
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
    linkedin_status: {
      type: 'text',
      label: (
        <div className="contacts-page__statuses">
          <StatusTag status={contact.linkedin_connection_status || 'notConnected'} type="linkedin" withTooltip />
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.linkedin_connection_status || 'notConnected'),
    },
    actions: {
      label: (
        <div className="contacts-page__actions">
          <div className="contacts-page__action">
            <Tooltip label="View profile" position="top" withArrow>
              <IconButton
                icon="bi bi-linkedin"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => window.open(contact.linkedin_url, '_blank')}
                hoverIcon="bi bi-box-arrow-up-right"
              />
            </Tooltip>
          </div>
        </div>
      ),
      value: calculateNetworkingStatusValue(contact.networking_status || 'notConnected'),
    },
  },
})) : []);
