import { useEffect, useMemo, useState } from 'react';
import './leaderboard.scss';
import { GamificationLeaderboardType, LeaderboardType, Rank } from '@/types/gamification';
import { GamificationLeaderboardItem } from './gamification-leaderboard-item';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  leaderboard: GamificationLeaderboardType;
  type: LeaderboardType
};

const NUMBER_OF_STUDENTS_ABOVE = 2;
const NUMBER_OF_STUDENTS_BELOW = 3;
const GLOBE_EMOJI = '🌎';

export const GamificationLeaderboard = ({
  leaderboard,
  type,
}: Props) => {
  const [topThreeStudents, setTopThreeStudents] = useState<Rank[]>([]);
  const [studentClosestCompetitors, setStudentClosestCompetitors] = useState<Rank[]>([]);
  const [studentTopCompetitors, setStudentTopCompetitors] = useState<number>(0);
  const [studentBottomCompetitors, setStudentBottomCompetitors] = useState<number>(0);

  const { trackEvent } = useAnalytics();

  const hasGlobe = useMemo(() => leaderboard.title.startsWith(GLOBE_EMOJI), [leaderboard.title]);
  const titleWithoutLeaderboard = useMemo(() => leaderboard.title.replace('Leaderboard', '').trim(), [leaderboard.title]);
  const titleForGradient = useMemo(() => (
    hasGlobe
      ? titleWithoutLeaderboard.substring(GLOBE_EMOJI.length).trim()
      : titleWithoutLeaderboard
  ), [hasGlobe, titleWithoutLeaderboard]);

  const computeLeaderboard = () => {
    if (!leaderboard?.ranking) return;
    const mappedStudents = leaderboard.ranking
      .map((ranking, index) => ({ ...ranking, rating: index + 1 }));

    const currentStudentIndex = mappedStudents
      .findIndex((student) => student.is_user);

    if (currentStudentIndex <= 3) {
      // if the current student is in the top 4 show simply 10 top students
      setStudentClosestCompetitors(mappedStudents.slice(3, mappedStudents.length > 10 ? 10 : mappedStudents.length));
      setTopThreeStudents(mappedStudents.slice(0, 3));
      setStudentTopCompetitors(0);
      setStudentBottomCompetitors(mappedStudents.length - 10);

      return;
    }

    const higherRatedUserIndex = currentStudentIndex - 3 > 2 ? currentStudentIndex - 3 : 3;
    const studentCompetitors = mappedStudents
      .slice(higherRatedUserIndex, higherRatedUserIndex + NUMBER_OF_STUDENTS_ABOVE + NUMBER_OF_STUDENTS_BELOW + 1);
    const studentTopCompetitorsNumber = higherRatedUserIndex - NUMBER_OF_STUDENTS_ABOVE;
    const studentBottomCompetitorsNumber = mappedStudents.length - (currentStudentIndex + NUMBER_OF_STUDENTS_BELOW) - 1;

    setStudentClosestCompetitors(studentCompetitors);
    setTopThreeStudents(mappedStudents.slice(0, 3));
    setStudentTopCompetitors(studentTopCompetitorsNumber);
    setStudentBottomCompetitors(studentBottomCompetitorsNumber);
  };

  const handleLeaderboardHover = () => {
    trackEvent('leaderboard_hover', {
      leaderboard_type: type,
      leaderboard_title: leaderboard.title,
    });
  };

  useEffect(() => {
    computeLeaderboard();
  }, []);

  return (
    <div className="leaderboard" onMouseEnter={handleLeaderboardHover}>
      <div className="leaderboard__header">
        <div className="leaderboard__title">
          {hasGlobe && <span style={{ marginRight: '0.25em' }}>{GLOBE_EMOJI}</span>}
          <span className="leaderboard__university-name">{titleForGradient}</span>
          <span> Leaderboard</span>
        </div>
        <p className="leaderboard__description">
          {leaderboard.description}
        </p>
      </div>
      <ul className="leaderboard__students-list">
        {topThreeStudents.map((student) => (
          <GamificationLeaderboardItem
            key={student.position}
            type={type}
            format={leaderboard.format}
            {...student}
          />
        ))}
        {studentTopCompetitors > 1 && (
          <div className="leaderboard__split">
            <span className="leaderboard__split-number">
              +
              {studentTopCompetitors}
            </span>
          </div>
        )}
        {studentClosestCompetitors.map((student) => (
          <GamificationLeaderboardItem
            key={student.position}
            format={leaderboard.format}
            type={type}
            {...student}
          />
        ))}
        {studentBottomCompetitors > 0 && (
          <div className="leaderboard__split">
            <span className="leaderboard__split-number">
              +
              {studentBottomCompetitors}
            </span>
          </div>
        )}
      </ul>
    </div>
  );
};
