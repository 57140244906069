export const COMPANY_STEPS = [
  'Saved',
  'Networking',
  'Applying',
  'Applied',
  'Interviewing',
  'Offer',
  'Rejected',
  'Offer Accepted',
];
