import { IconButton, Input, ToggleViews } from '@careeros/coco';
import { useEffect, useState } from 'react';
import { useScroll } from '@/services/hooks/use-scroll';
import Table from '@/components/table/table';
import { Column, TableRow } from '@/components/table/table-types';
import './student-contacts-overview.scss';
import { StudentContactsKanban } from '../student-contacts-kanban/student-contacts-kanban';

type Props = {
  columns: string[];
  layout: any;
  tableColumns: Column[];
  handleSearchQueryChange: (searchQuery: string) => void;
  searchQuery: string;
  tableData: TableRow[];
};

export const StudentContactsOverview = ({
  columns,
  layout,
  tableColumns,
  handleSearchQueryChange,
  searchQuery,
  tableData,
}: Props) => {
  const [kanbanContainer, handleVerticalScroll] = useScroll();
  const [showRightScrollArrow, setShowRightScrollArrow] = useState(false);
  const [showLeftScrollArrow, setShowLeftScrollArrow] = useState(false);
  const [selectedView, setSelectedView] = useState<'list' | 'kanban'>('kanban');

  const updateScrollState = () => {
    if (!kanbanContainer.current) {
      return;
    }

    setShowLeftScrollArrow(kanbanContainer.current.scrollLeft > 0);
    setShowRightScrollArrow(kanbanContainer.current.scrollLeft < (kanbanContainer.current.scrollWidth - kanbanContainer.current.clientWidth));
  };

  const handleScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const el = e.currentTarget;

    if (!kanbanContainer.current) {
      return;
    }

    const allColumnsWidth = kanbanContainer.current.scrollWidth;
    const columnsInViewWidth = kanbanContainer.current.clientWidth;

    if (Math.round(el.scrollLeft + columnsInViewWidth) >= Math.round(allColumnsWidth)) {
      setShowRightScrollArrow(false);
    } else {
      setShowRightScrollArrow(true);
    }

    if (el.scrollLeft === 0) {
      setShowLeftScrollArrow(false);
    } else {
      setShowLeftScrollArrow(true);
    }
  };

  const handleViewChange = (value: number) => {
    const valueToSet = value === 0 ? 'kanban' : 'list';
    setSelectedView(valueToSet);

    const currentSearch = new URLSearchParams(window.location.search);
    currentSearch.delete('contacts-view');
    currentSearch.append('contacts-view', valueToSet.toString());

    window.history.replaceState(null, '', `?${currentSearch.toString()}`);
  };

  useEffect(() => {
    updateScrollState();
  }, [kanbanContainer.current]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const view = searchParams.get('contacts-view');

    if (view) {
      setSelectedView(view === 'list' ? 'list' : 'kanban');
    }
  }, []);

  return (
    <div className="student-contacts-overview-container">
      <div className="student-contacts-overview-container__top-bar">
        <div className="student-contacts-overview-container__left">
          <div className="student-contacts-overview-container__view-toggle">
            <ToggleViews
              labels={['Kanban View', 'List View']}
              size="small"
              selectedValue={selectedView === 'kanban' ? 0 : 1}
              emitSelectedValue={handleViewChange}
              icons={['bi-kanban', 'bi-grid-3x3']}
              iconsSelected={['bi-kanban-fill', 'bi-table']}
            />
          </div>
          <div className="student-contacts-overview-container__search">
            <Input
              icon="bi bi-search"
              inputSize="medium"
              placeholder="Type by contact name, company or job title"
              value={searchQuery}
              id="contacts-search"
              label=""
              handleValueChange={handleSearchQueryChange}
            />
          </div>
        </div>
        <div className="student-contacts-overview-container__right">
          {selectedView === 'kanban' && (
            <div className="student-contacts-overview-container__controls">
              <IconButton
                icon="bi bi-chevron-left"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => handleVerticalScroll('left')}
                disabled={!showLeftScrollArrow}
              />
              <IconButton
                icon="bi bi-chevron-right"
                mode="rounded"
                size="medium"
                outlined
                handleClick={() => handleVerticalScroll('right')}
                disabled={!showRightScrollArrow}
              />
            </div>
          )}
        </div>
      </div>
      <div className={`student-contacts-overview-container__kanban ${selectedView === 'kanban' ? 'student-contacts-overview-container__kanban--active' : ''}`}>
        <StudentContactsKanban
          columns={columns}
          layout={layout}
          onScroll={handleScroll}
          scrollContainerRef={kanbanContainer}
        />
      </div>
      <div className={`student-contacts-overview-container__table ${selectedView === 'list' ? 'student-contacts-overview-container__table--active' : ''}`}>
        <Table isFixedLayout columns={tableColumns} data={tableData} />
      </div>
    </div>
  );
};
