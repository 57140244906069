import { TopCompaniesType } from '../types';

const computeFilteredCompaniesData = (status: string, topCompaniesData: TopCompaniesType[]) => (topCompaniesData || []).map(company => company.statuses.filter(s => s === status).length);

export const processTop10CompaniesData = (
  topCompaniesData: TopCompaniesType[],
) => {
  const topTenCompanies = (topCompaniesData || []).map((company: TopCompaniesType) => company.name);

  return {
    all: topCompaniesData || [],
    companiesData: {
      labels: topTenCompanies,
      datasets: [
        {
          label: 'Company Saved',
          data: computeFilteredCompaniesData('saved', topCompaniesData),
          backgroundColor: '#06A47E',
          color: '#ffffff',
        },
        {
          label: 'Networking',
          data: computeFilteredCompaniesData('networking', topCompaniesData),
          backgroundColor: '#98A6FD',
          color: '#ffffff',
        },
        {
          label: 'Applying',
          data: computeFilteredCompaniesData('applying', topCompaniesData),
          backgroundColor: '#2DCDC7',
          color: '#ffffff',
        },
        {
          label: 'Applied',
          data: computeFilteredCompaniesData('applied', topCompaniesData),
          backgroundColor: '#2C9ACF',
          color: '#ffffff',
        },
        {
          label: 'Interviewing',
          data: computeFilteredCompaniesData('interviewing', topCompaniesData),
          backgroundColor: '#EB993E',
          color: '#ffffff',
        },
        {
          label: 'Offer Received',
          data: computeFilteredCompaniesData('offer', topCompaniesData),
          backgroundColor: '#FC47BE',
          color: '#ffffff',
        },
        {
          label: 'Archived',
          data: computeFilteredCompaniesData('archived', topCompaniesData),
          backgroundColor: '#BEC9C8',
          color: '#ffffff',
        },
      ],
    },
  };
};
