import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import { Loader } from '@/components/loader/loader';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useSelf } from '@/services/queries/user';

function RefferalCheck() {
  const { user } = useAuth0();
  const { data: self } = useSelf();
  const { referralCode } = useParams();
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && referralCode && self) {
      trackEvent('Refferal Check', user, {
        referral_code: referralCode,
        user_id: self.id,
        user_name: `${self.first_name}  ${self.last_name}`,
      });
      navigate('/app/dashboard');
    }
  }, [user, referralCode, self]);

  return (
    <div id="loader-zone">
      <Loader />
    </div>
  );
}

export default CustomWithAuthenticationRequired(RefferalCheck, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
