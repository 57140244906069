/* eslint-disable no-restricted-syntax */
import * as Sentry from '@sentry/react';
import {
  useEffect, useMemo, useState,
} from 'react';
import {
  DefaultLogo, IconButton, ToggleViews, Tooltip,
} from '@careeros/coco';
import { Loader } from '@/components/loader/loader';
import '@/pages/styles/alumni-list.scss';

import { useSelfUniversity } from '@/services/queries/user';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';
import { useTabs, withTabs } from '@/components/tabs/TabsContext';
import { useAlumniBookFiltering } from '@/services/hooks/use-alumni-book-filters';
import { AlumBookFilters, AlumBookTile, AlumDescription } from '@/domains/core/alum';
import { Alum, AlumniBookFiltersType } from '@/types/alum';
import {
  determineHiringSuccess, getCurrentCompany, getCurrentPosition, getDegreeTag, getGraduationTag, parseCareerSummary,
} from '@/domains/core/alum/helpers/helpers';
import Table from '@/components/table/table';
import { Column, TableRow } from '@/components/table/table-types';
import { CareerType } from '@/domains/core/contact/types';
import { AlumniAnalytics } from '@/domains/core/alum/components/alumni-analytics/alumni-analytics';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';

const defaultFilters: AlumniBookFiltersType = {
  contactName: '',
  companies: [],
  pastCompanies: [],
  isCareerosUser: '',
  industryName: [],
  degree: '',
  locations: [],
  hiringSuccess: '',
  graduationYear: '',
};

export const AlumniOSColumns: Column[] = [
  {
    label: 'Name', accessor: 'full_name', sortable: true, type: 'text-with-image', isCentered: false,
  },
  {
    label: 'Company', accessor: 'company', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Job Title', accessor: 'job_title', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Hiring Success', accessor: 'hiring_success', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'CareerOS User', accessor: 'careeros_user', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Degree', accessor: 'degree', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Graduation Year', accessor: 'graduation_year', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

type MapAlumniFunction = (
  alumni: Alum[],
  handleCurrentCompanyClick: (company: string) => void,
) => TableRow[];

export const mapAlumni: MapAlumniFunction = (alumni, handleCurrentCompanyClick) => (Array.isArray(alumni) ? alumni.map((alum) => {
  const parsedCareerSummary = parseCareerSummary(alum?.career_summary.profile) as CareerType;

  const currentPosition = getCurrentPosition(alum);
  const currentCompany = getCurrentCompany(alum);
  return ({
    id: alum.id,
    handleRowClick: () => { },
    items: {
      full_name: {
        value: `${alum.first_name} ${alum.last_name}`,
        label: `${alum.first_name} ${alum.last_name}`,
        image: <DefaultLogo
          type="contact"
          source={parsedCareerSummary?.basics?.image || ''}
          className="contacts-page__table-image"
          name={`${alum.first_name} ${alum.last_name}`}
          size={24}
        />,
        type: 'text-with-image',
      },
      company: {
        value: currentCompany,
        label: currentCompany,
        type: 'text',
        handleClick: () => handleCurrentCompanyClick(currentCompany),
      },
      job_title: {
        type: 'text',
        label: currentPosition,
        value: currentPosition,
      },
      hiring_success: {
        type: 'text',
        label: determineHiringSuccess(alum) ? 'Yes' : 'No',
        value: determineHiringSuccess(alum) ? 'Yes' : 'No',
      },
      careeros_user: {
        type: 'text',
        label: alum.has_given_permission ? 'Yes' : 'No',
        value: alum.has_given_permission ? 'Yes' : 'No',
      },
      degree: {
        type: 'text',
        label: getDegreeTag(alum)?.tag_content || '',
        value: getDegreeTag(alum)?.tag_content || '',
      },
      graduation_year: {
        type: 'text',
        label: getGraduationTag(alum)?.tag_content || '',
        value: getGraduationTag(alum)?.tag_content || '',
      },
      actions: {
        label: (
          <div className="contacts-page__actions">
            {alum?.email
              && (
                <div className="contacts-page__action">
                  <Tooltip label="Reach out on email" position="top" withArrow>
                    <IconButton
                      icon="bi bi-send"
                      mode="rounded"
                      size="medium"
                      outlined
                      handleClick={() => window.open(`mailto:${alum.email}`, '_blank')}
                      hoverIcon="bi bi-box-arrow-up-right"
                    />
                  </Tooltip>
                </div>
              )}
            <div className="contacts-page__action">
              <Tooltip label="Linkedin" position="top" withArrow>
                <IconButton
                  icon="bi bi-linkedin"
                  mode="rounded"
                  size="medium"
                  outlined
                  handleClick={() => window.open(`https://www.linkedin.com/in/${alum.linkedin_slug}`, '_blank')}
                />
              </Tooltip>
            </div>
          </div>
        ),
        value: 'notConnected',
      },
    },
  });
}) : []);

function AlumniList() {
  const [selectedAlum, setSelectedAlum] = useState<Alum | null>(null);
  const { data: university } = useSelfUniversity();
  const [selectedView, setSelectedView] = useState(0);
  const { currentTab } = useTabs();
  const {
    alumni,
    currentActiveId,
    isLoading,
    alumniCount,
    totalAlumniCount,
    companies,
    locations,
    filters,
    setCurrentActiveId,
    setFilters,
  } = useAlumniBookFiltering({
    currentTab, defaultFilters, universityID: university?.id || '',
  });

  const handleCurrentCompanyClick = (company: string) => {
    setFilters({
      ...filters,
      companies: [...filters.companies, {
        name: company,
        value: company,
      }],
    });
  };

  const tableData = useMemo(() => mapAlumni(alumni, handleCurrentCompanyClick), [alumni]);

  const handleContactSelect = (id: string) => {
    setCurrentActiveId(id);
  };

  const getDetailedContactInfo = async () => {
    try {
      if (currentActiveId) {
        const contactDetail = alumni.find((contact) => contact.id === currentActiveId);
        if (contactDetail) {
          // Calculate hiring success here since we're using the original alumni data
          const contactWithHiringSuccess = {
            ...contactDetail,
            hiring_success: determineHiringSuccess(contactDetail),
          };
          console.log('contactDetail with hiring success:', contactWithHiringSuccess);
          setSelectedAlum(contactWithHiringSuccess);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  useAdvisorRoleCheck();

  useEffect(() => {
    getDetailedContactInfo();
  }, [currentActiveId]);

  if (isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="alumni-list" id="alumni-list">
      <div className="alumni-list__top-container">
        <AlumBookFilters
          filters={filters}
          setFilters={setFilters}
          defaultFilters={defaultFilters}
          companies={companies}
          locations={locations}
        />
        <div className="alumni-list__toggle-views">
          <ToggleViews
            size="large"
            selectedValue={selectedView}
            emitSelectedValue={setSelectedView}
            labels={['Feed', 'Table', 'Analytics']}
            icons={['bi-card-list', 'bi-table', 'bi-bar-chart']}
            iconsSelected={['bi-card-list', 'bi-table', 'bi-bar-chart-fill']}
          />
        </div>
      </div>
      <div className="alumni-list__bottom">
        {selectedView === 0 && (
          <>
            <div className="job-tabs">
              <div data-tab="All Contacts" className="alumni-list__tab">
                <div className="alum-tabs__top-info">
                  <div className="alum-tabs__count">
                    <p className="alum-tabs__count-number">
                      {`${alumniCount?.toLocaleString()} alumni found out of ${totalAlumniCount?.toLocaleString()}`}
                      {alumniCount > 0 && (
                        <TooltipIcon tooltipLabel="Alumni are ranked by date added" />
                      )}
                    </p>
                  </div>
                </div>
                <div className="alum-tabs__items">
                  {alumni.map((item) => (
                    <AlumBookTile
                      key={item.id}
                      alum={item}
                      isActive={currentActiveId === item.id}
                      onSelect={handleContactSelect}
                      setFilters={setFilters}
                      filters={filters}
                    />
                  ))}
                </div>
              </div>
            </div>
            <div className="alumni-list__content-wrapper">
              <div className="alumni-list__block">
                <AlumDescription
                  selectedAlum={selectedAlum || alumni[0]}
                />
              </div>
            </div>
          </>
        )}
        {selectedView === 1 && (
          <div className="alumni-list__table alumni-list__table--active">
            <Table isFixedLayout columns={AlumniOSColumns} data={tableData} />
          </div>
        )}
        {selectedView === 2 && (
          <div className="alumni-list__analytics">
            <AlumniAnalytics alumni={alumni} />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(AlumniList, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
}));
