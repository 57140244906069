import { useEffect, useMemo, useState } from 'react';
import './companies-filters.scss';
import {
  Button, IconButton, Checkbox, Chip, ChipTypes,
} from '@careeros/coco';
import { CompanyLocation, FilterOption } from '../../types/company';
import { CompaniesLocationFilter } from './companies-location-filter';
import {
  defaultCompaniesFilters,
  mapAndSortCompanySizes,
  newTagType,
  parseLocationFiltersToSearchParams,
  parseSearchParamsAndGetFilters,
  sortedIndustries,
} from './data-parser';
import {
  CompaniesFilterNameType,
  CompaniesFilterType,
  CompanyLocationWithName,
  FilterTagTypes,
} from '../../types/companies-filtering';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { CompaniesFiltersMobile } from '../companies-filters-mobile/companies-filters-mobile';
import { isMobileDevice } from '@/services/helpers/responsive';
import { DropdownSearchStatic } from '@/components/dropdown-search-static/dropdown-search-static';

type Props = {
  withIndustryFilter?: boolean;
  withoutSavedFilter?: boolean;
  tags: string[];
  locations: CompanyLocation[];
  sizes: string[];
  handleFiltersChange: (filters: CompaniesFilterType, saved_by_user: boolean | undefined, locations: CompanyLocation[]) => void;
  handleSearchParamsChange?: (searchParams: URLSearchParams) => void;
  preserveSearchParams?: boolean;
};

export const CompaniesFilters = ({
  handleFiltersChange,
  handleSearchParamsChange,
  tags,
  locations,
  sizes,
  withIndustryFilter = false,
  withoutSavedFilter = false,
  preserveSearchParams = false,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const [selectedFilters, setSelectedFilters] = useState<CompaniesFilterType>(defaultCompaniesFilters);
  const [selectedFiltersTags, setSelectedFiltersTags] = useState<{ type: ChipTypes, name: string, filterName: FilterTagTypes }[]>([]);
  const [showSavedCompanies, setShowSavedCompanies] = useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] = useState<CompanyLocationWithName[]>([]);
  const [selectedSearchParams, setSelectedSearchParams] = useState<URLSearchParams>(new URLSearchParams());
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const nonSelectedTags = useMemo<{ name: string }[]>(() => {
    const selectedTags = selectedFilters.tags.map((t) => t.name);
    const mappedTags = tags?.map((tag) => ({ name: tag })) || [];
    return mappedTags.filter((tag) => !selectedTags.includes(tag.name));
  }, [selectedFilters.tags.length]);

  const handleSelectedSearchParamsChange = (searchParams: URLSearchParams) => {
    handleSearchParamsChange?.(searchParams);
    setSelectedSearchParams(searchParams);
  };

  const handleUpdateFilters = (newFilters: CompaniesFilterType, showSaved: boolean, newLocations: CompanyLocationWithName[]) => {
    handleFiltersChange(newFilters, showSaved ? undefined : false, newLocations.flatMap((loc) => loc.value));
  };

  const handleFiltersMobileButtonClick = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
  };

  const updateSearchParamsWithNewFilters = (name: string, value: FilterOption[] | boolean | string) => {
    const newSearchParams = new URLSearchParams(window.location.search);

    newSearchParams.delete(name);

    if (typeof value === 'boolean' || typeof value === 'string') {
      newSearchParams.append(name, value.toString());
    } else if (value.length > 0) {
      newSearchParams.append(name, JSON.stringify(value.flatMap((v) => v.value)));
    }

    handleSelectedSearchParamsChange(newSearchParams);

    if (preserveSearchParams) {
      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    }
  };

  const handleLocationFilterDelete = (locationName: string) => {
    const newFilters = selectedLocations.filter((location) => location.name !== locationName);
    setSelectedLocations(newFilters);
    handleUpdateFilters(selectedFilters, showSavedCompanies, newFilters);
    setSelectedFiltersTags(selectedFiltersTags.filter((tag) => tag.name !== locationName));

    updateSearchParamsWithNewFilters('location', parseLocationFiltersToSearchParams(newFilters));
  };

  const handleFilterDelete = (filterName: FilterTagTypes, name: string) => {
    if (filterName === 'location') {
      handleLocationFilterDelete(name);
      return;
    }
    const newFilters = {
      ...selectedFilters,
      [filterName]: selectedFilters[filterName].filter((option) => option.name !== name),
    };

    setSelectedFilters(newFilters);
    handleUpdateFilters(newFilters, showSavedCompanies, selectedLocations);
    setSelectedFiltersTags(selectedFiltersTags.filter((tag) => (tag.filterName === filterName ? tag.name !== name : true)));

    updateSearchParamsWithNewFilters(filterName, newFilters[filterName]);
  };

  const handleAddFilterTag = (filterName: FilterTagTypes, name: string) => {
    trackEvent(`${filterName} filter applied`, { selectedFilter: name });
    setSelectedFiltersTags([...selectedFiltersTags, { type: newTagType(filterName), name, filterName }]);
  };

  const handleSelectLocation = (location: CompanyLocationWithName) => {
    setSelectedLocations(prevSelected => [...prevSelected, location]);
    handleAddFilterTag('location', location.name);
    handleUpdateFilters(selectedFilters, showSavedCompanies, [...selectedLocations, location]);

    updateSearchParamsWithNewFilters('location', parseLocationFiltersToSearchParams([...selectedLocations, location]));
  };

  const updateAllFiltersTags = (filters: CompaniesFilterType, locationFilters: CompanyLocationWithName[]) => {
    const newTags = Object.entries(filters).reduce(
      (acc, [filterName, filterOptions]) => [
        ...acc,
        ...filterOptions.map((option) => ({
          type: newTagType(filterName as FilterTagTypes),
          name: option.name as string,
          filterName: filterName as FilterTagTypes,
        })),
      ],
      [] as { type: ChipTypes; name: string; filterName: FilterTagTypes }[],
    );

    const locationTags = locationFilters.map((location) => ({
      type: 'location' as ChipTypes,
      name: location.name,
      filterName: 'location' as FilterTagTypes,
    }));

    setSelectedFiltersTags([...newTags, ...locationTags]);
  };

  const handleFilterChange = (name: CompaniesFilterNameType, options: FilterOption[]) => {
    const newFilters = { ...selectedFilters, [name]: options };

    if (options.length < selectedFilters[name].length) {
      setSelectedFiltersTags(selectedFiltersTags.filter((tag) => name !== tag.filterName || options.map(o => o.name).includes(tag.name)));
    } else {
      handleAddFilterTag(name, options[options.length - 1].name as string);
    }

    setSelectedFilters(newFilters);
    handleUpdateFilters(newFilters, showSavedCompanies, selectedLocations);

    updateSearchParamsWithNewFilters(name, options);
  };

  const hanldeShowSavedCompaniesChange = (value: boolean) => {
    setShowSavedCompanies(value);
    handleUpdateFilters(selectedFilters, value, selectedLocations);

    trackEvent('Show saved companies filter applied', { selectedFilter: value });
    updateSearchParamsWithNewFilters('saved_by_user', value);
  };

  const handleSearchSelect = (name: CompaniesFilterNameType, option: FilterOption) => {
    const prevSelectedOptions = selectedFilters[name];
    const newFilters = { ...selectedFilters, [name]: [...prevSelectedOptions, option] };

    setSelectedFilters(newFilters);
    handleUpdateFilters(newFilters, showSavedCompanies, selectedLocations);
    handleAddFilterTag(name, option.name as string);

    updateSearchParamsWithNewFilters(name, newFilters[name]);
  };

  const handleFiltersInSearchParams = () => {
    const { search } = window.location;

    const searchParams = new URLSearchParams(search);

    const filtersFromSearch = parseSearchParamsAndGetFilters(searchParams, sizes, tags);

    handleSelectedSearchParamsChange(searchParams);
    setSelectedLocations(filtersFromSearch.locations);
    setSelectedFilters(filtersFromSearch.filters);
    updateAllFiltersTags(filtersFromSearch.filters, filtersFromSearch.locations);
    setShowSavedCompanies(filtersFromSearch.savedByUser);
    handleUpdateFilters(filtersFromSearch.filters, filtersFromSearch.savedByUser, filtersFromSearch.locations);
  };

  const handleFilterReset = () => {
    setSelectedFilters(defaultCompaniesFilters);
    handleUpdateFilters(defaultCompaniesFilters, false, []);
    setSelectedLocations([]);
    setSelectedFiltersTags([]);
    setShowSavedCompanies(false);
    window.history.replaceState(null, '', '?');
    handleSelectedSearchParamsChange(new URLSearchParams());
  };

  const saveMobileFilters = (newFilters: CompaniesFilterType, newLocations: CompanyLocationWithName[]) => {
    const newSearchParams = new URLSearchParams(window.location.search);

    Object.entries(newFilters).forEach(([filterName, filterValue]) => {
      newSearchParams.delete(filterName);
      if (filterValue.length > 0) {
        newSearchParams.append(filterName, JSON.stringify(filterValue.flatMap((v) => v.value)));
      }
    });

    newSearchParams.delete('location');
    if (newLocations.length > 0) {
      newSearchParams.append('location', parseLocationFiltersToSearchParams(newLocations));
    }

    setSelectedFilters(newFilters);
    setSelectedLocations(newLocations);
    updateAllFiltersTags(newFilters, newLocations);
    handleUpdateFilters(newFilters, showSavedCompanies, newLocations);

    handleSelectedSearchParamsChange(newSearchParams);
    if (preserveSearchParams) {
      window.history.replaceState(null, '', `?${newSearchParams.toString()}`);
    }
  };

  useEffect(() => {
    handleFiltersInSearchParams();
  }, []);

  useEffect(() => {
    // If the search params are different from the selected filters, update the filters (in case of a direct link to the same path with filters in the URL search params)
    if (new URLSearchParams(window.location.search).toString() !== selectedSearchParams.toString()) {
      handleFiltersInSearchParams();
    }
  }, [window.location.search]);

  return (
    <div data-testid="companies-filters" className="companies-filters">
      <div className="companies-filters__filter-bar">
        <div className="companies-filters__filter-bar-mobile">
          <CompaniesLocationFilter
            allLocations={locations}
            selectedLocations={selectedLocations}
            handleLocationSelect={handleSelectLocation}
          />
          <div className="companies-filters__all-filters-mobile-button">
            <IconButton mode={isMobileModalOpen ? 'primary' : 'unset'} icon="bi bi-filter-circle" size="medium" handleClick={handleFiltersMobileButtonClick} />
          </div>
        </div>
        <div className="companies-filters__search-field">
          <DropdownSearchStatic
            selectedOption=""
            options={nonSelectedTags}
            handleItemSelect={(item) => handleSearchSelect('tags', { name: item, value: item })}
            placeholder="Search tags"
            name="tag-search"
            id="tag-search"
            inputIcon="bi bi-search"
            size="medium"
            canAddItems={false}
            data-testid="companies-filters-tag-search"
            showIcon={false}
            withMultipleSelection
            openOnClick
            listWidth="full"
          />
        </div>

        {(withIndustryFilter || isMobileDevice) && (
          <div className="companies-filters__filter" data-testid="companies-filters-industry-filter">
            <MultiSelect
              labelType="list"
              handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('industry', options)}
              placeholder="Industry"
              selected={selectedFilters.industry}
              options={sortedIndustries.map((industry) => ({ name: industry, value: industry }))}
            />
          </div>
        )}

        <div className="companies-filters__filter" data-testid="companies-filters-company-size-filter">
          <MultiSelect
            labelType="list"
            handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('sizes', options)}
            placeholder="Company Size"
            selected={selectedFilters.sizes}
            options={mapAndSortCompanySizes(sizes) || []}
          />
        </div>
        {!withoutSavedFilter && (
          <div className="companies-filters__checkbox" data-testid="companies-filters-company-saved-filter">
            <Checkbox
              label="Also show companies you already saved"
              checked={showSavedCompanies}
              onChange={hanldeShowSavedCompaniesChange}
            />
          </div>
        )}

        {((Object.values(selectedFilters).some(val => val.length > 0)) || showSavedCompanies || selectedLocations.length > 0) && !isMobileDevice && (
          <Button
            mode="primary"
            outlined
            size="medium"
            label="Reset"
            handleClick={handleFilterReset}
            data-testid="companies-filters-reset-button"
          />
        )}

        {isMobileModalOpen && (
          <CompaniesFiltersMobile
            locations={locations}
            sizes={sizes}
            selectedFilters={selectedFilters}
            selectedLocations={selectedLocations}
            nonSelectedTags={nonSelectedTags}
            industries={sortedIndustries}
            saveMobileFilters={saveMobileFilters}
            handleClose={() => setIsMobileModalOpen(false)}
          />
        )}
      </div>

      {selectedFiltersTags.length > 0 && (
        <div className="companies-filters__filter-labels" data-testid="companies-filters-chips">
          {selectedFiltersTags.map((tag) => (
            <Chip key={tag.name} label={tag.name} type={tag.type} handleClose={() => handleFilterDelete(tag.filterName, tag.name)} />
          ))}
          {isMobileDevice && (
            <Button
              mode="reset"
              outlined
              size="medium"
              label="Reset"
              handleClick={handleFilterReset}
              icon="bi bi-arrow-repeat"
              data-testid="companies-filters-reset-button"
            />
          )}
        </div>
      )}
    </div>
  );
};
