import { useContext, useEffect } from 'react';
import { InfoModal } from '@careeros/coco';
import { isMobileDevice } from '@/services/helpers/responsive';
import { ModalContext } from '@/components/modal/modal-provider';

const MOBILE_WARNING_KEY = 'mobile_warning_displayed';

/**
 * Hook to display a warning modal on mobile devices
 * @param options Configuration options for the modal
 */
export const useMobileWarning = () => {
  const { openModal, closeModal } = useContext(ModalContext) as any;

  const handleShowWarningModal = () => {
    const hasShownWarning = sessionStorage.getItem(MOBILE_WARNING_KEY);
    if (isMobileDevice && !hasShownWarning) {
      sessionStorage.setItem(MOBILE_WARNING_KEY, 'true');
      openModal(
        <InfoModal
          title="Best Viewed on Desktop"
          description="For the best experience and full functionality, please switch to a desktop or laptop. 💻"
          buttonLabel="Got it!"
          handleButtonClick={closeModal}
          icon="bii bi-exclamation-triangle-fill"
        />,
      );
    }
  };

  useEffect(() => {
    handleShowWarningModal();
  }, []);
};
