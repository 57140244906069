import LinkedinGuysLogo from '@/assets/images/linkedin-guys.jpeg';
import CaseCoach from '@/assets/images/casecoach.png';
import GraduateFirstLogo from '@/assets/images/graduates-first.png';
import VMockLogo from '@/assets/images/vmock-logo.png';
import VaultLogo from '@/assets/images/vault-logo.webp';
import CareerOSLogo from '@/assets/images/icons/new-logo-small.svg';
import ResumeWordedLogo from '@/assets/images/resume-worded-logo.png';
import MockInterviewerLogo from '@/assets/images/mockinterviewer-logo.jpeg';
import GammaAppLogo from '@/assets/images/gamma-app-logo.png';
import GoogleLogo from '@/assets/images/google-logo.png';
import { TaskNames, TaskObject } from './types';
// CHECKBOX DATA
const COMPANY_SAVED_ACTIONS = (companyName: string): TaskObject[] => [
  {
    name: TaskNames.extension,
    content: [
      {
        action: undefined,
        isLink: true,
        target: '_blank',
        to: import.meta.env.VITE_EXTENSION_LINK,
        label: 'Download the CareerOS Chrome Extension',
      },
    ],
  },
  {
    name: TaskNames.saveContact,
    content: [
      {
        action: undefined,
        label: 'Save your ',
      },
      {
        action: {
          type: 'openTab',
          target: 'Contacts',
        },
        label: 'first contact',
      },
      {
        action: undefined,
        label: ` at ${companyName} to start networking. `,
      },
      {
        action: {
          type: 'openTip',
          target: '#idealContact',
        },
        label: 'Click here',
      },
      {
        label: ' for tips on ideal contacts.',
      },
    ],
  },
  {
    name: TaskNames.optimizeProfile,
    content: [
      {
        label: 'Make sure your ',
      },
      {
        action: {
          type: 'openTip',
          target: '#optimizeProfile',
        },
        label: 'LinkedIn profile is on point',
      },
    ],
  },
];

const NETWORKING_ACTIONS: TaskObject[] = [
  {
    name: TaskNames.scheduleCoffeeChat,
    content: [
      {
        action: {
          type: 'openTip',
          target: '#openTipModal',
        },
        label: 'Schedule and prepare for a coffee chat',
      },
      {
        label: ' with your contact(s) to get unique insights into the company and role',
      },
    ],
  },
  {
    name: TaskNames.coffeeChatQuestions,
    isAI: true,
    content: [
      {
        action: {
          type: 'useAITool',
          target: 'Questions to ask in a Coffee Chat',
        },
        label: 'Create a list of questions',
      },
      {
        label: ' for your coffee chat using our AI tools',
      },
    ],
  },
  {
    name: TaskNames.followUp,
    content: [
      {
        action: {
          type: 'openTab',
          target: 'Jobs',
        },
        label: 'Save job',
      },
    ],
  },
];

const APPLYING_ACTIONS: TaskObject[] = [
  {
    name: TaskNames.roleRequirements,
    isAI: true,
    content: [
      {
        label: 'Use our AI tool to ',
      },
      {
        action: {
          type: 'useAITool',
          target: 'Learn About Roles',
        },
        label: 'understand role requirements',
      },
    ],
  },
  {
    name: TaskNames.careerStory,
    content: [
      {
        label: 'Use your resume and cover letter to ',
      },
      {
        action: {
          type: 'openTip',
          target: '#openTipModal',
        },
        label: 'craft your career story',
      },
    ],
  },
  {
    name: TaskNames.tailorResume,
    content: [
      {
        action: {
          type: 'openTip',
          target: '#openTipModal',
        },
        label: 'Create and tailor your resume',
      },
    ],
  },
  {
    name: TaskNames.tailorCoverLetter,
    content: [
      {
        action: {
          type: 'openTip',
          target: '#openTipModal',
        },
        label: 'Create and tailor your cover letter',
      },
    ],
  },
];

const APPLIED_ACTIONS: TaskObject[] = [
  {
    name: TaskNames.practiceInterview,
    isAI: true,
    content: [
      {
        action: {
          type: 'useAITool',
          target: 'Interview Preparation',
        },
        label: 'Use our AI tool',
      },
      {
        label: ' to prepare for typical interview questions',
      },
    ],
  },
  {
    name: TaskNames.reviewInterviewTips,
    content: [
      {
        action: {
          type: 'openTip',
          target: '#openTipModal',
        },
        label: 'Review interview preparation tips',
      },
    ],
  },
  {
    name: TaskNames.prepareInterviewTechniques,
    content: [
      {
        label: 'Prepare for industry-specific interview techniques such as Case Studies or Code Interviews',
      },
    ],
  },
];

const INTERVIEWING_ACTIONS: TaskObject[] = [
  {
    name: TaskNames.checkNews,
    content: [
      {
        isLink: true,
        to: '#googleNews',
        label: 'Check recent news about the company',
      },
    ],
  },
  {
    name: TaskNames.researchInterviewer,
    content: [
      {
        isLink: true,
        to: '#networkingLinkedin',
        label: 'Research your interviewer',
      },
      {
        label: ' to learn about their experience and identify common ground',
      },
    ],
  },
  {
    name: TaskNames.prepareQuestions,
    isAI: true,
    content: [
      {
        action: {
          type: 'useAITool',
          target: 'Interview Preparation',
        },
        label: 'Prepare questions for the interviewer',
      },
      {
        label: ' with our AI tools',
      },
    ],
  },
  {
    name: TaskNames.reviewInterviewDosAndDonts,
    content: [
      {
        label: 'Review our ',
      },
      {
        action: {
          type: 'openTip',
          target: '#openTipModal',
        },
        label: 'Interview Do’s and Don’ts',
      },
    ],
  },
];

const OFFER_ACTIONS: TaskObject[] = [
  {
    name: TaskNames.researchSalaries,
    content: [
      {
        action: {
          type: 'openTip',
          target: '#salariesResearch',
        },
        label: 'Research comparable salaries',
      },
      {
        label: ' to prepare for your offer negotiation',
      },
    ],
  },
  {
    name: TaskNames.prepareNegotiation,
    content: [
      {
        action: {
          type: 'openTip',
          target: '#negotiate',
        },
        label: 'Review our offer negotiation tips',
      },
      {
        label: ' - always negotiate your offer!',
      },
    ],
  },
  {
    name: TaskNames.evaluateOffer,
    content: [
      {
        label: 'Accept or decline your offer',
      },
    ],
  },
];

// INFO CONTENT
const COMPANY_SAVED_INFO = {
  title: 'This is just the beginning!',
  text: '80% of all jobs are filled through professional networking and candidates with referrals have much higher chances of advancing to the interview stage. Start networking with this company to gain unique insights and maximize your chances of getting in.',
};

const NETWORKING_INFO = {
  title: 'Slow is smooth and smooth is fast.',
  text: 'This stage is crucial! 80% of jobs are filled through professional networking. Instead of blindly applying on Linkedin, learn more about the industry or role that you are trying to break into and build a network of advocates using our messaging templates and tips.',
  buttonText: 'Learn more about this step',
};

const APPLYING_INFO = {
  title: 'Let’s get ready!',
  text: 'Before you smash that “Apply” button, let’s make sure your application documents are on point and take a moment to reflect whether this position is right for you.',
};
const APPLIED_INFO = {
  title: 'Practice makes perfect!',
  text: (
    <>
      In this stage, we will work on:
      <ul>
        <li>Practicing your elevator pitch & career story</li>
        <li>Revisit the “big 4” interview questions</li>
        <li>Perfect the STAR method</li>
        <li>Follow-up with contacts to optimally prepare for your interviews</li>
      </ul>
    </>
  ),
};

const INTERVIEWING_INFO = {
  title: 'Time to bring it home!',
  text: 'Congrats, you made it to the interview stage! You have done your homework and practiced your pitch. Take some time to read through some final tips below to make sure you are ready for the big day.',
};

const OFFER_INFO = {
  title: 'You did it!',
  text: 'Amazing job! You pulled it off and received an offer. Now let’s make sure you get what you deserve and check out the negotiation tips below. Oftentimes, an offer is just a starting point for your salary negotiation.',
};

const REJECTED_INFO = {
  title: 'Keep your head up!',
  text: 'We recommend you take a moment to reflect on your application and interview process to see if there are any areas you can improve on. Remember, every rejection is a step closer to your dream job!',
};

const ARCHIVE_INFO = {
  title: 'Company is currently in your archive',
  text: 'If you want to continue working on this company, you can bring it back to your Overview Board by restoring it.',
  button: 'Restore Company',
};

// TIPS
const COMPANY_SAVED_TIP = {
  title: 'Let’s kick things off!',
  subtitle:
    'Congrats! You’ve officially started your journey with this company.',
  text: 'Check out the tip box below and find out how you can optimize your Linkedin profile and start reaching out to new contacts to get your dream job.',
  buttonText: 'View initial tips',
};

const NETWORKING_TIP = {
  title: 'Become a networking pro!',
  subtitle:
    'You don’t feel comfortable reaching out to strangers for insights? Don’t worry! With our messaging templates and step-by-step guide to informational interviews (a.k.a. coffee chats), you will become a networking pro in no time.',
  text: 'Guide to informational interviews:',
  textChildren: [
    'The most important step: get started! Use our messaging templates to reach out to contacts.',
    'Don’t be shy! People who respond to your outreach actually want to help you, and expect nothing in return! They love talking about themselves and their jobs, especially when you make them feel like an expert whose insights you truly appreciate. They have been in your shoes and want to help.',
    'Remember that you are not just trying to get a referral, but to build a meaningful relationship. Always do your homework and research your contact to have a personalized, fruitful conversation.',
  ],
  buttonText: 'Step-by-step guide for coffee chats',
};

const APPLYING_TIP = {
  title: 'Document Preparation Tips',
  subtitle:
    'Time to prepare your application documents! In order to stand out from the crowd, it is extremely important to tailor your application documents specifically to the role you are applying for.',
  text: 'In the tip box below, you will find tips and tricks on making your Resume and Cover Letter convincing and easy to read for hiring managers. We will show you how to highlight your top skills and show your previous experience and successes.',
  buttonText: 'Optimize my documents',
};

const APPLIED_TIP = {
  title: 'Interview Preparation Tips',
  subtitle:
    'So you’ve made it to the interview round, now what? We believe a successful interview is a result of being well prepared and practice, practice, practice. You want to leave the interview with the feeling that you answered each question confidently and that you left a good impression on the hiring manager for why they should hire you.',
  text: 'In order to showcase your skills and leverage your previous experiences successfully, we’ve highlighted some frameworks and questions that will help you prepare your answers.',
  buttonText: 'Nail my interview questions',
};

const INTERVIEWING_TIP = {
  title: 'Do’s and Don’ts: Interview Tips',
  subtitle:
    'You’re in the home stretch. Let’s end strong and cover all the bases to leave a great impression!',
  text: 'Now that you’ve prepared and practiced your interview answers to perfection, we just have a few last minute reminders we feel you shouldn’t forget.',
  buttonText: 'Last minute tips & tricks',
};

const OFFER_TIP = {
  title: 'Let’s bring it home!',
  subtitle:
    'Let’s bring it home! The hard part is over and you’ve landed the job.',
  text: 'As you celebrate all your hard work paying off, remember that a big part of this stage is making sure you take the time to evaluate your offer package. We included a few tips on how to approach this so you get what you deserve!',
  buttonText: 'Prepare for negotiation',
};

// Are you sure modal

const COMPANY_SAVED_MODAL = {
  next: 'Thank you, I’ll proceed with networking',
  back: 'Let’s go back to Saved Company',
  text: 'We strongly recommend to complete all steps on the Company Saved stage before going through to the networking stage. This is the first step to forming professional relationships. Do you still want to proceed?',
};

const NETWORKING_MODAL = {
  next: 'Thank you, I’ll proceed with applying',
  back: 'Let’s go back to Networking',
  text: 'We strongly recommend to complete all steps on the Networking stage before applying for a position. We believe that this will greatly increase your chances of getting hired. Do you still want to proceed?',
};

const APPLYING_MODAL = {
  next: 'Thank you, I’ll proceed with applied',
  back: 'Let’s go back to Applying',
  text: 'We strongly recommend to complete all steps on the applying stage before starting the next stage. We believe that this will greatly increase your chances of getting hired. Do you still want to proceed?',
};

const APPLIED_MODAL = {
  next: 'Thank you, I’ll proceed with interviewing',
  back: 'Let’s go back to Applied',
  text: 'We strongly recommend to complete all steps on the applied stage before starting the interviewing stage. We believe that this will greatly increase your chances of getting hired. Do you still want to proceed?',
};
const INTERVIEWING_MODAL = {
  next: 'Thank you, I’ll proceed with offer',
  back: 'Let’s go back to Interviewing',
  text: 'We strongly recommend to complete all steps on the interviewing stage before finishing. We believe that this will greatly increase your chances of getting hired. Do you still want to proceed?',
};

// Resources box

const COMPANY_SAVED_RESOURCES = [
  {
    title: 'Linkedin Guys',
    description: 'Improve your Linkedin profile',
    link: 'https://courses.breakinto.tech/courses/linkedin?th__ug=9bccdc8d&th__c=goesade',
    image: LinkedinGuysLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['ESADE'],
  },
];

const NETWORKING_RESOURCES = [
  {
    title: 'CareerOS Tips',
    description: 'Learn more about this Networking Stage',
    link: '#coffeeChat',
    image: CareerOSLogo,
    buttonLabel: 'See tip box',
    linkTarget: '_self',
    forUniversities: ['ESADE'],
  },
  {
    title: 'Firsthand',
    description: 'Get advising with alumni & external experts',
    link: 'https://firsthand.co/',
    image: VaultLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['ESADE'],
  },
  {
    title: 'Resume Worded',
    description: 'Create an unbeatable Linkedin',
    link: 'https://resumeworded.com/linkedin-review/optimize-linkedin-profile',
    image: ResumeWordedLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['CareerOS University'],
  },
];

const APPLYING_RESOURCES = [
  {
    title: 'Vmock Career Fit',
    description: 'Optimize your CV',
    link: 'https://www.vmock.com/',
    image: VMockLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['ESADE', 'HEC'],
  },
  {
    title: 'Graduates First',
    description: 'Prepare for selection processes',
    link: 'https://www.graduatesfirst.com/university-career-services/ESADE',
    image: GraduateFirstLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['ESADE'],
  },
  {
    title: 'Casecoach',
    description: 'Practice case cracking',
    link: 'https://casecoach.com/',
    image: CaseCoach,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['HEC'],
  },
];

const APPLIED_RESOURCES = [
  {
    title: 'Casecoach',
    description: 'Practice case cracking',
    link: 'https://casecoach.com/',
    image: CaseCoach,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['ESADE', 'HEC'],
  },
  {
    title: 'AI Mock Interviews ',
    description: 'Stay prepared with AI Mock Interviews',
    link: 'https://www.mockinterviewer.ai/',
    image: MockInterviewerLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['CareerOS University'],
  },
];

const INTERVIEWING_RESOURCES = [
  {
    title: 'Casecoach',
    description: 'Practice case cracking',
    link: 'https://casecoach.com/',
    image: CaseCoach,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['ESADE', 'HEC'],
  },
  {
    title: 'Gamma',
    description: 'Present for success!',
    link: 'https://gamma.app/',
    image: GammaAppLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['CareerOS University'],
  },
  {
    title: 'Interview Warmup',
    description: 'Relax!',
    link: 'https://grow.google/certificates/interview-warmup/',
    image: GoogleLogo,
    buttonLabel: 'Visit now',
    linkTarget: '_blank',
    forUniversities: ['CareerOS University'],
  },
];

export const trackerTipsLocation: { [index: string]: any } = {
  '#openTipModal': {
    tab: '',
    tipName: '',
  },
  '#idealContact': {
    tab: 'Ideal Contacts',
    tipName: '',
  },
  '#optimizeProfile': {
    tab: 'LinkedIn Profile',
    tipName: '',
  },
  '#completeCoffeeChat': {
    tab: 'During',
    tipName: '',
  },
  '#thankYouNote': {
    tab: 'After',
    tipName: 'Thank you note',
  },
  '#customizeResume': {
    tab: 'At a glance',
    tipName: 'Customize your Resume',
  },
  '#resumeTemplates': {
    tab: 'Resume Deep Dive',
    tipName: 'Check out our Resume templates',
  },
  '#coverLetterTemplates': {
    tab: 'Cover Letter Deep Dive',
    tipName: 'Check out our Cover Letter templates',
  },
  '#customizeCoverLetter': {
    tab: 'At a glance',
    tipName: 'Customize your Cover Letter',
  },
  '#advancedResearch': {
    tab: 'Prepare your career story',
    tipName: 'Advanced Company Research',
  },
  '#typicalQuestions': {
    tab: 'Personal Fit',
    tipName: 'Typical Questions',
  },
  '#careerStory': {
    tab: 'Prepare your career story',
    tipName: 'Articulate your motivation',
  },
  '#prepareQuestions': {
    tab: 'Interview Do’s',
    tipName: 'Ask your own questions',
  },
  '#techSetup': {
    tab: 'Check your tech',
    tipName: '',
  },
  '#negotiate': {
    tab: 'Negotiate',
    tipName: 'Always Negotiate!',
  },
  '#salariesResearch': {
    tab: 'Prepare',
    tipName: 'Research comparable salaries',
  },
  '#prepareNegotiation': {
    tab: 'Prepare',
    tipName: 'Prepare your negotiation',
  },
  '#evaluateAndDecide': {
    tab: 'Evaluate & Decide',
    tipName: 'Evaluate & Decide',
  },
};

const trackerData = (companyName: string) => ({
  saved: {
    actions: COMPANY_SAVED_ACTIONS(companyName),
    info: COMPANY_SAVED_INFO,
    tip: COMPANY_SAVED_TIP,
    button: 'Start Networking',
    areYouSure: COMPANY_SAVED_MODAL,
    tipTitle: 'Getting the ball rolling',
    resources: COMPANY_SAVED_RESOURCES,
  },
  networking: {
    actions: NETWORKING_ACTIONS,
    info: NETWORKING_INFO,
    tip: NETWORKING_TIP,
    button: 'I’m ready to apply',
    areYouSure: NETWORKING_MODAL,
    tipTitle: 'Coffee Chat Tips',
    resources: NETWORKING_RESOURCES,
  },
  applying: {
    actions: APPLYING_ACTIONS,
    info: APPLYING_INFO,
    tip: APPLYING_TIP,
    button: 'I have submitted my application',
    areYouSure: APPLYING_MODAL,
    tipTitle: 'Document Preparation Tips',
    resources: APPLYING_RESOURCES,
  },
  applied: {
    actions: APPLIED_ACTIONS,
    info: APPLIED_INFO,
    tip: APPLIED_TIP,
    button: 'A date for my interview has been set',
    areYouSure: APPLIED_MODAL,
    tipTitle: 'Interview Preparation Tips',
    resources: APPLIED_RESOURCES,
  },
  interviewing: {
    actions: INTERVIEWING_ACTIONS,
    info: INTERVIEWING_INFO,
    tip: INTERVIEWING_TIP,
    button: 'I have received an offer',
    areYouSure: INTERVIEWING_MODAL,
    tipTitle: 'Do’s and Don’ts: Interview Tips',
    resources: INTERVIEWING_RESOURCES,
  },
  offer: {
    actions: OFFER_ACTIONS,
    info: OFFER_INFO,
    tip: OFFER_TIP,
    button: 'I have accepted or declined my offer',
    tipTitle: 'Negotiate your offer',
  },
  reject: {
    info: REJECTED_INFO,
  },
  archive: {
    info: ARCHIVE_INFO,
  },
});
export default trackerData;
