import { useContext, useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import { Button, InfoModal } from '@careeros/coco';
import { useQueryClient } from 'react-query';
import * as Sentry from '@sentry/react';
import { TabsHeader, TabsContent, withTabs } from '@/components/tabs/';
import ResumeComments from '../../../resume/components/resume-comments/resume-comments';
import { Job } from '@/domains/core/company/types';
import { AddCommentToResume } from '@/domains/core/advisor/components/add-commnet-to-resume/add-comment-to-resume';
import { BaseResumeScoreResponse, ResumeResponse, ResumeSchema } from '@/domains/core/resume/types';
import { approveResumeReview, getBaseResumeScore } from '@/services/api/resume';
import { ScoreInfo } from '@/domains/core/resume';
import './resume-comments-sidebar.scss';
import { ScoringAnchorsProvider } from '@/pages/context/scoring-anchors-resume';
import { useCheckResumeReviewStatus } from '@/services/queries/advisor';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ModalContext } from '@/components/modal/modal-provider';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  resume: ResumeResponse;
  job: Job
  url: string;
  type: 'base' | 'tailored'
};

const ResumeCommentsSidebar = ({
  resume, job, url, type,
}: Props) => {
  const { trackEvent } = useAnalytics();
  const [showCommentInput, setShowCommentInput] = useState(false);
  const [originalScore, setOriginalScore] = useState<BaseResumeScoreResponse | null>(null);
  const { data: isReviewRequested } = useCheckResumeReviewStatus(resume.id, {
    enabled: !!resume.id,
  });
  const queryClient = useQueryClient();
  const { addToast }: AddToastType = useContext(ToastContext);
  const { openModal, closeModal } = useContext(ModalContext) as any;

  if (!resume) return null;

  const getOriginalScore = async (resumeObject: ResumeSchema) => {
    const response = await getBaseResumeScore(resume.id, resumeObject);
    setOriginalScore(response);
  };

  const toggleCommentInput = () => {
    setShowCommentInput(!showCommentInput);
    trackEvent('CA Resume Add Comment Clicked');
  };

  const approveResume = async () => {
    try {
      await approveResumeReview(resume.id, url);
      queryClient.invalidateQueries(['check-resume-review-status']);
      queryClient.invalidateQueries(['combinedResume']);
      addToast({
        type: 'success',
        message: 'Success',
        additionalMessage: 'Resume approved. Email sent to student.',
      });
    } catch (error) {
      Sentry.captureException(error);
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again later.',
      });
    } finally {
      closeModal();
    }
  };

  const openApproveResumeModal = () => {
    openModal(
      <InfoModal
        icon="bi bi-rocket-takeoff"
        title="Do you want to approve this resume?"
        description={(
          <div>
            Are you happy with the student’s current resume? Once you approve it, the student will...
            <ul className="resume-comments-sidebar__approve-list">
              <li>...get an email notification about your approval.</li>
              <li>...have an amazing base resume they can be proud of.</li>
              <li>...potentially use this very resume to apply to jobs.</li>
            </ul>
          </div>
        )}
        buttonLabel="Approve"
        buttonIcon="bi bi-hand-thumbs-up"
        handleButtonClick={approveResume}
        secondaryButtonLabel="Cancel"
        handleSecondaryButtonClick={closeModal}
      />,
    );
  };

  const handleTabClick = (tabName: string) => {
    trackEvent(`CA Resume ${tabName} Viewed`);
  };

  useEffect(() => {
    getOriginalScore(resume.resume);
  }, [resume]);

  return (
    <ScoringAnchorsProvider>
      <div className="resume-comments-sidebar">
        <div className="resume-comments-sidebar__header">
          <div className="resume-comments-sidebar__header-tabs">
            <TabsHeader
              tabs={job ? ['Comments', 'Job Description'] : ['Comments', 'Score']}
              defaultTab="Comments"
              icons={job ? ['bi bi-chat-left-dots', 'bi bi-briefcase'] : ['bi bi-chat-left-dots', 'bi bi-speedometer2']}
              onTabClick={handleTabClick}
            />
          </div>
        </div>
        <div className="resume-comments-sidebar__content">
          <TabsContent>
            <div data-tab="Comments">
              <div className="resume-comments-sidebar__content-comments">
                {!showCommentInput && (
                  <div className="resume-comments-sidebar__content-comments-buttons">
                    <Button onClick={toggleCommentInput} outlined mode="primary" icon="bi bi-plus-circle" label="Write a Comment" size="small" />
                    {isReviewRequested && (
                      <Button onClick={openApproveResumeModal} mode="primary" icon="bi bi-check-circle" label="Approve Resume" size="small" />
                    )}
                  </div>
                )}
                {showCommentInput && (
                  <AddCommentToResume
                    id={resume.id}
                    url={url}
                    toggleCommentInput={toggleCommentInput}
                  />
                )}
              </div>
              {resume.id && <ResumeComments hasUnreadComments={() => {}} resumeId={resume.id} type={type} />}
            </div>
            <div data-tab="Score">
              {resume.id && !job && <ScoreInfo active resumeID={resume.id} resume={resume.resume} selectedResumeName={resume.resume_template.name} originalScore={originalScore} emitLatestSuggestions={() => {}} showBanner={false} />}
            </div>
            {job && (
            <div data-tab="Job Description">
              <div className="resume-comments-sidebar__job-info">
                <div className="resume-comments-sidebar__job-title">{job.title}</div>
                <div className="resume-comments-sidebar__job-description" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.description) }} />
              </div>
            </div>
            )}
          </TabsContent>
        </div>
      </div>
    </ScoringAnchorsProvider>
  );
};

export default withTabs(ResumeCommentsSidebar);
