import { useContext, useRef } from 'react';
import { useRevalidator } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { InfoModal } from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';
import { RequestResumeReviewModal } from '@/domains/core/resume/components/request-resume-review-modal/request-resume-review-modal';
import { extractFormData } from '../helpers/resume-request-format-data';
import { requestResumeReview } from '@/services/api/resume';
import { CADropdownOption } from '../types';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';

const baseURL = import.meta.env.VITE_APP_URL;

export const useResumeReview = (resumeId: string, type: 'base' | 'tailored', selfId?: string) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const revalidator = useRevalidator();
  const ref = useRef<HTMLFormElement>();
  const { addToast }: AddToastType = useContext(ToastContext);

  const handleRequestReview = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = extractFormData(event);
    if (formData.career_advisor_id) {
      const resumeURL = `${baseURL}/app/resume-os/student/${selfId}/${type}/${resumeId}`;
      const formattedComment = formData.comment.replace(/\n/g, '<br/>');
      try {
        await requestResumeReview(resumeId, formData.career_advisor_id, formattedComment, type.toUpperCase(), resumeURL);

        addToast({
          type: 'success',
          message: 'Success',
          additionalMessage: 'Successfully requested review for your resume.',
        });
      } catch (error) {
        addToast({
          type: 'error',
          message: 'Error requesting review',
          additionalMessage: 'Please try again later.',
        });
        Sentry.captureException(error);
      }
    }
    revalidator.revalidate();
    closeModal();
  };

  const handleButtonClick = () => {
    if (ref.current) {
      ref.current.externalSubmit();
    }
  };

  const requestReview = (careerAdvisors: CADropdownOption[]) => {
    openModal(
      <InfoModal
        icon="bi bi-send-exclamation"
        title="Submit Your Base Resume For Review"
        description={(
          <RequestResumeReviewModal
            careerAdvisors={careerAdvisors}
            onSubmit={handleRequestReview}
            ref={ref}
          />
        )}
        buttonLabel="Submit"
        buttonIcon="bi bi-arrow-right"
        handleButtonClick={handleButtonClick}
      />,
    );
  };

  return { requestReview };
};
