import { MessageType } from '../../netwoking/types';

export const getCommunicationChannel = (lastMessageType: MessageType | undefined, contactEmail: string | null): 'email' | 'linkedin' => {
  if (!lastMessageType && contactEmail) {
    return 'email';
  }
  if (lastMessageType === 'email') {
    return 'email';
  }
  return 'linkedin';
};
