import { DropdownListOption } from '@careeros/coco/dist/src/components/DropdownList/DropdownList';
import React, { useRef, useState } from 'react';
import { DropdownList, Tooltip } from '@careeros/coco';
import { PopupBoxWrapper } from '../../popup-box/popup-box';
import './dropdown-menu-button.scss';

type Props = {
  children: React.ReactNode;
  tooltipLabel?: string;
  dropdownHorizontalAlignment?: 'left' | 'right';
  dropdownOptions: DropdownListOption[];
  scrollContainerQuery?: string;
};

export const DropdownMenuButton = ({
  children,
  dropdownOptions,
  dropdownHorizontalAlignment = 'right',
  tooltipLabel,
  scrollContainerQuery,
}: Props) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const toggleDropdown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    setIsDropdownOpen((prevValue) => !prevValue);
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div className="dropdown-menu-button" ref={buttonRef}>
      <Tooltip
        label={tooltipLabel || 'Actions'}
        position="top"
        withArrow
        disableHoverListener={!tooltipLabel}
      >
        <div onClick={toggleDropdown}>
          {children}
        </div>
      </Tooltip>

      {isDropdownOpen && (
        <div className={`dropdown-menu-button__dropdown-container dropdown-menu-button__dropdown-container--align-${dropdownHorizontalAlignment}`}>
          <PopupBoxWrapper scrollContainerQuery={scrollContainerQuery} handleBlur={closeDropdown} parentRef={buttonRef}>
            <div className={`dropdown-menu-button__dropdown dropdown-menu-button__dropdown--align-${dropdownHorizontalAlignment}`}>
              <DropdownList
                handleClose={closeDropdown}
                parentRef={buttonRef}
                options={dropdownOptions}
                iconSize="small"
              />
            </div>
          </PopupBoxWrapper>
        </div>
      )}
    </div>
  );
};
