import { getSelectedStatusLabel } from './status-options';

export const getStudentsResumeFiltersFromSearchParams = (searchParams: URLSearchParams) => {
  const search = searchParams.get('search');
  const status = searchParams.get('status');
  const minScore = searchParams.get('minScore');
  const maxScore = searchParams.get('maxScore');

  const filters: {
    search?: string;
    status?: string;
    scoreRange?: [number, number];
  } = {};

  if (search) {
    filters.search = search;
  }
  if (status) {
    filters.status = getSelectedStatusLabel(status) || 'All Statuses';
  }
  if (minScore || maxScore) {
    filters.scoreRange = [
      parseInt(minScore || '0', 10),
      parseInt(maxScore || '100', 10),
    ];
  }

  return filters;
};
