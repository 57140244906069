import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import '../styles/student-overview.scss';
import { DocumentTable } from '@/domains/core/student';
import { TabsContent, TabsHeader, withTabs } from '@/components/tabs';
import { CACommentInputProvider } from '@/domains/core/advisor/contexts/CA-comment-input/CA-comment-input';
import { StudentContextProvider } from '@/domains/core/advisor/contexts/student-context/student-context-provider';
import { StudentsProfile } from '@/domains/core/profile/profile';
import {
  useGetAllStudentResumesQueries, useStudentApplications, useStudentByID, useStudentComments, useStudentDocuments,
} from '@/services/queries/student';
import { useStudentNotes } from '@/services/queries/advisor';
import CustomWithAuthenticationRequired from '../auth/custom-protected-route';
import {
  StudentContactsTab, StudentNotesTab, StudentOBHeader, StudentOBTab, StudentOverviewActions, StudentResumesTab,
} from '@/domains/core/advisor/components';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { useMobileWarning } from './hooks/use-mobile-warning';

function StudentOverviewPage() {
  const { studentID } = useParams();
  const { data: commentsResponse } = useStudentComments(studentID, { enabled: !!studentID });
  const { data: student, isLoading: isStudentLoading } = useStudentByID(studentID || '', { enabled: !!studentID });
  const { data: studentDocuments } = useStudentDocuments(studentID || '', { enabled: !!studentID });
  const { data: studentApplications, isLoading: isApplicationsLoading } = useStudentApplications(studentID || '', { enabled: !!studentID });
  const { data: studentBaseResumes, isLoading: isStudentBaseResumesLoading } = useGetAllStudentResumesQueries(studentID ?? '', {
    enabled: !!studentID,
  });
  const { data: notes } = useStudentNotes(studentID ?? '', {
    enabled: !!studentID,
  });
  const tabCounters = useMemo(() => ({
    Resumes: studentBaseResumes?.length || 0,
    Notes: notes?.length || 0,
    Documents: studentDocuments?.length || 0,
  }), [studentBaseResumes, studentDocuments, notes]);
  const { trackEvent } = useAnalytics();

  const tabTooltips = useMemo(() => ({
    'Overview Board': 'View and track all saved companies in Kanban or table format.',
    Contacts: 'View networking progress of all saved contacts in Kanban or table format.',
    Profile: 'View student’s work experience, education, targets and preferences.',
    Resumes: 'Access all uploaded resumes, both base and job-tailored.',
    Notes: 'Notes are shared across all Career Advisors.',
    Documents: 'Access all job search materials: resumes, cover letters, videos.',
  }), []);

  useAdvisorRoleCheck();
  useMobileWarning();

  const tabs = [
    'Overview Board',
    'Contacts',
    'Profile',
    'Resumes',
    'Notes',
    'Documents',
  ];

  const icons = [
    'bi bi-kanban',
    'bi-person-vcard',
    'bi bi-person-square',
    'bi bi-file-earmark-text',
    'bi bi-file-earmark',
    'bi bi-filetype-doc',
  ];

  const handleTabClick = (tabName: string) => {
    trackEvent(`CA Student OB ${tabName} Tab Opened`);
  };

  if (isStudentLoading || isApplicationsLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="student-overview">
      <StudentContextProvider student={student}>
        <CACommentInputProvider>
          <div className="student-overview__top">
            <div className="student-overview__header">
              <StudentOBHeader
                name={`${student?.first_name} ${student?.last_name}`}
                email={student?.email}
                profileImage={student?.profile_picture_url}
                university={student?.university}
                cohort={student?.cohort || ''}
                studentID={student.id}
              />
              <div className="student-overview__header-actions">
                <StudentOverviewActions comments={commentsResponse || []} />
              </div>
            </div>

            <div className="student-overview__tabs">
              <TabsHeader
                tabs={tabs}
                defaultTab="Overview Board"
                icons={icons}
                withBorder
                tabCounters={tabCounters}
                tabTooltips={tabTooltips}
                onTabClick={handleTabClick}
              />
            </div>
          </div>
          <div className="student-overview__tabs-content">
            <TabsContent>
              <div data-tab="Documents" className="student-overview__tab-content">
                <DocumentTable documents={studentDocuments || []} accessType="advisor" />
              </div>
              <div data-tab="Profile" className="student-overview__tab-content">
                <StudentsProfile id={student?.id || studentID} isCAView />
              </div>
              <div data-tab="Notes" className="student-overview__tab-content">
                <StudentNotesTab studentId={student?.id || studentID} notes={notes || []} />
              </div>
              <div data-tab="Contacts" className="student-overview__overview-tab">
                <StudentContactsTab studentID={student?.id || studentID} studentName={student?.first_name} />
              </div>
              <div data-tab="Resumes" className="student-overview__tab-content">
                <StudentResumesTab
                  studentID={student?.id || studentID}
                  studentName={student?.first_name}
                  resumes={studentBaseResumes || []}
                  isLoading={isStudentBaseResumesLoading}
                />
              </div>
              <div className="student-overview__overview-tab" data-tab="Overview Board">
                <StudentOBTab
                  studentFirstName={student?.first_name}
                  studentLastName={student?.last_name}
                  tiles={studentApplications || []}
                  studentID={student?.id}
                />
              </div>
            </TabsContent>
          </div>
        </CACommentInputProvider>
      </StudentContextProvider>
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(StudentOverviewPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
}));
