import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import DOMPurify from 'dompurify';
import './resume-comments.scss';
import {
  Tooltip, AccordionWithArrows, RotatingIcon, DefaultLogo, Counter,
} from '@careeros/coco';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import { useGetResumeComments } from '@/services/queries/resume';
import { resolveResumeComment } from '@/services/api/resume';
import { useResumeBuilderContext } from '@/pages/context/resume-builder';
import { ResumeComment } from '../../types';
import { convertNewLinesToHtml } from '@/services/helpers/messages';
import { useSelf } from '@/services/queries/user';
import { AdvisorCommentType } from '../../types/comments';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';

const baseURL = import.meta.env.VITE_APP_URL;

type ResumeCommentsProps = {
  resumeId: string;
  hasUnreadComments: (hasUnreadComments: boolean) => void;
  type: 'base' | 'tailored';
};

const ResumeComments = ({ resumeId, hasUnreadComments, type }: ResumeCommentsProps) => {
  const { data: comments, isLoading } = useGetResumeComments(resumeId, { enabled: !!resumeId });
  const { addComments } = useResumeBuilderContext();
  const queryClient = useQueryClient();
  const [sectionOpenCommentsExpanded, setSectionOpenCommentsExpanded] = useState(true);
  const [sectionResolvedCommentsExpanded, setSectionResolvedCommentsExpanded] = useState(false);
  const [openComments, setOpenComments] = useState<ResumeComment[]>([]);
  const [resolvedComments, setResolvedComments] = useState<ResumeComment[]>([]);
  const { data: self } = useSelf();
  const { completeActionIfAvailable } = useGamification();
  const handleExpandOpenComments = () => {
    setSectionOpenCommentsExpanded(!sectionOpenCommentsExpanded);
    setSectionResolvedCommentsExpanded(false);
  };

  const handleExpandResolvedComments = () => {
    setSectionResolvedCommentsExpanded(!sectionResolvedCommentsExpanded);
    setSectionOpenCommentsExpanded(false);
  };

  const handleResolveComment = async (commentId: string) => {
    const resumeURL = `${baseURL}/app/resume-os/student/${self?.id}/${type}/${resumeId}`;
    await resolveResumeComment(commentId, resumeURL);
    completeActionIfAvailable(GAMIFICATION_ACTIONS.RESOLVE_COMMENT);
    queryClient.invalidateQueries(['resumeComments', resumeId]);
  };

  useEffect(() => {
    if (comments && comments.length > 0) {
      addComments(comments);
      const unreadComments = comments.filter(comment => !comment.resolved);
      const doneComments = comments.filter(comment => comment.resolved);
      setOpenComments(unreadComments);
      setResolvedComments(doneComments);
      if (unreadComments.length > 0) {
        hasUnreadComments(true);
      } else {
        hasUnreadComments(false);
      }
    } else {
      hasUnreadComments(false);
    }
  }, [comments]);

  if (isLoading) return <div className="resume-comment__loading">Loading...</div>;

  return (
    <div className="resume-comments">
      <AccordionWithArrows
        toggle={(
          <div onClick={openComments.length > 0 ? handleExpandOpenComments : () => {}} className={sectionOpenCommentsExpanded ? 'resume-comments__section resume-comments__section--expanded' : 'resume-comments__section'}>
            <h3 className="resume-comments__section-title">
              Open Comments
              <Counter counter={(openComments.length) || 0} size="small" mode="default" />
            </h3>
            {openComments.length > 0 && <RotatingIcon expanded={sectionOpenCommentsExpanded} />}
          </div>
        )}
        isOpen={sectionOpenCommentsExpanded}
      >
        {comments && Array.isArray(comments) && comments?.filter(comment => !comment.resolved)?.map(comment => (
          <div key={comment.id} className="resume-comments__comment">
            <DefaultLogo type="contact" source={comment.author_image} className="resume-comments__comment-avatar" name={`${comment.author_first_name} ${comment.author_last_name}`} />
            <p className="resume-comments__comment-from">{`${comment.author_first_name} ${comment.author_last_name}`}</p>
            <p className="resume-comments__comment-date">
              {formatDateDeltaFromNow(comment.created_at)}
            </p>
            {(comment.kind !== AdvisorCommentType.reviewRequest && self?.role === 'student') && (
              <div className="resume-comments__comment-cta" onClick={() => handleResolveComment(comment.id)}>
                <i className="bi normal bi-check-circle" />
                <Tooltip position="top" label="Resolve Comment" withArrow>
                  <i className="bi hover bi-check-circle-fill" />
                </Tooltip>
              </div>
            )}
            {comment.kind === AdvisorCommentType.reviewRequest && (
              <span className="resume-comments__comment-kind">Requested Review:</span>
            )}
            <div className="resume-comments__comment-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(convertNewLinesToHtml(comment.text)) }} />
          </div>
        ))}
      </AccordionWithArrows>
      <AccordionWithArrows
        toggle={(
          <div onClick={resolvedComments?.length > 0 ? handleExpandResolvedComments : () => {}} className={sectionResolvedCommentsExpanded ? 'resume-comments__section resume-comments__section--expanded' : 'resume-comments__section'}>
            <h3 className="resume-comments__section-title">
              Resolved Comments
              <Counter counter={(resolvedComments.length) || 0} size="small" mode="default" />
            </h3>
            {resolvedComments?.length > 0 && <RotatingIcon expanded={sectionResolvedCommentsExpanded} />}
          </div>
        )}
        isOpen={sectionResolvedCommentsExpanded}
      >
        {comments && Array.isArray(comments) && comments?.filter(comment => comment.resolved).map(comment => (
          <div key={comment.id} className="resume-comments__comment">
            <DefaultLogo type="contact" source={comment.author_image} className="resume-comments__comment-avatar" name={`${comment.author_first_name} ${comment.author_last_name}`} />
            <p className="resume-comments__comment-from">{`${comment.author_first_name} ${comment.author_last_name}`}</p>
            <p className="resume-comments__comment-date">
              {formatDateDeltaFromNow(comment.created_at)}
            </p>
            <div className="resume-comments__comment-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(convertNewLinesToHtml(comment.text)) }} />
          </div>
        ))}
      </AccordionWithArrows>
    </div>
  );
};

export default ResumeComments;
