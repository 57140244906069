import './contact-tile-message.scss';

type Props = {
  message: string;
  type: 'email' | 'linkedin';
};

export const ContactTileMessage = ({ message, type }: Props) => (
  <div className="contact-tile-message">
    <div className="contact-tile-message__icon">
      <i className={`bi bi-${type === 'email' ? 'envelope' : 'linkedin'}`} />
    </div>
    <div className="contact-tile-message__message">
      {message}
    </div>
  </div>
);
