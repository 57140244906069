/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, StyleSheet, Font,
  Link,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';
import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';

import {
  computeSkillsStyle,
  renderAdditionalHighlightedText,
  renderWorkHighlightedText,
} from '../helpers/utils';

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: 400 }, { src: CalibriBold, fontWeight: 700 }, { src: CalibriItalic, fontStyle: 'italic' }] });

Font.registerHyphenationCallback(word => (
  [word]
));

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Calibri',
    width: '100%',
    padding: '20px 0px',
  },
  mainHeader: {
    flexDirection: 'column',
    backgroundColor: '#8397b0',
    padding: '10px 40px 10px 40px',
    color: '#fff',
    marginBottom: 10,
    marginTop: -20,
  },
  headerText: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    fontSize: 24,
  },
  contactInfo: {
    flexDirection: 'column',
    fontSize: 10,
  },
  contactInfoRow: {
    marginBottom: 3,
  },
  normalText: {
    fontSize: 10,
  },
  dateText: {
    fontSize: 10,
    color: '#8397b0',
  },
  sectionHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 0,
    textTransform: 'uppercase',
    color: '#8397b0',
  },
  sectionLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#8397b0',
    width: '100%',
    marginBottom: 4,
  },
  columnContainer: {
    flexDirection: 'column',
    padding: '10px 40px 20px 40px',
    flexGrow: 0,
    breakInside: 'avoid',
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  educationBlock: {
    marginBottom: 10,
    flexDirection: 'row',
    minHeight: 'auto',
    width: '100%',
  },
  dateColumn: {
    width: '20%',
    alignItems: 'flex-end',
    flexShrink: 0,
  },
  detailsColumn: {
    width: '80%',
    flexShrink: 0,
  },
  footer: {
    position: 'absolute',
    bottom: 20,
    left: 40,
    right: 40,
    height: 1,
    backgroundColor: '#000',
  },
  detailText: {
    fontSize: 10,
    marginLeft: 5,
  },
  column: {
    flexDirection: 'column',
  },
  skillsRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  skillContent: {
    fontSize: 10,
    marginLeft: 15,
    width: '70%',
  },
  sectionContainer: {
    flexDirection: 'column',
    padding: '10px 40px 0px 40px',
    flexGrow: 0,
  },
  pageBreakSection: {
    flexDirection: 'column',
    padding: '0px 40px 0px 40px',
    flexGrow: 0,
  },
  additionalTitle: {
    width: '20%',
  },
  additionalContent: {
    width: '80%',
  },
  aditionalLabel: {
    fontSize: 10,
    color: '#8597b0',
  },
  highlights: {
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: 10,
    marginLeft: 5,
    marginTop: 5,
    lineHeight: 1.1,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  highlightText: {
    fontSize: 10,
    color: '#000',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null,
};

const HHLTemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => (
  <Document title={title}>
    <Page size="A4" style={styles.page} wrap>
      <View fixed>
        <View render={({ pageNumber }) => (
          pageNumber === 1 ? (
            <View style={styles.mainHeader}>
              <Text style={styles.headerText}>{resume.basics.name}</Text>
              <View style={styles.contactInfo}>
                <View style={styles.contactInfo}>
                  <Text style={styles.contactInfoRow}>
                    {`Address: ${resume.basics.location?.address}${(resume.basics.location?.address && resume.basics.location?.city) ? ', ' : ''}${resume.basics.location?.city}`}
                  </Text>
                  <Text style={styles.contactInfoRow}>
                    Phone:
                    {' '}
                    {resume.basics.phone_numbers?.[0]}
                    {' | '}
                    Email:
                    {' '}
                    <Link href={`mailto:${resume.basics.emails[0]}`}>
                      {resume.basics.emails[0]}
                    </Link>
                    {' | '}
                    LinkedIn:
                    {' '}
                    <Link href={resume.basics.url}>
                      {resume.basics.url}
                    </Link>
                  </Text>
                  <Text style={styles.contactInfoRow}>
                    <Text style={styles.contactInfoRow}>
                      Date of Birth:
                      {' '}
                      {resume.basics.birth_date}
                    </Text>
                    <Text style={styles.contactInfoRow}>
                      {' | '}
                      Nationality:
                      {' '}
                      {Array.isArray(resume.basics.nationality) ? resume.basics.nationality.join(', ') : resume.basics.nationality}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>
          ) : (
            <View style={{ padding: '0px 40px' }} />
          )
        )}
        />
      </View>

      {/* EDUCATION */}
      <View style={[styles.sectionContainer, { paddingTop: 0 }]}>
        <Text style={styles.sectionHeader}>EDUCATION</Text>
        <View style={styles.sectionLine} />

        {resume.education?.map((edu) => (
          <View key={edu.institution} style={styles.educationBlock}>
            <View style={styles.detailsColumn}>
              <Text style={[styles.normalText, { fontWeight: 'bold' }]}>
                {edu.institution}
              </Text>
              {edu.area && (
                <Text style={styles.normalText}>
                  {edu.area}

                  {edu.score && (
                    <>
                      {' '}
                      |
                      {' '}
                      <Text style={styles.detailText}>
                        Current GPA:
                        {' '}
                        {edu.score}
                      </Text>
                    </>
                  )}
                </Text>
              )}
              {edu.studyType && (
                <Text style={styles.detailText}>
                  {edu.studyType}
                  {' '}
                </Text>
              )}
              <View style={styles.column}>
                {edu.description?.map((desc) => (
                  <Text style={styles.detailText}>
                    •
                    {' '}
                    {desc}
                  </Text>
                ))}
              </View>

            </View>
            <View style={styles.dateColumn}>
              <Text style={styles.dateText}>
                {edu.location ? edu.location : ''}
              </Text>
              <Text style={styles.dateText}>
                {edu.startDate}
                {edu.startDate && edu.endDate && ' - '}
                {edu.endDate === 'Present' ? edu.endDate : edu.endDate}
              </Text>
            </View>
          </View>
        ))}
      </View>

      {/* WORK EXPERIENCE */}
      <View style={styles.pageBreakSection}>
        <Text style={styles.sectionHeader}>WORK EXPERIENCE</Text>
        <View style={styles.sectionLine} />
        {resume.work?.map((work, index) => (
          <View key={work.company} style={styles.educationBlock}>
            <View style={styles.detailsColumn}>
              <Text style={[styles.normalText, { fontWeight: 'bold' }]}>
                {work.name}
              </Text>
              {work.position && <Text style={styles.normalText}>{work.position}</Text>}
              <View style={styles.column}>
                {work.highlights && work.highlights.length > 0 && (
                  <View style={styles.highlights}>
                    {work.highlights.map((highlight, highlightIndex) => {
                      const cleanedHighlight = highlight.replace(/^•\s/, '');

                      return (
                        <View key={`${highlight}-${highlightIndex}`} style={styles.listItem} wrap={false}>
                          <View style={styles.highlightText}>
                            <Text style={styles.bulletPoint}>•</Text>
                            {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            </View>
            <View style={styles.dateColumn}>
              <Text style={styles.dateText}>
                {work.location ? work.location : ''}
              </Text>
              <Text style={styles.dateText}>
                {work.startDate}
                {work.startDate && work.endDate && ' - '}
                {work.endDate === 'Present' ? work.endDate : work.endDate}
              </Text>
            </View>
          </View>
        ))}
      </View>

      {/* ACTIVITIES & LEADERSHIP */}
      { resume.extracurricular_activities?.length && (
        <View style={styles.pageBreakSection}>
          <Text style={styles.sectionHeader}>ACTIVITIES & LEADERSHIP</Text>
          <View style={styles.sectionLine} />
          {resume.extracurricular_activities?.map((extracurricular) => (
            <View key={extracurricular.name} style={styles.educationBlock}>
              <View style={styles.detailsColumn}>
                <Text style={[styles.normalText, { fontWeight: 'bold' }]}>
                  {extracurricular.institution}
                </Text>
                {extracurricular.role && <Text style={styles.normalText}>{extracurricular.role}</Text>}
                <View style={styles.column}>
                  {extracurricular.description?.map((description) => (
                    <Text style={styles.detailText}>
                      •
                      {' '}
                      {description}
                    </Text>
                  ))}
                </View>
              </View>
              <View style={styles.dateColumn}>
                <Text style={styles.dateText}>
                  {extracurricular.location ? extracurricular.location : ''}
                </Text>
                {(extracurricular.startDate || extracurricular.endDate) && (
                <Text style={styles.dateText}>
                  {extracurricular.startDate}
                  {' '}
                  -
                  {' '}
                  {extracurricular.endDate === 'Present' ? extracurricular.endDate : extracurricular.endDate}
                </Text>
                )}
              </View>
            </View>
          ))}
        </View>
      )}

      {(resume.languages?.length
        || resume.skills?.length
        || resume.certificates?.length
        || resume.additional_information.subheadings.length) && (
        <View style={styles.pageBreakSection}>
          <Text style={styles.sectionHeader}>ADDITIONAL INFORMATION</Text>
          <View style={styles.sectionLine} />

          {resume.languages?.length > 0 && (
          <View style={styles.educationBlock}>
            <View style={styles.additionalTitle}>
              <Text style={styles.aditionalLabel}>Languages</Text>
            </View>

            <View style={styles.additionalContent}>
              <Text style={styles.normalText}>{resume.languages?.map((language) => `${language.language} ${language.fluency ? `(${language.fluency})` : ''}`).join(', ')}</Text>
            </View>
          </View>
          )}

          {resume.skills?.length > 0 && (
          <View style={styles.educationBlock}>
            <View style={styles.additionalTitle}>
              <Text style={styles.aditionalLabel}>Technical Skills</Text>
            </View>

            <View style={styles.additionalContent}>
              <Text style={[computeSkillsStyle(highlightedWords, activeField, styles), styles.normalText]}>{resume.skills?.map((skill) => `${skill.name} ${skill.level ? `(${skill.level})` : ''}`).join(', ')}</Text>
            </View>
          </View>
          )}

          {resume.certificates?.length > 0 && (
          <View style={styles.educationBlock}>
            <View style={styles.additionalTitle}>
              <Text style={styles.aditionalLabel}>Certificates & Trainings</Text>
            </View>

            <View style={styles.additionalContent}>
              <Text style={styles.normalText}>{resume.certificates?.map((certification) => `${certification.name} ${certification.date ? `(${certification.date})` : ''}`).join(', ')}</Text>
            </View>
          </View>
          )}

          {(() => {
            let globalBulletIndex = 0;
            return resume.additional_information?.order?.reduce((acc: JSX.Element[], item, index, array) => {
              if (item.type === 'heading') {
                const bulletPoints = [];
                let i = index + 1;
                while (i < array.length && array[i].type === 'bullet') {
                  if (array[i].value) {
                    bulletPoints.push({ value: array[i].value, index: globalBulletIndex });
                    globalBulletIndex += 1;
                  }
                  i += 1;
                }

                if (bulletPoints.length > 0) {
                  acc.push(
                    <View style={styles.educationBlock}>
                      <View style={styles.additionalTitle}>
                        <Text style={styles.aditionalLabel}>
                          {item.value}
                        </Text>
                      </View>
                      <View style={styles.additionalContent}>
                        {bulletPoints.map((bullet) => (
                          <Text style={styles.normalText}>
                            {renderAdditionalHighlightedText(bullet.value.replace(/^•\s/, ''), bullet.index, highlightedWords, activeField, styles)}
                          </Text>
                        ))}
                      </View>
                    </View>,
                  );
                }
              }
              return acc;
            }, []);
          })()}
        </View>
      )}
    </Page>
  </Document>
);

export default HHLTemplate;
