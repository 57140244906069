import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { Company, COMPANY_PROFILE_TYPE_PREMIUM } from '@/domains/core/company/types';
import { Loader } from '@/components/loader/loader';
import './styles/company.scss';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useGetSingleCompanyProfile } from '@/services/queries/company';
import BasicCompanyProfile from './company/basic-company';
import { withTabs } from '@/components/tabs';
import PremiumCompanyProfile from './company/premium-company';
import { useSelf } from '@/services/queries/user';
import { useGetContactsNoCache } from '@/services/queries/contact';
import {
  useAlgoliaInsights,
} from '@/services/hooks/use-algolia';
import { RelatedCompaniesWrapper } from '@/domains/generic';

function CompanyPage() {
  const params = useParams();
  const { companyID } = params;
  const { data: contacts, isLoading: areContactsLoading } = useGetContactsNoCache();
  const { data: companyInformation, isLoading: isCompanyLoading, isFetching: isCompanyFetching } = useGetSingleCompanyProfile(companyID, { enabled: !!companyID && companyID !== 'null' });
  const { data: self } = useSelf();
  const [company, setCompany] = useState<Company | null>(null);
  const [premium, setPremium] = useState(false);
  const insights = useAlgoliaInsights();

  useEffect(() => {
    if (companyInformation && !isCompanyLoading && !isCompanyFetching) {
      setPremium(companyInformation.profile_type === COMPANY_PROFILE_TYPE_PREMIUM);
      const mappedCompany: Company = {
        id: companyID || '',
        name: companyInformation.general_information.name,
        overview: companyInformation.overview.overview || '',
        short_description: companyInformation.general_information.description,
        hq_location: companyInformation.general_information.hq_location,
        company_url: companyInformation.general_information.website_url,
        logo_url: companyInformation.general_information.logo_url,
        linkedin_url: companyInformation.general_information.linkedin_url,
        twitter_url: companyInformation.general_information.twitter_url || '',
        instagram_url: companyInformation.general_information.instagram_url,
        industry: companyInformation.general_information.careeros_industry,
        careeros_industry: companyInformation.general_information.careeros_industry,
        tags: companyInformation.general_information.tags || [],
        jobs: [], // Add jobs if available
        company_size: companyInformation.general_information.company_size,
        bg_image_url: companyInformation.general_information.bg_image_url,
        website_url: companyInformation.general_information.website_url,
      };
      setCompany(mappedCompany);
    }
  }, [companyInformation, isCompanyLoading, isCompanyFetching]);

  // Track view after company data is loaded
  useEffect(() => {
    const trackView = async () => {
      if (
        company
        && self?.id // Make sure we have a user ID
        && !isCompanyLoading
        && !isCompanyFetching
        && !areContactsLoading
      ) {
        insights.viewedObjectIDs(
          'Company Viewed',
          'company',
          [companyID || ''],
          self.id,
        );
      }
    };

    trackView();
  }, [
    company,
    self?.id,
    premium,
    isCompanyLoading,
    isCompanyFetching,
    areContactsLoading,
  ]);

  if ((!company && (isCompanyLoading || areContactsLoading)) || isCompanyFetching) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  if (!companyInformation || !company) {
    return (
      <>
        Company not found
      </>
    );
  }

  return (
    <div className={`company-page ${premium ? 'company-page--premium' : ''}`}>
      {premium ? (
        <PremiumCompanyProfile
          company={company}
          companyOverview={companyInformation.overview}
          companyFeatures={companyInformation.features}
          events={companyInformation.events}
          culture={companyInformation.culture}
          articlesUrlProfile={companyInformation.articles_url_profile}
          contacts={contacts}
          testimonials={null}
          teams={companyInformation.teams}
          generalInformation={companyInformation.general_information}
          application={companyInformation.application_info || {}}
        />
      ) : (
        <BasicCompanyProfile
          contacts={contacts}
          company={company}
          applicationInfo={companyInformation.application_info || {}}
          articlesUrlProfile={companyInformation.articles_url_profile}
        />
      )}

      {companyID && (
        <div className="company-page__related-companies">
          <RelatedCompaniesWrapper companyId={companyID} />
        </div>
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(withTabs(CompanyPage), {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
