/* eslint-disable no-underscore-dangle */
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { Mixpanel } from './mixpanel';

function trackWithMixpanel(eventName: string, user?: any, properties?: any) {
  try {
    Mixpanel.track(eventName, { ...properties, ...user });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { eventName, service: 'Mixpanel' },
    });
  }
}

function trackWithReactGA(eventName: string) {
  try {
    ReactGA.event({
      category: 'CareerOS App',
      action: eventName,
      value: 99, // optional, must be a number
      nonInteraction: false, // optional, true/false
    });
  } catch (error) {
    Sentry.captureException(error, {
      extra: { eventName, service: 'ReactGA' },
    });
  }
}

function trackWithAppcues(eventName: string, properties?: any) {
  try {
    if ((window as any).Appcues) {
      (window as any).Appcues.track(eventName, { ...properties });
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { eventName, service: 'Appcues' },
    });
  }
}

function trackWithCio(eventName: string, properties?: any) {
  try {
    if ((window as any)._cio) {
      (window as any)._cio.track(eventName, properties);
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { eventName, service: 'CIO' },
    });
  }
}

function trackWithHotjar(eventName: string) {
  try {
    if ((window as any).hj) {
      (window as any).hj('event', eventName);
    }
  } catch (error) {
    Sentry.captureException(error, {
      extra: { eventName, service: 'Hotjar' },
    });
  }
}

const trackEvent = (eventName: string, user?: any, properties?: any) => {
  const isProd = import.meta.env.VITE_ENV === 'prod';
  const isTest = import.meta.env.VITE_ENV === 'test';

  if (isTest || isProd) {
    trackWithMixpanel(eventName, user, properties);
  }

  if (!isProd) {
    return;
  }

  trackWithReactGA(eventName);
  trackWithAppcues(eventName, properties);
  trackWithCio(eventName, properties);
  trackWithHotjar(eventName);
};

export function useAnalytics() {
  return { trackEvent };
}
