import { getResumeStatusesForTable } from '@/domains/core/resume/utils/resume-statuses';

export const buildStatusOptionsAndMappings = () => {
  const statusesMap = getResumeStatusesForTable();
  const options = ['All Statuses', ...Object.values(statusesMap).map(({ label }) => label)];

  const map = new Map<string, string>();
  map.set('All Statuses', 'All Statuses');

  Object.entries(statusesMap).forEach(([value, { label }]) => {
    map.set(label, value);
  });

  return { statusOptions: options, statusLabelToValueMap: map };
};

export const getSelectedStatusLabel = (selectedStatus: string | undefined | null) => {
  if (selectedStatus === undefined || selectedStatus === null) return 'All Statuses';
  if (selectedStatus === 'RESUME_INVALID') return 'No Requests';
  const statusesMap = getResumeStatusesForTable();
  return statusesMap[selectedStatus]?.label || 'All Statuses';
};
