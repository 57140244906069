export const industryIconFiles: Record<string, string> = {
  Finance: 'finance',
  Banking: 'banking',
  Tech: 'tech',
  Technology: 'tech',
  Consulting: 'consulting',
  Healthcare: 'healthcare',
  'Travel & Hospitality': 'travel',
  Other: 'other',
  'Transport & Logistics': 'transport',
  'Energy & Utilities': 'energy',
  'Media & Entertainment': 'media',
  'Sports & Fashion': 'sports',
  'Industrials & Manufacturing': 'industrial',
  'FMCG, Food & Retail': 'food',
  'Professional Services': 'professional',
  'Social Impact': 'social',
  'Investment Firm': 'investment',
  'Financial Services': 'financials',
};
