import { useQuery, UseQueryOptions } from 'react-query';
import { getSelf, ApiUser } from '../api/user';
import { getUniversity } from '../api/student';
import { getEmailIdentities } from '@/services/api/user';
import { getTallyData } from '../api/profile';

export const useSelf = (options?: Omit<UseQueryOptions<ApiUser, Error>, 'queryKey' | 'queryFn'>) => useQuery<ApiUser, Error>('user', getSelf, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 8,
  ...options,
});

export const useSelfUniversity = () => useQuery('user-university', getUniversity, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 24,
});

export const useIdentities = () => useQuery('identites', getEmailIdentities, {
  retry: 0,
  // should be refetched in the background every hour
  staleTime: 1000 * 60 * 60,
});

export const useIdentitiesNoCache = () => useQuery('identities-no-cache', getEmailIdentities, {
  retry: 0,
  cacheTime: 0, // refetch on tab change
});

export const useTallyFormData = (userID: string | undefined) => useQuery('tally-form-data', () => getTallyData(userID!), {
  retry: 0,
  staleTime: 1000 * 60 * 60,
  enabled: !!userID,
});
