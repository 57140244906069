/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
import { useEffect, useState } from 'react';
import { AccordionWithArrows, RotatingIcon, RangeBadge } from '@careeros/coco';
import {
  BaseResumeScoreResponse,
  ResumeSchema,
  SectionScoreName,
  TemplateNames,
} from '../../types';
import './score-info.scss';
import RadialProgress from '@/components/radial-progress/radial-progress';
import { SectionOverallLength } from './section-overall-length/section-overall-length';
import { SectionBulletsBalance } from './section-bullets-balance/section-bullets-balance';
import { SectionBulletsQuality } from './section-bullets-quality/section-bullets-quality';
import { getBaseResumeScore } from '@/services/api/resume';
import { SectionSkills } from './section-skills/section-skills';
import { SectionConsistency } from './section-consistency/section-consistency';
import { RESUME_FIELDS } from '../../templates/template-rules/resume-rules';
import { useResumeBuilderContext } from '@/pages/context/resume-builder';
import { ScoringParams } from '../../templates/template-rules/scoring-params';

type ScoreInfoProps = {
  active: boolean;
  originalScore: BaseResumeScoreResponse | null;
  resume: ResumeSchema
  emitLatestSuggestions: (suggestions: any) => void;
  selectedResumeName: TemplateNames;
  resumeID: string;
  showBanner?: boolean;
};

export const ScoreInfo = ({
  active = false, resume, originalScore, emitLatestSuggestions, selectedResumeName, resumeID, showBanner = true,
}: ScoreInfoProps) => {
  const [openedSection, setOpenedSection] = useState<SectionScoreName>();

  const [score, setScore] = useState<BaseResumeScoreResponse | null>(null);
  const { setScore: setLatestScore } = useResumeBuilderContext();

  const visibleFields = RESUME_FIELDS[selectedResumeName];

  const getScore = async (resumeObject: ResumeSchema) => {
    const scoreParams = ScoringParams[selectedResumeName];
    const response = await getBaseResumeScore(resumeID, resumeObject, scoreParams);
    if (!response) return;
    setScore(response);
    setLatestScore(response?.score);
    // todo - move these to context provider
    emitLatestSuggestions(response.suggestion);
  };

  const getSectionWithLowestScore = (): SectionScoreName => {
    const sections: Array<{ name: SectionScoreName; score: number }> = [
      { name: 'overall_length', score: score?.overall_length?.percentage_score || 0 },
      { name: 'bullets_balance', score: score?.bullets_balance?.percentage_score || 0 },
      { name: 'bullets_quality', score: score?.bullets_quality?.percent_score || 0 },
      { name: 'skills', score: score?.skills?.percent_score || 0 },
      { name: 'consistency', score: score?.consistency?.percent_score || 0 },
    ];
    return sections.reduce((min, section) => (section.score < min.score ? section : min)).name;
  };

  const toggleSection = (SectionName: SectionScoreName) => {
    if (SectionName) {
      setOpenedSection((prevSection) => (prevSection === SectionName ? undefined : SectionName));
    }
  };

  useEffect(() => {
    if (!resume) return;
    getScore(resume);
  }, [resume]);

  useEffect(() => {
    if (score) {
      const lowestScoreSection = getSectionWithLowestScore();
      setOpenedSection(lowestScoreSection);
    }
  }, [score]);

  return (
    <div className="score-info">
      <div className="score-info__score">
        <div className="score-info__original-score">
          {active && <RadialProgress startPercentage={originalScore?.score || 0} percentage={score?.score || 0} difference={(originalScore?.score || 0) - (score?.score || 0)} status={(originalScore?.score || 0) - (score?.score || 0) > 0 ? 'negative' : 'positive'} /> }
        </div>
      </div>
      <AccordionWithArrows
        toggle={(
          <div onClick={() => toggleSection('overall_length')} className={openedSection === 'overall_length' ? 'score-info__section score-info__section--expanded' : 'score-info__section'}>
            <h3 className="score-info__section-title">
              <RangeBadge label={`${score?.overall_length?.percentage_score || 0} pts`} percentageOfCompletion={score?.overall_length?.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
              Overall Length
            </h3>
            <RotatingIcon expanded={openedSection === 'overall_length'} />
          </div>
        )}
        isOpen={openedSection === 'overall_length'}
      >
        <SectionOverallLength over={score?.overall_length} showBanner={showBanner} />
      </AccordionWithArrows>
      <AccordionWithArrows
        toggle={(
          <div onClick={() => toggleSection('bullets_balance')} className={openedSection === 'bullets_balance' ? 'score-info__section score-info__section--expanded' : 'score-info__section'}>
            <h3 className="score-info__section-title">
              <RangeBadge label={`${score?.bullets_balance?.percentage_score || 0} pts`} percentageOfCompletion={score?.bullets_balance?.percentage_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
              Bullets Balance
            </h3>
            <RotatingIcon expanded={openedSection === 'bullets_balance'} />
          </div>
        )}
        isOpen={openedSection === 'bullets_balance'}
      >
        <SectionBulletsBalance data={score?.bullets_balance} showBanner={showBanner} />
      </AccordionWithArrows>
      <AccordionWithArrows
        toggle={(
          <div onClick={() => toggleSection('bullets_quality')} className={openedSection === 'bullets_quality' ? 'score-info__section score-info__section--expanded' : 'score-info__section'}>
            <h3 className="score-info__section-title">
              <RangeBadge label={`${score?.bullets_quality?.percent_score || 0} pts`} percentageOfCompletion={score?.bullets_quality?.percent_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
              Bullets Quality
            </h3>
            <RotatingIcon expanded={openedSection === 'bullets_quality'} />
          </div>
        )}
        isOpen={openedSection === 'bullets_quality'}
      >
        <SectionBulletsQuality data={score?.bullets_quality} showBanner={showBanner} />
      </AccordionWithArrows>
      {visibleFields?.skills && (
        <AccordionWithArrows
          toggle={(
            <div onClick={() => toggleSection('skills')} className={openedSection === 'skills' ? 'score-info__section score-info__section--expanded' : 'score-info__section'}>
              <h3 className="score-info__section-title">
                <RangeBadge label={`${score?.skills?.percent_score || 0} pts`} percentageOfCompletion={score?.skills?.percent_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
                Skills
              </h3>
              <RotatingIcon expanded={openedSection === 'skills'} />
            </div>
          )}
          isOpen={openedSection === 'skills'}
        >
          <SectionSkills data={score?.skills} showBanner={showBanner} />
        </AccordionWithArrows>
      )}
      <AccordionWithArrows
        toggle={(
          <div onClick={() => toggleSection('consistency')} className={openedSection === 'consistency' ? 'score-info__section score-info__section--expanded' : 'score-info__section'}>
            <h3 className="score-info__section-title">
              <RangeBadge label={`${score?.consistency?.percent_score || 0} pts`} percentageOfCompletion={score?.consistency?.percent_score || 0} rangeColors={['#ff8585', '#ffe59e', '#7bcd8c']} />
              Consistency
            </h3>
            <RotatingIcon expanded={openedSection === 'consistency'} />
          </div>
        )}
        isOpen={openedSection === 'consistency'}
      >
        <SectionConsistency data={score?.consistency} showBanner={showBanner} />
      </AccordionWithArrows>
    </div>
  );
};
