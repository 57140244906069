import {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import * as Sentry from '@sentry/react';
import {
  getJobsLanguages,
  getJobsLocations,
  searchJobs,
} from '../api/job';
import { JobFeedJob, JobsFilterState, JobsLocationsType } from '@/domains/core/job/types/job';
import useDebounceEffect from './use-debounce';
import { useAnalytics } from './use-analytics';
import { getSearchParamsString, updateQueryString } from '@/domains/core/job/helpers/convertURLParamsToFilters';
import { useTallyFormData } from '../queries/user';
import { languageMap, contractStaticOptions } from '@/domains/core/job/components/job-filters/constants';

type Props = {
  currentTab: string | null;
  userId?: string;
};

const STORAGE_KEY = 'jobFilters';
const EMPTY_FILTERS: JobsFilterState = {
  query: '',
  sizes: [],
  job_types: [],
  work_modes: [],
  industries: [],
  locations: [],
  date_range: [],
  languages: [],
  favoriteCompanies: false,
  byUniversityPartners: [],
  byCareerOSPartners: [],
  required_languages: [],
};

export const useJobsFiltering = ({ currentTab, userId }: Props) => {
  const [isPageEnd, setIsPageEnd] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [jobs, setJobs] = useState<JobFeedJob[]>([]);
  const [totalJobsCount, setTotalJobsCount] = useState<number>(0);
  const [nextPage, setNextPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [currentActiveId, setCurrentActiveId] = useState('');
  const [jobsLocations, setJobsLocations] = useState<JobsLocationsType[]>([]);
  const [jobsLanguages, setJobsLanguages] = useState<string[]>([]);
  const [currentLoadingId, setCurrentLoadingId] = useState('');
  const observerTarget = useRef<HTMLDivElement>(null);
  const { data: tallyData } = useTallyFormData(userId);

  const [filters, setFilters] = useState<JobsFilterState>(EMPTY_FILTERS);

  const getLocations = async () => {
    const locations = await getJobsLocations();
    setJobsLocations(locations);
  };

  const getLanguages = async () => {
    const languages = await getJobsLanguages();

    setJobsLanguages(languages);
  };

  useEffect(() => {
    getLocations();
    getLanguages();
  }, []);

  useEffect(() => {
    if (!tallyData || !jobsLanguages.length) return;

    try {
      const savedFilters = localStorage.getItem(STORAGE_KEY);
      const isFirstVisit = !localStorage.getItem('hasVisitedJobsPage');

      if (savedFilters && !isFirstVisit) {
        setFilters({ ...EMPTY_FILTERS, ...JSON.parse(savedFilters), query: '' });
        return;
      }

      if (isFirstVisit) {
        localStorage.setItem('hasVisitedJobsPage', 'true');

        // Process job types first
        const jobTypes = tallyData
          .filter(item => item.type === 'PREFERRED_CONTRACT_TYPES')
          .flatMap(item => item.values
            .map(value => (contractStaticOptions.some(option => option.value === value)
              ? { name: value, value }
              : null)))
          .filter((item): item is { name: string; value: string } => item !== null);

        // Process languages
        const languages = tallyData
          .filter(item => item.type === 'LANGUAGES')
          .flatMap(item => item.values
            .map(value => {
              const langCode = Object.entries(languageMap).find(([, name]) => name === value)?.[0];
              return langCode && jobsLanguages.includes(langCode)
                ? { name: value, value: langCode }
                : null;
            }))
          .filter((item): item is { name: string; value: string } => item !== null);

        const tallyFilters = {
          ...EMPTY_FILTERS,
          job_types: jobTypes,
          languages,
        };

        localStorage.setItem(STORAGE_KEY, JSON.stringify(tallyFilters));
        setFilters(tallyFilters as JobsFilterState);
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  }, [tallyData, jobsLanguages]);

  const handleSetFilters = useCallback((newFilters: JobsFilterState | ((prev: JobsFilterState) => JobsFilterState)) => {
    setFilters(prev => {
      const nextFilters = typeof newFilters === 'function' ? newFilters(prev) : newFilters;
      const filtersToStore = { ...nextFilters, query: '' };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(filtersToStore));
      return nextFilters;
    });
  }, []);

  const { trackEvent } = useAnalytics();
  const isSmallScreen = () => window.innerWidth <= 720;

  const searchQuery = useMemo(() => getSearchParamsString(filters).toString(), [filters]);
  const convertSearchForTrackerEvent = searchQuery.split('&').map(param => param.split('='));
  const isSavedJobsTabSelected = useMemo(() => currentTab === 'Saved Jobs', [currentTab]);

  const updateCurrentActiveIdBasedOnScreen = (defaultId: string) => {
    if (!isSmallScreen()) {
      setCurrentActiveId(defaultId);
    } else {
      setCurrentActiveId('');
    }
  };

  const fetchNextPageData = async () => {
    if (!isSavedJobsTabSelected && isPageEnd) {
      return;
    }

    setIsNewPageLoading(true);
    setError(null);

    try {
      const { jobs: jobsResponse, total } = await searchJobs(searchQuery, nextPage);

      setTotalJobsCount(total);

      if (!jobsResponse || !jobsResponse?.length) {
        setIsPageEnd(true);
        return;
      }

      const newJobs = [...jobs, ...jobsResponse];

      if (!currentActiveId && jobsResponse.length > 0) {
        updateCurrentActiveIdBasedOnScreen(jobsResponse[0].id);
      }

      setJobs(newJobs);
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setCurrentPage(nextPage);
    }
  };

  const fetchJobsWithFilters = async () => {
    setNextPage(1);
    setJobs([]);
    setIsPageEnd(false);
    setIsNewPageLoading(true);
    setError(null);
    setIsLoading(true);

    try {
      const { jobs: jobsResponse, total } = await searchJobs(searchQuery, 1);

      setTotalJobsCount(total);

      if (!jobsResponse || !jobsResponse?.length) {
        setIsPageEnd(true);
        setJobs([]);
        return;
      }

      if (jobsResponse.length > 0) {
        updateCurrentActiveIdBasedOnScreen(jobsResponse[0].id);
      }

      setJobs(jobsResponse);
      convertSearchForTrackerEvent.forEach(([key, value]) => {
        if (key && value) {
          trackEvent('Filter applied', {}, { filter_type: key, filter_value: value });
        }
      });

      const searchParams = new URLSearchParams(searchQuery);
      const queryValue = searchParams.get('query');

      if (queryValue) {
        trackEvent('Job title searched', {}, { search: queryValue });
      }
    } catch (err) {
      setError(err as Error);
    } finally {
      setIsNewPageLoading(false);
      setIsLoading(false);
      setCurrentPage(1);
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/app/jobs') {
      const searchString = getSearchParamsString(filters);
      updateQueryString(searchString);
    }
  }, [filters]);

  useEffect(() => {
    if (!isSavedJobsTabSelected && nextPage > currentPage && !isNewPageLoading && !isPageEnd && !isSearchActive) {
      fetchNextPageData();
    }
  }, [nextPage, currentPage, isNewPageLoading, isPageEnd]);

  useDebounceEffect(fetchJobsWithFilters, [searchQuery]);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;

    if (!observer && observerTarget.current) {
      observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setNextPage((prevPage) => prevPage + 1);
          }
        },
        { threshold: 1 },
      );
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observer && observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget.current, jobs.length, isSavedJobsTabSelected]);

  const refetchJobs = useCallback(() => {
    fetchJobsWithFilters();
  }, [searchQuery]);

  return {
    jobs,
    filters,
    observerTarget,
    isNewPageLoading,
    isLoading,
    error,
    currentActiveId,
    jobsLocations,
    jobsLanguages,
    totalJobsCount,
    currentLoadingId,
    isPageEnd,
    setFilters: handleSetFilters,
    setCurrentActiveId,
    setCurrentLoadingId,
    setTotalJobsCount,
    setJobs,
    refetchJobs,
    setIsSearchActive,
  };
};
