import { useEffect, useRef, useState } from 'react';
import { useGetChallengesQuery } from '../queries/gamification';
import { Challenge } from '@/types/gamification';

export const useAnimatedChallenges = () => {
  const { data: challenges, isLoading, error } = useGetChallengesQuery();
  const [completedChallenges, setCompletedChallenges] = useState<Challenge[]>([]);
  const [animatingChallenges, setAnimatingChallenges] = useState<Challenge[]>([]);
  const previousChallengesRef = useRef<Challenge[] | null>(null);
  const initializedRef = useRef(false);

  useEffect(() => {
    if (!challenges || !Array.isArray(challenges) || isLoading) return;

    // Get all completed challenges
    const completed = challenges.filter(challenge => challenge.is_completed);

    // If this is the first load, just set the completed challenges without animation
    if (!initializedRef.current) {
      setCompletedChallenges(completed);
      previousChallengesRef.current = completed;
      initializedRef.current = true;
      return;
    }

    // Find new completed challenges since the last check
    const previousCompleted = previousChallengesRef.current || [];
    const newlyCompleted = completed.filter(
      challenge => !previousCompleted.some(prev => prev.id === challenge.id),
    );

    // Add newly completed challenges to the animating list
    if (newlyCompleted.length > 0) {
      setAnimatingChallenges(prev => [...prev, ...newlyCompleted]);
    }

    // Update completed challenges with the latest data, excluding animating ones
    setCompletedChallenges(completed.filter(
      challenge => !newlyCompleted.some(newChallenge => newChallenge.id === challenge.id),
    ));

    // Store current completed challenges for next comparison
    previousChallengesRef.current = completed;
  }, [challenges, isLoading]);

  // Function to handle animation end for a challenge
  const handleAnimationEnd = (challengeId: string): void => {
    setAnimatingChallenges(prev => {
      // Find and remove the challenge from animating
      const animatedChallenge = prev.find(c => c.id === challengeId);
      const newAnimating = prev.filter(c => c.id !== challengeId);

      // Only add to completed if we found and removed it from animating
      if (animatedChallenge) {
        setCompletedChallenges(prevCompleted => {
          // Check if it's already in completed challenges to prevent duplicates
          const alreadyExists = prevCompleted.some(c => c.id === challengeId);
          if (!alreadyExists) {
            return [...prevCompleted, animatedChallenge];
          }
          return prevCompleted;
        });
      }

      return newAnimating;
    });
  };

  return {
    unreadChallenges: completedChallenges.filter(challenge => !challenge.is_read),
    animatingChallenges,
    handleAnimationEnd,
    setAnimatingChallenges,
    setUnreadChallenges: setCompletedChallenges,
    isLoading,
    error,
  };
};
