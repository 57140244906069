import { useQuery } from 'react-query';
import { getApplications, getApplicationsV2, getArchivedApplications } from '../api/application';

export const useArchivedCompanies = () => useQuery('archive', async () => {
  const response = await getArchivedApplications();
  return response || [];
}, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 2,
});

export const useApplications = () => useQuery('applications', async () => {
  const response = await getApplications();

  return Array.isArray(response) ? response : [];
}, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 60 * 2,
});

export const useApplicationsNoCache = () => useQuery('applications-no-cache', async () => {
  const response = await getApplications();
  return response || [];
}, {
  retry: 0,
  cacheTime: 0, // refetch on tab change
});

export const useApplicationsNoCacheV2 = () => useQuery('applications-no-cache-v2', async () => {
  const response = await getApplicationsV2();
  return response || [];
}, {
  retry: 0,
  cacheTime: 0, // refetch on tab change
});
