import React, { useState } from 'react';
import { IconButton, Tooltip } from '@careeros/coco';
import { ConnectionStatus } from '@/domains/core/contact/types';
import './contact-tile.scss';
import { StatusTag } from '@/domains/core/contact/components/status-tags/status-tag';
import { stripHTML } from '@/services/helpers/processHtml';
import { ContactTileMessage } from '@/domains/core/contact/components/contact-tile-message';
import { AvatarWithLogo } from '@/domains/core/contact/components/avatar-with-logo/avatar-with-logo';
import { SuggestedContactTag } from '@/domains/core/contact/components/suggested-contact-tag/suggested-contact-tag';

type Props = {
  name: string;
  image: string;
  companyName: string;
  companyLogo: string;
  position: string;
  linkedInConnectionStatus?: ConnectionStatus;
  isArchived?: boolean;
  message?: {
    text: string;
    type: 'email' | 'linkedin';
  };
  handleTileClick?: () => void;
  handleArchiveToggle?: (shouldArchive: boolean) => void;
  handleSave?: () => void | Promise<void>;
  handleCompanyClick: () => void;
  readonly?: boolean;
  children?: React.ReactNode;
  networkingStatusRate?: number;
  isAlumni?: boolean;
  showLanguages?: boolean;
  isCampusChampion?: boolean;
  languages?: string[];
  schoolName: string;
  campusChampionClickHandler?: () => void;
  withArchiveToggle?: boolean;
};

export const ContactKanbanTile = ({
  image,
  name,
  companyName,
  companyLogo,
  position,
  linkedInConnectionStatus,
  handleTileClick,
  isArchived = false,
  message,
  handleArchiveToggle,
  readonly = false,
  handleCompanyClick,
  children,
  networkingStatusRate = 0,
  isAlumni = false,
  showLanguages = false,
  isCampusChampion = false,
  languages = [],
  schoolName,
  campusChampionClickHandler,
  withArchiveToggle = false,
  handleSave,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const handleArchiveClick = (e: React.MouseEvent) => {
    e.stopPropagation();

    handleArchiveToggle?.(!isArchived);
  };
  const handleClicked = () => {
    if (readonly || isArchived) {
      return;
    }

    handleTileClick?.();
  };

  const handleSaveClick = async () => {
    if (handleSave) {
      setIsSaving(true);
      await handleSave();
      setIsSaving(false);
    }
  };

  return (
    <div className={`contact-kanban-tile ${readonly ? 'contact-kanban-tile--readonly' : ''}`} onClick={handleClicked}>
      <div className="contact-kanban-tile__header">
        <AvatarWithLogo
          avatarImage={image}
          logoImage={companyLogo}
          avatarName={name}
          logoName={companyName}
          handleCompanyClick={handleCompanyClick}
          isAvatarDisabled={isArchived}
          isOutlined={isCampusChampion}
        />
        <div className="contact-kanban-tile__info">
          <div className={`contact-kanban-tile__name ${isArchived ? 'contact-kanban-tile__name--archived' : ''} ${handleTileClick ? 'contact-kanban-tile__name--clickable' : ''}`}>
            {React.Children.map(children, child => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child);
              }
              return child;
            })}
            {!children && (
              <Tooltip label={name} position="top" withArrow>
                <span className="contact-kanban-tile__name-text">{name}</span>
              </Tooltip>
            )}
            {/* {isCampusChampion && (
              <span className="contact-kanban-tile__star">
                <Tooltip position="top" label="Campus Champion">
                  <span>
                    ⭐
                  </span>
                </Tooltip>
              </span>
            )} */}
          </div>
        </div>
        <div className="contact-kanban-tile__status">
          <div className={`contact-kanban-tile__archive-action ${readonly ? 'contact-kanban-tile__archive-action--hidden' : ''}`}>
            {isArchived && withArchiveToggle && (
              <Tooltip position="top" withArrow label="Save">
                <IconButton
                  icon="bi bi-bookmark-heart"
                  onClick={handleArchiveClick}
                  mode="rounded"
                  outlined
                  size="small"
                />
              </Tooltip>
            )}

            {(!isArchived && withArchiveToggle) && (
              <Tooltip position="top" withArrow label="Archive">
                <IconButton
                  icon="bi bi-archive"
                  onClick={handleArchiveClick}
                  mode="rounded"
                  outlined
                  size="small"
                  isDangerous
                />
              </Tooltip>
            )}

            {handleSave && (
              <Tooltip position="top" withArrow label="Save">
                <IconButton
                  icon="bi bi-bookmark-heart"
                  onClick={handleSaveClick}
                  mode="rounded"
                  outlined
                  size="small"
                  isLoading={isSaving}
                />
              </Tooltip>
            )}
          </div>
          {(!isArchived && linkedInConnectionStatus) && <StatusTag status={linkedInConnectionStatus} type="linkedin" size="small" withTooltip />}
        </div>
      </div>
      <div className="contact-kanban-tile__position">
        <span className="contact-kanban-tile__position-text">{position}</span>
        {networkingStatusRate > 0 && (
          <span className="contact-kanban-tile__rate">
            <Tooltip position="top" withArrow label="In your desired functional area!">
              <div>
                {new Array(Math.floor(networkingStatusRate)).fill(0).map(() => (
                  <span>🔥</span>
                ))}
              </div>
            </Tooltip>
          </span>
        )}
      </div>

      {(isCampusChampion || showLanguages || isAlumni) && (
        <>
          <div className="contact-kanban-tile__separator" />
          <div className="contact-kanban-tile__tags">
            {isAlumni && (
              <SuggestedContactTag
                label="Alumni"
                tooltipLabel={`Also studied at ${schoolName}`}
                icon="bi bi-mortarboard"
                color="purple"
              />
            )}
            {showLanguages && Array.isArray(languages) && languages.length > 0 && (
              <SuggestedContactTag
                label={languages[0]}
                tooltipLabel={`Also speaks: ${languages.join(', ')}`}
                icon="bi bi-translate"
                color="green"
              />
            )}
            {isCampusChampion && (
              <SuggestedContactTag
                label="Campus Champion"
                tooltipLabel={`These are contacts selected specifically for ${schoolName} by ${companyName}. Click to see all Campus Champions.`}
                icon="bi bi-star"
                color="primary-blue"
                handleClick={campusChampionClickHandler}
              />
            )}
          </div>
        </>
      )}

      {message && (
      <>
        <div className="contact-kanban-tile__separator" />
        <div className="contact-kanban-tile__message">
          <ContactTileMessage message={stripHTML(message.text)} type={message.type} />
        </div>
      </>
      )}
    </div>
  );
};

type SubComponentProps = {
  children: React.ReactNode;
};

ContactKanbanTile.NameWithPreview = function NameWithPreview({ children }: SubComponentProps) {
  return <div className="contact-kanban-tile__name-text">{children}</div>;
};
