import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Badge, DefaultLogo } from '@careeros/coco';
import { MiniAdvisor, StudentModel } from '@/domains/core/advisor/types';
import Table from '@/components/table/table';
import { Column, TableRow } from '@/components/table/table-types';
import { TagType } from '@/components/tag/tag';
import { addTagToStudent, deleteTagFromUniversity, removeTagFromStudent } from '@/services/api/advisor';
import './student-table.scss';
import { FilterOption } from '@/domains/core/company/types';
import { studentProgressBadges } from './student-badge';
import { AdvisorAssignment } from '../../components/advisor-assignment/advisor-assigment';
import { StudentActionsButton } from '../../components/student-actions-button/student-actions-button';

export const columns: Column[] = [
  {
    label: 'First name', accessor: 'first_name', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Last Name', accessor: 'last_name', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Email', accessor: 'email', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Progress', accessor: 'progress', sortable: true, type: 'text', isCentered: false, isFitContent: true,
  },
  {
    label: 'Advisor', accessor: 'advisor', type: 'text', sortable: false, isCentered: false, isFitContent: true,
  },
  {
    label: 'Tags', accessor: 'tags', type: 'tags', sortable: false, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false, isFitContent: true,
  },
];

const mapMiniAdvisorsToFilterOptions = (advisors: MiniAdvisor[]): FilterOption[] => advisors.map((advisor) => ({
  name: `${advisor.firstName} ${advisor.lastName}`,
  value: advisor.id,
}));

export interface StudentTableProps {
  students: StudentModel[];
  tags: TagType[];
  universityID: string;
  allAdvisors: MiniAdvisor[];
}

const mapStudentsToData = (students: StudentModel[], allTags: TagType[], allAdvisors: MiniAdvisor[], universityID: string, navigate: (to: string) => void, mappedAdvisors: FilterOption[]): TableRow[] => (
  students.map((student) => ({
    handleRowClick: () => navigate(`/app/advisor/students/student-overview/${student.id}/university/${universityID}}`),
    id: student.id,
    source: student,
    items: {
      first_name: {
        label: student.firstName,
        image: (
          <DefaultLogo
            type="contact"
            source={student.profileImage}
            className="student-table__image"
            name={`${student.firstName} ${student.lastName}`}
            size={24}
          />),
        value: student.firstName,
        type: 'link',
        to: `/app/advisor/students/student-overview/${student.id}/university/${universityID}}`,
      },
      last_name: {
        label: student.lastName,
        value: student.lastName,
        type: 'text',
      },
      email: {
        label: student.email || 'N/A',
        value: student.email,
        type: 'text',
      },
      progress: {
        label: <Badge label={studentProgressBadges[student.status].label} color={studentProgressBadges[student.status].color} />,
        value: studentProgressBadges[student.status].order,
        type: 'text',
      },
      advisor: {
        label: (
          <AdvisorAssignment
            student={student}
            allAdvisors={allAdvisors}
            mappedAdvisors={mappedAdvisors}
          />),
        value: student.assignedAdvisors?.length || 0,
        type: 'text',
      },
      tags: {
        type: 'tags',
        tags: student.tags,
        allTags,
        addTag: (tag) => addTagToStudent(tag, student.id, universityID),
        removeTag: (tag) => removeTagFromStudent(tag, student.id, universityID),
        deleteTag: (tag) => deleteTagFromUniversity(tag.id, universityID),
        student: {
          university_id: universityID,
          cohort_id: student.cohort?.id,
        },
        label: '',
        value: '',
        id: student.id,
      },
      actions: {
        label: <StudentActionsButton universityId={universityID} studentId={student.id} />,
        value: student.id,
        type: 'text',
      },
    },
  })));

const StudentTable = ({
  students, tags, universityID, allAdvisors,
}: StudentTableProps) => {
  const navigate = useNavigate();
  const mappedAdvisors = useMemo(() => mapMiniAdvisorsToFilterOptions(allAdvisors), [allAdvisors]);
  const mappedStudents = useMemo(() => mapStudentsToData(students, tags, allAdvisors, universityID, navigate, mappedAdvisors), [students, tags, allAdvisors, universityID, navigate, mappedAdvisors]);

  return (
    <div className="student-table">
      <Table
        data={mappedStudents}
        columns={columns}
        hasTooltip={false}
        isRowClickable
      />
    </div>
  );
};

export default StudentTable;
