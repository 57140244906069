import { Tooltip, Counter } from '@careeros/coco';
import './tabs.scss';

type Props = {
  size: 'medium' | 'large';
  tabName: string;
  counter?: number;
  icon?: string;
  isActive: boolean;
  isDisabled?: boolean;
  isUnread?: boolean;
  handleTabClicked: () => void;
  counterTooltip?: string;
  tabTooltip?: string;
  activeIcon?: string;
};

export const Tab = ({
  tabName,
  icon,
  size,
  isActive,
  counter,
  isDisabled = false,
  isUnread = false,
  handleTabClicked,
  counterTooltip,
  tabTooltip,
  activeIcon,
}: Props) => (
  <div
    onClick={handleTabClicked}
    className={`careerOS-tab careerOS-tab--${size} ${
      isActive ? 'careerOS-tab--active' : ''
    } ${isDisabled ? 'careerOS-tab--disabled' : ''}`}
  >
    {icon && (
      <div className={`careerOS-tab__icon ${isUnread ? 'careerOS-tab__icon--unread' : ''}`}>
        <i className={`${icon} careerOS-tab__icon-source`} />
        <i className={`${activeIcon || icon} careerOS-tab__icon-source careerOS-tab__icon-source--active`} />
      </div>
    )}
    <div>
      <Tooltip label={tabTooltip || tabName} position="top" withArrow disableHoverListener={!tabTooltip}>
        <span>{tabName}</span>
      </Tooltip>
    </div>
    {counter !== undefined && (
      <Tooltip label={counterTooltip || tabName} position="top" withArrow disableHoverListener={!counterTooltip}>
        <Counter counter={counter} size="medium" mode="default" />
      </Tooltip>
    )}
  </div>
);
