import { useCookies } from 'react-cookie';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader } from '@/components/loader/loader';
import '@/pages/styles/resume-feed.scss';
import {
  PreviewControls,
  PreviewResume,
} from '@/domains/core/resume';

import CustomWithAuthenticationRequired from '../../auth/custom-protected-route';
import { useStudentResumeQuery } from '@/services/queries/resume';
import { ResumeBuilderProvider } from '@/pages/context/resume-builder';
import ResumeCommentsSidebar from '@/domains/core/advisor/components/resume-comments-sidebar/resume-comments-sidebar';
import { ResumeSchema, WorkOrderItem, WorkSection } from '@/domains/core/resume/types';

const baseURL = import.meta.env.VITE_APP_URL;

const ViewResumePage = ({ type }: { type: 'base' | 'tailored' }) => {
  const { studentID, resumeID } = useParams();
  const { data: resumeResponse, isLoading: isResumeLoading } = useStudentResumeQuery(type, studentID || '', resumeID || '', {
    enabled: !!studentID && !!resumeID,
  });

  const [resume, setResume] = useState<ResumeSchema | undefined>(resumeResponse?.resume);
  const [cookies] = useCookies(['cookiesAccepted']);
  const isCookieBannerVisible = useMemo(() => !cookies.cookiesAccepted, [cookies.cookiesAccepted]);
  const URL = `${baseURL}/app/resume-os/${type}/${resumeID}?tab=Comments`;

  const updateResumeWithNewFormat = (oldResume: ResumeSchema) => {
    const defaultHeading: WorkOrderItem = {
      type: 'heading',
      value: 'Professional Experience',
    };

    const workItems: WorkOrderItem[] = oldResume.work.map(work => ({
      type: 'work',
      value: work,
    }));

    const initialOrder = [defaultHeading, ...workItems];

    const newWorkSection: WorkSection = {
      order: initialOrder,
      subheadings: ['Professional Experience'],
      entries: oldResume.work,
    };

    setResume({
      ...oldResume,
      work_section: newWorkSection,
      work: oldResume.work,
    });
  };

  useEffect(() => {
    if (resumeResponse && !resumeResponse.resume.work_section?.order) {
      updateResumeWithNewFormat(resumeResponse.resume);
    } else {
      setResume(resumeResponse?.resume);
    }
  }, [resumeResponse]);

  if (isResumeLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <ResumeBuilderProvider>
      <div className="resume-builder resume-builder--advisor">
        {resumeResponse && (
        <>
          {type === 'base' && (
            <div className="resume-builder__controls">
              <PreviewControls />
            </div>
          )}
          <div className={`resume-builder__content resume-builder__content--advisor resume-builder__content--${type} ${isCookieBannerVisible ? 'resume-builder__content--with-cookie-banner' : ''}`}>
            {resume && <PreviewResume setNumberOfPages={() => {}} selectedTemplate={resumeResponse?.resume_template?.name} baseResume={resume} />}
            <ResumeCommentsSidebar url={URL} hasUnreadComments={false} job={resumeResponse?.job} resume={resumeResponse} type={type} />
          </div>
        </>
        )}
      </div>
    </ResumeBuilderProvider>
  );
};

export default CustomWithAuthenticationRequired(ViewResumePage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
