import { LeaderboardType } from '@/types/gamification';

/**
 * Formats XP numbers with appropriate abbreviations (K, M, B)
 * @param xp - The XP number to format
 * @returns Formatted string with appropriate abbreviation
 */
export function formatXP(xp: number): string {
  if (xp < 10000) {
    // Less than 10,000: Show full number with commas
    return xp.toLocaleString('en-US');
  }
  if (xp < 1000000) {
    // 10,000 to 999,999: Round to nearest whole number and append "K"
    return `${Math.round(xp / 1000)}K`;
  }
  if (xp < 10000000) {
    // 1,000,000 to 9,999,999: Show one decimal place and append "M"
    return `${(Math.round(xp / 100000) / 10).toFixed(1)}M`;
  }
  if (xp < 1000000000) {
    // 10,000,000 to 999,999,999: Round to nearest whole number and append "M"
    return `${Math.round(xp / 1000000)}M`;
  }
  // 1,000,000,000 and above: Round to nearest whole number and append "B"
  return `${Math.round(xp / 1000000000)}B`;
}

// Helper function to format numbers with thousand commas
export const formatNumberWithCommas = (text: string, type: LeaderboardType): string => {
  // Extract the numeric part from the text (assuming it's at the beginning)
  const match = text.match(/^(\d+)(.*)$/);
  if (!match) return text;

  const [, numericPart, restOfText] = match;

  if (type === 'xp') {
    return formatXP(Number(numericPart)) + restOfText;
  }

  // For non-XP values, just use simple comma formatting
  return `${Number(numericPart).toLocaleString('en-US')}${restOfText}`;
};
