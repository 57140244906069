/* eslint-disable no-restricted-globals */
import {
  useContext,
  useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { InfoModal } from '@careeros/coco';
import {
  TrackerContextType, TrackerContext,
  changeApplicationStatus,
} from '@/domains/core/company';
import './kanban-tile.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { updateApplicationRating } from '@/services/api/company';
import { OBTilePreviewDataType, Tile } from '@/domains/core/company/types';
import { OBTile } from '../OB-tile/OB-tile';
import { OBTileHeader } from '../OB-tile-header/OB-tile-header';
import { CompanyTileBody } from '../company-tile-body/company-tile-body';
import { ModalContext } from '@/components/modal/modal-provider';
import { statuses, trackerTabs } from '@/domains/core/tracker-data';
import { getCompanyPreviewData } from '../utils/company-preview';
import { KanbanTileJob } from '../tile-job/tile-job';
import { CompanyTileTrafficLights } from '@/domains/core/company/components/tile-traffic-lights/tile-traffic-lights';
import { updateApplicationHiringScore } from '@/services/api/application';
import { AddToastType } from '../../toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';

interface KanbanTileProps {
  tile: Tile;
  withTrafficLights?: boolean;
}

/**
 * Primary UI component for the kanban board
 */
export const CompanyKanbanTile = ({
  tile,
  withTrafficLights = false,
}: KanbanTileProps) => {
  const { addToast }: AddToastType = useContext(ToastContext);
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [previewCompanyData, setPreviewCompanyData] = useState<OBTilePreviewDataType | null>(null);
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { completeActionIfAvailable } = useGamification();

  const goToTracker = () => {
    openTracker(tile.company_id);
  };

  const getCompanyPreview = async () => {
    const companyPreviewDataResult = await getCompanyPreviewData(tile.company_id);
    setPreviewCompanyData(companyPreviewDataResult);
  };

  const handleNameHover = () => {
    if (!previewCompanyData) {
      getCompanyPreview();
    }
  };

  const handleCompanyRate = async (rate: number) => {
    await updateApplicationRating(tile.id, rate);

    if (rate === 3) {
      completeActionIfAvailable(GAMIFICATION_ACTIONS.SET_HYPE_LEVEL);
    }
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);

    trackEvent('Overview Board: Company Rate emoji clicked', {});
  };

  const archiveCompany = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveCompany', 'true');
    }
    closeModal();
    await changeApplicationStatus(tile.id, statuses.Archived, tile.ordering);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);
    queryClient.invalidateQueries(['dashboard-actions']);
    trackEvent('Overview Board: Company Archive', {});
  };

  const moveTileToRejected = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberRejectTile', 'true');
    }
    closeModal();
    await changeApplicationStatus(tile.id, statuses.Rejected, tile.ordering);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);
    queryClient.invalidateQueries(['dashboard-actions']);
    trackEvent('Overview Board: Company Status change');
  };

  const openAreYouSureRejected = () => {
    const rememberAction = localStorage.getItem('rememberRejectTile');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle"
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={moveTileToRejected}
          title="Move to Rejected"
          description={`${tile.company_name} will be moved to the Rejected column on your Overview Board. You can still drag the tile to another stage if the status has changed.`}
          buttonLabel="Keep on Board"
          secondaryButtonLabel="Move to Rejected"
          rememberText="Don't ask me again"
          isSecondButtonDanger
          isImageDanger
          secondaryButtonIcon="bi bi-forward"
          buttonIcon="bi bi-hand-thumbs-up"
          isButtonOutlined
        />,
      );
    } else {
      archiveCompany(false);
    }
  };

  const openAreYouSureArchive = () => {
    const rememberAction = localStorage.getItem('rememberArchiveCompany');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle"
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={archiveCompany}
          title="Archive Company"
          description={`Archiving will stop your progress with ${tile.company_name} and remove it from your Overview Board.`}
          buttonLabel="Keep on Board"
          secondaryButtonLabel="Archive"
          rememberText="Don't ask me again"
          isSecondButtonDanger
          isImageDanger
          secondaryButtonIcon="bi bi-archive"
          buttonIcon="bi bi-hand-thumbs-up"
          isButtonOutlined
        />,
      );
    } else {
      archiveCompany(false);
    }
  };

  const handleOpenTrackerTab = (tab: (typeof trackerTabs)[number], params?: { [key: string]: string }) => {
    openTracker(tile.company_id, tab, params);
  };

  const handleTrafficLightClick = async (status: number) => {
    try {
      const newScore = status === tile.hiring_score ? 0 : status;
      await updateApplicationHiringScore(tile.id, newScore);

      queryClient.invalidateQueries(['applications']);
      queryClient.invalidateQueries(['applications-no-cache']);
      queryClient.invalidateQueries(['applications-no-cache-v2']);
    } catch (e) {
      addToast({
        type: 'error',
        message: 'Failed to update hiring score',
      });
    }
  };

  return (
    <OBTile
      onClick={goToTracker}
      label="Company"
      withHoverLabel
    >
      {withTrafficLights && (
        <OBTile.TrafficLights>
          <CompanyTileTrafficLights handleStatusClick={handleTrafficLightClick} currentStatus={tile.hiring_score} />
        </OBTile.TrafficLights>
      )}
      <OBTile.Header>
        <OBTileHeader
          name={tile.company_name}
          logoUrl={tile.logo_url}
          hypeLevel={tile.rating}
          handleChangeHypeLevel={handleCompanyRate}
          imageLabel={tile.company_name}
          onSubtitleClick={goToTracker}
          onNameClick={goToTracker}
        >
          <OBTileHeader.Name
            name={tile.company_name}
            onNameClick={goToTracker}
            previewData={previewCompanyData}
            onNameHover={handleNameHover}
            onExpandClick={() => handleOpenTrackerTab('Company')}
            industry={tile.industry}
          />
        </OBTileHeader>
      </OBTile.Header>
      {tile.jobs.length > 0 && (
        <div className="kanban-tile__jobs">
          {tile.jobs.map((job) => (
            <KanbanTileJob id={job.id} key={job.title} title={job.title} url={job.linkedin_url} companyId={tile.company_id} />
          ))}
        </div>
      )}
      <OBTile.Separator />
      <OBTile.Body>
        <CompanyTileBody
          // jobNumber={tile.jobs.length || 0}
          handleArchiveCompany={openAreYouSureArchive}
          handleMoveToRejected={openAreYouSureRejected}
          tileStatus={tile.status}
          handleOpenTrackerTab={handleOpenTrackerTab}
          navigateToPage={navigate}
          contacts={tile.contacts}
        />
      </OBTile.Body>
    </OBTile>
  );
};
