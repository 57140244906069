import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Tooltip } from '@careeros/coco';
import { TrackerContextType, TrackerContext } from '@/domains/core/company';
import './tile-job.scss';

interface JobProps {
  id: string
  title: string;
  url: string;
  companyId: string;
  readOnly?: boolean;
}

export const KanbanTileJob = ({
  title, url, companyId, readOnly, id,
}: JobProps) => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const [isJobOverflow, setIsJobOverflow] = useState(false);
  const jobElementRef = useRef<any>(null);

  const handleLinkClick = (event: any) => {
    event.stopPropagation();

    openTracker(companyId, 'Jobs', { jobID: encodeURIComponent(id) });
  };

  useEffect(() => {
    if (jobElementRef.current) {
      setIsJobOverflow(jobElementRef.current.scrollWidth > jobElementRef.current.clientWidth);
    }
  }, []);
  return (

    <div key={url} className="kanban-tile-job">
      <i className="kanban-tile-job__icon bi bi-briefcase" />
      <Tooltip label={title} position="top" disableHoverListener={!isJobOverflow}>
        {readOnly ? (
          <a target="_blank" href={url} ref={jobElementRef} className={`kanban-tile-job__title ${url ? '' : 'kanban-tile-job__title--no-pointer-cursor'}`} rel="noreferrer">
            {title}
          </a>
        ) : (
          <span onClick={handleLinkClick} ref={jobElementRef} className="kanban-tile-job__title">
            {title}
          </span>
        )}
      </Tooltip>
    </div>
  );
};
