import { formatXP } from '@/services/helpers/gamification';
import { useGamification } from '@/services/hooks/use-gamification';

export const TopbarXP = () => {
  const {
    xp,
    isXpLoading,
    animatingXP,
    displayedXP,
  } = useGamification();

  if (isXpLoading || !xp) {
    return null;
  }

  return (
    <div className="topbar__xp">
      <span className="topbar__xp-value">
        {formatXP(displayedXP || 0)}
      </span>
      <span className="topbar__xp-label">
        XP
      </span>
      {animatingXP && (
      <span className="topbar__xp-added xp-bounce-out-down">
        +
        {formatXP(animatingXP)}
      </span>
      )}
    </div>
  );
};
