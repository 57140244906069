import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  useCompleteGamificationActionMutation, useGetGamificationActionsQuery, useGetChallengesQuery, useGetLeaderboardsQuery,
  useGetXpQuery,
} from '../queries/gamification';
import { Challenge } from '@/types/gamification';
import { useAnimatedChallenges } from './use-animated-challenges';

export const GAMIFICATION_ACTIONS = {
  CREATE_ACCOUNT: 'create_account',
  SEND_EMAIL: 'send_email',
  SEND_FOLLOW_UP: 'send_follow_up',
  LINKEDIN_REQUEST: 'linkedin_request',
  LINKEDIN_MESSAGE: 'linkedin_message',
  TALLY_FORM_SUBMISSION: 'tally_form_submission',
  EMAIL_CONNECTION: 'email_connection',
  RECEIVED_OFFER: 'receive_offer',
  ENROLL_IN_COHORT: 'cohort_form_submission',
  ADVANCED_SEARCH: 'advanced_search',
  CREATE_TEMPLATE: 'message_template',
  CREATE_FIRST_TEMPLATE: 'first_message_template',
  SAVED_TAILORED_RESUME: 'tailored_resume',
  SAVED_BASE_RESUME: 'base_resume',
  WEBINAR_ATTENDANCE: 'webinar_attendance',
  JOB_APPLICATION: 'job_application',
  COMPANY_STATUS_CHANGE: 'kanban_update',
  ADD_NOTE: 'create_note',
  SET_HYPE_LEVEL: 'set_hype_level',
  USED_AI_TOOLS: 'use_ai_tools',
  SAVE_JOB: 'save_job',
  NURTURING_CONTACT: 'update_contact_status',
  RESOLVE_COMMENT: 'resolve_comment',
  REQUEST_RESUME_REVIEW: 'resume_review',
  COFFEE_CHAT: 'coffee_chat',
  GETTING_INTERVIEW: 'interview',
  CHROME_EXTENSION_INSTALL: 'chrome_extension_install',
  SEND_FIRST_EMAIL: 'send_first_email',
  SEND_MESSAGE: 'cos_message',
  MOBILE_LOGIN: 'mobile_login',
  WEEKEND_USAGE: 'weekend_usage',
  SEND_AI_MESSAGE: 'send_ai_messages',
  NIGHT_USAGE: 'night_usage',
  FIRST_ON_LEADERBOARD: '1st_leaderboard_position',
  SECOND_ON_LEADERBOARD: '2nd_leaderboard_position',
  THIRD_ON_LEADERBOARD: '3rd_leaderboard_position',
};

export const useGamification = () => {
  const [animatingXP, setAnimatingXP] = useState<number | null>(null);
  const [displayedXP, setDisplayedXP] = useState<number | null>(null);
  const previousXPRef = useRef<number | null>(null);
  const initializedRef = useRef(false);

  const {
    data: gamificationActions,
    isLoading: isActionsLoading,
    isError: isActionsError,
  } = useGetGamificationActionsQuery();

  const {
    data: xp,
    isLoading: isXpLoading,
  } = useGetXpQuery();

  const {
    data: challenges,
    isLoading: isChallengesLoading,
    isError: isChallengesError,
  } = useGetChallengesQuery();

  const {
    data: leaderboards,
    isLoading: isLeaderboardsLoading,
    isError: isLeaderboardsError,
  } = useGetLeaderboardsQuery();

  // Use the new animated challenges hook
  const {
    unreadChallenges: completedChallenges,
    animatingChallenges,
    handleAnimationEnd,
    setAnimatingChallenges,
    setUnreadChallenges: setCompletedChallenges,
  } = useAnimatedChallenges();

  const { mutate: completeGamificationAction } = useCompleteGamificationActionMutation();

  const visibleChallenges = useMemo(() => {
    if (!challenges || !Array.isArray(challenges) || isChallengesLoading || isChallengesError) {
      return [];
    }
    const visible = challenges.filter((challenge: Challenge) => {
      const isVisible = !challenge.is_completed && challenge.user_progress >= challenge.visible_from_value;
      if (isVisible) {
        console.log('Visible challenge:', {
          name: challenge.name,
          progress: challenge.user_progress,
          visible_from: challenge.visible_from_value,
          completed: challenge.is_completed,
        });
      }
      return isVisible;
    });
    return visible.sort((a, b) => b.user_progress / b.completed_at_value - a.user_progress / a.completed_at_value);
  }, [challenges, isChallengesLoading, isChallengesError]);

  // Memoize all leaderboards
  const allLeaderboards = useMemo(() => {
    if (!leaderboards) return [];
    return [
      ...(leaderboards.xp_leaderboards || []),
      ...(leaderboards.challenges_leaderboards || []),
      ...(leaderboards.messages_leaderboards || []),
    ];
  }, [leaderboards]);

  const earnedChallenges = useMemo(() => {
    if (!challenges || !Array.isArray(challenges) || isChallengesLoading || isChallengesError) {
      return [];
    }
    return challenges.filter((challenge: Challenge) => challenge.is_completed);
  }, [challenges, isChallengesLoading, isChallengesError]);

  const lockedChallenges = useMemo(() => {
    if (!challenges || !Array.isArray(challenges) || isChallengesLoading || isChallengesError) {
      return [];
    }
    const locked = challenges.filter((challenge: Challenge) => {
      const isLocked = !challenge.is_completed && challenge.user_progress < challenge.visible_from_value;
      if (isLocked) {
        console.log('Locked challenge:', {
          name: challenge.name,
          progress: challenge.user_progress,
          visible_from: challenge.visible_from_value,
          completed: challenge.is_completed,
        });
      }
      return isLocked;
    });
    return locked;
  }, [challenges, isChallengesLoading, isChallengesError]);

  const completeActionIfAvailable = (actionName: string) => {
    if (!gamificationActions || isActionsLoading || isActionsError) {
      return; // Don't proceed if data isn't ready or there's an error
    }

    const action = Array.isArray(gamificationActions?.available_actions) ? gamificationActions.available_actions.find(
      (act) => act.name === actionName,
    ) : null;

    if (action) {
      completeGamificationAction(action.id);
    }
  };

  const checkForPositionOnLeaderboard = () => {
    if (allLeaderboards) {
      const isUserFirstOnAnyLeaderboard = allLeaderboards.some((lb) => lb.format === 'individual' && lb?.ranking && lb?.ranking[0] && lb?.ranking[0].is_user);
      if (isUserFirstOnAnyLeaderboard) {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.FIRST_ON_LEADERBOARD);
      }

      const isUserSecondOnAnyLeaderboard = allLeaderboards.some((lb) => lb.format === 'individual' && lb?.ranking && lb?.ranking[1] && lb?.ranking[1].is_user);
      if (isUserSecondOnAnyLeaderboard) {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.SECOND_ON_LEADERBOARD);
      }

      const isUserThirdOnAnyLeaderboard = allLeaderboards.some((lb) => lb.format === 'individual' && lb?.ranking && lb?.ranking[2] && lb?.ranking[2].is_user);
      if (isUserThirdOnAnyLeaderboard) {
        completeActionIfAvailable(GAMIFICATION_ACTIONS.THIRD_ON_LEADERBOARD);
      }
    }
  };

  useEffect(() => {
    if (allLeaderboards) {
      checkForPositionOnLeaderboard();
    }
  }, [allLeaderboards]);

  // Handle XP changes and animations
  useEffect(() => {
    if (!xp || isXpLoading) return;

    const currentXP = xp.total_xp;
    // If this is the first load, set both refs and displayed value
    if (!initializedRef.current) {
      previousXPRef.current = currentXP;
      setDisplayedXP(currentXP);
      initializedRef.current = true;
      return;
    }

    let timer: NodeJS.Timeout;

    // Calculate XP difference if we have a previous value
    if (previousXPRef.current !== null && currentXP > previousXPRef.current) {
      const xpDifference = currentXP - previousXPRef.current;
      setAnimatingXP(xpDifference);

      // Reset animating XP and update displayed XP after animation duration
      timer = setTimeout(() => {
        setAnimatingXP(null);
        setDisplayedXP(currentXP);
        previousXPRef.current = currentXP;
      }, 2000);
    } else {
      previousXPRef.current = currentXP;
      setDisplayedXP(currentXP);
    }

    // Cleanup function
    // eslint-disable-next-line consistent-return
    return () => { clearTimeout(timer); };
  }, [xp, isXpLoading]);

  return {
    // Actions related
    completeActionIfAvailable,
    isActionsLoading,
    isActionsError,
    gamificationActions,

    // Challenges related
    challenges,
    isChallengesLoading,
    isChallengesError,
    completedChallenges,
    earnedChallenges,
    animatingChallenges,
    handleAnimationEnd,
    setAnimatingChallenges,
    visibleChallenges,
    lockedChallenges,
    unreadChallenges: completedChallenges.filter((challenge: Challenge) => !challenge.is_read).sort((a, b) => new Date(b.completed_at).getTime() - new Date(a.completed_at).getTime()),
    setUnreadChallenges: setCompletedChallenges,

    // XP related
    xp,
    isXpLoading,
    animatingXP,
    setAnimatingXP,
    displayedXP,

    // Leaderboards related
    leaderboards,
    allLeaderboards,
    isLeaderboardsLoading,
    isLeaderboardsError,
  };
};
