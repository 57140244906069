import {
  useContext,
  useMemo,
  useState,
} from 'react';
import { InfoModal } from '@careeros/coco';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  TrackerContextType, TrackerContext,
  changeApplicationStatus,
} from '@/domains/core/company';
import './kanban-tile.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { updateApplicationRating } from '@/services/api/company';
import { OBTilePreviewDataType } from '@/domains/core/company/types';
import { OBTile } from '../OB-tile/OB-tile';
import { OBTileHeader } from '../OB-tile-header/OB-tile-header';
import { ModalContext } from '@/components/modal/modal-provider';
import { statuses, trackerTabs } from '@/domains/core/tracker-data';
import { JobTileBody } from '../job-tile-body/job-tile-body';
import { TileV2 } from '@/domains/core/company/types/tile-v2';
import { getJobPreviewData } from '../utils/company-preview';
import { useGetSelfBaseResume } from '@/services/queries/resume';
import { CompanyTileTrafficLights } from '@/domains/core/company/components/tile-traffic-lights/tile-traffic-lights';

interface KanbanTileProps {
  tile: TileV2;
  withTrafficLights?: boolean;
}

/**
 * Primary UI component for the kanban board
 */
export const JobKanbanTileV2 = ({
  tile,
  withTrafficLights = false,
}: KanbanTileProps) => {
  const { data: baseResumes } = useGetSelfBaseResume();
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [previewData, setPreviewData] = useState<OBTilePreviewDataType | null>(null);
  const hasBaseResume = useMemo(() => !!baseResumes?.length, [baseResumes]);
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const goToTracker = () => {
    openTracker(tile.company.id, 'Jobs', undefined, tile.job?.id);
  };

  const getPreview = async () => {
    if (!tile.job?.id) {
      return;
    }
    const jobInfo = await getJobPreviewData(tile.job.id);

    setPreviewData(jobInfo);
  };

  const handleNameHover = () => {
    if (!previewData) {
      getPreview();
    }
  };

  const handleTileRate = async (rate: number) => {
    await updateApplicationRating(tile.id, rate);

    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);

    trackEvent('Overview Board: Company Rate emoji clicked', {});
  };

  const archiveJobTile = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberArchiveJobTile', 'true');
    }
    closeModal();
    await changeApplicationStatus(tile.id, statuses.Archived, tile.ordering);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);
    queryClient.invalidateQueries(['dashboard-actions']);
    trackEvent('Overview Board: Job Archive', {});
  };

  const moveTileToRejected = async (rememberAction: boolean) => {
    if (rememberAction) {
      localStorage.setItem('rememberRejectTile', 'true');
    }
    closeModal();
    await changeApplicationStatus(tile.id, statuses.Rejected, tile.ordering);
    await queryClient.invalidateQueries(['archive']);
    await queryClient.invalidateQueries(['applications']);
    await queryClient.invalidateQueries(['applications-no-cache']);
    queryClient.invalidateQueries(['applications-no-cache-v2']);
    queryClient.invalidateQueries(['dashboard-actions']);
    trackEvent('Overview Board: Job Status change');
  };

  const openAreYouSureRejected = () => {
    const rememberAction = localStorage.getItem('rememberRejectTile');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle"
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={moveTileToRejected}
          title="Move to Rejected"
          description={`${tile.job?.title} will be moved to the Rejected column on your Overview Board. You can still drag the tile to another stage if the status has changed.`}
          buttonLabel="Keep on Board"
          secondaryButtonLabel="Move to Rejected"
          rememberText="Don't ask me again"
          isSecondButtonDanger
          isImageDanger
          secondaryButtonIcon="bi bi-forward"
          buttonIcon="bi bi-hand-thumbs-up"
          isButtonOutlined
        />,
      );
    } else {
      archiveJobTile(false);
    }
  };

  const openAreYouSureArchive = () => {
    const rememberAction = localStorage.getItem('rememberArchiveJobTile');
    if (!rememberAction) {
      openModal(
        <InfoModal
          icon="bi bi-exclamation-triangle"
          handleButtonClick={closeModal}
          handleSecondaryButtonClick={archiveJobTile}
          title="Archive Job"
          description={`Archiving will remove ${tile.job?.title} from your Overview Board. This action can’t be reversed.`}
          buttonLabel="Keep on Board"
          secondaryButtonLabel="Archive"
          rememberText="Don't ask me again"
          isSecondButtonDanger
          isImageDanger
          secondaryButtonIcon="bi bi-archive"
          buttonIcon="bi bi-hand-thumbs-up"
          isButtonOutlined
        />,
      );
    } else {
      archiveJobTile(false);
    }
  };

  const handleOpenTrackerTab = (tab: (typeof trackerTabs)[number], params?: { [key: string]: string }) => {
    openTracker(tile.company.id, tab, params, tile.job?.id);
  };

  // Hiring status for job tile ir readonly for now
  // const handleTrafficLightClick = async (status: number) => {
  //   const newScore = status === tile.hiring_score ? 0 : status;

  //   await updateApplicationHiringScore(tile.id, newScore);

  //   queryClient.invalidateQueries(['archive']);
  //   queryClient.invalidateQueries(['applications']);
  //   queryClient.invalidateQueries(['applications-no-cache']);
  //   queryClient.invalidateQueries(['applications-no-cache-v2']);
  // };

  return (
    <OBTile
      onClick={goToTracker}
      label="Job"
      withHoverLabel
    >
      {withTrafficLights && (
        <OBTile.TrafficLights>
          <CompanyTileTrafficLights currentStatus={3} />
        </OBTile.TrafficLights>
      )}
      <OBTile.Header>
        <OBTileHeader
          name={tile.job?.title || ''}
          subtitle={`@ ${tile.company.name}`}
          logoUrl={tile.company.logo_url}
          hypeLevel={tile.rating}
          handleChangeHypeLevel={handleTileRate}
          imageLabel={tile.job?.title || tile.company.name}
          onSubtitleClick={goToTracker}
          onNameClick={goToTracker}
          location={tile.job?.location}
        >
          <OBTileHeader.Name
            name={tile.job?.title || ''}
            onNameClick={goToTracker}
            previewData={previewData}
            onNameHover={handleNameHover}
            onExpandClick={() => handleOpenTrackerTab('Jobs')}
            industry={tile.company.industry}
            companyName={tile.company.name}
            onCompanyNameClick={() => handleOpenTrackerTab('Company')}
          />
        </OBTileHeader>
      </OBTile.Header>
      <OBTile.Separator />
      <OBTile.Body>
        <JobTileBody
          hasBaseResume={hasBaseResume}
          jobID={tile.job?.id}
          jobDeadline={tile.job?.deadline}
          applicationDate={tile.job?.applied_at}
          handleArchive={openAreYouSureArchive}
          handleMoveToRejected={openAreYouSureRejected}
          tileStatus={tile.status}
          handleOpenTrackerTab={handleOpenTrackerTab}
          navigateToPage={navigate}
          contacts={tile.contacts}
        />
      </OBTile.Body>
    </OBTile>
  );
};
