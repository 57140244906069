import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/browser';
import { useAnalytics } from './use-analytics';

const extensionId = import.meta.env.VITE_EXTENSION_ID;

enum MessageType {
  ReissueFCMToken = 'Reissue FCM token',
  GetConnectionStatus = 'Get connection status',
  SendAccessToken = 'Set access token',
  CheckLinkedInLogin = 'Check LinkedIn login',
  GetSuggestedContacts = 'Get suggested contacts info',
  GetPremiumStatus = 'Get Premium status',
  SaveContact = 'Save contact',
  SendLinkedInMessage = 'Send LinkedIn message',
  SendLinkedInConnection = 'Send LinkedIn connection',
  UpdateAllLinkedInConversations = 'Update all LinkedIn conversations',
  GetUserProfilePicture = 'Get user profile picture',
  GetUserLinkedInConnections = 'Get user LinkedIn connections',
  TrackLinkedInLogin = 'Track LinkedIn login',
}

export async function sendMessageToExtension(message: string, data?: any): Promise<any> {
  // if not Chrome browser or the website url is not listed in the extension 'externally_connectable' list
  if (typeof chrome === 'undefined' || typeof chrome.runtime === 'undefined') {
    return undefined;
  }

  return new Promise((resolve, reject) => {
    try {
      chrome.runtime.sendMessage(
        extensionId,
        { message, data },
        {},
        (resp: any) => {
          if (!resp) {
            reject(new Error('got undefined response after sending message to extension'));
            return;
          }

          if (resp.success) {
            resolve(resp.message);
          } else {
            reject(resp.error);
          }
        },
      );
    } catch (error) {
      reject(error);
    }
  });
}

export function useExtensionMessaging() {
  const { getAccessTokenSilently } = useAuth0();
  const { trackEvent } = useAnalytics();

  const getUserToken = async () => {
    try {
      const token = await getAccessTokenSilently();
      return token;
    } catch (error) {
      Sentry.captureException(error);
      return undefined;
    }
  };

  const reissueFCMToken = () => sendMessageToExtension(MessageType.ReissueFCMToken);
  const getContactConnectionStatus = (linkedInUrl: string) => sendMessageToExtension(MessageType.GetConnectionStatus, linkedInUrl);
  const sendAccessToken = async () => {
    try {
      const token = await getUserToken();

      if (!token) {
        return undefined;
      }

      const response = await sendMessageToExtension(MessageType.SendAccessToken, token);

      if (response === 'Authenticated') {
        trackEvent('Successful Login in Chrome Extension');
      }

      return response;
    } catch (error) {
      return undefined;
    }
  };
  const checkLinkedInLogin = async () => {
    try {
      await sendAccessToken();
      const response = await sendMessageToExtension(MessageType.CheckLinkedInLogin);

      return response;
    } catch (error) {
      return undefined;
    }
  };
  const getSuggestedContacts = (companyUrl: string, savedContactsIds: string[]) => sendMessageToExtension(MessageType.GetSuggestedContacts, { companyUrl, savedContactsIds });
  const getPremiumStatus = () => sendMessageToExtension(MessageType.GetPremiumStatus);
  const saveContact = async (
    contactLinkedInUrl: string,
    companyName: string,
    companyLinkedInId: string,
    companyId: string,
    companyLinkedInUrl: string,
  ) => {
    await sendAccessToken();

    return sendMessageToExtension(MessageType.SaveContact, {
      contactLinkedInUrl,
      companyName,
      companyLinkedInId,
      companyId,
      companyLinkedInUrl,
    });
  };
  const sendLinkedInMessage = (contactLinkedInUrn: string, message: string, contactLinkedInUrl: string) => sendMessageToExtension(MessageType.SendLinkedInMessage, { contactLinkedInUrn, message, contactLinkedInUrl });
  const sendLinkedInConnection = (contactLinkedInUrn: string, message: string, contactLinkedInUrl: string) => sendMessageToExtension(MessageType.SendLinkedInConnection, { contactLinkedInUrn, message, contactLinkedInUrl });
  const updateAllLinkedInConversations = () => sendMessageToExtension(MessageType.UpdateAllLinkedInConversations);
  const getUserProfilePicture = () => sendMessageToExtension(MessageType.GetUserProfilePicture);
  const getUserLinkedInConnections = () => sendMessageToExtension(MessageType.GetUserLinkedInConnections);
  const askToTrackLinkedInLogin = () => sendMessageToExtension(MessageType.TrackLinkedInLogin);

  return {
    reissueFCMToken,
    getContactConnectionStatus,
    sendAccessToken,
    checkLinkedInLogin,
    getSuggestedContacts,
    getPremiumStatus,
    saveContact,
    sendLinkedInMessage,
    sendLinkedInConnection,
    updateAllLinkedInConversations,
    getUserProfilePicture,
    getUserLinkedInConnections,
    askToTrackLinkedInLogin,
  };
}
