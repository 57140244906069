const contactKanbanStatuses = {
  'Contact Saved': 'saved',
  '1st Outreach': '1st-outreach',
  '2nd Outreach': '2nd-outreach',
  'Response Received': 'response-received',
  'Coffee Chat': 'coffee-chat',
  Nurturing: 'nurturing',
  'Golden Referral': 'golden-referral',
  Archived: 'archive',
} as const;

const flip = (data: typeof contactKanbanStatuses) => Object.fromEntries(
  Object
    .entries(data)
    .map(([key, value]) => [value, key]),
);

export type ContactStatus = keyof typeof contactKanbanStatuses;
const contactActiveStatuses = Object.keys(contactKanbanStatuses).filter((status) => status !== 'Archived') as ContactStatus[];
export type ContactApiStatus = (typeof contactKanbanStatuses)[keyof typeof contactKanbanStatuses];
const contactAPIstatuses = flip(contactKanbanStatuses) as { [key in ContactApiStatus]: ContactStatus };
const contactStatuses = Object.keys(contactKanbanStatuses) as ContactStatus[];

export {
  contactKanbanStatuses, contactAPIstatuses, contactActiveStatuses, contactStatuses,
};
