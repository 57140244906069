import { useQueryClient } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { SuggestedPeopleItem } from '@/domains/core/contact/types';
import { useAnalytics } from './use-analytics';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { extractNameSubstringFromContactLink } from '../helpers/contact';
import { useExtensionMessaging } from './use-extension-messaging';

type Props = {
  companyLinkedIn: string;
  companyId: string;
  savedContactsUrls: string[];
  companyName: string;
};

export const useLinkedInSuggestedContacts = ({
  companyId,
  companyLinkedIn,
  companyName,
  savedContactsUrls,
}: Props) => {
  const [exensionIsInstalled, setExtensionIsInstalled] = useState(false);
  const [isLoggedIntoLinkedIn, setIsLoggedIntoLinkedIn] = useState(false);
  const [suggestedContacts, setSuggestedContacts] = useState<SuggestedPeopleItem[]>([]);
  const [companyLinkedInID, setCompanyLinkedInID] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const { getSuggestedContacts, saveContact, checkLinkedInLogin } = useExtensionMessaging();
  const { addToast } = useContext(ToastContext) as AddToastType;
  const queryClient = useQueryClient();
  const suggestedContactsFromLocalStorageID = `suggested-contacts-info-${companyId}-version-1`;
  const savedContactsLinkedInIds = useMemo<string[]>(() => savedContactsUrls
    .map((link) => extractNameSubstringFromContactLink(link) || '')
    .filter(Boolean), [savedContactsUrls]);
  const filteredSuggestions = useMemo<SuggestedPeopleItem[]>(() => suggestedContacts
    .filter((contact) => {
      const nameSubstring = extractNameSubstringFromContactLink(contact.link);
      return nameSubstring !== null && !savedContactsLinkedInIds.includes(nameSubstring);
    }), [suggestedContacts, savedContactsLinkedInIds.length]);

  const getFilteredSuggestions = (suggestions: SuggestedPeopleItem[]): SuggestedPeopleItem[] => suggestions
    .filter((contact) => {
      const nameSubstring = extractNameSubstringFromContactLink(contact.link);
      return nameSubstring !== null && !savedContactsLinkedInIds.includes(nameSubstring);
    });

  const checkSuggestedContactsInLocalStorage = () => {
    let suggestedContactsFromLocalStorage: any = window.localStorage.getItem(suggestedContactsFromLocalStorageID);
    if (!suggestedContactsFromLocalStorage) {
      return [];
    }

    suggestedContactsFromLocalStorage = JSON.parse(suggestedContactsFromLocalStorage);
    setCompanyLinkedInID(suggestedContactsFromLocalStorage.companyLinkedInId);

    return suggestedContactsFromLocalStorage.contacts;
  };

  const updateContacts = () => {
    queryClient.invalidateQueries(['company-suggested-contacts', companyId]);
    queryClient.invalidateQueries(['company-saved-contacts', companyId]);
    queryClient.invalidateQueries(['company-campus-champions', companyId]);
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('contacts-no-cache');
    queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries('xp');
    queryClient.invalidateQueries('leaderboards');
    queryClient.invalidateQueries('gamification-actions');
    queryClient.invalidateQueries('challenges');
  };

  const handleSuggestedContactSave = async (contactUrl: string) => {
    if (!companyLinkedInID || !companyName) {
      return;
    }

    try {
      await saveContact(contactUrl, companyName, companyLinkedInID, companyId, companyLinkedIn);

      trackEvent('Saved Contact', user, {
        companyId,
        source: 'Suggested',
      });
      updateContacts();
      addToast(
        {
          type: 'success',
          message: 'Contact saved successfully.',
        },
      );
    } catch {
      trackEvent('Suggested Contact Failed', user, {
        companyId,
      });
      addToast(
        {
          type: 'warning',
          message: 'An error occurred. Click here to save this contact from their Linkedin profile.',
          handleClick: () => window.open(contactUrl, '_blank'),
        },
      );
    }
  };

  const checkUserExtension = async () => {
    const linkedInLoginStatus = await checkLinkedInLogin();

    if (!linkedInLoginStatus) {
      setExtensionIsInstalled(false);
      setIsLoading(false);
      setIsError(true);
      return false;
    }

    if (linkedInLoginStatus === 'User is not logged into LinkedIn.') {
      setExtensionIsInstalled(true);
      setIsLoggedIntoLinkedIn(false);
      setIsLoading(false);
      setIsError(true);
      return false;
    }

    setExtensionIsInstalled(true);
    setIsLoggedIntoLinkedIn(true);
    setIsError(false);

    return true;
  };

  const getSuggestedContactsFromExtension = async () => {
    setIsLoading(true);
    setIsError(false);
    try {
      const peopleResult = await getSuggestedContacts(companyLinkedIn, savedContactsLinkedInIds || []);

      if (!peopleResult?.contacts || !peopleResult?.contacts.length) {
        setIsLoading(false);
        return;
      }

      setSuggestedContacts(peopleResult.contacts);
      setCompanyLinkedInID(peopleResult.companyLinkedInId);
      window.localStorage.setItem(suggestedContactsFromLocalStorageID, JSON.stringify(peopleResult));
    } catch (e) {
      setIsError(true);
    }
    setIsLoading(false);
  };

  const getAndSetSuggestions = async () => {
    if (filteredSuggestions.length > 0) {
      return;
    }

    setIsLoading(true);
    const checkExtensionResult = await checkUserExtension();

    if (!checkExtensionResult) {
      setIsError(true);
      setIsLoading(false);
      return;
    }

    const suggestedContactsFromLocalStorage = checkSuggestedContactsInLocalStorage();
    const filteredSuggestionsFromLocalStorage = getFilteredSuggestions(suggestedContactsFromLocalStorage);

    if (!suggestedContactsFromLocalStorage.length) {
      getSuggestedContactsFromExtension();
    } else if (filteredSuggestionsFromLocalStorage.length === 0) {
      getSuggestedContactsFromExtension();
    } else {
      setSuggestedContacts(suggestedContactsFromLocalStorage);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkUserExtension();
    checkSuggestedContactsInLocalStorage();

    // Running extension related methods on tab visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkUserExtension();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return {
    getLinkedInSuggestedContacts: getAndSetSuggestions,
    saveLinkedInSuggestedContact: handleSuggestedContactSave,
    exensionIsInstalled,
    isLoggedIntoLinkedIn,
    isLoadingLinkedInSuggestedContacts: isLoading,
    isError,
    linkedInSuggestedContacts: filteredSuggestions,
    companyLinkedInID,
  };
};
