import { useNavigate, useParams, useRevalidator } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  InboxContactsList, ClickOnConversationCTA, ChatBox,
} from '@/domains/core/netwoking';
import './styles/inbox.scss';

import { useGetContact, useGetContactsNoCache } from '@/services/queries/contact';
import { ConnectionStatus, Contact } from '@/domains/core/contact/types';
import { useExtensionMessaging } from '@/services/hooks/use-extension-messaging';

import { fetchAllContactEmails, updateContactConnectionStatus } from '@/services/api/contact';
import { useAnalytics } from '@/services/hooks/use-analytics';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { InboxProvider } from './context/inbox';
import { ContactBioSidebar } from '@/domains/core/netwoking/components';
import { ChatBoxLoader } from '@/domains/core/netwoking/components/chat-box-loader/chat-box-loader';
import { useArchiveContact } from '@/services/hooks/use-archive-contact';

const MessagesInbox = () => {
  const [networkingStatus, setNetworkingStatus] = useState<ConnectionStatus>('notConnected');
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>('notConnected');
  const [isContactSidebarOpen, setIsContactSidebarOpen] = useState(false);
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const [firstChatContactId, setFirstChatContactId] = useState<string | undefined>();
  const [isPremiumLinkedIn, setIsPremiumLinkedIn] = useState(false);
  const { getContactConnectionStatus, getPremiumStatus, sendAccessToken: sendAccessTokenToExtension } = useExtensionMessaging();

  const { trackEvent } = useAnalytics();

  const queryClient = useQueryClient();

  const { data: contacts, isLoading: areContactsLoading, isRefetching: isRefetchingContacts } = useGetContactsNoCache();
  const [nonArchivedContacts, setNonArchivedContacts] = useState<Contact[]>([]);
  const params = useParams();
  const { contactID } = params;
  const [openedChatContactId, setOpenedChatContactId] = useState<string | undefined>(contactID);

  const { data: contact, isLoading: isContactLoading } = useGetContact(openedChatContactId, {
    enabled: !!openedChatContactId, // Only fetch when a valid contactID is available
  });

  const invalidateContactQuery = () => {
    queryClient.invalidateQueries(['contact', openedChatContactId]);
    queryClient.invalidateQueries('quests');
    revalidator.revalidate();
    trackEvent('Inbox Contact Email Changed');
  };

  const handleToggle = (val: boolean) => {
    setIsContactSidebarOpen(val);

    const searchParams = new URLSearchParams(window.location.search);
    if (val) {
      searchParams.set('open-sidebar', 'true');
      trackEvent('View Contact Profile');
    } else {
      searchParams.delete('open-sidebar');
    }
    window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
  };

  const closeContactSidebar = () => {
    handleToggle(false);
  };

  const updateContactsAfterArchive = () => {
    invalidateContactQuery();

    setNonArchivedContacts(nonArchivedContacts.filter((c: Contact) => c.id !== openedChatContactId));

    closeContactSidebar();
    navigate('/app/inbox');
  };

  const { requestArchiveConfirmation } = useArchiveContact(updateContactsAfterArchive);

  const updateConnectionStatus = async () => {
    if (isContactLoading) {
      return;
    }

    if (!contact) {
      return;
    }

    if (contact.linkedin_connection_status === 'connected') {
      setConnectionStatus(contact.linkedin_connection_status);
      return;
    }

    try {
      const status = await getContactConnectionStatus(contact.linkedin_url);
      if (!status) {
        setConnectionStatus(contact.linkedin_connection_status || 'notConnected');
      } else {
        setConnectionStatus(status);
        if (status !== contact.linkedin_connection_status) {
          updateContactConnectionStatus(contact, status);
        }
      }
    } catch (error) {
      setConnectionStatus(contact.linkedin_connection_status || 'notConnected');
    }
  };

  const checkPremiumProfile = async () => {
    try {
      const status = await getPremiumStatus();
      if (status) {
        setIsPremiumLinkedIn(status);
      }
    } catch {
      trackEvent('LinkedIn extension not installed');
    }
  };

  const checkContactSidebarValueInSearchParams = () => {
    const searchParams = new URLSearchParams(window.location.search);
    const openSidebar = searchParams.get('open-sidebar');
    if (openSidebar) {
      setIsContactSidebarOpen(true);
    }
  };

  useEffect(() => {
    if (contact && !isContactLoading) {
      updateConnectionStatus();
    }
  }, [contact, isContactLoading]);

  useEffect(() => {
    if (!areContactsLoading && !isRefetchingContacts) {
      fetchAllContactEmails();
      const filteredContacts = Array.isArray(contacts) ? contacts.filter((c: Contact) => c.kanban_position.status !== 'archive') : [];
      setNonArchivedContacts(filteredContacts);
    }
  }, [contacts, areContactsLoading, isRefetchingContacts]);

  useEffect(() => {
    if (firstChatContactId && !contactID) {
      setOpenedChatContactId(firstChatContactId);
    } else {
      setOpenedChatContactId(contactID);
    }
  }, [firstChatContactId, contactID]);

  useEffect(() => {
    checkPremiumProfile();
    sendAccessTokenToExtension();
    checkContactSidebarValueInSearchParams();

    // Running extension related methods on tab visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        checkPremiumProfile();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return (
    <div className={`messages-inbox messages-inbox--${isContactSidebarOpen ? 'opened' : 'closed'}`}>
      <div className="messages-inbox__sidebar">
        <InboxContactsList isContactSidebarOpen={isContactSidebarOpen} isLoadingContacts={areContactsLoading || isRefetchingContacts} contacts={nonArchivedContacts || []} selectedContactId={contact?.id} changeFirstChatContactId={setFirstChatContactId} />
      </div>
      {contact && !isContactLoading && (
        <InboxProvider contact={contact} isPremiumLinkedIn={isPremiumLinkedIn}>
          <div className="messages-inbox__content">
            {openedChatContactId && connectionStatus && !isContactLoading && (
              <ChatBox
                connectionStatus={connectionStatus}
                handleSetNetworkingStatus={setNetworkingStatus}
                isChatFullWidth={isContactSidebarOpen}
                handleWidthToggle={handleToggle}
                networkingStatus={networkingStatus}
              />
            )}
          </div>
          <div className="messages-inbox__contact">
            {contact && openedChatContactId && connectionStatus && (
              <ContactBioSidebar
                networkingStatus={networkingStatus}
                connectionStatus={connectionStatus}
                onEmailChanged={invalidateContactQuery}
                handleArchive={() => requestArchiveConfirmation(openedChatContactId)}
                handleClose={closeContactSidebar}
              />
            )}
          </div>
        </InboxProvider>

      )}

      {!contact && openedChatContactId && (
        <div className="messages-inbox__content">
          <ChatBoxLoader />
        </div>
      )}

      {!openedChatContactId && (
        <div className="messages-inbox__content">
          <ClickOnConversationCTA />
        </div>
      )}
    </div>
  );
};

export default CustomWithAuthenticationRequired(MessagesInbox);
