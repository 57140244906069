export function setLatestUrlToStorage(url: string) {
  const latestUrlObject = new URL(url);
  const latestUrlData = {
    route: latestUrlObject.pathname + latestUrlObject.search,
    added_at: Date.now(),
  };
  window.localStorage.setItem('latestAppUrl', JSON.stringify(latestUrlData));
}

export function removeLatestUrlFromStorage() {
  window.localStorage.removeItem('latestAppUrl');
}

export function getLatestUrlFromStorage(): string | null {
  const latestUrlData = window.localStorage.getItem('latestAppUrl');
  if (!latestUrlData) {
    return null;
  }

  const parsedData = JSON.parse(latestUrlData);

  removeLatestUrlFromStorage();

  // If the URL is older than 1 hour, we don't want to redirect to it
  if ((Date.now() - parsedData.added_at) > 3600000) {
    return null;
  }

  return parsedData.route;
}
