/* eslint-disable no-plusplus */
/* eslint-disable no-nested-ternary */
import AMSTemplate from '../components/ams-template';
import ASBTemplate from '../components/asb-template';
import BayesTemplate from '../components/bayes-template';
import EsadeTemplate from '../components/esade-template';
import ESMTATSTemplate from '../components/esmt-ast-template';
import ESMTTemplate from '../components/esmt-template';
import GeorgiaTemplate from '../components/georgia-template';
import HultEMBATemplate from '../components/hult-emba-template';
import HultMastersTemplate from '../components/hult-masters-template';
import HultMBANTemplate from '../components/hult-mban-template';
import OxfordTemplate from '../components/oxford-template';
import MarylandMbaTemplate from '../components/maryland-mba-template';
import MarylandMsTemplate from '../components/maryland-ms-template';
import MarylandUgTemplate from '../components/maryland-ug-template';
import RiceTemplate from '../components/rice-template';
import HHLEducationTemplate from '../components/hhl-education-template';
import MarylandMba2PageTemplate from '../components/maryland-mba-template-2-pager';
import HHLWorkTemplate from '../components/hhl-work-template';
import HHLTemplate from '../components/hhl-template';
import MBSMBATemplate from '../components/mbs-mba-template';
import MBSMastersTemplate from '../components/mbs-masters-template';
import MBSBachelorsTemplate from '../components/mbs-bachelors-template';
import EBSTemplate from '../components/ebs-template';
import EBS2PageTemplate from '../components/ebs-template-2-pager';
import {
  AMS_FIELDS,
  ASB_FIELDS,
  BAYES_FIELDS,
  BOCCONI_STANDARD_FIELDS,
  EBS_2_PAGE_FIELDS,
  EBS_FIELDS,
  ESMT_ATS_FIELDS,
  ESMT_FIELDS,
  GEORGIA_FIELDS,
  HHL_EDUCATION_FIELDS,
  HHL_ONE_PAGE_FIELDS,
  HHL_WORK_FIELDS,
  HULT_EMBA_FIELDS,
  HULT_FIELDS,
  HULT_MBAN_FIELDS,
  MARYLAND_MBA_2_PAGE_FIELDS,
  MARYLAND_MBA_FIELDS,
  MARYLAND_MS_FIELDS,
  MARYLAND_UG_FIELDS,
  MBS_BACHELORS_FIELDS,
  MBS_MBA_FIELDS,
  MODERN_MINIMALIST_FIELDS,
  MODERN_SIMPLE_FIELDS,
  OXFORD_FIELDS,
  RICE_FIELDS,
} from './resume-fields';
import {
  AMS_RULES,
  ASB_RULES,
  BAYES_RULES,
  BOCCONI_STANDARD_RULES,
  EBS_RULES,
  ESMT_ATS_RULES,
  ESMT_RULES,
  GEORGIA_RULES,
  HHL_EDUCATION_RULES,
  HHL_ONE_PAGE_RULES,
  HHL_WORK_RULES,
  HULT_RULES,
  MARYLAND_MBA_RULES,
  MARYLAND_UG_RULES,
  MBS_MBA_RULES,
  MODERN_MINIMALIST_RULES,
  OXFORD_RULES,
  RESUME_RULES,
  RICE_RULES,
} from './fields-validation-rules';
import BocconiTemplate from '../components/bocconi-template';

export const RESUME_TEMPLATES: any = {
  'Modern Simple': EsadeTemplate,
  AMS: AMSTemplate,
  'ESMT Standard': ESMTTemplate,
  'Hult Masters': HultMastersTemplate,
  'Hult MBAN': HultMBANTemplate,
  'Hult MBA': HultMastersTemplate,
  'Hult EMBA': HultEMBATemplate,
  'Hult BBA': HultMastersTemplate,
  Rice: RiceTemplate,
  'Modern Minimalist': AMSTemplate,
  Oxford: OxfordTemplate,
  Georgia: GeorgiaTemplate,
  'Compliant with AI-powered ATS': ESMTATSTemplate,
  ASB: ASBTemplate,
  Bayes: BayesTemplate,
  'Maryland MBA': MarylandMbaTemplate,
  'Maryland MBA (2 pager)': MarylandMba2PageTemplate,
  'Maryland MS': MarylandMsTemplate,
  'Maryland UG': MarylandUgTemplate,
  'HHL 2 Page - Education first': HHLEducationTemplate,
  'HHL 2 Page - Work first': HHLWorkTemplate,
  'HHL 1 Page': HHLTemplate,
  'MBS MBA': MBSMBATemplate,
  'MBS Masters': MBSMastersTemplate,
  'MBS Bachelors': MBSBachelorsTemplate,
  EBS: EBSTemplate,
  'EBS (2 pager)': EBS2PageTemplate,
  'Bocconi Standard': BocconiTemplate,
};

export const RESUME_SIZE_LIMITS = {
  'ESMT Standard': 1,
  'Modern Simple': 1,
  'Hult Masters': 1,
  'Hult MBAN': 1,
  'Hult MBA': 1,
  'Hult BBA': 1,
  'Hult EMBA': 2,
  Rice: 1,
  'Modern Minimalist': 1,
  Georgia: 1,
  'Compliant with AI-powered ATS': 1,
  Oxford: 1,
  ASB: 1,
  AMS: 1,
  Bayes: 1,
  'Maryland MBA': 1,
  'Maryland MBA (2 pager)': 2,
  'Maryland MS': 1,
  'Maryland UG': 1,
  'HHL 2 Page - Education first': 2,
  'HHL 2 Page - Work first': 2,
  'HHL 1 Page': 1,
  'MBS MBA': 1,
  'MBS Masters': 1,
  'MBS Bachelors': 1,
  EBS: 1,
  'EBS (2 pager)': 2,
  'Bocconi Standard': 1,
};

export const RESUME_FIELDS = {
  'ESMT Standard': ESMT_FIELDS,
  'Modern Simple': MODERN_SIMPLE_FIELDS,
  'Hult Masters': HULT_FIELDS,
  'Hult MBAN': HULT_MBAN_FIELDS,
  'Hult MBA': HULT_FIELDS,
  'Hult BBA': HULT_FIELDS,
  'Hult EMBA': HULT_EMBA_FIELDS,
  Rice: RICE_FIELDS,
  'Modern Minimalist': MODERN_MINIMALIST_FIELDS,
  Georgia: GEORGIA_FIELDS,
  Oxford: OXFORD_FIELDS,
  'Compliant with AI-powered ATS': ESMT_ATS_FIELDS,
  ASB: ASB_FIELDS,
  AMS: AMS_FIELDS,
  Bayes: BAYES_FIELDS,
  'Maryland MBA': MARYLAND_MBA_FIELDS,
  'Maryland MBA (2 pager)': MARYLAND_MBA_2_PAGE_FIELDS,
  'Maryland MS': MARYLAND_MS_FIELDS,
  'Maryland UG': MARYLAND_UG_FIELDS,
  'HHL 2 Page - Education first': HHL_EDUCATION_FIELDS,
  'HHL 2 Page - Work first': HHL_WORK_FIELDS,
  'HHL 1 Page': HHL_ONE_PAGE_FIELDS,
  'MBS MBA': MBS_MBA_FIELDS,
  'MBS Masters': MBS_MBA_FIELDS,
  'MBS Bachelors': MBS_BACHELORS_FIELDS,
  EBS: EBS_FIELDS,
  'EBS (2 pager)': EBS_2_PAGE_FIELDS,
  'Bocconi Standard': BOCCONI_STANDARD_FIELDS,
};

export const UNIVERSITY_RULES = {
  'ESMT Standard': ESMT_RULES,
  'Modern Simple': RESUME_RULES,
  'Hult Masters': HULT_RULES,
  'Hult MBAN': HULT_RULES,
  'Hult MBA': HULT_RULES,
  'Hult BBA': HULT_RULES,
  'Hult EMBA': HULT_RULES,
  Rice: RICE_RULES,
  'Modern Minimalist': MODERN_MINIMALIST_RULES,
  Georgia: GEORGIA_RULES,
  Oxford: OXFORD_RULES,
  'Compliant with AI-powered ATS': ESMT_ATS_RULES,
  ASB: ASB_RULES,
  AMS: AMS_RULES,
  Bayes: BAYES_RULES,
  'Maryland MBA': MARYLAND_MBA_RULES,
  'Maryland MS': MARYLAND_MBA_RULES,
  'Maryland MBA (2 pager)': MARYLAND_MBA_RULES,
  'Maryland UG': MARYLAND_UG_RULES,
  'HHL 2 Page - Education first': HHL_EDUCATION_RULES,
  'HHL 2 Page - Work first': HHL_WORK_RULES,
  'HHL 1 Page': HHL_ONE_PAGE_RULES,
  'MBS MBA': MBS_MBA_RULES,
  'MBS Masters': MBS_MBA_RULES,
  'MBS Bachelors': MBS_MBA_RULES,
  EBS: EBS_RULES,
  'EBS (2 pager)': EBS_RULES,
  'Bocconi Standard': BOCCONI_STANDARD_RULES,
};

export const ALLOWED_TEMPLATES: { [key: string]: string[] } = {
  ESMT: ['ESMT Standard', 'Compliant with AI-powered ATS'],
  Trial: ['Bocconi Standard', 'Compliant with AI-powered ATS', 'Hult Masters', 'Hult EMBA', 'Hult MBA', 'Hult BBA', 'Hult MBAN', 'Bayes', 'ESMT Standard', 'Maryland MBA', 'Maryland MS', 'Maryland UG', 'Maryland MBA (2 pager)', 'HHL 2 Page - Education first', 'HHL 2 Page - Work first', 'HHL 1 Page', 'MBS MBA', 'MBS Masters', 'MBS Bachelors', 'EBS', 'EBS (2 pager)'],
  'Hult International Business School': ['Hult Masters', 'Hult EMBA', 'Hult MBA', 'Hult BBA', 'Hult MBAN'],
  'Rice University': ['Rice'],
  'University of Georgia': ['Georgia'],
  'University of Oxford': ['Oxford'],
  ASB: ['ASB'],
  'University of Maryland': ['Maryland MBA', 'Maryland MS', 'Maryland UG', 'Maryland MBA (2 pager)'],
  'Munich Business School': ['MBS MBA', 'MBS Masters', 'MBS Bachelors'],
  'HHL Leipzig Graduate School of Management': ['HHL 2 Page - Education first', 'HHL 2 Page - Work first', 'HHL 1 Page'],
  'SDA Bocconi School of Management': ['Bocconi Standard'],
};

export const DEFAULT_TEMPLATES: any = ['Modern Simple', 'Modern Minimalist'];
