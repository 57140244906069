import { Counter, StatusIndicator } from '@careeros/coco';
import './kanban-column.scss';
import { TooltipIcon } from '@/components/tooltip-icon/tooltip-icon';

type Props = {
  title: string;
  children: React.ReactNode;
  withCounter?: boolean;
  counter?: number;
  isLive?: boolean;
  isSpecial?: boolean;
  isArchived?: boolean;
  icon?: string;
  withStatusIndicator?: boolean;
  withExtraInfo?: boolean;
  extraInfoLabel?: string | JSX.Element;
};

export const KanbanColumn = ({
  title,
  children,
  withCounter,
  counter,
  isLive,
  isSpecial,
  isArchived,
  icon,
  withStatusIndicator = false,
  withExtraInfo = false,
  extraInfoLabel,
}: Props) => (
  <div className={`kanban-column ${isSpecial ? 'kanban-column--special' : ''}`}>
    <div className="kanban-column__header">
      <div className="kanban-column__header-left">
        <div className={`kanban-board-contacts__title ${isArchived ? 'kanban-board-contacts__title--archive' : ''}`}>
          {icon && <i className={`${icon} kanban-column__icon ${isSpecial ? 'kanban-column__icon--special' : ''}`} />}
          <span className={`kanban-column__title-text ${isSpecial ? 'kanban-column__title-text--special' : ''}`}>{title}</span>
        </div>
        {withExtraInfo && (
          <TooltipIcon
            tooltipLabel={extraInfoLabel}
            tooltipPosition="bottom-center"
            withoutTooltipArrow
          />
        )}
        {(withCounter && counter !== undefined) && (
          <span className="kanban-board-contacts__counter">
            <Counter counter={counter} size="medium" mode="default" />
          </span>
        )}
      </div>
      {withStatusIndicator && (
        <StatusIndicator
          status={isLive ? 'active' : 'unknown'}
          label={{
            active: 'Contact status updated automatically',
            unknown: 'Contact status updated manually',
          }}
          withTooltip
        />
      )}
    </div>
    {children}
  </div>
);
