import { useContext, useState } from 'react';
import { InfoModal, Tooltip } from '@careeros/coco';
import { ModalContext } from '../modal/modal-provider';
import './tooltip-icon.scss';

type Props = {
  tooltipLabel?: string | JSX.Element;
  tooltipPosition?: 'top' | 'bottom' | 'right' | 'bottom-center';
  textSize?: 'text-large' | 'text-medium' | 'text-small';
  withoutTooltipArrow?: boolean
  icon?: string;
  hoverIcon?: string;
  withModal?: boolean;
  modalTitle?: string;
  modalDescription?: string | JSX.Element;
  tooltipTextAlignment?: 'left' | 'center' | 'right';
  modalOrientation?: 'vertical' | 'horizontal';
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
};

export const TooltipIcon = ({
  tooltipLabel,
  tooltipPosition = 'top',
  textSize,
  withModal = false,
  modalTitle: title,
  modalDescription: description,
  icon = 'bi-info-circle',
  hoverIcon = `${icon}-fill`,
  tooltipTextAlignment = 'left',
  modalOrientation = 'vertical',
  withoutTooltipArrow = false,
  onClick,
}: Props) => {
  const [isHovered, setIsHovered] = useState(false);
  const { openModal, closeModal } = useContext(ModalContext) as any;

  // this should be handled outside this component @Anastasiia
  const handleIconClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (withModal && title && description) {
      openModal(
        <InfoModal
          orientation={modalOrientation}
          icon="bi bi-info-circle"
          title={title}
          description={description}
          buttonLabel="Got it!"
          handleButtonClick={closeModal}
        />,
      );
    }
  };

  return (
    <div
      onClick={onClick}
      className={`tooltip-icon ${onClick ? 'tooltip-icon--clickable' : ''}`}
      data-testid="tooltip-icon"
      data-tooltip-position={tooltipPosition}
    >
      {tooltipLabel ? (
        <Tooltip textAlignment={tooltipTextAlignment} textSize={textSize} label={tooltipLabel} withArrow={!withoutTooltipArrow} position={tooltipPosition}>
          <i
            className={`bi ${
              isHovered ? hoverIcon : icon
            }`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          />
        </Tooltip>
      ) : (
        <i
          className={`bi ${
            isHovered ? hoverIcon : icon
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleIconClick}
        />
      )}
    </div>
  );
};
