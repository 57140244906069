import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getAvailableActions, getChallenges, completeAction, getLeaderboards,
  readChallenge,
  getXp,
} from '../api/gamification';
import { Leaderboards } from '@/types/gamification';

export const useGetChallengesQuery = () => useQuery('challenges', getChallenges, {
  retry: 0,
  // no stale time
  staleTime: 0,
});

export const useGetXpQuery = () => useQuery('xp', getXp, {
  retry: 0,
  // no stale time
  staleTime: 0,
});

export const useGetGamificationActionsQuery = () => useQuery('gamification-actions', getAvailableActions, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60 * 10,
});

export const useCompleteGamificationActionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(completeAction, {
    onSuccess: () => {
      queryClient.invalidateQueries('gamification-actions');
      queryClient.invalidateQueries('challenges');
      queryClient.invalidateQueries('xp');
      queryClient.invalidateQueries('leaderboards');
    },
  });
};

export const useReadChallengeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(readChallenge, {
    onSuccess: () => {
      // Invalidate the challenges query to trigger a refetch
      queryClient.invalidateQueries('challenges');
    },
  });
};

export const useGetLeaderboardsQuery = () => useQuery('leaderboards', async () => {
  const response: Leaderboards | { error: any } = await getLeaderboards();

  if ('error' in response) {
    throw new Error(String(response.error));
  }

  return response;
}, {
  retry: 0,
  // should be refetched in the background every 8 hours
  staleTime: 1000 * 60,
});
