import './company-teams.scss';
import { ApplicationProcess, CardWithBadges, TeamSelector } from '@careeros/coco';
import { useState } from 'react';
import { Team } from '@/domains/core/company/types';

type CompanyTeamsProps = {
  teams: Team[];
  onTeamClick?: () => void;
};

const CompanyTeamsContainer = ({ teams, onTeamClick }: CompanyTeamsProps) => {
  const [selectedTeam, setSelectedTeam] = useState<Team | undefined>(teams?.[0] || undefined);

  const handleTeamSelect = (team: string) => {
    setSelectedTeam(teams.find((t) => t.name === team) || undefined);
    if (onTeamClick) {
      onTeamClick();
    }
  };

  if (!selectedTeam) {
    return null;
  }

  return (
    <div className="about-application">
      <TeamSelector
        teams={teams.map((team) => team.name)}
        selectedTeam={selectedTeam.name}
        onSelect={handleTeamSelect}
      />

      <CardWithBadges
        title={selectedTeam.name}
        badges={selectedTeam.skills || []}
        description={selectedTeam.description}
      />

      <div className="cos_team-requirements">
        <CardWithBadges
          title="Working Style"
          badges={selectedTeam.working_style || []}
        />

        <CardWithBadges
          title="Languages Spoken"
          badges={selectedTeam.languages_spoken || []}
        />
      </div>
      <ApplicationProcess application_steps={selectedTeam.application_steps || []} />
    </div>
  );
};

export default CompanyTeamsContainer;
