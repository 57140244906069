import {
  useContext, useEffect, useLayoutEffect, useRef, useState,
} from 'react';
import { createPortal } from 'react-dom';
// eslint-disable-next-line import/no-cycle
import { ModalContext } from './modal-provider';
import './modal.scss';

// TODO: find a better way to solve problem with root
const modalRoot = document.querySelector('#modal-root') as HTMLElement || document.querySelector('#storybook-root') as HTMLElement;
const Modal = () => {
  const {
    modalContent, showModal, closeModal, modalNotDismissible,
  } = useContext(ModalContext) as any;

  // Use a ref to track the previous showModal value
  const prevShowModalRef = useRef(false);
  // Track whether the modal should be in the DOM
  const [isInDOM, setIsInDOM] = useState(false);
  // Track the animation states separately for content and backdrop
  const [contentAnimationClass, setContentAnimationClass] = useState('');
  const [backdropAnimationClass, setBackdropAnimationClass] = useState('');
  // Track if we're in the process of closing
  const [isClosing, setIsClosing] = useState(false);

  // Handle the close with animation
  const handleCloseWithAnimation = () => {
    if (!modalNotDismissible && !isClosing) {
      setIsClosing(true);

      // First animate the content out
      setContentAnimationClass('exit');

      // After content animation completes, animate backdrop
      setTimeout(() => {
        setBackdropAnimationClass('exit');

        // After backdrop animation completes, close the modal
        setTimeout(() => {
          closeModal();
          setIsClosing(false);
        }, 400); // Match with backdrop CSS transition duration
      }, 300); // Slightly shorter than content animation to create overlap
    }
  };

  const closeOnEscapeKeyDown = (e: any) => {
    if ((e.charCode || e.keyCode) === 27 && !modalNotDismissible && !isClosing) {
      handleCloseWithAnimation();
    }
  };

  const handleCloseModal = (e: React.MouseEvent) => {
    e.preventDefault();
    handleCloseWithAnimation();
  };

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyDown);
    };
  }, [isClosing]);

  // Handle body scroll locking
  useEffect(() => {
    if (showModal) {
      document.body.classList.add('body-no-scroll');
      document.body.style.overflow = 'hidden';
    } else {
      document.body.classList.remove('body-no-scroll');
      document.body.style.overflow = '';
    }
  }, [showModal]);

  // Handle animation and DOM presence
  useLayoutEffect(() => {
    // Opening the modal
    if (showModal && !prevShowModalRef.current) {
      setIsInDOM(true);
      setIsClosing(false);

      // Reset animation classes
      setContentAnimationClass('');
      setBackdropAnimationClass('');

      // Force a reflow before animation
      const forceReflow = () => document.body.offsetHeight;
      forceReflow();

      // Apply animation classes in next frame
      requestAnimationFrame(() => {
        setContentAnimationClass('show');
        setBackdropAnimationClass('show');
      });
    } else if (!showModal && prevShowModalRef.current && !isClosing) {
      // Only handle unmounting if we're not already in a closing animation
      // This prevents double animations when handleCloseWithAnimation is used
      setIsInDOM(false);
    }

    // Update the ref for the next render
    prevShowModalRef.current = showModal;
  }, [showModal, isClosing]);

  // Don't render anything if not in DOM
  if (!isInDOM && !isClosing) return null;

  return createPortal(
    <div className="modal-container">
      {/* Backdrop with fade animation */}
      <div
        className={`modal-backdrop ${backdropAnimationClass}`}
        onClick={handleCloseModal}
      />

      {/* Content with slide animation */}
      <div className="modal-wrapper">
        <div
          className={`modal-content ${contentAnimationClass}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div
            onClick={handleCloseWithAnimation}
            className={`modal-close ${modalNotDismissible ? 'modal-close--disabled' : ''}`}
          >
            <i className="bi bi-x-lg" />
          </div>
          <div className="modal-body">{modalContent}</div>
        </div>
      </div>
    </div>,
    modalRoot,
  );
};

export default Modal;
