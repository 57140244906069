import { useEffect, useMemo, useState } from 'react';
import './ai-tools-tab.scss';
import { Button } from '@careeros/coco';
import { AIToolCard } from '../ai-tool-card/ai-tool-card';
import { AITools, AIToolsNames } from '@/domains/core/tracker-data';
import { Job, Note } from '../../types';
import { JobPicker } from '../job-picker/job-picker';
import { AIToolResponse } from '../ai-tool-response/ai-tool-response';
import { useAnalytics } from '@/services/hooks/use-analytics';

type Props = {
  preselectedTool?: AIToolsNames;
  jobs: Job[];
  companyName: string;
  companyLinkedIn: string;
  companyID: string;
  preselectedJobName?: string;
  changeTab: (tab: string, state?: string) => void;
  handleNoteAdded: (note: Note) => void;
};

export const AIToolsTab = ({
  preselectedTool,
  companyName,
  jobs,
  companyID,
  changeTab,
  companyLinkedIn,
  preselectedJobName,
  handleNoteAdded,
}: Props) => {
  const [selectedTool, setSelectedTool] = useState<AIToolsNames | null>(null);
  const [selectedJob, setSelectedJob] = useState<string>();
  const [sectionTitle, setSectionTitle] = useState<string>(selectedTool || '');
  const showSelectJob = useMemo(() => selectedTool && selectedTool !== 'Company Insights' && !selectedJob, [selectedTool, selectedJob]);
  const { trackEvent } = useAnalytics();

  const handleToolSelection = (tool: AIToolsNames) => {
    trackEvent('AI Tool Selected', { tool });
    setSelectedTool(tool);

    setSectionTitle(tool);

    if (tool === 'Learn About Roles' && selectedJob) {
      setSectionTitle(`${selectedJob} at ${companyName}`);
    }
  };

  const handleBackClick = () => {
    setSelectedJob(preselectedJobName);
    setSectionTitle(selectedTool || '');
    setSelectedTool(null);
  };

  const handleJobSelection = (job: string) => {
    setSelectedJob(job);

    if (selectedTool === 'Learn About Roles') {
      setSectionTitle(`${selectedJob} at ${companyName}`);
    }
  };

  useEffect(() => {
    if (preselectedTool) {
      handleToolSelection(preselectedTool);
    }
  }, [preselectedTool]);

  useEffect(() => {
    if (preselectedJobName) {
      handleJobSelection(preselectedJobName);
    }
  }, [preselectedJobName]);

  if (!selectedTool) {
    return (
      <div className="ai-tools__container">
        {AITools.map((tool) => (
          <AIToolCard
            key={tool}
            toolName={tool}
            companyName={companyName}
            handleButtonClick={() => handleToolSelection(tool)}
          />
        ))}
      </div>
    );
  }

  return (
    <div className="ai-tools">
      <div className="ai-tools__back-button">
        <Button
          mode="invisible"
          size="medium"
          label="All AI Tools"
          icon="bi bi-arrow-left"
          handleClick={handleBackClick}
        />
      </div>
      <div className="ai-tools__tool">
        <h3 className="ai-tools__title">{sectionTitle}</h3>
        {showSelectJob && (
          <div className="ai-tools__select-job">
            <JobPicker
              jobs={jobs.map((job) => job.title)}
              changeTab={changeTab}
              companyLinkedIn={companyLinkedIn}
              handleJobPick={handleJobSelection}
            />
          </div>
        )}
        {selectedTool && !showSelectJob && (
          <AIToolResponse handleNoteAdded={handleNoteAdded} companyID={companyID} companyName={companyName} toolName={selectedTool} jobTitle={selectedJob} />
        )}
      </div>
    </div>
  );
};
