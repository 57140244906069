import { useState } from 'react';
import { Button, Input, Checkbox } from '@careeros/coco';
import { Job } from '@/domains/core/company/types';
import './manual-add-job.scss';

interface AddJobProps {
  companyName: string;
  companyID: string;
  handleJobAdd: (job: Omit<Job, 'id'>) => void;
}

export const ManuallyAddJob = ({
  companyName, companyID, handleJobAdd,
}: AddJobProps) => {
  const [formData, setFormData] = useState<Omit<Job, 'id'>>({
    company_name: companyName,
    company_id: companyID,
    title: '',
    location: '',
    linkedin_url: '',
    description: '',
    note: '',
    deadline: '',
    posted_on: new Date().toISOString(),
    rolling_date: false,
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const optionalFields = ['note', 'rolling_date', 'deadline', 'id', 'linkedin_url'];

  const rollingDateChangeHandler = (
    prevFormData: typeof formData,
    name: string,
    value: string | boolean,
  ) => {
    if (name === 'rollingDate') {
      const newRollingDate = !prevFormData.rolling_date;
      return {
        ...prevFormData,
        rolling_date: newRollingDate,
      };
    }

    return {
      ...prevFormData,
      [name]: value,
    };
  };

  const onChangeHandler = (value: string | boolean, name: string = '') => {
    setFormData((prevFormData) => rollingDateChangeHandler(prevFormData, name, value));
  };

  const isFieldValid = (fieldName: string) => {
    if (fieldName === 'linkedin_url') {
      return true;
    }
    return formData[fieldName].trim() !== '';
  };
  const onSubmitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    const formDataIsValid = Object.entries(formData)
      .filter(entry => !optionalFields.includes(entry[0]))
      .every(entry => entry[1].trim().length !== 0);
    if (formDataIsValid) {
      formData.deadline = formData.deadline ? new Date(formData.deadline).toISOString() : undefined;

      handleJobAdd(formData);
    }
  };

  return (
    <div className="manually-add-job">
      <h3 className="manually-add-job__title">
        Save Job Manually
      </h3>
      <form className={`manually-add-job__form${isSubmitted ? ' manually-add-job__form--submitted' : ''}`} onSubmit={onSubmitHandler}>
        <Input
          label="Job title"
          id="title"
          placeholder="e.g. Consultant"
          invalid={isSubmitted && !isFieldValid('title')}
          warningText="This field is mandatory"
          value={formData.title}
          handleValueChange={onChangeHandler}
        />
        <Input
          label="Location"
          id="location"
          placeholder="e.g. Berlin"
          invalid={isSubmitted && !isFieldValid('location')}
          warningText="This field is mandatory"
          value={formData.location}
          handleValueChange={onChangeHandler}
        />
        <Input
          label="Job URL"
          id="linkedin_url"
          type="text"
          placeholder="www.google.com"
          invalid={isSubmitted && !isFieldValid('linkedin_url')}
          value={formData.linkedin_url}
          handleValueChange={onChangeHandler}
        />
        <Input
          label="Add application deadline"
          value={formData.deadline}
          id="deadline"
          placeholder=""
          type="date"
          handleValueChange={onChangeHandler}
          min={new Date().toISOString().split('T')[0]}
          max="3000-12-31"
          readOnly={formData.rolling_date}
        />
        <div className="manually-add-job__rolling-date">
          <Checkbox
            label="No deadline"
            checked={formData.rolling_date}
            onChange={(v) => onChangeHandler(v, 'rollingDate')}
            id="rollingDate"
          />
        </div>
        <div className="manually-add-job__field">
          <label htmlFor="description" className="manually-add-job__label">
            Description
            <textarea
              name="description"
              id="description"
              className={`manually-add-job__textarea ${isSubmitted && !isFieldValid('description') ? 'manually-add-job__textarea--invalid' : ''}`}
              placeholder="e.g. Consultants do a lot of Powerpoint presentations..."
              onChange={(e) => onChangeHandler(e.target.value, e.target.name)}
            />
            {(isSubmitted && !isFieldValid('description')) && (
              <span className="manually-add-job__warning">This field is mandatory</span>
            )}
          </label>
        </div>
        <div className="manually-add-job__button">
          <Button
            type="submit"
            mode="primary"
            label="Save"
            icon="bi bi-floppy"
            size="medium"
          />
        </div>
      </form>
    </div>
  );
};
