import { useNavigate } from 'react-router-dom';
import { Tooltip, DefaultLogo } from '@careeros/coco';

import { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { formatDateDeltaFromNow } from '@/services/helpers/date';
import './contact-box.scss';
import { stripHTML } from '@/services/helpers/processHtml';
import { MessageType } from '@/domains/core/netwoking/types';

interface ContactBoxProps {
  contactImage: string;
  contactName: string;
  messageTimestamp: any;
  company: string;
  text: string;
  contactId: string;
  selected?: boolean;
  type: MessageType;
  isMessageEmpty?: boolean;
  isCampusChampion?: boolean;
}

export const ContactBox = ({
  contactName,
  contactImage,
  company,
  text,
  messageTimestamp,
  contactId,
  selected = false,
  type,
  isMessageEmpty = false,
  isCampusChampion,
}: ContactBoxProps) => {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const boxRef = useRef<HTMLDivElement>(null);

  const scrollToContact = () => {
    if (boxRef.current) {
      boxRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const goToContact = () => {
    trackEvent('Inbox: Contact clicked');
    navigate(`/app/inbox/${contactId}?view=${type}`);
  };

  useEffect(() => {
    if (selected) {
      setTimeout(() => {
        scrollToContact();
      }, 100);
    }
  }, [selected, boxRef.current]);

  return (
    <div
      className={`contact-box ${selected ? 'contact-box--selected' : ''} ${isMessageEmpty ? 'contact-box--empty' : ''}`}
      ref={boxRef}
      onClick={goToContact}
      data-testid="contact-box"
    >
      <div className="contact-box__container">
        <DefaultLogo
          type="contact"
          source={contactImage}
          handleClick={goToContact}
          className={`contact-box__image ${isCampusChampion ? 'contact-box__image--campus-champion' : ''}`}
          name={contactName}
          size={48}
        />
        <div
          className="contact-box__type"
          data-testid="contact-box-type"
        >
          {type === 'email' ? <i className="bi bi-envelope" /> : <i className="bi bi-linkedin" /> }
        </div>
      </div>
      <div className="contact-box__info">
        <div className="contact-box__top-info">
          <div className="contact-box__name" data-testid="contact-box-name">
            {contactName}
            {isCampusChampion && (
            <Tooltip label="Campus Champion" position="top">
              <span>
                {' '}
                ⭐
              </span>
            </Tooltip>
            )}
          </div>
          <div className="contact-box__date" data-testid="contact-box-date">
            {messageTimestamp ? formatDateDeltaFromNow(messageTimestamp.toDate()) : <i className="bi bi-chat-heart-fill" />}
          </div>
        </div>
        <div className="contact-box__company" data-testid="contact-box-company">
          {company}
        </div>
        <p className={`contact-box__text ${isMessageEmpty ? 'contact-box__text--empty' : ''}`} data-testid="contact-box-text" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(stripHTML(text)) }} />
      </div>
    </div>
  );
};
