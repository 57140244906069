import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@careeros/coco';
import { ContactKanbanTile } from '@/domains/generic/kanban/contact-tile/contact-tile';
import { mockedSuggestedContacts } from '../../types/mocked-contacts';
import LockedImage from '@/assets/images/emoji-locked.png';
import './empty-suggested-contacts-column.scss';

export const EmptySuggestedContactsColumn = () => {
  const navigate = useNavigate();

  return (
    <div className="empty-suggested-contacts-column">
      <div className="empty-suggested-contacts-column__list">
        {mockedSuggestedContacts.map((contact) => (
          <ContactKanbanTile
            image={contact.image}
            name={contact.name}
            companyName={contact.companyName}
            position={contact.position}
            handleSave={() => { }}
            handleCompanyClick={() => { }}
            companyLogo={contact.companyLogo}
            networkingStatusRate={contact.networkingStatusRate}
            isAlumni={contact.isAlumni}
            showLanguages={contact.languages.length > 0}
            isCampusChampion={contact.isCampusChampion}
            languages={contact.languages}
            schoolName={contact.schoolName}
            campusChampionClickHandler={() => { }}
          />
        ))}
      </div>

      <div className="empty-suggested-contacts-column__banner">
        <img src={LockedImage} alt="" className="empty-suggested-contacts-column__prompt-image" />
        <h3 className="empty-suggested-contacts-column__prompt-title">Unlock Suggested Contacts</h3>
        <p className="empty-suggested-contacts-column__prompt-description">
          <Link to="/app/companies/explore" className="empty-suggested-contacts-column__prompt-link">Save at least 5 companies </Link>
          to unlock personalized contacts
        </p>

        <Button
          label="Explore Companies"
          mode="primary"
          size="medium"
          handleClick={() => navigate('/app/companies/explore')}
          icon="bi bi-box-arrow-up-right"
          iconPosition="right"
        />
      </div>
    </div>
  );
};
