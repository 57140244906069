import { DefaultLogo } from '@careeros/coco';
import { Column, TableRow } from '@/components/table/table-types';
import { CompanyRating } from '@/domains/core/company';
import { APIstatuses } from '@/domains/core/tracker-data';
import { CommentFromCA } from '@/domains/core/advisor/types';
import { TileV2 } from '@/domains/core/company/types/tile-v2';
import { OBTableActions } from '../ob-table-actions/ob-table-actions';

export const ApplicationsTableColumnsV2: Column[] = [
  {
    label: 'Name', accessor: 'name', sortable: true, type: 'link', sortbyOrder: 'asc', isCentered: false,
  },
  {
    label: 'Type', accessor: 'type', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Status', accessor: 'status', sortable: true, type: 'text', isCentered: false,
  },
  {
    label: 'Industry', accessor: 'industry', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Hype Level', accessor: 'hype_level', type: 'text', sortable: true, isCentered: false,
  },
  {
    label: 'Actions', accessor: 'actions', type: 'actions', sortable: false, isCentered: false,
  },
];

type ApplicationMapHandler = (
  tiles: TileV2[],
  additionalData: {
    openTracker: (id: string, tab?: string | undefined, additionalParams?: { [key: string]: string }, jobID?: string) => void,
    handleCompanyRate: (id: string, rate: number) => Promise<void>,
    comments: CommentFromCA[],
  }
) => TableRow[];

export const mapApplicationsV2: ApplicationMapHandler = (tiles, {
  openTracker, handleCompanyRate, comments,
}) => (Array.isArray(tiles) ? tiles.map((tile) => ({
  id: tile.id,
  handleRowClick: () => openTracker(tile.company.id, undefined, undefined, tile.job?.id),
  items: {
    name: {
      value: (tile.type === 'job' && tile.job?.title) ? tile.job?.title : tile.company.name,
      label: (tile.type === 'job' && tile.job?.title) ? tile.job?.title : tile.company.name,
      image: <DefaultLogo
        type="contact"
        source={tile.company.logo_url}
        className="applications-table__image"
        name={tile.company.name}
        size={24}
      />,
      type: 'link',
      handleClick: () => openTracker(tile.company.id, undefined, undefined, tile.job?.id),
    },
    status: {
      value: tile.status,
      label: APIstatuses[tile.status],
      type: 'text',
    },
    industry: {
      type: 'text',
      label: tile.company.industry,
      value: tile.company.industry,
    },
    type: {
      type: 'text',
      label: tile.type.charAt(0).toUpperCase() + tile.type.slice(1),
      value: tile.type,
    },
    hype_level: {
      type: 'icon',
      label: (
        <div className="applications-table__rating">
          <CompanyRating handleCompanyRate={(rate) => handleCompanyRate(tile.id, rate)} currentRate={tile.rating} />
        </div>
      ),
      value: tile.rating,
    },
    actions: {
      label: (
        <OBTableActions
          companyID={tile.company.id}
          companyName={tile.company.name}
          comments={comments.filter((comment: CommentFromCA) => comment.application?.id === tile.id)}
          tileID={tile.id}
          countOfContacts={tile.count_of_contacts}
          tileOrdering={tile.ordering}
          jobID={tile.job?.id}
          type={tile.type}
          tileStatus={tile.status}
          jobTitle={tile.job?.title}
        />
      ),
      value: tile.id,
    },
  },
})) : []);
