import { useEffect } from 'react';
import { Loader } from '@/components/loader/loader';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';

import './styles/articles.scss';
import { useSelfUniversity } from '@/services/queries/user';

function ArticlesPage() {
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();

  useEffect(() => {
    if (!isUniversityLoading && !university?.articles_url) {
      throw new Error('University does not have articles URL');
    }
  }, [university, isUniversityLoading]);

  if (isUniversityLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="articles-page">
      <div className="articles-page__iframe-container">
        <iframe
          src={university?.articles_url}
          className="articles-page__iframe"
          allow="autoplay; fullscreen"
          title="Articles"
        />
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(ArticlesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
