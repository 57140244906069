/* eslint-disable react/no-array-index-key */
import {
  Page, Text, View, Document, StyleSheet, Font,
  Link,
} from '@react-pdf/renderer';
import { HighlightWords, ResumeSchema } from '../../types';
import Calibri from '@/assets/fonts/resume-builder/calibri-font-family/calibri-regular.ttf';
import CalibriBold from '@/assets/fonts/resume-builder/calibri-font-family/calibri-bold.ttf';
import CalibriItalic from '@/assets/fonts/resume-builder/calibri-font-family/calibri-italic.ttf';

import {
  computeSkillsStyle,
  renderAdditionalHighlightedText,
  renderWorkHighlightedText,
} from '../helpers/utils';

Font.register({ family: 'Calibri', src: Calibri, fonts: [{ src: Calibri, fontWeight: 400 }, { src: CalibriBold, fontWeight: 700 }, { src: CalibriItalic, fontStyle: 'italic' }] });

Font.registerHyphenationCallback(word => (
  [word]
));

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: '#fff',
    fontFamily: 'Calibri',
    width: '100%',
    paddingBottom: 20,
  },
  mainHeader: {
    flexDirection: 'column',
    backgroundColor: '#8597b0',
    padding: '10px 40px 10px 40px',
    color: '#fff',
    marginBottom: 10,
  },
  secondPageHeader: {
    flexDirection: 'column',
    backgroundColor: '#8597b0',
    padding: '10px 40px 10px 40px',
    marginBottom: '10px',
    color: '#fff',
  },
  secondPageHeaderText: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    fontSize: 24,
  },
  secondPageContactInfoRow: {
    marginBottom: 1,
  },
  headerText: {
    textAlign: 'left',
    fontWeight: 'bold',
    fontFamily: 'Calibri',
    fontSize: 24,
    marginBottom: '5px',
    textTransform: 'uppercase',
  },
  contactInfo: {
    flexDirection: 'column',
    fontSize: 10,
    gap: 3,
  },
  contactInfoRow: {
    marginBottom: 3,
  },
  normalText: {
    fontSize: 10,
  },
  dateText: {
    fontSize: 10,
    color: '#8597b0',
  },
  sectionHeader: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 5,
    textTransform: 'uppercase',
    color: '#8597b0',
  },
  sectionLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#8597b0',
    width: '100%',
    marginBottom: 10,
  },
  columnContainer: {
    flexDirection: 'column',
    padding: '10px 40px 0px 40px',
    flexGrow: 0,
  },
  profileSection: {
    marginBottom: 15,
  },
  row: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  educationBlock: {
    marginBottom: 5,
    flexDirection: 'row',
    width: '100%',
  },
  dateColumn: {
    width: '20%',
  },
  detailsColumn: {
    width: '80%',
  },
  detailText: {
    fontSize: 10,
    marginLeft: 15,
    marginBottom: 5,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  skillsRow: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  skillLabel: {
    fontSize: 10,
    color: '#8597b0',
    width: '20%',
  },
  skillContent: {
    fontSize: 10,
    marginLeft: 20,
    width: '80%',
  },
  sectionContainer: {
    flexDirection: 'column',
    padding: '10px 40px 0px 40px',
    flexGrow: 0,
  },
  pageBreakSection: {
    flexDirection: 'column',
    padding: '0px 40px',
    flexGrow: 0,
  },
  aditionalLabel: {
    fontSize: 10,
    color: '#8597b0',
  },
  highlights: {
    width: '100%',
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    fontSize: 10,
    marginLeft: 15,
    lineHeight: 1.1,
  },
  bulletPoint: {
    width: 10,
    fontSize: 10,
  },
  highlightText: {
    fontSize: 10,
    color: '#000',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginBottom: 3,
  },
  GreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
  },
  RedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
  },
  SkillsGreenHighlight: {
    color: '#07795C',
    textDecoration: 'underline',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsRedHighlight: {
    color: '#BA1A1A',
    textDecoration: 'underline',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleGreenHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#bbece880',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
  SkillsSubtleRedHighlight: {
    color: '#000',
    textDecoration: 'none',
    backgroundColor: '#ffdad680',
    fontSize: 10,
    fontFamily: 'Calibri',
  },
});

type ResumeSchemaProps = {
  resume: ResumeSchema,
  title: string,
  highlightedWords: HighlightWords | null,
  activeField: { type: 'work' | 'additional_information'; index: number; bulletIndex: number } | null,
};

const HHLWorkTemplate = ({
  resume, title, highlightedWords, activeField,
}: ResumeSchemaProps) => (
  <Document title={title}>
    <Page size="A4" style={styles.page} wrap>

      <View style={styles.mainHeader} fixed>
        <Text style={styles.headerText}>{resume.basics.name}</Text>
        <View style={styles.contactInfo}>
          {(resume.basics.location?.address || resume.basics.location?.city) && (
          <Text style={styles.contactInfoRow}>
            {`Address: ${resume.basics.location?.address}${(resume.basics.location?.address && resume.basics.location?.city) ? ', ' : ''}${resume.basics.location?.city}`}
          </Text>
          )}
          {resume.basics.phone_numbers?.[0] && (
          <Text style={styles.contactInfoRow}>
            Phone:
            {' '}
            {resume.basics.phone_numbers?.[0]}
          </Text>
          )}
          {resume.basics.emails?.[0] && (
          <Text style={styles.contactInfoRow}>
            Email:
            {' '}
            <Link href={`mailto:${resume.basics.emails[0]}`}>
              {resume.basics.emails[0]}
            </Link>
          </Text>
          )}
          {resume.basics.url && (
          <Text style={styles.contactInfoRow}>
            LinkedIn:
            {' '}
            <Link href={resume.basics.url}>
              {resume.basics.url}
            </Link>
          </Text>
          )}
          {resume.basics.birth_date && (
          <Text style={styles.contactInfoRow}>
            Date of Birth:
            {' '}
            {resume.basics.birth_date}
          </Text>
          )}
          {resume.basics.nationality && resume.basics.nationality.filter(nationality => nationality.trim().length > 0).length > 0 && (
          <Text style={styles.contactInfoRow}>
            Nationality:
            {' '}
            {Array.isArray(resume.basics.nationality) ? resume.basics.nationality.join(', ') : resume.basics.nationality}
          </Text>
          )}
        </View>
      </View>

      {resume.basics.summary && (
      <View style={[styles.sectionContainer, { paddingTop: 0 }]}>
        <Text style={styles.sectionHeader}>PROFILE</Text>
        <View style={styles.sectionLine} />
        <View style={styles.profileSection}>
          <Text style={styles.normalText}>{resume.basics.summary}</Text>
        </View>
      </View>
      )}

      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>WORK EXPERIENCE</Text>
        <View style={styles.sectionLine} />
        {resume.work?.map((work, index) => (
          <View key={work.company} style={styles.educationBlock}>
            <View style={styles.dateColumn}>
              <Text style={styles.dateText}>
                {work.startDate}
                {work.startDate && work.endDate && ' - '}
                {work.endDate === 'Present' ? work.endDate : work.endDate}
              </Text>
            </View>
            <View style={styles.detailsColumn}>
              <Text style={[styles.normalText, { fontWeight: 'bold' }]}>
                {work.name}
                {work.location ? `, ${work.location}` : ''}
              </Text>
              {work.position && <Text style={styles.normalText}>{work.position}</Text>}
              <View style={styles.column}>
                {work.highlights && work.highlights.length > 0 && (
                  <View style={styles.highlights}>
                    {work.highlights.map((highlight, highlightIndex) => {
                      const cleanedHighlight = highlight.replace(/^•\s/, '');

                      return (
                        <View key={`${highlight}-${highlightIndex}`} style={styles.listItem} wrap={false}>
                          <View style={styles.highlightText}>
                            <Text style={styles.bulletPoint}>•</Text>
                            {renderWorkHighlightedText(cleanedHighlight, highlightIndex, index, highlightedWords, activeField, styles)}
                          </View>
                        </View>
                      );
                    })}
                  </View>
                )}
              </View>
            </View>
          </View>
        ))}
      </View>

      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>EDUCATION</Text>
        <View style={styles.sectionLine} />

        {resume.education?.map((edu) => (
          <View key={edu.institution} style={styles.educationBlock}>
            <View style={styles.dateColumn}>
              <Text style={styles.dateText}>
                {edu.startDate}
                {edu.startDate && edu.endDate && ' - '}
                {edu.endDate === 'Present' ? edu.endDate : edu.endDate}
              </Text>
            </View>
            <View style={styles.detailsColumn}>
              <Text style={[styles.normalText, { fontWeight: 'bold' }]}>
                {edu.institution}
                {edu.location ? `, ${edu.location}` : ''}
              </Text>
              {edu.area && <Text style={styles.normalText}>{edu.area}</Text>}
              {edu.studyType && <Text style={styles.detailText}>{edu.studyType}</Text>}
              <View style={styles.column}>
                {edu.description?.map((desc) => (
                  <Text style={styles.detailText}>
                    •
                    {' '}
                    {desc}
                  </Text>
                ))}
              </View>

              {edu.score && (
                <Text style={styles.detailText}>
                  • GPA:
                  {' '}
                  {edu.score}
                </Text>
              )}
            </View>
          </View>
        ))}
      </View>

      {(resume.skills?.length > 0 || resume.languages?.length || resume.certificates?.length > 0) && (
      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>SKILLS</Text>
        <View style={styles.sectionLine} />

        <View style={styles.detailsColumn}>
          <View style={styles.skillsRow}>
            <Text style={styles.skillLabel}>Languages</Text>
            <Text style={styles.skillContent}>{resume.languages?.map((language) => `${language.language} (${language.fluency})`).join(', ')}</Text>
          </View>
          <View style={styles.skillsRow}>
            {resume.skills?.length > 0 && <Text style={styles.skillLabel}>Technical Skills</Text>}
            <Text style={[computeSkillsStyle(highlightedWords, activeField, styles), styles.skillContent]}>{resume.skills?.map((skill) => skill.name).join(', ')}</Text>
          </View>

          {resume.certificates?.length > 0 && (
          <View style={styles.skillsRow}>
            <Text style={styles.skillLabel}>Certificates</Text>
            <Text style={styles.skillContent}>{resume.certificates?.map((certificate) => `${certificate.name} (${certificate.date})`).join(', ')}</Text>
          </View>
          )}
        </View>
      </View>
      )}

      {resume.extracurricular_activities?.length && (
      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>EXTRACURRICULAR ACTIVITIES</Text>
        <View style={styles.sectionLine} />
        {resume.extracurricular_activities?.map((extracurricular) => (
          <View key={extracurricular.name} style={styles.educationBlock}>
            <View style={styles.dateColumn}>
              {(extracurricular.startDate || extracurricular.endDate) && (
              <Text style={styles.dateText}>
                {extracurricular.startDate}
                {' '}
                -
                {' '}
                {extracurricular.endDate === 'Present' ? extracurricular.endDate : extracurricular.endDate}
              </Text>
              )}
            </View>
            <View style={styles.detailsColumn}>
              <Text style={[styles.normalText, { fontWeight: 'bold' }]}>
                {extracurricular.institution}
                {extracurricular.location ? `, ${extracurricular.location}` : ''}
              </Text>
              {extracurricular.role && <Text style={styles.normalText}>{extracurricular.role}</Text>}
              <View style={styles.column}>
                {extracurricular.description?.map((description) => (
                  <Text style={styles.detailText}>
                    •
                    {' '}
                    {description}
                  </Text>
                ))}
              </View>
            </View>
          </View>
        ))}
      </View>
      )}

      {resume.additional_information.subheadings?.length && (
      <View style={styles.sectionContainer}>
        <Text style={styles.sectionHeader}>MISCELLANEOUS</Text>
        <View style={styles.sectionLine} />
        {(() => {
          let globalBulletIndex = 0;
          return resume.additional_information?.order?.reduce((acc: JSX.Element[], item, index, array) => {
            if (item.type === 'heading') {
              const bulletPoints = [];
              let i = index + 1;
              while (i < array.length && array[i].type === 'bullet') {
                if (array[i].value) {
                  bulletPoints.push({ value: array[i].value, index: globalBulletIndex });
                  globalBulletIndex += 1;
                }
                i += 1;
              }

              if (bulletPoints.length > 0) {
                acc.push(
                  <View style={styles.educationBlock}>
                    <View style={styles.dateColumn}>
                      <Text style={styles.aditionalLabel}>
                        {item.value}
                      </Text>
                    </View>
                    <View style={styles.detailsColumn}>
                      {bulletPoints.map((bullet) => (
                        <Text style={styles.normalText}>
                          {renderAdditionalHighlightedText(bullet.value.replace(/^•\s/, ''), bullet.index, highlightedWords, activeField, styles)}
                        </Text>
                      ))}
                    </View>
                  </View>,
                );
              }
            }
            return acc;
          }, []);
        })()}
      </View>
      )}
    </Page>
  </Document>
);

export default HHLWorkTemplate;
