/* eslint-disable no-restricted-syntax */
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import * as Sentry from '@sentry/react';
import { Button } from '@careeros/coco';
import { Loader } from '@/components/loader/loader';
import '@/pages/styles/dashboard.scss';
import {
  StudentResumeTableColumns,
} from '@/domains/core/advisor';
import {
  buildCohortsWithAll,
  mapStudentsToResumeTableData,
} from '@/services/helpers/advisor';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import CustomWithAuthenticationRequired from '../../auth/custom-protected-route';
import { useStudentsByUniversity } from '@/services/hooks/use-students-by-university';
import { useAdvisorData } from '@/services/hooks/use-student-data';
import Table from '@/components/table/table';
import { useAdvisorRoleCheck } from '@/pages/career-advisor/hooks/authz';
import { useAnalytics } from '@/services/hooks/use-analytics';
import '../../styles/students-resumes.scss';
import { CommentInputBox } from '@/domains/core/advisor/components/comment-input-box/comment-input-box';
import { addStudentGeneralComment } from '@/domains/core/advisor/api/comment';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ToastContext } from '@/components/toast/toast-provider';
import { StudentFilters } from '@/domains/core/advisor/components/resume-student-filters/resume-student-filters';
import { useResumeStudentFilters } from '@/domains/core/advisor/hooks/use-resume-student-filters';
import { updateURLParams } from '@/domains/core/advisor/utils/update-url-params';
import { getStudentsResumeFiltersFromSearchParams } from '@/domains/core/advisor/utils/get-filters-after-page-load';

function StudentsResumesPage() {
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const { data: university } = useSelfUniversity();
  const { universityID, cohortID } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: self } = useSelf();
  const { addToast }: AddToastType = useContext(ToastContext);

  const [isCommentBoxVisible, setIsCommentBoxVisible] = useState(false);
  const [commentBoxData, setCommentBoxData] = useState({
    studentId: '',
    studentEmail: '',
    studentFirstName: '',
    studentLastName: '',
    defaultMessage: '',
  });

  const {
    data: studentsResponse,
    cohorts,
    isCohortsLoading,
    isStudentsLoading,
  } = useStudentsByUniversity('resumes', universityID || university?.id);

  const {
    students,
    isLoading,
  } = useAdvisorData(
    studentsResponse,
    universityID || university?.id,
    cohortID,
  );

  const {
    searchValue,
    selectedStatus,
    selectedStatusLabel,
    scoreRange,
    statusOptions,
    filteredStudents,
    handleFilterChange,
  } = useResumeStudentFilters({
    students,
  });

  const cohortsWithAll = useMemo(() => {
    if (!cohorts) return [];
    return buildCohortsWithAll('/app/resume-os/students', cohorts, universityID, university?.id);
  }, [cohorts]);

  const closeCommentBox = () => {
    setIsCommentBoxVisible(false);
  };

  const handleCreateResumeBookClick = () => {
    navigate(`/app/resume-os/students/${universityID || university?.id}/${cohortID || 'all-cohorts'}/resume-book`);
    trackEvent('Create a Resume Book Clicked');
  };

  const sendGeneralComment = async (comment: string, studentId?: string, studentEmail?: string) => {
    if (!studentId || !studentEmail) return;
    const caName = `${self?.first_name} ${self?.last_name}`;
    const profilePicture = self?.profile_picture_url || '';

    try {
      await addStudentGeneralComment(studentId, comment, studentEmail, caName, profilePicture);
      addToast({
        type: 'success',
        message: 'Success',
        additionalMessage: 'Comment was sent successfully to the student.',
      });
    } catch (error) {
      Sentry.captureException(error);
      addToast({
        type: 'error',
        message: 'Error',
        additionalMessage: 'Something went wrong. Please try again.',
      });
    }
    setIsCommentBoxVisible(false);
    trackEvent('CA Left "Nudge student to work on resume(s)" comment');
  };

  const openAddCommentBox = useCallback((studentName: string, studentLastName: string, studentId?: string, studentEmail?: string) => {
    const prefillMessage = `Hi ${studentName},<br><br>I noticed you have not created a base resume yet.<br><br>Thanks and best,<br><br>${self?.first_name} ${self?.last_name}`;

    setCommentBoxData({
      studentId: studentId || '',
      studentEmail: studentEmail || '',
      studentFirstName: studentName || '[Student First Name]',
      studentLastName: studentLastName || '[Student Last Name]',
      defaultMessage: prefillMessage,
    });

    setIsCommentBoxVisible(true);
    trackEvent('CA Resume "Nudge student to work on resume(s)" Clicked');
  }, [self]);

  const tableData = useMemo(
    () => mapStudentsToResumeTableData(filteredStudents, openAddCommentBox),
    [filteredStudents],
  );

  useAdvisorRoleCheck();

  useEffect(() => {
    updateURLParams(searchValue, selectedStatus, scoreRange, searchParams, setSearchParams);
  }, [searchValue, selectedStatus, scoreRange, searchParams, setSearchParams]);

  useEffect(() => {
    handleFilterChange(getStudentsResumeFiltersFromSearchParams(searchParams));
  }, []);

  if (isCohortsLoading || isStudentsLoading || isLoading) {
    return (
      <div id="loader-zone">
        <Loader />
      </div>
    );
  }

  return (
    <div className="students-resumes">
      <div className="students-resumes__top-container">
        <StudentFilters
          cohortsWithAll={cohortsWithAll}
          scoreRange={scoreRange}
          searchValue={searchValue}
          selectedStatusLabel={selectedStatusLabel}
          statusOptions={statusOptions}
          onSearchChange={(value) => handleFilterChange({ search: value })}
          onStatusChange={(value) => handleFilterChange({ status: value })}
          onScoreRangeChange={(value) => handleFilterChange({ scoreRange: value })}
        />
        <Button
          mode="primary"
          size="medium"
          label="Create a Resume Book"
          icon="bi bi-files"
          onClick={handleCreateResumeBookClick}
        />
      </div>

      <div className="students-resumes__content">
        {(filteredStudents.length > 0) && (
          <div className="career-os-grid">
            <div className="width-24/24">
              <div className="students-resumes__table">
                <Table
                  data={tableData}
                  columns={StudentResumeTableColumns}
                  hasTooltip={false}
                />
              </div>
            </div>
          </div>
        )}
        {(filteredStudents.length === 0) && (
          <div className="students-resumes__no-students">
            <div className="students-resumes__image">
              <div className="coming-soon-box">
                There are no students found.
              </div>
            </div>
          </div>
        )}
      </div>

      {isCommentBoxVisible && (
        <div className="students-resumes__comment-box">
          <CommentInputBox
            handleSendComment={(comment) => sendGeneralComment(comment, commentBoxData.studentId, commentBoxData.studentEmail)}
            title="General Comment"
            studentFirstName={commentBoxData.studentFirstName}
            studentLastName={commentBoxData.studentLastName}
            closeCommentBox={closeCommentBox}
            showAITemplates
            defaultMessage={commentBoxData.defaultMessage}
            withCloseButton
          />
        </div>
      )}
    </div>
  );
}

export default CustomWithAuthenticationRequired(StudentsResumesPage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
