import { useAuth0 } from '@auth0/auth0-react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import {
  useGetCampusChampionsByCompanyID, useGetSavedContactsByCompany, useSuggestedContactsByCompanyID,
} from '../queries/contact';
import { useSelf } from '../queries/user';
import { useAnalytics } from './use-analytics';
import {
  Contact, ContactWithNetworkingData, SuggestedContactType,
} from '@/domains/core/contact/types';
import { useNetworkingStatuses } from './use-networking-statuses';
import { useAlgoliaInsights } from './use-algolia';
import { saveContact } from '../api/contact';

type PreparedNetworkingContact = ContactWithNetworkingData & { isCampusChampion?: boolean };

export const useCompanyContacts = (companyID: string) => {
  const { data: savedContacts, isLoading: isLoadingSavedContactsResponse } = useGetSavedContactsByCompany(companyID, { refetchOnMount: 'always' });
  const { data: suggestedContacts, isLoading: isLoadingSuggestedContactsResponse } = useSuggestedContactsByCompanyID(companyID);
  const { data: campusChampions, isLoading: isLoadingChampions } = useGetCampusChampionsByCompanyID(companyID);
  const { data: self } = useSelf();
  const { user } = useAuth0();
  const { trackEvent } = useAnalytics();
  const queryClient = useQueryClient();
  const insights = useAlgoliaInsights();
  const navigate = useNavigate();
  const { mapAllConnectionStatuses, mapSuggestedContactsWithConnectionStatus } = useNetworkingStatuses();
  const [savedContactsWithConnectionStatus, setSavedContactsWithConnectionStatus] = useState<ContactWithNetworkingData[]>([]);
  const [suggestedContactsWithConnectionStatus, setSuggestedContactsWithConnectionStatus] = useState<SuggestedContactType[]>([]);
  const [preparedSavedContacts, setPreparedSavedContacts] = useState<PreparedNetworkingContact[]>([]);
  const [preparedSuggestedContacts, setPreparedSuggestedContacts] = useState<SuggestedContactType[]>([]);
  const [isLoadingSuggestedContacts, setIsLoadingSuggestedContacts] = useState(true);
  const [isLoadingSavedContacts, setIsLoadingSavedContacts] = useState(true);
  const aggregatedLinkedInUrls = useMemo(() => {
    const savedUrls = savedContacts?.map((contact) => contact.linkedin_url) || [];
    const suggestedUrls = preparedSuggestedContacts?.map((contact) => contact.linkedin_url) || [];

    return [...savedUrls, ...suggestedUrls];
  }, [savedContacts, preparedSuggestedContacts]);

  const revalidateContacts = () => {
    queryClient.invalidateQueries(['company-suggested-contacts', companyID]);
    queryClient.invalidateQueries(['company-saved-contacts', companyID]);
    queryClient.invalidateQueries(['company-campus-champions', companyID]);
    queryClient.invalidateQueries(['suggested-contacts']);
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('contacts-no-cache');
    queryClient.invalidateQueries('quests');
    queryClient.invalidateQueries('gamification-actions');
    queryClient.invalidateQueries('challenges');
    queryClient.invalidateQueries('xp');
    queryClient.invalidateQueries('leaderboards');
  };

  const handleReachOut = async (id: string) => {
    const suggestedContact = preparedSuggestedContacts?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;
    await saveContact(suggestedContact, suggestedContact.company_id);
    revalidateContacts();
    trackEvent('Saved Contact', user, {
      companyID,
      source: 'Suggested Contacts from our DB',
      type: 'Reach out',
    });

    if (self?.id) {
      insights.purchasedObjectIDs(
        'Contact Saved',
        'contact',
        [suggestedContact.contact_id],
        self.id,
      );
    }
    navigate(`/app/inbox/${suggestedContact.contact_id}`);
  };

  const handleSaveSuggestedContact = async (id: string) => {
    const suggestedContact = preparedSuggestedContacts?.find((contact: SuggestedContactType) => contact.contact_id === id);
    if (!suggestedContact) return;

    await saveContact(suggestedContact, suggestedContact.company_id);

    revalidateContacts();
    trackEvent('Saved Contact', user, {
      companyID,
      source: 'Suggested Contacts from our DB',
      type: 'Save',
    });

    if (self?.id) {
      insights.purchasedObjectIDs(
        'Contact Saved',
        'contact',
        [suggestedContact.contact_id],
        self.id,
      );
    }
  };

  const getSuggestedContactsWithConnectionStatus = async (suggestedContactsPayload: SuggestedContactType[]) => {
    const contactLinkedInURLs = suggestedContactsWithConnectionStatus.map((contact) => contact.linkedin_url);
    const pendingLinkedInURLs = suggestedContactsPayload.map((contact) => contact.linkedin_url);
    const newContactUrls = pendingLinkedInURLs.filter((contactUrl) => !contactLinkedInURLs.includes(contactUrl));

    if (newContactUrls.length === 0) {
      return suggestedContactsWithConnectionStatus.filter((contact) => pendingLinkedInURLs.includes(contact.linkedin_url));
    }

    const result: SuggestedContactType[] = await mapSuggestedContactsWithConnectionStatus(suggestedContactsPayload);

    setSuggestedContactsWithConnectionStatus(result);

    return result;
  };

  const silentlyUpdateSuggestedContactsWithConnectionStatus = async (suggestedContactsPayload: SuggestedContactType[]) => {
    const updatedResponse = await getSuggestedContactsWithConnectionStatus(suggestedContactsPayload);

    setPreparedSuggestedContacts(updatedResponse);
  };

  const prepareSuggestedContacts = () => {
    const filteredSuggestedContacts = suggestedContacts?.filter((suggestedContact: SuggestedContactType) => {
      const savedContact = savedContacts?.find((savedC) => savedC.id === suggestedContact.contact_id);
      return !savedContact;
    }) || [];

    const filteredCampusChampions = campusChampions?.filter((campusChampion: SuggestedContactType) => {
      const savedContact = savedContacts?.find((savedC) => savedC.id === campusChampion.contact_id);
      return !savedContact;
    }) || [];

    setPreparedSuggestedContacts([...filteredCampusChampions, ...filteredSuggestedContacts]);
    setIsLoadingSuggestedContacts(false);
    silentlyUpdateSuggestedContactsWithConnectionStatus([...filteredCampusChampions, ...filteredSuggestedContacts]);
  };

  const getSavedContactsWithConnectionStatus = async (savedContactsPayload: Contact[]) => {
    const contactLinkedInURLs = savedContactsWithConnectionStatus.map((contact) => contact.linkedin_url);
    const pendingLinkedInURLs = savedContactsPayload?.map((contact) => contact.linkedin_url) || [];
    const newContactUrls = pendingLinkedInURLs.filter((contactUrl) => !contactLinkedInURLs.includes(contactUrl));

    if (newContactUrls.length === 0) {
      return savedContactsWithConnectionStatus.filter((contact) => pendingLinkedInURLs.includes(contact.linkedin_url));
    }

    if (savedContactsPayload && self) {
      const result: ContactWithNetworkingData[] = await mapAllConnectionStatuses(savedContactsPayload, self.id);

      setSavedContactsWithConnectionStatus(result);

      return result;
    }

    return savedContactsPayload;
  };

  const silentlyUpdateSavedContactsWithConnectionStatus = async (savedContactsPayload: Contact[]) => {
    const updatedResponse = await getSavedContactsWithConnectionStatus(savedContactsPayload);

    setPreparedSavedContacts(updatedResponse);
  };

  const prepareSavedContacts = () => {
    const filteredContacts = savedContacts?.filter((contact) => contact.kanban_position.status !== 'archive') || [];

    const sortedContacts = filteredContacts.sort((a, b) => {
      if (a.is_campus_champion && !b.is_campus_champion) {
        return -1;
      }
      if (!a.is_campus_champion && b.is_campus_champion) {
        return 1;
      }

      return new Date(b.saved_at || 0).getTime() - new Date(a.saved_at || 0).getTime();
    }) || [];

    setPreparedSavedContacts(sortedContacts);
    setIsLoadingSavedContacts(false);
    silentlyUpdateSavedContactsWithConnectionStatus(sortedContacts);
  };

  useEffect(() => {
    if (!isLoadingChampions && !isLoadingSavedContactsResponse && !isLoadingSuggestedContactsResponse) {
      prepareSuggestedContacts();
    }
  }, [suggestedContacts, campusChampions, savedContacts, isLoadingChampions, isLoadingSavedContactsResponse, isLoadingSuggestedContactsResponse]);

  useEffect(() => {
    if (!isLoadingSavedContactsResponse && savedContacts && campusChampions && !isLoadingChampions && self) {
      prepareSavedContacts();
    }
  }, [savedContacts, campusChampions, isLoadingChampions, self, isLoadingSavedContactsResponse]);

  return {
    savedContacts: preparedSavedContacts,
    computedSuggestedContacts: preparedSuggestedContacts,
    handleSaveSuggestedContact,
    handleReachOutSuggestedContact: handleReachOut,
    isLoadingSavedContacts,
    isLoadingSuggestedContacts,
    aggregatedLinkedInUrls,
  };
};
