import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLoaderData } from 'react-router-dom';

import {
  Button, IconButton, InfoModal, Chip, ChipTypes,
  Checkbox,
} from '@careeros/coco';

import {
  contractStaticOptions,
  languageMap,
  postedDateStaticOptions,
  workTypeStaticOptions,
} from './constants';
import { mapAndSortCompanySizes, sortedIndustries } from '@/domains/core/company/components/companies-filters/data-parser';
import { FilteredCompaniesResponse, FilterOption } from '@/domains/core/company/types';
import {
  JobFilterChipType,
  JobFilterTagTypes,
  JobsFilterState,
  JobsLocationsType,
  JobFeedJob,
} from '../../types/job';
import { MultiSelect } from '@/components/multi-select/multi-select';
import { MobileJobFilters } from '@/domains/core/job';
import { ModalContext } from '@/components/modal/modal-provider';
import { JobsFiltersModal } from './job-filters-modal';
import { useAnalytics } from '@/services/hooks/use-analytics';
import './job-filters.scss';
import { isMobileDevice } from '@/services/helpers/responsive';
import { DropdownSearchDynamic } from '@/components/dropdown-search-dynamic/dropdown-search-dynamic';
import { getJobsLocationsByQuery } from '@/services/api/job';
import { convertURLParamsToFilters } from '../../helpers/convertURLParamsToFilters';
import { useSelfUniversity } from '@/services/queries/user';
import { AlgoliaFilterBar } from '@/domains/generic/';

type Props = {
  filters: JobsFilterState,
  jobsLocations: JobsLocationsType[],
  jobsLanguages: string[],
  setFilters: (newFilters: JobsFilterState | ((prev: JobsFilterState) => JobsFilterState)) => void,
  isDisabled?: boolean,
  setJobs: (jobs: JobFeedJob[]) => void,
  setCurrentActiveId: (id: string) => void,
  setTotalJobsCount: (count: number) => void,
  refetchJobs: () => void,
  setIsSearchActive: (isSearchActive: boolean) => void,
};

export const JobFilters = ({
  filters,
  jobsLocations,
  jobsLanguages,
  setFilters,
  isDisabled = false,
  setJobs,
  setCurrentActiveId,
  refetchJobs,
  setTotalJobsCount,
  setIsSearchActive,
}: Props) => {
  const [selectedFiltersTags, setSelectedFiltersTags] = useState<JobFilterChipType[]>([]);
  const [isMobileModalOpen, setIsMobileModalOpen] = useState(false);
  const { sizes } = useLoaderData() as FilteredCompaniesResponse;
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const { trackEvent } = useAnalytics();
  const { data: university } = useSelfUniversity();
  const jobsLocationsOptions = useMemo(() => {
    if (!Array.isArray(jobsLocations)) {
      return [];
    }

    return jobsLocations
      .filter(location => {
        const locationName = [location.city, location.state, location.country]
          .filter(Boolean)
          .join(', ');
        return filters?.locations?.every(selectedLocation => selectedLocation.name !== locationName);
      })
      .map(location => ({
        name: [location.city, location.state, location.country]
          .filter(Boolean)
          .join(', '),
        value: JSON.stringify(location),
      }));
  }, [jobsLocations, filters?.locations]);

  const jobsLanguagesOptions: FilterOption[] = useMemo<FilterOption[]>(() => (
    Array.isArray(jobsLanguages)
      ? jobsLanguages.map(language => ({
        name: languageMap[language] || language,
        value: language,
      })).sort((a, b) => {
        if (a.value === 'EN') return -1;
        if (b.value === 'EN') return 1;
        return a.name.localeCompare(b.name);
      })
      : []
  ), [jobsLanguages]);

  const industriesOptions = useMemo<FilterOption[]>(() => sortedIndustries.map((industry) => ({ name: industry, value: industry })), []);
  const sizesOptions = useMemo<FilterOption[]>(() => mapAndSortCompanySizes(sizes), []);
  const locationOptions = useMemo(() => filters.locations?.reduce<string[]>((acc, location) => {
    if (typeof location.name === 'string') {
      acc.push(location.name);
    }
    return acc;
  }, []) || [], [filters.locations]);

  const staticOptions = useMemo(() => ({
    locations: jobsLocationsOptions,
    contract: contractStaticOptions,
    postedDate: postedDateStaticOptions,
    workType: workTypeStaticOptions,
    industries: industriesOptions,
    sizes: sizesOptions,
    languages: jobsLanguagesOptions,
  }), [jobsLocationsOptions, contractStaticOptions, postedDateStaticOptions, workTypeStaticOptions, industriesOptions, sizesOptions, jobsLanguagesOptions]);

  const updateFilterTags = useCallback(
    (newFilters: typeof filters) => Object.entries(newFilters).flatMap(([filterName, filterOptions]): JobFilterChipType[] => {
      if (typeof filterOptions === 'object' && 'name' in filterOptions) {
        return [{
          type: filterName as ChipTypes,
          name: filterOptions.name as string,
          filterName: filterName as JobFilterTagTypes,
        }];
      }
      if (filterName === 'required_languages' && Array.isArray(filterOptions)) {
        return filterOptions.map((option) => ({
          type: filterName as ChipTypes,
          name: typeof option.name === 'string' ? option.name : '',
          label: 'Requires only English',
          filterName: filterName as JobFilterTagTypes,
        }));
      }
      if (Array.isArray(filterOptions)) {
        return filterOptions.map((option) => ({
          type: filterName as ChipTypes,
          name: typeof option.name === 'string' ? option.name : '',
          filterName: filterName as JobFilterTagTypes,
        }));
      }
      if (filterOptions === true) {
        return [{
          type: filterName as ChipTypes,
          name: filterName === 'favoriteCompanies' ? 'Saved Companies' : filterName,
          filterName: filterName as JobFilterTagTypes,
        }];
      }
      return [];
    }).filter(tag => tag.name !== '') as JobFilterChipType[],
    [],
  );

  const saveMobileFilters = (newFilters: typeof filters) => {
    setFilters(newFilters);
    setSelectedFiltersTags(updateFilterTags(newFilters));
  };

  const handleFilterChange = useCallback((name: string, options: FilterOption[]) => {
    setFilters((prevFilters: JobsFilterState) => {
      const newFilters = { ...prevFilters, [name]: options };
      setSelectedFiltersTags(updateFilterTags(newFilters));
      return newFilters;
    });
  }, []);

  const handleFilterDelete = useCallback((filterName: JobFilterTagTypes, name: string) => {
    const newValue = Array.isArray(filters[filterName])
      ? (filters[filterName] as FilterOption[]).filter((option: FilterOption) => option.name !== name)
      : false;

    const newFilters = {
      ...filters,
      [filterName]: newValue,
    };
    setFilters(newFilters);
    setSelectedFiltersTags(updateFilterTags(newFilters));
  }, [filters, setFilters, updateFilterTags]);

  const handleFilterReset = () => {
    const resetFilters: JobsFilterState = {
      query: '',
      sizes: [],
      job_types: [],
      work_modes: [],
      industries: [],
      locations: [],
      date_range: [],
      languages: [],
      byUniversityPartners: [],
      byCareerOSPartners: [],
      required_languages: [],
      favoriteCompanies: false,
    };
    setFilters(resetFilters);
    setSelectedFiltersTags([]);
    window.history.replaceState(null, '', '?');
  };

  const handleLocationSelect = (name: string, value: string) => {
    let parsedValue: JobsLocationsType;
    try {
      parsedValue = JSON.parse(value);
    } catch (error) {
      return;
    }

    const locationName = [parsedValue.city, parsedValue.state, parsedValue.country]
      .filter(Boolean)
      .join(', ');

    const newLocations = [...filters.locations, { name: locationName, value: JSON.stringify(parsedValue) }];
    const newFilters = { ...filters, locations: newLocations };

    setFilters(newFilters);
    setSelectedFiltersTags(updateFilterTags(newFilters));
    trackEvent('Job location search', parsedValue);
  };

  const handleFiltersMobileButtonClick = () => {
    setIsMobileModalOpen(!isMobileModalOpen);
    trackEvent('More filters clicked');
  };

  const handleCancelButtonClick = () => {
    handleFilterReset();
    closeModal();
  };

  const openMoreFiltersModal = () => {
    openModal(
      <InfoModal
        title="Additional Filters"
        description={(
          <JobsFiltersModal
            filters={filters}
            staticOptions={staticOptions}
            handleFilterChange={handleFilterChange}
            university={university}
          />
        )}
        buttonLabel="Apply Filters"
        handleButtonClick={() => closeModal()}
        secondaryButtonLabel="Cancel"
        handleSecondaryButtonClick={() => handleCancelButtonClick()}
      />,
    );
    trackEvent('More filters clicked');
  };

  const handleLocationSearch = async (query: string) => {
    let locations: JobsLocationsType[] = [];
    try {
      locations = await getJobsLocationsByQuery(query || '');
    } catch {
      locations = [];
    }

    return locations.filter(location => {
      const locationName = [location.city, location.state, location.country]
        .filter(Boolean)
        .join(', ');
      return filters?.locations?.every(selectedLocation => selectedLocation.name !== locationName);
    })
      .map(location => ({
        name: [location.city, location.state, location.country]
          .filter(Boolean)
          .join(', '),
        value: JSON.stringify(location),
      }));
  };

  const handleFiltersInSearchParams = () => {
    const { search } = window.location;
    const searchParams = new URLSearchParams(search);
    const filtersFromSearch = convertURLParamsToFilters(searchParams, { industriesOptions, sizesOptions, jobsLanguagesOptions });

    setFilters(filtersFromSearch);
    setSelectedFiltersTags(updateFilterTags(filtersFromSearch));
  };

  const handleEnglishOnlyFilterChange = (checked: boolean) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      required_languages: checked ? [{ name: languageMap.EN, value: 'EN' }] : [],
      languages: checked ? [] : prevFilters.languages,
    }));
  };

  useEffect(() => {
    if (new URLSearchParams(window.location.search).toString()) {
      handleFiltersInSearchParams();
    }
  }, []);

  useEffect(() => {
    if (filters) {
      setSelectedFiltersTags(updateFilterTags(filters));
    }
  }, [filters]);

  return (
    <div className="job-filters">
      <div className="job-filters__container">
        <div className="job-filters__filters">
          <div className="job-filters__input">
            <AlgoliaFilterBar
              filters={filters}
              setJobs={setJobs}
              setCurrentActiveId={setCurrentActiveId}
              refetchJobs={refetchJobs}
              setIsSearchActive={setIsSearchActive}
              setTotalJobsCount={setTotalJobsCount}
            />
          </div>

          <DropdownSearchDynamic
            selectedOption={locationOptions}
            options={jobsLocationsOptions}
            handleItemSelect={(item, value) => handleLocationSelect(item, value)}
            loaderFunction={handleLocationSearch}
            placeholder="Search job location"
            name="loaction-search"
            id="location-search"
            inputIcon="bi bi-geo-alt"
            size="medium"
            canAddItems={false}
            showIcon={false}
            withMultipleSelection
            openOnClick
            listWidth="full"
            isDisabled={isDisabled}
          />

          <div className="job-filters__filters-mobile-container">
            <MultiSelect
              labelType="list"
              handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('job_types', options)}
              placeholder="Full-time"
              selected={filters.job_types}
              options={contractStaticOptions}
              width="fit-box"
              size={isMobileDevice ? 'full' : 'medium'}
              isDisabled={isDisabled}
            />

            {!isDisabled && (
              <div className="job-filters__all-filters-mobile-button">
                <IconButton mode={isMobileModalOpen ? 'primary' : 'unset'} icon="bi bi-filter-circle" size="medium" handleClick={handleFiltersMobileButtonClick} />
              </div>
            )}
          </div>

          <MultiSelect
            labelType="list"
            handleSelectedOptions={(options: FilterOption[]) => handleFilterChange('languages', options)}
            placeholder="Job Ad Language"
            selected={filters.languages}
            options={jobsLanguagesOptions}
            width="fit-box"
            isDisabled={isDisabled || filters.required_languages.length > 0}
          />
          <Checkbox
            label="Requires only English"
            checked={filters.required_languages.some(language => language.value === 'EN')}
            onChange={handleEnglishOnlyFilterChange}
            textType="highlight"
          />
          {!isDisabled && (
            <div className="job-filters__all-filters-button">
              <Button mode="primary" outlined size="medium" label="More Filters" icon="bi bi-funnel" handleClick={openMoreFiltersModal} />
            </div>
          )}
        </div>
      </div>

      {selectedFiltersTags.length > 0 && (
        <div className="job-filters__filter-labels" data-testid="job-filters-chips">
          {selectedFiltersTags.map((tag) => (
            <Chip
              key={`${tag.filterName}-${tag.name}`}
              label={tag.label || tag.name}
              type={tag.type}
              handleClose={() => handleFilterDelete(tag.filterName, tag.name)}
            />
          ))}
          <Button
            mode="reset"
            outlined
            size="medium"
            label="Reset"
            handleClick={handleFilterReset}
            icon="bi bi-arrow-repeat"
            data-testid="job-filters-reset-button"
          />
        </div>
      )}

      {isMobileModalOpen && (
        <MobileJobFilters
          filters={filters}
          jobsLocations={jobsLocations}
          staticOptions={staticOptions}
          setFilters={saveMobileFilters}
          handleClose={() => setIsMobileModalOpen(false)}
          university={university}
        />
      )}
    </div>
  );
};
