import { useState, useEffect } from 'react';
import { excludedCAs } from '@/domains/core/advisor/constants/excluded';
import { TagType } from '@/components/tag/tag';
import { Student } from '@/domains/core/advisor/types';
import { Tile } from '@/domains/core/company/types';

export const useAdvisorData = (
  studentsResponse: any[],
  universityID: string | undefined,
  cohortID: string | undefined,
  parseStudents?: (unParsedStudents: any, applications: Tile[]) => void,
  computeNetworkingData?: (studentsData: any) => void,
) => {
  const [isLoading, setIsLoading] = useState(true);
  const [students, setStudents] = useState<Student[]>([]);
  const [tiles, setTiles] = useState<Tile[]>([]);
  const [tagFilters, setTagFilters] = useState<any[]>([]);

  const mapStudentData = (student: Student) => ({
    ...student,
    name: `${student.first_name} ${student.last_name}`,
    type: 'MBA Student',
    logged_in: true,
  });

  const getFiltersFromStorage = () => {
    const filtersFromLocalStorage = localStorage.getItem('advisorFilters');
    if (filtersFromLocalStorage && typeof filtersFromLocalStorage === 'string') {
      return JSON.parse(filtersFromLocalStorage);
    }
    return null;
  };

  const filterStudentsByTags = (studentsData: Student[], filters: any[]) => {
    const filterBy = filters?.map((filter: any) => filter.name) || [];
    if (filterBy.length === 0) {
      return studentsData;
    }
    return studentsData.filter((student) => student?.tags?.some((tag: TagType) => filterBy.includes(tag.label)));
  };

  const filterExcludedStudents = (studentsData: Student[]) => studentsData.filter((student) => !excludedCAs.includes(student.email));

  const extractTilesData = (studentsData: any[]) => {
    const tilesMap = new Map<string, Tile>();

    studentsData.forEach((student) => {
      const tilesResponse = student.applications || [];
      tilesResponse.forEach((tile: Tile) => {
        const tileKey = `${tile.company_name}-${student.id}`;
        tilesMap.set(tileKey, tile);
      });
    });

    return Array.from(tilesMap.values()).filter((tile: Tile) => tile.company_name !== 'CareerOS');
  };

  const processData = () => {
    if (!Array.isArray(studentsResponse)) {
      return;
    }

    setIsLoading(true);

    let studentsData = studentsResponse.map(mapStudentData);

    const filters = getFiltersFromStorage();
    if (filters) {
      setTagFilters(filters);
      studentsData = filterStudentsByTags(studentsData, filters);
    }

    const studentsFiltered = filterExcludedStudents(studentsData);
    const tilesData = extractTilesData(studentsFiltered);

    setStudents(studentsFiltered);
    setTiles(tilesData);

    if (computeNetworkingData) {
      computeNetworkingData(studentsFiltered);
    }
    if (parseStudents) {
      parseStudents(studentsFiltered, tilesData);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    processData();
  }, [studentsResponse, universityID, cohortID]);

  return {
    isLoading,
    students,
    tiles,
    tagFilters,
    setTagFilters,
  };
};
