import { useEffect, useState } from 'react';
import { Button, DefaultLogo } from '@careeros/coco';
import { useQueryClient } from 'react-query';

import { useAuth0 } from '@auth0/auth0-react';
import { LogoutButton } from '@/domains/generic/auth';
import { Loader } from '@/components/loader/loader';
import './styles/profile.scss';
import { router } from '@/main';
import useConnectEmail from '@/services/hooks/use-connect-email';

import CheckmarkIcon from '@/assets/images/icons/checkmark.svg';
import gmailIcon from '@/assets/images/icons/gmail-icon.svg';
import outlookIcon from '@/assets/images/icons/outlook-icon.svg';
import { useIdentitiesNoCache, useSelf, useSelfUniversity } from '@/services/queries/user';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { getUserRole } from '@/services/helpers/user';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';

function ProfilePage() {
  const { data: identities } = useIdentitiesNoCache();
  const { data: self, isLoading: isSelfLoading } = useSelf();
  const { data: university, isLoading: isUniversityLoading } = useSelfUniversity();

  const { trackEvent } = useAnalytics();

  const {
    user, isAuthenticated, isLoading,
  } = useAuth0();

  const queryClient = useQueryClient();
  const {
    completeActionIfAvailable,
  } = useGamification();

  const successCallback = () => {
    queryClient.invalidateQueries('identities-no-cache');
    completeActionIfAvailable(GAMIFICATION_ACTIONS.EMAIL_CONNECTION);

    trackEvent('Email Inbox connected', user, {
      source: 'Profile',
    });
  };

  const [isCareerAdvisor, setIsCareerAdvisor] = useState(false);
  const { handleConnectEmail, handleChangeEmail } = useConnectEmail(successCallback);

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  const deleteData = () => {
    window.open('https://share.hsforms.com/1tOvGmlG0TX-rTPyXIEb8SA55xla', '_blank');
  };

  const goToDocumentsPage = () => {
    router.navigate('/app/documents');
  };

  const displayUniversity = university?.cohort ? `${university.name}, ${university.cohort}` : university?.name;

  useEffect(() => {
    if (user) {
      const role = getUserRole(user);
      setIsCareerAdvisor(role === 'CareerAdvisor');
    }
    if (user?.sub) {
      queryClient.invalidateQueries('identities-no-cache');
    }
  }, [user?.sub]);

  return (
    <div>
      <div className="white-wrapper">
        {isAuthenticated && user && !isSelfLoading && !isUniversityLoading && (
          <div className="profile-boxes">
            <div className="profile-boxes__box profile-boxes__box--account">
              <h2 className="profile-boxes__box__title">
                {isCareerAdvisor ? 'Account Information' : 'Student Account'}
              </h2>
              <div className="profile-boxes__box__image">
                <DefaultLogo
                  type="contact"
                  source={self?.profile_picture_url || user.picture || ''}
                  name={self?.first_name || user.name || ''}
                  size={100}
                  className=""
                />
              </div>
              <h2 className="profile-boxes__box__name">{user.name}</h2>
              <p className="profile-boxes__box__university">{displayUniversity}</p>
              <p className="profile-boxes__box__email">{user.email}</p>
              {isCareerAdvisor && (
                <div className="profile-boxes__box__delete">
                  <p>
                    Click the button to delete your CareerOS account.
                    <br />
                  </p>
                  <Button handleClick={deleteData} mode="primary" outlined size="medium" label="Delete my account" />
                </div>
              )}
            </div>
            <div className="profile-boxes__box">
              {(!identities || identities?.length === 0) ? (
                <>
                  <div className="profile-boxes__box--logout">
                    <h2 className="profile-boxes__box__title">Email Settings</h2>
                    <Button
                      label="Connect Your Inbox"
                      size="medium"
                      mode="primary"
                      handleClick={handleConnectEmail}
                    />
                  </div>
                  <div className="profile-boxes__box__content">Connect your email account to send and receive emails within CareerOS.</div>
                </>

              ) : (
                <div>
                  <h2 className="profile-boxes__box__title2">Email Settings</h2>
                  <div className="identity-box">
                    {identities?.map((identity) => (
                      <div key={identity.id} className="identity-box__item">
                        <div className="identity-box__item--label">
                          {identity.provider === 'gmail' ? <img className="identity-box__item--icon" src={gmailIcon} alt="" /> : <img className="identity-box__item--icon" src={outlookIcon} alt="" />}
                          <span>{identity.email}</span>
                        </div>
                        <Button
                          label="Change email"
                          size="small"
                          mode="primary"
                          outlined
                          handleClick={handleChangeEmail}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="profile-boxes__box profile-boxes__box--logout">
              <h2 className="profile-boxes__box__title">Calling it a day?</h2>
              <LogoutButton />
            </div>
            <div className="profile-boxes__box profile-boxes__box--logout profile-boxes__box--documents ">
              <h2 className="profile-boxes__box__title">My Documents</h2>
              <Button
                label="View documents"
                size="medium"
                mode="primary"
                handleClick={goToDocumentsPage}
              />
            </div>
            {!isCareerAdvisor && (
              <div className="profile-boxes__box privacy-box">
                <div className="privacy-box__top">
                  <h2 className="profile-boxes__box__title">Privacy Preferences</h2>
                  <Button handleClick={deleteData} mode="dangerous" outlined size="medium" label="Delete my account" />
                </div>
                <div className="privacy-box__checkbox">
                  <img className="privacy-box__image" src={CheckmarkIcon} alt="" />
                  <b>
                    By accepting our
                    {' '}
                    <a href="https://drive.google.com/file/d/1-MfxvE-4E9Ipof7gDO5pIdZ-UR9DRwDl/view" target="_blank" rel="noreferrer" aria-label="Privacy Policy (opens in new window)">Privacy Policy</a>
                    ,
                    you have agreed to share your information and progress with your Career Advisors.
                  </b>
                </div>
                <p>
                  CareerOS is designed as a collaboration tool for Students and Career Advisors.
                  This means that sharing information about your job search,
                  such as your saved companies and saved contacts, but never personal messages or notes,
                  is covered by our general terms and conditions and privacy policy.
                  If you don’t wish to share information with your Career Advisors,
                  you can opt to delete your account and stop using CareerOS.
                  <br />
                  <br />
                  Please note that deleting your account will delete all data related to your account,
                  like saved companies, contacts, and jobs, and other data related to your career journey.
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomWithAuthenticationRequired(ProfilePage, {
  onRedirecting: () => (
    <div id="loader-zone">
      <Loader />
    </div>
  ),
});
