export const universityLookerLinks: { [key: string]: string } = {
  ESADE: 'https://lookerstudio.google.com/embed/reporting/cc90d82b-025c-4c15-bc43-28181d5870cb/page/p_puu0ssrhnd',
  ESMT: 'https://lookerstudio.google.com/embed/reporting/1981357d-eb09-44e9-9421-05a106280c5b/page/p_puu0ssrhnd',
  'Schiller International University': 'https://lookerstudio.google.com/embed/reporting/a72165d1-db1e-4df6-ae96-58c967ffbf29/page/p_puu0ssrhnd',
  'Texas A&M': '',
  'Munich Business School': 'https://lookerstudio.google.com/embed/reporting/63c7dc2a-6e10-4e03-a391-ae97356e11d2/page/p_puu0ssrhnd',
  HEC: 'https://lookerstudio.google.com/embed/reporting/07fcb101-88c3-4af4-a78f-a5b53bacd566/page/p_puu0ssrhnd',
  'WHU – Otto Beisheim School of Management': 'https://lookerstudio.google.com/embed/reporting/d162c7bc-0ffb-402f-acb4-b5466271c27f/page/p_puu0ssrhnd',
  'University of Maryland': 'https://lookerstudio.google.com/embed/reporting/5ace231a-32a2-4d09-a3c2-5f955fb32060/page/p_puu0ssrhnd',
  Tomorrow: '',
  'Católica Lisbon School of Business': 'https://lookerstudio.google.com/embed/reporting/8baee316-01d9-40f9-85ed-781a23f6fe16/page/p_puu0ssrhnd',
  'Darla Moore School of Business, University of South Carolina': '',
  'Georgetown University': 'https://lookerstudio.google.com/embed/reporting/a5bddb9a-9cc2-4e69-8c4f-5327c659034e/page/p_puu0ssrhnd',
  'Stanford Graduate School of Business': '',
  'Rice University': 'https://lookerstudio.google.com/embed/reporting/f6bbb4b9-b576-429a-8999-71294f0be758/page/p_puu0ssrhnd',
  'SDA Bocconi School of Management': 'https://lookerstudio.google.com/embed/reporting/1fad73fa-0834-4aa9-bfe8-ffc3cf61518c/page/p_puu0ssrhnd',
  'London Business School': '',
  'University College Dublin': '',
  'University of Oxford': '',
  'Copenhagen Business School': '',
  'The University of Edinburgh': 'https://lookerstudio.google.com/embed/reporting/d8342173-f429-479d-a5cf-07107f8656f4/page/p_puu0ssrhnd',
  'University of Georgia': 'https://lookerstudio.google.com/embed/reporting/41834010-25b0-4015-9cba-b4c177d65a0d/page/p_puu0ssrhnd',
  'Rotterdam School of Management': '',
  'The University of Leeds': '',
  'EBS Business School': 'https://lookerstudio.google.com/embed/reporting/56377a41-53c1-438e-9935-8e2b5d674bb7/page/p_puu0ssrhnd',
  'Antwerp Management School': 'https://lookerstudio.google.com/embed/reporting/974da20d-3fa5-4f6c-9e21-0f42154162be/page/p_puu0ssrhnd',
  'HHL Leipzig Graduate School of Management': 'https://lookerstudio.google.com/embed/reporting/081d30e9-4c51-4011-ad39-f8e0df53a0c5/page/p_puu0ssrhnd',
  'Bayes Business School': '',
  'Zeppelin University': '',
  ISEG: '',
  ESSEC: '',
  'Asia School of Business': '',
  'University of CareerOS': 'https://lookerstudio.google.com/embed/reporting/ff042b2b-7309-41e1-b5a8-01d64baa932e/page/p_puu0ssrhnd',
  'CareerOS Academy': 'https://lookerstudio.google.com/embed/reporting/ff042b2b-7309-41e1-b5a8-01d64baa932e/page/p_puu0ssrhnd',
};
