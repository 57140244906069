/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  useState, useEffect, useRef, useMemo,
} from 'react';
import {
  InstantSearch, SearchBox, Configure,
} from 'react-instantsearch';
import { searchClient } from '@/services/hooks/use-algolia';
import './algolia-filter.scss';
import { useAnalytics } from '@/services/hooks/use-analytics';
import { JobsFilterState, JobFeedJob } from '@/domains/core/job/types/job';
import { SearchResults } from '../search-result/search-result';

type AlgoliaSearchBarProps = {
  filters?: JobsFilterState;
  setJobs: (jobs: JobFeedJob[]) => void;
  setTotalJobsCount: (count: number) => void;
  setCurrentActiveId: (id: string) => void;
  refetchJobs: () => void;
  setIsSearchActive: (isSearchActive: boolean) => void;
};

export const AlgoliaFilterBar = ({
  filters,
  setJobs,
  setCurrentActiveId,
  setTotalJobsCount,
  refetchJobs,
  setIsSearchActive,
}: AlgoliaSearchBarProps) => {
  const [query, setQuery] = useState('');
  const formRef = useRef<HTMLFormElement>(null);
  const { trackEvent } = useAnalytics();
  const searchStateRef = useRef<string | undefined>();

  const filteredLanguages = useMemo(
    () => {
      if (filters?.languages) {
        return filters.languages.map((language) => language.value);
      }
      return [];
    },
    [filters?.languages],
  );

  const filteredLocations = useMemo(
    () => {
      if (filters?.locations) {
        return filters.locations.map((location) => location.name);
      }
      return [];
    },
    [filters?.locations],
  );

  const filteredJobTypes = useMemo(
    () => {
      if (filters?.job_types) {
        return filters.job_types.map((jobType) => jobType.value);
      }
      return [];
    },
    [filters?.job_types],
  );

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  // Handle changes to query - clear jobs and force refetch when query is cleared
  useEffect(() => {
    if (query === '' && searchStateRef.current) {
      // Reset the search state reference
      searchStateRef.current = undefined;
      setIsSearchActive(false);
      // Always refetch jobs when the search query is cleared
      refetchJobs();
    } else if (query !== '') {
      // Set search state when query is not empty
      searchStateRef.current = query;
      setIsSearchActive(true);
    }
  }, [query, refetchJobs]);

  // Additional cleanup for page navigation/unmounting
  useEffect(() => () => {
    setQuery('');
    const input = document.querySelector('.algolia-filter-form__input') as HTMLInputElement;
    if (input) {
      input.value = '';
    }
    setIsSearchActive(false);
  }, []);

  return (
    <form ref={formRef} className="algolia-filter-form" method="" onSubmit={handleSubmit}>
      <button className="algolia-filter-form__button" aria-label="Search for companies on CareerOS" type="submit">
        <i className="bi bi-search" />
      </button>
      <InstantSearch
        searchClient={searchClient}
        indexName="job"
        future={{ preserveSharedStateOnUnmount: true }}
      >
        <label className="algolia-filter-form__label">
          <SearchBox
            placeholder="Search Jobs"
            classNames={{
              root: '',
              form: '',
              input: 'algolia-filter-form__input',
              submit: 'hidden',
              reset: 'hidden',
              loadingIndicator: 'hidden',
            }}
            onInput={(e: React.FormEvent<HTMLInputElement>) => setQuery((e.target as HTMLInputElement).value)}
            onKeyDown={(e: React.KeyboardEvent) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            searchAsYouType
          />
        </label>
        {query && (
          <div>
            <Configure
              hitsPerPage={1000}
              clickAnalytics
              facetFilters={[
                // Language filters (OR between values)
                ...(filteredLanguages.length > 0
                  ? [filteredLanguages.map(lang => `job_language:${lang}`)]
                  : []),

                // Job type filters (OR between values)
                ...(filteredJobTypes.length > 0
                  ? [filteredJobTypes.map(type => `job_type:${type}`)]
                  : []),

                // Location filters (OR between values)
                ...(filteredLocations.length > 0
                  ? [filteredLocations.map(loc => `location:${loc}`)]
                  : []),
              ]}
              filters="is_active:ACTIVE"
            />
            <SearchResults setCurrentActiveId={setCurrentActiveId} setTotalJobsCount={setTotalJobsCount} setJobs={setJobs} query={query} trackEvent={trackEvent} />
          </div>
        )}
      </InstantSearch>
    </form>
  );
};
