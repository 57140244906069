import {
  DragDropContext, Droppable, Draggable, OnDragEndResponder, DropResult,
} from 'react-beautiful-dnd';
import './contacts-kanban.scss';
import {
  ContactApiStatus, ContactsKanbanBoardLayout, ContactStatus, ContactWithNetworkingData, SuggestedContactTypeWithNetworkingData,
} from '../../types';
import { ContactKanbanTileWithPreview } from '@/domains/core/contact/components/contact-kanban-tile-with-preview/contact-kanban-tile-with-preview';
import { SuggestedContactKanbanTileWithPreview } from '../suggested-contact-kanban-tile-with-preview/suggested-contact-kanban-tile-with-preview';
import { KanbanColumn } from '@/domains/generic/kanban/kanban-column/kanban-column';
import { ContactKanbanCardInfo, contactKanbanColumnProps } from '@/domains/core/netwoking/constants/contacts-kanban';
import { EmptySuggestedContactsColumn } from '../empty-suggested-contacts-column/empty-suggested-contacts-column';
import { TileSkeleton } from '@/domains/generic/kanban/tile-skeleton/tile-skeleton';

type ContactsKanbanProps = {
  columns: ContactStatus[];
  layout: ContactsKanbanBoardLayout;
  onChange: (cardInfo: ContactKanbanCardInfo, newStatus: ContactStatus) => Promise<void>;
  handleContactArchive?: (contactId: string, currentStatus: ContactApiStatus, shouldArchive: boolean) => void;
  scrollContainerRef?: React.RefObject<HTMLDivElement>;
  onScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  schoolName: string;
  withSuggestedColumn?: boolean;
  suggestedContacts: SuggestedContactTypeWithNetworkingData[];
  isLoadingSuggestedContacts?: boolean;
  isLoadingContacts?: boolean;
  isPromptToSaveCompanies?: boolean;
};

export const ContactsKanbanBoard = ({
  columns,
  layout,
  onChange,
  handleContactArchive,
  scrollContainerRef,
  onScroll,
  schoolName,
  suggestedContacts,
  isLoadingSuggestedContacts = false,
  isLoadingContacts = false,
  isPromptToSaveCompanies = false,
  withSuggestedColumn = false,
}: ContactsKanbanProps) => {
  const handleSaveSuggestedContact = async (contactId: string) => {
    const payload: ContactKanbanCardInfo = {
      id: contactId,
      status: 'Suggested',
      dropPosition: 0,
    };

    onChange(payload, 'Contact Saved');
  };

  const onDragEnd: OnDragEndResponder = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const { source, destination, draggableId } = result;

    const payload: ContactKanbanCardInfo = {
      id: draggableId,
      status: source.droppableId as ContactStatus,
      dropPosition: destination.index,
    };

    onChange(payload, destination.droppableId as ContactStatus);
  };

  return (
    <div className="kanban-board-contacts">
      <div className="kanban-board-contacts__content" ref={scrollContainerRef} onScroll={onScroll}>
        <DragDropContext onDragEnd={onDragEnd}>
          <>
            {withSuggestedColumn && (
              <KanbanColumn
                title={contactKanbanColumnProps.Suggested.label}
                icon={contactKanbanColumnProps.Suggested.icon}
                isSpecial={contactKanbanColumnProps.Suggested.isSpecial}
                withExtraInfo
                extraInfoLabel={contactKanbanColumnProps.Suggested.extraInfoLabel}
              >
                <Droppable droppableId="Suggested" isDropDisabled>
                  {(providedDrop) => (
                    <div
                      className="kanban-board-contacts__drop-zone"
                      ref={providedDrop.innerRef}
                      {...providedDrop.droppableProps}
                    >
                      {!isPromptToSaveCompanies && suggestedContacts && suggestedContacts.map((contact: SuggestedContactTypeWithNetworkingData, idx: number) => (
                        <Draggable key={contact.id} draggableId={contact.id} index={idx}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="kanban-board-contacts__tile">
                                <SuggestedContactKanbanTileWithPreview
                                  key={contact.id}
                                  contact={contact}
                                  schoolName={schoolName}
                                  handleSaveSuggestedContact={() => handleSaveSuggestedContact(contact.id)}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {isPromptToSaveCompanies && !isLoadingSuggestedContacts && (
                        <EmptySuggestedContactsColumn />
                      )}
                      {isLoadingSuggestedContacts && suggestedContacts.length === 0 && new Array(3).fill(null).map(() => (
                        <div className="kanban-board-contacts__tile">
                          <TileSkeleton />
                        </div>
                      ))}
                      {providedDrop.placeholder}
                    </div>
                  )}
                </Droppable>
              </KanbanColumn>
            )}
            {columns.map((column) => (
              <KanbanColumn
                title={contactKanbanColumnProps[column].label}
                icon={contactKanbanColumnProps[column].icon}
                isSpecial={contactKanbanColumnProps[column].isSpecial}
                withCounter={contactKanbanColumnProps[column].withCounter}
                counter={layout[column]?.length || 0}
                isLive={contactKanbanColumnProps[column].isLive}
                key={column}
                withStatusIndicator={contactKanbanColumnProps[column].withStatusIndicator}
                withExtraInfo={contactKanbanColumnProps[column].withExtraInfo}
                extraInfoLabel={contactKanbanColumnProps[column].extraInfoLabel}
              >
                <Droppable key={column} droppableId={column} isDropDisabled={isLoadingContacts}>
                  {(providedDrop) => (
                    <div
                      className="kanban-board-contacts__drop-zone"
                      ref={providedDrop.innerRef}
                      {...providedDrop.droppableProps}
                    >
                      {layout[column] && layout[column].map((contact: ContactWithNetworkingData, idx: number) => (
                        <Draggable key={contact.id} draggableId={contact.id} index={idx}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="kanban-board-contacts__tile">
                                <ContactKanbanTileWithPreview
                                  key={contact.id}
                                  contact={contact}
                                  handleContactArchive={handleContactArchive}
                                  schoolName={schoolName}
                                />
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {(isLoadingContacts && layout[column].length === 0) && new Array(3).fill(null).map(() => (
                        <div className="kanban-board-contacts__tile">
                          <TileSkeleton />
                        </div>
                      ))}
                      {providedDrop.placeholder}
                    </div>
                  )}
                </Droppable>
              </KanbanColumn>
            ))}
          </>
        </DragDropContext>
      </div>
    </div>
  );
};
