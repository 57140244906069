import React from 'react';
import { Tooltip, DefaultLogo } from '@careeros/coco';
import './OB-tile-header.scss';
import { CompanyRating } from '@/domains/core/company';
import { OBTileNameWithPreview } from '../OB-tile-name-with-preview/OB-tile-name-with-preview';

type Props = {
  name: string;
  subtitle?: string;
  logoUrl: string;
  hypeLevel?: number;
  handleChangeHypeLevel?: (level: number) => Promise<void>;
  location?: string;
  onSubtitleClick?: () => void;
  onNameClick?: () => void;
  imageLabel?: string;
  children?: React.ReactNode;
};

export const OBTileHeader = ({
  name,
  subtitle,
  logoUrl,
  hypeLevel,
  handleChangeHypeLevel,
  location,
  onSubtitleClick,
  onNameClick,
  imageLabel,
  children,
}: Props) => (
  <div className="OB-tile-header">
    <div onClick={onNameClick} className="OB-tile-header__logo">
      <Tooltip label={imageLabel || name} position="top" withArrow disableHoverListener={!imageLabel}>
        <DefaultLogo
          source={logoUrl}
          type="company"
          name={name}
          className="OB-tile-header__logo-image"
          size={32}
        />
      </Tooltip>
    </div>
    <div className="OB-tile-header__info">
      <div className="OB-tile-header__info-row">
        {!children && (
          <Tooltip label={name} position="top" withArrow>
            <span onClick={onNameClick} className="OB-tile-header__name">
              {name}
            </span>
          </Tooltip>
        )}
        {React.Children.map(children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child);
          }
          return child;
        })}
        {hypeLevel !== undefined && (
          <div className="OB-tile-header__hype-level">
            <CompanyRating handleCompanyRate={handleChangeHypeLevel} currentRate={hypeLevel} />
          </div>
        )}
      </div>
      {(subtitle || location) && (
        <div className="OB-tile-header__info-row">
          {subtitle && (
          <div className="OB-tile-header__subtitle-wrapper">
            <Tooltip label={subtitle} position="top" withArrow>
              <span onClick={onSubtitleClick} className="OB-tile-header__subtitle">
                {subtitle}
              </span>
            </Tooltip>
          </div>
          )}
          {location && (
          <div className="OB-tile-header__location-wrapper">
            <Tooltip label={location} position="top" withArrow>
              <span onClick={onSubtitleClick} className="OB-tile-header__location">
                {location}
              </span>
            </Tooltip>
          </div>
          )}
        </div>
      )}
    </div>
  </div>
);

OBTileHeader.Name = OBTileNameWithPreview;
