import { IdentitiesResponse } from '@/domains/core/user/types';
import { http } from './http';
import { convertFromApiCAComment } from '@/domains/core/advisor/api/model/comment';
import { DashboardActionsResponse } from '@/domains/core/student/types';

export type ApiUser = {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  role: 'student' | 'careerAdvisor'
  linkedin_slug: string;
  linkedin_urn: string;
  profile_picture_url: string;
  has_completed_demographics_form: boolean;
  university_id: string;
};

export const getSelf = (): Promise<ApiUser> => http.get('/users/self');

export const updateSelfProfileImage = (image: string) => http.patch('/users/self', { profile_picture_url: image });

export const getConversations = async (): Promise<any> => http.get('/users/conversations');

export const getUserStatistic = () => http.get(`/users/self/statistics?date=${new Date().toISOString().split('T')[0]}`);

export const patchChallengesModals = (challenge: string, status: 'activated' | 'halfway' | 'completed') => http.patch('/users/self/statistics', {
  challenge_status: status,
  challenge_modal: challenge,
});

export const updateTargetGoals = (savedCompaniesTarget: number, savedContactTarget: number, sentLinkedinConnectionsTarget: number) => http.patch('/users/self/targets', {
  saved_companies_target: savedCompaniesTarget,
  saved_contacts_target: savedContactTarget,
  sent_linkedin_invitations_target: sentLinkedinConnectionsTarget,
});

export const saveEmailAuthorizationGranted = (connection: string) => http.post(`/users/self/authorization/${connection}`, {});

export const getEmailIdentities = (): Promise<IdentitiesResponse> => http.get('/users/self/identities').then(response => response.identities);

export const saveOnboardingData = (data: any) => http.post('/users/self/onboarding', data);

export const enrollToCohort = (cohort: string, university: string) => http.post('/users/enroll/cohort', { cohort, university });

export const assignCareerAdvisorRole = () => http.post('/users/assign/career-advisor', {});

export const updateFirstNameForUser = (firstName: string) => http.patch('/users/self/firstname', { first_name: firstName });

export const getSelfComments = async () => {
  const response = await http.get('/comments');

  if (!response?.comments || !Array.isArray(response?.comments)) {
    return [];
  }

  return response.comments.map(convertFromApiCAComment);
};

export const getActionTiles = async (): Promise<DashboardActionsResponse> => http.get('/dashboard');
