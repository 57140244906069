import { useMemo } from 'react';
import { AvatarStack, DefaultLogo } from '@careeros/coco';
import { formatNumberWithCommas } from '@/services/helpers/gamification';
import { LeaderboardType } from '@/types/gamification';

type GamificationLeaderboardItemProps = {
  position: number;
  name: string;
  logo: string;
  score_text: string;
  is_logo_blurred: boolean;
  is_user: boolean;
  type: LeaderboardType;
  peer_logo?: string;
  second_peer_logo?: string;
  third_peer_logo?: string;
  format?: 'group' | 'individual';
};

export const GamificationLeaderboardItem = ({
  position,
  name,
  logo,
  score_text,
  is_logo_blurred,
  is_user,
  type,
  peer_logo,
  second_peer_logo,
  third_peer_logo,
  format,
}: GamificationLeaderboardItemProps) => {
  // Memoize the formatted score text to prevent unnecessary recalculations
  const formattedScoreText = useMemo(
    () => formatNumberWithCommas(score_text || '', type),
    [score_text, type],
  );

  // Prepare the list for AvatarStack conditionally
  const personList = [
    {
      id: logo,
      name,
      avatar: logo,
    },
  ];

  if (peer_logo) {
    personList.push({
      id: peer_logo,
      name: 'Fellow Rockstar',
      avatar: peer_logo,
    });
  }
  if (second_peer_logo) {
    personList.push({
      id: second_peer_logo,
      name: 'Fellow Rockstar',
      avatar: second_peer_logo,
    });
  }
  if (third_peer_logo) {
    personList.push({
      id: third_peer_logo,
      name: 'Fellow Rockstar',
      avatar: third_peer_logo,
    });
  }

  return (
    <li
      className={`leaderboard__student ${is_user ? 'leaderboard__student--highlighted' : ''}`}
    >
      <div className="leaderboard__student-rating">{position}</div>
      {format === 'group' && is_user ? (
        <AvatarStack personList={personList} />
      ) : (
        <DefaultLogo type="contact" source={logo} className={`${is_logo_blurred && !is_user ? 'leaderboard__university-logo leaderboard__university-logo--blurred' : 'leaderboard__university-logo'}`} name="CareerOS Rockstar" />
      )}
      <span className="leaderboard__student-name">
        {name || 'Fellow Rockstar'}
      </span>
      <div className="leaderboard__student-quests">
        <p>
          {formattedScoreText}
        </p>
      </div>
    </li>
  );
};
