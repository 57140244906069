import { useAuth0 } from '@auth0/auth0-react';
import {
  useContext,
  useDeferredValue, useEffect, useMemo, useState,
} from 'react';
import { useQueryClient } from 'react-query';
import { Banner } from '@careeros/coco';
import { useNavigate } from 'react-router-dom';
import { TableRow } from '@/components/table/table-types';
import Star from '@/assets/images/star.png';
import './styles/contacts.scss';
import { useSelf, useSelfUniversity } from '@/services/queries/user';
import {
  Contact, ContactApiStatus, contactAPIstatuses, contactKanbanStatuses, ContactsKanbanBoardLayout, ContactStatus, contactStatuses, ContactWithNetworkingData,
  SuggestedContactType,
  SuggestedContactTypeWithNetworkingData,
} from '@/domains/core/contact/types';
import { useNetworkingStatuses } from '@/services/hooks/use-networking-statuses';
import { TrackerContext, TrackerContextType } from '@/domains/core/company';
import { saveContact, updateContactPositionInKanban } from '@/services/api/contact';
import { useAnalytics } from '@/services/hooks/use-analytics';
import CustomWithAuthenticationRequired from './auth/custom-protected-route';
import { useGetContactsNoCache, useSuggestedContacts } from '@/services/queries/contact';
import { Loader } from '@/components/loader/loader';
import {
  ContactKanbanCardInfo,
} from '@/domains/core/netwoking/constants/contacts-kanban';
import {
  ContactsTableColumns,
  mapContacts,
} from '@/domains/core/netwoking/constants/contact-table';
import { useContactsKanban } from '@/services/hooks/use-contacts-kanban';
import { ToastContext } from '@/components/toast/toast-provider';
import { AddToastType } from '@/domains/generic/toasts/types';
import { ContactsOverviewContent } from '@/domains/core/contact/components/contacts-kanban-container/contacts-kanban-container';
import { useApplicationsNoCache } from '@/services/queries/application';
import { GAMIFICATION_ACTIONS, useGamification } from '@/services/hooks/use-gamification';
import { useSelfCampusChampions } from '@/services/queries/campus-champion';

const ContactsPage = () => {
  const { openTracker } = useContext(TrackerContext) as TrackerContextType;
  const { trackEvent } = useAnalytics();
  const {
    computeKanbanLayout,
    requestArchiveConfirmation,
    handleContactNewStatus,
    kanbanLayout,
    changeKanbanLayout,
  } = useContactsKanban();
  const { data: selfUniversity } = useSelfUniversity();
  const navigate = useNavigate();
  const { completeActionIfAvailable } = useGamification();
  const { data: applications } = useApplicationsNoCache();
  const { data: campusChampions, isLoading: areCampusChampionsLoading } = useSelfCampusChampions();
  const { data: contacts, isLoading: areContactsLoading } = useGetContactsNoCache();
  const { data: suggestedContacts, isLoading: areSuggestedContactsLoading } = useSuggestedContacts();
  const [contactsWithStatuses, setContactsWithStatuses] = useState<ContactWithNetworkingData[]>([]);
  const [preparedContacts, setPreparedContacts] = useState<ContactWithNetworkingData[]>([]);
  const [suggestedContactsWithStatuses, setSuggestedContactsWithStatuses] = useState<SuggestedContactTypeWithNetworkingData[]>([]);
  const [filteredSuggestedContacts, setFilteredSuggestedContacts] = useState<SuggestedContactTypeWithNetworkingData[]>([]);
  const [tableData, setTableData] = useState<TableRow[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isPreparingSuggestedContacts, setIsPreparingSuggestedContacts] = useState(true);
  const [isPreparingContacts, setIsPreparingContacts] = useState(true);
  const { addToast }: AddToastType = useContext(ToastContext);
  const { user } = useAuth0();
  const queryClient = useQueryClient();
  const { data: self } = useSelf();
  const deferredQuery = useDeferredValue(searchQuery);
  const { mapAllConnectionStatuses, isLoading: isFetchingNetworkingStatuses, mapSuggestedContactsWithConnectionStatus } = useNetworkingStatuses();
  const [showAlumniOnly, setShowAlumniOnly] = useState(false);
  const isPromptToSaveCompanies = useMemo(() => applications && applications?.length < 5, [applications]);
  const withSuggestedColumn = useMemo(
    () => isPromptToSaveCompanies
    || (!areSuggestedContactsLoading ? !!suggestedContacts?.length : true),
    [suggestedContacts, areSuggestedContactsLoading, isPromptToSaveCompanies],
  );

  const handleShowAlumniOnlyChange = (value: boolean) => {
    setShowAlumniOnly(value);
  };

  const getSavedContactsWithConnectionStatus = async (savedContactsPayload: Contact[]) => {
    const contactLinkedInURLs = contactsWithStatuses.map((contact) => contact.linkedin_url);
    const pendingLinkedInURLs = savedContactsPayload?.map((contact) => contact.linkedin_url) || [];
    const newContactUrls = pendingLinkedInURLs.filter((contactUrl) => !contactLinkedInURLs.includes(contactUrl));

    if (newContactUrls.length === 0) {
      return contactsWithStatuses.filter((contact) => pendingLinkedInURLs.includes(contact.linkedin_url));
    }

    if (savedContactsPayload && self) {
      const result: ContactWithNetworkingData[] = await mapAllConnectionStatuses(savedContactsPayload, self.id);

      setContactsWithStatuses(result);

      return result;
    }

    return savedContactsPayload;
  };

  const revalidateSuggestedContacts = () => {
    queryClient.invalidateQueries('contacts-no-cache');
    queryClient.invalidateQueries('gamification-actions');
    queryClient.invalidateQueries('challenges');
    queryClient.invalidateQueries('xp');
    queryClient.invalidateQueries('leaderboards');
    queryClient.invalidateQueries('company-saved-contacts');
    queryClient.invalidateQueries(['suggested-contacts']);
    queryClient.invalidateQueries('contacts');
    queryClient.invalidateQueries('quests');
  };

  const saveSuggestedContactAndAssignPosition = async (contact: SuggestedContactType, newStatus: ContactStatus, position: number) => {
    try {
      await saveContact(contact, contact.company_id);
      trackEvent('Saved Contact', user, {
        companyID: contact.company_id,
        source: 'Suggested Contacts from our DB',
        type: 'Save',
      });

      if (newStatus !== 'Contact Saved' || position !== 0) {
        await updateContactPositionInKanban(contact.contact_id, contactKanbanStatuses[newStatus], position);
      }

      // if (self?.id) {
      // insights.purchasedObjectIDs(
      //   'Contact Saved',
      //   'contact',
      //   [suggestedContact.contact_id],
      //   self.id,
      // );
      // }
      revalidateSuggestedContacts();
    } catch (error) {
      addToast({
        type: 'error',
        message: 'Failed to save contact',
        additionalMessage: 'Please try again later',
      });
    }
  };

  const handleSaveSuggestedContact = async (id: string, newStatus: ContactStatus, position: number) => {
    if (newStatus === 'Archived') {
      addToast({
        type: 'warning',
        message: 'It is not possible to archive suggested contacts',
      });

      return;
    }

    const contact = suggestedContacts?.find((c) => c.id === id);
    if (!contact) {
      return;
    }

    const newKanban = { ...kanbanLayout };

    const contactCard = {
      ...contact.contact,
      status: newStatus,
      kanban_position: { status: contactKanbanStatuses[newStatus], row: position },
      match_criteria: contact.match_criteria,
      matched_languages: contact.matched_languages,
    };

    newKanban[newStatus]?.splice(position, 0, contactCard);

    changeKanbanLayout(newKanban as ContactsKanbanBoardLayout);
    setFilteredSuggestedContacts(filteredSuggestedContacts.filter((c) => c.id !== id));
    await saveSuggestedContactAndAssignPosition(contact, newStatus, position);
  };

  const processSuggestedContacts = async (suggested: SuggestedContactTypeWithNetworkingData[]) => {
    const preparedResponse = await mapSuggestedContactsWithConnectionStatus(suggested || []);

    setSuggestedContactsWithStatuses(preparedResponse);
    setIsPreparingSuggestedContacts(false);
  };

  const handleCardStatusChange = async (cardInfo: ContactKanbanCardInfo, newStatus: ContactStatus, archiveActionConfirmed: boolean = false) => {
    const { id, status, dropPosition } = cardInfo;

    if (status === 'Suggested') {
      handleSaveSuggestedContact(id, newStatus, dropPosition);
      return;
    }

    if (status !== newStatus && newStatus === 'Archived' && !archiveActionConfirmed) {
      requestArchiveConfirmation(id, status, dropPosition);
      return;
    }

    if (newStatus === 'Nurturing') {
      completeActionIfAvailable(GAMIFICATION_ACTIONS.NURTURING_CONTACT);
    }

    handleContactNewStatus({ id, status, dropPosition }, newStatus);
  };

  const filterContacts = (queryString: string, onlyAlumni: boolean, contactsToFilter: ContactWithNetworkingData[] | undefined) => {
    if (!contactsToFilter) {
      return;
    }

    const filteredResults = Array.isArray(contactsToFilter) ? contactsToFilter?.filter((contact) => {
      const fullName = `${contact.first_name} ${contact.last_name}`;

      if (onlyAlumni && !contact.match_criteria?.includes('alumni')) {
        return false;
      }

      return fullName.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.company.toLowerCase().includes(queryString.toLowerCase())
        || contact.current_position.title.toLowerCase().includes(queryString.toLowerCase());
    }) : [];

    const filteredNonArchived = filteredResults?.filter((contact) => contact.kanban_position.status !== 'archive');
    setTableData(mapContacts(filteredNonArchived || [], openTracker, requestArchiveConfirmation, isFetchingNetworkingStatuses));
    computeKanbanLayout(filteredResults || []);
    setIsPreparingContacts(false);
  };

  const filterSuggestedContacts = (queryString: string, onlyAlumni: boolean, contactsToFilter: SuggestedContactTypeWithNetworkingData[] | undefined) => {
    const filteredResults = Array.isArray(contactsToFilter) ? contactsToFilter?.filter((contact) => {
      const fullName = `${contact.contact.first_name} ${contact.contact.last_name}`;

      if (onlyAlumni && !contact.match_criteria?.includes('alumni')) {
        return false;
      }

      return fullName.toLowerCase().includes(queryString.toLowerCase())
        || contact.contact.current_position.company.toLowerCase().includes(queryString.toLowerCase())
        || contact.contact.current_position.title.toLowerCase().includes(queryString.toLowerCase());
    }) : [];

    setFilteredSuggestedContacts(filteredResults);
  };

  const processContactAction = (id: string, currentStatus: ContactApiStatus, isArchiveAction: boolean) => {
    if (isArchiveAction) {
      requestArchiveConfirmation(id, contactAPIstatuses[currentStatus]);
    } else {
      handleCardStatusChange({ id, status: contactAPIstatuses[currentStatus], dropPosition: 0 }, 'Contact Saved');
    }
  };

  const processContactsWithStatuses = async (allContacts: Contact[]) => {
    if (!self?.id) {
      return;
    }

    const contactsWithStatus = await getSavedContactsWithConnectionStatus(allContacts);
    setPreparedContacts(contactsWithStatus);
    setIsPreparingContacts(false);
  };

  const goToCampusChampions = () => {
    navigate('/app/contacts/campus-champions');
  };

  useEffect(() => {
    const contactsToFilter = preparedContacts.length ? preparedContacts : contacts;
    const suggestedContactsToFilter = suggestedContactsWithStatuses.length ? suggestedContactsWithStatuses : suggestedContacts;

    if (deferredQuery) {
      filterContacts(deferredQuery.trim(), showAlumniOnly, contactsToFilter);
      filterSuggestedContacts(deferredQuery.trim(), showAlumniOnly, suggestedContactsToFilter);
    } else {
      filterContacts('', showAlumniOnly, contactsToFilter);
      filterSuggestedContacts('', showAlumniOnly, suggestedContactsToFilter);
    }
  }, [deferredQuery, showAlumniOnly]);

  useEffect(() => {
    if (preparedContacts.length) {
      filterContacts(searchQuery, showAlumniOnly, preparedContacts);
    }
  }, [preparedContacts]);

  useEffect(() => {
    if (suggestedContactsWithStatuses.length) {
      filterSuggestedContacts(searchQuery, showAlumniOnly, suggestedContactsWithStatuses);
    }
  }, [suggestedContactsWithStatuses]);

  useEffect(() => {
    if (!self?.id || areContactsLoading || !contacts) {
      return;
    }

    // filter contacts only if the kanban layout is not set (initial load). Otherwise, the contacts will be filtered when the kanban layout is set.
    if (kanbanLayout === null) {
      filterContacts(searchQuery, showAlumniOnly, contacts);
    }

    processContactsWithStatuses(contacts);
  }, [contacts, self?.id, areContactsLoading]);

  useEffect(() => {
    if (!areSuggestedContactsLoading && suggestedContacts) {
      filterSuggestedContacts(searchQuery, showAlumniOnly, suggestedContacts);
      processSuggestedContacts(suggestedContacts || []);
    }
  }, [suggestedContacts, areSuggestedContactsLoading]);

  if (areContactsLoading || !kanbanLayout || areCampusChampionsLoading) {
    return (
      <div id="loader">
        <Loader />
      </div>
    );
  }

  return (
    <div className="contacts-page" id="main">
      {Array.isArray(campusChampions) && campusChampions?.length > 0 && (
        <div className="contacts-page__banner">
          <Banner
            title={`Discover our ${selfUniversity?.name} Campus Champions`}
            image={Star}
            text={`${campusChampions?.length} ${campusChampions.length === 1 ? 'contact is' : 'contacts are'} ready to chat to students from ${selfUniversity?.name}.`}
            isButtonOutlined
            buttonPlacement="horizontal"
            handleButtonClick={goToCampusChampions}
            buttonLabel="View Campus Champions"
            buttonIcon="bi bi-box-arrow-up-right"
            buttonIconPosition="right"
          />
        </div>
      )}
      <ContactsOverviewContent
        columns={contactStatuses}
        layout={kanbanLayout}
        tableColumns={ContactsTableColumns}
        handleSearchQueryChange={setSearchQuery}
        searchQuery={searchQuery}
        tableData={tableData}
        handleContactArchive={processContactAction}
        cardChangeHandler={handleCardStatusChange}
        schoolName={selfUniversity?.name || 'your school'}
        suggestedContacts={filteredSuggestedContacts || []}
        handleShowAlumniOnlyChange={handleShowAlumniOnlyChange}
        showAlumniOnly={showAlumniOnly}
        isLoadingSuggestedContacts={isPreparingSuggestedContacts}
        isLoadingContacts={isPreparingContacts}
        withSuggestedColumn={withSuggestedColumn}
        isPromptToSaveCompanies={isPromptToSaveCompanies}
      />
    </div>
  );
};

export default CustomWithAuthenticationRequired(ContactsPage);
