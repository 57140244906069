import { useState } from 'react';
import './public-layout.scss';
import { StudentNavbarView } from '../../../pages/layout/navbar/components/navbar-view/navbar-view';
import { NavbarItemName } from '../../../pages/layout/navbar/types/types';
import { TopbarDumb } from '../../../pages/layout/topbar/topbar-dumb';

type Props = {
  children: React.ReactNode;
  handleLoginClick: () => void;
};

export const PublicLayout = ({ children, handleLoginClick }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  return (
    <div className="public-layout">
      <StudentNavbarView
        collapsed={isCollapsed}
        isCookieBannerVisible={false}
        showContent
        logoLink="/with-university"
        links={[
          {
            label: NavbarItemName.Home,
            url: '/',
            icon: 'bi bi-house-door',
            iconSelected: 'bi bi-house-door-fill',
            order: 1,
            isActive: false,
          },
          {
            label: NavbarItemName.OverviewBoard,
            url: '/with-university',
            icon: 'bi bi-grid-1x2',
            iconSelected: 'bi bi-grid-1x2-fill',
            order: 2,
            isActive: true,
          },
          {
            label: NavbarItemName.Inbox,
            url: '/with-university',
            icon: 'bi bi-chat',
            iconSelected: 'bi bi-chat-fill',
            order: 3,
            isActive: true,
          },
          {
            label: NavbarItemName.Contacts,
            url: '/with-university',
            icon: 'bi bi-person-vcard',
            iconSelected: 'bi bi-person-vcard-fill',
            order: 4,
            isActive: true,
          },
          {
            label: NavbarItemName.Jobs,
            url: '/with-university',
            icon: 'bi bi-briefcase',
            iconSelected: 'bi bi-briefcase-fill',
            order: 5,
            isActive: true,
          },
          {
            label: NavbarItemName.Companies,
            url: '/with-university',
            icon: 'bi bi-building',
            iconSelected: 'bi bi-building-fill',
            order: 6,
            isActive: true,
          },
          {
            label: NavbarItemName.ResumeOS,
            url: '/with-university',
            icon: 'bi bi-file-earmark',
            iconSelected: 'bi bi-file-earmark-fill',
            order: 6,
            isActive: true,
          },
        ]}
        handleToggle={() => setIsCollapsed(!isCollapsed)}
        handleLinkClick={(ev: any) => {
          ev.preventDefault();
        }}
      />
      <div className={`public-layout__content ${isCollapsed ? 'public-layout__content--collapsed' : ''}`}>
        <div className="public-layout__topbar">
          <TopbarDumb
            title=""
            withSearch={false}
            withProfile={false}
            isLoggedIn={false}
            handleLoginClick={handleLoginClick}
          />
        </div>
        <div className="public-layout__main">
          {children}
        </div>
      </div>
    </div>
  );
};
