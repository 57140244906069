/* eslint-disable @typescript-eslint/no-use-before-define */
import { useCallback, useContext, useState } from 'react';
import { InfoModal } from '@careeros/coco';
import { ModalContext } from '@/components/modal/modal-provider';
import { getConfig } from '@/config';
import { createWebSocketService, WebSocketService } from '@/services/websocket/websocket';
import { ChooseProviderModal } from '@/domains/generic/modals/choose-provider/choose-provider-modal';
import { LoadingModal } from '@/domains/generic/modals/loading/loading-modal';

const config = getConfig();
const websocketUrl = `${config.websocketUrl}/ws/identities`;

const useConnectEmail = (onSuccess: any) => {
  const { openModal, closeModal } = useContext(ModalContext) as any;
  const [wsService, setWsService] = useState<WebSocketService | null>(null);

  const delay = (ms: number) => new Promise(resolve => { setTimeout(resolve, ms); });

  const showLoadingModal = () => {
    openModal(
      <LoadingModal
        orientation="horizontal"
        image="bi bi-envelope"
        title="Hang tight! Email connection in progress..."
      />,
    );
  };

  const showSuccessModal = (email: string) => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title={`${email} is now connected to CareerOS!`}
        buttonLabel="Continue"
        handleButtonClick={closeModal}
        icon="bi bi-check-circle-fill"
        description={(
          <>You can now send emails to all your contacts with ease and receive timely outreach and follow-up Quests on your Home Page. Let’s start networking on EASY MODE 💪🏼</>
        )}
      />,
    );
  };

  const showFailedModal = (email: string) => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title={`Failed to connect ${email || 'email'} to CareerOS`}
        buttonLabel="Continue"
        icon="bi bi-exclamation-diamond"
        handleButtonClick={closeModal}
        description={(
          <>We couldn&apos;t establish a connection for email. Please check your settings or network connection, and try again.</>
        )}
      />,
    );
  };

  const showRetryModal = () => {
    const handleRetryClick = () => {
      closeModal();
      handleConnectEmail();
    };

    openModal(
      <InfoModal
        orientation="horizontal"
        title="Access to your inbox is required"
        buttonLabel="Retry"
        handleButtonClick={handleRetryClick}
        icon="bi bi-exclamation-triangle"
        isImageDanger
        description={(
          <>
            We couldn&apos;t access your inbox because the necessary permissions were not granted.
            Please ensure you
            {' '}
            <strong>select all checkboxes</strong>
            {' '}
            during the connection process and try again.
          </>
        )}
      />,
    );
  };

  const handleSessionExpired = (email?: string) => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title={`Session expired for ${email || 'your email account'}`}
        buttonLabel="Reconnect"
        icon="bi bi-exclamation-circle"
        isImageDanger
        handleButtonClick={handleChooseProvider}
        description={(
          <>
            Your email session has expired. You need to reauthorize your email account to continue.
            Don&apos;t worry, it&apos;ll just take a moment!
          </>
                )}
      />,
    );
  };

  const handleInsufficientScope = (email?: string) => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title={`Permission required for ${email || 'your email account'}`}
        buttonLabel="Reconnect"
        icon="bi bi-exclamation-circle"
        isImageDanger
        handleButtonClick={handleChooseProvider}
        description={(
          <>
            Your email provider requires additional permissions to send emails.
            Please reconnect your email account and grant the necessary permissions
            to continue.
          </>
                )}
      />,
    );
  };

  const handleWebSocketMessage = useCallback(async (event: any) => {
    if (!event.data) return;

    const dataObj = JSON.parse(event.data);
    const { email } = dataObj.payload;
    const { status } = dataObj.payload;

    showLoadingModal();

    await delay(1500);

    if (status === 'success') {
      showSuccessModal(email);
    } else if (status === 'missing_permissions') {
      showRetryModal();
    } else {
      showFailedModal(email);
    }

    if (wsService) {
      wsService.close();
      setWsService(null);
    }

    onSuccess();
  }, [wsService]);

  const connectWebSocket = () => {
    const token = window.localStorage.getItem('token');
    if (token) {
      const newWsService = createWebSocketService(websocketUrl, token);
      newWsService.onMessage(handleWebSocketMessage);
      setWsService(newWsService);
    }
  };

  const goToOauth2 = (provider: string) => {
    const accessToken = window.localStorage.getItem('token');
    if (!accessToken) {
      return;
    }

    if (!wsService) {
      connectWebSocket();
    }

    window.open(`${config.apiUrl}/v1/oauth/${provider}/start?token=${accessToken}`, '_blank');
  };

  const handleConfirmProvider = (providerName: string, providerId: string) => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title={`Connect your ${providerName} Account to CareerOS`}
        secondaryButtonLabel="Back"
        buttonLabel="Continue"
        handleSecondaryButtonClick={handleChooseProvider}
        handleButtonClick={() => goToOauth2(providerId)}
        icon="bi bi-envelope"
        description={(
          <>
            <p>By granting CareerOS access to your inbox we will be able to access data and information from your account:</p>
            <ul className="connect-inbox-modal__list">
              <li>You can send and receive messages, schedule email sends, and modify email drafts in the CareerOS app. You will also be able to log and track emails sent in-app. CareerOS will have access to your data, like email headers, subject lines, and email bodies.</li>
            </ul>
            <p>
              For more information on the provider integration and the types of data we can access, please see our
              {' '}
              <a href="https://www.thecareeros.com/legal/privacy" target="_blank" rel="noreferrer">
                Privacy Policy.
              </a>
            </p>
          </>
 )}
      />,
    );
  };

  const handleChooseProvider = () => {
    openModal(
      <ChooseProviderModal
        orientation="horizontal"
        title="Choose your email provider"
        image="bi bi-envelope"
        handleButtonClick={handleConfirmProvider}
      />,
    );
  };

  const handleConnectEmail = () => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title="(Re-)Connect your email inbox and make networking a breeze 🌊"
        buttonLabel="Connect Your Inbox"
        handleButtonClick={handleChooseProvider}
        icon="bi bi-envelope"
        description={(
          <ul className="connect-inbox-modal__list">
            <li>Easily send and schedule emails directly from CareerOS</li>
            <li>Keep track of your message history with your contacts</li>
            <li>Receive follow-up Quests on your messages on your Home Page</li>
          </ul>
        )}
      />,
    );
  };

  const handleConnectEmailBeforeSend = () => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title="Connect your Email Account to send this Email"
        buttonLabel="Connect Inbox"
        handleButtonClick={handleChooseProvider}
        secondaryButtonLabel="Cancel"
        handleSecondaryButtonClick={closeModal}
        icon="bi bi-envelope"
        buttonIcon="bi bi-arrow-right"
        primaryButtonIconPosition="right"
        description={(
          <ul className="connect-inbox-modal__list">
            <li>Easily send and schedule emails directly from CareerOS</li>
            <li>Keep track of your message history with your contacts</li>
            <li>Receive follow-up Quests on your messages on your Home Page</li>
          </ul>
        )}
      />,
    );
  };

  const handleChangeEmail = () => {
    openModal(
      <InfoModal
        orientation="horizontal"
        title="Change your email address"
        handleButtonClick={handleChooseProvider}
        secondaryButtonLabel="Back"
        buttonLabel="Continue"
        handleSecondaryButtonClick={closeModal}
        icon="bi bi-envelope"
        description={(
          <>
            <p>By updating your email address, please be aware of the following important information:</p>
            <ul className="connect-inbox-modal__list">
              <li>Changing your email will result in the deactivation of all current sequences and automated processes associated with your current email. This is because our system will lose access to your existing email data, including any ongoing tracking or scheduling features.</li>
            </ul>
            <p>
              For more information on how changing your email affects the services and access to your data, please refer to our
              {' '}
              <a href="https://www.thecareeros.com/legal/privacy" target="_blank" rel="noreferrer">
                Privacy Policy.
              </a>
            </p>
          </>
                )}
      />,
    );
  };

  return {
    handleConnectEmail,
    handleChangeEmail,
    handleSessionExpired,
    handleInsufficientScope,
    handleConnectEmailBeforeSend,
  };
};

export default useConnectEmail;
