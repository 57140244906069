import React, {
  useState, useCallback, useRef, useEffect,
} from 'react';
import './range-slider.scss';
import { Tooltip } from '@careeros/coco';

interface RangeSliderProps {
  label?: string;
  min?: number;
  max?: number;
  defaultMinValue?: number;
  defaultMaxValue?: number;
  width?: 'full' | 'static';
  onChange?: (values: [number, number]) => void;
}

export const RangeSlider: React.FC<RangeSliderProps> = ({
  label,
  min = 0,
  max = 100,
  defaultMinValue = 0,
  defaultMaxValue = 40,
  width = 'static',
  onChange,
}) => {
  const [minVal, setMinVal] = useState(defaultMinValue);
  const [maxVal, setMaxVal] = useState(defaultMaxValue);
  const [isDragging, setIsDragging] = useState<'min' | 'max' | null>(null);
  const trackRef = useRef<HTMLDivElement>(null);

  const getPercentage = (value: number) => ((value - min) / (max - min)) * 100;

  const handleMouseDown = (handle: 'min' | 'max') => {
    setIsDragging(handle);
  };

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!isDragging || !trackRef.current) return;

    const rect = trackRef.current.getBoundingClientRect();
    const percentage = Math.min(Math.max(0, ((e.clientX - rect.left) / rect.width) * 100), 100);
    const newValue = Math.round((percentage * (max - min)) / 100 + min);

    if (isDragging === 'min' && newValue <= maxVal) {
      setMinVal(newValue);
      onChange?.([newValue, maxVal]);
    } else if (isDragging === 'max' && newValue >= minVal) {
      setMaxVal(newValue);
      onChange?.([minVal, newValue]);
    }
  }, [isDragging, max, min, minVal, maxVal, onChange]);

  const handleMouseUp = () => {
    setIsDragging(null);
  };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove]);

  useEffect(() => {
    setMinVal(defaultMinValue);
    setMaxVal(defaultMaxValue);
  }, [defaultMinValue, defaultMaxValue]);

  return (
    <div className={`range-slider range-slider--${width}-width`} data-testid="range-slider">
      {label && <div className="range-slider__label">{label}</div>}
      <div className="range-slider__track" ref={trackRef}>
        <div
          className="range-slider__range"
          style={{
            left: `${getPercentage(minVal)}%`,
            width: `${getPercentage(maxVal) - getPercentage(minVal)}%`,
          }}
        />
        <div
          className="range-slider__handle"
          style={{ left: `${getPercentage(minVal)}%` }}
          onMouseDown={() => handleMouseDown('min')}
        >
          <Tooltip label={`${getPercentage(minVal)}%`} position="top" withArrow>
            <div className="range-slider__handle-button" />
          </Tooltip>
        </div>
        <div
          className="range-slider__handle"
          style={{ left: `${getPercentage(maxVal)}%` }}
          onMouseDown={() => handleMouseDown('max')}
        >
          <Tooltip label={`${getPercentage(maxVal)}%`} position="top" withArrow>
            <div className="range-slider__handle-button" />
          </Tooltip>
        </div>
      </div>

    </div>
  );
};
